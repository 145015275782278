import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import loader from 'assets/img/loader.svg';
import closeIcon from 'assets/img/modals/icons/close.png';
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Api from 'helpers/Api';
import { useNestedState } from 'helpers/NestedState';
import Skeleton from 'react-loading-skeleton';

function OPR(props, ref) {

    const defaultData = null;

    const [state, setState] = useNestedState({
        overlay: false,
        modal: false,
        id: null,
        data: defaultData,
        loading: false,
        onSuccess: null
    });

    useImperativeHandle(ref, () => ({
        show: (id) => {
            show(id);
        },
        hide: () => {
            hide();
        },
    }));

    useEffect(() => {
        loadData();
    }, [state.id]);

    const show = (id) => {
        setState(prev => ({
            ...prev,
            overlay: true,
            id: id
        }));

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);

    }

    const hide = () => {
        setState(prev => ({
            ...prev,
            modal: false,
        }));

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 150);
    }

    const handleHide = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            id: null,
            data: defaultData
        }));

        hide();
    }

    // todo
    const loadData = () => {
        if (!state.id) {
            return;
        }

        setState(prev => ({
            ...prev,
            loading: true,
        }))

        Api.get('accounts/stroiteli/opr', {
            params: {
                project_id: state.id,
                company_id: props.companyId
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data,
                loading: false,
            }));
        });
    }

    const renderRow = (data, level = 1, path = []) => {
        let out = [];

        data.map((type, index) => {

            let item = <TableRow
                key={'row-' + Math.random()}
            >
                <TableCell style={{ paddingLeft: (level * 20) + 'px' }}>
                    {[path.join('.'), index + 1].filter(e => e).join('.')}. {type.name || 'n/a'}
                </TableCell>
                <TableCell align="right">
                    {price(type.amount)}
                </TableCell>
            </TableRow>;

            out.push(item);

            if (type.children.length > 0) {
                out = out.concat(renderRow(type.children, level + 1, path.concat(index + 1)));
            }

        });

        return out;
    }

    const price = price => {
        return price.toFixed(2);
    }

    return (
        <>
            <div key={state.id} className={`${state.overlay ? 'visible' : ''} overlay`}>
                <div className={`${state.modal ? 'show' : ''} popup-primary extra-large`}>
                    <h2 className="head">
                        Обща калкулация за обект
                        <img className="close-icon" alt="close" src={closeIcon} onClick={handleHide} />
                    </h2>

                    <Box className="body">
                        {state.loading
                            ?
                            <Skeleton count={20} height={30} />
                            :
                            <>
                                <TableContainer style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <Table size="small" >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><b>Наименование на разходите</b></TableCell>
                                                <TableCell align="right"><b>Сума</b></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {renderRow(state.data?.expenses?.operativna_deynost || [])}
                                        </TableBody>
                                    </Table>
                                    <Table size="small" >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><b>Наименование на приходите</b></TableCell>
                                                <TableCell align="right"><b>Сума</b></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {renderRow(state.data?.incomes?.operativna_deynost || [])}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <br />

                                <TableContainer style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <Table size="small" >
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    <b>ОБЩО РАЗХОДИ ЗА ОПЕРАТИВНА ДЕЙНОСТ</b>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <b>{price(state.data?.expenses?.amount_operativna_deynost || 0)}</b>
                                                </TableCell>
                                                <TableCell>
                                                    <b>ОБЩО ПРИХОДИ ОТ ОПЕРАТИВНА ДЕЙНОСТ</b>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <b>{price(state.data?.incomes?.amount_operativna_deynost || 0)}</b>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>


                                </TableContainer>

                                <br />
                                <TableContainer style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <Table size="small">
                                        <TableBody>
                                            {renderRow(state.data?.expenses?.drugi || [])}
                                        </TableBody>
                                    </Table>
                                    <Table size="small">
                                        <TableBody>
                                            {renderRow(state.data?.incomes?.drugi || [])}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <br />

                                <TableContainer style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <Table size="small" >
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    <b>ОБЩО РАЗХОДИ</b>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <b>{price(state.data?.expenses?.amount || 0)}</b>
                                                </TableCell>
                                                <TableCell>
                                                    <b>ОБЩО ПРИХОДИ</b>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <b>{price(state.data?.incomes?.amount || 0)}</b>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <b>ПЕЧАЛБА</b>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <b>{price(state.data?.profit || 0)}</b>
                                                </TableCell>
                                                <TableCell>
                                                    <b>ЗАГУБА</b>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <b>{price(state.data?.loss || 0)}</b>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <b>ДАНЪК ПЕЧАЛБА</b>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <b>{price(state.data?.profit_vat || 0)}</b>
                                                </TableCell>
                                                <TableCell>

                                                </TableCell>
                                                <TableCell align="right">

                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <b>НЕТНА ПЕЧАЛБА</b>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <b>{price(state.data?.profit_net || 0)}</b>
                                                </TableCell>
                                                <TableCell>
                                                    <b>НЕТНА ЗАГУБА</b>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <b>{price(state.data?.loss_net || 0)}</b>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>

                                </TableContainer>
                            </>
                        }

                    </Box>

                    <div className="footer">
                        <Button
                            className="cancel"
                            onClick={handleHide}
                        >
                            Затвори
                        </Button>

                    </div>
                </div>
            </div>
        </>
    )
}

export default forwardRef(OPR);