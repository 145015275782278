import { useHistory } from "react-router"

//helpers
import { useQuery } from 'helpers/Url'

//misc
import DatePicker from 'components/misc/DatePicker'
import RedditTextField from "components/misc/RedditTextField"

//Plugins
import moment from "moment"

//MUI components
import Tooltip from '@material-ui/core/Tooltip'

//images
import clearFilterIcon from 'assets/img/documents/icons/clear-filter.png'

function Filter(props) {
    const history = useHistory()
    const query = useQuery()

    const handleDateChange = (date, name) => {
        props.handleSearch(name, moment(date).format('YYYY-MM-DD'))
    }

    const handleSearch = e => {
        const { name } = e.target
        const { value } = e.target

        props.handleSearch(name, value)
    }

    const handleClearFilter = () => {
        props.clearFilter();
    }

    const areAppliedFilters = () => {
        return props.filter.search || props.filter.start_date || props.filter.end_date
    }

    return (
        <div className="row filter">
            <Tooltip
                title="Търсене по име на документ"
            >
                <div
                    className="col"
                    style={{
                        maxWidth: '300px'
                    }}
                >
                    <RedditTextField
                        size="small"
                        variant="filled"
                        margin="dense"
                        fullWidth
                        label="Търсене..."
                        name="search"
                        value={props.filter.search || ''}
                        onChange={handleSearch}
                    />
                </div>
            </Tooltip>
            <Tooltip
                title="Търсене по начална дата на създаване"
            >
                <div
                    className="col"
                    style={{
                        maxWidth: '150px'
                    }}
                >
                    <DatePicker
                        reddit
                        fullWidth
                        label="От дата"
                        name="date"
                        disableToolbar
                        // format="dd/MM/yyyy"
                        value={props.filter.start_date || null}
                        onChange={e => {
                            handleDateChange(e.target.value, 'start_date')
                        }}
                    />
                </div>
            </Tooltip>
            <Tooltip
                title="Търсене по крайна дата на създаване"
            >
                <div
                    className="col"
                    style={{
                        maxWidth: '150px'
                    }}
                >
                    <DatePicker
                        reddit
                        fullWidth
                        label="До дата"
                        name="date"
                        disableToolbar
                        format="dd/MM/yyyy"
                        value={props.filter.end_date || null}
                        onChange={e => {
                            handleDateChange(e.target.value, 'end_date')
                        }}
                    />
                </div>
            </Tooltip>
            <Tooltip
                title="Изчистите данните от филтъра"
            >
                <div
                    className={`col link ${areAppliedFilters() ? '' : 'disabled'}`}
                    onClick={handleClearFilter}
                    style={{
                        justifyContent: 'center',
                        width: 'fit-content',
                        flex: 'initial'
                    }}
                >
                    <img src={clearFilterIcon} />
                </div>
            </Tooltip>
        </div>
    )
}

export default Filter