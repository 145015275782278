import { useEffect, useState } from "react"

//components
import SaveButton from "components/misc/Button"
import RedditTextField from "components/misc/RedditTextField"
import FindCompany from "components/pages/sandbox/findcompany/components/FindCompany"

//libraries
import { Button } from "@material-ui/core"
import moment from "moment"
import { Skeleton } from "@mui/material"
import { useQueryClient } from "react-query"

//stores
import { useAddToRecommendationsModal } from "stores/customer/useAddToRecommendationsModal"

//providers
import { useAuthDataContext } from "providers/Auth"

//images
import uploadIcon from "assets/img/app/icons/upload.png"

//hooks
import useSaveOnServer from "hooks/useSaveOnServer"

function AddOrEditRecommendations({ allRecommendations }, ref) {
  const queryClient = useQueryClient()
  const { getUser } = useAuthDataContext()

  const { getShown, getForm, hideModal } = useAddToRecommendationsModal()
  const [localForm, setLocalForm] = useState({})

  const { saveOnServer, loadingSaveOnServer } = useSaveOnServer()

  useEffect(() => {
    setLocalForm(getForm())
  }, [getForm()])

  function handleSetLocalForm(key, value) {
    setLocalForm((prev) => ({
      ...prev,
      [key]: value,
    }))
  }

  const handleClose = (e) => {
    if (e) {
      e.preventDefault()
    }

    setLocalForm(getForm())
    hideModal()
  }

  function saveToLocalStorage() {
    let updatedRecommendation = [...allRecommendations]

    if (localForm.id) {
      updatedRecommendation = allRecommendations.map((recommendation) => {
        if (recommendation.id === localForm.id) {
          return {
            ...localForm,
            id: recommendation.id,
          }
        }

        return recommendation
      })
    } else {
      updatedRecommendation.push({
        ...localForm,
        id: moment().unix(),
      })
    }

    saveOnServer("recommendations", updatedRecommendation, () => {
      handleClose()
      queryClient.invalidateQueries("recommendations")
    })
  }

  function getCreationDate() {
    if (localForm.created_at) {
      return moment(localForm.created_at).format("dd, DD MMM")
    }

    return moment().format("dd, DD MMM")
  }

  return (
    <div className={`${getShown() ? "visible" : ""} overlay`}>
      <div
        className={`popup-primary small marketing-archive ${
          getShown() ? "show" : ""
        }`}
      >
        <div className="body">
          <h3>{localForm.id ? "Редакция" : "Добавяне"} на препоръка</h3>
          <p>Въведете препоръка от клиент или доставчик</p>
          <div className="row unchangable-row">
            <div className="col">
              <span className="label">Датана създаване</span>
              <b>{getCreationDate()}</b>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <FindCompany
                // centeredLabel={true}
                label="Клиент"
                inputIdName="clientField"
                setSelectedCompanyCB={(value) => {
                  setLocalForm((prev) => ({
                    ...prev,
                    client: value,
                  }))
                }}
                initialValue={localForm?.clientField}
                setInputValueCB={(value) => {
                  setLocalForm((prev) => ({
                    ...prev,
                    clientField: value,
                  }))
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <RedditTextField
                label="Отзив"
                multiline
                rows={3}
                value={localForm.note}
                onChange={(e) => handleSetLocalForm("note", e.target.value)}
              />
            </div>
          </div>
          <div
            className="upload disabled"
            style={{
              justifyContent: "center",
            }}
          >
            <img src={uploadIcon} alt="" />
            <h5>Изберете файл</h5>
            <input
              type="file"
              multiple
              name="file"
              //   ref={inputFileRef}
              //   onChange={handleAttach}
              accept=".pdf"
              title=""
            />
            <p>Допустими формати: .pdf</p>
          </div>
        </div>
        <div className="footer">
          <Button variant="contained" className="cancel" onClick={handleClose}>
            Отказ
          </Button>
          <SaveButton
            loading={loadingSaveOnServer}
            className="save"
            // onClick={(e) => formRef.current?.save(e)}
            onClick={saveToLocalStorage}
          />
        </div>
      </div>
    </div>
  )
}

export default AddOrEditRecommendations
