import { useState, useImperativeHandle, forwardRef, useEffect } from 'react'

//mics
import RedditTextField from 'components/misc/RedditTextField'
import RedditSelect from 'components/misc/RedditSelect'
import SaveButton from 'components/misc/Button'

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'

//libraries
import ClickAwayListener from 'react-click-away-listener'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import moment from 'moment'
import { Checkbox, FormControlLabel, MenuItem, Button } from '@material-ui/core'

//images
import templateIcon from 'assets/img/documents/icons/template.png'
import closeIcon from 'assets/img/modals/icons/close.png'

//hooks
import useMailTemplates from '../hooks/useMailTemplates'

//providers
import { trans } from 'providers/Translation'

const PERIODS = {
    day: 1,
    week: 2,
    month: 3,
    year: 4,
    threeMonths: 5,
    sixMonths: 6,
}

const WEEK_DAYS = [
    {
        id: 1,
        name: trans('dates.weekdaysObj.monday')
    },
    {
        id: 2,
        name: trans('dates.weekdaysObj.tuesday')
    },
    {
        id: 3,
        name: trans('dates.weekdaysObj.wednesday')
    },
    {
        id: 4,
        name: trans('dates.weekdaysObj.thursday')
    },
    {
        id: 5,
        name: trans('dates.weekdaysObj.friday')
    },
    {
        id: 6,
        name: trans('dates.weekdaysObj.saturday')
    },
    {
        id: 7,
        name: trans('dates.weekdaysObj.sunday')
    }
]

const MONTHS = [
    {
        id: 1,
        name: trans('dates.months')[0]
    },
    {
        id: 2,
        name: trans('dates.months')[1]
    },
    {
        id: 3,
        name: trans('dates.months')[2]
    },
    {
        id: 4,
        name: trans('dates.months')[3]
    },
    {
        id: 5,
        name: trans('dates.months')[4]
    },
    {
        id: 6,
        name: trans('dates.months')[5]
    },
    {
        id: 7,
        name: trans('dates.months')[6]
    },
    {
        id: 8,
        name: trans('dates.months')[7]
    },
    {
        id: 9,
        name: trans('dates.months')[8]
    },
    {
        id: 10,
        name: trans('dates.months')[9]
    },
    {
        id: 11,
        name: trans('dates.months')[10]
    },
    {
        id: 12,
        name: trans('dates.months')[11]
    }
]

function RepeatOptions({ }, ref) {
    const defaultData = {
        repeat: false,
        disable_repeat_end_date: false,
        repeat_after: 1,
        repeat_on_day: 1,
        repeat_on_month: 1,
        repeat_on_last_day: false,
    }

    const [state, setState] = useState({
        id: null,
        overlay: false,
        modal: false,
        data: defaultData,
        onSuccess: null,
        onError: null,
        onClose: null,
    })

    const [validations, setValidations] = useValidation()
    const {
        fetchTemplates,
        toggleTemplates,
        hideTemplates,
        getTemplates,
        getActiveTemplate,
        getTemplatesData,
        resetTemplatesData,
        getActiveTemplateId,
        setSelectedTemplate
    } = useMailTemplates()

    useImperativeHandle(ref, () => ({
        open: (data) => {
            handleOpen(data)
        },
        close: () => {
            handleHide()
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        },
        onError: fn => {
            setState(prev => ({
                ...prev,
                onError: fn
            }))
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }))
        }
    }))

    useEffect(() => {
        if (state.data?.repeat_auto_send) fetchTemplates({
            documentIdsArr: [state.data?.id],
            endpoint: 'documents/mail-templates'
        })
    }, [state.data?.repeat_auto_send])

    useEffect(() => {
        if (getActiveTemplate()) {
            setState(prev => ({
                ...prev,
                text: getActiveTemplate().text,
                topic: getActiveTemplate().title
            }))
        }
    }, [getActiveTemplate()])

    const handleOpen = (data) => {

        if (!data?.id) {
            throw new Error('not valid data')
        }

        setState(prev => ({
            ...prev,
            overlay: true,
            data: data,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                modal: true,
            }))
        }, 50)
    }

    const handleHide = () => {
        setState(prev => ({
            ...prev,
            modal: false,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
            }))
        }, 100)

        resetData()
        setValidations(null)

        if (typeof state.onClose === 'function') {
            state.onClose()
        }
    }

    const resetData = () => {
        setState(prev => ({
            ...prev,
            data: defaultData,
        }))
        resetTemplatesData()
    }

    const handleInputChange = e => {
        const { name, value } = e.target

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: value
            }
        }))
    }

    const handleCheckboxChange = e => {
        const name = e.target.name

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: !prev.data[name]
            }
        }))
    }

    const handleStartDateChange = e => {
        const value = e.target.value

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                repeat_start_date_ymd: value,
                repeat_end_date_ymd: value > prev.data.repeat_end_date_ymd ? value : prev.data.repeat_end_date_ymd,
            }
        }))
    }

    const handleEndDateChange = e => {
        const value = e.target.value

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                repeat_end_date_ymd: value < prev.data.repeat_start_date_ymd ? prev.data.repeat_start_date_ymd : value,
            }
        }))
    }

    const handleSave = e => {
        e.preventDefault()

        setLoading(true)
        setValidations(null)

        const url = 'documents/make-repeat'
        const data = new FormData(e.target)

        if (state.data.id) data.append('id', state.data.id)

        Api.post(url, data)
            .then(res => {
                if (res.data.success) {
                    if (typeof state.onSuccess === 'function') state.onSuccess(res.data)

                    handleHide()
                } else {
                    if (typeof state.onError === 'function') state.onError(res.data)
                }

            })
            .catch(error => {
                const _err = error.response

                if (_err && _err.status && _err.status === 422) setValidations(_err.data.errors)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const setLoading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    const getWeekDays = () => {
        return WEEK_DAYS
    }

    const getMonths = () => {
        return MONTHS
    }

    const getWeekValues = () => {
        return [
            {
                id: 1,
                name: 'седмица'
            },
            {
                id: 2,
                name: '2 седмици'
            },
            {
                id: 3,
                name: '3 седмици'
            },
            {
                id: 4,
                name: '4 седмици'
            },
        ]
    }

    const getMonthValues = () => {
        let n = []

        for (let i = 1; i <= 12; i++) {
            n.push({
                id: i,
                name: i === 1 ? 'месец' : (i + ' месеца')
            })
        }

        return n
    }

    const repeatAfterIsVisible = () => {
        if (isDay() || isWeek() || isMonth()) {
            return true
        }

        return false
    }

    const getMonthDays = month => {
        if (!month) {
            return []
        }

        let days = moment(moment().format('YYYY') + month.toFixed(2), 'YYYY-MM').daysInMonth()

        let n = []

        for (let i = 1; i <= days; i++) {
            if (Number(month) === 2) {
                if (i > 28) {
                    break
                }
            }

            n.push(i)
        }

        return n
    }

    const isDay = () => {
        return state.data.repeat_period === PERIODS.day
    }

    const isWeek = () => {
        return state.data.repeat_period === PERIODS.week
    }

    const isMonth = () => {
        return state.data.repeat_period === PERIODS.month
    }

    const isThreeMonths = () => {
        return state.data.repeat_period === PERIODS.threeMonths
    }

    const isSixMonths = () => {
        return state.data.repeat_period === PERIODS.sixMonths
    }

    const isYear = () => {
        return state.data.repeat_period === PERIODS.year
    }

    const isMonthPeriod = () => {
        if (isMonth() || isThreeMonths() || isSixMonths()) {
            return true
        }

        return false
    }

    const isWeekPeriod = () => {
        if (isWeek()) {
            return true
        }

        return false
    }

    const isYearPeriod = () => {
        if (isYear()) {
            return true
        }

        return false
    }

    const getText = () => state.text || ''

    const handleTextChange = value => {
        setState(prev => ({
            ...prev,
            text: value.getData() || ''
        }))
    }

    const createMarkup = text => ({ __html: text })

    const nl2br = text => text.replaceAll(/(\r\n|\r|\n)/g, '<br/>')

    return (
        <div className={`${state.overlay ? 'visible' : ''} overlay`}>
            <form className={`${state.modal ? 'show' : ''} ${!state.data?.repeat_auto_send ? 'small' : ''} popup-primary repeat-options`} noValidate onSubmit={handleSave}>
                <div className="body">
                    <img className="close-icon" alt="close" src={closeIcon} onClick={handleHide} />

                    <div className="left">
                        <h2 className="head">
                            Периодично издаване
                        </h2>
                        {state.data.repeat ?
                            <div className="row">
                                <div className="col">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={state.data && state.data.repeat ? true : false}
                                                onChange={handleCheckboxChange}
                                                name="repeat"
                                                value={1}
                                                color="primary"
                                            />
                                        }
                                        label={<>
                                            <b>
                                                Периодично издаване за:
                                            </b>
                                            <p>
                                                {state.data?.customerinfo?.name || ''}
                                            </p>
                                        </>}
                                    />
                                </div>
                            </div>
                            :
                            <input type="hidden" name="repeat" value={1} />
                        }
                        <div className="row">
                            <div className="col">
                                <RedditTextField
                                    fullWidth
                                    label="От дата"
                                    name="repeat_start_date"
                                    type="date"
                                    value={state.data?.repeat_start_date_ymd || ''}
                                    onChange={handleStartDateChange}
                                    error={Boolean(validations && validations.repeat_start_date)}
                                    helperText={validations && validations.repeat_start_date && (validations.repeat_start_date[0] || validations.repeat_start_date)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                            <div className="col">
                                <RedditTextField
                                    fullWidth
                                    label="До дата (незадълж.)"
                                    name="repeat_end_date"
                                    type="date"
                                    value={(state.data?.repeat_end_date_ymd || '')}
                                    onChange={handleEndDateChange}
                                    error={Boolean(validations && validations.repeat_end_date)}
                                    helperText={validations && validations.repeat_end_date && (validations.repeat_end_date[0] || validations.repeat_end_date)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {!state.data?.repeat_end_date_ymd ?
                                    <input hidden
                                        name="disable_repeat_end_date"
                                        value="1"
                                    />
                                    :
                                    <></>
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <RedditSelect
                                    name="repeat_period"
                                    value={state.data?.repeat_period || ''}
                                    onChange={handleInputChange}
                                    label="Период"
                                    error={validations && validations.repeat_period}
                                    helperText={validations && validations.repeat_period && (validations.repeat_period[0] || validations.repeat_period)}
                                >
                                    <MenuItem value={PERIODS.week}>
                                        седмица
                                    </MenuItem>
                                    <MenuItem value={PERIODS.month}>
                                        месец
                                    </MenuItem>
                                    <MenuItem value={PERIODS.year}>
                                        година
                                    </MenuItem>
                                </RedditSelect>
                            </div>
                        </div>
                        <div className="row">
                            {repeatAfterIsVisible() ?
                                <div className="col">
                                    <RedditSelect
                                        name="repeat_after"
                                        value={state.data?.repeat_after || ''}
                                        onChange={handleInputChange}
                                        label="Издавай веднъж на"
                                        error={validations && validations.repeat_after}
                                        helperText={validations && validations.repeat_after && (validations.repeat_after[0] || validations.repeat_after)}
                                    >
                                        {isWeek() ?
                                            getWeekValues().map(n =>
                                                <MenuItem key={n.id} value={n.id}>
                                                    {n.name}
                                                </MenuItem>
                                            )
                                            :
                                            getMonthValues().map(n =>
                                                <MenuItem key={n.id} value={n.id}>
                                                    {n.name}
                                                </MenuItem>
                                            )}
                                    </RedditSelect>
                                </div>
                                :
                                <>
                                </>
                            }
                            <div className="col">
                                {isWeekPeriod()
                                    ?
                                    <RedditSelect
                                        name="repeat_on_day"
                                        value={state.data?.repeat_on_day || ''}
                                        onChange={handleInputChange}
                                        label="Ден от седмицата"
                                        error={validations && validations.repeat_on_day}
                                        helperText={validations && validations.repeat_on_day && (validations.repeat_on_day[0] || validations.repeat_on_day)}
                                    >
                                        {getWeekDays().map(day =>
                                            <MenuItem key={day.id} value={day.id}>
                                                {day.name}
                                            </MenuItem>
                                        )}
                                    </RedditSelect>
                                    :
                                    isMonthPeriod() ?
                                        <>
                                            <RedditSelect
                                                name="repeat_on_day"
                                                value={state.data?.repeat_on_day || ''}
                                                onChange={handleInputChange}
                                                label="Число от месеца"
                                                error={validations && validations.repeat_on_day}
                                                helperText={validations && validations.repeat_on_day && (validations.repeat_on_day[0] || validations.repeat_on_day)}
                                                disabled={Number(state.data?.repeat_on_last_day) === 1}
                                            >
                                                {getMonthDays(state.data.repeat_on_month).map(day =>
                                                    <MenuItem key={day} value={day}>
                                                        {day}
                                                    </MenuItem>
                                                )}
                                            </RedditSelect>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={state.data && state.data.repeat_on_last_day ? true : false}
                                                        onChange={handleCheckboxChange}
                                                        name="repeat_on_last_day"
                                                        value={1}
                                                        color="primary"
                                                    />
                                                }
                                                label={<>
                                                    Последно число<br /> от месеца
                                                </>}
                                                style={{
                                                    marginTop: '5px'
                                                }}
                                            />
                                        </>
                                        :
                                        isYearPeriod() ?
                                            <div className="row">
                                                <div className="col">
                                                    <RedditSelect
                                                        name="repeat_on_month"
                                                        value={state.data?.repeat_on_month || ''}
                                                        onChange={handleInputChange}
                                                        label="Издаване през месец"
                                                        error={validations && validations.repeat_on_month}
                                                        helperText={validations && validations.repeat_on_month && (validations.repeat_on_month[0] || validations.repeat_on_month)}
                                                    >
                                                        {getMonths().map(month =>
                                                            <MenuItem key={month.id} value={month.id}>
                                                                {month.name}
                                                            </MenuItem>
                                                        )}
                                                    </RedditSelect>
                                                </div>
                                                <div className="col">
                                                    <RedditSelect
                                                        name="repeat_on_day"
                                                        value={state.data?.repeat_on_day || ''}
                                                        onChange={handleInputChange}
                                                        label="Число от месеца"
                                                        error={validations && validations.repeat_on_day}
                                                        helperText={validations && validations.repeat_on_day && (validations.repeat_on_day[0] || validations.repeat_on_day)}
                                                    >
                                                        {getMonthDays(state.data.repeat_on_month).map(day =>
                                                            <MenuItem key={day} value={day}>
                                                                {day}
                                                            </MenuItem>
                                                        )}
                                                    </RedditSelect>
                                                </div>
                                            </div>
                                            :
                                            <>
                                            </>
                                }
                            </div>
                        </div>
                        <hr />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={Boolean(state.data?.repeat_auto_send)}
                                    onChange={handleCheckboxChange}
                                    name="repeat_auto_send"
                                    value={1}
                                    color="primary"
                                />
                            }
                            label="Автоматично изпращане до клиента"
                        />
                    </div>
                    {state.data?.repeat_auto_send ?
                        <div className="right">
                            <h2 className="head">
                                Преглед на писмото
                            </h2>
                            <div className="row label-holder">
                                <div className="col label">
                                    Тема
                                </div>
                                <div className="col">
                                    <div className="email-input-wrapper">
                                        <div className="email-input">
                                            <div className="input">
                                                <input
                                                    type="text"
                                                    name="topic"
                                                    value={state.topic || ''}
                                                    onChange={handleInputChange}
                                                    className={Boolean(validations && validations.topic) ? 'val' : ''}
                                                />
                                            </div>
                                            <RedditTextField
                                                error={Boolean(validations && validations.topic)}
                                                helperText={validations && validations.topic && validations.topic[0]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="row"
                                style={{
                                    marginTop: '20px'
                                }}
                            >
                                <div className="col">
                                    <div className="row label-holder">
                                        <div className="col label">
                                            Съобщение
                                        </div>
                                        <div className="col">
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end'
                                                }}
                                            >
                                                <ClickAwayListener onClickAway={hideTemplates}>
                                                    <div className="active-template">
                                                        <div
                                                            onClick={toggleTemplates}
                                                            className="link row align-center"
                                                            style={{ marginBottom: '0' }}
                                                        >
                                                            <img
                                                                src={templateIcon}
                                                                style={{
                                                                    marginRight: '5px'
                                                                }}
                                                            />
                                                            {getActiveTemplate() ? getActiveTemplate().name : 'Няма избран шаблон'}
                                                        </div>

                                                        <div className={`dropdown ${Boolean(getTemplatesData().anchorEl) && 'show'}`}>
                                                            <div className="heading">
                                                                <div className="title">
                                                                    Шаблони със съобщения
                                                                </div>
                                                            </div>
                                                            <div className="body">
                                                                {getTemplates().map(template =>
                                                                    <div
                                                                        className={`template ${getActiveTemplateId() === template.id && 'active'}`}
                                                                        onClick={() => {
                                                                            setSelectedTemplate(template)
                                                                        }}
                                                                    >
                                                                        <div className="name">
                                                                            {template.name}
                                                                        </div>
                                                                        <div
                                                                            className="text"
                                                                            dangerouslySetInnerHTML={createMarkup(nl2br(template.text.substring(0, 20)))}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ClickAwayListener>
                                            </div>
                                        </div>
                                    </div>
                                    <input type="hidden" name="text" value={getText()} />
                                    <CKEditor
                                        editor={window.ClassicEditor}
                                        data={getText()}
                                        config={{
                                            placeholder: 'Въведете съобщение...',
                                        }}
                                        onChange={(_, editor) => {
                                            handleTextChange(editor)
                                        }}
                                    />
                                </div>
                            </div>
                            <input type="hidden" name="text" value={getText()} />
                        </div>
                        :
                        <>
                        </>
                    }
                </div>
                <div className="footer">
                    <Button
                        className="cancel"
                        onClick={handleHide}
                    >
                        Отказ
                    </Button>
                    <SaveButton
                        loading={state.loading}
                        className="save"
                    />
                </div>
            </form>
        </div>
    )
}

export default forwardRef(RepeatOptions)