//librairies
import { setRef } from '@material-ui/core'
import moment from 'moment'
import { create } from 'zustand'

const INITIAL_FORM = {
  id: null,
  startDate: moment().format("YYYY-MM-DD"),
  endDate: moment().format("YYYY-MM-DD"),
  isAllDay: false,
  eventType: null,
  startHour: null,
  endHour: null,
  title: "",
  client: {},
  clientField: "",
  tags: []
}
export const useAddToCalendarModal = create((set, get) => ({
  show: false,
  refresh: null,
  form: { ...INITIAL_FORM },
  showModal: (show = true, initialForm = null) => set(state => ({
    show,
    form: {
      ...INITIAL_FORM,
      ...(initialForm || state.form)
    }
  })),
  setForm: (key, value) => set((state) => ({
    ...state,
    form: {
      ...state.form,
      [key]: value,
    }
  })),
  hideModal: () => set({ show: false, form: INITIAL_FORM }),
  getForm: () => get().form,
  getShown: () => get().show,
  getRefresh: () => get().refresh,
  setRefresh: () => set({ refresh: moment().unix() }),
}))