import { useEffect } from "react"
import usePasswordStrengthIndicatorHook from "../userPasswordStrengthIndicatorHook"

const PasswordStrengthIndicator = props => {
    const { password } = props
    const {
        getRecommendationMessage,
        testPassword,
        setFocusType,
        getFocusType
    } = usePasswordStrengthIndicatorHook()

    useEffect(() => {
        const pwInput = document.querySelector('input[name="password"]')

        pwInput.addEventListener('focusin', () => {
            setFocusType(true)
        })
        pwInput.addEventListener('focusout', () => {
            setFocusType(false)
        })
    }, [])

    return (
        <div className={`password-indicator ${getFocusType() ? 'show' : ''}`}>
            <b>
                {testPassword(password) === 1 ? 'Слаба' : testPassword(password) === 2 ? 'Средна' : testPassword(password) === 3 ? 'Силна' : ''} парола
            </b>
            <div className={`loader ${testPassword(password) === 2 ? 'medium' : testPassword(password) === 3 ? 'strong' : ''}`}>

            </div>
            <p>
                {getRecommendationMessage(password)}
            </p>
        </div>
    )
}
export default PasswordStrengthIndicator