//components
import DayOfWeekCalendarColumn from "../partials/DayOfWeekCalendarColumn"

function CalendarHourly({ calendar }) {
  return (
    <ul className="calendar-type-1">
      <li className="small">
        <div className="day-of-week small"></div>
        <ul className="small">
          {Object.keys(calendar[0]?.events || {}).map((hour) => (
            <li key={hour}>
              <b>{hour}</b>
            </li>
          ))}
        </ul>
      </li>
      {calendar.map(({ date, events }) => (
        <DayOfWeekCalendarColumn key={date} events={events} date={date} />
      ))}
    </ul>
  )
}

export default CalendarHourly
