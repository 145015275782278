//components
import StoresList from "../partials/StoresList"

function Stores({ id }) {
  return (
    <div className="row">
      <div className="col">
        <h6>Всички обекти</h6>
        <div className="field">
          <StoresList clientId={id} />
        </div>
      </div>
    </div>
  )
}

export default Stores
