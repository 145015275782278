import ReactDOM from 'react-dom';
import React, { useEffect, useRef, useState } from "react";

function TableCol(props) {
    const ref = useRef(null);

    useEffect(() => {
        // console.log(props);

        if (!ref.current) {
            return;
        }

        // console.log(props)

        const setBoundingClientRect = () => {
            let rect = ref.current?.getBoundingClientRect();

            if (rect) {
                props.handleBoundingClientRect(rect);
            }
        }

        setBoundingClientRect();

        // let interval = setInterval(() => {
        //     setBoundingClientRect()
        // }, 1000);

        setTimeout(() => {
            setBoundingClientRect()
        }, 1000);

        // ref.current.addEventListener('mouseleave', setBoundingClientRect);

        return () => {
            // ref.current.removeEventListener('mouseleave', setBoundingClientRect);
            // clearInterval(interval);
        }

    }, [ref.current]);

    // ако е TableCol
    if (typeof props.children.type === 'function') {
        return (
            React.cloneElement(props.children, {
                // ref: ref,
                propRef: ref,
                onSort: props.onSort,
                activeSortKey: props.activeSortKey,
                activeSortDir: props.activeSortDir,
            })
        )
    }

    // ако е th
    return React.cloneElement(props.children, {
        ref: ref,
    })
}


function TableHeader(props) {

    const [state, setState] = useState({
        elementsRect: {},
        tableRect: null,
        wrapperRect: null,
        showFakeTable: false,
        showFakeScroll: false,
        zIndex: 0,
    });

    const activeSortKey = props.activeSortKey;
    const activeSortDir = props.activeSortDir;
    const onSort = props.onSort;

    const fakeWrapperRef = useRef(null); // table-responsive div
    const tableHeadRef = useRef(null); // thead
    const fakeTableScroll = useRef(null);

    // useEffect(() => {
    //     console.log(props.children)
    // }, [props.children]);

    useEffect(() => {
        if (!props.tableRef.current) {
            return;
        }

        // console.log(props.tableRef.current.parentNode)

        // console.log(props.tableRef.current)

        const onScroll = e => {
            getDimensions();
        };

        let tableWrapper = props.tableRef.current.parentNode;

        tableWrapper.addEventListener('scroll', onScroll)

        getDimensions();

        return () => {
            tableWrapper.removeEventListener('scroll', onScroll);
        }

    }, [props.tableRef.current]);

    useEffect(() => {

        let table = props.tableRef.current;
        let thead = tableHeadRef.current;

        let modal = props.tableRef.current.closest('.modal');

        const onResize = e => {
            getDimensions();
        };

        const onScroll = e => {

            let tableRect = table.getBoundingClientRect();
            let tableHeadRect = thead.getBoundingClientRect();

            // console.log(e)
            // console.log(tableRect)
            // console.log(window.scrollY);
            // console.log(e.currentTarget.scrollY)
            // console.log(window.innerHeight);
            // console.log(e.target)

            let scrollY = modal ? e.target.scrollTop : window.scrollY;

            // console.log(scrollY)

            let tableTopPos = tableRect.top + scrollY;
            let tableEndPos = tableRect.bottom - tableHeadRect.height + scrollY;

            // console.log(tableEndPos


            let showFakeTable = scrollY > tableTopPos && scrollY < tableEndPos;

            let showFakeScroll = window.innerHeight > tableRect.top + tableHeadRect.height && window.innerHeight < tableRect.bottom;

            if (scrollY > tableEndPos) {
                // showFakeScroll = false;
            }

            // console.log(tableTopPos, tableEndPos);

            setState(prev => ({
                ...prev,
                showFakeTable: showFakeTable,
                showFakeScroll: showFakeScroll,
                zIndex: modal ? getZIndex(e.target) : 0,
            }));

            if (showFakeTable) {
                setTimeout(() => {
                    getDimensions();
                }, 100);
            }
        }

        let tableRect = table.getBoundingClientRect();
        let tableHeadRect = thead.getBoundingClientRect();

        // дали първоначално да е показан хоризонталния скрол
        let showFakeScroll = window.innerHeight > tableRect.top + tableHeadRect.height && window.innerHeight < tableRect.bottom;

        if (showFakeScroll) {
            setState(prev => ({
                ...prev,
                showFakeScroll: showFakeScroll,
                zIndex: modal ? getZIndex(modal) : 0
            }));
        }

        if (modal) {
            modal.addEventListener('resize', onResize);
            modal.addEventListener('scroll', onScroll);
        } else {
            window.addEventListener('resize', onResize);
            window.addEventListener('scroll', onScroll);
        }

        return () => {
            if (modal) {
                modal.removeEventListener('resize', onResize);
                modal.removeEventListener('scroll', onScroll);
            } else {
                window.removeEventListener('resize', onResize);
                window.removeEventListener('scroll', onScroll);
            }
        }
    }, []);

    const getDimensions = () => {
        let el = props.tableRef.current;

        let rect = el.getBoundingClientRect();
        let rect2 = el.parentNode.getBoundingClientRect();

        // console.log('tableref');
        // console.log(el);
        // console.log(rect);
        // console.log(rect2);

        fakeWrapperRef.current.scrollLeft = el.parentNode.scrollLeft;
        fakeTableScroll.current.scrollLeft = el.parentNode.scrollLeft;

        setState(prev => ({
            ...prev,
            tableRect: rect,
            wrapperRect: rect2,
            tableClassList: el.classList
        }));
    }

    const getZIndex = element => {
        const style = getComputedStyle(element);

        const zIndex = style.zIndex;

        // console.log(zIndex);

        return zIndex;
    }

    const handleBoundingClientRect = (rect, index) => {
        // console.log(index);
        // console.log(rect);

        setState(prev => ({
            ...prev,
            elementsRect: {
                ...prev.elementsRect,
                [index]: rect
            }
        }));
    }

    // useEffect(() => {
    //     console.log(state)
    // }, [state]);

    // console.log(props.children);

    const renderFakeTableCols = (children, level = 0) => {
        // console.log(children)

        // console.log('render')

        return children.map((child, i) =>
            // ако е масив
            // това се случва ако колоните в таблицата са създадени чрез .map
            Array.isArray(child)
                ?
                renderFakeTableCols(child, level + 100)
                :
                // ако е TableCol
                typeof child.type === 'function'
                    ?
                    React.cloneElement(child, {
                        key: level + i,
                        style: {
                            width: state.elementsRect[level + i]?.width || 'auto'
                        },
                        activeSortKey: activeSortKey,
                        activeSortDir: activeSortDir,
                        onSort: onSort
                    })
                    :
                    // ако е th
                    React.cloneElement(child, {
                        key: level + i,
                        style: {
                            width: state.elementsRect[level + i]?.width || 'auto'
                        },
                    })
        )
    }

    const renderTableCols = (children, level = 0) => {
        return children.map((child, i) =>
            // ако е масив
            // това се случва ако колоните в таблицата са създадени чрез .map
            Array.isArray(child)
                ?
                renderTableCols(child, level + 100)
                :
                <TableCol
                    key={level + i}
                    handleBoundingClientRect={rect => handleBoundingClientRect(rect, level + i)}
                    activeSortKey={activeSortKey}
                    activeSortDir={activeSortDir}
                    onSort={onSort}
                >
                    {child}
                </TableCol>
        )
    }

    const handleScroll = e => {
        let scrollLeft = e.target.scrollLeft;

        let thead = tableHeadRef.current;

        let div = thead.closest('.table-responsive');

        if (div) {
            div.scrollLeft = scrollLeft;
        }
    }

    // console.log(props.children) 
    // console.log(state.elementsRect)

    return (
        <>
            {/* fake table */}
            {ReactDOM.createPortal(
                <div style={{ position: 'fixed', zIndex: state.zIndex + 1, top: 0, left: state.wrapperRect?.x || '0', display: state.showFakeTable ? 'block' : 'none' }}>
                    <div ref={fakeWrapperRef} className="table-responsive" style={{ width: state.wrapperRect?.width || '0', overflowX: 'hidden' }}>
                        <table className={state.tableClassList} style={{ width: state.tableRect?.width || '0' }}>
                            <thead style={{ borderBottom: '2px solid currentColor' }}>
                                <tr>
                                    {renderFakeTableCols(props.children)}
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>,
                document.body
            )}

            {/* original table */}
            <thead ref={tableHeadRef}>
                <tr>
                    {renderTableCols(props.children)}
                </tr>
            </thead>

            {/* fake scroll */}
            {ReactDOM.createPortal(
                <div style={{ position: 'fixed', zIndex: state.zIndex + 1, bottom: 0, left: state.wrapperRect?.x || '0', display: state.showFakeScroll ? 'block' : 'none' }}>
                    <div ref={fakeTableScroll} className="table-responsive" style={{ width: state.wrapperRect?.width || '0', overflowX: 'scroll' }} onScroll={handleScroll}>
                        <div style={{ width: state.tableRect?.width || '0', height: '1px' }}>

                        </div>
                    </div>
                </div>,
                document.body
            )}
        </>
    )
}

export default TableHeader;