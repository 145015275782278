import { useImperativeHandle, useState, forwardRef } from "react"

//components
import SaveButton from "components/misc/Button"
import RedditSelect from "components/misc/RedditSelect"
import RedditTextField from "components/misc/RedditTextField"
import DatePicker from "components/misc/DatePicker"
import CampaignClients from "./CampaignClients"
import AddEditCampaignFormTemplate from "./AddEditCampaignFormTemplate"

//libraries
import { Button, Checkbox, MenuItem } from "@material-ui/core"
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import moment from "moment"

//hooks
import useFetchClientStore from "../hooks/useFetchClientStore"

//images
import calendarBlue from "assets/img/customers/icons/calendar-blue.png"

function AddOrEditCampaign({ clientId }, ref) {
  const { getClient, loadingClient } = useFetchClientStore(clientId)

  const [state, setState] = useState({
    show: false,
    form: {
      id: null,
      clients: [],
      sendDate: moment().format("YYYY-MM-DD HH:mm:ss"),
    },
  })

  useImperativeHandle(ref, () => ({
    add: () => {
      handleShow()
    },
    edit: (id) => {
      handleShow(id)
    },
    hide: () => {
      hideModal()
    },
  }))

  function handleShow(id = null) {
    setState((prev) => ({
      ...prev,
      show: true,
      form: {
        ...prev.form,
        id,
      },
    }))
  }

  function handleClose(e) {
    if (e) e.preventDefault()

    hideModal()
  }

  function hideModal() {
    setState((prev) => ({
      ...prev,
      show: false,
    }))
  }

  function getClientsList() {
    const updatedClients = [...state.form.clients]

    if (getClient()?.info) updatedClients.unshift(getClient()?.info)

    return updatedClients
  }

  function handleChangeDateAndTimePicker(value) {
    const sendDate = moment(value["$d"]).format("YYYY-MM-DD HH:mm:ss")

    setState((prev) => ({
      ...prev,
      form: {
        ...prev.form,
        sendDate,
      },
    }))
  }

  return (
    <div className={`${state.show ? "visible" : ""} overlay`}>
      <div
        className={`popup-primary extra-large complex-modal storage-load-form-modal campaign-modal ${
          state.show ? "show" : ""
        }`}
      >
        <div className="header sky-blue">
          <b> {state.form.id ? "Редакция" : "Добавяне"} на Кампания</b>
          <p>
            Lorem Ipsum е елементарен примерен
            <br /> текст, използван в печатарската
          </p>
        </div>
        <div className="body">
          <div className="row">
            <div className="col small">
              <div className="row">
                <div className="col">
                  <h6>Основни</h6>
                  <div className="field">
                    <div className="row">
                      <div className="col">
                        <RedditTextField
                          label="Анкета за доволни клиенти"
                          fullWidth
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <RedditSelect label="Тип на кампанията" fullWidth>
                          <MenuItem value="1">Анкета</MenuItem>
                        </RedditSelect>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <DatePicker
                          reddit
                          fullWidth
                          label="Начало Дата / Час"
                          disableToolbar
                          format="dd/MM/yyyy"
                          //   value={state.data?.term_date_ymd || state.data?.date_ymd || today}
                          name="term_date"
                          // defaultValue={state.data?.date_ymd || today}
                          //   onChange={handleTermDateChange}
                        />
                      </div>
                      <div className="col">
                        <DatePicker
                          reddit
                          fullWidth
                          label="Край Дата / Час"
                          disableToolbar
                          format="dd/MM/yyyy"
                          //   value={state.data?.term_date_ymd || state.data?.date_ymd || today}
                          name="term_date"
                          // defaultValue={state.data?.date_ymd || today}
                          //   onChange={handleTermDateChange}
                        />
                      </div>
                    </div>
                    <hr />
                    <b>Включени клиенти</b>
                    <div className="row">
                      <CampaignClients clients={getClientsList()} editable />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <h6>Правила за изпращане</h6>
                  <div className="field">
                    <div className="row checkbox-row">
                      <Checkbox
                        color="primary"
                        // onChange={toggleIsAllDay}
                        value="all_day"
                        // checked={getForm().isAllDay}
                      />
                      <span
                        className="label"
                        //    onClick={toggleIsAllDay}
                      >
                        Изпращане на всички новорегистрирани
                      </span>
                    </div>
                    <div className="row checkbox-row">
                      <Checkbox
                        color="primary"
                        // onChange={toggleIsAllDay}
                        value="all_day"
                        // checked={getForm().isAllDay}
                      />
                      <span
                        className="label"
                        //    onClick={toggleIsAllDay}
                      >
                        Изпращане до всчики на{" "}
                        <Button className="date-and-time">
                          <img src={calendarBlue} alt="" />
                          {moment(state.form.sendDate).format(
                            "DD.MM.YYYY / HH:mm"
                          )}{" "}
                          ч.
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                              onChange={handleChangeDateAndTimePicker}
                            />
                          </LocalizationProvider>
                        </Button>
                      </span>
                    </div>
                    <div className="row checkbox-row">
                      <Checkbox
                        color="primary"
                        // onChange={toggleIsAllDay}
                        value="all_day"
                        // checked={getForm().isAllDay}
                      />
                      <span
                        className="label"
                        //    onClick={toggleIsAllDay}
                      >
                        Изпращане на всички клиенти с поръчки
                      </span>
                    </div>
                    <div className="row checkbox-row">
                      <Checkbox
                        color="primary"
                        // onChange={toggleIsAllDay}
                        value="all_day"
                        // checked={getForm().isAllDay}
                      />
                      <span
                        className="label"
                        //    onClick={toggleIsAllDay}
                      >
                        Изпращане на всички клиенти, оставили препоръка
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <h6>Съобщение</h6>
              <div className="field template-holder">
                <AddEditCampaignFormTemplate />
              </div>
            </div>
          </div>
        </div>

        <div className="footer">
          <Button className="cancel" onClick={handleClose}>
            Отказ
          </Button>
          <SaveButton
            // loading={state.loading}
            className="save"
            // onClick={(e) => formRef.current?.save(e)}
          />
        </div>
      </div>
    </div>
  )
}

export default forwardRef(AddOrEditCampaign)
