import { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom"
import moment from "moment"
import { FloatingLabel, Form, Dropdown, Row, Col } from 'react-bootstrap'
import { MenuItem } from '@material-ui/core'

//providers
import { useAuthDataContext } from "providers/Auth"

//helpers
import { useQuery } from 'helpers/Url'
import Api from 'helpers/Api'
import Loader from 'components/misc/Loader'
import Autocomplete from 'components/misc/Dropdown'
import DatePicker from 'components/partials/filter/DatePicker'
import RedditTextField from 'components/misc/RedditTextField'
import RedditSelect from 'components/misc/RedditSelect'

function Filter(props) {
    const history = useHistory()
    const query = useQuery()

    const auth = useAuthDataContext()

    const [depots, setDepots] = useState([])
    const [statuses, setStatuses] = useState([])

    const [filters, setFilters] = useState({
        show: false,
    })

    useEffect(() => {
        Api.get('store/revisions/statuses')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setStatuses(res.data)
                }
            })
    }, [])

    useEffect(() => {
        Api.get('store/revisions/depots')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setDepots(res.data)
                }
            })
    }, [auth.getUser().getStoreId()])

    const handleDateChange = (date, name) => {
        props.handleSearch(name, moment(date).format('YYYY-MM-DD'))
    }

    const handleSearch = e => {
        const name = e.target.name
        const value = e.target.value

        props.handleSearch(name, value)
    }

    const handleFromDateChange = e => {
        const name = e.target.name
        const value = e.target.value

        let date = value ? moment(value).format('YYYY-MM-DD') : ''

        props.handleSearch({
            created_from_date: date,
            created_to_date: date,
        })
    }

    const handleToDateChange = e => {
        const value = e.target.value

        let date = value ? moment(value).format('YYYY-MM-DD') : ''

        if (date && props.filter.created_from_date > date) {
            return
        }

        props.handleSearch({
            created_to_date: date,
        })
    }

    const handleChangeUser = data => {
        props.handleSearch({
            creator_id: data.id,
        })
    }

    const handleChangeAcceptor = data => {
        props.handleSearch({
            acceptor_id: data.id,
        })
    }

    const toggleFilters = () => {
        setFilters(prev => ({
            ...prev,
            show: !prev.show,
        }))
    }

    const getMoreFiltersValue = () => {
        let data = []

        if (props.filter.created_from_date) {
            data.push(1)
        }

        if (props.filter.created_to_date) {
            data.push(1)
        }

        if (props.filter.creator_id) {
            data.push(1)
        }

        if (props.filter.acceptor_id) {
            data.push(1)
        }

        if (props.filter.document_date) {
            data.push(1)
        }

        if (props.filter.deleted) {
            data.push(1)
        }

        if (data.length === 0) {
            return 'Няма избрани'
        }

        return data.length + ' избрани'
    }

    return (
        <div className="row filter">
            <div
                className="col"
            >
                <RedditTextField
                    fullWidth
                    label="Търсене..."
                    name="search"
                    value={props.filter.search || ''}
                    onChange={handleSearch}
                />
            </div>
            <div
                className="col"
            >
                <RedditSelect
                    name="status_id"
                    value={props.filter.status_id || 0}
                    onChange={handleSearch}
                    label="Статус"
                >
                    {auth.getUser().permission('storeloads_opened') && auth.getUser().permission('storeloads_closed') ?
                        <MenuItem value="0">
                            Всички
                        </MenuItem>
                        :
                        <>
                        </>
                    }
                    {auth.getUser().permission('storeloads_opened') ?
                        <MenuItem value={1}>
                            Отворени
                        </MenuItem>
                        :
                        <>
                        </>
                    }
                    {auth.getUser().permission('storeloads_closed') ?
                        <MenuItem value={2}>
                            Затворени
                        </MenuItem>
                        :
                        <>
                        </>
                    }
                </RedditSelect>
            </div>
            <div
                className="col"
            >
                <RedditSelect
                    name="status_id"
                    value={props.filter.status_id || 0}
                    onChange={handleSearch}
                    label="Склад"
                >
                    <MenuItem value={0}>
                        Всички
                    </MenuItem>
                    {depots.map(o =>
                        <MenuItem key={o.id} value={o.id}>
                            {o?.store?.translation?.name || o?.store?.name} - {o?.translation?.name || o.name}
                        </MenuItem>
                    )}
                </RedditSelect>
            </div>
        </div>
    )
}

export default Filter