import { useRef } from "react"

//images
import mailIcon from "assets/img/app/icons/send-colored.png"
import exportIcon from "assets/img/app/icons/export-colored.png"
import downloadIcon from "assets/img/app/icons/download-colored.png"
import downloadPdfIcon from "assets/img/app/icons/download-pdf.png"
import printIcon from "assets/img/app/icons/print-colored.png"

//libraries
import { Button, Tooltip } from "@material-ui/core"

//providers
import { useAppContext } from "providers/App"

//components
import Send from "components/partials/documents/Send"
import Export from "components/pages/documents/partials/export/Export"
import PrintButtonLayout from "./PrintButtonLayout"
import DownloadButtonLayout from "./DownloadButtonLayout"

function MultipleActions({
  getSelectedCount,
  getDocuments,
  getSelectedDocumentsIds,
  typeId,
  allDocumentsFromAllPagesSelected,
  totalCount,
  setAllDocumentsFromAllPagesSelected,
}) {
  const sendModalRef = useRef()
  const exportModalRef = useRef()

  const app = useAppContext()
  const count = Number(getSelectedCount())

  const success = (msg) => {
    app.handleSuccess(msg)
  }

  const error = (msg) => {
    app.handleError(msg)
  }

  const handleSend = (id) => {
    let email = null

    if (!Array.isArray(id)) {
      const document = (getDocuments() || []).find(
        (d) => Number(d.id) === Number(id)
      )

      if (document) {
        email = document?.customerinfo?.email
      }
    }

    const modal = sendModalRef.current
    modal.open(id, email)
    modal.onSuccess(() => {
      success()
    })
    modal.onError(() => {
      error()
    })
  }
  const handleSendSelected = () => {
    const ids = getSelectedDocumentsIds()

    if (ids.length) handleSend(ids)
  }

  const handleExport = (id) => {
    const modal = exportModalRef.current

    modal.open(id)
    modal.onSuccess(() => {
      success("Експортирането завърши успешно!")
    })
    modal.onError(() => {
      error()
    })
  }
  const handleExportSelected = () => {
    const ids = getSelectedDocumentsIds()

    if (ids.length) handleExport(ids)
  }

  const getSingleFileDownloadUrl = () => {
    const id = getSelectedDocumentsIds()[0] || null

    const downloadPdfUrl = document
      .querySelector(`table.type-outer tr[data-id="${id}"]`)
      .getAttribute("data-download-pdf-url")

    return downloadPdfUrl
  }

  return (
    <>
      <Send ref={sendModalRef} documentsType="documents" />
      <Export
        ref={exportModalRef}
        typeId={typeId}
        getSelectedCount={getSelectedCount}
      />
      <div className="multiple-actions">
        {totalCount ? (
          <div className="top">
            <div className="selected-count">
              Избрани: <span>{count}</span>
            </div>
            {!allDocumentsFromAllPagesSelected ? (
              <Button onClick={setAllDocumentsFromAllPagesSelected}>
                Избери всички <i>{totalCount}</i>
              </Button>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <div
            className="top"
            style={{
              marginBlock: 0,
              justifyContent: "center",
              minHeight: "35px",
            }}
          >
            <div className="selected-count">Избрани: {count}</div>
          </div>
        )}
        <ul>
          <li>
            <Button
              onClick={() => {
                handleSendSelected()
              }}
            >
              <img src={mailIcon} />
              Изпращане
            </Button>
          </li>
          <li>
            <DownloadButtonLayout
              multipleDocumentsIds={
                count > 1 ? getSelectedDocumentsIds() : null
              }
              downloadUrl={count === 1 ? getSingleFileDownloadUrl() : null}
            >
              <Button>
                <img src={count === 1 ? downloadPdfIcon : downloadIcon} />
                Изтегляне
              </Button>
            </DownloadButtonLayout>
          </li>
          <li>
            <Button
              onClick={() => {
                handleExportSelected()
              }}
            >
              <img
                src={exportIcon}
                style={{
                  marginRight: "-5px",
                }}
              />
              Експорт
            </Button>
          </li>
          <li>
            <PrintButtonLayout multipleDocumentsIds={getSelectedDocumentsIds()}>
              <Button>
                <img
                  src={printIcon}
                  style={{
                    marginRight: "-5px",
                  }}
                />
                Принтиране
              </Button>
            </PrintButtonLayout>
          </li>
        </ul>
      </div>
    </>
  )
}

export default MultipleActions
