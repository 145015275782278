import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom"
import moment from "moment"
import { FloatingLabel, Form, Dropdown, Row, Col } from 'react-bootstrap'
import { CaretLeft, CaretDown, CaretRight, CaretDownFill } from 'react-bootstrap-icons'
import { MenuItem } from '@material-ui/core'

//providers
import { useAuthDataContext } from "providers/Auth"

//helpers
import { useQuery } from 'helpers/Url'
import Api from 'helpers/Api'
import Loader from 'components/misc/Loader'
import Autocomplete from 'components/misc/Dropdown'
import DatePicker from 'components/partials/filter/DatePicker'
import RedditTextField from 'components/misc/RedditTextField'
import RedditSelect from 'components/misc/RedditSelect'

function Filter(props) {
    const history = useHistory()
    const query = useQuery()

    const auth = useAuthDataContext()

    const [operations, setOperations] = useState([])
    const [depots, setDepots] = useState([])
    const [documentTypes, setDocumentTypes] = useState([])
    const [paymentMethods, setPaymentMethods] = useState([])

    const [filters, setFilters] = useState({
        show: false,
    })

    const hasOpenedPermission = auth.getUser().permission('storeloads_opened')
    const hasClosedPermission = auth.getUser().permission('storeloads_closed')

    useEffect(() => {
        Api.get('store/storeloads/operations')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setOperations(res.data)
                }
            })

        Api.get('store/storeloads/documents-types')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setDocumentTypes(res.data)
                }
            })

        Api.get('store/storeloads/payment-methods')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setPaymentMethods(res.data)
                }
            })
    }, [])

    useEffect(() => {
        Api.get('store/storeloads/depots', {
            params: {
                all: 1,
            }
        }).then(res => {
            if (Array.isArray(res.data)) {
                setDepots(res.data)
            }
        })
    }, [auth.getUser().getStoreId()])

    const handleDateChange = (date, name) => {
        props.handleSearch(name, date ? moment(date).format('YYYY-MM-DD') : '')
    }

    const handleSearch = e => {
        const name = e.target.name
        const value = e.target.value

        props.handleSearch(name, value)
    }

    const handleFromDateChange = e => {
        const name = e.target.name
        const value = e.target.value

        let date = value ? moment(value).format('YYYY-MM-DD') : ''

        props.handleSearch({
            created_from_date: date,
            created_to_date: date,
        })
    }

    const handleToDateChange = e => {
        const value = e.target.value

        let date = value ? moment(value).format('YYYY-MM-DD') : ''

        if (date && props.filter.created_from_date > date) {
            return
        }

        props.handleSearch({
            created_to_date: date,
        })
    }

    const handleChangeSupplier = data => {
        props.handleSearch({
            supplier_id: data.id,
        })
    }

    const handleChangeUser = data => {
        props.handleSearch({
            user_id: data.id,
        })
    }

    const handleChangeArticle = data => {
        props.handleSearch({
            article_id: data?.id || '',
        })
    }

    const handleChangeDepot = data => {
        let ids = data.map(d => d.id)

        props.handleSearch({
            depot_id: ids
        })
    }

    const toggleFilters = () => {
        setFilters(prev => ({
            ...prev,
            show: !prev.show,
        }))
    }

    const getMoreFiltersValue = () => {
        let data = []

        if (props.filter.created_from_date) {
            data.push(1)
        }

        if (props.filter.created_to_date) {
            data.push(1)
        }

        if ((props.filter.depot_id || []).length) {
            data.push(1)
        }

        if (props.filter.user_id) {
            data.push(1)
        }

        if (props.filter.document_type_id) {
            data.push(1)
        }

        if (props.filter.document_no) {
            data.push(1)
        }

        if (props.filter.document_date) {
            data.push(1)
        }

        if (props.filter.supplier_id) {
            data.push(1)
        }

        if (props.filter.paid_status) {
            data.push(1)
        }

        if (props.filter.deleted) {
            data.push(1)
        }

        if (data.length === 0) {
            return 'Няма избрани'
        }

        return data.length + ' избрани'
    }

    return (
        <div className="row filter">
            <div
                className="col"
            >
                <div
                    className="col"
                >
                    <RedditTextField
                        fullWidth
                        label="Търсене..."
                        name="search"
                        value={props.filter.search || ''}
                        onChange={handleSearch}
                    />
                </div>
            </div>
            <div
                className="col"
            >
                <RedditSelect
                    name="status_id"
                    value={props.filter.status_id || 0}
                    onChange={handleSearch}
                    label="Статус"
                >
                    {auth.getUser().permission('storeloads_opened') && auth.getUser().permission('storeloads_closed') ?
                        <MenuItem value="0">
                            Всички
                        </MenuItem>
                        :
                        <>
                        </>
                    }
                    {auth.getUser().permission('storeloads_opened') ?
                        <MenuItem value={1}>
                            Отворени
                        </MenuItem>
                        :
                        <>
                        </>
                    }
                    {auth.getUser().permission('storeloads_closed') ?
                        <MenuItem value={2}>
                            Затворени
                        </MenuItem>
                        :
                        <>
                        </>
                    }
                </RedditSelect>
            </div>
            <div
                className="col"
            >
                <RedditSelect
                    name="operation_id"
                    value={props.filter.operation_id || 0}
                    onChange={handleSearch}
                    label="Операция"
                >
                    <MenuItem value="0">
                        Всички
                    </MenuItem>
                    {operations.map(o =>
                        <MenuItem key={o.id} value={o.id}>
                            {o?.translation?.name || o.name}
                        </MenuItem>
                    )}
                </RedditSelect>
            </div>
            <div
                className="col"
            >
                <Autocomplete
                    variant="filled"
                    inputPlaceholder="Артикул"
                    url="store/autocomplete/articles"
                    inputIdName="article_id"
                    // inputValue={state.data?.supplier?.name}
                    inputIdValue={props.filter.article_id || ''}
                    onChange={handleChangeArticle}
                    onInputChange={handleChangeArticle}
                    renderText={data => {
                        return (
                            <div style={{ display: 'flex', width: '100%' }}>
                                <div style={{ width: 'max-content', marginRight: '10px' }}>
                                    {data.id}
                                </div>
                                <div>
                                    {data.name}
                                    <br />
                                    {data.article_name}
                                </div>
                            </div>
                        )
                    }}
                    renderInputText={data => data.article_name}
                />
            </div>
        </div>
    )
}

export default Filter
