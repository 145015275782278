import { forwardRef, useEffect, useImperativeHandle, useRef, useState, Fragment } from 'react'

//plugins
import { Button, } from '@material-ui/core'

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useAppContext } from 'providers/App'

//misc
import SaveButton from 'components/misc/Button'
import DatePicker from 'components/misc/DatePicker'
import moment from 'moment'

function Activate(props, ref) {

    const defaultData = {
        date: moment().toDate(),
    }

    const app = useAppContext()

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null
    })

    const [validations, setValidations] = useValidation()

    const formRef = useRef(null)

    useImperativeHandle(ref, () => ({
        open: (id) => {
            handleShow(id)
        },
        hide: () => {
            hide()
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        }
    }))

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }))

        onEntering?.()
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }))

        onExiting?.()
    }

    const handleShow = (id) => {
        setState(prev => ({
            ...prev,
            data: defaultData,
            id: id,
        }))

        show()
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault()
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }))

        setValidations(null)

        hide()
    }

    const handleSave = () => {
        if (state.loading) {
            return
        }

        setState(prev => ({
            ...prev,
            loading: true,
        }))

        setValidations(null)

        let url = 'store/subscriptions/activate'

        let data = new FormData(formRef.current)

        data.append('id', state.id)

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
            }

            handleClose()
        }).catch(error => {
            const _err = error.response

            if (_err && _err.status && _err.status === 422) {
                setValidations(_err.data.errors)
            } else {
                app.showError()
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }))
        })
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleCheckboxChange = e => {
        const name = e.target.name

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }))
    }

    const handleDateChange = (date) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                date: date,
            }
        }))
    }

    return (
        <>

            <div className={`${state.show ? "visible" : ""} overlay`}>
                <div
                    className={`popup-primary medium complex-modal storage-load-form-modal price-modal ${state.show ? "show" : ""}`}
                >
                    <div className="header light-brown">
                        <b>
                            Активиране на абонамент {state.id && '(ID: ' + state.id + ')'}
                        </b>
                        <p>
                            Lorem Ipsum е елементарен примерен<br /> текст, използван в печатарската
                        </p>
                    </div>
                    <div className="body">
                        <form
                            onSubmit={handleSave}
                            ref={formRef}
                            style={{
                                width: '100%'
                            }}
                        >
                            <div className="row">
                                <div className="col">
                                    <h6>
                                        Дата
                                    </h6>
                                    <div className="field">
                                        <DatePicker
                                            reddit
                                            name="date"
                                            value={state.data?.date ? moment(state.data.date).format('YYYY-MM-DD') : ''}
                                            onChange={e => handleDateChange(e.target.value)}
                                            variant="filled"
                                            label="От дата"
                                            error={Boolean(validations && validations.date)}
                                            helperText={validations && validations.date && (validations.date[0] || validations.date)}
                                            style={{
                                                // height: '41px',
                                                width: '100%'
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                    <div className="footer">
                        <Button className="cancel" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            onClick={handleSave}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default forwardRef(Activate)
