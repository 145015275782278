import { trans } from "providers/Translation";

export const getPaymentPeriods = (calendar_type, payment_period_type, period, period_type) => {
    const periods = [];

    // от момента на активация
    if (calendar_type === 1) {

        // плащане по ден
        if (payment_period_type === 1) {
            // по дни
            if (period_type === 1) {
                for (let i = 1; i <= period; i++) {
                    periods.push({
                        id: i,
                        name: `ден ${i}`,
                    })
                }
            }

            // по седмици
            if (period_type === 2) {
                for (let i = 1; i <= period * 7; i++) {
                    periods.push({
                        id: i,
                        name: `ден ${i}`,
                    })
                }
            }

        }

        // плащане по седмица
        if (payment_period_type === 2) {
            // по седмици
            if (period_type === 2) {
                for (let i = 1; i <= period; i++) {
                    periods.push({
                        id: i,
                        name: `седмица ${i}`,
                    })
                }
            }

            // по месеци
            if (period_type === 3) {
                for (let i = 1; i <= period * 4.34524; i++) {
                    periods.push({
                        id: i,
                        name: `седмица ${i}`,
                    })
                }
            }

            // по години
            if (period_type === 4) {
                for (let i = 1; i <= period * 52.1429; i++) {
                    periods.push({
                        id: i,
                        name: `седмица ${i}`,
                    })
                }
            }
        }

        // плащане по месец
        if (payment_period_type === 3) {
            // по месеци
            if (period_type === 3) {
                for (let i = 1; i <= period; i++) {
                    periods.push({
                        id: i,
                        name: `месец ${i}`,
                    })
                }
            }

            // по години
            if (period_type === 4) {
                for (let i = 1; i <= period * 12; i++) {
                    periods.push({
                        id: i,
                        name: `месец ${i}`,
                    })
                }
            }
        }

        // плащане по година 
        if (payment_period_type === 4) {
            // по години
            if (period_type === 4) {
                for (let i = 1; i <= period; i++) {
                    periods.push({
                        id: i,
                        name: `година ${i}`,
                    })
                }
            }
        }
    }

    // календарен период
    if (calendar_type === 2) {
        // плащане по ден
        if (payment_period_type === 1) {
            // по дни
            if (period_type === 1) {
                for (let i = 1; i <= period; i++) {
                    periods.push({
                        id: i,
                        name: `ден ${i}`,
                    })
                }
            }

            // по седмици
            if (period_type === 2) {
                for (let i = 1; i <= (period >= 1 ? 7 : period); i++) {
                    periods.push({
                        id: i,
                        name: `ден ${i}`,
                    })
                }
            }
        }

        // плащане по седмица
        if (payment_period_type === 2) {

            // по седмици
            if (period_type === 2) {
                for (let i = 1; i <= 1; i++) {
                    periods.push({
                        id: i,
                        name: `седмица`,
                    })
                }
            }

            // по месеци
            if (period_type === 3) {
                for (let i = 1; i <= period * 4.34524; i++) {
                    periods.push({
                        id: i,
                        name: `седмица ${i}`,
                    })
                }
            }

            // по години
            if (period_type === 4) {
                for (let i = 1; i <= 52.1429; i++) {
                    periods.push({
                        id: i,
                        name: `седмица ${i}`,
                    })
                }
            }
        }

        // плащане по месец
        if (payment_period_type === 3) {
            // по месеци
            if (period_type === 3) {
                for (let i = 1; i <= (period > 12 ? 12 : period); i++) {
                    periods.push({
                        id: i,
                        name: trans('dates.months')[i - 1]
                    })
                }
            }

            // по години: яну-дек
            if (period_type === 4) {
                for (let i = 1; i <= 12; i++) {
                    periods.push({
                        id: i,
                        name: trans('dates.months')[i - 1]
                    })
                }
            }
        }

        // плащане по година 
        if (payment_period_type === 4) {
            // по години
            if (period_type === 4) {
                for (let i = 1; i <= period; i++) {
                    periods.push({
                        id: i,
                        name: `година ${i}`,
                    })
                }
            }
        }
    }


    // еднократно 
    if (payment_period_type === 5) {
        for (let i = 1; i <= 1; i++) {
            periods.push({
                id: i,
                name: `-`,
            })
        }
    }

    return periods;
}