import { useRef } from "react"
import { useHistory } from "react-router-dom"

//helpers
import { splitLongNumbers } from "helpers/numbers"

//libraries
import Skeleton from "react-loading-skeleton"
import moment from "moment"
import { Button } from "@material-ui/core"

//components
import NoDataFound from "components/misc/NoDataFound"
import CreateSaleMenu from "./CreateSaleMenu"
import CreateDocumentMenu from "components/pages/dashboard/components/CreateDocumentMenu"
import StoreOut from "components/pages/storeouts/StoreOut"

//hooks
import useInvoiceFromClient from "hooks/useInvoiceFromClient"

//constants
import { INVOICE } from "constants/invoices"

//images
import dropArrowIcon from "assets/img/app/icons/drop-arrow-white.png"

//providers
import { useAuthDataContext } from "providers/Auth"
import Refs from "Refs"

function AllActivitiesDashboardTable({
  loadingActivities,
  getActivities,
  clientId,
  refetchData,
  handleClose,
}) {
  const { getUser } = useAuthDataContext()

  const navigate = useHistory()
  const { createInvoiceFromClient, isLoading } = useInvoiceFromClient()

  const createDocumentButtonRef = useRef(null)
  const createDocumentMenuRef = useRef(null)
  const createSaleButtonRef = useRef(null)
  const createSaleMenuRef = useRef(null)
  const storeOutModalRef = useRef(null)

  function handleCreateDocument(type = INVOICE) {
    if (type.target) return

    createInvoiceFromClient(clientId, type).then((res) => {
      if (res) {
        navigate.push("/invoices", {
          data: res,
          mode: "new",
        })

        handleClose()
      }
    })
  }

  function handleClickAddDocumentButton(e) {
    if (!e.target.classList.contains("menu")) handleCreateDocument(INVOICE)
  }

  function handleCreateSale() {
    navigate.push(`/accounts/new?client_id=${clientId}`)

    handleClose()
  }

  function handleExternalAddSale() {
    const modal = storeOutModalRef.current

    modal.add(null, clientId)
    modal.onSuccess(() => {
      modal.hide()
      refetchData()
    })
  }

  function handleClickAddSaleButton(e) {
    if (!e.target.classList.contains("menu")) handleCreateSale()
  }

  function handleClickAddSubscriptionButton() {
    const modal = Refs.getInstance().getRef('newSubscription');

    modal.add(clientId)
    modal.onSuccess(() => {
      modal.hide()
      refetchData()
    })
  }

  function createDifferentDocument() {
    createDocumentMenuRef.current.open()
  }

  function createDifferentSale() {
    createSaleMenuRef.current.open()
  }

  function renderAdditionalButton(centered = false) {
    return (
      <>
        <div
          className="row"
          style={centered ? { justifyContent: "center" } : {}}
        >
          <div
            className="col"
            style={{
              width: "fit-content",
              flex: "initial",
            }}
          >
            {getUser().hasStoreAccess() ? (
              <>
                <CreateSaleMenu
                  createSaleButtonRef={createSaleButtonRef}
                  ref={createSaleMenuRef}
                  externalAddSale={handleExternalAddSale}
                />
                <Button
                  className={`add with-dropdown new-btn-primary ${isLoading ? "disabled" : ""
                    }`}
                  ref={createSaleButtonRef}
                  onClick={handleClickAddSaleButton}
                >
                  Нова продажба
                  <i className="menu" onClick={createDifferentSale}>
                    <img src={dropArrowIcon} />
                  </i>
                </Button>
              </>
            ) : (
              <></>
            )}
          </div>
          <div
            className="col"
            style={{
              width: "fit-content",
              flex: "initial",
            }}
          >
            <CreateDocumentMenu
              createDocumentButtonRef={createDocumentButtonRef}
              ref={createDocumentMenuRef}
              externalAddDocument={handleCreateDocument}
            />
            <Button
              className={`add with-dropdown new-btn-primary ${isLoading ? "disabled" : ""
                }`}
              ref={createDocumentButtonRef}
              onClick={handleClickAddDocumentButton}
            >
              Фактура
              <i className="menu" onClick={createDifferentDocument}>
                <img src={dropArrowIcon} />
              </i>
            </Button>
          </div>
          {getUser().hasSubscriptionAccess() ? (
            <div
              className="col"
              style={{
                width: "fit-content",
                flex: "initial",
              }}
            >
              <Button
                className="add new-btn-primary"
                onClick={handleClickAddSubscriptionButton}
              >
                Абонамент
              </Button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </>
    )
  }

  if (loadingActivities) {
    return (
      <div
        style={{
          width: "100%",
        }}
      >
        <Skeleton count={1} height={35} />
        <Skeleton count={1} height={35} />
        <Skeleton count={1} height={35} />
        <Skeleton count={1} height={35} />
        <Skeleton count={1} height={35} />
      </div>
    )
  }

  if (!loadingActivities && !getActivities().length) {
    return (
      <>
        <NoDataFound
          style={{
            marginBottom: "-10px",
          }}
        >
          <h2>Няма налични записи</h2>
          <p>
            Може да добавите нова продажба, фактура или абонамент от бутона
            по-долу.
          </p>
          {renderAdditionalButton(true)}
        </NoDataFound>
      </>
    )
  }

  return (
    <>
      <table className="type-outer">
        <thead>
          <tr>
            <th>№</th>
            <th>Тип</th>
            <th>Дата</th>
            <th className="text-right">Сума</th>
          </tr>
        </thead>
        <tbody>
          {getActivities().map((activity) => (
            <tr key={activity.id}>
              <td
                style={{
                  cursor: "default",
                }}
              >
                {activity.no}
              </td>
              <td
                style={{
                  cursor: "default",
                }}
              >
                {activity.subtype}
              </td>
              <td
                style={{
                  cursor: "default",
                }}
              >
                {moment(activity.date, "YYYY-MM-DDTHH:mm").format("DD.MM.YYYY")}{" "}
                г.
              </td>
              <td
                style={{
                  cursor: "default",
                }}
                className="text-right"
              >
                {splitLongNumbers((activity.price || 0).toFixed(2))}{" "}
                {activity.currency.name}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <hr />
      {renderAdditionalButton()}
      <StoreOut ref={storeOutModalRef} noRedirect={true} />
    </>
  )
}

export default AllActivitiesDashboardTable
