export function shortenToMaximumFirstAndLastName(name = '') {
    if (!name) {
        return ''
    }

    const splitedName = name.split(' ')
    if (splitedName.length === 1) {
        return splitedName[0]
    }

    if (splitedName.length > 1) {
        return `${splitedName[0]} ${splitedName[splitedName.length - 1]}`
    }

    return name
}