import { useEffect, useState, useRef, Fragment } from 'react'

//plugins
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import axios from 'axios'
import { emulateTab } from 'emulate-tab'

//providers
import { useAuthDataContext } from 'providers/Auth'
import { useAppContext } from 'providers/App'
import { useDocumentContext } from './Provider'
import { useDocumentsTabsContext } from 'providers/DocumentsTabs'

//constants
import { PAYMENT_EXTRA_DATA } from 'constants/PaymentMethod'
import { DOCUMENT_TYPE } from 'constants/Document'
import { CUSTOMER_TYPE } from 'constants/Customer'

//partials
import MakePaid from './partials/payment/MakePaid'
import Send from 'components/partials/documents/Send'
import Preview from './partials/Preview'
import ProductRowInput from './partials/ProductRowInput'
import CustomerInput from './partials/CustomerInput'
import DelivererBankDetails from './partials/DelivererBankDetails'
import CustomerInputMain from './partials/CustomerInputMain'
import NextNumber from './partials/NextNumber'
import SelectUnit from './partials/SelectUnit'
import DocumentNumber from './partials/DocumentNumber'

//modals
import Question from '../../modals/Question'
import Success from '../../modals/Success'
import Error from '../../modals/Error'
import Epay from 'components/pages/settings/partials/payments/partials/Epay'
import PayPal from 'components/pages/settings/partials/payments/partials/PayPal'
import Stripe from 'components/pages/settings/partials/payments/partials/Stripe'
import Bank from 'components/pages/settings/partials/payments/partials/Bank'

//Misc
import TextField from 'components/misc/TextField'
import RedditTextField from 'components/misc/RedditTextField'
import RedditSelect from 'components/misc/RedditSelect'
import DatePicker from 'components/misc/DatePicker'
import Autocomplete from '../../misc/Autocomplete'
import SaveButton from '../../misc/Button'
import ClientConfig from 'components/misc/ClientConfig'

//MUI components
import { MenuItem, Button, InputAdornment, Tooltip, Menu, Checkbox, Select } from '@material-ui/core'

//helpers
import Api from 'helpers/Api'
import { useNestedState } from 'helpers/NestedState'
import { useValidation } from 'helpers/Validation'
import { usePresenceSocketChannel } from 'helpers/Socket'
import { shortenToMaximumFirstAndLastName } from './helpers/shorten'
import { splitLongNumbers } from 'helpers/numbers'

//styles
import 'assets/scss/pages/documents.scss'

//images
import settingsIcon from 'assets/img/app/icons/settings.png'
import dropArrowIcon from 'assets/img/app/icons/drop-arrow-white.png'
import checkFilledIcon from 'assets/img/app/icons/check-filled.png'
import initialCleintIcon from 'assets/img/documents/icons/initial-client.png'
import checkIcon from 'assets/img/documents/icons/check.png'
import warningIcon from 'assets/img/documents/icons/warning.png'
import loadingImage from 'assets/img/loader-2.svg'
import closeSmallIcon from 'assets/img/app/icons/close-small.png'

//hooks
import useCheckPaymentAvailability from './hooks/useCheckPaymentAvaiability'
import Refs from 'Refs'
import CustomerDeepData from './partials/CustomerDeepData'
import * as addressing from './helpers/addressing'

const TABLE_COLUMNS = {
    number: {
        visible: true,
        ableToHide: true,
        name: '№',
    },
    products: {
        visible: true,
        ableToHide: false,
        name: 'АРТИКУЛИ / УСЛУГИ',
    },
    quantity: {
        visible: true,
        ableToHide: true,
        name: 'К-ВО',
    },
    // unit: {
    //     visible: true,
    //     ableToHide: true,
    //     name: 'МЯРКА',
    // },
    singlePrice: {
        visible: true,
        ableToHide: false,
        name: 'ЕД. ЦЕНА',
    },
    discount: {
        visible: true,
        ableToHide: true,
        name: 'ОТСТЪПКА',
    },
    price: {
        visible: true,
        ableToHide: false,
        name: 'СТОЙНОСТ',
    }
}

const timestamp = moment().unix()

function Index(props) {
    const today = moment().format('YYYY-MM-DD')
    const auth = useAuthDataContext()
    const app = useAppContext()

    const company = auth?.getUser()?.getCompany()
    const companyId = auth?.getUser()?.getCompany().getId()

    const {
        hasWritePermission
    } = props

    const formRef = useRef(null)
    const successModalRef = useRef(null)
    const errorModalRef = useRef(null)
    const documentNumberInputRef = useRef(null)
    const deleteModalRef = useRef(null)
    const makePaidModalRef = useRef(null)
    const makeUnpaidModalRef = useRef(null)
    const sendModalRef = useRef(null)
    const previewModalRef = useRef(null)
    const vatReasonSelectRef = useRef(null)
    const epayConfigModalRef = useRef(null)
    const bankConfigModalRef = useRef(null)
    const paypalConfigModalRef = useRef(null)
    const stripeConfigModalRef = useRef(null)
    const optionsMenuButtonRef = useRef(null)
    const colNameRef = useRef(null)
    const productRowInputRef = useRef(null)
    const relatedNoFieldRef = useRef(null)
    const viesRequestTimeoutRef = useRef(null)
    const calculationRequestTimeoutRef = useRef(null)
    const handleSelectFieldAfterProductTimeoutRef = useRef(null)
    const setOfferIntroTimeoutRef = useRef(null)

    const { isPaymentAvailable } = useCheckPaymentAvailability()

    const { getActiveTabTypeId, getActiveSubtab, handleSetSubtab, handleAddNewSubtab, handleChangeTabs, handleSetTabUnrepeatableActions, getTabUnrepeatableActions } = useDocumentsTabsContext()
    const context = useDocumentContext()
    const { refreshPreview } = context?.state || {}

    const { html_url, updated_at } = getActiveSubtab()

    const [paymentMethods, setPaymentMethods] = useState({
        data: [],
        loading: true,
    })

    const [state, setState] = useNestedState({
        loadingData: false,
        loading: false,
        disableSave: true,
        data: {
            customer_type_id: CUSTOMER_TYPE.COMPANY,
            vat: company?.getVatNumber() ? '20' : '0',
            rows: [{
                group: 'Група 1',
            }],
            ...getActiveSubtab(),
        },
        currency: 'BGN',
        triggerPriceCalculation: false,
        calculationRequest: null,
        nextNumberRequest: null,
        nextNumbersRequest: null,
        viesRequest: null,
        addNote: false,
        removeVat: company?.getVatNumber() ? false : true,
        minimizeDelivererFields: true,
        tableColumns: TABLE_COLUMNS,
        showColumnManager: false,
        triggerEditing: false,
        saveButtonMenu: null,
        autoSend: false,
        vatLoading: true,
        renewCheckVat: false,
        showClientHiddenFields: false,
        showCustomVatField: false,
    })

    const [focusedInputName, setFocusedInputName] = useState(null)

    const [validations, setValidations] = useValidation()

    const clientName = state.data.customerinfo?.name || ''
    const clientDataName = state.data.customer?.name || ''
    const clientEik = state.data.customerinfo?.eikegn || ''

    // const addMode = !docId ? true : false;
    const docId = state.data?.id
    const addMode = state.data.mode === 'new'
    const editMode = state.data.mode === 'edit'
    const previewMode = state.data.mode === 'preview'

    const titles = {
        [DOCUMENT_TYPE.OFFER]: {
            add: 'Нова',
            edit: 'Редактиране',
            type: 'Оферта'
        },
        [DOCUMENT_TYPE.PROFORMA]: {
            add: 'Нова',
            edit: 'Редактиране',
            type: 'Проформа'
        },
        [DOCUMENT_TYPE.INVOICE]: {
            add: 'Нова',
            edit: 'Редактиране',
            type: 'Фактура'
        },
        [DOCUMENT_TYPE.CREDIT_NOTE]: {
            add: 'Ново',
            edit: 'Редактиране',
            type: 'Кредитно известие',
            shortType: 'Кр. известие',
            fontSize: '30px'
        },
        [DOCUMENT_TYPE.DEBIT_NOTE]: {
            add: 'Ново',
            edit: 'Редактиране',
            type: 'Дебитно известие',
            shortType: 'Дб. известие',
            fontSize: '30px'
        },
        [DOCUMENT_TYPE.PROTOCOL]: {
            add: 'Нов',
            edit: 'Редактиране',
            type: 'Протокол'
        },
        [DOCUMENT_TYPE.STOCK]: {
            add: 'Нова',
            edit: 'Редактиране',
            type: 'Стокова разписка',
            shortType: 'Ст. разписка'
        },
    }

    useEffect(() => {
        window.addEventListener('keypress', function (e) {
            if (e.key === "Enter") {
                e.preventDefault()
                // emulateTab()
            }
        })
    }, [])

    useEffect(() => {
        if (getActiveSubtab().id === state.data.id) return

        setState(prev => ({
            ...prev,
            data: getActiveSubtab()
        }))
    }, [getActiveSubtab()])

    useEffect(() => {
        handleSetSubtab(getActiveTabTypeId(), docId, state.data)
    }, [state.data, Number(state.data.no), state.data.date_ymd])

    useEffect(() => {
        if (state.data.payment_method_id === null && state.data?.company?.banks?.length && paymentMethods.data.length) {
            setTimeout(() => {
                setPaymentMethod(paymentMethods.data[1])
            }, 1000)
        }
    }, [state.data?.company?.banks, paymentMethods])

    useEffect(() => {
        if (previewMode) return
        if (!channel) return

        channel.whisper('editing', {
            user: auth.getUser().getData(),
            id: docId
        })

        return () => {
            channel.whisper('stopEditing', {
                user: auth.getUser().getData(),
                id: docId
            })
        }
    }, [auth, channel, docId, state.triggerEditing, editMode])

    useEffect(() => {
        if (previewMode) return
        if (!channel) return

        channel.listenForWhisper('triggerEditing', e => {
            setState(prev => ({
                ...prev,
                triggerEditing: moment().unix()
            }))
        })
    }, [channel, editMode])

    useEffect(() => {
        if (editMode || previewMode) loadData(true)
    }, [state.data.mode, refreshPreview])

    useEffect(() => {
        if (state.data) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    author: shortenToMaximumFirstAndLastName(state.data?.companyaddress?.mol || ''),
                }
            }))

            if (state.data.recipient) {
                setState(prev => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        recipient: null,
                    }
                }))
            }
        }
    }, [state.data.customeraddress?.id, editMode])

    useEffect(() => {
        if (editMode) return
        const clientNameField = document.querySelector(`.t-${timestamp} input[name="customerinfo[name]"]`)
        if (showAllClientFields()) {
            const clientEIKField = document.querySelector(`.t-${timestamp} input[name="customerinfo[eikegn]"]`)
            if (clientEik?.length || clientName?.length || clientDataName?.length) {
                if (!(clientName?.length || clientDataName?.length)) clientNameField?.focus()
                if (!clientEik?.length) clientEIKField?.focus()
            }
        } else {
            clientNameField?.focus()
        }
    }, [showAllClientFields(), editMode])

    useEffect(() => {
        if (!addMode) return

        if (state.data?.customerinfo?.id) {
            const productInput = document.querySelectorAll(`.active-doc .product-input input.MuiInputBase-input`)

            setTimeout(() => {
                if (isCreditNote() || isDebitNote()) {
                    relatedNoFieldRef?.current?.open(true)
                    relatedNoFieldRef?.current?.focus('.active-doc')
                } else {
                    productInput[productInput.length - 1]?.click()
                    productInput[productInput.length - 1]?.focus()
                }
            }, 500)
        }
    }, [state.data?.customerinfo?.id, editMode])

    useEffect(() => {
        if (previewMode || editMode) return

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                company: auth.getUser().getCompany(),
                companyinfo: auth.getUser().getCompany().getInfo(),
                companyaddress: auth.getUser().getCompanyAddress(),
                companybank: auth.getUser().getCompany().getBank(),
                // deal_location: auth.getUser().getCompanyAddress()?.settlement?.name || '',
            }
        }))
    }, [auth, editMode])

    // ако няма добавен ред за аркитули, винаги да създава 1 празен ред
    useEffect(() => {
        if (previewMode) return

        if (!state.data.rows || (state.data.rows && state.data.rows.length === 0)) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    rows: [
                        {

                        }
                    ]
                }
            }))
        }

    }, [state.data.rows, editMode])

    // залага номер на документа
    useEffect(() => {
        if (previewMode) return

        if (
            addMode
            && state.data.company
            // && String(state.data.no || '') === ''
        ) {
            // getNextNumber(state.data.company?.id, query.get('type_id'));
            handleNumberChange('')

            if (documentNumberInputRef.current) documentNumberInputRef.current.resetData()
        }
    }, [state.data.company?.id, editMode])

    useEffect(() => {
        const inputs = document.querySelectorAll(`.t-${timestamp} input`)
        for (let i = 0; i < inputs.length; i++) {
            inputs[i].addEventListener('keyup', () => {
                if (!app.isStartedDocumentActivity(docId)) app.setStartedDocumentActivities(true, docId)
            })
        }

        if (previewMode) return

        loadPaymentMethods()
    }, [editMode])

    useEffect(() => {
        if (previewMode) return
    }, [state.data?.customerinfo, editMode])

    useEffect(() => {
        if (previewMode) return

        let vatNumber = state?.data?.customerinfo?.vat_number

        if (!vatNumber) {

            const eik = state?.data?.customerinfo?.eikegn

            if (!eik) return

            vatNumber = `BG${eik}`
        }

        clearTimeout(viesRequestTimeoutRef.current)
        viesRequestTimeoutRef.current = setTimeout(() => {
            checkVies(vatNumber)
                .then(res => {
                    // ??
                    if (res.valid) {
                        if (!state?.data?.customerinfo?.vat_number) setState('customerinfo.vat_number', vatNumber, 'data')
                    }

                    // ??
                    setState('customerinfo.vat_is_valid', res.valid, 'data')
                })
        }, 500)
    }, [state?.data?.customerinfo?.eikegn, state?.data?.customerinfo?.vat_number, editMode])

    useEffect(() => {
        if (previewMode) return

        if (state.autoSend) document.querySelector('.save.with-dropdown').click()
    }, [state.autoSend, editMode])

    useEffect(() => {
        if ((state.data?.customer?.name?.length === 0) && (state.data?.customerinfo?.eikegn?.length === 0)) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    customer: {
                        id: null,
                        name: ''
                    },
                    customerinfo: {
                        id: null,
                        eikegn: ''

                    }
                }
            }))
            handleShowClientHiddenFields(false)
        }
        setTriggerPriceCalculation()

        if (!state.data.vat) handleRemoveVat(true)
    }, [state.data?.customer?.name, state.data?.customerinfo?.eikegn, editMode])

    useEffect(() => {
        if (state.data?.rows?.length) setTriggerPriceCalculation()
    }, [state.data?.rows?.length])

    useEffect(() => {
        if (state.triggerPriceCalculation) calculatePrice()
    }, [state.triggerPriceCalculation])

    // залага примерен Уводен текст за оферта спрямо получателя
    useEffect(() => {
        if (!isOffer()) {
            return
        }

        // if(editMode) {
        //     return;
        // }

        clearTimeout(setOfferIntroTimeoutRef.current)

        const text = addressing.guessFromName(state.data?.customeraddress?.mol)

        // console.log(text);

        setOfferIntroTimeoutRef.current = setTimeout(() => {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    offer_intro: text
                }
            }))
        }, 300)

    }, [state.data?.customeraddress?.mol])

    var channel = usePresenceSocketChannel({
        channel: 'documents',
        channelId: auth?.getUser()?.getCompany()?.getId()
    }, [auth])

    const checkTypeId = id => getActiveTabTypeId() === Number(id)

    const isOffer = () => checkTypeId(DOCUMENT_TYPE.OFFER)

    const isProforma = () => checkTypeId(DOCUMENT_TYPE.PROFORMA)

    const isInvoice = () => checkTypeId(DOCUMENT_TYPE.INVOICE)

    const isCreditNote = () => checkTypeId(DOCUMENT_TYPE.CREDIT_NOTE)

    const isDebitNote = () => checkTypeId(DOCUMENT_TYPE.DEBIT_NOTE)

    const handleDisableSave = disableSave => {
        setState(prev => ({
            ...prev,
            disableSave
        }))

        // if (!disableSave) {
        //     
        // }
    }

    const handleAddNote = () => {
        setState(prev => ({
            ...prev,
            addNote: !prev.addNote
        }))
    }

    const loadData = (withLoading = false) => {
        if (addMode) return
        if (state.data.newId) return
        if (getTabUnrepeatableActions(getActiveSubtab().id, 'DOCUMENTS_SHOW')) return
        if (withLoading) setLoadingData(true)

        Api.get('documents/show', {
            params: {
                id: docId
            }
        })
            .then(res => {
                if (editMode) handleSetTabUnrepeatableActions(getActiveSubtab().id, 'DOCUMENTS_SHOW')

                if (!res.data.vat) handleRemoveVat(true)

                setState(prev => ({
                    ...prev,
                    data: {
                        ...res.data,
                        mode: state.data.mode,
                    }
                }))
            })
            .catch(error => {
                const _err = error.response

                if (_err && _err.status && _err.status === 404) {
                    // todo
                }
            })
            .finally(() => {
                // setLoading(false)
                setLoadingData(false)
            })
    }

    const setLoadingData = loadingData => {
        setState(prev => ({
            ...prev,
            loadingData
        }))
    }

    const handleInputChange = e => {
        let { name, value } = e.target

        if (isCreditNote()) {
            if (name.includes("quantity") && value >= 0) {
                value *= -1
            }
        }

        if ((name === 'vat')) {
            if ((value === '0')) {
                handleRemoveVat(true)

                setTimeout(() => {
                    vatReasonSelectRef?.current?.open(true)
                }, 500)
            } else handleRemoveVat(false)
        }

        if (name === 'related_no') {
            value = getOrderedNumberSymbol(value)
        }

        // if (name === 'customeraddress[mol]' && !(state.data.recipient || '').length) {
        //     setState(prev => ({
        //         ...prev,
        //         data: {
        //             ...prev.data,
        //             recipient: shortenToMaximumFirstAndLastName(value),
        //         }
        //     }))
        // }

        setState(name, value, 'data')
        handleDisableSave(false)

    }
    /**
     * 
     * @param {string} val 
     * @returns добавя символ № към номера на документа и проверява дали № не се намира посредата на номера
     */
    const getOrderedNumberSymbol = (val = '') => {
        let recalculatedVal = val || ''

        if (val?.length) {
            const number = String(val || '')

            recalculatedVal = number.trim()
        }

        if (isNaN(Number(recalculatedVal))) {
            recalculatedVal = ''
        }

        return recalculatedVal || ''
    }

    const handleDateChange = (date, name = 'date', e) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name + '_ymd']: date,
                date: moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY'),
            }
        }))

        if (name === 'date') {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    pay_to_date_ymd: date,
                    term_date_ymd: date
                }
            }))

            // handleTabDateChange(moment(date).format('MM.DD.YYYY'), tabIndex)
        }

        handleDisableSave(false)
    }

    const handlePayToDateChange = e => {
        const { value } = e.target

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                // pay_to_date_ymd: moment(date).format('YYYY-MM-DD')
                pay_to_date_ymd: value,
            }
        }))

        handleDisableSave(false)

    }
    const handleTermDateChange = e => {
        const { value } = e.target

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                term_date_ymd: value,
            }
        }))

        handleDisableSave(false)
    }


    const handlePaymentMethodChange = e => {
        const { value } = e.target
        const method = paymentMethods.data.find(p => Number(p.id) === Number(value))
        const company = auth.getUser().getCompanyById(state.data?.company?.id)

        if (method && company) {
            if (method.name === 'bank') {
                if (!company.hasBank()) {
                    showBankConfigModal(() => {
                        auth.refresh()

                        setPaymentMethod(method)
                    })

                    return
                }
            }

            if (method.name === 'epay' || method.name === 'epay_borica') {
                if (!company.hasEpay()) {
                    showEpayConfigModal(() => {
                        auth.refresh()

                        setPaymentMethod(method)
                    })

                    return
                }
            }

            if (method.name === 'paypal' || method.name === 'paypal_card') {
                if (!company.hasPayPal()) {
                    showPayPalConfigModal(() => {
                        auth.refresh()

                        setPaymentMethod(method)
                    })

                    return
                }
            }

            if (method.name === 'stripe') {
                if (!company.hasStripe()) {
                    showStripeConfigModal(() => {
                        auth.refresh()

                        setPaymentMethod(method)
                    })

                    return
                }
            }
        }

        setPaymentMethod(method)
        handleDisableSave(false)
    }

    const setPaymentMethod = method => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                payment_method_id: method.id,
                paymentmethod: method,
            }
        }))
    }

    const showBankConfigModal = onSuccess => {
        const modal = bankConfigModalRef.current

        if (modal) {
            modal.show()

            modal.onSuccess(() => {
                if (onSuccess) onSuccess()
            })
        }
    }

    const showEpayConfigModal = onSuccess => {
        const modal = epayConfigModalRef.current

        if (modal) {
            modal.show()

            modal.onSuccess(() => {
                if (onSuccess) onSuccess()
            })
        }
    }

    const showPayPalConfigModal = onSuccess => {
        const modal = paypalConfigModalRef.current

        if (modal) {
            modal.show()

            modal.onSuccess(() => {
                if (onSuccess) onSuccess()
            })
        }
    }

    const showStripeConfigModal = onSuccess => {
        const modal = stripeConfigModalRef.current

        if (modal) {
            modal.show()

            modal.onSuccess(() => {
                if (onSuccess) onSuccess()
            })
        }
    }

    const handleVatReasonChange = data => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                vat_reason_id: data?.id,
                vatreason: data || null
            }
        }))

        handleDisableSave(false)

    }

    function setTriggerPriceCalculation() {
        setState(prev => ({
            ...prev,
            triggerPriceCalculation: moment().unix()
        }))
    }

    const handlePriceInputChange = e => {
        handleInputChange(e)
        // clearTimeout(calculationRequestTimeoutRef.current)
        // if (state.calculationRequest) state.calculationRequest.cancel()
        // calculationRequestTimeoutRef.current = setTimeout(() => {
        //     calculatePrice(e.target.value)
        // }, 500)

        // ако няма timeout се чупи
        clearTimeout(calculationRequestTimeoutRef.current)

        calculationRequestTimeoutRef.current = setTimeout(() => {
            setTriggerPriceCalculation()
        }, 300)

        handleDisableSave(false)
    }

    const handleQuantityChange = (e, index) => {

        let qty = e.target.value

        let row = state.data.rows[index]

        if (!row) {
            return
        }

        let product = row.product

        //Това пречеше на количеството да се контролира, ако не е избран продукт от списъка.
        // if (!product) {
        //     return;
        // }

        let prices = product?.prices || []

        let oldPriceWithSameQty = prices?.find(p => Number(p.quantity) === Number(qty)) || 0

        if (oldPriceWithSameQty) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    rows: Object.values({
                        ...prev.data.rows,
                        [index]: {
                            ...prev.data.rows[index],
                            price: oldPriceWithSameQty.price,
                        }
                    })
                }
            }))
        }

        handlePriceInputChange(e)
    }

    // company
    const handleCompanyChange = data => {
        if (data && typeof data === 'object') {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    company: data,
                    companyinfo: data.info,
                    companyaddress: null
                }
            }))

            // ако има само 1 адрес да се маркира автоматично
            if (data.addresses.length === 1) handleCompanyAddressChange(data.addresses[0])
        } else {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    company: null,
                    companyinfo: {
                        id: null,
                        name: data
                    }
                }
            }))
        }

        handleDisableSave(false)
    }

    const handleCompanyAddressChange = data => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                companyaddress: typeof data === 'object' && data !== null ? data : {
                    // id: null,
                    id: prev.data.companyaddress?.id,
                    name: data
                },
            }
        }))

        handleDisableSave(false)

    }

    const handleCompanyCountryChange = data => {
        if (typeof data !== 'object' || Number(data?.id) !== Number(state.data?.companyaddress?.country?.id)) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    companyaddress: {
                        ...prev.data?.companyaddress,
                        district: null,
                        municipality: null,
                        settlement: null,
                        area: null,
                        street: null,
                        street_no: null,
                        postcode: null
                    }
                }
            }))
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                companyaddress: {
                    ...prev.data?.companyaddress,
                    country: typeof data === 'object' ? data : {
                        id: null,
                        name: data
                    },
                }
            }
        }))
    }

    const handleCompanySettlementChange = data => {
        if (typeof data !== 'object' || Number(data?.id) !== Number(state.data?.companyaddress?.settlement?.id)) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    companyaddress: {
                        ...prev.data?.companyaddress,
                        area: null,
                        street: null,
                        street_no: null,
                        postcode: null
                    }
                }
            }))
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                companyaddress: {
                    ...prev.data?.companyaddress,
                    settlement: typeof data === 'object' ? data : {
                        id: null,
                        municipality_id: null,
                        name: data
                    },
                }
            }
        }))
    }

    // customer
    const handleCustomerChange = (data, fromMain = false) => {
        if (!data) return

        if (!showAllClientFields() && state.data.customer) clearCustomerData()

        if (fromMain && !Number.isNaN(Number(data))) return

        if (data && typeof data === 'object') {

            // let customer = data;
            let customer = cloneDeep(data)

            // не трябва да се подава id от търговския регистър
            if (customer.class === 'App\\Models\\TradeRegistry\\Company') {
                customer.id = null
            }

            let addresses = data.addresses.map((address, index) => {

                if (address.name === null || address.name.length === 0) {
                    address.name = `Офис ${index + 1}`
                }

                return address
            })

            customer.addresses = addresses
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    customer: customer,
                    customerinfo: customer.info,
                    customeraddress: null,
                }
            }))

            handleCustomerAddressChange(customer.addresses[0])
        } else {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    customer: {
                        id: prev?.data?.customer?.id,
                        name: data
                    },
                    // customerinfo: {
                    //     id: prev?.data?.customerinfo?.id,
                    //     eikegn: data
                    // }
                }
            }))
        }

        handleDisableSave(false)

    }

    const handleCustomerEikChange = data => {
        if (data && typeof data === 'object') handleCustomerChange(data)

        handleDisableSave(false)
    }

    const handleCustomerAddressChange = data => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                customeraddress: typeof data === 'object' && data !== null ? data : {
                    // id: null,
                    id: prev.data.customeraddress?.id,
                    name: data
                },
                // recipient: shortenToMaximumFirstAndLastName(typeof data === 'object' ? data.mol : ''),
                author: shortenToMaximumFirstAndLastName(typeof data === 'object' ? data.mol : ''),
                deal_location: auth.getUser().getCompanyAddress()?.settlement?.name || ''
            }
        }))

        handleDisableSave(false)
    }

    const handleCustomerSettlementChange = data => {
        if (typeof data !== 'object' || Number(data?.id) !== Number(state.data?.customeraddress?.settlement?.id)) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    customeraddress: {
                        ...prev.data?.customeraddress,
                        area: null,
                        street: null,
                        street_no: null,
                        postcode: null
                    }
                }
            }))
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                customeraddress: {
                    ...prev.data?.customeraddress,
                    settlement: typeof data === 'object' ? data : {
                        id: null,
                        municipality_id: null,
                        name: data
                    },
                }
            }
        }))
    }

    const handleChangeCustomerTradeObject = customeraddress => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                customeraddress
            }
        }))
    }

    const handleNumberChange = data => {
        let number = ''
        if (data?.target) handleDisableSave(false)

        if (typeof data === 'object' && data !== null) {
            number = getOrderedNumberSymbol(data.next_number)

            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    no: number,
                    serial_id: data.id
                }
            }))

        } else {
            number = getOrderedNumberSymbol(data)
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    no: number,
                    serial_id: null
                }
            }))
        }
        handleDisableSave(false)
    }

    const handleAddRow = (e, group = null) => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: prev.data.rows.concat({
                    id: moment().unix(),
                    group: group
                })
            }
        }))
    }

    const handleRemoveRow = (e, index) => {
        e.preventDefault()
        const rows = [...state.data.rows]
        const updatedRows = rows.filter((row, i) => i !== index)

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: updatedRows
            }
        }))

        handleDisableSave(false)
    }

    const handleUnitChange = (data, index) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: Object.values({
                    ...prev.data.rows,
                    [index]: {
                        ...prev.data.rows[index],
                        unit: data?.id ? data : {
                            id: null,
                            name: data.name
                        }
                    }
                })
            }
        }))

        if (state?.data?.rows[index || 0]?.unit?.id) {

            handleDisableSave(false)
        }
        if (data?.id) handleSelectFieldAfterUnit()
    }

    const handleSelectFieldAfterUnit = () => {
        const el = document.querySelector('.quantity-input')
        const nextField = el.parentElement.nextSibling
        nextField.querySelector('input').focus()
    }

    const handleBankChange = data => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                bank_id: data?.id || null,
                bank: data
            }
        }))

        handleDisableSave(false)
    }

    const calculatePrice = () => {
        const clonedRows = [...state.data.rows]
        let totalPrice = 0

        clonedRows.map(r => {
            if (r.price && r.quantity) {
                r.total_price = r.price * r.quantity

                if (r.discount) {
                    r.total_price = r.total_price - (r.total_price * r.discount / 100)
                }

                totalPrice += r.total_price
            } else {
                r.total_price = 0
            }

            return r
        })
        const vat = (totalPrice / 100 * (Number(state.data.vat || 0) || 0))
        const totalPriceGross = totalPrice + vat

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                price: totalPrice,
                rows: clonedRows,
                vat_price: vat,
                gross_price: totalPriceGross,
            },
        }))

        // if (state.calculationRequest) state.calculationRequest.cancel()

        // const calculationRequest = axios.CancelToken.source()

        // setState(prev => ({
        //     ...prev,
        //     calculationRequest
        // }))

        // const form = formRef.current

        // if (!form) return

        // const data = new FormData(form)

        // Api.post('documents/calculate-price', data, {
        //     cancelToken: calculationRequest.token
        // })
        //     .then(res => {
        //         const data = {...res.data.document}
        //         const rows = [...res.data.rows]

        //         setState(prev => ({
        //             ...prev,
        //             currency: data.currency.name,
        //             data: {
        //                 ...prev.data,
        //                 ...data,
        //                 rows: prev.data.rows.map((row, index) => {
        //                     return { ...row, ...rows[index] }
        //                 })
        //             }
        //         }))
        //     })
        //     .finally(() => {
        //         setState(prev => ({
        //             ...prev,
        //             calculated: true
        //         }))
        //     })
    }

    const checkVies = async number => {
        if (state.viesRequest) state.viesRequest.cancel()

        const viesRequest = axios.CancelToken.source()

        setState(prev => ({
            ...prev,
            viesRequest
        }))
        setVatLoading(true)

        return await Api.get('documents/check-vies', {
            params: {
                vat_number: number,
            },
            cancelToken: viesRequest.token
        })
            .then(res => res.data)
            .finally(() => {
                setVatLoading(false)
                renewCheckVat(false)
                setTimeout(() => {
                    renewCheckVat(true)
                }, 3000)
            })
    }

    const setVatLoading = vatLoading => {
        setState(prev => ({
            ...prev,
            vatLoading
        }))
    }

    const renewCheckVat = renewCheckVat => {
        setState(prev => ({
            ...prev,
            renewCheckVat
        }))
    }

    const loadPaymentMethods = async () => {
        const data = await app.getPaymentMethods()

        setPaymentMethods(prev => ({
            ...prev,
            data,
        }))

        setPaymentMethod(data[0])
    }

    const handleCheckCustomerVatNumber = () => {
        const vatNumber = state?.data?.customerinfo?.vat_number

        if (!vatNumber) return

        checkVies(vatNumber)
            .then(res => {
                if (res.valid) success('Фирмата е регистрирана по ЗДДС')
                else error('Фирмата не е регистрирана по ЗДДС')

                setState('customerinfo.vat_is_valid', res.valid, 'data')
            })
    }

    function setPreviewData(data) {
        setState(prev => ({
            ...prev,
            data: {
                ...data,
                mode: 'preview'
            }
        }))
    }

    const handleSave = e => {
        e.preventDefault()
        if (productRowInputRef.current.isAddProductModalOpen) return

        setLoading(true)
        setValidations(null)

        const url = addMode ? 'documents/create' : 'documents/update'
        const data = new FormData(e.target)

        if (!addMode) data.append('id', state.data?.newId || docId)
        else {
            data.append('type_id', getActiveTabTypeId())
            data.delete('id')
        }

        if (state.data.accountsdata) {
            state.data.accountsdata.map(link => {
                if (!link.disabled) {
                    data.append('from_account_id[]', link.account_id)
                }
            })
        }

        app.setStartedDocumentActivities(false, state.data?.newId || docId)

        Api.post(url, data)
            .then(res => {
                if (state.autoSend) {
                    const sendModal = sendModalRef.current

                    sendModal.open(res.data.id, res.data.customerinfo?.email)
                    sendModal.onSuccess(() => {
                        success()
                        handleCloseTab()
                    })
                } else {
                    setState(prev => ({
                        ...prev,
                        data: {
                            ...prev.data,
                            ...res.data,
                            id: prev.data.id,
                            newId: res.data.id,
                            mode: 'preview'
                        }
                    }))
                }

                auth.refresh()

                setAutoSend(false)
                handleDisableSave(true)
            })
            .catch(error => {
                const _err = error.response

                if (_err && _err.status && _err.status === 422) setValidations(_err.data.errors)
                else {
                    const modal = errorModalRef.current

                    modal.open()
                    modal.onSuccess(() => {
                        //
                    })
                }
                app.setStartedDocumentActivities(true, docId)
                setAutoSend(false)

            })
            .finally(() => {
                setLoading(false)
            })
    }

    const setLoading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    const showCustomVatField = showCustomVatField => {
        setState(prev => ({
            ...prev,
            showCustomVatField,
            data: {
                ...prev.data,
                vat: !showCustomVatField ? 20 : prev.data.vat
            }
        }))

        if (!showCustomVatField) setTriggerPriceCalculation()
    }

    const handleDelete = e => {
        e.preventDefault()

        const modal = deleteModalRef.current
        modal.open()
        modal.onSuccess(() => {
            handleDestroy(docId)
        })
    }

    const handleDestroy = id => {
        Api.post('documents/delete', {
            id
        })
            .then(res => {
                if (res.data.success) {
                    success()
                    document.querySelector(`.active-subtab i.close`).click()
                }
            })
            .catch(() => {
                error()
            })
    }

    const handleMakePaid = e => {
        e.preventDefault()

        const modal = makePaidModalRef.current

        modal.open(docId)
        modal.onSuccess(() => {
            success()
            loadData(true)
        })
    }

    const handleMakeUnpaid = e => {
        e.preventDefault()

        const modal = makeUnpaidModalRef.current

        modal.open()
        modal.onSuccess(() => {
            makeUnpaid(docId)
        })
    }

    const makeUnpaid = id => {
        Api.post('documents/make-unpaid', {
            id
        })
            .then(res => {
                if (res.data.success) {
                    success()
                    loadData(true)
                }
            })
    }

    const handlePreview = e => {
        e.preventDefault()

        previewModalRef.current.open(state.data)
    }

    const handleDownload = e => {
        e.preventDefault()

        previewModalRef.current.open(state.data, true)
    }

    const handleSend = e => {
        e.preventDefault()

        const modal = sendModalRef.current

        modal.open(docId, state.data.customerinfo?.email)
        modal.onSuccess(() => {
            success()
        })

        modal.onError(() => {
            error()
        })
    }

    const success = msg => {
        app.handleSuccess(msg)
    }

    const error = msg => {
        app.handleError(msg)
    }

    const handleRemoveVat = removeVat => {
        setState(prev => ({
            ...prev,
            removeVat
        }))

        handleDisableSave(false)

    }

    const handleManageColumns = showColumnManager => {
        setState(prev => ({
            ...prev,
            showColumnManager
        }))

        if (!showColumnManager) {
            const tableColums = state?.tableColumns || {}
            const values = Object.values(tableColums)
            const keys = Object.keys(tableColums)
            const data = []
            const formData = new FormData()

            values.map((v, i) => {
                if (!v.visible) data.push(keys[i])
            })

            formData.append('company_id', companyId)
            formData.append('data[documents_hidden_fields]', JSON.stringify(data))

            Api.post('settings/set', formData)
                .finally(() => {
                    auth.refresh()
                })
        }
    }

    const getCoumnsInList = () => {
        const tableColums = state?.tableColumns || {}
        const keys = Object.keys(tableColums)
        const values = Object.values(tableColums)

        const list = values.map((t, i) => {
            return (
                <MenuItem
                    onClick={() => {
                        if (t.ableToHide) handleShowColumns(keys[i], !t.visible)
                    }}
                >
                    <Checkbox
                        checked={t.visible}
                        disabled={!t.ableToHide}
                        color="primary"
                        style={{
                            padding: '0',
                            marginRight: '5px',
                        }}
                    />
                    {t.name}
                </MenuItem>
            )
        })

        return list
    }

    const handleShowColumns = (column, visible) => {
        setState(prev => ({
            ...prev,
            tableColumns: {
                ...prev.tableColumns,
                [column]: {
                    ...prev.tableColumns[column],
                    visible
                }
            }
        }))
    }

    const handleSelectProduct = (index, product) => {
        let price = product.price
        let qty = 1
        let unit = {}

        if (Array.isArray(product.prices)) {
            let latest = product.prices[0]

            if (latest) {
                price = latest.price
                qty = latest.quantity
                unit = latest.unit
            }
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: Object.values({
                    ...prev.data.rows,
                    [index]: {
                        ...prev.data.rows[index],
                        price: price,
                        quantity: qty,
                        product: product,
                        unit
                    }
                })
            }
        }))

        // clearTimeout(calculationRequestTimeoutRef.current)
        // if (state.calculationRequest) state.calculationRequest.cancel()
        // calculationRequestTimeoutRef.current = setTimeout(() => {
        //     calculatePrice()
        // }, 500)

        setTriggerPriceCalculation()

        if (product.id) handleSelectFieldAfterProduct(index)

        handleDisableSave(false)
    }

    const handleNameProduct = (value, index) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: Object.values({
                    ...prev.data.rows,
                    [index]: {
                        ...prev.data.rows[index],
                        input: {
                            categoriesSearchValue: '',
                            value
                        }
                    }
                })
            }
        }))

        handleDisableSave(false)
    }

    const handleSelectFieldAfterProduct = index => {
        let nextField = document.querySelector(`.product-input[data-name="rows[${index}][name]"]`)
        if (!nextField) {
            handleSelectFieldAfterProductTimeoutRef.current = setTimeout(() => {
                handleSelectFieldAfterProduct(index)
            }, 500)
        } else {
            nextField = nextField?.parentNode?.nextSibling
            clearTimeout(handleSelectFieldAfterProductTimeoutRef.current)
            nextField.querySelector('input').focus()
        }
    }

    const openMenu = e => {
        setState(prev => ({
            ...prev,
            saveButtonMenu: e.currentTarget
        }))
    }

    const closeMenu = () => {
        setState(prev => ({
            ...prev,
            saveButtonMenu: null
        }))
    }

    //от тук до [!], функциите работят заедно
    const handleSaveOptions = type => {
        if (type === 'save_and_send') setAutoSend(true)
        else document.querySelector('.save.with-dropdown').click()
    }

    const setAutoSend = autoSend => {
        setState(prev => ({
            ...prev,
            autoSend
        }))
    }
    //[!]

    const handleCloseTab = () => {
        document.querySelector(`.active-subtab i.close`).click()
    }

    const appendBank = (bank = {}) => {
        if (state.data?.company?.banks) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    company: {
                        ...prev.data.company,
                        banks: [
                            bank,
                            ...prev.data.company.banks
                        ]
                    }
                }
            }))
            handleChangeBank(bank)
        }
    }

    const handleChangeBank = companybank => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                companybank
            }
        }))
    }

    /**
     * 
     * @returns дали да се се покажат всички полета на клиента, след като се избере фирма
     */
    function showAllClientFields() {
        if (state.data?.customerinfo?.id) {
            if (!(state.data?.customer?.name || '').length && !(state.data?.customerinfo?.eikegn || '').length) {
                setState(prev => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        customerinfo: null,
                        customer: null,
                        customeraddress: null
                    }
                }))
            }
        }
        if (((state.data?.customer?.name || '').length || (state.data?.customerinfo?.eikegn || '').length) && state.showClientHiddenFields || state.data?.customerinfo?.id) {
            return true
        }

        return false
        // return state.showClientHiddenFields
    }

    const clearCustomerData = () => {
        if (!state.data.customer) return
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                customer: null,
                customeraddress: null,
                costomerinfo: null
            }
        }))
    }

    /**
     * 
     * @returns дали ИН ПО ЗДДС е валиден
     */
    const isVatNumberValid = () => {
        let valid = {
            isValid: false,
            hasVat: false
        }
        if (String(state.data?.customerinfo?.vat_is_valid).length > 0) {
            valid = {
                ...valid,
                hasVat: true
            }
            if (state.data?.customerinfo?.vat_is_valid) {
                valid = {
                    ...valid,
                    isValid: true
                }
            }
        }

        return valid
    }

    const handleShowClientHiddenFields = (showClientHiddenFields, data) => {
        setState(prev => ({
            ...prev,
            showClientHiddenFields
        }))

        let isEik = false
        if (data) {
            if (!Number.isNaN(Number(data))) {
                isEik = true
            }

            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    customerinfo: {
                        id: prev?.data?.customerinfo?.id,
                        eikegn: isEik ? data : '',
                        name: !isEik ? data : ''
                    }
                }
            }))
        }
    }

    /**
     * връща дали маркирания раздел е главен
     * ползва се за да предотварти изкачането на Prompt компонента, който казва, че има незапазени промени, когато смениш от документ към главен раздел
     * @returns {boolean}
     */
    const isMainTabActive = () => {
        if (document.querySelectorAll('li.active .subtabs li')[0]?.classList.contains('active-subtab')) {
            return true
        }

        return false
    }

    const handleInsertNextNumber = insert => {
        if (insert) {
            handleNumberChange(insert)
        }

        const valid = cloneDeep(validations)
        valid.no = false

        setValidations({
            ...valid,
        })
    }

    function handleFocusToChangeBg(inputName) {
        setFocusedInputName(inputName)
    }

    function focusQtyField(rowIndex) {
        const qtyFiedl = document.querySelector(`.t-${timestamp}`).nextSibling.querySelector(`input[name="rows[${rowIndex}][quantity]"]`)

        if (!qtyFiedl.value.length) qtyFiedl.focus()
    }

    function appendInvoiceForCreditOrDebitNote(invoice) {
        if (!invoice) return
        invoice['type_id'] = getActiveTabTypeId()
        delete invoice['id']
        invoice['type'] = {}

        handleRemoveVat(Boolean(invoice.vatreason))

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                ...invoice
            }
        }))
    }

    function handleRelatedNumberChange(related_no) {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                related_no
            }
        }))
    }

    function getRowPrice({ total_price }) {
        if (!total_price) {
            return '0.00'
        }

        return total_price.toFixed(2)
    }

    const showAccount = id => {
        let modal = Refs.getInstance().getRef('account')

        if (!modal) {
            return
        }

        modal.open(id)
    }

    const renderRows = (group = undefined) => {
        return (
            <>
                <table className="type-doc">
                    <thead>
                        <tr>
                            <th className="option">
                                <Tooltip
                                    title="Управление на колоните"
                                >
                                    <Button
                                        onClick={() => {
                                            handleManageColumns(true)
                                        }}
                                        className="options-menu-button"
                                        ref={optionsMenuButtonRef}
                                        tabIndex={-1}
                                    >
                                        <img src={settingsIcon} />
                                    </Button>
                                </Tooltip>
                                <Menu
                                    anchorEl={optionsMenuButtonRef.current}
                                    keepMounted
                                    open={state.showColumnManager}
                                    onClose={() => {
                                        handleManageColumns(false)
                                    }}
                                // onClick={handleClose}
                                >
                                    {getCoumnsInList()}
                                </Menu>
                            </th>
                            {state?.tableColumns?.number?.visible ?
                                <th className="number">
                                    {state?.tableColumns?.number?.name}
                                </th>
                                :
                                <>
                                </>
                            }
                            {state?.tableColumns?.products?.visible ?
                                <th className="product">
                                    {state?.tableColumns?.products?.name}
                                </th>
                                :
                                <>
                                </>
                            }
                            {state?.tableColumns?.quantity?.visible ?
                                <th className="quantity" style={{
                                    minWidth: '120px'
                                }}>
                                    {state?.tableColumns?.quantity?.name}
                                </th>
                                :
                                <>
                                </>
                            }
                            {/* {state?.tableColumns?.unit?.visible ?
                                                    <th className="unit">
                                                        {state?.tableColumns?.unit?.name}
                                                    </th>
                                                    :
                                                    <>
                                                    </>
                                                } */}
                            {state?.tableColumns?.singlePrice?.visible ?
                                <th className="single-price">
                                    {state?.tableColumns?.singlePrice?.name}
                                </th>
                                :
                                <>
                                </>
                            }
                            {state?.tableColumns?.discount?.visible ?
                                <th className="discount">
                                    {state?.tableColumns?.discount?.name}
                                </th>
                                :
                                <>
                                </>
                            }
                            {state?.tableColumns?.price?.visible ?
                                <th className="text-right price">
                                    {state?.tableColumns?.price?.name}
                                </th>
                                :
                                <>
                                </>
                            }
                        </tr>
                    </thead>
                    {!state.loadingData ?
                        <tbody> {
                            Array.isArray(state.data.rows)
                                ?
                                state.data.rows.map((row, index) => group === undefined || row.group === group ? (
                                    <tr key={'row-' + row.id}>
                                        <td>
                                            <button
                                                className={`${(state.data.rows || []).length <= 1 ? 'disabled' : ''} remove small`}
                                                onClick={e => {
                                                    handleRemoveRow(e, index)
                                                }}
                                                style={{
                                                    display: 'block',
                                                    marginLeft: '5px'
                                                }}
                                            >

                                            </button>
                                        </td>
                                        {state?.tableColumns?.number?.visible ?
                                            <td>
                                                {index + 1}

                                                <input type="hidden" name={`rows[${index}][group]`} value={row.group || ''} />
                                            </td>
                                            :
                                            <>
                                            </>
                                        }
                                        {state?.tableColumns?.products?.visible &&
                                            <td>
                                                <ProductRowInput
                                                    ref={productRowInputRef}
                                                    companyId={state.data?.company?.id}
                                                    customerId={state.data?.customer?.id}
                                                    customerEik={state.data?.customerinfo?.eikegn}
                                                    name={`rows[${index}][name]`}
                                                    value={row.name}
                                                    initialRowData={state.data.rows[index]}
                                                    productIdName={`rows[${index}][product_id]`}
                                                    productId={row.product_id}
                                                    error={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].name && (validations.rows[index].name[0] || validations.rows[index].name))}
                                                    helperText={validations && validations.rows && validations.rows[index] && validations.rows[index].name && (validations.rows[index].name[0] || validations.rows[index].name)}
                                                    onSelect={product => handleSelectProduct(index, product)}
                                                    handleDisableSave={handleDisableSave}
                                                    onChange={e => {
                                                        const { value } = e.target
                                                        handleNameProduct(value, index)
                                                    }}
                                                    key={state.data.rows[index].created_at}
                                                    focusQtyField={() => {
                                                        focusQtyField(index)
                                                    }}
                                                    autoFocus={(state.data.rows.length - 1) === index}
                                                />
                                            </td>
                                        }
                                        {state?.tableColumns?.quantity?.visible ?
                                            <td>
                                                <TextField
                                                    variant="outlined"
                                                    margin="none"
                                                    size="small"
                                                    fullWidth
                                                    type="number"
                                                    min="0"
                                                    step="0.001"
                                                    name={`rows[${index}][quantity]`}
                                                    value={row.quantity}
                                                    className="quantity-input input-with-right-label"
                                                    onChange={e => handleQuantityChange(e, index)}
                                                    error={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].quantity && (validations.rows[index].quantity[0] || validations.rows[index].quantity))}
                                                    helperText={validations && validations.rows && validations.rows[index] && validations.rows[index].quantity && (validations.rows[index].quantity[0] || validations.rows[index].quantity)}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">
                                                            <SelectUnit
                                                                initialUnit={row.unit || null}
                                                                onChange={data => {
                                                                    handleUnitChange(data, index)
                                                                }}
                                                                fieldName={`rows[${index}][unit][name]`}
                                                            />
                                                        </InputAdornment>,
                                                    }}
                                                />
                                                <input type="hidden" name={`rows[${index}][unit][id]`} value={row.unit?.id || null} />
                                                <input type="hidden" name={`rows[${index}][unit][name]`} value={row.unit?.name || null} />
                                            </td>
                                            :
                                            <>
                                            </>
                                        }
                                        {/* {state?.tableColumns?.unit?.visible ?
                                                        <td className="unit-input">
                                                            <Autocomplete
                                                                size="small"
                                                                margin="none"
                                                                // inputPlaceholder="Мярка"
                                                                inputName={`rows[${index}][unit][name]`}
                                                                inputIdName={`rows[${index}][unit][id]`}
                                                                url="documents/units/all"
                                                                selected={row.unit || ''}
                                                                error={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].unit && (validations.rows[index].unit[0] || validations.rows[index].unit.name))}
                                                                helperText={validations && validations.rows && validations.rows[index] && validations.rows[index].unit && (validations.rows[index].unit[0] || validations.rows[index].unit.name)}
                                                                onChange={data => handleUnitChange(data, index)}
                                                                onInputChange={data => handleUnitChange(data, index)}
                                                                autoSelect
                                                            />
                                                        </td>
                                                        :
                                                        <>
                                                        </>
                                                    } */}
                                        {state?.tableColumns?.singlePrice?.visible ?
                                            <td>
                                                <TextField
                                                    variant="outlined"
                                                    margin="none"
                                                    size="small"
                                                    fullWidth
                                                    type="number"
                                                    step="0.01"
                                                    name={`rows[${index}][price]`}
                                                    className="input-with-right-label"
                                                    value={row.price || ''}
                                                    onChange={handlePriceInputChange}
                                                    error={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].price && (validations.rows[index].price[0] || validations.rows[index].price))}
                                                    helperText={validations && validations.rows && validations.rows[index] && validations.rows[index].price && (validations.rows[index].price[0] || validations.rows[index].price)}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">{state.currency}</InputAdornment>,
                                                    }}
                                                />
                                            </td>
                                            :
                                            <>
                                            </>
                                        }
                                        {state?.tableColumns?.discount?.visible ?
                                            <td>
                                                <TextField
                                                    variant="outlined"
                                                    margin="none"
                                                    size="small"
                                                    fullWidth
                                                    type="number"
                                                    min="0"
                                                    step="0.0001"
                                                    name={`rows[${index}][discount]`}
                                                    value={row.discount || ''}
                                                    onChange={handlePriceInputChange}
                                                    className="input-with-right-label"
                                                    error={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].discount && (validations.rows[index].discount[0] || validations.rows[index].discount))}
                                                    helperText={validations && validations.rows && validations.rows[index] && validations.rows[index].discount && (validations.rows[index].discount[0] || validations.rows[index].discount)}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                    }}
                                                />
                                            </td>
                                            :
                                            <>
                                            </>
                                        }
                                        {state?.tableColumns?.price?.visible ?
                                            <td className="text-right bold">
                                                <b style={Number(getRowPrice(row)) < 0 ? { color: '#f44336' } : {}}>
                                                    {splitLongNumbers(getRowPrice(row))} {state.currency}
                                                </b>
                                            </td>
                                            :
                                            <>
                                            </>
                                        }
                                    </tr>
                                ) : <></>)
                                :
                                ''
                        }</tbody>
                        :
                        <>
                        </>
                    }
                </table>

                <button
                    className="add small"
                    onClick={e => handleAddRow(e, group)}
                    style={{
                        marginLeft: '12px'
                    }}
                >

                </button>
            </>
        )
    }

    const renderVatFields = () => {
        return (
            <div
                className="row"
                style={{
                    // alignItems: 'flex-end',
                    flexDirection: 'column'
                }}
            >
                <div
                    className="col"
                    style={{
                        marginTop: '-45px',
                        // width: state.removeVat ? '601px' : '401px',
                        width: '100%',
                        marginLeft: 'auto',
                        flex: 'initial',
                    }}
                >
                    <hr
                        style={{
                            width: 'calc(100% - 100px)',
                            marginLeft: 'auto'
                        }}
                    />
                    <table className="sum">
                        <tbody>
                            <tr>
                                <td className="text-right">
                                    Стойност
                                </td>
                                <td className="bold text-right">
                                    {splitLongNumbers(state.data.price ? state.data.price.toFixed(2) : `0.00`)} {state.currency}
                                </td>
                            </tr>

                            <tr>
                                <td className="text-right">
                                    <div className="row">
                                        {state.removeVat ?
                                            <div
                                                className="col"
                                                style={{
                                                    minWidth: '280px',
                                                    // marginLeft: 'auto'
                                                    margin: '10px auto'
                                                }}
                                            >
                                                {!isOffer() &&
                                                    <Autocomplete
                                                        freeSolo={false}
                                                        variant="filled"
                                                        size="small"
                                                        margin="dense"
                                                        inputPlaceholder="Основание за премахване на ДДС"
                                                        inputName={`vat_reason`}
                                                        inputIdName={`vat_reason_id`}
                                                        url="documents/vat-reasons"
                                                        getResultData={data => data}
                                                        selected={state.data?.vatreason || null}
                                                        error={Boolean(validations && validations.vat_reason_id && (validations.vat_reason_id[0] || validations.vat_reason_id))}
                                                        helperText={validations && validations.vat_reason_id && (validations.vat_reason_id[0] || validations.vat_reason_id)}
                                                        onChange={handleVatReasonChange}
                                                        ref={vatReasonSelectRef}
                                                        autoSelect
                                                    />
                                                }
                                            </div>
                                            :
                                            <>
                                            </>
                                        }
                                        <div
                                            className="col"
                                            style={{
                                                alignItems: 'flex-end',
                                                maxWidth: '150px',
                                                marginLeft: 'auto'
                                            }}
                                        >
                                            <div className="select-vat">
                                                <label style={{ marginRight: '8px' }}>
                                                    ДДС
                                                </label>
                                                {state.showCustomVatField ?
                                                    <div className="custom-vat">
                                                        <TextField
                                                            variant="outlined"
                                                            margin="none"
                                                            size="small"
                                                            fullWidth
                                                            type="number"
                                                            min="0"
                                                            autoFocus
                                                            name="vat"
                                                            onChange={handlePriceInputChange}
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                            }}
                                                        />
                                                        <Tooltip title="Премахване друго ДДС">
                                                            <i
                                                                className="close"
                                                                onClick={() => {
                                                                    showCustomVatField(false)
                                                                }}
                                                            >
                                                                <img src={closeSmallIcon} />
                                                            </i>
                                                        </Tooltip>
                                                    </div>
                                                    :
                                                    <Select
                                                        variant="outlined"
                                                        margin="dense"
                                                        size="small"
                                                        name="vat"
                                                        value={state.data.vat}
                                                        onChange={e => {
                                                            handlePriceInputChange(e)
                                                            if (e.target.value === 'custom') {
                                                                showCustomVatField(true)

                                                                return
                                                            }
                                                            if (e.target.value === '') {
                                                                showCustomVatField(true)

                                                                return
                                                            }
                                                            setTimeout(() => {
                                                                emulateTab()
                                                            }, 500)
                                                        }}
                                                        // label="ДДС"
                                                        className="vat-field"
                                                    >
                                                        <MenuItem
                                                            value="20"
                                                        >
                                                            20%
                                                        </MenuItem>
                                                        <MenuItem
                                                            value="9"
                                                        >
                                                            9%
                                                        </MenuItem>
                                                        <MenuItem
                                                            value="custom"
                                                        >
                                                            Друго
                                                        </MenuItem>
                                                        <MenuItem
                                                            value="0"
                                                        >
                                                            Без
                                                        </MenuItem>
                                                    </Select>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td className="bold text-right with-input">
                                    {splitLongNumbers(state.data.vat_price && !state.removeVat ? state.data.vat_price.toFixed(2) : `0.00`)} {state.currency}
                                </td>
                            </tr>
                            <tr>
                                <td className="text-right">
                                    Сума за плащане
                                </td>
                                <td className="bold text-right">
                                    {splitLongNumbers(state.data.gross_price ? (Number(state.data.gross_price) - (state.removeVat ? Number(state.data.vat_price) : 0)).toFixed(2) : `0.00`)} {state.currency}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div
                    className="col"
                    style={{
                        flex: 'initial',
                        width: '844px',
                        paddingLeft: '9px',
                        marginTop: state.addNote ? '-10px' : '-32px',
                    }}
                >
                    {state.addNote ?
                        <>
                            <RedditTextField
                                fullWidth
                                label="Забележки"
                                name="description"
                                value={state.data.description || ''}
                                multiline
                                autoFocus
                                rows="3"
                                onChange={handleInputChange}
                                style={{
                                    height: '90px'
                                }}
                            />

                        </>
                        :
                        <>
                        </>
                    }
                    <span
                        className="link"
                        onClick={handleAddNote}
                        style={{
                            width: 'fit-content',
                            marginTop: '10px'
                        }}
                    >
                        {state.addNote ? 'Премахване' : 'Добавяне'} на забележка
                    </span>
                </div>

            </div>
        )
    }

    const getRowsGroups = () => {
        const groups = []

        for (let i = 0; i < (state.data.rows || []).length; i++) {
            const row = state.data.rows[i]

            let group = row.group || null

            if (groups.indexOf(group) > -1) {
                continue
            }

            groups.push(group)
        }

        return groups
    }

    const handleAddGroup = (e) => {
        const groups = getRowsGroups()

        let max = 1

        groups.map(group => {
            let index = Number(group.replace(/^\D+/g, ''))

            if (index + 1 > max) {
                max = index + 1
            }
        })

        // handleAddRow(e, 'Група ' + (moment().unix()))
        handleAddRow(e, 'Група ' + max)
    }

    const handleGroupNameChange = (oldName, newName) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: prev.data.rows.map(row => {
                    if (row.group === oldName) {
                        row.group = newName || null
                    }

                    return row
                })
            }
        }))
    }

    console.log(
        getRowsGroups()
    )

    return (
        previewMode ?
            <Preview
                data={state.data}
                htmlUrl={html_url || state.data.html_url}
                updatedAt={updated_at}
                handleAddNewSubtab={handleAddNewSubtab}
                handleChangeTabs={handleChangeTabs}
                hasWritePermission={hasWritePermission}
                key={state.data}
            />
            :
            <>
                <Question
                    ref={deleteModalRef}
                    mainMessage="Сигурни ли сте, че желаете да изтриете този документ?"
                />

                <Question
                    ref={makeUnpaidModalRef}
                    mainMessage="Сигурни ли сте?"
                />

                <MakePaid
                    ref={makePaidModalRef}
                />

                <Send
                    ref={sendModalRef}
                    documentsType="documents"
                />

                <Success
                    mainMessage="Успешно запазихте документа!"
                    secondaryMessage="Може да продължите с работата."
                    agreeBtnText={state.autoSend ? 'Към изпращане' : null}
                    ref={successModalRef}
                />

                <Error
                    ref={errorModalRef}
                    mainMessage="Нещо се обърка!"
                />

                <Epay
                    ref={epayConfigModalRef}
                    message="Нямате зададени настройки за приемане на онлайн плащане чрез ePay. Моля, посочете ги тук."
                />

                <Bank
                    ref={bankConfigModalRef}
                    message="Нямате зададени настройки за приемане на банков превод. Моля, посочете ги тук."
                    appendBank={appendBank}
                />

                <PayPal
                    ref={paypalConfigModalRef}
                    message="Нямате зададени настройки за приемане на онлайн плащане чрез PayPal. Моля, посочете ги тук."
                />

                <Stripe
                    ref={stripeConfigModalRef}
                    message="Нямате зададени настройки за приемане на онлайн плащане чрез Stripe. Моля, посочете ги тук."
                />


                <section
                    id="documents"
                    key={state.data}
                >
                    <form
                        ref={formRef}
                        onSubmit={handleSave}
                        className="wrapperd"
                    >
                        {/* {state.data.accountsdata &&
                            <div>
                                Документът е съставен по сметка №: {state.data.accountsdata.map((a, i) =>
                                    <Fragment key={a.account_id}>
                                        <span className="link" onClick={e => showAccount(a.account_id)}>
                                            {a.account_id}
                                        </span>
                                        <span>
                                            {i !== state.data.accountsdata.length - 1 && ', '}
                                        </span>
                                    </Fragment>
                                )}
                            </div>
                        } */}
                        <div className="scalable-holder">
                            {/* {editMode ?
                                <div className="row document-options">
                                    <div className={`buttons ${!state.disableSave ? 'disabled' : ''}`}>
                                        {state.data.is_payable && !state.data.is_paid &&
                                            <button
                                                className="make-paid"
                                                onClick={handleMakePaid}
                                            >
                                                Платена
                                            </button>
                                        }

                                        {state.data.is_payable && state.data.is_paid &&
                                            <button
                                                className="make-paid"
                                                onClick={handleMakeUnpaid}
                                            >
                                                Неплатена
                                            </button>
                                        }

                                        <button
                                            className="normal download"
                                            onClick={handleDownload}
                                        >
                                            Изтегляне
                                        </button>

                                        <button
                                            className="normal print"
                                            onClick={handlePreview}
                                        >
                                            Принтиране
                                        </button>

                                        <button
                                            className="normal send"
                                            onClick={handleSend}
                                        >
                                            Изпращане
                                        </button>

                                        {state.data.allow_delete &&
                                            <button
                                                className="remove small"
                                                onClick={handleDelete}
                                            >

                                            </button>
                                        }

                                    </div>
                                </div>
                                :
                                <>
                                </>
                            } */}
                            <div id="providers" className={`t-${timestamp}`}>
                                <div className="row">

                                    {/* получател */}
                                    <div className={`col client ${showAllClientFields() ? 'fields-shown' : ''}`}>
                                        <div className="panel client-holder">

                                            <div className="heading row">
                                                Клиент {state.data?.customerinfo?.id ?
                                                    <ClientConfig
                                                        clientId={state.data?.customer?.id}
                                                        clientName={state.data?.customer?.info?.name}
                                                        clientEik={state.data?.customer?.info?.eikegn}
                                                        clientAddresses={state.data?.customer?.addresses}
                                                        activeClientAddressId={state.data?.customeraddress?.id}
                                                        handleChangeCustomerTradeObject={handleChangeCustomerTradeObject}
                                                    />
                                                    :
                                                    <></>
                                                }
                                            </div>
                                            <CustomerDeepData
                                                showAllClientFields={showAllClientFields}
                                                data={state.data}
                                                validations={validations}
                                                handleCustomerChange={handleCustomerChange}
                                                handleFocusToChangeBg={handleFocusToChangeBg}
                                                focusedInputName={focusedInputName}
                                                handleCustomerEikChange={handleCustomerEikChange}
                                                setState={setState}
                                                state={state}
                                                isVatNumberValid={isVatNumberValid}
                                                handleInputChange={handleInputChange}
                                                handleCustomerSettlementChange={handleCustomerSettlementChange}
                                                handleCheckCustomerVatNumber={handleCheckCustomerVatNumber}
                                                colNameRef={colNameRef}
                                                handleShowClientHiddenFields={handleShowClientHiddenFields}
                                                tradeRegistryReqUrl="documents/customers/trade-registry"
                                                customersFindReqUrl="documents/customers/find"
                                                noTradeObject={true}
                                            />
                                        </div>
                                    </div>
                                    {/* дата */}
                                    <div className="col small central-styled">
                                        <div className="panel">
                                            <span className="sign">
                                                {titles[getActiveTabTypeId()][editMode ? 'edit' : 'add']}
                                            </span>
                                            <h3
                                                className="heading text-center"
                                                style={{ fontSize: titles[getActiveTabTypeId()].fontSize ?? '' }}
                                            >
                                                {titles[getActiveTabTypeId()].type || ''}
                                            </h3>
                                            <div
                                                className="row"
                                                style={{
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <div
                                                    className={`col fillable-input ${Boolean(validations && validations.no) ? 'validate' : ''} ${Number((state.data?.no || '').length) === 1 ? 'light-border' : ''}`}
                                                >
                                                    {(validations?.no || [])[0] === 'validation.unique' ?
                                                        <NextNumber
                                                            handleInsertNextNumber={handleInsertNextNumber}
                                                            typeId={getActiveTabTypeId()}
                                                        />
                                                        :
                                                        <>
                                                        </>
                                                    }

                                                    <DocumentNumber
                                                        number={state.data?.no}
                                                        handleInputChange={handleInputChange}
                                                        companyId={state.data?.company?.id}
                                                        companyAddressId={state.data?.companyaddress?.id}
                                                        typeId={getActiveTabTypeId()}
                                                    />


                                                    {/* <Autocomplete
                                                            ref={documentNumberInputRef}
                                                            variant="standard"
                                                            size="small"
                                                            // inputPlaceholder="№"
                                                            inputName="no"
                                                            inputIdName="serial_id"
                                                            url="documents/next-numbers"
                                                            params={{
                                                                company_id: state.data?.company?.id,
                                                                company_address_id: state.data?.companyaddress?.id,
                                                                type_id: getActiveTabTypeId()
                                                            }}
                                                            selectedId={state.data?.serial_id || null}
                                                            selectedValue={state.data?.no || '0000000001'}
                                                            requiredParams={['company_id', 'type_id']}
                                                            getOptionLabel={option => option?.next_number || ''}
                                                            renderOption={option => option?.next_number || ''}
                                                            getResultData={data => data}
                                                            onChange={handleNumberChange}
                                                            onInputChange={handleNumberChange}
                                                            error={Boolean(validations && validations.no)}
                                                            // helperText={validations && validations.no && (validations.no[0] || validations.no)}
                                                            disableSearch={editMode ? true : false}
                                                            autoSelect
                                                        /> */}
                                                    <span className="label">
                                                        Номер на {(titles[getActiveTabTypeId()].shortType || titles[getActiveTabTypeId()].type || '').toLowerCase()}
                                                    </span>
                                                </div>
                                                <span className="separator">
                                                    /
                                                </span>
                                                <div className="col">
                                                    <DatePicker
                                                        reddit
                                                        fullWidth
                                                        // label="Дата"
                                                        name="date"
                                                        value={state.data?.date_ymd || today}
                                                        onChange={e => {
                                                            handleDateChange(e.target.value)
                                                        }}
                                                    />
                                                    <span className="label">
                                                        Дата на издаване
                                                    </span>
                                                </div>
                                            </div>
                                            {(isCreditNote() || isDebitNote()) &&
                                                <>
                                                    <div
                                                        className="row"
                                                        style={{
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <div
                                                            className={`col fillable-input ${!state.data?.related_no?.length ? 'light-border' : ''}`}
                                                        >
                                                            <div className='no-row'>
                                                                <span className='no-label'>
                                                                    №
                                                                </span>
                                                                <Autocomplete
                                                                    ref={relatedNoFieldRef}
                                                                    variant="standard"
                                                                    size="small"
                                                                    inputName="related_no"
                                                                    inputIdName="serial_related_no"
                                                                    url={isCreditNote() ? "documents/find-invoice-for-creditnote" : 'documents/find-invoice-for-debitnote'}
                                                                    params={{
                                                                        customer_id: state.data.customer?.id,
                                                                        no: null
                                                                    }}
                                                                    selectedValue={state.data?.related_no || '0000000001'}
                                                                    requiredParams={['customer_id']}
                                                                    getOptionLabel={option => option?.serial_related_no || ''}
                                                                    renderOption={option => <div className="related-number-option">{`№${option?.related_no}/${moment(option.related_date, 'YYY-MM-DD').format('DD.MM.YYYY')}, `}<span>{`${splitLongNumbers(option.gross_price_formatted)}${option.currency.name}` || ''}</span></div>}
                                                                    error={Boolean(validations && validations.related_no)}
                                                                    helperText={validations && validations.related_no}
                                                                    onChange={appendInvoiceForCreditOrDebitNote}
                                                                    onInputChange={handleRelatedNumberChange}
                                                                    disableSearch={editMode ? true : false}
                                                                    disabled={Boolean(state.data?.has_invoice)}
                                                                />
                                                            </div>
                                                            <span className="label">
                                                                Към фактура №
                                                            </span>
                                                        </div>
                                                        <span className="separator">
                                                            /
                                                        </span>
                                                        <div className="col">
                                                            <DatePicker
                                                                reddit
                                                                fullWidth
                                                                // label="Дата"
                                                                name="related_date"
                                                                value={state.data?.related_date_ymd || today || null}
                                                                onChange={e => handleDateChange(e.target.value, 'related_date')}
                                                                disabled={Boolean(state.data?.has_invoice)}
                                                            />
                                                            <span className="label">
                                                                Дата
                                                            </span>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>

                                    {/* доставчик */}
                                    <div
                                        className="col"
                                    >
                                        <div className={`panel deliverer`}>
                                            <div className="heading">
                                                Доставчик
                                            </div>
                                            <div className="col">
                                                <div className="row">
                                                    <div className="col">
                                                        Име:
                                                    </div>
                                                    <div className="col">
                                                        {state.data?.companyinfo?.name || ''}
                                                    </div>
                                                </div>
                                                <div className="row double">
                                                    <div className="col">
                                                        <div className="row">
                                                            <div className="col">
                                                                ЕИК:
                                                            </div>
                                                            <div className="col">
                                                                {state.data?.companyinfo?.eikegn || ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="row">
                                                            <div className="col">
                                                                ИН ПО ЗДДС:
                                                            </div>
                                                            <div className="col">
                                                                {state.data?.companyinfo?.vat_number || ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col">
                                                        МОЛ:
                                                    </div>
                                                    <div className="col">
                                                        {state.data?.companyaddress?.mol || ''}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        Град:
                                                    </div>
                                                    <div className="col">
                                                        {state.data?.companyaddress?.settlement?.name || ''}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        Адрес:
                                                    </div>
                                                    <div className="col">
                                                        {state.data?.companyaddress?.address || ''}
                                                    </div>
                                                </div>
                                                <DelivererBankDetails
                                                    selected={state.data?.companybank}
                                                    banks={state.data?.company?.banks}
                                                    handleChangeBank={handleChangeBank}
                                                    handleBankModal={bankConfigModalRef}
                                                    companyId={state.data?.company?.id}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {isOffer() ?
                                <div id="intro">
                                    <div className="panel">
                                        <RedditTextField
                                            fullWidth
                                            label="Уводен текст"
                                            name="offer_intro"
                                            value={state.data.offer_intro || ''}
                                            multiline
                                            rows="3"
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                :
                                <>
                                </>
                            }

                            {isOffer()
                                ?
                                <div
                                    id="articles"
                                    className={state.loadingData ? 'disabled' : ''}
                                >
                                    {getRowsGroups().map((group, i) =>

                                        <div key={i} className="panel">
                                            {getRowsGroups().length > 1 &&
                                                <TextField
                                                    variant="outlined"
                                                    margin="none"
                                                    size="small"
                                                    fullWidth
                                                    type="text"
                                                    value={group || ''}
                                                    onChange={e => handleGroupNameChange(group, e.target.value)}
                                                    style={{
                                                        marginTop: '10px'
                                                    }}
                                                />
                                            }
                                            {renderRows(group)}
                                            {getRowsGroups().length - 1 === i &&
                                                renderVatFields()
                                            }
                                        </div>
                                    )}

                                    <button
                                        className="add"
                                        onClick={handleAddGroup}
                                        style={{
                                            marginLeft: '12px'
                                        }}
                                    >
                                        Нова група
                                    </button>
                                </div>
                                :
                                <div
                                    id="articles"
                                    className={state.loadingData ? 'disabled' : ''}
                                >

                                    <div className="panel">
                                        {/* <div className="heading">
                                        Добавяне на Артикули / Услуги
                                    </div> */}


                                        {renderRows()}
                                        {renderVatFields()}

                                    </div>
                                </div>
                            }
                            {isOffer() ?
                                <div id="outro">
                                    <div className="panel">
                                        <RedditTextField
                                            fullWidth
                                            label="Заключителен текст"
                                            name="offer_footer"
                                            value={state.data.offer_footer || ''}
                                            multiline
                                            rows="3"
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                :
                                <>
                                </>
                            }
                            <div id="payments">
                                <div className="row">
                                    {(isProforma() || isInvoice()) &&
                                        <div
                                            className="col"
                                            style={{
                                                // flex: 0.6,
                                                minWidth: '600px',
                                                flexShrink: 0
                                            }}
                                        >
                                            <div className="panel">
                                                {/* <div className="heading">
                                                    Метод на плащане
                                                </div> */}
                                                <div className="row">
                                                    <div
                                                        className="col"
                                                        style={{
                                                            minWidth: '200px'
                                                        }}
                                                    >
                                                        <RedditSelect
                                                            name="payment_method_id"
                                                            value={state.data.payment_method_id || ''}
                                                            onChange={handlePaymentMethodChange}
                                                            label="Метод на плащане"
                                                            key={state.data.payment_method_id}
                                                        >
                                                            {paymentMethods.data.map(method =>
                                                                <MenuItem
                                                                    key={'method-' + method.id}
                                                                    value={method.id}
                                                                >
                                                                    <img src={PAYMENT_EXTRA_DATA[method.name].icon} />
                                                                    {method?.translation?.name}

                                                                    {isPaymentAvailable(method?.name || '') ?
                                                                        <img src={checkFilledIcon} />
                                                                        :
                                                                        <>
                                                                        </>
                                                                    }
                                                                </MenuItem>
                                                            )}
                                                        </RedditSelect>

                                                    </div>

                                                    <div
                                                        className="col"
                                                    >
                                                        <DatePicker
                                                            reddit
                                                            fullWidth
                                                            label="Данъчно събитие"
                                                            disableToolbar
                                                            format="dd/MM/yyyy"
                                                            value={state.data?.pay_to_date_ymd || state.data?.date_ymd || today}
                                                            name="pay_to_date"
                                                            // defaultValue={state.data?.date_ymd || today}
                                                            onChange={handlePayToDateChange}
                                                        />
                                                    </div>
                                                    <div
                                                        className="col"
                                                    >
                                                        <DatePicker
                                                            reddit
                                                            fullWidth
                                                            label="Дата на падеж"
                                                            disableToolbar
                                                            format="dd/MM/yyyy"
                                                            value={state.data?.term_date_ymd || state.data?.date_ymd || today}
                                                            name="term_date"
                                                            // defaultValue={state.data?.date_ymd || today}
                                                            onChange={handleTermDateChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    }
                                    <div
                                        className="col"
                                        style={{
                                            flex: 1
                                        }}
                                    >

                                        <div className="panel">
                                            {/* <div className="heading">
                                                Други
                                            </div> */}

                                            <div className="row">
                                                {isOffer() &&
                                                    <div
                                                        className="col"
                                                    >
                                                        <DatePicker
                                                            reddit
                                                            fullWidth
                                                            label="Валидна до дата"
                                                            disableToolbar
                                                            format="dd/MM/yyyy"
                                                            value={state.data?.term_date_ymd || ''}
                                                            name="term_date"
                                                            // defaultValue={state.data?.date_ymd || today}
                                                            onChange={handleTermDateChange}
                                                        />
                                                    </div>
                                                }
                                                <div
                                                    className="col"
                                                    style={{ flex: 1 }}
                                                >
                                                    <RedditTextField
                                                        fullWidth
                                                        margin="dense"
                                                        size="small"
                                                        label="Място на сделката"
                                                        name="deal_location"
                                                        value={state.data.deal_location || ''}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div
                                                    className="col"
                                                    style={{ flex: 1 }}
                                                >
                                                    <RedditTextField
                                                        fullWidth
                                                        margin="dense"
                                                        size="small"
                                                        label="Получател"
                                                        name="recipient"
                                                        key={state.data?.customeraddress?.id}
                                                        // defaultValue={}
                                                        value={state.data.recipient || shortenToMaximumFirstAndLastName(state.data?.customeraddress?.mol) || ''}
                                                        onChange={e => {
                                                            handleInputChange(e)
                                                        }}
                                                    />
                                                </div>
                                                <div
                                                    className="col"
                                                    style={{ flex: 1 }}
                                                >
                                                    <RedditTextField
                                                        fullWidth
                                                        margin="dense"
                                                        size="small"
                                                        label="Съставил"
                                                        name="author"
                                                        value={state.data.author || ''}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div id="footer">
                            <Menu
                                anchorEl={state.saveButtonMenu}
                                elevation={0}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                open={Boolean(state.saveButtonMenu)}
                                onClose={closeMenu}
                                onClick={closeMenu}
                            >
                                <Tooltip
                                    title="Запазване на документа"
                                >
                                    <MenuItem
                                        onClick={() => {
                                            handleSaveOptions('save_only')
                                        }}
                                    >
                                        Запазване
                                    </MenuItem>
                                </Tooltip>
                                <Tooltip
                                    title="Запазване на документа и изпращане на клиент"
                                >
                                    <MenuItem
                                        onClick={() => {
                                            handleSaveOptions('save_and_send')
                                        }}
                                    >
                                        Запазване и изпращане
                                    </MenuItem>
                                </Tooltip>

                            </Menu>
                            <Button
                                className="cancel"
                                onClick={handleCloseTab}
                            >
                                Отказ
                            </Button>
                            <SaveButton
                                className="save with-dropdown"
                                loading={state.loading}
                                disabled={state.disableSave}
                            >
                                Запази
                                <i
                                    className="menu"
                                    onClick={openMenu}
                                >
                                    <img src={dropArrowIcon} />
                                </i>
                            </SaveButton>
                        </div>
                    </form>
                </section>
            </>
    )
}

export default Index