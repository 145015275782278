import { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom"
import moment from "moment"

//providers
import { useAuthDataContext } from "providers/Auth"
import { useAppContext } from 'providers/App'

//helpers
import { useQuery } from 'helpers/Url'
import Autocomplete from 'components/misc/Dropdown'
import Category from 'components/partials/filter/Category'
import DatePicker from 'components/partials/filter/DatePicker'
import RedditTextField from 'components/misc/RedditTextField'

function Filter(props) {
    const app = useAppContext();
    const history = useHistory()
    const query = useQuery()

    const auth = useAuthDataContext();

    const [filters, setFilters] = useState({
        show: false,
    });

    useEffect(() => {
        //
    }, []);

    const handleDateChange = (date, name) => {
        props.handleSearch(name, moment(date).format('YYYY-MM-DD'));
    }

    const handleSearch = e => {
        const name = e.target.name
        const value = e.target.value

        props.handleSearch(name, value)
    }

    const handleFromDateChange = e => {
        const name = e.target.name
        const value = e.target.value

        let date = value ? moment(value).format('YYYY-MM-DD') : '';

        props.handleSearch({
            created_from_date: date,
            created_to_date: date,
        });

        // app.getLockedFilter('orders_created_from_date').setValue(date);
        // app.getLockedFilter('orders_created_to_date').setValue(date);
    }

    const handleToDateChange = e => {
        const value = e.target.value

        let date = value ? moment(value).format('YYYY-MM-DD') : '';

        if (date && props.filter.created_from_date > date) {
            return;
        }

        props.handleSearch({
            created_to_date: date,
        });

        // app.getLockedFilter('orders_created_to_date').setValue(date);
    }

    const handleDeliveryFromDateChange = e => {
        const name = e.target.name
        const value = e.target.value

        let date = value ? moment(value).format('YYYY-MM-DD') : '';

        props.handleSearch({
            delivery_from_date: date,
            delivery_to_date: date,
        });
    }

    const handleDeliveryToDateChange = e => {
        const value = e.target.value

        let date = value ? moment(value).format('YYYY-MM-DD') : '';

        if (date && props.filter.delivery_from_date > date) {
            return;
        }

        props.handleSearch({
            delivery_to_date: date,
        });
    }

    const handleStatusChange = data => {
        let checked = data.map(d => d.id);

        props.handleSearch('status_id', checked);

        // app.getLockedFilter('orders_status_id').setValue(checked);
    }

    const handleChangeStore = data => {
        let checked = data.map(d => d.id);

        props.handleSearch('store_id', checked);

        // app.getLockedFilter('orders_store_id').setValue(checked);
    }

    const handleChangeFromStore = data => {
        let checked = data.map(d => d.id);

        props.handleSearch('from_store_id', checked);

        // app.getLockedFilter('orders_from_store_id').setValue(checked);
    }

    const handleChangeArticle = data => {
        props.handleSearch({
            article_id: data?.id || '',
        });
    }

    const handleCategoryChange = selected => {
        props.handleSearch('category_id', selected)
    }

    const handleChangeUser = data => {
        props.handleSearch({
            creator_id: data?.id || '',
        });
    }

    const handleChangeClient = data => {
        props.handleSearch({
            client_id: data?.id || '',
        });
    }

    const handleChangeSpeditor = data => {
        let checked = data.map(d => d.id);

        props.handleSearch({
            speditor_id: checked
        });
    }

    const handleChangePaymentMethod = data => {
        let checked = data.map(d => d.id);

        props.handleSearch({
            paymethod_id: checked
        });
    }

    const toggleFilters = () => {
        setFilters(prev => ({
            ...prev,
            show: !prev.show,
        }))
    }

    const getMoreFiltersValue = () => {
        let data = [];

        if ((props.filter.category_id || []).length > 0) {
            data.push(1);
        }

        if (props.filter.created_from_date) {
            data.push(1);
        }

        if (props.filter.created_to_date) {
            data.push(1);
        }

        if (props.filter.delivery_from_date) {
            data.push(1);
        }

        if (props.filter.delivery_to_date) {
            data.push(1);
        }

        if (props.filter.creator_id) {
            data.push(1);
        }

        if ((props.filter.speditor_id || []).length > 0) {
            data.push(1);
        }

        if ((props.filter.paymethod_id || []).length > 0) {
            data.push(1);
        }

        if (props.filter.deleted) {
            data.push(1);
        }

        if (data.length === 0) {
            return 'Няма избрани';
        }

        return data.length + ' избрани';
    }

    return (
        <>
            <div className="row filter">
                <div
                    className="col col-auto col-md-2 col-12 mb-2"
                >
                    <RedditTextField
                        fullWidth
                        label="Търсене..."
                        name="search"
                        value={props.filter.search || ''}
                        onChange={handleSearch}
                        autoFocus
                    />
                </div>
                <div
                    className="col col-auto col-md-2 col-12 mb-2"
                >
                    <RedditTextField
                        fullWidth
                        label="Номер заявка"
                        name="order_num"
                        value={props.filter.order_num || ''}
                        onChange={handleSearch}
                    />
                </div>
                <div
                    className="col col-auto col-md-2 col-12 mb-2"
                >
                    <Autocomplete
                        margin="none"
                        size="small"
                        variant="filled"
                        inputPlaceholder="Статус"
                        url="store/orders/statuses"
                        inputIdName="status_id[]"
                        selectedIds={props.filter.status_id || []}
                        multiple
                        onChange={handleStatusChange}
                        filterable={false}
                        lock="orders_status_id"
                    />
                </div>
                {/* <div
                    className="col col-auto col-md-2 col-12 mb-2"
                >
                    <Autocomplete
                        url="autocomplete/stores"
                        params={{
                            // permission: 'clientorders_index'
                            all: 1,
                        }}
                        inputPlaceholder="ТО обработка"
                        selectedIds={props.filter.store_id || []}
                        renderText={data => data?.translation?.name || data?.name}
                        onChange={data => handleChangeStore(data)}
                        multiple
                        filterable={false}
                        lock="orders_store_id"
                    />
                </div> */}
                <div
                    className="col col-auto col-md-2 col-12 mb-2"
                >
                    <Autocomplete
                        margin="none"
                        size="small"
                        variant="filled"
                        inputPlaceholder="Клиент"
                        url="store/autocomplete/clients"
                        inputIdName="client_id"
                        // inputValue={state.data?.supplier?.name}
                        inputIdValue={props.filter.client_id || ''}
                        onChange={handleChangeClient}
                        onInputChange={handleChangeClient}
                        renderText={data => data.info?.name}
                    />
                </div>
                <div
                    className="col col-auto col-md-2 col-12 mb-2"
                >
                    <Autocomplete
                        margin="none"
                        size="small"
                        variant="filled"
                        inputPlaceholder="Артикул"
                        url="store/autocomplete/articles"
                        inputIdName="article_id"
                        // inputValue={state.data?.supplier?.name}
                        inputIdValue={props.filter.article_id || ''}
                        onChange={handleChangeArticle}
                        onInputChange={handleChangeArticle}
                        renderText={data => {
                            return (
                                <div style={{ display: 'flex', width: '100%' }}>
                                    <div style={{ width: 'max-content', marginRight: '10px' }}>
                                        {data.id}
                                    </div>
                                    <div>
                                        {data.name}
                                        <br />
                                        {data.article_name}
                                    </div>
                                </div>
                            )
                        }}
                        renderInputText={data => data.article_name}
                    />
                </div>

                {/* <div
                    className="col col-auto col-md-2 col-12 mb-2"
                >
                    <Category
                        selected={props.filter.category_id || []}
                        onChange={handleCategoryChange}
                        position="end"
                    />
                </div> */}
                <div
                    className="col col-auto col-md-2 col-12 mb-2"
                >
                    <DatePicker
                        placeholder="Създаден от"
                        name="created_from_date"
                        value={props.filter.created_from_date || ''}
                        onChange={handleFromDateChange}
                        lock="orders_created_from_date"
                    />
                </div>
                <div
                    className="col col-auto col-md-2 col-12 mb-2"
                >
                    <DatePicker
                        placeholder="Създаден до"
                        name="created_to_date"
                        value={props.filter.created_to_date || ''}
                        onChange={handleToDateChange}
                        lock="orders_created_to_date"
                    />
                </div>
                {/* <div
                    className="col col-auto col-md-2 col-12 mb-2"
                >
                    <DatePicker
                        placeholder="За дата от"
                        name="delivery_from_date"
                        value={props.filter.delivery_from_date || ''}
                        onChange={handleDeliveryFromDateChange}
                    />
                </div>
                <div
                    className="col col-auto col-md-2 col-12 mb-2"
                >
                    <DatePicker
                        placeholder="За дата до"
                        name="delivery_to_date"
                        value={props.filter.delivery_to_date || ''}
                        onChange={handleDeliveryToDateChange}
                    />
                </div> */}

                {/* <div
                    className="col col-auto col-md-2 col-12 mb-2"
                >
                    <Autocomplete
                        inputPlaceholder="Начин на плащане"
                        url="autocomplete/payment-methods"
                        inputIdName="paymethod_id[]"
                        selectedIds={props.filter.paymethod_id || []}
                        onChange={handleChangePaymentMethod}
                        onInputChange={handleChangePaymentMethod}
                        multiple
                        filterable={false}
                    />
                </div> */}

                {/* <div
                    className="col col-auto col-md-2 col-12 mb-2"
                >
                    <FloatingLabel label="Изтрити">
                        <Form.Select
                            name="deleted"
                            value={props.filter.deleted || ''}
                            onChange={handleSearch}
                        >
                            <option value="">По подразбиране</option>
                            <option value="with-deleted">Покажи изтрити</option>
                            <option value="only-deleted">Покажи само изтрити</option>
                        </Form.Select>
                    </FloatingLabel>
                </div> */}

            </div>

            {/* <div>
                <Button
                    size="sm"
                >
                    Покажи още
                </Button>
            </div> */}
        </>
    )
}

export default Filter;