import { useEffect, useRef, useState } from "react"

//helpers
import Api from "helpers/Api"

//libraries
import axios from "axios"

function useFetchAllActivitiesDashboard(clientId = null) {
    const [loading, setLoading] = useState(true)
    const [activities, setActivities] = useState([])
    const [filters, setFilters] = useState({
        search: '',
    })

    const reqCancelTokenRef = useRef(null)

    useEffect(() => {
        _fetchData()
    }, [filters, clientId])

    function _fetchData() {
        if (!clientId) {
            console.error('client id is required')

            return
        }

        if (reqCancelTokenRef.current) reqCancelTokenRef.current.cancel()
        reqCancelTokenRef.current = axios.CancelToken.source()

        setLoading(true)

        Api.get(`store/clients/dashboard/all-activities?client_id=${clientId}&search=${getSearchValue()}`, {
            cancelToken: reqCancelTokenRef.current.token
        })
            .then(res => {
                _setActivities(res.data || [])
            })
            .finally(() => {
                setLoading(false)
            })

    }

    function _setActivities(data) {
        setActivities(data)
    }

    function getActivities() {
        return activities
    }

    function refetchData() {
        _fetchData()
    }

    function handleSearch(e) {
        const { value } = e.target

        setFilters(prev => ({
            ...prev,
            search: value
        }))
    }

    function getSearchValue() {
        return filters?.search || ''
    }

    function refetchData() {
        _fetchData()
    }

    return {
        getActivities,
        loadingActivities: loading,
        refetchData,
        handleSearch,
        getSearchValue,
        refetchData
    }
}

export default useFetchAllActivitiesDashboard