//hooks
import useFetchAllActivitiesDashboard from "../hooks/useFetchAllActivitiesDashboard"

//components
import RedditTextField from "components/misc/RedditTextField"
import AllActivitiesDashboardTable from "./AllActivitiesDashboardTable"

function AllActivitiesDashboard({ clientId, handleClose }) {
  const {
    loadingActivities,
    getActivities,
    handleSearch,
    getSearchValue,
    refetchData,
  } = useFetchAllActivitiesDashboard(clientId)

  return (
    <>
      <div className="row filter">
        <div
          className="col"
          style={{
            maxWidth: "300px",
          }}
        >
          <RedditTextField
            size="small"
            margin="dense"
            fullWidth
            label="Търсене..."
            name="search"
            value={getSearchValue()}
            onChange={handleSearch}
          />
        </div>
      </div>
      <AllActivitiesDashboardTable
        loadingActivities={loadingActivities}
        getActivities={getActivities}
        clientId={clientId}
        refetchData={refetchData}
        handleClose={handleClose}
      />
    </>
  )
}

export default AllActivitiesDashboard
