import { useRef, useState } from "react"

//librarires
import axios from "axios"

//helpers
import Api from "helpers/Api"

//providers
import { useAppContext } from "providers/App"

function useSetActiveTradeObject() {
    const { handleSuccess } = useAppContext()

    const [isLoading, setIsLoading] = useState(false)

    const cancelToken = useRef()

    function setActiveTradeObject(customerId, addressId) {
        if (cancelToken.current) cancelToken.current.cancel()

        cancelToken.current = axios.CancelToken.source()

        setIsLoading(true)

        Api.get('customers/set-default-address', {
            params: {
                customer_id: customerId,
                address_id: addressId
            },
            cancelToken: cancelToken.current.token,
        })
            .then(() => {
                handleSuccess("Основния търговски обект е променен успешно!")
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return {
        isLoading,
        setActiveTradeObject,
    }
}

export default useSetActiveTradeObject