import { Card } from 'react-bootstrap'
import { Button } from '@material-ui/core'
import Invoices from './partials/Invoices'
import Documents from './partials/Documents'

function Index(props) {

    return (
        <div className="preview-bill-documents">
            <div className="options-bar">
                <Button className="cancel" onClick={e => props.linkDocument()}>
                    Прикачи документ
                </Button>
                <Button className="add" onClick={e => props.addDocument()}>
                    Нов документ
                </Button>
            </div>
            <div className="row">
                <div className="col">
                    <h6>
                        Списък с документи
                    </h6>
                    <div className="field">
                        <Invoices
                            id={props.id}
                            showInvoice={props.showInvoice}
                            deleteInvoice={props.deleteInvoice}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index