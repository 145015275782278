import { TYPES } from "constants/imports";

export const ROWS = {
    [TYPES.ARTICLE]: [
        require('./columns/article/ArticleName').default,
        require('./columns/article/PublicName').default,
        require('./columns/article/RefNum').default,
        require('./columns/article/ArtNum').default,
        require('./columns/article/Barcode').default,
        require('./columns/article/ActualPrice').default,
        require('./columns/article/AvgDeliveryPrice').default,
        require('./columns/article/AmountUnit').default,
        require('./columns/article/AmountType').default,
        require('./columns/article/TaxGroup').default,
        require('./columns/article/Deleted').default,
        require('./columns/article/IsForSale').default,
        require('./columns/article/IsService').default,
        require('./columns/article/IsPublic').default,
        require('./columns/article/MinQuantity').default,
        require('./columns/article/MaxQuantity').default,
        require('./columns/article/Code').default,
        require('./columns/article/Intrastat').default,
        require('./columns/article/Description').default,
        require('./columns/article/Slug').default,
        require('./columns/article/ArticleID').default,
        require('./columns/article/Category').default,
        require('./columns/article/CreatedAt').default,
        require('./columns/article/ArticleDetail').default,
        require('./columns/article/File').default,
        require('./columns/article/Supplier').default,
        require('./columns/article/DeliveryPrice').default,
        require('./columns/Ignore').default,

        // зареждане
        require('./columns/article/Amount').default,
        require('./columns/article/LoadPrice').default,
        require('./columns/article/LoadNote').default,
    ],
    [TYPES.STORELOAD]: [
        require('./columns/storeload/ArticleID').default,
        require('./columns/storeload/ArticleName').default,
        require('./columns/storeload/Barcode').default,
        require('./columns/storeload/RefNum').default,
        require('./columns/storeload/Amount').default,
        require('./columns/storeload/CurrentPrice').default,
        require('./columns/storeload/TaxGroup').default,
        require('./columns/storeload/Note').default,
        require('./columns/Ignore').default,
    ],
    [TYPES.STORELOADCHECKUP]: [
        require('./columns/storeload/ArticleID').default,
        require('./columns/storeload/ArticleName').default,
        require('./columns/storeload/Barcode').default,
        require('./columns/storeload/RefNum').default,
        require('./columns/storeload/Amount').default,
        require('./columns/Ignore').default,
    ],
    [TYPES.STOREMOVE]: [
        require('./columns/storemove/ArticleID').default,
        require('./columns/storemove/ArticleName').default,
        require('./columns/storemove/Barcode').default,
        require('./columns/storemove/RefNum').default,
        require('./columns/storemove/Amount').default,
        require('./columns/storemove/Note').default,
        require('./columns/Ignore').default,
    ],
    [TYPES.STOREMOVECHECKUP]: [
        require('./columns/storemove/ArticleID').default,
        require('./columns/storemove/ArticleName').default,
        require('./columns/storemove/Barcode').default,
        require('./columns/storemove/RefNum').default,
        require('./columns/storemove/Amount').default,
        require('./columns/Ignore').default,
    ],
    [TYPES.REVISION]: [
        require('./columns/revision/ArticleID').default,
        require('./columns/revision/ArticleName').default,
        require('./columns/revision/Barcode').default,
        require('./columns/revision/RefNum').default,
        require('./columns/revision/Amount').default,
        require('./columns/revision/Reason').default,
        require('./columns/Ignore').default,
    ],
    [TYPES.ACCOUNT]: [
        require('./columns/account/ArticleID').default,
        require('./columns/account/ArticleName').default,
        require('./columns/account/Barcode').default,
        require('./columns/account/RefNum').default,
        require('./columns/account/Amount').default,
        require('./columns/account/CurrentPrice').default,
        require('./columns/account/TaxGroup').default,
        require('./columns/Ignore').default,
    ],
    [TYPES.PRICERULE]: [
        require('./columns/pricerule/ArticleID').default,
        require('./columns/pricerule/ArticleName').default,
        require('./columns/pricerule/Barcode').default,
        require('./columns/pricerule/RefNum').default,
        require('./columns/pricerule/PreferedPrice').default,
        require('./columns/Ignore').default,
    ],
    [TYPES.ARTICLEGROUP]: [
        require('./columns/pricerule/ArticleID').default,
        require('./columns/pricerule/ArticleName').default,
        require('./columns/pricerule/Barcode').default,
        require('./columns/pricerule/RefNum').default,
        require('./columns/Ignore').default,
    ],
}