import { trans } from "providers/Translation";

export const settings = [
    {
        key: 'lang_id',
        name: trans('companies/settings.lang'),
        type: 'select',
        options: [

        ],
        disableClearable: true,
    },
    {
        key: 'currency_id',
        name: trans('companies/settings.currency'),
        type: 'select',
        options: [

        ],
        disableClearable: true,
    },
    {
        key: 'set_expenses_with_vat',
        name: trans('companies/settings.set_expenses_with_vat'),
        type: 'select',
        options: [
            {
                id: 0,
                name: 'НЕ'
            },
            {
                id: 1,
                name: 'ДА'
            }
        ],
        disableClearable: true,
    },
    {
        key: 'set_incomes_with_vat',
        name: trans('companies/settings.set_incomes_with_vat'),
        type: 'select',
        options: [
            {
                id: 0,
                name: 'НЕ'
            },
            {
                id: 1,
                name: 'ДА'
            }
        ],
        disableClearable: true,
    },
    {
        key: 'documents_logo',
        name: trans('companies/settings.documents_logo'),
        type: 'input-file',
        multiple: false,
    },
    // {
    //     key: 'test',
    //     name: 'test',
    //     type: 'input-text',
    // }
]