import { useEffect, useImperativeHandle, useRef, forwardRef } from "react"
import Autocomplete from "components/misc/Dropdown"
import Alert from 'components/misc/Alert'
import { Button } from "@material-ui/core"
import HelpInfo from 'components/misc/Info'
import { TrashFill } from "react-bootstrap-icons"
import Api from "helpers/Api"
import moment from "moment"
import Loader from "components/misc/Loader"
import { useAppContext } from "providers/App"
import { useValidation } from "helpers/Validation"
import SaveButton from 'components/misc/Button'
import { useNestedState } from "helpers/NestedState"
import Refs from "Refs"
import RedditTextField from "components/misc/RedditTextField"
import { InputAdornment } from '@material-ui/core'
import DatePicker from "components/misc/DatePicker"
import { Skeleton } from "@material-ui/lab"
import TextField from "components/misc/TextField"


let timeout

function Index(props, ref) {

    const app = useAppContext()

    const formRef = useRef(null)

    const [state, setState] = useNestedState({
        data: {
            suppliers: []
        },
        pages: 0,
        total: 0,
        totals: {},
        loading: true,
        filter: {
            page: 1,
        },
        refresh: false,
        loadingSave: false
    })

    const [validations, setValidations] = useValidation()

    const { show, hide } = props

    useEffect(() => {

        loading(true)

        Api.get('store/articles/suppliers', {
            params: {
                id: props.id,
                ...state.filter,
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: {
                    suppliers: res.data
                },
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                },
            }))

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 })
            }

            loading(false)
            sorting(false)
        })
    }, [props.id, state.refresh])

    useImperativeHandle(ref, () => ({
        save: () => {
            handleSave()
        }
    }))

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }))
    }

    const sorting = (sorting) => {
        setState(prev => ({
            ...prev,
            sorting: Boolean(sorting)
        }))
    }

    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout)

        if (typeof key === 'object') {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    ...key
                },
            }))
        } else {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    [key]: val,
                },
            }))
        }

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                refresh: moment().unix()
            }))
        }, delay)
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            refresh: moment().unix()
        }))
    }

    const handleSort = (sort, order) => {
        setState(prev => ({
            ...prev,
            sorting: true,
            filter: {
                ...prev.filter,
                sort: sort,
                order: order,
                page: 1,
            },
            refresh: moment().unix()
        }))
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleSave = () => {
        if (state.loadingSave) return

        setState(prev => ({
            ...prev,
            loadingSave: true,
        }))

        let data = new FormData(formRef.current)

        data.append('id', props.id)

        Api.post('store/articles/suppliers', data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {

            if (typeof props.onSuccess === 'function') {
                props.onSuccess(res.data)
            }

            app.showSuccess()
        }).catch(error => {
            const _err = error.response

            if (_err && _err.status && _err.status === 422) {
                setValidations(_err.data.errors)
            } else {
                app.showError()
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loadingSave: false,
            }))
        })
    }

    const addSupplier = () => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                suppliers: prev.data.suppliers.concat({})
            }
        }))
    }

    const removeSupplier = (index) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                suppliers: prev.data.suppliers.filter((d, i) => Number(i) !== Number(index))
            }
        }))
    }

    const handleAddSupplier = (index) => {

        let modal = Refs.getInstance().getRef('supplier')

        if (!modal) {
            return
        }

        hide(() => {
            modal.add()
        })

        modal.onSuccess(supplier => {
            show()

            if (supplier) {
                handleSupplierChange(supplier, index)
            }
        })

        modal.onClose(() => {
            show()
        })

    }

    const handleSupplierChange = (data, index) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                suppliers: Object.values({
                    ...prev.data.suppliers,
                    [index]: {
                        ...prev.data.suppliers[index],
                        supplier_id: data?.id,
                        supplier: data,
                    }
                })
            }
        }))
    }

    const handleSupplierInputChange = (data, index) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                suppliers: Object.values({
                    ...prev.data.suppliers,
                    [index]: {
                        ...prev.data.suppliers[index],
                        supplier_id: null,
                        supplier: null,
                    }
                })
            }
        }))
    }

    return (
        <div className="right-form-holder">

            {state.loading
                ?
                <>
                    <Skeleton count={1} height={35} />
                    <Skeleton count={1} height={35} />
                    <Skeleton count={1} height={35} />
                    <Skeleton count={1} height={35} />
                    <Skeleton count={1} height={35} />
                </>
                :
                <>
                    {state.data.suppliers.length === 0 &&
                        <Alert>
                            Няма добавени характеристики
                        </Alert>
                    }

                    <form ref={formRef}>
                        <table className="type-doc">
                            <thead>
                                <tr>
                                    <th>
                                        Доставчик
                                    </th>
                                    <th>
                                        Дост. цена
                                    </th>
                                    <th>
                                        Последна цена
                                    </th>
                                    <th>
                                        Последно зареждане
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {state.data.suppliers.map((detail, index) =>
                                    <tr key={'d-' + index}>
                                        <td>
                                            <Autocomplete
                                                variant="basic"
                                                url="store/autocomplete/suppliers"
                                                // inputPlaceholder="Доставчик"
                                                inputName={`suppliers[${index}][name]`}
                                                inputIdName={`suppliers[${index}][id]`}
                                                inputValue={detail.supplier?.name || ''}
                                                inputIdValue={detail.supplier_id || ''}
                                                error={Boolean(validations?.suppliers && validations.suppliers[index] && validations.suppliers[index].id)}
                                                helperText={validations?.suppliers && validations.suppliers[index] && validations.suppliers[index].id && (validations.suppliers[index].id[0] || validations.suppliers[index].id)}
                                                onClickAddButton={e => handleAddSupplier(index)}
                                                onChange={data => handleSupplierChange(data, index)}
                                                onInputChange={data => handleSupplierInputChange(data, index)}
                                                fullWidth
                                            />
                                        </td>
                                        <td
                                            style={{
                                                width: '150px'
                                            }}
                                        >
                                            <TextField
                                                margin="none"
                                                size="small"
                                                variant="outlined"
                                                fullWidth
                                                type="number"
                                                min="0"
                                                step="0.001"
                                                value={detail.delivery_price || ''}
                                                error={Boolean(validations?.suppliers && validations.suppliers[index] && validations.suppliers[index].delivery_price)}
                                                helperText={validations?.suppliers && validations.suppliers[index] && validations.suppliers[index].delivery_price}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end"> BGN</InputAdornment>,
                                                }}
                                                className="quantity-input input-with-right-label"
                                            />
                                        </td>
                                        <td
                                            style={{
                                                width: '150px'
                                            }}
                                        >
                                            <TextField
                                                margin="none"
                                                size="small"
                                                variant="outlined"
                                                fullWidth
                                                type="number"
                                                min="0"
                                                step="0.001"
                                                value={detail.last_load_price || ''}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end"> BGN</InputAdornment>,
                                                }}
                                                className="quantity-input input-with-right-label"
                                            />
                                        </td>
                                        <td
                                            style={{
                                                width: '200px'
                                            }}
                                        >
                                            <RedditTextField
                                                margin="none"
                                                size="small"
                                                variant="outlined"
                                                type="text"
                                                disabled
                                                name={`suppliers[${index}][external_ref]`}
                                                value={detail.last_load ? moment(detail.last_load).format('DD.MM.YYYY HH:mm') : ''}
                                                className="quantity-input input-with-right-label"
                                            />
                                        </td>
                                        <td
                                            style={{
                                                maxWidth: '40px',
                                            }}
                                        >
                                            <Button className="remove small" onClick={e => removeSupplier(index)}>
                                            </Button>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </form>

                    <Button
                        className="add"
                        onClick={addSupplier}
                        style={{
                            marginTop: '15px'
                        }}
                    >
                        Нов
                    </Button>
                </>
            }
        </div>
    )
}

export default forwardRef(Index)
