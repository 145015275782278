export default class Container {
    static instance = null;

    auth = null;
    location = null;
    history = null;
    query = null;
    wait = false;

    static getInstance() {
        if (Container.instance === null) {
            Container.instance = new Container();
        }

        return Container.instance;
    }

    setAuth(auth) {
        this.auth = auth;
    }

    setLocation(location) {
        this.location = location;
    }

    setHistory(history) {
        this.history = history;
    }

    setQuery(query) {
        this.query = query;
    }

    getAuth() {
        return this.auth;
    }

    getUser() {
        return this.auth?.getUser();
    }

    getLocation() {
        return this.location;
    }

    getHistory() {
        return this.history;
    }

    getQuery() {
        return this.query;
    }
}
