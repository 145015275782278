import { useEffect, useRef, useState } from 'react';
import Api from 'helpers/Api';
import { buildSearchParams, buildUrl, useQuery } from 'helpers/Url';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { Alert, Button, Table, OverlayTrigger, Tooltip, Form } from 'react-bootstrap';
import { ArrowLeftCircle, CurrencyDollar, Plus, PrinterFill } from 'react-bootstrap-icons';
import axios from 'axios';
import { cloneDeep } from 'lodash';

import Options from './partials/Options';

import Filter from './partials/FilterPrintList';
import Layout from 'components/Layout';

//misc
import MenuOptions from 'components/misc/MenuOptions'
import Pagination from 'components/misc/Pagination'
import Loader from 'components/misc/Loader';
import NoDataFound from 'components/misc/NoDataFound';
import TableHeader from 'components/misc/TableHeader';
import TableCol from 'components/misc/TableCol';
import TableOptions from 'components/misc/TableOptions';
import DynamicTableCol from 'components/partials/table/DynamicTableCol';
import DynamicTableBodyCol from 'components/partials/table/DynamicTableBodyCol';
import Refs from 'Refs';

import { useAuthDataContext } from 'providers/Auth';
import { useAppContext } from 'providers/App';
import PrintOptions from './partials/PrintOptions';
import { baseUrl } from 'helpers/mode';
import moment from 'moment'

let timeout;

function PrintList() {

    const location = useLocation();
    const history = useHistory();
    const query = useQuery();
    const auth = useAuthDataContext();
    const app = useAppContext();

    const tableRef = useRef(null);
    const printOptionsModalRef = useRef(null);

    const [state, setState] = useState({
        sorting: false,
        loading: true,
        refresh: false,
        setFilter: false,
        data: {},
        headings: {},
        types: {},
        pages: 0,
        total: 0,
        filter: {
            page: 1,
        },
        columns: {
            all: {},
            details: {},
            selected: [],
            sortable: [],
            sort: null,
            order: null,
        },
        tableKey: '',
        request: null,
    });

    const [selected, setSelected] = useState({});

    useEffect(() => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: query.get('page') || 1,
                limit: query.get('limit') || '',
                search: query.get('search') || '',
                ref: query.get('ref') || '',
                category_id: query.getAll('category_id[]') || [],
                detail_value_id: JSON.parse(query.get('detail_value_id')) || {},
                prices: query.getAll('prices[]') || [],
                created_from_date: query.get('created_from_date') || '',
                created_to_date: query.get('created_to_date') || '',
                updated_from_date: query.get('updated_from_date') || '',
                updated_to_date: query.get('updated_to_date') || '',
                barcode: query.get('barcode') || '',
                file: query.get('file') || '',
                deleted: query.get('deleted') || '',
                sort: query.get('sort') || '',
                order: query.get('order') || '',
            },
            refresh: moment().unix()
        }))
    }, [location.search]);

    useEffect(() => {
        if (state.setFilter) {
            history.push('?' + buildSearchParams(query, state.filter));
        }

    }, [state.setFilter]);

    useEffect(() => {
        if (state.refresh) {
            loadData();
        }
    }, [state.refresh]);

    useEffect(() => {
        refresh();
    }, [auth.getUser().getStoreId()]);

    const loadData = () => {

        if (state.request) {
            state.request.cancel();
        }

        let request = axios.CancelToken.source();

        setState(prev => ({
            ...prev,
            request: request,
            loading: true,
        }));

        let url = 'store/articles/print-list';

        Api.get(url, {
            params: state.filter,
            cancelToken: request.token
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data.items,
                total: res.data.total,
                pages: res.data.pages,
                headings: res.data.headings,
                types: res.data.types,
                columns: res.data.columns,
                tableKey: res.data.tableKey,
                loading: false,
                sorting: false,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                }
            }));

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 });
            }
        });
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }));
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: reset ? 1 : prev.filter.page
            },
            setFilter: moment().unix(),
            refresh: moment().unix(),
        }));
    }

    const refreshTable = () => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                sort: '',
                order: '',
                page: 1,
                limit: '',
            },
            setFilter: moment().unix(),
            refresh: moment().unix(),
        }));
    }

    // Search
    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout);

        if (typeof key === 'object') {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    ...key
                },
            }));
        } else {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    [key]: val,
                },
            }));
        }

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                setFilter: moment().unix()
            }));
        }, delay);
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            setFilter: moment().unix(),
        }));
    }

    const sorting = (sorting) => {
        setState(prev => ({
            ...prev,
            sorting: Boolean(sorting)
        }));
    }

    const handleSort = (sort, order) => {
        setState(prev => ({
            ...prev,
            sorting: true,
            filter: {
                ...prev.filter,
                sort: sort,
                order: order,
                page: 1,
            },
            setFilter: moment().unix()
        }));
    }

    const isChecked = (id) => {
        return selected[id] ? true : false;
    }

    const handleSelect = (id) => {
        const data = cloneDeep(selected);

        if (isChecked(id)) {
            delete data[id];
        } else {
            data[id] = 5;
        }

        setSelected(data);
    }

    const isAllSelected = () => {
        let ids = state.data.map(a => a.article_id);

        for (let i = 0; i < ids.length; i++) {
            if (!isChecked(ids[i])) {
                return false;
            }
        }

        if (getSelectedCount() > 0) {
            return true;
        }

        return false;
    }

    const getSelectedCount = () => {
        return Object.keys(selected).length;
    }

    const handleSelectAll = () => {
        const data = cloneDeep(selected);

        let ids = state.data.map(a => a.article_id);

        let isSelected = isAllSelected();

        for (let i = 0; i < ids.length; i++) {
            if (isSelected) {
                delete data[ids[i]];
            } else {
                if (isChecked(ids[i])) {
                    continue;
                }

                data[ids[i]] = 5;
            }
        }

        setSelected(data);
    }

    const handleInputChange = (e, article_id) => {
        let value = e.target.value;

        setSelected(prev => ({
            ...prev,
            [article_id]: value
        }));
    }

    const showPrintOptions = () => {
        let modal = printOptionsModalRef.current;

        if (!modal) {
            return;
        }

        modal.open();

        modal.onSuccess(({ label_id, skip }) => {
            let base = baseUrl + 'store/articles/print';

            let ids = [];

            Object.entries(selected).map(item => {
                ids.push({
                    article_id: item[0],
                    count: item[1]
                });
            })

            // let ids = selected;

            let url = buildUrl(base, {
                ids: JSON.stringify(ids),
                label_id: label_id,
                skip: skip,
                token: auth.getToken()
            });

            // console.log(url);

            window.open(url);
        });
    }

    const showCalcPriceOptions = () => {
        let modal = Refs.getInstance().getRef('question');

        modal.open('Сигурни ли сте, че искате да преизчислите текущата продажна цена за избраните артикули?');

        modal.onSuccess(() => {
            calculatePrice();
        });
    }

    const calculatePrice = () => {
        let ids = Object.keys(selected);

        Api.post('store/articles/calculate-price', {
            article_id: ids,
        }).then(res => {
            Refs.getInstance().getRef('info').open('Ще получите имейл, когато синхронизацията завърши!');
        }).catch(err => {
            app.showError();
        });
    }

    const showPriceList = () => {
        history.push('/articles-print-list-free');
    }

    const handleLimitChange = limit => {
        handleSearch('limit', limit, 0);
    }

    return (
        <>
            <PrintOptions
                ref={printOptionsModalRef}
            />

            <Layout>
                <div className="container-fluid">

                    <div className="page-head">
                        <h1 className="display-6">
                            <Link to="/articles" className="link-dark text-decoration-none">
                                <ArrowLeftCircle />
                            </Link> Списък артикули за печат
                        </h1>

                        <div className="buttons">

                        </div>
                    </div>

                    <br />

                    <div className="panel">
                        <Filter
                            filter={state.filter}
                            handleSearch={handleSearch}
                        />

                        {state.loading && !state.sorting
                            ?
                            <Loader />
                            :
                            state.data.length === 0 ?
                                <NoDataFound />
                                :
                                <>
                                    <div className="pagination-with-options mb-3">
                                        <Pagination
                                            page={state.filter.page}
                                            pages={state.pages}
                                            handlePage={handlePage}
                                            limit={state.filter.limit}
                                            onLimitChange={handleLimitChange}
                                        />

                                        <TableOptions
                                            url="articles/print-list"
                                            filter={state.filter}
                                            columns={state.columns}
                                            tableKey={state.tableKey}
                                            refresh={refreshTable}
                                        >
                                            <Button
                                                variant="dark"
                                                // size="sm"
                                                onClick={showPriceList}
                                            >
                                                <Plus /> Режим свободен избор
                                            </Button>
                                        </TableOptions>
                                    </div>

                                    <Table className={Object.entries(state.headings).length > 14 ? 'xxl' : 'big'} responsive striped hover ref={tableRef}>
                                        <TableHeader
                                            tableRef={tableRef}
                                            activeSortKey={state.filter.sort}
                                            activeSortDir={state.filter.order}
                                            onSort={(col, dir) => handleSort(col, dir)}
                                        >
                                            {Object.entries(state.headings).map((heading, i) =>
                                                <DynamicTableCol
                                                    key={i}
                                                    type={state.types[heading[0]]}
                                                    name={heading[1]}
                                                    title={state.columns.description[heading[0]]}
                                                    sortKey={heading[0]}
                                                    sortable={state.columns.sortable.indexOf(heading[0]) > -1}
                                                />
                                            )}
                                            <td key={'cb'} className="text-center">
                                                <Form.Check
                                                    type="checkbox"
                                                    checked={isAllSelected()}
                                                    onChange={e => handleSelectAll()}
                                                />
                                            </td>
                                            <td key={'copy'} className="text-center" style={{ width: '100px' }}>
                                                Копия
                                            </td>
                                        </TableHeader>
                                        <tbody>
                                            {state.data.map((c, index) =>
                                                <tr
                                                    key={'c-' + index}
                                                    className={c.deleted ? 'deleted' : ''}
                                                >
                                                    {Object.entries(state.headings).map((heading, i) =>
                                                        <DynamicTableBodyCol
                                                            key={heading[0]}
                                                            type={state.types[heading[0]]}
                                                            name={c[heading[0]]}
                                                            data={c}
                                                            currency={state.currency}
                                                        />
                                                    )}
                                                    <td className="text-center">
                                                        <Form.Check
                                                            type="checkbox"
                                                            checked={isChecked(c.article_id)}
                                                            onChange={e => handleSelect(c.article_id)}
                                                        />
                                                    </td>
                                                    <td className="text-center">
                                                        <Form.Control
                                                            type="number"
                                                            min="1"
                                                            step="1"
                                                            value={selected[c.article_id] || ''}
                                                            onChange={e => handleInputChange(e, c.article_id)}
                                                            size="sm"
                                                            disabled={isChecked(c.article_id) ? false : true}
                                                        />
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>

                                    <Pagination
                                        className="mt-3"
                                        page={state.filter.page}
                                        pages={state.pages}
                                        total={state.total}
                                        handlePage={handlePage}
                                        limit={state.filter.limit}
                                        onLimitChange={handleLimitChange}
                                    />
                                </>
                        }

                    </div>
                </div>

                {getSelectedCount() > 0 ?
                    <div className="baloon-selected">
                        Избрани артикули: {getSelectedCount()} бр.
                        <div className="buttons">
                            {/* <Button variant="secondary" onClick={showCalcPriceOptions}>
                                <CurrencyDollar /> <span>
                                    Преизчисли продажна цена
                                </span>
                            </Button> */}

                            <Button variant="primary" onClick={showPrintOptions}>
                                <PrinterFill /> <span>
                                    Печат
                                </span>
                            </Button>
                        </div>
                    </div>
                    : ''
                }
            </Layout>
        </>
    )
}

export default PrintList;
