import { Checkbox, FormControlLabel } from "@material-ui/core";
import Api from "helpers/Api";
import { useEffect, useState } from "react";

function Stores(props) {

    const { isStoreChecked, onChange } = props;

    const [stores, setStores] = useState([]);

    useEffect(() => {
        Api.get('store/autocomplete/stores')
            .then(res => {
                if (Array.isArray(res.data.items)) {
                    setStores(res.data.items);
                }
            });
    }, []);


    return (
        stores.map(store =>
            <div key={store.id} className="row">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isStoreChecked(store) ? true : false}
                            onChange={() => {
                                onChange(store);
                            }}
                            color="primary"
                            name="store_id[]"
                            value={store.id}
                        />
                    }
                    label={store.translation?.name || store.name}
                />
            </div>
        )
    )
}

export default Stores;