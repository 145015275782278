//MUI components
import { MenuItem, Tooltip } from '@material-ui/core'

//images
import mailIcon from 'assets/img/app/icons/mail.png'
import linkIcon from 'assets/img/app/icons/link.png'
import printIcon from 'assets/img/app/icons/print.png'
import downloadIcon from 'assets/img/app/icons/download.png'
import previewIcon from 'assets/img/app/icons/preview.png'
import editIcon from 'assets/img/app/icons/edit.png'
import deleteIcon from 'assets/img/app/icons/delete.png'
import autoCreateIcon from 'assets/img/app/icons/auto-create.png'
import checkedIcon from 'assets/img/app/icons/check-bordered.png'
import partlyPaymentIcon from 'assets/img/app/icons/partly-payment.png'
import creditNoteIcon from 'assets/img/app/icons/credit-note.png'
import debitNoteIcon from 'assets/img/app/icons/debit-note.png'
import exportIcon from 'assets/img/app/icons/export.png'
import createProformaIcon from 'assets/img/app/icons/create-proforma.png'
import createInvoiceIcon from 'assets/img/app/icons/create-invoice.png'
import markAsUnpaidIcon from 'assets/img/app/icons/mark-as-unpaid.png'
import dublicateIcon from 'assets/img/app/icons/dublicate.png'

//components
import PrintButtonLayout from './PrintButtonLayout'
import DownloadButtonLayout from './DownloadButtonLayout'

//providers
import { useAuthDataContext } from 'providers/Auth'

function Options(props) {
    const {
        data,
        fromPreview,
        handleSuccessCopied,
        handleSend,
        handleExport,
        handlePreview,
        handleEdit,
        handleConvertToProforma,
        handleConvertToCreditNote,
        handleConvertToDebitNote,
        handleShowRepeatOptions,
        handleConvertToInvoice,
        handleMakePaid,
        handleAddPayment,
        handleMakeUnpaid,
        handleDelete,
        handleDublicate,
    } = props

    const auth = useAuthDataContext()

    return (
        <div key={data.id}>
            {data.allow_edit &&
                <Tooltip
                    title="Кликнете 'Редакция', за да редактирате документа"
                >
                    <MenuItem
                        onClick={handleEdit}
                    >
                        <img src={editIcon} />
                        Редакция
                    </MenuItem>
                </Tooltip>
            }
            {!fromPreview ?
                <Tooltip
                    title="Кликнете 'Преглед', за да визуализирате документа"
                >
                    <MenuItem onClick={handlePreview}
                    >
                        <img src={previewIcon} />
                        Преглед
                    </MenuItem>
                </Tooltip>
                :
                <div>
                </div>
            }
            {data.allow_duplicate ?
                <Tooltip
                    title="Кликнете 'Преглед', за да визуализирате документа"
                >
                    <MenuItem onClick={() => {
                        handleDublicate(data.id)
                    }}
                    >
                        <img src={dublicateIcon} />
                        Създаване на дубликат
                    </MenuItem>
                </Tooltip>
                :
                <div>
                </div>
            }
            {fromPreview ?
                <Tooltip
                    title="Кликнете Експорт"
                >
                    <MenuItem
                        onClick={() => {
                            handleExport(data.id)
                        }}
                    >
                        <img src={exportIcon} />
                        Експорт
                    </MenuItem>
                </Tooltip>
                :
                <div>
                </div>
            }
            {data.allow_repeat ?
                <Tooltip
                    title="Кликнете 'Периодично издаване', за да генерирате периодично издаване"
                >
                    <MenuItem
                        onClick={() => {
                            handleShowRepeatOptions(data)
                        }}
                    >
                        <img src={autoCreateIcon} />
                        Периодично издаване
                    </MenuItem>
                </Tooltip>
                :
                <>
                </>
            }
            <div>
                <hr />
                <MenuItem
                    style={{
                        backgroundColor: "#fff"
                    }}
                >
                    <ul className="holder">
                        <Tooltip
                            title="Кликнете, за да изпратете на Вашите клиенти"
                        >
                            <li
                                onClick={() => {
                                    handleSend(data.id)
                                }}
                            >
                                <img src={mailIcon} />
                                Изпращане<br /> по Е-поща
                            </li>
                        </Tooltip>
                        <Tooltip
                            title="Кликнете, за да копирате връзката към документа"
                        >
                            <li
                                onClick={() => {
                                    navigator.clipboard.writeText(data.url)
                                    handleSuccessCopied(data.id, 'Връзката беше копирана.')
                                }}
                            >
                                <img src={linkIcon} />
                                Копиране<br /> на линк
                            </li>
                        </Tooltip>

                        {/* <Tooltip
                            title="Кликнете, за да започнете принтиране"
                        > */}
                        <li className="print">
                            <PrintButtonLayout printUrl={`${data.print_url}&token=${auth.getToken()}`}>
                                <img src={printIcon} />
                                Принтиране
                            </PrintButtonLayout>
                        </li>
                        {/* </Tooltip> */}
                        {/* <Tooltip
                            title="Кликнете, за да свалите документа"
                        > */}
                        <li className='print'>
                            <DownloadButtonLayout downloadUrl={`${data.download_pdf_url}&token=${auth.getToken()}`}>
                                <img src={downloadIcon} />
                                Изтегляне
                            </DownloadButtonLayout>
                        </li>
                        {/* </Tooltip> */}
                    </ul>
                </MenuItem>
                {data.allow_proforma || data.allow_invoice || (data.is_payable && !data.is_paid) || (data.is_payable && !data.is_paid) || (data.is_payable && data.is_paid) || data.allow_convert || data.allow_debit_not || data.allow_delete ? <hr /> : <div></div>}
            </div>
            {data.allow_proforma &&
                <Tooltip
                    title="Кликнете 'Създай проформа', за да започнете създаването на документа"
                >
                    <MenuItem
                        onClick={() => {
                            handleConvertToProforma(data.id)
                        }}
                    >
                        <img src={createProformaIcon} />
                        Създай проформа
                    </MenuItem>
                </Tooltip>
            }
            {data.allow_invoice &&
                <div>
                    <Tooltip
                        title="Кликнете 'Създай фактура', за да започнете създаването на документа"
                    >
                        <MenuItem
                            onClick={() => {
                                handleConvertToInvoice(data.id)
                            }}
                        >
                            <img src={createInvoiceIcon} />
                            Създай фактура
                        </MenuItem>
                    </Tooltip>
                    <hr />
                </div>
            }
            {data.is_payable && !data.is_paid &&
                <Tooltip
                    title="Кликнете 'Маркирай като платено', за да маркирате като платено"
                >
                    <MenuItem
                        onClick={() => {
                            handleMakePaid(data.id)
                        }}
                    >
                        <img src={checkedIcon} />
                        Маркирай като платено
                    </MenuItem>
                </Tooltip>
            }
            {data.is_payable && !data.is_paid &&
                <Tooltip
                    title="Кликнете 'Добави частично плащане', за да добавите частично плащане"
                >
                    <MenuItem
                        onClick={() => {
                            handleAddPayment(data.id)
                        }}
                    >
                        <img src={partlyPaymentIcon} />
                        Добави частично плащане
                    </MenuItem>
                </Tooltip>
            }
            {data.is_payable && data.is_paid &&
                <Tooltip
                    title="Кликнете 'Маркирай като неплатено', за да маркирате като неплатено"
                >
                    <MenuItem
                        onClick={() => {
                            handleMakeUnpaid(data.id)
                        }}
                    >
                        <img src={markAsUnpaidIcon} />
                        Маркирай като неплатено
                    </MenuItem>
                </Tooltip>
            }
            {data.allow_convert || data.allow_debit_note ?
                <hr />
                :
                <div>
                </div>
            }
            {data.allow_convert &&
                <Tooltip
                    title="Кликнете 'Създай кредитно известие', за да започнете създаването на документа"
                >
                    <MenuItem
                        onClick={() => {
                            handleConvertToCreditNote(data.id)
                        }}
                    >
                        <img src={creditNoteIcon} />
                        Създай кредитно известие
                    </MenuItem>
                </Tooltip>
            }
            {data.allow_debit_note &&
                <Tooltip
                    title="Кликнете 'Създай кредитно известие', за да започнете създаването на документа"
                >
                    <MenuItem
                        onClick={() => {
                            handleConvertToDebitNote(data.id)
                        }}
                    >
                        <img src={debitNoteIcon} />
                        Създай дебитно известие
                    </MenuItem>
                </Tooltip>
            }
            {data.allow_delete &&
                <div>
                    <hr />
                    <Tooltip
                        title="Изтрийте документа"
                    >
                        <MenuItem
                            onClick={() => {
                                handleDelete(data.id)
                            }}
                            className="remove"
                        >
                            <img src={deleteIcon} />
                            Изтриване
                        </MenuItem>
                    </Tooltip>
                </div>
            }
        </div>
    )
}

export default Options