import { useEffect, useRef, useState } from "react";
import { Dropdown, Pagination, Table } from "react-bootstrap";
import axios from "axios";
import Loader from "components/misc/Loader";
import MenuOptions from "components/misc/MenuOptions";
import NoDataFound from "components/misc/NoDataFound";
import TableHeader from "components/misc/TableHeader";
import TableOptions from "components/misc/TableOptions";
import DynamicTableBodyCol from "components/partials/table/DynamicTableBodyCol";
import DynamicTableCol from "components/partials/table/DynamicTableCol";
import Api from "helpers/Api";

import { OPERATION_TYPE_IDS } from "constants/imports";
import moment from "moment"

function Previous(props) {

    const TYPE = props.type;
    const OPERATION_TYPE_ID = OPERATION_TYPE_IDS[TYPE];

    const tableRef = useRef(null);

    const [state, setState] = useState({
        sorting: false,
        loading: true,
        refresh: false,
        data: {},
        headings: {},
        types: {},
        pages: 0,
        total: 0,
        filter: {
            page: 1,
        },
        columns: {
            all: {},
            details: {},
            selected: [],
            sortable: [],
            sort: null,
            order: null,
        },
        tableKey: '',
        request: null,
    });

    useEffect(() => {
        loadData();
    }, [state.refresh]);

    const loadData = () => {

        if (state.request) {
            state.request.cancel();
        }

        let request = axios.CancelToken.source();

        setState(prev => ({
            ...prev,
            request: request,
            loading: true,
        }));

        let url = 'store/imports/previous?operation_type_id=' + OPERATION_TYPE_ID;

        Api.get(url, {
            params: state.filter,
            cancelToken: request.token
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data.items,
                total: res.data.total,
                pages: res.data.pages,
                headings: res.data.headings,
                types: res.data.types,
                columns: res.data.columns,
                tableKey: res.data.tableKey,
                loading: false,
                sorting: false,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                }
            }));

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 });
            }
        });
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }));
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: reset ? 1 : prev.filter.page
            },
            refresh: moment().unix(),
        }));
    }

    const refreshTable = () => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                sort: '',
                order: '',
                page: 1
            },
            refresh: moment().unix(),
        }));
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            refresh: moment().unix(),
        }));
    }

    const sorting = (sorting) => {
        setState(prev => ({
            ...prev,
            sorting: Boolean(sorting)
        }));
    }

    const handleSort = (sort, order) => {
        setState(prev => ({
            ...prev,
            sorting: true,
            filter: {
                ...prev.filter,
                sort: sort,
                order: order,
                page: 1,
            },
            refresh: moment().unix()
        }));
    }

    const handleShowDetails = (id) => {
        props.handleShowDetails(id);
    }

    return (
        <>


            {state.loading && !state.sorting
                ?
                <Loader />
                :
                state.data.length === 0 ?
                    <NoDataFound />
                    :
                    <>
                        <div className="pagination-with-options mb-3">
                            <Pagination
                                page={state.filter.page}
                                pages={state.pages}
                                handlePage={handlePage}
                            />

                            <TableOptions
                                url={`imports/previous?operation_type_id=${OPERATION_TYPE_ID}`}
                                filter={state.filter}
                                columns={state.columns}
                                tableKey={state.tableKey}
                                refresh={refreshTable}
                            />
                        </div>

                        <Table striped hover ref={tableRef}>
                            <TableHeader
                                tableRef={tableRef}
                                activeSortKey={state.filter.sort}
                                activeSortDir={state.filter.order}
                                onSort={(col, dir) => handleSort(col, dir)}
                            >
                                {Object.entries(state.headings).map((heading, i) =>
                                    <DynamicTableCol
                                        key={i}
                                        type={state.types[heading[0]]}
                                        name={heading[1]}
                                        title={state.columns.description[heading[0]]}
                                        sortKey={heading[0]}
                                        sortable={state.columns.sortable.indexOf(heading[0]) > -1}
                                    />
                                )}
                                <th className="text-center" style={{ width: '5%' }}>Опции</th>
                            </TableHeader>
                            <tbody>
                                {state.data.map((c, index) =>
                                    <tr
                                        key={'c-' + index}
                                        className={c.deleted ? 'deleted' : ''}
                                    >
                                        {Object.entries(state.headings).map((heading, i) =>
                                            <DynamicTableBodyCol
                                                key={heading[0]}
                                                type={state.types[heading[0]]}
                                                name={c[heading[0]]}
                                                data={c}
                                            />
                                        )}

                                        <td className="text-center">
                                            <MenuOptions>
                                                <Dropdown.Item onClick={e => handleShowDetails(c.import_id)}>
                                                    Детайли
                                                </Dropdown.Item>
                                            </MenuOptions>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>

                        <Pagination
                            className="mt-3"
                            page={state.filter.page}
                            pages={state.pages}
                            total={state.total}
                            handlePage={handlePage}
                        />
                    </>
            }
        </>
    )
}

export default Previous;