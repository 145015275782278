import { useState, useEffect, useImperativeHandle, forwardRef, useRef } from 'react'

//images
import backIcon from 'assets/img/documents/icons/back.png'
import closeIcon from 'assets/img/documents/icons/close.png'
import forwardIcon from 'assets/img/documents/icons/forward.png'
import welcomeIllustrationImage from 'assets/img/documents/welcome-illustration.png'
import brandImage from 'assets/img/app/brand-full-gray.png'

//MUI components
import { Button } from '@material-ui/core'

//modals
import Edit from './Edit'

//partials
import Register from './Register'
import Login from './Login'

//providers
import { usePublicPreviewContext } from './PublicPreviewProvider'

const PublicPreviewAuthScreen = (props, ref) => {
    const publicPreview = usePublicPreviewContext()
    const [state, setState] = useState({
        currentAuthStep: 1,
        viewPw: false,
        loading: false,
        show: false
    })

    const editModalRef = useRef(null)

    useImperativeHandle(ref, () => ({
        open: show => {
            handleShow(show)
        }
    }))

    const handleShow = show => {
        setState(prev => ({
            ...prev,
            show
        }))
    }

    const handleCurrentAuthStep = currentAuthStep => {
        setState(prev => ({
            ...prev,
            currentAuthStep
        }))
    }

    //Не се ползва
    // const handleValidations = (name, value, reset = false) => {
    //     if(reset) {
    //         setValidation(false) 

    //         return
    //     }

    //     setValidation(prev => ({
    //         ...prev,
    //         [name]: value
    //     }))
    // }


    /**
     * 
     * @param {boolean} fromNextBtn
     */
    const handleEdit = (fromNextBtn = false) => {
        const modal = editModalRef.current

        modal.edit(state, fromNextBtn)
    }

    const handleSaveData = data => {
        // setState(data)
        // alert(1)
        setTimeout(() => {
            handleCurrentAuthStep(2)
        }, 500)
    }

    return (
        <div className={`auth-menu ${state.show ? 'show' : ''}`}>
            <Edit
                ref={editModalRef}
                handleSaveData={handleSaveData}
            />
            <div className="head-part">
                <div>
                    {state.currentAuthStep !== 1 ?
                        <span
                            className="back"
                            onClick={() => {
                                handleCurrentAuthStep(1)
                            }}
                        >
                            <img src={backIcon} />
                            Назад
                        </span>
                        :
                        <>
                        </>
                    }
                </div>
                <div>
                    <img className="brand" src={brandImage} />
                </div>
                <div>
                    <span
                        className="close"
                        onClick={() => {
                            handleShow(false)
                        }}
                    >
                        <img src={closeIcon} />
                    </span>
                </div>
            </div>
            {state.currentAuthStep === 1 ?
                <div className="body-part welcome">
                    <img src={welcomeIllustrationImage} className="main" />
                    <h2>
                        {publicPreview.getCustomerName()}
                    </h2>
                    <ul className="company-data">
                        <li>
                            ЕИК: {publicPreview.getEik()}
                        </li>
                        <li>
                            МОЛ: {publicPreview.getMol()}
                        </li>
                        <li>
                            Адрес: {publicPreview.getSettlement()}, {publicPreview.getCustomerAddress()}
                        </li>
                    </ul>
                    <Button
                        className="edit"
                        onClick={() => {
                            handleEdit()
                        }}
                    >
                        Редактиране
                    </Button>
                    <hr />
                    <ul className="info">
                        <li>
                            Управление на бизнес документация
                        </li>
                        <li>
                            Шаблони на документите по Ваш вкус
                        </li>
                        <li>
                            Статистика за финансовите дейности
                        </li>
                        <li>
                            Плащания по удобни начини
                        </li>
                        <li>
                            Управление на продукти/услуги
                        </li>
                        <li>
                            Интеграции, нужни за Вашата дейност
                        </li>
                    </ul>
                    <Button
                        onClick={() => {
                            if (!publicPreview.getEik() || !publicPreview.getMol() || !publicPreview.getCustomerAddress() || !publicPreview.getCustomerName()) {
                                handleEdit(true)

                                return
                            }
                            handleCurrentAuthStep(2)
                        }}
                        className="add"
                    >
                        Напред към профила
                        <img src={forwardIcon} />
                    </Button>
                </div>
                :
                publicPreview.getMode() === 'register' ?
                    <Register
                        handleEdit={handleEdit}
                        handleShow={handleShow}
                    />
                    :
                    <Login
                        handleEdit={handleEdit}
                        handleShow={handleShow}
                    />
            }
            <div className="footer-part">
                www.docuela.com
            </div>
        </div>
    )
}

export default forwardRef(PublicPreviewAuthScreen)