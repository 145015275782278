import { createPortal } from "react-dom"
import { useEffect, useState } from "react"

//components
import MenuOptions from "components/misc/MenuOptions"
import PipelinesStatusOptions from "../partials/PipelinesStatusOptions"
import PipelinesItemOptions from "../partials/PipelinesItemOptions"
import AddOrEditPipelinesItem from "../partials/AddOrEditPipelinesItem"
import RedditTextField from "components/misc/RedditTextField"
import RedditSelect from "components/misc/RedditSelect"

//libraries
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { Button, MenuItem, Tooltip } from "@material-ui/core"
import moment from "moment"
import { useQueryClient } from "react-query"

//images
import addIcon from "assets/img/customers/icons/add-to-pipelines.png"
import cashIcon from "assets/img/customers/icons/cash.png"
import clientIcon from "assets/img/customers/icons/client.png"

//stores
import { useAddToPipelinesModal } from "stores/customer/useAddToPipelinesModal"

//hooks
import useFetchFromServer from "hooks/useFetchFromServer"
import useSaveOnServer from "hooks/useSaveOnServer"

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source)
  const destClone = Array.from(destination)
  const [removed] = sourceClone.splice(droppableSource.index, 1)

  destClone.splice(droppableDestination.index, 0, removed)

  const result = {}
  result[droppableSource.droppableId] = sourceClone
  result[droppableDestination.droppableId] = destClone

  return result
}

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  marginBottom: "10px",
  background: isDragging ? "#E7E7E7" : "#F8F8FB",
  ...draggableStyle,
})
const getListStyle = (isDraggingOver) => ({
  border: isDraggingOver ? "1px dashed #42424E" : "1px solid #fff",
  padding: isDraggingOver ? "5px" : "0",
})

const PIPELINES_FROM_SERVER = [
  {
    id: 1,
    title: "Потенциални сделки",
    color: "#99C2FF",
    items: [],
  },
  {
    id: 2,
    title: "Сделки в процес на обработка",
    color: "#FF8C6C",
    items: [],
  },
  {
    id: 3,
    title: "Завършени успешно",
    color: "#6EDB97",
    items: [],
  },
]

function Pipelines({ clientId }) {
  const queryClient = useQueryClient()

  const { getData, loadingFromServer } = useFetchFromServer("pipelines", [])
  const { saveOnServer, loadingSaveOnServer } = useSaveOnServer()

  const [state, setState] = useState(PIPELINES_FROM_SERVER)
  const [filters, setFilters] = useState({
    search: "",
    statusId: "all",
  })

  const { showModal, getShown } = useAddToPipelinesModal()

  // temp целия useEffect
  useEffect(() => {
    if (!loadingFromServer && !getData().length) {
      saveOnServer("pipelines", PIPELINES_FROM_SERVER, () => {
        queryClient.invalidateQueries("pipelines")
      })
    } else {
      setState(getData())
    }
  }, [getData().length === 0])

  function onDragEnd(result) {
    const { source, destination } = result

    if (!destination) {
      return
    }

    const sInd = +source.droppableId
    const dInd = +destination.droppableId

    if (sInd === dInd) {
      const items = reorder(state[sInd].items, source.index, destination.index)
      const newState = structuredClone(state)

      newState[sInd].items = items

      //temp да се махне, когато statusId се променя от бекенда
      newState.map((status) => {
        status.items.map((item) => {
          item.statusId = status.id

          return item
        })

        return status
      })

      setState(newState)

      saveOnServer("pipelines", newState, () => {
        queryClient.invalidateQueries("pipelines")
      })
    } else {
      const result = move(
        state[sInd].items,
        state[dInd].items,
        source,
        destination
      )
      const newState = structuredClone(state)

      newState[sInd].items = result[sInd]
      newState[dInd].items = result[dInd]

      //temp да се махне, когато statusId се променя от бекенда
      newState.map((status) => {
        status.items.map((item) => {
          item.statusId = status.id

          return item
        })

        return status
      })

      setState(newState)

      saveOnServer("pipelines", newState, () => {
        queryClient.invalidateQueries("pipelines")
      })
    }
  }

  // Добавя нова (статична) бележка към група
  //   function addItemToStatusDummy(index) {
  //     const newState = [...state]

  //     newState[index].items.push({
  //       id: `item-${new Date().getTime()}`,
  //       content: `Бележка ${new Date().getTime()}`,
  //     })
  //     setState(newState)
  //   }

  function addItemToStatus(statusId) {
    showModal(true, {
      statusId,
    })
  }

  function handleEditPipelinesStatus() {}

  function handleEditPipelinesNote(note) {
    showModal(true, {
      ...note,
    })
  }

  function handleRemovePipelinesStatus(statusIndex) {
    return // temp

    const newState = structuredClone(state)

    setState(newState.filter((_, i) => i !== statusIndex))
  }

  function handleRemovePipelinesItem(statusIndex, itemIndex) {
    const newState = structuredClone(state)

    newState[statusIndex].items.splice(itemIndex, 1)
    setState(newState)

    saveOnServer("pipelines", newState, () => {
      queryClient.invalidateQueries("pipelines")
    })
  }

  // temp - цялото съдържание на функцията
  function handleChangeStatus(statusIndex, itemIndex, selectedStatusId) {
    const newState = structuredClone(state)
    const item = newState[statusIndex].items[itemIndex]

    newState[statusIndex].items.splice(itemIndex, 1)
    newState.find(({ id }) => id === selectedStatusId).items.push(item)

    setState(newState)

    saveOnServer("pipelines", newState, () => {
      queryClient.invalidateQueries("pipelines")
    })
  }

  function getStatusesOnly() {
    return state.map(({ title, color, id }) => ({
      id,
      title,
      color,
    }))
  }

  function handleChangeFilter(e) {
    const { name, value } = e.target

    setFilters({
      ...filters,
      [name]: value,
    })
  }

  function getFilteredPipelinesByStatus() {
    const filteredStatus = getData().filter(({ title }) =>
      title.toLowerCase().includes(filters.search.toLowerCase())
    )

    if (filters.statusId === "all") {
      return filteredStatus
    }

    return filteredStatus.filter(({ id }) => id === filters.statusId)
  }

  return (
    <>
      <div className="pipelines">
        {/* <Button
        onClick={() => {
          setState([
            ...state,
            {
              id: new Date().getTime(),
              title: "Нова група",
              color: "#FF8C6C",
              items: [],
            },
          ])
        }}
      >
        Нова група
      </Button> */}
        <div className="field">
          <div className="row filter">
            <div
              className="col"
              style={{
                maxWidth: "300px",
              }}
            >
              <RedditTextField
                size="small"
                margin="dense"
                fullWidth
                label="Търсене..."
                name="search"
                value={filters.search}
                onChange={handleChangeFilter}
              />
            </div>
            <div className="col">
              <RedditSelect
                label="Статус"
                fullWidth
                size="small"
                margin="dense"
                value={filters.statusId}
                onChange={handleChangeFilter}
                name="statusId"
              >
                <MenuItem value="all">Всички</MenuItem>
                {getStatusesOnly().map(({ title, id, color }) => (
                  <MenuItem key={id} value={id}>
                    {title}
                  </MenuItem>
                ))}
              </RedditSelect>
            </div>
          </div>

          <div
            className={`dnd-holder ${
              loadingFromServer || loadingSaveOnServer ? "disabled" : ""
            }`}
          >
            <DragDropContext onDragEnd={onDragEnd}>
              {getFilteredPipelinesByStatus().map(
                ({ color, title, items, id }, i) => (
                  <Droppable key={i} droppableId={`${i}`}>
                    {(provided, snapshot) => (
                      <div className="dnd-status">
                        <div
                          className="status-head"
                          style={{
                            backgroundColor: color,
                          }}
                        >
                          <b>{title}</b>
                          <span>{items.length}</span>
                          <Tooltip title={`Добавяне към ${title}`}>
                            <Button
                              className="add-item"
                              onClick={() => addItemToStatus(id)}
                            >
                              <img src={addIcon} alt="add" />
                            </Button>
                          </Tooltip>
                          <div className="menu-wrapper">
                            <MenuOptions iconColor="#fff">
                              <PipelinesStatusOptions
                                handleEdit={handleEditPipelinesStatus}
                                handleRemove={() =>
                                  handleRemovePipelinesStatus(i)
                                }
                              />
                            </MenuOptions>
                          </div>
                        </div>
                        <ul
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                          {...provided.droppableProps}
                          className="dnd-wrapper"
                        >
                          {items.map((item, j) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={j}
                            >
                              {(provided, snapshot) => (
                                <li
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                  className="dnd-item"
                                >
                                  <div className="row">
                                    <b>{item.description}</b>
                                    <MenuOptions containsNestedLists>
                                      <PipelinesItemOptions
                                        handleEdit={() =>
                                          handleEditPipelinesNote(item)
                                        }
                                        handleRemove={() =>
                                          handleRemovePipelinesItem(i, j)
                                        }
                                        statuses={getStatusesOnly()}
                                        statusId={id}
                                        onStatusChange={(selectedStatusId) =>
                                          handleChangeStatus(
                                            i,
                                            j,
                                            selectedStatusId
                                          )
                                        }
                                      />
                                    </MenuOptions>
                                  </div>
                                  <p>
                                    <img src={clientIcon} alt="" />
                                    {item.client?.info?.name}
                                  </p>
                                  <ul>
                                    <li>
                                      <img src={cashIcon} alt="" />
                                      {item.price} лв.
                                    </li>
                                    <li>
                                      {item.closeDate
                                        ? moment(item.closeDate).format(
                                            "DD MMM"
                                          )
                                        : "Неизвестна дата"}
                                    </li>
                                  </ul>
                                </li>
                              )}
                            </Draggable>
                          ))}
                        </ul>
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                )
              )}
            </DragDropContext>
          </div>
        </div>
      </div>
      {createPortal(
        <AddOrEditPipelinesItem
          statuses={getStatusesOnly()}
          clientId={clientId}
          allPipelines={getData()}
        />,
        document.body
      )}
    </>
  )
}

export default Pipelines
