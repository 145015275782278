//partials
import Header from './partials/Header/Index'

function Layout({ children, hidden }) {
    return (
        <>
            {!hidden ? <Header /> : <> </>}
            <main style={hidden ? { display: "none" } : {}}>
                {children}
            </main>
        </>
    )
}

export default Layout