import { useRef } from 'react'
import { Prompt, useHistory } from 'react-router-dom'

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//miscs
import SaveButton from 'components/misc/Button'
import SnackBar from 'components/misc/SnackBar'
import RedditTextField from 'components/misc/RedditTextField'

//MUI components
import { Button, Tooltip } from '@material-ui/core'

//modals
import Question from 'components/modals/Question'
import ChangePassword from './partials/ChangePassword'

//providers
import { useAuthDataContext } from 'providers/Auth'
import { useAppContext } from 'providers/App'

//components
import LastDocuments from './partials/LastDocuments'

//images
import editIcon from 'assets/img/settings/icons/edit.png'

function User() {
    const auth = useAuthDataContext()
    const app = useAppContext()
    const history = useHistory()
    const name = auth.getUser().getFullname()
    const lastname = auth.getUser().getLastname()
    const phone = auth.getUser().getPhone()
    const email = auth.getUser().getEmail()
    const avatar = auth.getUser().getAvatar()

    const [state, setState] = useNestedState({
        loading: true,
        loadingSave: false,
        disableSave: true,
        data: {
            name,
            email,
            lastname,
            phone,
            avatar
        }
    })
    const [validations, setValidations] = useValidation()

    const deleteModalRef = useRef(null)
    const changePasswordModalRef = useRef(null)

    const setLoading = loading => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }))
    }

    const setLoadingSave = loadingSave => {
        setState(prev => ({
            ...prev,
            loadingSave
        }))
    }

    const handleDisableSave = disableSave => {
        setState(prev => ({
            ...prev,
            disableSave
        }))
    }

    const handleInputChange = e => {
        const { name, value } = e.target

        setState(name, value, 'data')
        handleDisableSave(false)
    }

    // delete
    const handleDelete = id => {
        const modal = deleteModalRef.current

        modal.open()
        modal.onSuccess(() => {
            setLoading(true)
            handleDestroy(id)
        })
    }

    const handleDestroy = id => {
        Api.post('users/delete', {
            id
        })
            .then(() => {
                history.push('/settings/users')
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleSave = e => {
        e.preventDefault()

        setLoadingSave(true)
        setValidations(null)

        const url = 'auth/update'
        const data = new FormData(e.target)

        Api.post(url, data)
            .then(res => {
                auth.setData(res.data)
                app.handleSuccess('Промените бяха запазени успешно!')
                handleDisableSave(true)
            })
            .catch(error => {
                const _err = error.response

                if (_err && _err.status && _err.status === 422) setValidations(_err.data.errors)
                else app.handleError('Грешка при въвеждането на потребителските данни.')
            })
            .finally(() => {
                setLoadingSave(false)
            })
    }

    const handleChangePicture = () => {
        document.querySelector('input[type="file"]').click()
        handleDisableSave(false)
    }

    const handleUploadPicture = e => {
        const reader = new FileReader()
        reader.addEventListener('load', ev => {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    avatar: ev.target.result
                }
            }))
        })
        reader.readAsDataURL(e.target.files[0])
    }

    return (
        <section id="profile">
            <Question
                ref={deleteModalRef}
                mainMessage="Потвърждавам изтриването"
                secondaryMessage="Изтритите от Вас данни не могат да бъдат възстановени."
                agreeBtnText="Изтриване"
                agreeBtnClass="remove"
            />
            <Prompt
                when={!state.disableSave}
                message="Имате незапазени промени! Сигурни ли сте, че искате да напуснете страницата?"
            />
            <ChangePassword
                ref={changePasswordModalRef}
            />
            <div id="head">
                <div className="title">
                    Моят профил
                </div>
            </div>
            <hr />
            <form noValidate onSubmit={handleSave}>
                <div className="row">
                    <div
                        className="col"
                        style={{
                            maxWidth: '500px',
                            marginRight: '30px'
                        }}
                    >
                        <input
                            type="file"
                            hidden
                            name="picture"
                            onChange={handleUploadPicture}
                            accept="image/*"
                        />
                        <div className="content">
                            <b>
                                Основна информация
                            </b>
                            <div className="top col">
                                <div className="left">
                                    <div className="top-part">
                                        <Tooltip title="Смяна на профилната снимка">
                                            <picture
                                                onClick={handleChangePicture}
                                            >
                                                <img src={state.data?.avatar} />
                                            </picture>
                                        </Tooltip>
                                        <div className="right-part">
                                            <span className="role">
                                                {state.data.role?.translation.name || 'Потребител'}
                                            </span>
                                            <div className="field">
                                                <img src={editIcon} />
                                                <input
                                                    type="text"
                                                    defaultValue={state.data.name}
                                                    name="name"
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            {/* <div className="field">
                                                <img src={editIcon} />
                                                <input
                                                    type="text"
                                                    defaultValue={state.data.lastname}
                                                    name="lastname"
                                                    onChange={handleInputChange}
                                                />
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="bottom-part">
                                        <p>
                                            Информация за контакти
                                        </p>
                                        <ul>
                                            <li>
                                                <span>
                                                    Е-поща
                                                </span>
                                                <div className="field">
                                                    <img src={editIcon} />
                                                    <input
                                                        type="text"
                                                        defaultValue={state.data.email}
                                                        name="email"
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </li>
                                            <li>
                                                <span>
                                                    Телефон
                                                </span>
                                                <div className="field">
                                                    <img src={editIcon} />
                                                    <input
                                                        type="text"
                                                        defaultValue={state.data.phone}
                                                        name="phone"
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="right">
                                    <h6>
                                        Смяна на парола
                                    </h6>
                                    <p>
                                        Сменете Вашата парола за достъп
                                    </p>
                                    <Button
                                        className="normal save change-password"
                                        onClick={() => {
                                            changePasswordModalRef.current.open()
                                        }}
                                    >
                                        Смени парола
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content col">
                        <b>
                            История на създадените документи
                        </b>
                        <LastDocuments />
                    </div>
                </div>

                <div id="footer">
                    <Button
                        className="cancel"
                        onClick={() => {
                            history.goBack()
                        }}
                    >
                        Назад
                    </Button>
                    <SaveButton
                        className="save"
                        loading={state.loadingSave}
                        disabled={state.disableSave}
                    />
                </div>
            </form>
        </section>
    )
}

export default User