import React, { useState, useImperativeHandle, forwardRef, useRef, useEffect } from 'react'
import Home from './Home'
import Edit from './Edit'
import Payments from './Payments'

import closeIcon from '../../../../assets/img/modals/icons/close.png'

import { useSubscriptionContext } from '../Provider'
import { useAppContext } from 'providers/App'
import { useAuthDataContext } from 'providers/Auth'

import { Button } from '@material-ui/core'

//images
import viewIcon from 'assets/img/accounts/icons/view.png'
import editIcon from 'assets/img/customers/icons/edit.png'

//components
import LeftSideMenu from 'components/misc/LeftSideMenu'
import SaveButton from 'components/misc/Button'

function View(props, ref) {

    const tabs = [
        {
            id: 'home',
            name: 'Основни данни',
            component: Home,
            icon: viewIcon
        },
        {
            id: 'payments',
            name: 'Плащания',
            component: Payments,
            icon: viewIcon,
            hasSaveButton: true,
        },
        {
            id: 'edit',
            name: 'Редактиране',
            component: Edit,
            icon: editIcon,
            hasSaveButton: true,
        },
    ]

    const app = useAppContext()
    const auth = useAuthDataContext()

    const [state, setState] = useState({
        show: false,
        params: null,
        id: null,
        tabId: null,
        onClose: null,
        activeTabIndex: 0,
    })

    const context = useSubscriptionContext()

    const screenRef = useRef(null)

    useImperativeHandle(ref, () => ({
        open: (id) => {
            handleShow(id)
        },
        close: () => {
            hide()
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }))
        }
    }))

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }))
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }))
    }

    const handleShow = (id) => {
        setState(prev => ({
            ...prev,
            id: id,
            tabId: 'main',
        }))

        show()
    }

    const handleClose = () => {
        setState(prev => ({
            ...prev,
            show: false,
            tabId: tabs[0].id
        }))

        context.resetData()

        if (typeof state.onClose === 'function') {
            hide(state.onClose)
        } else {
            hide()
        }

        setState(prev => ({
            ...prev,
            onClose: null,
        }))
    }

    const setKey = id => {
        setState(prev => ({
            ...prev,
            tabId: id,
        }))
    }

    const handleTabChange = tabIndex => {
        setState(prev => ({
            ...prev,
            activeTabIndex: Number(tabIndex)
        }))
    }

    const getActiveTab = () => {
        return tabs[state.activeTabIndex];
    }

    return (
        <>

            <div className={`${state.show ? "visible" : ""} overlay`}>
                <div
                    className={`popup-primary large complex-modal subscription-modal ${state.show ? "show" : ""}`}
                >
                    <div className="header sky-blue">
                        <b>
                            Преглед на абонамент
                        </b>
                        <p>
                            Lorem Ipsum е елементарен примерен<br /> текст, използван в печатарската
                        </p>
                    </div>
                    <div className="body">
                        <div className="row">
                            <LeftSideMenu
                                tabsArr={tabs}
                                activeTabIndex={state.activeTabIndex}
                                setActiveTabIndex={handleTabChange}
                            />
                            <div className="right-form-holder">
                                {React.createElement(tabs[state.activeTabIndex].component, {
                                    ref: screenRef,
                                    id: state.id,
                                    show: show,
                                    hide: hide,
                                    handleClose: handleClose,
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="footer">
                        <div
                            className="row"
                            style={{
                                marginLeft: 'auto',
                                width: 'fit-content'
                            }}
                        >
                            <Button className="cancel" onClick={handleClose}>
                                Затвори
                            </Button>
                            {getActiveTab()?.hasSaveButton &&
                                <SaveButton
                                    loading={context.state.loading}
                                    className="save"
                                    onClick={() => {
                                        screenRef.current?.save()
                                    }}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default forwardRef(View)