import { forwardRef, useEffect, useImperativeHandle, useState, useRef } from 'react'
import ReactDOM from 'react-dom'
import Skeleton from 'react-loading-skeleton'

//MUI components
import { Button, ClickAwayListener, Tooltip } from '@material-ui/core'

//providers
import { useAppContext } from 'providers/App'

//helpers
import Api from 'helpers/Api'

//plugins
import axios from 'axios'

//miscs
import TextField from 'components/misc/TextField'

//images
import categoriesIcon from 'assets/img/documents/icons/categories.png'
import folderPurpleIcon from 'assets/img/documents/icons/folder-purple.png'
import folderIcon from 'assets/img/documents/icons/folder.png'
import folderPurpleAddIcon from 'assets/img/documents/icons/folder-purple-add.png'
import folderPurpleAddBigIcon from 'assets/img/documents/icons/folder-purple-add-big.png'
import lastIcon from 'assets/img/documents/icons/last.png'
import minusIcon from 'assets/img/documents/icons/minus.png'
import plusIcon from 'assets/img/documents/icons/plus.png'
import productsIcon from 'assets/img/documents/icons/products.png'
import trendIcon from 'assets/img/documents/icons/trend.png'
import addToCategoryIcon from 'assets/img/documents/icons/add-to-category.png'
import directAddIcon from 'assets/img/documents/icons/direct-add.png'
import removeIcon from 'assets/img/documents/icons/remove.png'

//modals
import AddProduct from '../modals/AddProduct'

let productRowInputProducsTimeout
let productRowInputCategoriesTimeout
// let fieldRowBlur
function ProductRowInput(props, ref) {
    const app = useAppContext()
    const { handleDisableSave, focusQtyField, initialRowData, onChange } = props
    const defaultCategoriesData = {
        data: [],
        pages: 1,
        total: 0,
        loading: false,
        request: null,
    }

    const defaultProductsData = {
        data: [],
        pages: 0,
        total: 0,
        loading: false,
        timeout: null,
        request: null
    }

    const [state, setState] = useState({
        showOptions: false,
        input: initialRowData.input || {
            value: '',
            categoriesSearchValue: '',
        },
        product: initialRowData.product || null,
        categories: defaultCategoriesData,
        products: defaultProductsData,
        current: null,
        latest: null,
        items: [],
        filter: false,
        onSuccess: null,
        onError: null,
        onClose: null,
        expandedCategories: [],
        hasItems: true,
    })
    const [latest, setLatest] = useState({
        latestAddeed: [],
        oftenSelected: [],
        loading: false,
    })

    const [activeIndexesInList, setActiveIndexesList] = useState({
        products: 0,
        lastAdded: 0,
        oftenSelected: null
    })

    const addProductRef = useRef(null)
    const lastAddedHolderRef = useRef()
    const oftenSelectedHolderRef = useRef()
    const productsHolderRef = useRef()

    useImperativeHandle(ref, () => ({
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        },
        isAddProductModalOpen: addProductRef?.current?.isOpened(),
        onError: fn => {
            setState(prev => ({
                ...prev,
                onError: fn
            }))
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }))
        }
    }))

    useEffect(() => {
        Api.get(`documents/products/has-any-categories-products?customer_id=${props.customerId}&customer_eik=${props.customerEik}`)
            .then(res => {
                const { success } = res.data

                setState(prev => ({
                    ...prev,
                    hasItems: success
                }))
            })
    }, [])

    // useEffect(() => {
    //     setState(prev => ({
    //         ...prev,
    //         input: {
    //             ...prev.input,
    //             value: props?.value || ''
    //         }
    //     }))
    // }, [props.value])

    function getInputValue() {
        const { value } = state.input

        if (value) {
            return value
        }

        if (state.product?.name) {
            return state.product?.name
        }

        return ''
    }

    useEffect(() => {
        const categoryId = state.current

        if (!categoryId || isStartedTypingInMainInput()) return
        loadProducts(categoryId)
    }, [state.current])

    useEffect(() => {
        clearTimeout(productRowInputProducsTimeout)

        productRowInputProducsTimeout = setTimeout(() => {
            loadProducts(!getInputValue().length ? state.current : null, getInputValue())
        }, 300)
    }, [getInputValue()])

    useEffect(() => {
        clearTimeout(productRowInputCategoriesTimeout)

        productRowInputCategoriesTimeout = setTimeout(() => {
            loadCategories(state.input.categoriesSearchValue)
        }, 300)
    }, [state.input.categoriesSearchValue])

    useEffect(() => {
        if (addProductRef?.current?.isOpened()) return

        loadCategories('')
    }, [props.companyId, state.showOptions])

    useEffect(() => {
        if (addProductRef?.current?.isOpened()) return

        if (state.showOptions) loadLatestProducts()
    }, [state.showOptions])

    useEffect(() => {
        loadLatestProducts()
    }, [props.companyId, props.customerId, props.customerEik])

    useEffect(() => {
        if (getCurrentArticleFormListWithCategory()) {
            document.addEventListener('keypress', function (e) {
                if (e.keyCode == 13) {
                    if (document.activeElement.getAttribute('name') === 'customerinfo[eikegn]') return true
                    if (document.activeElement.getAttribute('name') === 'customerinfo[name]') return true

                    e.preventDefault()
                    // selectProduct(getCurrentArticleFormListWithCategory())

                    return false
                }
            })
        }
    }, [state.products])


    // const lastAddedHolderRef = useRef()
    // const oftenSelectedHolderRef = useRef()
    // const productsHolderRef = useRef()


    function controlFromKeyboardIniti(e) {
        const lastProductsIndex = state.products.data.length - 1
        const lastLastAddedIndex = latest.latestAddeed.length - 1
        const lastOfternSelectedIndx = latest.oftenSelected.length - 1

        switch (e.keyCode) {
            //down
            case 40:
                if (isStartedTypingInMainInput()) {
                    const nextIndex = getProductsActiveIndex() + 1

                    if (getProductsActiveIndex() === lastProductsIndex) {
                        setProductsActiveIndex(0)
                        productsHolderRef.current.querySelectorAll('li')[0]?.scrollIntoView()

                        return
                    }

                    setProductsActiveIndex(nextIndex)
                    productsHolderRef.current.querySelectorAll('li')[nextIndex]?.scrollIntoView()

                    return
                }

                if (getLastAddedActiveIndex() !== null) {
                    const nextIndex = getLastAddedActiveIndex() + 1

                    if (getLastAddedActiveIndex() === lastLastAddedIndex) {
                        setLastAddedActiveIndex(0)
                        lastAddedHolderRef.current.querySelectorAll('li')[0]?.scrollIntoView()

                        return
                    }

                    setLastAddedActiveIndex(nextIndex)
                    lastAddedHolderRef.current.querySelectorAll('li')[nextIndex]?.scrollIntoView()

                    return
                }

                if (getOftenSelectedActiveIndex() !== null) {
                    const nextIndex = getOftenSelectedActiveIndex() + 1

                    if (getOftenSelectedActiveIndex() === lastOfternSelectedIndx) {
                        setOftenSelectedActiveIndex(0)

                        return
                    }

                    setOftenSelectedActiveIndex(nextIndex)
                    oftenSelectedHolderRef.current.querySelectorAll('li')[nextIndex]?.scrollIntoView()

                    return
                }


                break
            //left
            case 37:
                if (!lastAddedHolderRef.current) return

                if (!isStartedTypingInMainInput()) {
                    if (getOftenSelectedActiveIndex() !== null) {
                        setOftenSelectedActiveIndex(null)
                        setLastAddedActiveIndex(0)
                        lastAddedHolderRef.current.querySelectorAll('li')[0]?.scrollIntoView()
                    }
                }

                break
            //right
            case 39:
                if (!oftenSelectedHolderRef.current) return

                if (!isStartedTypingInMainInput()) {
                    if (getLastAddedActiveIndex() !== null) {
                        setLastAddedActiveIndex(null)
                        setOftenSelectedActiveIndex(0)
                        oftenSelectedHolderRef.current?.querySelectorAll('li')[0]?.scrollIntoView()
                    }
                }

                break
            //up
            case 38:
                if (isStartedTypingInMainInput()) {
                    const prevIndex = getProductsActiveIndex() - 1

                    if (getProductsActiveIndex() === 0) {
                        setProductsActiveIndex(lastProductsIndex)
                        productsHolderRef.current.querySelectorAll('li')[lastProductsIndex]?.scrollIntoView()

                        return
                    }

                    setProductsActiveIndex(prevIndex)
                    productsHolderRef.current.querySelectorAll('li')[prevIndex]?.scrollIntoView()

                    return
                }

                if (getLastAddedActiveIndex() !== null) {
                    const prevIndex = getLastAddedActiveIndex() - 1

                    if (getLastAddedActiveIndex() === 0) {
                        setLastAddedActiveIndex(lastLastAddedIndex)
                        lastAddedHolderRef.current.querySelectorAll('li')[lastLastAddedIndex]?.scrollIntoView()

                        return
                    }

                    setLastAddedActiveIndex(prevIndex)
                    lastAddedHolderRef.current.querySelectorAll('li')[prevIndex]?.scrollIntoView()

                    return
                }

                if (getOftenSelectedActiveIndex() !== null) {
                    const prevIndex = getOftenSelectedActiveIndex() - 1

                    if (getOftenSelectedActiveIndex() === 0) {
                        setOftenSelectedActiveIndex(lastOfternSelectedIndx)
                        oftenSelectedHolderRef.current.querySelectorAll('li')[lastOfternSelectedIndx]?.scrollIntoView()

                        return
                    }

                    setOftenSelectedActiveIndex(prevIndex)
                    oftenSelectedHolderRef.current.querySelectorAll('li')[prevIndex]?.scrollIntoView()

                    return
                }

                break
            case 13:
                if (isStartedTypingInMainInput()) {
                    selectProduct(state.products.data[getProductsActiveIndex()])

                    return
                }

                selectProduct(latest[getLastAddedActiveIndex() !== null ? 'latestAddeed' : 'oftenSelected'][getLastAddedActiveIndex() !== null ? getLastAddedActiveIndex() : getOftenSelectedActiveIndex()])

                break
            default:
                break
        }
    }

    function setProductsActiveIndex(products) {
        setActiveIndexesList(prev => ({
            ...prev,
            products
        }))
    }
    function getProductsActiveIndex() {
        return activeIndexesInList.products
    }

    function setLastAddedActiveIndex(lastAdded) {
        setActiveIndexesList(prev => ({
            ...prev,
            lastAdded
        }))
    }
    function getLastAddedActiveIndex() {
        return activeIndexesInList.lastAdded
    }

    function setOftenSelectedActiveIndex(oftenSelected) {
        setActiveIndexesList(prev => ({
            ...prev,
            oftenSelected
        }))
    }
    function getOftenSelectedActiveIndex() {
        return activeIndexesInList.oftenSelected
    }

    const loadCategories = name => {
        if (!props.companyId) return

        if (state.categories.request) state.categories.request.cancel()

        const request = axios.CancelToken.source()

        setState(prev => ({
            ...prev,
            categories: {
                ...prev.categories,
                request
            }
        }))

        setLoadingCategories(true)

        Api.get('documents/products/categories',
            {
                params: {
                    company_id: props.companyId,
                    name
                },
                cancelToken: request.token
            }
        )
            .then(res => {
                const { items, total, pages } = res.data

                setState(prev => ({
                    ...prev,
                    categories: {
                        ...prev.categories,
                        data: items,
                        total,
                        pages
                    }
                }))
            })
            .finally(() => {
                setLoadingCategories(false)
            })
    }

    const setLoadingCategories = loading => {
        setState(prev => ({
            ...prev,
            categories: {
                ...prev.categories,
                loading
            }
        }))
    }

    const loadProducts = (categoryId, name) => {
        if (!props.companyId) return

        if (state.products.request) state.products.request.cancel()

        const request = axios.CancelToken.source()

        setState(prev => ({
            ...prev,
            products: {
                ...prev.products,
                request
            }
        }))

        setLoadingProducts(true)

        Api.get('documents/products/products',
            {
                params: {
                    company_id: props.companyId,
                    category_id: categoryId,
                    name
                },
                cancelToken: request.token
            }
        )
            .then(res => {
                const { items, total, pages } = res.data

                setState(prev => ({
                    ...prev,
                    products: {
                        ...prev.products,
                        data: items,
                        total,
                        pages,
                    }
                }))
                setProductsActiveIndex(0)
                setLastAddedActiveIndex(0)
                setOftenSelectedActiveIndex(null)
            })
            .finally(() => {
                setLoadingProducts(false)
            })
    }

    const setLoadingProducts = loading => {
        setState(prev => ({
            ...prev,
            products: {
                ...prev.products,
                loading
            }
        }))
    }

    const loadLatestProducts = () => {
        if (!props.companyId) return

        setLatestLoading(true)

        Api.get('documents/products/latest', {
            params: {
                company_id: props.companyId,
                customer_id: props.customerId,
                customer_eik: props.customerEik,
            }
        })
            .then(res => {
                setLatest(prev => ({
                    ...prev,
                    oftenSelected: res.data.often_selected,
                    latestAddeed: res.data.latest_added,
                }))

                setProductsActiveIndex(0)
                setLastAddedActiveIndex(0)
                setOftenSelectedActiveIndex(null)
            })
            .finally(() => {
                setLatestLoading(false)
            })
    }

    const setLatestLoading = loading => {
        setLatest(prev => ({
            ...prev,
            loading
        }))
    }

    const handleInputChange = e => {
        if (hasSelectedProduct()) return

        const { name, value } = e.target

        onChange(e)

        setState(prev => ({
            ...prev,
            input: {
                ...prev.input,
                value
            }
        }))

        handleDisableSave(false)
    }

    const handleCategoriesSearcgInputChange = e => {
        const { name, value } = e.target

        setState(prev => ({
            ...prev,
            input: {
                ...prev.input,
                categoriesSearchValue: value
            }
        }))
    }

    const showOptions = () => {
        if (!props.companyId) return

        if (state.showOptions) return

        setState(prev => ({
            ...prev,
            showOptions: true
        }))
    }

    const hideOptions = () => {
        setState(prev => ({
            ...prev,
            showOptions: false
        }))
    }

    const handleClickAway = () => {
        hideOptions()
    }

    const loadMore = (category, appendToCategory) => {
        const lastChildId = category.children[category.children.length - 1]?.id

        Api.get('documents/products/categories/more', {
            params: {
                id: category.id,
                last_id: lastChildId,
            }
        })
            .then(res => {
                appendToCategory(res.data)
                alert('трябва да се append към списъка с категории')
            })

    }

    const selectProduct = product => {
        setState(prev => ({
            ...prev,
            input: {
                ...prev.input,
                value: product?.translation?.name || product?.name || ''
            },
            product
        }))

        if (typeof props.onSelect === 'function') {
            props.onSelect(product)
        }

        hideOptions()
    }


    /**
     * директно добавяне на продукт в посочената категория
     * @param {object} category 
     * @param {string} productName 
     */
    const handleDirectAddProductToCategory = (category, productName) => {
        const { translation, color, id } = category
        const data = {
            categories: {
                id,
                name: translation.name,
                color,
            },
            product: {
                id: '',
                name: productName
            }
        }

        setLoadingCategories(true)

        Api.post('documents/products/save-categories-and-product', data)
            .then(() => {
                app.handleSuccess('Продукта беше добавен успешно!')
                loadProducts(null, getInputValue())
            })
            .finally(() => {
                hideOptions()
                setLoadingCategories(false)
            })

        FQF()
    }

    function FQF() {
        hideOptions()
        focusQtyField()
    }

    /**
     * рендерира папките (категориите) на база това, дали са разпънати или не
     * @param {object} data 
     * @param {number} level 
     * @returns {node}
     */
    const renderCategories = (data, level = 0) => {
        return (data || []).map((c, i) =>
            <>
                <li
                    data-level={level}
                    style={{
                        marginLeft: level === 0 ? 5 : level * 15,
                        marginTop: level === 0 ? 0 : '2px'
                    }}
                    key={c.id}
                    className={state.current === c.id ? 'selected' : ''}
                >
                    <div
                        className="direct-add"
                        onClick={() => {
                            handleExpandToggleCategories(c.id)
                        }}
                    >
                        <img
                            src={isCategoryExpanded(c.id) ? plusIcon : minusIcon}
                            style={!c.children.length ? { opacity: 0 } : {}}
                            className="expand"
                        />
                        <img
                            src={getInputValue() ? folderPurpleIcon : folderIcon}
                            className="folder"
                        />
                        {c.translation.name}
                    </div>
                    {isStartedTypingInMainInput() ?
                        <Tooltip title={`Бързо добавяне на артикул към - ${c.translation.name}`}>
                            <Button
                                className="nested-add"
                                onClick={() => {
                                    handleDirectAddProductToCategory(c, getInputValue())
                                }}
                            >
                                <img src={directAddIcon} />
                            </Button>
                        </Tooltip>
                        :
                        <>
                        </>
                    }
                    {isStartedTypingInMainInput() ?
                        <Tooltip title={`Добавяне на артикул към категория или подкатегория`}>
                            <Button
                                className="nested-add"
                                onClick={() => {
                                    addProductRef?.current?.open(getInputValue(), c)
                                }}
                            >
                                <img src={addToCategoryIcon} />
                            </Button>
                        </Tooltip>
                        :
                        <>
                        </>
                    }
                </li>
                {isCategoryExpanded(c.id) && renderCategories(c.children, level + 1)}
            </>
        )
    }

    /**
     * проверява дали дадена папка е разпъната
     * @param {number} categoryId 
     * @returns {boolean}
     */
    const isCategoryExpanded = categoryId => {
        if (state.expandedCategories.includes(categoryId)) {
            return true
        }

        return false
    }

    /**
     * задава текуща категория (id)
     * @param {number} current 
     */
    const setCurrentSelectedCategory = current => {
        setState(prev => ({
            ...prev,
            current
        }))
    }

    /**
     * разпъва/сгъва дадена папка
     * @param {number} categoryId 
     */
    const handleExpandToggleCategories = categoryId => {
        setCurrentSelectedCategory(categoryId)
        let { expandedCategories } = state

        if (isCategoryExpanded(categoryId)) {
            expandedCategories = expandedCategories.filter(e => Number(e) != Number(categoryId))
        } else expandedCategories.push(categoryId)

        setState(prev => ({
            ...prev,
            expandedCategories
        }))
    }

    /**
     * дали потребителя е започнал да пише в полето на продукта
     * @returns {boolean}
     */
    const isStartedTypingInMainInput = () => {
        if ((getInputValue() || '').length) {
            return true
        }

        return false
    }

    /**
     * дали има добавени продукти/категории в падащото меню
     * @returns {boolean}
     */
    const hasAddedItesm = () => {
        if (state.hasItems) {
            return true
        }

        return false
    }

    const appendDataFromAddProductModal = data => {
        loadProducts(null, getInputValue())
        FQF()
    }

    /**
     * взима всички резултати от списъка, които отговарят 1:1 с написаното в полето
     * @returns {object|undifined}
     */
    const getInputArticleFromList = () => {
        const article = getInputValue()

        const foundArticles = state.products.data.filter(d => (d?.translation?.name || d.name || '').toLowerCase() === article.toLowerCase())

        return foundArticles
    }

    /**
     * взима един резултат от getInputArticleFromList(), който няма категория
     * @returns {object}
     */
    const getInputArticleFromListWithoutCategory = () => getInputArticleFromList().find(a => !a.category_id)

    /**
     * връща текущия намерен артикул, който съвпада от полето за въвеждане и принадлежи на катеогиря
     * @returns {object}
     */
    const getCurrentArticleFormListWithCategory = () => getInputArticleFromList().find(a => a.category_id)

    /**
     * дали да се показва търсачката на категории
     * @returns {boolean}
     */
    const isSearchVisible = () => {
        if (state.categories.pages > 1 || document.activeElement.classList.contains('categories-search') || state.input.categoriesSearchValue.length) {
            return true
        }

        return false
    }

    /**
     * дали да се показват продуктите при писане
     * @returns {boolean}
     */
    const showProductsWhenTyping = () => {
        if ((!state.products.loading && !state.products.data.length) || !getInputValue().length) {
            return true
        }

        return false
    }

    /**
     * дали да се показват категориите при писане
     * @returns {boolean}
     */
    const showCategoriesWhenTyping = () => {
        if ((!state.categories.loading && !state.categories.data.length) || !getCurrentArticleFormListWithCategory()) {
            return false
        }

        return true
    }

    const productFieldRef = useRef()
    const selectedProductRef = useRef()

    useEffect(() => {
        if (productFieldRef.current?.getRef()) {
            productFieldRef.current.getRef().current.style.paddingLeft = `${(selectedProductRef.current?.clientWidth || 0) + (!hasSelectedProduct() ? 14 : 20)}px`
        }
    }, [state.product?.id])

    function hasSelectedProduct() {
        if (state.product?.id) {
            return true
        }

        return false
    }

    function handleRemoveSelectedProduct() {
        setState(prev => ({
            ...prev,
            product: null,
            input: {
                ...prev.input,
                value: prev.product?.name || prev.product?.translation?.name || ''
            }
        }))
    }

    function removeProductOnDelete(e) {
        if ((e.key === "Backspace" || e.key === "Delete") && hasSelectedProduct()) {
            handleRemoveSelectedProduct()
        }
    }

    return (
        <>
            {ReactDOM.createPortal(
                <AddProduct
                    ref={addProductRef}
                    appendData={appendDataFromAddProductModal}
                    getInputArticleFromListWithoutCategory={getInputArticleFromListWithoutCategory()}
                />,
                document.body
            )}
            {console.log(state.product)}
            <ClickAwayListener onClickAway={handleClickAway}>
                <div className="product-input" data-name={props.name}>
                    {hasSelectedProduct() ?
                        <div className="selected-product" ref={selectedProductRef}>
                            {state.product?.name || state.product?.translation?.name || ''}
                            <span onClick={handleRemoveSelectedProduct}>
                                <img
                                    src={removeIcon}
                                    className="remove"
                                />
                            </span>
                        </div>
                        :
                        <TextField
                            variant="outlined"
                            margin="none"
                            size="small"
                            fullWidth
                            // label="Име"
                            name={props.name}
                            value={hasSelectedProduct() ? '' : getInputValue() || ''}
                            onChange={handleInputChange}
                            onClick={showOptions}
                            onKeyDown={e => {
                                removeProductOnDelete(e)
                                controlFromKeyboardIniti(e)
                            }}
                            onFocus={() => {
                                // clearTimeout(fieldRowBlur)
                                showOptions()
                            }}
                            // onBlur={() => {
                            //     clearTimeout(fieldRowBlur)
                            //     fieldRowBlur = setTimeout(() => {
                            //         handleClickAway()
                            //     }, 500)
                            // }}
                            error={props.error}
                            helperText={props.helperText}
                            autoComplete="off"
                            ref={productFieldRef}
                            autoFocus={props.autoFocus}
                        />

                    }
                    <input type="hidden" name={props.productIdName} value={state.product?.id || props.productId || ''} />
                    {getInputValue() ?
                        <input type="hidden" name={props.name} value={getInputValue() || ''} />
                        :
                        <>
                        </>
                    }
                    {!hasAddedItesm() && (isStartedTypingInMainInput() && !state.categories.loading && !state.categories.data.length) ?
                        <div
                            className={`initial-add ${state.showOptions || addProductRef?.current?.isOpened() ? 'show' : ''}`}
                            onClick={() => {
                                addProductRef?.current?.open(getInputValue())
                            }}
                        >
                            <img src={folderPurpleAddBigIcon} />
                            <div className="right">
                                <h3>
                                    Добавяне на продукт в категория
                                </h3>
                                <p>
                                    Добавете този продукт към категория или подкатегория.
                                </p>
                            </div>
                        </div>
                        :
                        <div className={`dropdown ${state.showOptions || addProductRef?.current?.isOpened() ? 'show' : ''}`}>
                            {(!latest.loading && !latest.latestAddeed.length) && (!latest.loading && !latest.oftenSelected.length)
                                && ((!latest.loading && !latest.latestAddeed.length) && (!latest.loading && !latest.oftenSelected.length))
                                && (!state.categories.loading && !state.categories.data.length) && (!state.products.loading && !state.products.data.length)
                                && !isStartedTypingInMainInput() ?
                                <p>
                                    Започнете въвеждането на продукт, за да го добавите.
                                </p>
                                :
                                !isStartedTypingInMainInput() ?
                                    <>
                                        {/* latest */}
                                        {(!latest.loading && !latest.latestAddeed.length) && (!latest.loading && !latest.oftenSelected.length) ?
                                            <>
                                            </>
                                            :
                                            <>
                                                <div className="row">
                                                    {!latest.loading && !latest.latestAddeed.length ?
                                                        <>
                                                        </>
                                                        :
                                                        <div className="col col-2">
                                                            <div className="head">
                                                                <h6>
                                                                    <img src={lastIcon} />
                                                                    Последно добавени
                                                                </h6>
                                                            </div>
                                                            <div className={`ul ${!latest.loading && latest.latestAddeed.length === 0 ? 'no-results' : ''}`}>
                                                                {latest.loading
                                                                    ?
                                                                    <Skeleton count={4} height={30} />
                                                                    :
                                                                    latest.latestAddeed.length === 0
                                                                        ?
                                                                        <p>Няма намерени продукти</p>
                                                                        :
                                                                        <ul ref={lastAddedHolderRef}>
                                                                            {latest.latestAddeed.map((p, i) =>
                                                                                <li
                                                                                    key={'p-' + i}
                                                                                    onClick={e => selectProduct(p)}
                                                                                    className={getLastAddedActiveIndex() === i ? 'selected' : ''}
                                                                                >
                                                                                    <h6>
                                                                                        {p.translation?.name || p.name}
                                                                                    </h6>
                                                                                    {p.categories[0] ?
                                                                                        <span>
                                                                                            {p.categories[0]?.translation?.name || ''}
                                                                                        </span>
                                                                                        :
                                                                                        <>
                                                                                        </>
                                                                                    }
                                                                                </li>
                                                                            )}
                                                                        </ul>
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                    {!latest.loading && !latest.oftenSelected.length ?
                                                        <>
                                                        </>
                                                        :
                                                        <div className="col col-2">
                                                            <div className="head">
                                                                <h6>
                                                                    <img src={trendIcon} />
                                                                    Често добавяни
                                                                </h6>
                                                            </div>
                                                            <div className={`ul ${!latest.loading && latest.oftenSelected.length === 0 ? 'no-results' : ''}`}>
                                                                {latest.loading
                                                                    ?
                                                                    <Skeleton count={4} height={30} />
                                                                    :
                                                                    latest.oftenSelected.length === 0
                                                                        ?
                                                                        <p>Няма намерени продукти</p>
                                                                        :
                                                                        <ul ref={oftenSelectedHolderRef}>
                                                                            {latest.oftenSelected.map((p, i) =>
                                                                                <li
                                                                                    key={'p-' + i}
                                                                                    onClick={() => {
                                                                                        selectProduct(p)
                                                                                    }}
                                                                                    className={getOftenSelectedActiveIndex() === i ? 'selected' : ''}
                                                                                >
                                                                                    <h6>
                                                                                        {p.translation?.name || p.name}
                                                                                    </h6>
                                                                                    {p.categories[0] ?
                                                                                        <span>
                                                                                            {p.categories[0]?.translation?.name || ''}
                                                                                        </span>
                                                                                        :
                                                                                        <>
                                                                                        </>
                                                                                    }
                                                                                </li>
                                                                            )}
                                                                        </ul>
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                </div>


                                            </>
                                        }
                                        {((!latest.loading && !latest.latestAddeed.length) && (!latest.loading && !latest.oftenSelected.length)) || (!state.categories.loading && !state.categories.data.length) && (!state.products.loading && !state.products.data.length) ?
                                            <>
                                            </>
                                            :
                                            <div className="divider"></div>
                                        }
                                        {/* categories */}
                                        {(!state.categories.loading && !state.categories.data.length) && (!state.products.loading && !state.products.data.length) ?
                                            <>
                                            </>
                                            :
                                            <div className="row">
                                                <div className="col col-2">
                                                    <div
                                                        className="head row space-between"
                                                        style={{
                                                            marginBottom: 0,
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <h6>
                                                            <img src={categoriesIcon} />
                                                            Категории
                                                        </h6>
                                                    </div>
                                                    <div className={`categories ul ${!state.categories.loading && state.categories.data.length === 0 ? 'no-results' : ''}`}>
                                                        {state.categories.loading
                                                            ?
                                                            <Skeleton count={4} height={30} />
                                                            :
                                                            state.categories.data.length === 0
                                                                ?
                                                                <p>Няма намерени категории</p>
                                                                :
                                                                <>
                                                                    <ul>
                                                                        {renderCategories(state.categories.data)}
                                                                    </ul>
                                                                </>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col col-2">
                                                    <div
                                                        className="head row space-between"
                                                        style={{
                                                            marginBottom: 0,
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <h6>
                                                            <img src={productsIcon} />
                                                            Продукти
                                                        </h6>
                                                    </div>
                                                    <div className={`ul ${(!state.products.loading && state.products.data.length === 0) || (!state.current && !isStartedTypingInMainInput()) ? 'no-results' : ''}`}>
                                                        {!state.current && !isStartedTypingInMainInput()
                                                            ?
                                                            <p>Изберете категория или започенете търсене</p>
                                                            :
                                                            state.products.loading
                                                                ?
                                                                <Skeleton count={4} height={30} />
                                                                :
                                                                state.products.data.length === 0
                                                                    ?
                                                                    <p>Няма намерени продукти</p>
                                                                    :
                                                                    <ul>
                                                                        {state.products.data.map((p, i) =>
                                                                            <li key={'p-' + i} onClick={e => selectProduct(p)}>
                                                                                {p.translation?.name || p.name}
                                                                            </li>
                                                                        )}
                                                                    </ul>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </>
                                    :
                                    <>
                                        {state.products.loading
                                            ?
                                            <Skeleton count={4} height={30} />
                                            :
                                            <div className="products-found">
                                                <ul ref={productsHolderRef}>
                                                    {state.products.data.map((p, i) =>
                                                        <li
                                                            key={'p-' + i} onClick={e => selectProduct(p)}
                                                            className={`${getCurrentArticleFormListWithCategory()?.id === p?.id ? 'category-found-selected' : ''} ${getProductsActiveIndex() === i ? 'selected' : ''}`}
                                                        >
                                                            <h6>
                                                                {p.translation?.name || p.name}
                                                            </h6>
                                                            {p.categories[0] ?
                                                                <span>
                                                                    {p.categories[0]?.translation?.name || ''}
                                                                </span>
                                                                :
                                                                <>
                                                                </>
                                                            }
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        }
                                        {showProductsWhenTyping() || showCategoriesWhenTyping() ? <></> : <div className="divider big"></div>}
                                        {showCategoriesWhenTyping() ?
                                            <>
                                            </>
                                            :
                                            <div className="add-to-categories">
                                                {!state.categories.data.length && !state.categories.loading ?
                                                    <>
                                                    </>
                                                    :
                                                    <>
                                                        <h3>
                                                            Добавяне на “{getInputValue()}” в друга категория
                                                        </h3>
                                                        {isSearchVisible() ?
                                                            <input
                                                                type="search"
                                                                placeholder="Търсене на категория..."
                                                                onChange={handleCategoriesSearcgInputChange}
                                                                value={state.input.categoriesSearchValue}
                                                                className="categories-search"
                                                            />
                                                            :
                                                            <>
                                                            </>
                                                        }
                                                        {state.categories.loading
                                                            ?
                                                            <Skeleton count={4} height={30} />
                                                            :
                                                            state.categories.data.length === 0
                                                                ?
                                                                <p className="no-data">Няма намерени категории.</p>
                                                                :
                                                                <ul>
                                                                    {renderCategories(state.categories.data)}
                                                                </ul>
                                                        }
                                                    </>}
                                                {getInputArticleFromListWithoutCategory() || !state.products.data.length ?
                                                    <>
                                                        {(!state.categories.data.length && !state.categories.loading) ? <></> : <div className="divider"></div>}
                                                        <Button
                                                            className="add-category"
                                                            onClick={() => {
                                                                addProductRef?.current?.open(getInputValue())
                                                            }}
                                                        >
                                                            <img src={folderPurpleAddIcon} />
                                                            Създай нова категория
                                                        </Button>
                                                    </>
                                                    :
                                                    <>
                                                    </>
                                                }
                                            </div>
                                        }
                                    </>
                            }
                        </div>
                    }
                </div>
            </ClickAwayListener>
        </>
    )
}

export default forwardRef(ProductRowInput)