import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import { Modal, Col, Row, FloatingLabel, Form } from 'react-bootstrap'
import { Button, MenuItem } from '@material-ui/core'

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useHistory } from 'react-router-dom'

//misc
import SaveButton from 'components/misc/Button'
import { useAppContext } from 'providers/App'
import { TYPES as INVOICES_TYPES } from 'constants/invoices'
import { TYPES as DOCUMENTS_TYPES } from 'constants/documents'
import RedditSelect from 'components/misc/RedditSelect'
import RedditTextField from 'components/misc/RedditTextField'
import moment from 'moment'

function LinkDocument(props, ref) {

    const app = useAppContext()
    const navigate = useHistory()

    const defaultData = {

    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        refresh: false,
        data: defaultData,
        loading: false,
        loadingBase: false,
        loadingData: false,
        loadingApply: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
        onClose: null,
    })

    const [validations, setValidations] = useValidation()

    const formRef = useRef(null)

    useImperativeHandle(ref, () => ({
        open: (id) => {
            open(id)
        },
        hide: () => {
            hide()
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }))
        }
    }))

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }))

        onEntering?.()
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }))

        onExiting?.()
    }

    const open = (id) => {
        setState(prev => ({
            ...prev,
            data: {
                num: id
            },
            id: id,
        }))

        show()
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault()
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }))

        setValidations(null)

        if (typeof state.onClose === 'function') {
            hide(state.onClose)
        } else {
            hide()
        }
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            refresh: moment().unix(),
        }))
    }

    const handleSave = () => {
        if (state.loading) {
            return
        }

        setState(prev => ({
            ...prev,
            loading: true,
            loadingBase: true,
        }))

        setValidations(null)

        let url = 'store/accounts/link-document'

        let data = new FormData(formRef.current)

        data.append('id', state.id)

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (res.data.success) {
                state.onSuccess()

                hide(state.onClose)
            }
        }).catch(error => {
            const _err = error.response

            if (_err && _err.status && _err.status === 422) {
                setValidations(_err.data.errors)
            }
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
                loadingBase: false,
                loadingApply: false,
            }))
        })
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    return (
        <div className={`${state.show ? "visible" : ""} overlay`}>
            <div
                className={`popup-primary small ${state.show ? "show" : ""}`}
            >
                {state.show ?
                    <div className="body">
                        <form ref={formRef} onSubmit={handleSave}>
                            <h3 style={{
                                marginBottom: '15px'
                            }}>
                                Прикачване на документ към сметка
                            </h3>
                            <div className="row">
                                <div className="col" style={{
                                    maxWidth: '300px'
                                }}>
                                    <RedditSelect
                                        name="type_id"
                                        value={state.data.type_id || ''}
                                        onChange={handleInputChange}
                                        label="Вид документ"
                                        error={Boolean(validations?.type_id)}
                                        helperText={validations?.type_id && (validations?.type_id[0] || validations?.type_id)}
                                    >
                                        {INVOICES_TYPES.map(t =>
                                            <MenuItem key={t.id} value={t.type}>
                                                {t.name || ''}
                                            </MenuItem>
                                        )}
                                        {/* {DOCUMENTS_TYPES.map(t =>
                                            <MenuItem key={t.id} value={t.type}>
                                                {t.name || ''}
                                            </MenuItem>
                                        )} */}
                                    </RedditSelect>
                                </div>
                                <div className="col">
                                    <RedditTextField
                                        size="small"
                                        variant="filled"
                                        margin="dense"
                                        fullWidth
                                        label="Номер на документ"
                                        name="no"
                                        value={state.data.no || ''}
                                        onChange={handleInputChange}
                                        error={Boolean(validations?.no)}
                                        helperText={validations?.no && (validations?.no[0] || validations?.no)}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                    :
                    <>
                    </>
                }
                <div className="footer">
                    <Button
                        className="cancel"
                        onClick={handleClose}
                    >
                        Отказ
                    </Button>
                    <SaveButton
                        loading={state.loadingBase}
                        disabled={state.loading}
                        className="save"
                        text="Прикачи"
                        onClick={handleSave}
                    />
                </div>
            </div>
        </div>
    )
}

export default forwardRef(LinkDocument)
