import { Link, useLocation } from 'react-router-dom'

//images
import documentsIcon from 'assets/img/header/icons/documents.png'
import financesIcon from 'assets/img/header/icons/finances.png'
import clientsIcon from 'assets/img/header/icons/clients.png'
import settingsIcon from 'assets/img/header/icons/settings.png'
import dashboardIcon from 'assets/img/header/icons/dashboard.png'
import statisticsIcon from 'assets/img/header/icons/statistics.png'
import salesIcon from 'assets/img/header/icons/sales.png'
import depotIcon from 'assets/img/header/icons/depot.png'
import catelogueIcon from 'assets/img/header/icons/catalogue.png'
import subscriptionsIcon from 'assets/img/header/icons/subscriptions.png'
import marketingIcon from 'assets/img/header/icons/marketing.png'
import communicationIcon from 'assets/img/header/icons/communication.png'

//partials
import Company from './partials/Company'
import Notifications from './partials/Notifications'
import User from './partials/User'

//providers
import { useAuthDataContext } from 'providers/Auth'

const Header = () => {
    const { pathname, search } = useLocation()
    const auth = useAuthDataContext()

    const menuItems = [
        {
            id: 21,
            base: 'dashboard',
            path: '/dashboard',
            title: 'Табло',
            icon: dashboardIcon,
            module: 'documents',
        },
        {
            id: 1,
            base: 'invoices',
            path: '/invoices',
            title: 'Документи',
            icon: documentsIcon,
            module: 'documents',
        },
        auth.getUser().hasCRMAccess() ? {
            id: 'communication',
            base: 'communication',
            path: '/communication',
            title: 'Комуникация',
            icon: communicationIcon,
        } : null,
        auth.getUser().hasCRMAccess() ? {
            id: 'sales',
            base: 'sales',
            path: null,
            title: 'Продажби',
            icon: salesIcon,
            children: [
                {
                    id: 'salesall',
                    base: 'sales/sales',
                    path: '/sales/all',
                    title: 'Продажби',
                },
                {
                    id: 'complaints',
                    base: 'sales/complaints',
                    path: '/sales/complaints',
                    title: 'Рекламации',
                },
                {
                    id: 'recommendations',
                    base: 'sales/recommendations',
                    path: '/sales/recommendations',
                    title: 'Препоръки',
                },
            ]
        } : null,
        auth.getUser().hasOrdersAccess() ? {
            id: 'orders',
            base: 'orders',
            path: '/orders',
            title: 'Поръчки',
            icon: salesIcon,
        } : null,
        auth.getUser().hasStoreAccess() ? {
            id: 'accounts',
            base: 'accounts',
            path: '/accounts',
            title: 'Продажби',
            icon: salesIcon,
        } : null,
        auth.getUser().hasCRMAccess() ? {
            id: 'marketing',
            base: 'marketing',
            path: null,
            title: 'Маркетинг',
            icon: marketingIcon,
            children: [
                {
                    id: 'calendar',
                    base: 'marketing/calendar',
                    path: '/marketing/calendar',
                    title: 'Календар на дейността',
                },
                {
                    id: 'archive',
                    base: 'marketing/archive',
                    path: '/marketing/archive',
                    title: 'Маркетинг архив',
                },
                {
                    id: 'deals',
                    base: 'marketing/deals',
                    path: '/marketing/deals',
                    title: 'Управление на сделките',
                },
            ]
        } : null,
        auth.getUser().hasSubscriptionAccess() ? {
            id: 'subscriptions',
            base: 'subscriptions',
            path: '/subscriptions/all',
            title: 'Абонаменти',
            icon: subscriptionsIcon,
        } : null,
        {
            id: 'finances',
            base: 'finances',
            path: '/finances/incomes',
            title: 'Финанси',
            icon: financesIcon,
        },
        (auth.getUser().hasStoreAccess() || auth.getUser().hasCRMAccess()) ? {
            id: 'catalogue',
            base: 'catalogue',
            path: '/articles',
            title: 'Каталог',
            icon: catelogueIcon,
        } : null,
        auth.getUser().hasStoreAccess() ? {
            id: 'storage',
            base: 'storage',
            path: null,
            title: 'Склад',
            icon: depotIcon,
            children: [
                {
                    id: 'amounts_by_depots',
                    base: 'reports',
                    path: '/reports/storage/amounts-by-depots',
                    title: 'Наличности',
                },
                {
                    id: 'loads',
                    base: 'storage/loads',
                    path: '/storage/loads',
                    title: 'Зареждания',
                },
                {
                    id: 'moves',
                    base: 'storage/moves',
                    path: '/storage/moves',
                    title: 'Прехвърляния',
                },
                {
                    id: 'outs',
                    base: 'storage/outs',
                    path: '/storage/outs',
                    title: 'Изписвания',
                },
                {
                    id: 'revisions',
                    base: 'storage/revisions',
                    path: '/storage/revisions',
                    title: 'Ревизии',
                },
            ]
        } : null,
        {
            id: 'reports',
            base: 'reports',
            path: '/reports',
            title: 'Справки',
            icon: statisticsIcon,
        },
        {
            id: 7,
            base: 'customers',
            path: '/customers',
            title: 'Клиенти',
            icon: clientsIcon
        },
        {
            id: 8,
            base: 'settings',
            path: '/settings/numbers',
            title: 'Настройки',
            icon: settingsIcon
        },
    ]

    /**
     * 
     * @param {array} menuItems всички модули
     * @returns достъпните за потребителя модули
     */
    const getModules = menuItems => {
        let modules = []

        modules = menuItems.filter(item => item).filter(item => {
            if (item?.module) {
                if (auth.getUser()?.module(item.module)) {
                    return true
                }

                return false
            }

            return true
        })

        return modules
    }

    /**
     * 
     * @param {object} item модул от менюто 
     * @returns дали модула е активен
     */
    const getActiveModule = item => {
        let active = false
        const { path, base } = item

        if ((path && (pathname + search).includes(path)) || `${pathname}${search}`.includes(base)) {
            active = true
        }

        return active
    }

    const render = (items) => {
        return items.map(item =>
            <li key={item.id}>
                {item.children && item.children.length > 0
                    ?
                    <>
                        <span
                            className={getActiveModule(item) ? 'active' : ''}
                            style={{
                                cursor: 'default'
                            }}
                        >
                            <img src={item.icon} />
                            {item.title}
                            <ul>
                                {render(item.children)}
                            </ul>
                        </span>

                    </>
                    :
                    <Link
                        to={item.path}
                        className={getActiveModule(item) ? 'active' : ''}
                    >
                        <img src={item.icon} />
                        {item.title}
                    </Link>
                }
            </li>
        )
    }

    return (
        <header>
            <div className="wrapper">
                <Company />
                <ul className="menu">
                    {render(
                        getModules(menuItems)
                    )}
                </ul>
                <div className="more">
                    <Notifications />
                    <User />
                </div>
            </div>
        </header>
    )
}

export default Header