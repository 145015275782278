import { useRef } from "react"
import { useHistory } from "react-router-dom"

//helpers
import { splitLongNumbers } from "helpers/numbers"

//libraries
import Skeleton from "react-loading-skeleton"
import { Button, Tooltip } from "@material-ui/core"

//components
import NoDataFound from "components/misc/NoDataFound"

//constants
import { INVOICE } from "constants/invoices"

//hooks
import useInvoiceFromClient from "hooks/useInvoiceFromClient"
import CreateDocumentMenu from "components/pages/dashboard/components/CreateDocumentMenu"

//images
import dropArrowIcon from "assets/img/app/icons/drop-arrow-white.png"
import previewIcon from "assets/img/articles/icons/preview.png"
import useInvoiceFromAccount from "hooks/useInvoiceFromAccount"

function SubscriptionsActivitiesDashboardTable({
  loadingActivities,
  getActivities,
  clientId,
  handleClose,
}) {
  const navigate = useHistory()
  const { createInvoiceFromClient, isLoading } = useInvoiceFromClient()

  const createButtonRef = useRef(null)
  const createDocumentMenuRef = useRef(null)

  function handleCreateDocument(type = INVOICE) {
    if (type.target) return

    createInvoiceFromClient(clientId, type).then((res) => {
      if (res) {
        navigate.push("/invoices", {
          data: res,
          mode: "new",
        })

        handleClose()
      }
    })
  }

  function handleClickAddButton(e) {
    if (!e.target.classList.contains("menu")) handleCreateDocument(INVOICE)
  }

  function createDifferentDocument() {
    createDocumentMenuRef.current.open()
  }

  function handlePreviewInvoice(data) {
    navigate.push("/invoices", {
      data,
      mode: "preview",
    })

    handleClose()
  }

  function renderAdditionalButton() {
    return (
      <>
        <CreateDocumentMenu
          createDocumentButtonRef={createButtonRef}
          ref={createDocumentMenuRef}
          externalAddDocument={handleCreateDocument}
        />
        <Button
          className={`add with-dropdown new-btn-primary ${
            isLoading ? "disabled" : ""
          }`}
          ref={createButtonRef}
          onClick={handleClickAddButton}
        >
          Фактура
          <i className="menu" onClick={createDifferentDocument}>
            <img src={dropArrowIcon} />
          </i>
        </Button>
      </>
    )
  }

  if (loadingActivities) {
    return (
      <div
        style={{
          width: "100%",
        }}
      >
        <Skeleton count={1} height={35} />
        <Skeleton count={1} height={35} />
        <Skeleton count={1} height={35} />
        <Skeleton count={1} height={35} />
        <Skeleton count={1} height={35} />
      </div>
    )
  }

  if (!loadingActivities && !getActivities().length) {
    return (
      <>
        <NoDataFound
          style={{
            marginBottom: "-10px",
          }}
        >
          <h2>Няма налични записи</h2>
          <p>Може да създадете нов документ от бутона по-долу</p>
          {renderAdditionalButton()}
        </NoDataFound>
      </>
    )
  }

  return (
    <>
      <table className="type-outer">
        <thead>
          <tr>
            <th>Тип</th>
            <th>№</th>
            <th>Дата</th>
            <th>Получател</th>
            <th className="text-right">Общо</th>
            <th className="text-center">Опции</th>
          </tr>
        </thead>
        <tbody>
          {getActivities().map((activity) => (
            <tr key={activity.id}>
              <td
                style={{
                  cursor: "default",
                }}
              >
                {activity.type.translation.name}
              </td>
              <td
                style={{
                  cursor: "default",
                }}
              >
                {activity.no}
              </td>
              <td
                style={{
                  cursor: "default",
                }}
              >
                {activity.is_active ? "Да" : "Не"}
              </td>
              <td
                style={{
                  cursor: "default",
                }}
              >{`${activity.date_dmy} г.`}</td>
              <td
                style={{
                  cursor: "default",
                }}
                className="text-right"
              >
                {splitLongNumbers(
                  (Number(activity.gross_price_formatted) || 0).toFixed(2)
                )}{" "}
                {activity.currency.name}
              </td>
              <td
                className="text-center"
                style={{
                  cursor: "default",
                }}
              >
                <div className="options-holder">
                  <Tooltip title="Преглед в нова страница">
                    <Button
                      className="options-menu-button ex-option"
                      onClick={() => {
                        handlePreviewInvoice(activity)
                      }}
                    >
                      <img src={previewIcon} />
                    </Button>
                  </Tooltip>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <hr />
      {renderAdditionalButton()}
    </>
  )
}

export default SubscriptionsActivitiesDashboardTable
