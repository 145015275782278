export default class Refs {
    static instance = null;

    refs = {};

    static getInstance() {
        if (Refs.instance === null) {
            Refs.instance = new Refs();
        }

        return Refs.instance;
    }

    setRef(key, ref) {
        this.refs[key] = ref;
    }

    unsetRef(key) {
        delete this.refs[key];
    }

    getRef(key) {
        return this.refs[key] || null;
    }
}
