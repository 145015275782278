//constants
import { CAMPAIGN_FORM_TEMPLATE_FIELD_TYPES } from "constants/CampaignFormTemplate"

//libraries
import { Button, Tooltip } from "@material-ui/core"

//stores
import { useCampaignFormTemplate } from "stores/customer/useCampaignFormTemplate"

//images
import removeIcon from "assets/img/app/icons/delete-white.png"

function CampaignFormTemplateAnswer({ answer }) {
  const {
    id,
    type,
    placeholderValue,
    index,
    parentType,
    parentGroupId,
    parentQuestionId,
  } = answer
  const { removeAnswer } = useCampaignFormTemplate()

  function getAnswerType() {
    let index = 0
    const currentType = type || parentType

    Object.values(CAMPAIGN_FORM_TEMPLATE_FIELD_TYPES).map(({ type }, i) => {
      if (currentType === type) {
        index = i
      }

      return type
    })

    return Object.keys(CAMPAIGN_FORM_TEMPLATE_FIELD_TYPES)[index]
  }

  return (
    <li className="campaign-form-template-answer">
      <div className="answer-holder">
        <span>{`${index + 1}. `}</span>
        <span className="type">
          <img
            src={CAMPAIGN_FORM_TEMPLATE_FIELD_TYPES[getAnswerType()].icon}
            alt=""
          />
        </span>
        <input
          placeholder="Въведете етикет..."
          value={placeholderValue}
          className="placeholder text"
        />
        <Tooltip title="Премахване на отговор">
          <Button
            onClick={() => removeAnswer(parentGroupId, parentQuestionId, id)}
            className="remove-answer"
          >
            <img src={removeIcon} alt="" />
          </Button>
        </Tooltip>
      </div>
    </li>
  )
}

export default CampaignFormTemplateAnswer
