import { createContext, useState, useContext, useEffect, useRef } from "react"
import { cloneDeep } from "lodash"
import { useQuery as uQ, queryCache } from 'react-query'
import axios from 'axios'

//helpers
import { useIsMount } from "helpers/IsMount"
import { useNestedState } from "helpers/NestedState"
import { usePresenceSocketChannel } from "helpers/Socket"
import Api from "helpers/Api"

//providers
import { useAuthDataContext } from "providers/Auth"
import { useHistory } from "react-router-dom"

//constants
import { DOCUMENT_TYPE } from "constants/Document"
import { useQuery } from "helpers/Url"
import moment from "moment"

export const DocumentContext = createContext({})

const DocumentProvider = props => {
    const history = useHistory()
    const auth = useAuthDataContext()
    const isMount = useIsMount()
    const query = useQuery()

    const searchTimeoutRef = useRef(null)
    const documentsCancelTokenRef = useRef(null)

    const filter = {
        page: 1,
        start_date: '',
        end_date: '',
        search: '',
        sent: '',
        period: '',
        status: '',
        payment_method_id: '',
        client_id: '',
        type_id: DOCUMENT_TYPE.INVOICE
    }

    const [state, setState] = useState({
        loading: true,
        forceLoading: true,
        refresh: false,
        refreshPreview: false,
        setFilter: false,
        // data: [],
        // pages: 0,
        // total: 0,
        filter,
        docCount: {},
        refetchRQ: false
    })

    const [documents, setDocuments] = useNestedState({})

    const [documentsEditing, setDocumentsEditing] = useState({})

    const [documentsCount, setDocumentsCount] = useState({
        offer: 0,
        proforma: 0,
        invoice: 0,
        creditnote: 0,
        debitnote: 0,
        protocol: 0,
        stock: 0
    })

    const [documentTabs, setDocumentTabs] = useState({
        data: {},
        type: null
    })

    useEffect(() => {
        if (state.refetchRQ) {
            refetch()
            handleRefetchRQ(false)
        }
    }, [state.refetchRQ])

    const { data, isLoading, refetch } = uQ(['data', getSearchParams()], loadData, {
        staleTime: 100,
    })

    async function loadData() {
        let url = `documents/all`

        if (documentsCancelTokenRef.current) documentsCancelTokenRef.current.cancel()
        documentsCancelTokenRef.current = axios.CancelToken.source()

        const res = await Api.get(url, {
            params: getSearchParams(),
            cancelToken: documentsCancelTokenRef.current?.token
        })

        return res.data
    }

    function getSearchParams() {
        let params = { ...state.filter }
        Object.entries(state.filter).map(filter => {
            if ((String(filter[1]) || '').includes(',')) {
                filter[1] = filter[1].split(',')
            }

            if (String(filter[1]).length) {
                params = {
                    ...params,
                    [filter[0]]: filter[0] === 'page' ? Number(filter[1]) : filter[1]
                }
            }
        })

        if (Array.isArray(params['type_id'])) {
            params['type_id'] = [...(params?.['type_id'] || []), state.filter.type_id]
        }

        return params
    }

    function getSearchParam(key) {
        return String(state.filter[key]) || null
    }

    // Search
    function handleSearch(key, val, delay = 300) {
        clearTimeout(searchTimeoutRef.current)

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },
        }))

        searchTimeoutRef.current = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                setFilter: moment().unix()
            }))
        }, delay)
    }

    const handlePreviewInvoice = (data = {}) => {
        history.push("/invoices", { data, mode: "preview" })
    }

    const handleEditInvoice = (data = {}) => {
        history.push("/invoices", { data, mode: "edit" })
    }

    function handleRefetchRQ(refetchRQ) {
        setState(prev => ({
            ...prev,
            refetchRQ
        }))
    }

    useEffect(() => {
        if (!isMount) return

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: 1,
            },
            // refresh: new Date(),
            // setFilter: new Date(),
            forceLoading: true,
        }))
    }, [auth])

    usePresenceSocketChannel({
        channel: 'companies',
        channelId: auth?.getUser()?.getCompany()?.getId(),
        event: '.blackbox\\documents\\events\\InvoiceChanged',
        onEvent: (payload) => {
            setState(prev => ({
                ...prev,
                // [payload.type_id]: {},
                refresh: moment().unix(),
                setFilter: moment().unix(),
                forceLoading: false,
                refreshPreview: moment().unix()
            }))

            handleRefetchRQ(true)

            // да се изчисти стейта на запазения документ,
            // за да може да се направи нова заявка за него, 
            // когато се отвори страницата за редакция
            if (documents[payload.id]) {
                setDocuments(prev => ({
                    ...prev,
                    [payload.id]: null
                }))
            }
        },
    }, [auth])

    const channel = usePresenceSocketChannel({
        channel: 'documents',
        channelId: auth?.getUser()?.getCompany()?.getId(),
        onJoin: members => {
            // console.log(members);
        },
        onJoining: member => {
            // console.log(member);
        },
        onLeaving: member => {
            removeDocumentsEditingUser(member.id)
        }
    }, [auth])

    useEffect(() => {
        if (!channel) {
            return
        }

        channel.listenForWhisper('editing', e => {
            setDocumentsEditing(prev => ({
                ...prev,
                [e.id]: prev[e.id] ? {
                    ...prev[e.id],
                    [e.user.id]: e.user
                } : {
                    [e.user.id]: e.user
                }
            }))
        })

        channel.listenForWhisper('stopEditing', e => {
            removeDocumentsEditingUser(e.user.id, e.id)
        })

        channel.whisper('triggerEditing')

    }, [channel])

    const removeDocumentsEditingUser = (userId, documentId = null) => {
        let data = cloneDeep(documentsEditing)

        if (documentId) {
            if (data[documentId]) {
                if (data[documentId][userId]) {
                    delete data[documentId][userId]
                }

                if (Object.keys(data[documentId]).length === 0) {
                    delete data[documentId]
                }
            }
        } else {
            Object.entries(data).map(e => {
                if (data[e[0]][userId]) {
                    delete data[e[0]][userId]
                }

                if (Object.keys(data[e[0]]).length === 0) {
                    delete data[e[0]]
                }
            })
        }

        setDocumentsEditing(data)
    }

    const setDocumentData = (id, data) => {
        setDocuments(id, data, null)
    }

    const clearFilter = defaultTypeId => {
        setState(prev => ({
            ...prev,
            filter: {
                ...filter,
                type_id: defaultTypeId
            },
            setFilter: moment().unix()
        }))
    }

    const cleanUp = () => {
        setState(prev => ({
            ...prev,
            refresh: false,
            setFilter: false,
        }))
    }

    const loadDocumentsCount = async () => {
        return await Api.get('documents/count')
            .then(res => {
                let data = {}
                let docCount = {}
                res.data.map(r => {
                    data[r.name] = r.count
                    docCount[r.type_id] = r
                })

                setDocumentsCount(data)
                setState(prev => ({
                    ...prev,
                    docCount
                }))
            })
    }
    const exportedData = {
        state,
        setState,
        documents,
        setDocuments,
        setDocumentData,
        documentsEditing,
        clearFilter,
        cleanUp,
        loadDocumentsCount,
        documentsCount,
        handlePreviewInvoice,
        documentTabs,
        handleEditInvoice,
        handleRefetchRQ,
        documentReqData: data,
        isLoading,
        getSearchParam,
        getSearchParams
    }

    return <DocumentContext.Provider value={exportedData} {...props} />
}

export const useDocumentContext = () => useContext(DocumentContext)

export default DocumentProvider