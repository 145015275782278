import { useState, useEffect, useRef } from 'react'
import { Button, Dropdown, Table } from 'react-bootstrap'
import Api from 'helpers/Api'
import Loader from 'components/misc/Loader'
import moment from 'moment'
import PriceValue from 'components/partials/PriceValue'
import { Pencil, Upc, Upload } from 'react-bootstrap-icons'
import { useHistory } from 'react-router-dom'
import Rows from './Rows'
import { useAuthDataContext } from 'providers/Auth'

function Index(props) {

    const { show, hide } = props

    const auth = useAuthDataContext()
    const navigate = useHistory()

    const defaultData = {
        rows: []
    }

    const [state, setState] = useState({
        data: defaultData,
        loading: false,
    })

    const importModalRef = props.importModalRef

    useEffect(() => {
        if (props.id) {
            setState(prev => ({
                ...prev,
                loading: true
            }))

            Api.get('store/storeloads/show', {
                params: {
                    id: props.id
                }
            }).then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data,
                    loading: false,
                }))
            })
        }
    }, [props.id])

    const showArticle = id => {
        props.showArticle(id)
    }

    const handleEdit = () => {
        navigate.push('/storage/loads/' + state.data.id + '/edit')
    }

    const handleCreateAccount = () => {
        props.hide()

        navigate.push('/accounts/new?from_load_id=' + state.data.id)
    }

    const handleCreateStoreMove = () => {
        props.hide()

        navigate.push('/storage/moves/new?from_load_id=' + state.data.id)
    }

    const handleCreateInvoice = () => {
        props.hide()

        navigate.push('/invoices/new?from_load_id=' + state.data.id)
    }

    const handleCreateStoreReturn = () => {
        props.hide()

        navigate.push('/storage/loads/new?from_load_id=' + state.data.id + '&return=1')
    }

    const handlePrintLabels = () => {
        props.hide()

        navigate.push('/articles-print-list-free?from_load_id=' + state.data.id)
    }

    const handleImport = () => {
        props.hide()

        let modal = importModalRef.current

        if (!modal) {
            return
        }

        modal.open()

        modal.setParams({
            load_id: state.data.id,
        })

        modal.onClose(() => {
            props.show()
        })

        modal.onSuccess((res) => {
            console.log(res)
            // alert(1);

            // modal.close();

            // timeout-а в импорта е 700мс
            setTimeout(() => {
                props.showCheckup(res.checkup_id)
            }, 1000)
        })
    }

    return (

        state.loading
            ?
            <Loader />
            :
            <div className="right-form-holder">
                <div className="preview-article-home">
                    <div className="row">
                        <div className="col">
                            <h6>
                                Основни данни
                            </h6>
                            <div className="field">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="th">
                                                ID
                                            </td>
                                            <td>
                                                {state.data.id}
                                            </td>
                                            <td className="th">
                                                Създал
                                            </td>
                                            <td>
                                                {state.data?.user?.username}
                                            </td>
                                            <td className="th">
                                                Създадено на
                                            </td>
                                            <td>
                                                {state.data.created_at ? moment(state.data.created_at).format('DD.MM.YYYY HH:mm') : ''}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="th">
                                                Операция
                                            </td>
                                            <td>
                                                {state.data?.operation?.translation?.name || state.data?.operation?.name}
                                            </td>
                                            <td className="th">
                                                Статус
                                            </td>
                                            <td>
                                                {state.data?.status?.translation?.name || state.data?.status?.name}
                                            </td>
                                            <td className="th">
                                                Затворено на
                                            </td>
                                            <td>
                                                {state.data.closed_at ? moment(state.data.closed_at).format('DD.MM.YYYY HH:mm') : ''}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="th">
                                                Склад
                                            </td>
                                            <td>
                                                {state.data?.depot?.name}
                                            </td>
                                            <td className="th">
                                                Търговски обект
                                            </td>
                                            <td>
                                                {state.data?.depot?.store?.translation?.name || state.data?.depot?.store?.name}
                                            </td>
                                            <td className="th">
                                                Заявка №
                                            </td>
                                            <td>
                                                {state.data?.order_no}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="th">
                                                Тип документ
                                            </td>
                                            <td>
                                                {state.data?.documenttype?.translation?.name || state.data?.documenttype?.name}
                                            </td>
                                            <td className="th">
                                                Документ №
                                            </td>
                                            <td>
                                                {state.data?.document_no}
                                            </td>
                                            <td className="th">
                                                Документ дата
                                            </td>
                                            <td>
                                                {state.data.document_date ? moment(state.data.document_date).format('DD.MM.YYYY HH:mm') : ''}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="th">
                                                Доставчик
                                            </td>
                                            <td>
                                                {state.data?.supplier?.name}
                                            </td>
                                            <td className="th">
                                                Регистрация по ДДС
                                            </td>
                                            <td>
                                                {state.data.has_tax ? 'ДА' : 'НЕ'}
                                            </td>
                                            <td className="th">
                                                Цени
                                            </td>
                                            <td>
                                                {state.data.with_tax ? 'С ДДС' : 'Без ДДС'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="th">
                                                Платена сума
                                            </td>
                                            <td>
                                                {(state.data.total_paid || 0).toFixed(2)}  {state.data?.paymentmethod && ' / ' + state.data?.paymentmethod?.translation?.name || state.data?.paymentmethod?.name}
                                            </td>
                                            <td className="th">
                                                Срок за плащане
                                            </td>
                                            <td>
                                                {state.data.paid_due_date ? moment(state.data.paid_due_date).format('DD.MM.YYYY HH:mm') : ''}
                                            </td>
                                            <td className="th">
                                                Валута / Курс
                                            </td>
                                            <td>
                                                {state.data?.currency?.name || '-'} / {state.data?.currency_rate ? state.data?.currency_rate.toFixed(4) : '-'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="th">
                                                Бележки
                                            </td>
                                            <td colSpan={5}>
                                                {state.data.description}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <h6>
                                Артикули
                            </h6>
                            <div className="field">
                                <Rows
                                    id={state.data.id}
                                    show={show}
                                    hide={hide}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
    )
}

export default Index