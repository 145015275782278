function useRightClick() {
    function _calcMenuOptionsPosition({ clientX, clientY }, el) {
        const menuWidthInPixels = el.clientWidth
        const menuHeightInPixels = el.clientHeight + 10
        let x = clientX - menuWidthInPixels
        let y = clientY

        if (x < menuWidthInPixels) {
            x = clientX
        }

        if ((window.innerHeight - menuHeightInPixels) < y) {
            y = window.innerHeight - menuHeightInPixels
        }

        return {
            x,
            y
        }
    }

    function handlePositionMenuOptions(e, { id }) {
        const { button, target } = e
        const el = document.querySelector(`.with-right-click.id-${id} .MuiPaper-root`)
        const { x, y } = _calcMenuOptionsPosition(e, el)


        document.querySelector('body').click()


        if (button === 2 || (button === 0 && target.classList.contains('options-menu-button') && !target.classList.contains('ex-option'))) {
            e.preventDefault()
            e.stopPropagation()

            if (document.querySelector('.menu-is-opened')) return

            document.querySelector(`.options-menu-button[data-id="${id}"]`)?.click()
            el.style.marginLeft = `${x}px`
            el.style.marginTop = `${y}px`

            document.querySelector(`tr[data-id="${id}"]`)?.classList.add('active')
        } else {
            document.querySelector('tr.active')?.classList.remove('active')
        }
    }

    return {
        handlePositionMenuOptions
    }
}

export default useRightClick