//images
import readioIcon from "assets/img/customers/icons/radio.png"
import checkboxIcon from "assets/img/customers/icons/checkbox.png"
import textIcon from "assets/img/customers/icons/text.png"

export const CAMPAIGN_FORM_TEMPLATE_FIELD_TYPES = {
    TEXT: {
        title: "Текстово поле",
        icon: textIcon,
        type: 'text',
    },
    RADIO: {
        title: "Единичен избор",
        icon: readioIcon,
        type: 'radio',
    },
    CHECKBOX: {
        title: "Множествен избор",
        icon: checkboxIcon,
        type: 'checkbox',
    },
}