//stores
import { usePreviewBillTabs } from "stores/accounts/usePreviewBillTabs"

//components
import PreviewBill from "./PreviewBill"
import PreviewBillInvoice from "./PreviewBillInvoice"
import PreviewBillStock from "./PreviewBillStock"

export default function PreviewBillContent() {
  const { getActiveTabIndex } = usePreviewBillTabs()

  switch (getActiveTabIndex()) {
    case 1:
      return <PreviewBillInvoice />
    case 2:
      return <PreviewBillStock />
    default:
      return <PreviewBill />
  }
}
