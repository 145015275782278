import { createContext, useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Api from '../helpers/Api';
import User from "../models/User";
import moment from "moment"
// import { useAppContext } from "./App";

export const AuthDataContext = createContext({});

const initialAuthData = {
    isLogged: false,
    data: {},
    user: null,
    booted: false,
};

let interval;

const AuthDataProvider = props => {

    const history = useHistory();
    // const app = useAppContext();

    const [authData, setAuthData] = useState(initialAuthData);
    const [booted, setBooted] = useState(false);
    const [state, setState] = useState({
       
    })

    const getAuthData = () => {
        let currentAuthData = initialAuthData;

        try {
            if (localStorage.getItem('auth')) {
                currentAuthData = JSON.parse(localStorage.getItem('auth'));
            }
        } catch (e) {
            //
        }

        setAuthData(currentAuthData);
        setBooted(true);

        // if (currentAuthData.isLogged) {
        //     refresh();
        // }
    }

    const isLogged = () => {
        return Boolean(authData.isLogged);
    }

    const getUser = () => {
        return authData.user;
    }

    const loadData = async (retry = 0) => {
        return await Api.get('auth/profile')
            .then(res => {

                // let state = localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')) : initialAuthData;

                setAuthData(prev => ({
                    ...prev,
                    user: new User(res.data)
                }));

                // localStorage.setItem('auth', JSON.stringify(state));

            })
            .catch(err => {

                if (retry > 2) {
                    logout();
                } else {
                    loadData(retry + 1);
                }

            });
    }

    const refresh = async () => {
        await loadData()
    }

    const setData = newAuthData => {
        setAuthData(prev => ({
            ...prev,
            user: new User(newAuthData)
        }));
    }

    const login = newAuthData => {

        let data = {
            access_token: newAuthData.access_token,
            expires_in: newAuthData.expires_in,
            token_type: newAuthData.token_type
        };

        let expiresIn = data.expires_in;

        if (expiresIn > 0) {
            let expiresAt = moment().add(expiresIn, 'seconds').toDate();
            let expiresAtTimestamp = expiresAt.getTime();

            data.expires_at = expiresAt;
            data.expires_at_timestamp = expiresAtTimestamp;
        }

        let state = {
            isLogged: true,
            data: data,
        }

        localStorage.setItem('auth', JSON.stringify(state));

        setData(newAuthData.user)

        window.location.href = '/dashboard'
    }

    const refreshToken = () => {
        Api.post('auth/refresh')
            .then(response => {
                login(response.data);
            })
            .catch(error => {
                logout();
            });
    }

    const logout = (redirect = true) => {
        Api.post('auth/logout')
            .finally(() => {
                setAuthData(initialAuthData);

                localStorage.clear();

                if (redirect) {
                    window.location = '/?redirect=' + window.location.pathname + window.location.search;
                    // history.push('/?redirect=' + window.location.pathname + window.location.search);
                }
            });
    }

    const getToken = () => {
        if (authData.data) {
            return authData.data.access_token;
        }
        return null;
    }

    useEffect(() => {
        getAuthData();
    }, []);

    useEffect(() => {

        clearInterval(interval);

        if (authData.isLogged) {
            let expiringTime = authData.data?.expires_at_timestamp;

            if (expiringTime) {
                interval = setInterval(() => {
                    let currentTime = moment().unix();

                    let ttl = (expiringTime - currentTime) / 1000;

                    if (ttl <= 300) {
                        refreshToken();
                    }

                }, 60000);
            }
        }

        return () => {
            clearInterval(interval);
        }
    }, [authData]);

    const auth = {
        state,
        isLogged,
        getUser,
        getToken,
        loadData,
        setData,
        login,
        refresh,
        logout
    };

    if (booted) {
        return <AuthDataContext.Provider value={auth} {...props} />;
    }

    return null;
};

export const useAuthDataContext = () => useContext(AuthDataContext);

export default AuthDataProvider;