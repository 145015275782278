import Api from "helpers/Api";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";

function IsPublic(props, ref) {

    useImperativeHandle(ref, () => ({
        getName: getName,
        getType: getType,
        getTitle: getTitle,
        hasSettings: hasSettings,
        getSettings: getSettings,
        getDefaultOptions: ({ createOrUpdate }) => ({})
    }));

    const getName = () => {
        return 'Публичен';
    }

    const getType = () => {
        return 'is_public';
    }

    const getTitle = () => {
        return 'Възможност да се показва артикула в публичните списъци';
    }

    const hasSettings = () => true;

    const getSettings = ({ colIndex, state, setConfig, createOrUpdate, getValues }) => {


        const getMapping = () => {
            getValues(colIndex)
                .then(res => {
                    let options = {};

                    res.data.values.map(r => {
                        options[r] = 1;
                    });

                    setConfig(options);

                })
        }

        // console.log(state.options)

        return (
            <div>
                <label>
                    "Mapping" на стойности:
                </label>
                <div>
                    <Button
                        size="sm"
                        onClick={e => getMapping()}
                    >
                        Изтегли текущите стойности
                    </Button>

                    <Table className="mt-3" bordered>
                        <tbody>
                            {Object.entries(state.options).map(c =>
                                <tr key={c[0]}>
                                    <td style={{ textAlign: 'right' }}>{c[0]}</td>
                                    <td style={{ width: '50px' }}>
                                        <Form.Check
                                            checked={Number(c[1]) === 1}
                                            onChange={e => setConfig(c[0], e.target.checked ? 1 : 0)}
                                            size="sm"
                                        />

                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
            </div>
        )
    }

    return getName();
}

export default forwardRef(IsPublic)