import { useRef } from "react"

//libraries
import { Button } from "@material-ui/core"

//providers
import { useDocumentContext } from "components/pages/documents/Provider"

//components
import ItemsPerPageOption from "./ItemsPerPageOption"

function PaginationOptions() {
  const itemsPerPageOptionRef = useRef(null)

  const context = useDocumentContext()
  const { documentReqData } = context

  const openMenu = (e) => {
    itemsPerPageOptionRef.current.openMenu(e)
  }

  return (
    <>
      {/* DS */}
      {/* <div className="pagination-options">
        Показани на страница: <Button onClick={openMenu}>20</Button> от{" "}
        {documentReqData?.total}
      </div>
      <ItemsPerPageOption ref={itemsPerPageOptionRef} /> */}
    </>
  )
}

export default PaginationOptions
