import { useEffect } from 'react'

//MUI components
import { Tooltip } from '@material-ui/core'

//images
import closeSmallIcon from 'assets/img/app/icons/close-small.png'

//providers
import { useDocumentsTabsContext } from 'providers/DocumentsTabs'
import { useAuthDataContext } from 'providers/Auth'

//constants
import { INVOICES_TABS } from '../constants/tabs'

const Tabs = () => {
    const { getSubtabsForCurrentTab, getActiveTabTypeId, getActiveSubtabId, handleSetActiveSubtabId, handleSetActiveTabTypeId, handleRemoveSubtab } = useDocumentsTabsContext()
    const auth = useAuthDataContext()

    const companyId = auth?.getUser()?.getCompany()?.getId()
    const subtabEls = document.querySelectorAll('.active.bordered ul li')

    /**
     * 
     * @param {array} subtabs всички подраздели (видими и изтрити) 
     * @returns всички видими подраздели 
     */
    const getVisibleSubtabs = subtabs => {
        const visibleSubtabs = subtabs.filter(t => {
            return !t.deleted && !isDocumentCreatedByDifferentCompany(t)
        })

        return visibleSubtabs
    }

    /**
     * 
     * @param {object} tab подраздел 
     * @returns дали подрадела е създаден от различна фирма от текущо избраната
     */
    const isDocumentCreatedByDifferentCompany = tab => (tab.companyId && (Number(tab.companyId) !== Number(companyId)))

    /**
     * 
     * @param {object} tab раздел 
     * @returns данни за раздела
     */
    const getUnactiveTabData = tab => {
        const data = {
            // - 1, защото изключваме раздела по подразбиране
            subtabsCount: getVisibleSubtabs(tab.subtabs).length - 1
        }

        return data
    }

    /**
     * 
     * @param {object} tab раздел
     * @param {object} subtab подраздел 
     * @returns името на подраздела (съставено от номер / дата) или името на раздела (което ще се ползва в подраздела по подразбиране, напр. Фактури)
     */
    const getSubtabName = subtab => {
        const { no, date, date_dmy } = subtab

        return `${no ? Number(no).toString() : '-'} / ${date_dmy || date}г.`
    }

    useEffect(() => {
        subtabEls[0]?.click()
    }, [companyId])

    const getDocumentsFromCompany = docs => {
        return docs.filter(d => {
            return (d.companyId === companyId) || !d.companyId
        })
    }

    return (
        <ul className="tabs">
            {INVOICES_TABS.map((t, i) =>
                <li
                    key={`tab_${i}`}
                    className={`${getActiveTabTypeId() === t.typeId ? 'active' : ''} ${getActiveTabTypeId() === t.typeId && getSubtabsForCurrentTab(t.typeId).length ? 'bordered' : ''}`}
                >
                    <ul className="subtabs">
                        <li
                            className={!getActiveSubtabId() ? 'active-subtab' : ''}
                            onClick={() => {
                                handleSetActiveSubtabId(null)
                                handleSetActiveTabTypeId(t.typeId)
                            }}
                        >
                            <span style={{
                                display: 'flex'
                            }}>
                                {t.fullName}
                                {(getActiveTabTypeId() !== t.typeId) && (getSubtabsForCurrentTab(t.typeId).length) ?
                                    <i className="count">
                                        {getSubtabsForCurrentTab(t.typeId).length}
                                    </i>
                                    :
                                    <></>
                                }
                            </span>
                        </li>
                        {getActiveTabTypeId() === t.typeId ?
                            getSubtabsForCurrentTab(t.typeId).map((s, si) =>
                                <Tooltip
                                    title={getSubtabName(s)}
                                >
                                    <li
                                        key={`subtab_${s.id}`}
                                        className={getActiveSubtabId() === s.id ? 'active-subtab' : ''}
                                        onClick={e => {
                                            if (!e.target.classList.contains('close')) handleSetActiveSubtabId(s.id)
                                        }}
                                        data-id={s.id}
                                        data-type-id={t.typeId}
                                    >
                                        <span>
                                            {getSubtabName(s)}
                                        </span>
                                        <i
                                            onClick={() => {
                                                handleRemoveSubtab(t.typeId, s.id)
                                            }}
                                            className="close"
                                            data-id={s.id}
                                        >
                                            <img src={closeSmallIcon} />
                                        </i>
                                    </li>
                                </Tooltip>
                            )
                            :
                            <></>
                        }
                    </ul>

                </li>
            )}
        </ul>
    )
}

export default Tabs