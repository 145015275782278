function LeftSideMenu({
  tabsArr = [],
  activeTabIndex,
  setActiveTabIndex,
  disabled = false,
}) {
  function handleSetActiveTab(tabIndex) {
    setActiveTabIndex(tabIndex)
  }

  return (
    <aside className={disabled ? "disabled" : ""}>
      <ul className="left-side-menu">
        {tabsArr.map((t, i) => (
          <li
            key={t.name}
            style={{ color: t.validate ? "red" : "" }}
            className={activeTabIndex === i ? "active" : ""}
            onClick={() => {
              handleSetActiveTab(i)
            }}
          >
            {t.icon ? <img src={t.icon} alt={t.name} /> : <></>}
            {t.name}
          </li>
        ))}
      </ul>
    </aside>
  )
}

export default LeftSideMenu
