export const addExtraPrice = (price, percent) => {
    if (!isNumeric(price)) {
        price = 0;
    }

    if (!isNumeric(percent)) {
        percent = 0;
    }

    return Number(price) * (1 + Number(percent) / 100);
}

export const removeExtraPrice = (price, percent) => {
    if (!isNumeric(price)) {
        price = 0;
    }

    if (!isNumeric(percent)) {
        percent = 0;
    }

    return Number(price) / (100 + Number(percent)) * 100;
}

export const calcExtraPrice = (from, to) => {
    if (!isNumeric(from)) {
        from = 0;
    }

    if (!isNumeric(to)) {
        to = 0;
    }

    if (from === 0 || from === to) {
        return 0;
    }

    return (((to - from) / from) * 100);
}

export const calcDiscount = (price, percent) => {
    if (!isNumeric(price)) {
        price = 0;
    }

    if (!isNumeric(percent)) {
        percent = 0;
    }

    return (price * percent) / 100;
}

export const applyDiscount = (price, percent) => {
    if (!isNumeric(price)) {
        price = 0;
    }

    if (!isNumeric(percent)) {
        percent = 0;
    }

    return price - ((price * percent) / 100);
}

export const applyDiscountOrMarkupPrice = (price, percent) => {
    if (!isNumeric(price)) {
        price = 0;
    }

    if (!isNumeric(percent)) {
        percent = 0;
    }

    return price + ((price * percent) / 100);
}

export const vatPrice = (price, vat) => {
    if (!isNumeric(price)) {
        price = 0;
    }

    if (!isNumeric(vat)) {
        vat = 0;
    }

    return Number(price) * (Number(vat) / 100);
}

export const negativeValue = value => {
    return -1 * Math.abs(value);
}

export const positiveValue = value => {
    return Math.abs(value);
}

export const isNumeric = (value) => {
    return !isNaN(value - parseFloat(value));
}

export const isFloat = value => {
    return parseFloat(value) !== parseInt(value);
}

export const convertFromRateToRate = (price, from, to) => {
    if (!isNumeric(price)) {
        price = 0;
    }

    if (!isNumeric(from)) {
        from = 0;
    }

    if (!isNumeric(to)) {
        to = 0;
    }

    price = price / from;
    price = price * to;

    return price;
}
