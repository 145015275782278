class Middleware {

    constructor(app) {
        this.app = app;
        this.auth = app.getAuth();
        this.user = app.getUser();
        this.history = app.getHistory();
        this.location = app.getLocation();
        this.query = app.getQuery();
    }

    redirect(to) {
        // this.history.push(to);
        this.history.replace(to);
    }

    run(props, next) {

    }
}

export default Middleware;