//librairies
import { create } from 'zustand'

export const useCampaignFormTemplate = create((set, get) => ({
    groups: [
        {
            id: 1,
            titleValue: "",
            descriptionValue: "",
            questions: []
        },
    ],
    addGroup: () => set((state) => ({
        groups: [
            ...state.groups,
            {
                id: state.groups.length + 1,
                titleValue: "",
                descriptionValue: "",
                questions: []
            }
        ]
    })),
    removeGroup: (id) => set((state) => ({
        groups: state.groups.filter(group => group.id !== id)
    })),
    updateGroup: (group) => set((state) => ({
        groups: state.groups.map(item => item.id === group.id ? group : item)
    })),
    addQuestion: (groupId, questionType = "checkbox") => set((state) => ({
        groups: state.groups.map(group => {
            if (group.id === groupId) {
                return {
                    ...group,
                    questions: [
                        ...group.questions,
                        {
                            id: group.questions.length + 1,
                            value: "",
                            type: questionType, // checkbox, radio, text, free
                            answers: [],
                        },
                    ]
                }
            }
            return group
        })
    })),
    removeQuestion: (groupId, questionId) => set((state) => ({
        groups: state.groups.map(group => {
            if (group.id === groupId) {
                return {
                    ...group,
                    questions: group.questions.filter(question => question.id !== questionId)
                }
            }
            return group
        })
    })),
    updateQuestion: (groupId, question) => set((state) => ({
        groups: state.groups.map(group => {
            if (group.id === groupId) {
                return {
                    ...group,
                    questions: group.questions.map(item => item.id === question.id ? question : item)
                }
            }
            return group
        })
    })),
    addAnswer: (groupId, questionId, answerType = null) => set((state) => ({
        groups: state.groups.map(group => {
            if (group.id === groupId) {
                return {
                    ...group,
                    questions: group.questions.map(question => {
                        if (question.id === questionId) {
                            return {
                                ...question,
                                answers: [
                                    ...question.answers,
                                    {
                                        id: question.answers.length + 1,
                                        type: answerType || question.type,
                                        placeholderValue: "",
                                    }
                                ]
                            }
                        }
                        return question
                    })
                }
            }
            return group
        })
    })),
    removeAnswer: (groupId, questionId, answerId) => set((state) => ({
        groups: state.groups.map(group => {
            if (group.id === groupId) {
                return {
                    ...group,
                    questions: group.questions.map(question => {
                        if (question.id === questionId) {
                            return {
                                ...question,
                                answers: question.answers.filter(answer => answer.id !== answerId)
                            }
                        }
                        return question
                    })
                }
            }
            return group
        })
    })),
    updateAnswer: (groupId, questionId, answer) => set((state) => ({
        groups: state.groups.map(group => {
            if (group.id === groupId) {
                return {
                    ...group,
                    questions: group.questions.map(question => {
                        if (question.id === questionId) {
                            return {
                                ...question,
                                answers: question.answers.map(item => item.id === answer.id ? answer : item)
                            }
                        }
                        return question
                    })
                }
            }
            return group
        })
    })),
    handleChangeQuestionType: (groupId, questionId, type) => set((state) => ({
        groups: state.groups.map(group => {
            if (group.id === groupId) {
                return {
                    ...group,

                    questions: type === "free" ? [
                        ...group.questions.map(question => {
                            // Когато се избере тип "микс от полета", занулява всички добавени отговори
                            // към съответния въпрос, защото не знае към какъв тип да ги преобразува
                            if (question.id === questionId) {
                                question.type = type
                                question.answers = []
                            }

                            return question
                        })
                    ] : group.questions.map(question => {
                        // Когато се избере друг тип полета, преобразува всички добавени отговори
                        // в съответния тип
                        if (question.id === questionId) {
                            question.type = type
                            question.answers = question.answers.map(answer => {
                                answer.type = type
                                return answer
                            })
                        }

                        return question
                    })
                }
            }
            return group
        })
    })),
    getGroups: () => get().groups,
}))