//hooks
import useFetchIncomesStats from "../hooks/useFetchIncomesStats"

//helpers
import { splitLongNumbers } from "helpers/numbers"

//librarires
import Skeleton from "react-loading-skeleton"

//images
import incomesIcon from "assets/img/customers/icons/incomes.png"
import unpaidIcon from "assets/img/customers/icons/unpaid.png"

function IncomesBadge({ clientId }) {
  const { getData, loadingIncomesStats } = useFetchIncomesStats(clientId)

  if (loadingIncomesStats) {
    return (
      <div className="incomes-stats">
        <Skeleton count={1} height={52} width={160} />
        <Skeleton
          count={1}
          height={52}
          width={160}
          style={{
            marginLeft: "10px",
          }}
        />
      </div>
    )
  }

  return (
    <div className="incomes-stats">
      <div className="incomes-badge">
        <img src={incomesIcon} />
        <div className="col">
          <b>приходи</b>
          <div>
            {splitLongNumbers(Number(getData().incomes || 0).toFixed(2))}{" "}
            <span>BGN</span>
          </div>
        </div>
      </div>
      <div className="unpaid-badge">
        <img src={unpaidIcon} />
        <div className="col">
          <b>разходи</b>
          <div>
            {splitLongNumbers(Number(getData().unpaid || 0).toFixed(2))}{" "}
            <span>BGN</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IncomesBadge
