import React, { useEffect, useRef, useState } from 'react'
import Api from 'helpers/Api'
import { buildSearchParams, useQuery } from 'helpers/Url'
import { useLocation, Link, useHistory } from 'react-router-dom'
import { Alert, Table } from 'react-bootstrap'
import { useAuthDataContext } from 'providers/Auth'
import { Button } from '@material-ui/core'

import GroupOptions from 'components/pages/settings/partials/depots_groups/partials/Options'
import Options from './partials/Options'

import Question from 'components/modals/Question'
import Filter from './partials/Filter'
import AddOrEdit from './partials/AddOrEdit'
import AddOrEditGroup from 'components/pages/settings/partials/depots_groups/partials/AddOrEdit'

//misc
import MenuOptions from 'components/misc/MenuOptions'
import Pagination from 'components/misc/Pagination'
import Loader from 'components/misc/Loader'
import NoDataFound from 'components/misc/NoDataFound'
import moment from 'moment'

let timeout

function Index() {

    const location = useLocation()
    const history = useHistory()
    const query = useQuery()
    const auth = useAuthDataContext()

    const addOrEditGroupModalRef = useRef(null)
    const addOrEditModalRef = useRef(null)
    const deleteModalRef = useRef(null)

    const [state, setState] = useState({
        loading: true,
        refresh: false,
        setFilter: false,
        data: [],
        pages: 0,
        total: 0,
        filter: {
            page: 1,
        },
    })

    useEffect(() => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: query.get('page') || 1,
                search: query.get('search') || ''
            },
            refresh: moment().unix()
        }))
    }, [location.search])

    useEffect(() => {
        if (state.setFilter) {
            history.push('?' + buildSearchParams(query, state.filter))
        }

    }, [state.setFilter])

    useEffect(() => {
        if (state.refresh) {
            loadData()
        }
    }, [state.refresh])

    useEffect(() => {
        refresh()
    }, [auth.getUser().getStoreId()])

    const loadData = () => {

        loading(true)

        let url = 'store/depots/all'

        Api.get(url, {
            params: state.filter
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data.items,
                total: res.data.total,
                pages: res.data.pages
            }))

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 })
            }

            loading(false)

        })
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }))
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: reset ? 1 : prev.filter.page
            },
            setFilter: moment().unix(),
            refresh: moment().unix(),
        }))
    }

    const handleAddGroup = () => {
        let modal = addOrEditGroupModalRef.current

        modal.add()

        modal.onSuccess(() => {
            refresh(true)
        })
    }

    // update
    const handleEditGroup = id => {
        let modal = addOrEditGroupModalRef.current

        modal.edit(id)

        modal.onSuccess(() => {
            refresh(false)
        })
    }

    // delete
    const handleDeleteGroup = id => {
        let modal = deleteModalRef.current

        modal.open()
        modal.onSuccess(() => {
            handleDestroyGroup(id)
        })
    }

    const handleDestroyGroup = id => {
        Api.post('store/depots/groups/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                refresh(false)
            }
        })
    }

    const handleAdd = (groupId) => {
        let modal = addOrEditModalRef.current

        modal.add(groupId)

        modal.onSuccess(() => {
            refresh(true)
        })
    }

    // update
    const handleEdit = id => {
        let modal = addOrEditModalRef.current

        modal.edit(id)

        modal.onSuccess(() => {
            refresh(false)
        })
    }

    // delete
    const handleDelete = id => {
        let modal = deleteModalRef.current

        modal.open()
        modal.onSuccess(() => {
            handleDestroy(id)
        })
    }

    const handleDestroy = id => {
        Api.post('store/depots/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                refresh(false)
            }
        })
    }


    // Search
    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout)

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },
        }))

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                setFilter: moment().unix()
            }))
        }, delay)
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            setFilter: moment().unix()
        }))
    }

    return (
        <>

            <AddOrEditGroup
                ref={addOrEditGroupModalRef}
            />

            <AddOrEdit
                ref={addOrEditModalRef}
                refresh={refresh}
            />

            <Question
                ref={deleteModalRef}
                mainMessage="Сигурни ли сте?"
                agreeBtnText="Изтриване"
                agreeBtnClass="remove"
            />

            <section id="companies">
                {auth.getUser()?.getStore()?.name ?
                    <>
                        <div id="head">
                            <div className="title">
                                {auth.getUser()?.getStore()?.name} | Складове
                            </div>
                            <Button className="add" onClick={handleAddGroup} disabled={auth.getUser().permission('depots_edit') === false}>
                                Добави група
                            </Button>
                        </div>
                        <hr />

                        <div className="panel">
                            <Filter
                                filter={state.filter}
                                handleSearch={handleSearch}
                            />

                            {state.loading
                                ?
                                <Loader />
                                :
                                state.data.length === 0 ?
                                    <NoDataFound />
                                    :
                                    <>
                                        <table className="type-outer">
                                            <thead>
                                                <tr>
                                                    <th className="id">ID</th>
                                                    <th>Име</th>
                                                    <th className="options">
                                                        Опции
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {state.data.map(c =>
                                                    <React.Fragment key={'c-' + c.id}>
                                                        <tr

                                                        >
                                                            <td className="id">
                                                                {c.id}
                                                            </td>

                                                            <td>
                                                                <span className="link" onClick={e => handleEditGroup(c.id)}>
                                                                    {c.name}
                                                                </span>
                                                            </td>

                                                            <td className="options" style={{ width: '65px' }}>
                                                                <MenuOptions>
                                                                    <GroupOptions
                                                                        data={c}
                                                                        handleAdd={handleAdd}
                                                                        handleEdit={handleEditGroup}
                                                                        handleDelete={handleDeleteGroup}
                                                                    />
                                                                </MenuOptions>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={3} style={{ padding: 0, paddingLeft: '50px' }}>
                                                                {c.depots.length === 0
                                                                    ?

                                                                    <Button
                                                                        className="add"
                                                                        onClick={e => handleAdd(c.id)}
                                                                        style={{
                                                                            marginTop: '15px',
                                                                            marginBottom: '15px',
                                                                            marginLeft: '-50px'
                                                                        }}
                                                                    >
                                                                        Добави склад към тази група
                                                                    </Button>
                                                                    :
                                                                    <table className="type-outer">
                                                                        <thead>
                                                                            <tr>
                                                                                <th className="id">
                                                                                    ID
                                                                                </th>
                                                                                <th>
                                                                                    Име
                                                                                </th>
                                                                                <th className="text-center">Тежест</th>
                                                                                <th className="options" style={{ width: '65px' }}>
                                                                                    Опции
                                                                                </th>
                                                                            </tr>

                                                                        </thead>
                                                                        <tbody>
                                                                            {c.depots.map(d =>
                                                                                <tr
                                                                                    key={'d-' + d.id}
                                                                                >
                                                                                    <td className="id">
                                                                                        {d.id}
                                                                                    </td>

                                                                                    <td>
                                                                                        <span className="link" onClick={e => handleEdit(d.id)}>
                                                                                            {d.name}
                                                                                        </span>
                                                                                    </td>

                                                                                    <td className="text-center">
                                                                                        {d.priority}
                                                                                    </td>

                                                                                    <td className="options" style={{ width: '65px' }}>
                                                                                        <MenuOptions>
                                                                                            <Options
                                                                                                data={d}
                                                                                                handleEdit={handleEdit}
                                                                                                handleDelete={handleDelete}
                                                                                            />
                                                                                        </MenuOptions>
                                                                                    </td>
                                                                                </tr>
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                }
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                )}
                                            </tbody>
                                        </table>

                                        <Pagination
                                            className="mt-3"
                                            page={state.filter.page}
                                            pages={state.pages}
                                            total={state.total}
                                            handlePage={handlePage}
                                        />
                                    </>
                            }

                        </div>
                    </>
                    :
                    <>
                        <div id="head">
                            <div className="title">
                                Складове
                            </div>
                        </div>
                        <div className="panel" style={{
                            paddingTop: '40px'
                        }}>
                            <p style={{
                                textAlign: 'center'
                            }}>
                                За да добавите склад, първо добавете търговски обект.
                            </p>
                            <br />
                            <Link
                                to="/settings/stores"
                                style={{
                                    margin: '0 auto',
                                    width: 'fit-content',
                                    display: 'block'
                                }}
                            >
                                <Button className="add">
                                    Нов търговски обект
                                </Button>
                            </Link>
                        </div>
                    </>
                }
            </section>
        </>
    )
}

export default Index