import { useState, forwardRef, useImperativeHandle } from 'react'

//images
import closeIcon from 'assets/img/modals/icons/close.png'

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//misc
import RedditTextField from 'components/misc/RedditTextField'
import RedditSelect from 'components/misc/RedditSelect'
import Autocomplete from "components/misc/Autocomplete"
import SaveButton from 'components/misc/Button'

//MUI components
import { Button, MenuItem } from '@material-ui/core'

//providers
import { useAppContext } from 'providers/App'

//partials
import Permissions from './Permissions'
import Stores from './Stores'

function Add(props, ref) {
    const app = useAppContext()
    const [permissions, setPermissions] = useState([])

    const initialState = {
        overlay: false,
        modal: false,
        data: null,
        selectedPermissions: {},
        selectedStores: {},
        parent: null,
        onSuccess: null,
        userRoles: [],
        companiesList: []
    }
    const [state, setState] = useNestedState({
        ...initialState
    })

    const [validations, setValidations] = useValidation()

    useImperativeHandle(ref, () => ({
        add: parent => {
            setState(prev => ({
                ...prev,
                parent
            }))

            show()
        },
        hide: () => {
            hide()
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        }
    }))

    const show = () => {
        setState(prev => ({
            ...prev,
            overlay: true,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }))
        }, 50)

        getUserRoles()
    }

    const hide = () => {
        setState(prev => ({
            ...prev,
            modal: false,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                ...initialState
            }))
        }, 150)

        setValidations(null)
    }

    const handleHide = e => {
        e.preventDefault()

        hide()
    }

    const handleSave = e => {
        e.preventDefault()

        setLoading(true)
        setValidations(null)

        const url = 'users/add'
        const data = new FormData(e.target)

        data.append('company_id', props.companyId)

        Api.post(url, data)
            .then(() => {
                app.handleSuccess('Потребителя беше създаден успешно!')
                props.refresh()
                hide()
            })
            .catch(error => {
                const _err = error.response

                if (_err && _err.status && _err.status === 422) setValidations(_err.data.errors)
                else app.handleError('Нещо се обърка!')
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const setLoading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    const handleInputChange = e => {
        const { name, value } = e.target

        setState(name, value, 'data')
    }


    const getUserRoles = async () => {
        const { data } = await Api.get('users/roles/all')

        setState(prev => ({
            ...prev,
            userRoles: data
        }))
    }

    const isStoreChecked = store => {
        return state.selectedStores[store.id] ? true : false;
    }

    const handleStoreChange = store => {
        const selected = Object.assign({}, state.selectedStores);

        if (isStoreChecked(store)) {
            delete selected[store.id];
        } else {
            selected[store.id] = 1;
        }

        setState(prev => ({
            ...prev,
            selectedStores: selected
        }))
    }

    return (
        <>
            <div key={state.id} className={`${state.overlay ? 'visible' : ''} overlay`}>
                <form className={`${state.modal ? 'show' : ''} popup-primary add-edit-user small`} noValidate onSubmit={handleSave}>
                    <h2 className="head">
                        Добавяне на потребител
                        <img className="close-icon" alt="close" src={closeIcon} onClick={handleHide} />
                    </h2>

                    <div className="body">
                        <div className="row">
                            <div className="col">
                                <b>
                                    Основна информация
                                </b>
                                <div className="holder">
                                    <div className="row">
                                        <div className="col" style={{ marginRight: '20px' }}>
                                            <div className="row">
                                                <div className="col">
                                                    <RedditTextField
                                                        size="small"
                                                        variant="filled"
                                                        margin="dense"
                                                        label="Е-поща"
                                                        name="email"
                                                        value={state.data?.email || ''}
                                                        onChange={handleInputChange}
                                                        error={Boolean(validations && validations.email)}
                                                        helperText={validations && validations.email && (validations.email[0] || validations.email)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <RedditTextField
                                                        size="small"
                                                        variant="filled"
                                                        margin="dense"
                                                        label="Парола"
                                                        name="password"
                                                        type="password"
                                                        value={state.data?.password || ''}
                                                        onChange={handleInputChange}
                                                        error={Boolean(validations && validations.password)}
                                                        helperText={validations && validations.password && (validations.password[0] || validations.password)}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <RedditTextField
                                                        size="small"
                                                        variant="filled"
                                                        margin="dense"
                                                        label="Повторете паролата"
                                                        name="password_confirmation"
                                                        type="password"
                                                        value={state.data?.password_confirmation || ''}
                                                        onChange={handleInputChange}
                                                        error={Boolean(validations && validations.password)}
                                                        helperText={validations && validations.password && (validations.password[0] || validations.password)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="row">
                                                <div className="col">
                                                    <RedditTextField
                                                        size="small"
                                                        variant="filled"
                                                        margin="dense"
                                                        label="Име"
                                                        name="name"
                                                        value={state.data?.name || ''}
                                                        onChange={handleInputChange}
                                                        error={Boolean(validations && validations.name)}
                                                        helperText={validations && validations.name && (validations.name[0] || validations.name)}
                                                        autoFocus
                                                    />
                                                </div>
                                                <div className="col">
                                                    <RedditTextField
                                                        size="small"
                                                        variant="filled"
                                                        margin="dense"
                                                        label="Фамилия"
                                                        name="lastname"
                                                        value={state.data?.lastname || ''}
                                                        onChange={handleInputChange}
                                                        error={Boolean(validations && validations.lastname)}
                                                        helperText={validations && validations.lastname && (validations.lastname[0] || validations.lastname)}
                                                        autoFocus
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <RedditSelect
                                                        name="role_id"
                                                        value={state.data?.role_id || state?.userRoles[1]?.id || ''}
                                                        onChange={handleInputChange}
                                                        label="Потребителска роля"
                                                    >
                                                        {console.log(state)}
                                                        {state.userRoles.map(r =>
                                                            <MenuItem
                                                                key={`r_${r.id}`}
                                                                value={r.id}
                                                            >
                                                                {r?.translation?.name || '-'}
                                                            </MenuItem>
                                                        )}
                                                    </RedditSelect>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <b>
                                    Достъп на потребителя
                                </b>
                                <div className="holder">
                                    <Permissions />
                                </div>
                            </div>
                        </div>
                        {/* <Autocomplete
                            variant="outlined"
                            freeSolo={false}
                            multiple
                            inputPlaceholder="Достъп до фирма"
                            inputIdName="company_ids[]"
                            url="companies/all"
                            params={{
                                resource: 1
                            }}
                            // selectedId={params.company_id || null}
                            value={state.data?.companies || []}
                            getOptionLabel={option => option?.info?.name || option?.name || ''}
                            renderOption={option => option?.info?.name || option?.name || ''}
                            onChange={handleCompanyChange}
                            // onInputChange={handleCompanyChange}
                            error={Boolean(validations && validations.company_ids)}
                            helperText={validations && validations.company_ids && (validations.company_ids[0] || validations.company_ids)}
                        /> */}
                        {/* <div className="row">
                            <div className="col">
                                <RedditTextField
                                    size="small"
                                    variant="filled"
                                    margin="dense"
                                    label="Име"
                                    name="name"
                                    value={state.data?.name || ''}
                                    onChange={handleInputChange}
                                    error={Boolean(validations && validations.name)}
                                    helperText={validations && validations.name && (validations.name[0] || validations.name)}
                                    autoFocus
                                />

                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <RedditTextField
                                    size="small"
                                    variant="filled"
                                    margin="dense"
                                    label="Е-поща"
                                    name="email"
                                    value={state.data?.email || ''}
                                    onChange={handleInputChange}
                                    error={Boolean(validations && validations.email)}
                                    helperText={validations && validations.email && (validations.email[0] || validations.email)}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <RedditTextField
                                    size="small"
                                    variant="filled"
                                    margin="dense"
                                    label="Парола"
                                    name="password"
                                    type="password"
                                    value={state.data?.password || ''}
                                    onChange={handleInputChange}
                                    error={Boolean(validations && validations.password)}
                                    helperText={validations && validations.password && (validations.password[0] || validations.password)}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <RedditTextField
                                    size="small"
                                    variant="filled"
                                    margin="dense"
                                    label="Повторете паролата"
                                    name="password_confirmation"
                                    type="password"
                                    value={state.data?.password_confirmation || ''}
                                    onChange={handleInputChange}
                                    error={Boolean(validations && validations.password)}
                                    helperText={validations && validations.password && (validations.password[0] || validations.password)}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <RedditSelect
                                    name="role_id"
                                    value={state.data?.role_id || state?.userRoles[1]?.id || ''}
                                    onChange={handleInputChange}
                                    label="Потребителска роля"
                                >
                                    {state.userRoles.map(r =>
                                        <MenuItem
                                            key={`r_${r.id}`}
                                            value={r.id}
                                        >
                                            {r?.translation?.name || '-'}
                                        </MenuItem>
                                    )}
                                </RedditSelect>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <Autocomplete
                                    variant="outlined"
                                    freeSolo={false}
                                    multiple
                                    inputPlaceholder="Достъп до фирма"
                                    inputIdName="company_ids[]"
                                    url="companies/all"
                                    params={{
                                        resource: 1
                                    }}
                                    // selectedId={params.company_id || null}
                                    value={state.data?.companies || []}
                                    getOptionLabel={option => option?.info?.name || option?.name || ''}
                                    renderOption={option => option?.info?.name || option?.name || ''}
                                    onChange={handleCompanyChange}
                                    // onInputChange={handleCompanyChange}
                                    error={Boolean(validations && validations.company_ids)}
                                    helperText={validations && validations.company_ids && (validations.company_ids[0] || validations.company_ids)}
                                />
                            </div>
                        </div>
                        {(state?.data?.companies || []).length ?
                            <>
                                <h3 className="heading">
                                    Функционален достъп на потребителя
                                </h3>
                                {validations?.permissions ?
                                    <span
                                        style={{
                                            color: '#ff426e',
                                            fontSize: '13px',
                                            display: 'block',
                                            marginTop: '-15px',
                                            marginBottom: '10px'
                                        }}
                                    >
                                        Изберете достъп на потребителя
                                    </span>
                                    :
                                    <>
                                    </>
                                }
                            </>
                            :
                            <>
                            </>
                        }
                        {state.data && Array.isArray(state.data.companies) && state.data.companies.map(c =>
                            <div
                                key={'cp-' + c.id}
                                style={{
                                    marginBottom: '30px'
                                }}
                                className="functionality-access"
                            >
                                <h3 className="heading">
                                    {c.name}
                                </h3>
                                {permissions.map((m, i) =>
                                    <div
                                        className="col"
                                        key={'m-' + m.id}
                                    >
                                        <p>
                                            {m?.translation?.name}
                                        </p>
                                        <div className="access row">
                                            {m.permissions.map(p =>
                                                <div className="col">
                                                    <FormControlLabel
                                                        key={'p-' + p.id}
                                                        control={
                                                            <Switch
                                                                checked={hasCompanyPermission(c.id, p.id)}
                                                                onChange={() => {
                                                                    handleCompanyPermissionChange(c.id, p.id)
                                                                }}
                                                                color="primary"
                                                                margin="normal"
                                                                inputProps={{ 'aria-label': 'checkbox' }}
                                                                name={`permissions[${c.id}][]`}
                                                                value={p.id}
                                                            />
                                                        }
                                                        label={p?.translation?.name}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        {(permissions.length - 1) != i ?
                                            <hr />
                                            :
                                            <>
                                            </>
                                        }
                                    </div>
                                )}
                            </div>
                        )} */}

                        <div className="row">
                            <div className="col">
                                <b>
                                    Достъп до търговски обекти

                                </b>
                                <div className="holder">
                                    <Stores
                                        isStoreChecked={isStoreChecked}
                                        onChange={handleStoreChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="footer">
                        <Button
                            className="cancel"
                            onClick={handleHide}
                        >
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        />
                    </div>
                </form>
            </div>
        </>
    )
}

export default forwardRef(Add)