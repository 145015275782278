import { useState, useEffect } from "react"

//components
import SaveButton from "components/misc/Button"
import RedditSelect from "components/misc/RedditSelect"
import RedditTextField from "components/misc/RedditTextField"
import DatePicker from "components/misc/DatePicker"
import FindCompany from "components/pages/sandbox/findcompany/components/FindCompany"

//libraries
import { Button, InputAdornment, MenuItem } from "@material-ui/core"
import moment from "moment"
import { Skeleton } from "@mui/material"
import { useQueryClient } from "react-query"

//providers
import { useAuthDataContext } from "providers/Auth"

//hooks
import useFetchClientStore from "../hooks/useFetchClientStore"
import useSaveOnServer from "hooks/useSaveOnServer"

//stores
import { useAddToPipelinesModal } from "stores/customer/useAddToPipelinesModal"

function AddOrEditPipelinesItem({ statuses, clientId, allPipelines }, ref) {
  const queryClient = useQueryClient()

  const { getUser } = useAuthDataContext()
  const { getClient, getClientAcountsTotal, getClientCurrency, loadingClient } =
    useFetchClientStore(clientId)

  const { getForm, hideModal, getShown } = useAddToPipelinesModal()
  const [localForm, setLocalForm] = useState({})
  const [loading, setLaoding] = useState(false)

  const { saveOnServer, loadingSaveOnServer } = useSaveOnServer()

  useEffect(() => {
    setLocalForm(getForm())
    if (getForm()) simulateLoading()
  }, [getForm()])

  function simulateLoading() {
    setLaoding(true)
    setTimeout(() => {
      setLaoding(false)
    }, 300)
  }

  function handleClose(e) {
    if (e) e.preventDefault()

    setLocalForm(getForm())
    hideModal()
  }

  function handleChange(e) {
    const { name, value } = e.target

    setLocalForm((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  function getSelectedStatusColor() {
    const selectedStatus = statuses.find(
      (status) => status.id === localForm.statusId
    )

    return selectedStatus?.color || "#000"
  }

  function getCreationDate() {
    if (localForm.created_at) {
      return moment(localForm.created_at).format("dd, DD MMM")
    }

    return moment().format("dd, DD MMM")
  }

  function getAuthorName() {
    if (localForm.author) {
      return localForm.author.fullname
    }

    return getUser().data.fullname
  }

  function saveToLocalStorage() {
    const pipelines = allPipelines
    pipelines.map((pipeline) => {
      if (pipeline.id === localForm.statusId) {
        if (localForm.id) {
          pipeline.items = pipeline.items.map((item) => {
            if (item.id === localForm.id) {
              return {
                ...localForm,
                id: item.id,
              }
            }

            return item
          })

          return pipeline
        } else {
          pipeline.items = [
            ...pipeline.items,
            {
              ...localForm,
              id: moment().unix().toString(),
            },
          ]
        }
      }

      return pipeline
    })

    saveOnServer("pipelines", pipelines, () => {
      handleClose()
      queryClient.invalidateQueries("pipelines")
    })
  }

  return (
    <div className={`${getShown() ? "visible" : ""} overlay`}>
      <div
        className={`popup-primary small pipelines-item ${
          getShown() ? "show" : ""
        }`}
      >
        <div className="body">
          <h3>{localForm.id ? "Редакция" : "Добавяне"} на Сделка / Продажба</h3>
          <p>
            Добавете нова сделка или продажба и променяйте статуса според
            процеса на работа
          </p>
          {loading ? (
            <div
              style={{
                width: "100%",
              }}
            >
              <Skeleton count={1} height={35} />
              <Skeleton count={1} height={35} />
              <Skeleton count={1} height={35} />
              <Skeleton count={1} height={35} />
              <Skeleton count={1} height={35} />
            </div>
          ) : (
            <>
              <div className="row unchangable-row">
                <div className="col">
                  <span className="label">Датана създаване</span>
                  <b>{getCreationDate()}</b>
                </div>
                <div className="col">
                  <span className="label">Създадена от</span>
                  <b>{getAuthorName()}</b>
                </div>
              </div>
              <div className="row status-row">
                <div className="col">
                  <span
                    style={{
                      backgroundColor: getSelectedStatusColor(),
                    }}
                    className="color-indicator"
                  ></span>
                  <RedditSelect
                    // value={state.data?.cashdesk_id || cashdesks[0]?.id || ""}
                    // onChange={handleCashdeskChange}
                    // error={Boolean(validations?.cashdesk_id)}
                    // helperText={
                    //   validations?.cashdesk_id &&
                    //   (validations.cashdesk_id[0] || validations.cashdesk_id)
                    // }
                    label="Статус"
                    value={localForm.statusId}
                    name="statusId"
                    onChange={handleChange}
                  >
                    {statuses.map(({ title, id, color }) => (
                      <MenuItem key={id} value={id}>
                        {title}
                      </MenuItem>
                    ))}
                  </RedditSelect>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <RedditTextField
                    label="Сделка/Продажба"
                    name="deal"
                    onChange={handleChange}
                  />
                </div>
              </div>
              {getClient()?.info ? (
                <div className="row unchangable-row">
                  <div className="col">
                    <span className="label">Клиент</span>
                    <b>{getClient()?.info.name || "-"}</b>
                  </div>
                  <div className="col">
                    <span className="label">ЕИК</span>
                    <b>{getClient()?.info.eikegn || "-"}</b>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col">
                    <FindCompany
                      // centeredLabel={true}
                      label="Клиент"
                      inputIdName="clientField"
                      setSelectedCompanyCB={(value) => {
                        setLocalForm((prev) => ({
                          ...prev,
                          client: value,
                        }))
                      }}
                      initialValue={localForm?.clientField}
                      setInputValueCB={(value) => {
                        setLocalForm((prev) => ({
                          ...prev,
                          clientField: value,
                        }))
                      }}
                    />
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col">
                  <DatePicker
                    reddit
                    fullWidth
                    label="Дата на затваряне"
                    disableToolbar
                    format="dd/MM/yyyy"
                    value={localForm.closeDate}
                    name="closeDate"
                    // defaultValue={state.data?.date_ymd || today}
                    onChange={handleChange}
                  />
                </div>
                <div className="col">
                  <RedditTextField
                    fullWidth
                    type="number"
                    step="0.01"
                    label="Сума"
                    name="price"
                    onChange={handleChange}
                    value={localForm.price}
                    className="input-with-right-label"
                    // value={row.price || ""}
                    // onChange={handlePriceInputChange}
                    // error={Boolean(
                    //   validations &&
                    //     validations.rows &&
                    //     validations.rows[index] &&
                    //     validations.rows[index].price &&
                    //     (validations.rows[index].price[0] ||
                    //       validations.rows[index].price)
                    // )}
                    // helperText={
                    //   validations &&
                    //   validations.rows &&
                    //   validations.rows[index] &&
                    //   validations.rows[index].price &&
                    //   (validations.rows[index].price[0] ||
                    //     validations.rows[index].price)
                    // }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {getClientCurrency()?.name || "BGN"}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <RedditTextField
                    label="Описание"
                    multiline
                    rows={4}
                    fullWidth
                    name="description"
                    value={localForm.description}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        <div className="footer">
          <Button variant="contained" className="cancel" onClick={handleClose}>
            Отказ
          </Button>
          <SaveButton
            loading={loadingSaveOnServer}
            className="save"
            // onClick={(e) => formRef.current?.save(e)}
            onClick={saveToLocalStorage}
          />
        </div>
      </div>
    </div>
  )
}

export default AddOrEditPipelinesItem
