import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import { Modal, Button, Col, Row, FloatingLabel, Form, } from 'react-bootstrap';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useHistory } from 'react-router-dom';

//misc
import SaveButton from 'components/misc/Button'
import Info from 'components/modals/Info';
import moment from 'moment';

function NraExport(props, ref) {

    const navigate = useHistory();

    const month = moment().month() + 1;
    const year = moment().year();

    const defaultData = {
        month: month > 1 ? month - 1 : 12,
        year: year,
    }

    const [state, setState] = useNestedState({
        show: false,
        refresh: false,
        data: defaultData,
        loading: false,
        loadingBase: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
        onClose: null,
    });

    const [companies, setCompanies] = useState([]);

    const months = [
        {
            id: 1,
            name: 'Януари'
        },
        {
            id: 2,
            name: 'Февруари'
        },
        {
            id: 3,
            name: 'Март'
        },
        {
            id: 4,
            name: 'Април'
        },
        {
            id: 5,
            name: 'Май'
        },
        {
            id: 6,
            name: 'Юни'
        },
        {
            id: 7,
            name: 'Юли'
        },
        {
            id: 8,
            name: 'Август'
        },
        {
            id: 9,
            name: 'Септември'
        },
        {
            id: 10,
            name: 'Октомври'
        },
        {
            id: 11,
            name: 'Ноември'
        },
        {
            id: 12,
            name: 'Декември'
        },
    ];

    const [validations, setValidations] = useValidation();

    const formRef = useRef(null);

    useImperativeHandle(ref, () => ({
        open: () => {
            open();
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    useEffect(() => {
        Api.get('store/accounts/companies')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setCompanies(res.data);
                }
            });
    }, []);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const open = () => {
        setState(prev => ({
            ...prev,
            data: defaultData,
        }));

        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
        }));

        setValidations(null);

        if (typeof state.onClose === 'function') {
            hide(state.onClose);
        } else {
            hide();
        }
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            refresh: moment().unix(),
        }));
    }

    const handleSave = e => {
        e.preventDefault();

        if (state.loading) {
            return;
        }

        setState(prev => ({
            ...prev,
            loading: true,
            loadingBase: true,
        }));

        setValidations(null);

        let url = 'store/accounts/export-nra-xml';

        let data = new FormData(e.target);

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            },
        }).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/xml' }))

            download(url);
        }).catch(error => {
            const _err = error.response;

            if (_err && _err.status && _err.status === 422) {
                setValidations(_err.data.errors);
            }
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
                loadingBase: false,
            }));
        });
    }

    const download = url => {
        const link = document.createElement("a");
        link.download = 'Справка.xml';
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // delete link;
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleDateChange = e => {
        const name = e.target.name + '_ymd'
        const value = e.target.value

        setState(name, value, 'data')
    }

    const getYears = () => {
        let data = [];

        for (let i = moment().year() - 5; i <= moment().year(); i++) {
            data.push(i);
        }

        return data;
    }

    return (
        <>

            <Modal size="md" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form ref={formRef} onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Експорт на одиторски файл
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Row className="mb-3">
                            <Col>
                                <FloatingLabel label="Фирма">
                                    <Form.Select
                                        name={`company_id`}
                                        autoComplete="new-password"
                                        value={state.data.company_id || ''}
                                        onChange={(e) => handleInputChange(e)}
                                        isInvalid={Boolean(validations?.company_id)}
                                    >
                                        <option value=""></option>
                                        {companies.map(c =>
                                            <option key={c.id} value={c.id}>{c.pop_name}</option>
                                        )}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {validations?.company_id && (validations?.company_id[0] || validations?.company_id)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FloatingLabel label="Месец">
                                    <Form.Select
                                        name={`month`}
                                        autoComplete="new-password"
                                        value={state.data.month || ''}
                                        onChange={(e) => handleInputChange(e)}
                                        isInvalid={Boolean(validations?.month)}
                                    >
                                        {months.map(m =>
                                            <option key={m.id} value={m.id}>{m.name}</option>
                                        )}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {validations?.month && (validations?.month[0] || validations?.month)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel label="Година">
                                    <Form.Select
                                        name={`year`}
                                        autoComplete="new-password"
                                        value={state.data.year || ''}
                                        onChange={(e) => handleInputChange(e)}
                                        isInvalid={Boolean(validations?.year)}
                                    >
                                        {getYears().map(m =>
                                            <option key={m} value={m}>{m}</option>
                                        )}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {validations?.year && (validations?.year[0] || validations?.year)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loadingBase}
                            disabled={state.loading}
                            className="save"
                            text="Експорт"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(NraExport);
