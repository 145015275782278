import React, { useEffect, useImperativeHandle, useMemo, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Popper from '@material-ui/core/Popper'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import Api from '../../helpers/Api'
import axios from 'axios'
import RedditTextField, { useStylesReddit } from './RedditTextField'
import { Tooltip } from '@material-ui/core'

let active

const defaultOptionLabel = option => {
    return option?.name || ''
}

const defaultRenderOption = option => {
    return option?.name || ''
}

const defaultOptionDisabled = option => {
    return false
}

const defaultResultData = data => {
    return data?.items || []
}

const defaultResultPages = data => {
    return data?.pages || 0
}

const defaultResultTotal = data => {
    return data?.total || 0
}

function Index(props, ref) {

    const size = props.size || 'medium'
    const variant = props.variant && ['outlined', 'filled', 'standard'].indexOf(props.variant) > -1 ? props.variant : 'outlined'
    const margin = props.margin || 'dense'
    const multiple = Boolean(props.multiple) || false
    const disableSearch = typeof props.disableSearch !== 'undefined' ? Boolean(props.disableSearch) : false
    const permanentlyClosedDropdown = props.permanentlyClosedDropdown || false

    // дали свободно може да се пише в текстовото поле или трябва да се избира само от списъка с опции
    const freeSolo = typeof props.freeSolo !== 'undefined' ? props.freeSolo : true

    // дали може да се изтрива текста
    const disableClearable = typeof props.disableClearable !== 'undefined' ? props.disableClearable : false

    // какво да бъде името на текстовото поле
    const inputName = props.inputName || 'inputName'

    // какво да бъде името на скритото поле с id
    const inputIdName = props.inputIdName || null

    const required = props.required || false

    // какъв да бъде placeholder-а
    const inputPlaceholder = props.inputPlaceholder || 'inputPlaceholder'

    // да бъде ли забранено полето
    const inputDisabled = Boolean(props.inputDisabled) || false

    // какъв тип поле да се показва
    const renderInputType = props.inputPlaceholder ? 'RedditTextField' : 'TextField'

    // как да бъдат показани опциите в списъка
    const getOptionLabel = typeof props.getOptionLabel === 'function' ? props.getOptionLabel : defaultOptionLabel

    // как да бъде показана избраната опция
    const renderOption = typeof props.renderOption === 'function' ? props.renderOption : defaultRenderOption

    // дали е опцията да е disabled
    const getOptionDisabled = typeof props.getOptionDisabled === 'function' ? props.getOptionDisabled : defaultOptionDisabled

    const onChange = typeof props.onChange === 'function' ? props.onChange : null
    const onInputChange = typeof props.onInputChange === 'function' ? props.onInputChange : null
    const onFocus = typeof props.onFocus === 'function' ? props.onFocus : null
    const onInputFocus = props.onInputFocus || null
    const onInputBlur = props.onInputBlur || null

    // избраната опция
    const selected = typeof props.selected === 'object' ? props.selected : null
    const selectedId = props.selectedId || null
    const selectedValue = props.selectedValue || ''

    // url-а за заявката за търсене
    const url = props.url

    // допълнители параметри, които да бъдат включени в заявката за търсене
    const params = typeof props.params === 'object' ? props.params : {}

    // задължителни полета, които трябва да са включени в params за да се изпълни заявка за търсене
    const requiredParams = props.requiredParams instanceof Array ? props.requiredParams : []

    // името на параметъра за търсене към заявката
    const searchParamName = props.searchParamName || 'name'
    const pageParamName = props.pageParamName || 'page'

    // дали компонента да работи с фиксирани опции или чрез опции заредени от адрес
    const fixedOptions = typeof props.fixedOptions !== 'undefined' ? props.fixedOptions : false

    // дефинирани опции - работи с fixedOptions = true
    const options = props.options instanceof Array ? props.options : []

    const error = props.error || false
    const helperText = props.helperText || ''

    // как да се използват данните от резултата на заявката
    const getResultData = typeof props.getResultData === 'function' ? props.getResultData : defaultResultData

    // как да се вземе общия брой страници от резултата на заявката
    const getResultPages = typeof props.getResultPages === 'function' ? props.getResultPages : defaultResultPages

    // как да се вземе общия брой резултати от заявката
    const getResultTotal = typeof props.getResultTotal === 'function' ? props.getResultTotal : defaultResultTotal

    // как да са групирани
    const groupBy = typeof props.groupBy === 'function' ? props.groupBy : null

    const [state, setState] = useState({
        data: [],
        page: 1,
        pages: 0,
        total: 0,
        hasMore: false,
        firstRender: true,
        allowAutoSelect: true,
        timeout: null,
        ajaxRequest: null,
    })

    const [open, setOpen] = useState(false)
    const [inputValue, setInputValue] = useState(selectedValue || '')
    const [value, setValue] = useState(multiple ? [] : null)
    const [loading, setLoading] = useState(false)

    useImperativeHandle(ref, () => ({
        resetData: () => resetData(),
        open: open => setOpen(open),
        focus: parentClass => focusInputField(parentClass)
    }))

    useEffect(() => {
        setState(prev => ({
            ...prev,
            data: options
        }))
    }, [props.options])

    // todo
    useEffect(() => {
        if (typeof selected !== 'undefined') {
            setValue(selected)
        }

        if (selected && typeof selected === 'object') {
            setInputValue(getOptionLabel(selected))
        } else if (typeof selected === 'string') {
            setInputValue(selected)
        } else {
            // setInputValue('');
            setInputValue(getOptionLabel(selected))
        }

    }, [
        props.selected
    ])

    useEffect(() => {
        if (typeof props.selectedId !== 'undefined') {
            if (props.selectedId) {
                setValue({
                    id: props.selectedId
                })
            } else {
                setValue(null)
            }
        }
    }, [props.selectedId])

    useEffect(() => {
        if (typeof props.selectedValue !== 'undefined') {
            setInputValue(props.selectedValue || '')
        }
    }, [props.selectedValue])

    useEffect(() => {
        if (typeof props.value !== 'undefined') {
            setValue(props.value)
        }
    }, [props.value])

    // автоматично попълване на името, ако няма такова и има избрано value.id
    useEffect(() => {
        if (value && value?.id && String(inputValue) === '') {
            let selectedOption = state.data.find(o => Number(o.id) === Number(value.id))

            if (selectedOption) {
                setInputValue(getOptionLabel(selectedOption))
            }
        }
    }, [value, inputValue, state.data])

    // автоматично избиране на първата опция, ако няма избрана
    useEffect(() => {
        if (props.autoSelect && state.allowAutoSelect && state.data.length && !value && !inputValue) {
            handleChange(state.data[0])

            setState(prev => ({
                ...prev,
                allowAutoSelect: false
            }))
        }
    }, [props.autoSelect, value, inputValue, state.data])

    useEffect(() => {
        active = true

        if ((open || props.autoSelect) && state.data.length === 0) {
            loadData()
        }

        return () => {
            active = false
        }
    }, [open, props.autoSelect, props.params])

    useEffect(() => {
        if (props.selectedId) {
            loadData(1, true)
        }
    }, [props.selectedId])

    useEffect(() => {
        // console.log('input ' + inputName);
        // console.log(inputValue);

        if (open) {
            clearTimeout(_getTimeout())

            _setTimeout(() => {
                loadData(1, true)
            }, 300)
        }

    }, [inputValue, props.params])

    function focusInputField(parentClass = '') {
        document.querySelector(`${parentClass} input[name="${inputName}"]`).focus()
    }

    const hasMissingParams = () => {
        if (requiredParams.length) {
            let inputParams = Object.keys(params)

            if (requiredParams.indexOf(searchParamName) > -1) {
                if (inputParams.indexOf(searchParamName) === -1) {
                    inputParams.push(searchParamName)
                }
                params[searchParamName] = inputValue
            }

            let missingParams = []

            // console.log(params)
            // console.log(inputParams)

            let hasMissingParams = requiredParams.filter(p => {

                if (!inputParams.includes(p) || (inputParams.includes(p) && !params[p])) {
                    missingParams.push(p)

                    return true
                }

                return false
            }).length

            // if (inputName == 'customerinfo[name]') {
            //     console.log(hasMissingParams);
            //     console.log(requiredParams);
            //     console.log(inputParams);
            // }

            if (hasMissingParams) {
                console.warn(`[Autocomplete]: Missing params: ${missingParams.join(', ')}`)
                return true
            }
        }

        return false
    }

    const loadData = (page = 1, reset = false) => {

        if (disableSearch) {
            return
        }

        if (fixedOptions) {
            return
        }

        if (!url) {
            return
        }

        if (hasMissingParams()) {
            return
        }

        if (active) {
            setLoading(true)
            setPage(page)
        }

        let fullParams = {
            [searchParamName]: inputValue,
            [pageParamName]: page,
            ...params
        }

        if (state.firstRender) {
            if (selectedId) {
                fullParams.id = selectedId
            }
        }

        if (state.ajaxRequest) {
            state.ajaxRequest.cancel()
        }

        let ajaxRequest = axios.CancelToken.source()

        if (active) {
            setState(prev => ({
                ...prev,
                ajaxRequest: ajaxRequest
            }))
        }

        Api.get(url, {
            params: fullParams,
            cancelToken: ajaxRequest.token
        }).then(res => {
            if (active) {
                setState(prev => ({
                    ...prev,
                    // data: reset ? res.data.items : [...prev.data, ...res.data.items],
                    data: reset ? getResultData(res.data) : [...prev.data, ...getResultData(res.data)],
                    pages: getResultPages(res.data),
                    total: getResultTotal(res.data),
                    hasMore: getResultPages(res.data) > page,
                    firstRender: false,
                }))

                if (getResultTotal(res.data) === 0) setOpen(false)
            }
        }).finally(() => {
            if (active) {
                setLoading(false)
            }
        })
    }

    const setPage = page => {
        setState(prev => ({
            ...prev,
            page: page
        }))
    }

    const resetData = () => {
        setState(prev => ({
            ...prev,
            data: []
        }))
    }

    const handleChange = data => {
        setValue(data)
        setInputValue(getOptionLabel(data))

        if (onChange) {
            onChange(data)
        }
    }

    const handleInputChange = (data, reason) => {
        if (reason === 'reset') {
            // handleChange(null);
            return
        }

        if (data !== inputValue) {
            setInputValue(data)
        }

        // if (reason === 'reset') {
        //     // handleChange(null);
        //     return;
        // }

        if (onInputChange) {
            onInputChange(data)
        }
    }

    const _setTimeout = (fn, delay) => {
        setState(prev => ({
            ...prev,
            timeout: setTimeout(fn, delay)
        }))
    }

    const _getTimeout = () => {
        return state.timeout
    }

    // useEffect(() => {
    //     console.log('value changed');
    //     console.log(value);
    // }, [value])

    // useEffect(() => {
    //     console.log('data ' + inputName);
    //     console.log(state.data);
    // }, [state.data]);

    const classes = useStylesReddit()
    return (
        <>
            <Autocomplete
                fullWidth
                freeSolo={freeSolo}
                disableClearable={disableClearable}
                forcePopupIcon={false}
                multiple={multiple}
                open={permanentlyClosedDropdown ? false : open}
                onOpen={() => {
                    setOpen(true)
                }}
                onClose={() => {
                    setOpen(false)
                }}
                onFocus={e => {
                    setOpen(true)
                    if (onInputFocus) onInputFocus(e)
                }}
                onBlur={e => {
                    if (onInputBlur) onInputBlur(e)
                }}
                inputValue={inputValue || ''}
                onInputChange={(e, newInputValue, reason) => {
                    // if (reason === 'input') {
                    handleInputChange(newInputValue, reason)
                    // }
                }}
                value={Array.isArray(value) ? value : (value || (multiple ? [] : null))}
                onChange={(e, newValue) => {
                    handleChange(newValue)
                }}
                getOptionSelected={(option, value) => Boolean(option?.id === value?.id)}
                getOptionLabel={(option) => getOptionLabel(option)}
                options={state.data}
                renderOption={(option) => renderOption(option)}
                filterOptions={x => x}
                getOptionDisabled={(option) => getOptionDisabled(option)}
                groupBy={groupBy}
                loading={loading}
                loadingText={
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress color="inherit" size={20} style={{ margin: 'auto' }} />
                    </div>
                }
                ListboxProps={{
                    onScroll: (event) => {
                        const listboxNode = event.currentTarget
                        if (listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight - 30) {
                            if (state.hasMore && !loading) {
                                loadData(state.page + 1)
                            }
                        }
                    },
                    style: {
                        maxHeight: '200px',
                    }
                }}
                PopperComponent={props => <Popper {...props} style={{ width: 'fit-content' }} placement='bottom-start' />}
                renderInput={(params) => (

                    renderInputType === 'RedditTextField'
                        ?
                        <RedditTextField
                            {...params}
                            label={inputPlaceholder}
                            variant={variant}
                            margin={margin}
                            size={size}
                            name={inputName}
                            onFocus={onFocus}
                            required={required}
                            InputProps={{
                                ...params.InputProps,
                                classes,
                                disableUnderline: true,
                                endAdornment: (
                                    // <React.Fragment>
                                    //     {params.InputProps.endAdornment}
                                    // </React.Fragment>
                                    helperText &&
                                    <Tooltip title={helperText} placement="top" arrow>
                                        <span className="error-info">

                                        </span>
                                    </Tooltip>
                                ),
                            }}
                            error={error}
                            helperText={helperText}
                        />
                        :
                        <TextField
                            {...params}
                            // label={inputPlaceholder}
                            variant={variant}
                            margin={margin}
                            size={size}
                            name={inputName}
                            onFocus={onFocus}
                            required={required}
                            InputProps={{
                                ...params.InputProps,
                                // classes,
                                // disableUnderline: true,
                                endAdornment: (
                                    // <React.Fragment>
                                    //     {params.InputProps.endAdornment}
                                    // </React.Fragment>
                                    helperText &&
                                    <Tooltip title={helperText} placement="top" arrow>
                                        <span className="error-info">

                                        </span>
                                    </Tooltip>
                                ),
                            }}
                            error={error}
                            helperText={null}
                        />
                )}
                disabled={inputDisabled}
            />

            {inputIdName &&
                Array.isArray(value)
                ?
                value.map((v, i) =>
                    <input key={'iv-' + i} type="hidden" name={inputIdName} value={v?.id || ''} />
                )
                :
                <input type="hidden" name={inputIdName} value={value?.id || ''} />
            }
        </>
    )
}

export default React.memo(React.forwardRef(Index), (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next)
})