import { useRef, useState } from "react"

//providers
import { useAuthDataContext } from "providers/Auth"

//helpers
import Api from "helpers/Api"

//libraries
import axios from "axios"

function useFetchDocumentNextNumber() {
    // const [loadingNextNumber, setLoadingNextNumber] = useState(false)
    const [nextNumber, setNextNumber] = useState(null)

    const auth = useAuthDataContext()

    const nextNumberCancelToken = useRef(null)

    async function fetchNextNumber(docTypeId) {
        // setLoadingNextNumber(true)

        const companyId = auth?.getUser()?.getCompany()?.id
        const typeId = docTypeId
        const companyAddressId = auth?.getUser()?.getCompany()?.address?.id


        const params = {
            company_id: companyId,
            type_id: typeId,
            company_address_id: companyAddressId,
        }
        if (nextNumberCancelToken.current) nextNumberCancelToken.current.cancel()
        nextNumberCancelToken.current = axios.CancelToken.source()

        let nextNumber = 1

        if (companyId && typeId && companyAddressId) {
            await Api.get('documents/next-number',
                {
                    params,
                    cancelToken: nextNumberCancelToken.current.token
                }
            )
                .then(res => {
                    nextNumber = res.data
                    setNextNumber(nextNumber)
                })
            // .finally(() => {
            //     setLoadingNextNumber(false)
            // })
        }

        return nextNumber
    }

    function getNextNumber(docTypeId = null) {
        if (nextNumber) {
            return nextNumber
        }

        if (!nextNumberCancelToken.current && docTypeId) fetchNextNumber(docTypeId)

        return '...'
    }

    return {
        fetchNextNumber,
        // loadingNextNumber,
        getNextNumber
    }
}

export default useFetchDocumentNextNumber