import { Box } from '@material-ui/core';
import React from 'react';
import failIcon from 'assets/img/fail.png';
import { Link } from 'react-router-dom';

function Index() {
    return (
        <section>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                m="auto"
                height="75vh"
            >
                <div style={{ textAlign: 'center' }}>
                    <img src={failIcon} height={200} />
                    <h3 style={{ marginTop: '40px' }}>
                        Нещо се обърка!
                    </h3>
                    <p style={{ marginTop: '20px', fontSize: '13px' }}>
                        Възникна грешка при опита за свързване на вашите акаунти.
                    </p>
                    <Link to="/settings/integrations" style={{ display: 'block', marginTop: '20px' }}>
                        Към интеграции
                    </Link>
                </div>
            </Box>
        </section>
    )
}

export default Index;