import { useState, useRef } from 'react'
import { useLocation } from 'react-router-dom'

//providers
import { useAuthDataContext } from 'providers/Auth'

//helpers
import { isProd } from 'helpers/mode'
import { useQuery } from 'helpers/Url'

//MUI components
import { Button, MenuItem, Tooltip, Menu } from '@material-ui/core'

//images
import dropArrowIcon from 'assets/img/app/icons/drop-arrow-white.png'
import downoadIcon from 'assets/img/app/icons/download-colored.png'
import printIcon from 'assets/img/app/icons/print-colored.png'
import mailIcon from "assets/img/app/icons/send-colored.png"

//hooks
import useFindFilters from '../../hooks/useFindFilters'
import PrintButtonLayout from '../PrintButtonLayout'
import DownloadButtonLayout from '../DownloadButtonLayout'

//providers
import { useDocumentContext } from '../../Provider'

function AddButton(props) {
    const query = useQuery()

    const context = useDocumentContext()
    const { getSearchParams } = context

    const location = useLocation()
    const auth = useAuthDataContext()
    const APP_URL = process.env[isProd ? 'REACT_APP_URL' : 'REACT_APP_URL_DEV']
    const {
        allPagesTotal,
        handleExport,
        handleSend,
        typeId,
        isCurrentPageDataVisible
    } = props

    const {
        getSearch,
        getDocTypes,
        getPeriod,
        getSent,
        getPaymentStatus,
        getPaymentMethod
    } = useFindFilters()

    const [anchorEl, setAnchorEl] = useState(null)

    const addBtnRef = useRef(null)

    const openMenu = () => {
        setAnchorEl(addBtnRef.current)
    }

    const closeMenu = () => {
        setAnchorEl(null)
    }

    const getDownloadUrl = () => {
        const url = `${APP_URL}documents/download${getFilters()}`

        return url
    }
    const getDocumentsFilters = () => Object.keys(getSearchParams()).map((item, i) => `${item}=${getSearchParams()[item]}`).join('&')
    const getFilters = () => `?token=${auth.getToken()}&${getDocumentsFilters()}`

    return (
        <>
            <Tooltip
                title={
                    <>
                        <b className="head">
                            Експортирай {allPagesTotal} документа с филтъра:
                        </b>
                        <ul className="body">
                            {(query.get('search') || '').length ?
                                <li>
                                    Търсене:
                                    <span>
                                        {getSearch()}
                                    </span>
                                </li>
                                :
                                <>
                                </>
                            }

                            {(query.get('type_id') || '').length || typeId ?
                                <li>
                                    Тип документи:
                                    <span>
                                        {getDocTypes(typeId)}
                                    </span>
                                </li>
                                :
                                <>
                                </>
                            }
                            {(query.get('period') || query.get('start_date') || query.get('end_date') || '').length ?
                                <li>
                                    За период:
                                    <span>
                                        {getPeriod()}
                                    </span>
                                </li>
                                :
                                <>
                                </>
                            }
                            {(query.get('sent') || '').length ?
                                <li>
                                    Изпратени:
                                    <span>
                                        {getSent()}
                                    </span>
                                </li>
                                :
                                <>
                                </>
                            }
                            {(query.get('status') || '').length ?
                                <li>
                                    Статус:
                                    <span>
                                        {getPaymentStatus()}
                                    </span>
                                </li>
                                :
                                <>
                                </>
                            }
                            {(query.get('payment_method_id') || '').length ?
                                <li>
                                    Метод на плащане:
                                    <span>
                                        {getPaymentMethod()}
                                    </span>
                                </li>
                                :
                                <>
                                </>
                            }
                        </ul>
                    </>
                }
                classes={{
                    popper: "additional-info-tooltip"
                }}
            >
                <Button
                    onClick={e => {
                        if (e.target.classList.contains('menu')) {
                            openMenu()

                            return
                        }
                        handleExport()
                    }}
                    className={`export with-dropdown ${anchorEl ? 'active' : ''} ${!isCurrentPageDataVisible ? 'export-small' : ''}`}
                    ref={addBtnRef}
                >
                    <div className="content">
                        <b>
                            Експорт
                        </b>
                        {isCurrentPageDataVisible ?
                            <span>
                                {`(${allPagesTotal} броя)`}
                            </span>
                            :
                            <>
                            </>
                        }
                    </div>
                    <i
                        className="menu"
                        onClick={openMenu}
                    >
                        <img src={dropArrowIcon} />
                    </i>
                </Button>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={closeMenu}
                onClick={e => {
                    if (e.target.classList.contains('print-button-layout')) return

                    closeMenu()
                }}
                className="additinal-options-menu"
            >
                {/* DS */}
                {/* <MenuItem
                    className='send'
                    onClick={handleSend}
                >
                    <img src={mailIcon} />
                    Изпращане по Е-поща {`(${allPagesTotal} броя)`}
                </MenuItem> */}
                <MenuItem
                    className='print'
                >
                    <DownloadButtonLayout downloadUrl={getDownloadUrl()}>
                        <img src={downoadIcon} />
                        Изтегляне {`(${allPagesTotal} броя)`}
                    </DownloadButtonLayout>
                </MenuItem>
                <MenuItem className='print'>
                    <PrintButtonLayout printUrl={`${APP_URL}documents/bulk-print${getFilters()}`}>
                        <img src={printIcon} />
                        Принтиране  {`(${allPagesTotal} броя)`}
                    </PrintButtonLayout>
                </MenuItem>
            </Menu>
        </>
    )
}

export default AddButton