import { buildUrl } from "helpers/Url"
import moment from "moment"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import Refs from "Refs"
import FilePreview from "../FilePreview"
import PriceValue from "../PriceValue"

function DynamicTableBodyCol(props) {
    const name = props.name
    const type = props.type || ''
    const colspan = props.colspan || 1
    const boldText = props.boldText || false

    // цялата информация за реда
    const data = props.data || null

    const currency = data?.currency || props.currency || null

    // дали е ред Общо
    const isTotalRow = props.total ? true : false

    const classes = {
        price: 'text-right',
        number: 'text-right',
        percent: 'text-right',
        preview: 'picture',
        left: 'text-left',
        center: 'text-center',
        right: 'text-right',
        article_quantity: 'text-right',
    }

    const getClassName = () => {
        let className = [classes[type] || '']

        if (type === 'price' || type === 'number' || type === 'percent' || type === 'article_quantity') {
            if (parseFloat(name) < 0) {
                className.push('negative-value')
            }
        }

        return className.join(' ')
    }

    const getValue = () => {
        if (type === 'date') {
            return getDateValue()
        }

        if (type === 'datetime') {
            return getDateTimeValue()
        }

        if (type === 'price') {
            return getPriceValue()
        }

        if (type === 'article') {
            if (data.article_id || data.id) {
                return getArticleValue()
            }
        }

        if (type === 'client') {
            if (data.client_id || data.id) {
                return getClientValue()
            }
        }

        if (type === 'number') {
            return getNumberValue()
        }

        if (type === 'percent') {
            return getPercentValue()
        }

        if (type === 'preview') {
            if (isTotalRow === false) {
                return getPreviewValue()
            }
        }

        if (type === 'storage_operation') {
            return getStorageOperationValue()
        }

        if (type === 'article_quantity') {
            return getArticleQuantityValue()
        }

        if (type === 'account') {
            return getAccountValue()
        }

        return name
    }

    const getDateValue = () => {
        return name ? moment(name).format('DD.MM.YYYY') : ''
    }

    const getDateTimeValue = () => {
        return name ? moment(name).format('DD.MM.YYYY HH:mm') : ''
    }

    const getPriceValue = () => {
        if (name !== null) {
            return <PriceValue prefix={currency?.prefix} sufix={currency?.sufix}>{name}</PriceValue>
        }

        return null
    }

    const getArticleValue = () => {
        return (
            <span className="link" onClick={e => handleShowArticle(data.article_id || data.id)}>
                {name}
            </span>
        )
    }

    const getClientValue = () => {
        let id = data.client_id || data.id

        if (id === -1) {
            return name
        }

        return (
            <span className="link" onClick={e => handleShowClient(id)}>
                {name}
            </span>
        )
    }

    const getNumberValue = () => {
        return name
    }

    const getPercentValue = () => {
        if (name !== null) {
            return <PriceValue pad={0} sufix="%">{name}</PriceValue>
        }

        return null
    }

    const getPreviewValue = () => {
        return (
            <OverlayTrigger
                placement="right-start"
                overlay={
                    <Tooltip className="file-preview" id={'tooltip-img-' + data.id}>
                        {/* <img src={buildUrl(c.thumbnail, { width: 200 })} loading="lazy" alt="" /> */}
                        <FilePreview
                            file={{
                                url: buildUrl(name?.thumbnail_url, { width: 200 }),
                                // type: c?.preview?.mime_type
                            }}
                        />
                    </Tooltip>
                }
            >
                {/* <img src={buildUrl(c.thumbnail, { width: 40 })} loading="lazy" alt="" /> */}
                <div>
                    <FilePreview
                        file={{
                            url: buildUrl(name?.thumbnail_url, { width: 40 }),
                            // type: c?.preview?.mime_type
                        }}
                        width={40}
                    />
                </div>
            </OverlayTrigger>
        )
    }

    const getStorageOperationValue = () => {
        return (
            <span className="link" onClick={e => handleShowOperation(data.operation_id, data.operation_type)}>
                {name}
            </span>
        )
    }

    const getAccountValue = () => {
        return (
            <span className="link" onClick={e => handleShowAccount(data.account_id || data.id)}>
                {name}
            </span>
        )
    }

    const getArticleQuantityValue = () => {
        return (
            <span className="link" onClick={e => handleShowArticleAvailability(data.article_id)}>
                {name}
            </span>
        )
    }

    const handleShowArticle = id => {
        if (typeof props.handleShowArticle === 'function') {
            return props.handleShowArticle(id)
        }

        let modal = Refs.getInstance().getRef('article')

        if (modal) {
            modal.open(id)
        }

        if (typeof props.onShowArticle === 'function') {
            props.onShowArticle()
        }

        if (typeof props.onHideArticle === 'function') {
            modal.onClose(() => {
                props.onHideArticle()
            })
        }

        if (typeof props.onArticleUpdate === 'function') {
            modal.onSuccess(props.onArticleUpdate)
        }
    }

    const handleShowClient = id => {
        let modal = Refs.getInstance().getRef('client')

        if (modal) {
            modal.open(id)
        }
    }

    const handleShowOperation = (id, type) => {
        let modal = Refs.getInstance().getRef('storageOperation')

        if (modal) {
            modal.open(id, type)
        }
    }

    const handleShowArticleAvailability = id => {
        let modal = Refs.getInstance().getRef('articleAvailability')

        if (modal) {
            modal.open(id)
        }
    }

    const handleShowAccount = (id, type) => {
        let modal = Refs.getInstance().getRef('account')

        if (modal) {
            modal.open(id, type)
        }
    }

    const createMarkup = html => {
        return {
            __html: html
        }
    }

    const style = props.style && Object.entries(props.style).length
        ?
        props.style
        :
        {
            whiteSpace: 'nowrap'
        }

    if (typeof getValue() === 'object') {
        return <td colSpan={colspan} style={style} onClick={(props.type !== 'client' && props.type !== 'article_quantity') ? props.onClick : ''} className={getClassName()}>{getValue() || '-'}</td>
    }

    return (
        <td colSpan={colspan} style={style} onClick={(props.type !== 'client' && props.type !== 'article_quantity') ? props.onClick : ''} className={getClassName()} dangerouslySetInnerHTML={createMarkup(getValue())} />
    )
}

export default DynamicTableBodyCol