import { useEffect, useRef, useState } from 'react';
import Api from 'helpers/Api';
import { buildSearchParams, buildUrl, useQuery } from 'helpers/Url';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { Alert, Button, Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useAuthDataContext } from 'providers/Auth';

import Options from './partials/Options';

import Question from 'components/modals/Question';
import Filter from './partials/Filter';
import Layout from 'components/Layout';

//misc
import MenuOptions from 'components/misc/MenuOptions'
import Pagination from 'components/misc/Pagination'
import Loader from 'components/misc/Loader';

import NoDataFound from 'components/misc/NoDataFound';
// import ArticleReportsOptions from 'components/partials/ArticleReportsOptions';
import PaginationMini from 'components/misc/PaginationMini'
import TableHeader from 'components/misc/TableHeader';
import TableCol from 'components/misc/TableCol';
import TableOptions from 'components/misc/TableOptions';
import DynamicTableCol from 'components/partials/table/DynamicTableCol';
import DynamicTableBodyCol from 'components/partials/table/DynamicTableBodyCol';
import Refs from 'Refs';
import axios from 'axios';
import moment from 'moment'

let timeout;

function Index() {

    const location = useLocation();
    const navigate = useHistory();
    const query = useQuery();
    const auth = useAuthDataContext();

    const [state, setState] = useState({
        loading: true,
        refresh: false,
        setFilter: false,
        data: [],
        headings: {},
        types: {},
        pages: 0,
        total: 0,
        totals: {

        },
        totalsColspan: {},
        filter: {
            page: 1,
        },
        columns: {
            all: {},
            details: {},
            selected: [],
            sortable: [],
            sort: null,
            order: null,
        },
        tableKey: '',
        request: null,
    });

    const articleModalRef = useRef(null);
    const tableRef = useRef(null);

    useEffect(() => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: query.get('page') || 1,
                limit: query.get('limit') || '',
                search: query.get('search') || '',
                // store_id: query.get('store_id') || '',
                depot_id: query.getAll('depot_id[]') || [],
                article_id: query.get('article_id') || '',
                category_id: query.getAll('category_id[]') || [],
                status: query.getAll('status[]') || [],
                created_from_date: query.get('created_from_date') || '',
                created_to_date: query.get('created_to_date') || '',
                amount_last_update: query.get('amount_last_update') || '',
                // amount: query.getAll('amount[]') || [],
                amount: ['below_zero', 'above_zero'],
                amount_from: query.get('amount_from') || '',
                amount_to: query.get('amount_to') || '',
                detail_value_id: JSON.parse(query.get('detail_value_id')) || {},
                supplier_id: query.get('supplier_id') || '',
                with_deleted: query.get('with_deleted') || '',
                with_service: query.get('with_service') || '',
                sort: query.get('sort') || '',
                order: query.get('order') || ''
            },
            refresh: moment().unix()
        }))
    }, [location.search]);

    useEffect(() => {
        if (state.setFilter) {
            navigate.push('?' + buildSearchParams(query, state.filter));
        }

    }, [state.setFilter]);

    useEffect(() => {
        if (state.refresh) {
            loadData();
        }
    }, [state.refresh, auth.getUser().getStoreId()]);

    const loadData = () => {

        if (state.request) {
            state.request.cancel();
        }

        let request = axios.CancelToken.source();

        setState(prev => ({
            ...prev,
            loading: true,
            request: request,
        }));

        let url = 'store/reports/storage/amounts-by-depots';

        Api.get(url, {
            params: state.filter,
            cancelToken: request.token
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data.items,
                currency: res.data.currency,
                total: res.data.total,
                pages: res.data.pages,
                depots: res.data.depots,
                totals: res.data.totals,
                totalsColspan: res.data.totalsColspan,
                headings: res.data.headings,
                types: res.data.types,
                columns: res.data.columns,
                tableKey: res.data.tableKey,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                }
            }));

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 });
            }

            loading(false);
            sorting(false);
        });
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }));
    }

    const sorting = (sorting) => {
        setState(prev => ({
            ...prev,
            sorting: Boolean(sorting)
        }));
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: reset ? 1 : prev.filter.page
            },
            setFilter: moment().unix(),
            refresh: moment().unix(),
        }));
    }

    const refreshTable = () => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                sort: '',
                order: '',
                page: 1,
                limit: '',
            },
            setFilter: moment().unix(),
            refresh: moment().unix(),
        }));
    }

    // Search
    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout);

        if (typeof key === 'object') {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    ...key
                },
            }));
        } else {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    [key]: val,
                },
            }));
        }

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                setFilter: moment().unix()
            }));
        }, delay);
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            setFilter: moment().unix()
        }));
    }

    const handleSort = (sort, order) => {
        setState(prev => ({
            ...prev,
            sorting: true,
            filter: {
                ...prev.filter,
                sort: sort,
                order: order,
                page: 1,
            },
            setFilter: moment().unix()
        }));
    }

    const handleShowArticle = id => {
        let modal = Refs.getInstance().getRef('article');

        if (modal) {
            modal.open(id);
        }
    }

    const getAmountText = article => {
        let text = {
            below_zero: 'Липса (<0)',
            above_max: 'Над максимума',
            below_min: 'Под минимума',
            enough: 'Достатъчно'
        };

        return text[article.amount_status] || '';
    }

    const getAmountBg = article => {
        let text = {
            below_zero: 'negative-value',
            above_max: 'blue-value',
            below_min: 'brown-value',
            enough: 'green-value'
        };

        return text[article.amount_status] || '';
    }

    const handleLimitChange = limit => {
        handleSearch('limit', limit, 0);
    }

    return (
        <>
            <Layout>
                <div className="container-fluid">

                    <div className="head-with-heading">
                        <h3>Текущи складови наличности по складове</h3>

                        <div className="buttons">

                        </div>
                    </div>

                    <div className="panel" style={{
                        marginTop: 0
                    }}>

                        <div className="filter-holder">
                            {/* <Filter
                                filter={state.filter}
                                handleSearch={handleSearch}
                            /> */}

                            <PaginationMini
                                page={state.filter.page}
                                pages={state.pages}
                                handlePage={handlePage}
                                isLoading={state.loading}
                            />
                        </div>

                        {state.loading && true
                            ?
                            <Loader />
                            :
                            state.data.length === 0 ?
                                <NoDataFound></NoDataFound>
                                :
                                <>
                                    {/* <div className="pagination-with-options mb-3">
                                        <Pagination
                                            page={state.filter.page}
                                            pages={state.pages}
                                            handlePage={handlePage}
                                            limit={state.filter.limit}
                                            onLimitChange={handleLimitChange}
                                        />
                                        <TableOptions
                                            url="store/reports/storage/amounts-by-depots"
                                            filter={state.filter}
                                            columns={state.columns}
                                            tableKey={state.tableKey}
                                            refresh={refreshTable}
                                        />
                                    </div> */}

                                    <table className="type-outer" ref={tableRef}>
                                        <TableHeader
                                            tableRef={tableRef}
                                            activeSortKey={state.filter.sort}
                                            activeSortDir={state.filter.order}
                                            onSort={(col, dir) => handleSort(col, dir)}
                                        >
                                            {Object.entries(state.headings).map((heading, i) =>
                                                <DynamicTableCol
                                                    key={i}
                                                    type={state.types[heading[0]]}
                                                    name={heading[1]}
                                                    sortKey={heading[0]}
                                                    sortable={state.columns.sortable.indexOf(heading[0]) > -1}
                                                    title={state.columns.description[heading[0]]}
                                                />
                                            )}
                                            {/* <th className="options">Опции</th> */}
                                        </TableHeader>
                                        <tbody>
                                            {state.data.map((c, i) =>
                                                <tr
                                                    key={'c-' + i}
                                                    onClick={e => {
                                                        handleShowArticle(c.id)
                                                    }}
                                                >
                                                    {Object.entries(state.headings).map((heading, i) =>
                                                        heading[0] === 'depots_quantity'
                                                            ?
                                                            <td className={`text-right brown-value`} style={{ backgroundColor: 'rgba(121,85,72,.529)' }}>
                                                                {c.depots_quantity}
                                                            </td>
                                                            :
                                                            heading[0] === 'depots_count'
                                                                ?
                                                                <td className={`text-right`}>
                                                                    {c.depots_count > 0
                                                                        ?
                                                                        <Link to={`/reports/storage/amounts-by-totals?article_id=${c.id}`}>
                                                                            {c.depots_count}
                                                                        </Link>
                                                                        :
                                                                        0
                                                                    }
                                                                </td>
                                                                :
                                                                heading[0] === 'amount_status'
                                                                    ?
                                                                    <td className={`text-center ${getAmountBg(c)}`}>
                                                                        {c.amount_status_text}
                                                                    </td>
                                                                    :
                                                                    <DynamicTableBodyCol
                                                                        key={heading[0]}
                                                                        type={state.types[heading[0]]}
                                                                        name={c[heading[0]]}
                                                                        data={c}
                                                                        currency={state.currency}
                                                                    />
                                                    )}
                                                    {/* <td className="options">
                                                        <MenuOptions>
                                                            <ArticleReportsOptions
                                                                data={c}
                                                                handleShowArticle={handleShowArticle}
                                                            />
                                                        </MenuOptions>
                                                    </td> */}
                                                </tr>
                                            )}
                                            <tr className="total">
                                                {Object.entries(state.totals).map((heading, i) =>
                                                    <DynamicTableBodyCol
                                                        key={heading[0]}
                                                        type={state.types[heading[0]]}
                                                        name={state.totals[heading[0]]}
                                                        data={state.totals}
                                                        currency={state.currency}
                                                        colspan={state.totalsColspan[heading[0]]}
                                                    />
                                                )}
                                            </tr>
                                        </tbody>
                                    </table>

                                    <Pagination
                                        className="mt-3"
                                        page={state.filter.page}
                                        pages={state.pages}
                                        total={state.total}
                                        handlePage={handlePage}
                                        limit={state.filter.limit}
                                        onLimitChange={handleLimitChange}
                                    />
                                </>
                        }

                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Index;
