//images
import otherIcon from "assets/img/customers/icons/other.png"
import taskIcon from "assets/img/customers/icons/task.png"
import callIcon from "assets/img/customers/icons/call.png"
import mailIcon from "assets/img/customers/icons/mail.png"
import networkIcon from "assets/img/customers/icons/network.png"
import meetingIcon from "assets/img/customers/icons/meeting.png"
import facebookIcon from "assets/img/customers/icons/facebook.png"
import instagramIcon from "assets/img/customers/icons/instagram.png"
import twitterIcon from "assets/img/customers/icons/twitter.png"

export const MONTHS = {
    1: "Януари",
    2: "Февруари",
    3: "Март",
    4: "Април",
    5: "Май",
    6: "Юни",
    7: "Юли",
    8: "Август",
    9: "Септември",
    10: "Октомври",
    11: "Ноември",
    12: "Декември",
}


export const ADD_OPTIONS_TYPES_SUBLISTS = {
    NETWORK: [
        {
            title: "Facebook действие",
            icon: facebookIcon,
            type: "facebook",
            background: "#5FAAFF",
        },
        {
            title: "Instagram действие",
            icon: instagramIcon,
            type: "instagram",
            background: "linear-gradient(94deg, #FFCF53 -0.01%, #CE4384 33.93%, #9461F0 65.43%, #FA524A 97.73%)",
        },
        {
            title: "Twitter (X) действие",
            icon: twitterIcon,
            type: "twitter",
            background: "#1DA1F2"
        },
    ],
}

export const ADD_OPTIONS_TYPES = {
    MEETING: {
        title: "Среща",
        icon: meetingIcon,
        type: "meeting",
        sublist: null,
        background: "#7CD8FF"
    },
    NETWORK: {
        title: "Действия в социални мрежи",
        icon: networkIcon,
        type: "network",
        sublist: ADD_OPTIONS_TYPES_SUBLISTS.NETWORK,
        background: "#7CD8FF"
    },
    MAIL: {
        title: "Изпращане на имейл",
        icon: mailIcon,
        type: "mail",
        sublist: null,
        background: "#7CD8FF"
    },
    CALL: {
        title: "Обаждане",
        icon: callIcon,
        type: "call",
        sublist: null,
        background: "#7CD8FF"
    },
    TASK: {
        title: "Дейност / Задача",
        icon: taskIcon,
        type: "task",
        sublist: null,
        background: "#7CD8FF"
    },
    OTHER: {
        title: "Другa",
        icon: otherIcon,
        type: "other",
        sublist: null,
        background: "#7CD8FF"
    },
}