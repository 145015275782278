import { useEffect } from "react"

//helpers
import Api from "helpers/Api"

//providers
import { useDashboardDataContext } from "../providers/dashboard"
import { useAppContext } from "providers/App"

function useFetchDocuments() {
    const app = useAppContext()
    const { setDocuments, setLoadingDocuments, getRefreshed } = useDashboardDataContext()

    useEffect(() => {
        _fetchData()
        app.getPaymentMethods()
    }, [getRefreshed('DOCUMENTS')])

    function _fetchData() {
        Api.get('dashboard/latest-documents')
            .then(res => {
                const { items } = res.data

                setDocuments(items)
            })
            .finally(() => {
                setLoadingDocuments(false)
            })
    }

    return {}
}

export default useFetchDocuments