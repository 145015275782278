import { useRef, useImperativeHandle, forwardRef } from 'react'
import Form from '../partials/Form'
import { useClientContext } from '../Provider'
import SaveButton from 'components/misc/Button'
import Vies from '../partials/Vies'

function Index(props, ref) {
    const context = useClientContext()

    const formRef = useRef(null)
    useImperativeHandle(ref, () => ({
        save: () => {
            formRef.current?.save()
        },
    }))

    return (
        <>
            <Form
                ref={formRef}
                id={props.id}
                show={props.show}
                hide={props.hide}
                viesModalRef={props.viesModalRef}
                handleStopLoadingSave={props.handleStopLoadingSave}
            />
            {/* <div
                className="mt-3"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <SaveButton
                    loading={context.state?.loading}
                    className="save"
                    onClick={e => formRef.current?.save(e)}
                />
            </div> */}
        </>
    )
}

export default forwardRef(Index)