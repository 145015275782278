import { useRef } from "react"
import { createPortal } from "react-dom"

//libraries
import { Button, MenuItem } from "@material-ui/core"

//components
import RedditSelect from "components/misc/RedditSelect"
import RedditTextField from "components/misc/RedditTextField"
import CampaignsList from "../partials/CampaignsList"
import PaginationMini from "components/misc/PaginationMini"
import Pagination from "components/misc/Pagination"
import AddOrEditCampaign from "../partials/AddOrEditCampaign"

const CAMPAIGNS_LIST = [
  {
    id: 1,
    status: {
      id: 1,
      title: "Активна",
      color: "#3BC870",
    },
    type: {
      id: 1,
      title: "Анкета",
    },
    title:
      "Lorem Ipsum е елементарен примерен текст, използван в печатарската и типографската индустрия",
    clients: [
      {
        id: 1,
        name: "Илиян Алински",
      },
      {
        id: 2,
        name: "Димитър Димитров",
      },
      {
        id: 3,
        name: "Антон Антонов",
      },
      {
        id: 4,
        name: "Иван Иванов",
      },
      {
        id: 5,
        name: "Петър Петров",
      },
    ],
    startDate: "2024-09-18",
    endDate: "2024-09-29",
  },
  {
    id: 2,
    status: {
      id: 2,
      title: "Пауза",
      color: "#FFC107",
    },
    type: {
      id: 2,
      title: "Събитие",
    },
    title:
      "Lorem Ipsum е елементарен примерен текст, използван в печатарската и типографската индустрия",
    clients: [
      {
        id: 1,
        name: "Илиян Алински",
      },
      {
        id: 2,
        name: "Димитър Димитров",
      },
      {
        id: 3,
        name: "Антон Антонов",
      },
    ],
    startDate: "2024-09-18",
    endDate: "2024-09-29",
  },
  {
    id: 3,
    status: {
      id: 3,
      title: "Приключена",
      color: "#F44336",
    },
    type: {
      id: 3,
      title: "Промоция",
    },
    title:
      "Lorem Ipsum е елементарен примерен текст, използван в печатарската и типографската индустрия",
    clients: [
      {
        id: 1,
        name: "Илиян Алински",
      },
      {
        id: 2,
        name: "Димитър Димитров",
      },
    ],
    startDate: "2024-09-18",
    endDate: "2024-09-29",
  },
  {
    id: 4,
    status: {
      id: 3,
      title: "Приключена",
      color: "#F44336",
    },
    type: {
      id: 3,
      title: "Промоция",
    },
    title:
      "Lorem Ipsum е елементарен примерен текст, използван в печатарската и типографската индустрия",
    clients: [
      {
        id: 1,
        name: "Илиян Алински",
      },
      {
        id: 2,
        name: "Димитър Димитров",
      },
    ],
    startDate: "2024-09-18",
    endDate: "2024-09-29",
  },
  {
    id: 5,
    status: {
      id: 3,
      title: "Приключена",
      color: "#F44336",
    },
    type: {
      id: 3,
      title: "Промоция",
    },
    title:
      "Lorem Ipsum е елементарен примерен текст, използван в печатарската и типографската индустрия",
    clients: [
      {
        id: 1,
        name: "Илиян Алински",
      },
      {
        id: 2,
        name: "Димитър Димитров",
      },
    ],
    startDate: "2024-09-18",
    endDate: "2024-09-29",
  },
]

function Campaigns({ clientId }) {
  const addOrEditCampaignRef = useRef(null)

  return (
    <>
      <div className="campaigns">
        <div className="field">
          <div className="filter-holder">
            <div className="row filter">
              <div
                className="col"
                style={{
                  maxWidth: "300px",
                }}
              >
                <RedditTextField
                  size="small"
                  margin="dense"
                  fullWidth
                  label="Търсене..."
                  name="search"
                  //   value={filters.search}
                  //   onChange={handleChangeFilter}
                />
              </div>
              <div className="col">
                <RedditSelect
                  label="Статус"
                  fullWidth
                  size="small"
                  margin="dense"
                  defaultValue="all"
                  //   value={filters.statusId}
                  //   onChange={handleChangeFilter}
                  name="statusId"
                >
                  <MenuItem value="all">Всички</MenuItem>
                  {/* {getStatusesOnly().map(({ title, id, color }) => (
                <MenuItem key={id} value={id}>
                  {title}
                </MenuItem>
              ))} */}
                </RedditSelect>
              </div>
              <div className="col">
                <RedditSelect
                  label="Тип"
                  fullWidth
                  size="small"
                  margin="dense"
                  defaultValue="all"
                  //   value={filters.statusId}
                  //   onChange={handleChangeFilter}
                  name="typeId"
                >
                  <MenuItem value="all">Всички</MenuItem>
                  {/* {getStatusesOnly().map(({ title, id, color }) => (
                <MenuItem key={id} value={id}>
                  {title}
                </MenuItem>
              ))} */}
                </RedditSelect>
              </div>
            </div>
            <PaginationMini
              isLoading={false}
              page={1}
              pages={5}
              handlePage={() => {}}
            />
          </div>
          <CampaignsList campaigns={CAMPAIGNS_LIST} />
          <hr />
          <Button
            className="add"
            onClick={() => addOrEditCampaignRef.current.add()}
          >
            Нова кампания
          </Button>
          <Pagination page={1} pages={5} handlePage={() => {}} />
        </div>
      </div>
      {createPortal(
        <AddOrEditCampaign ref={addOrEditCampaignRef} clientId={clientId} />,
        document.body
      )}
    </>
  )
}

export default Campaigns
