//libraries
import MenuItem from "@material-ui/core/MenuItem"

//images
import editIcon from "assets/img/app/icons/edit.png"
import deleteIcon from "assets/img/app/icons/delete.png"

function CampaignOptions({ handleEdit, handleDelete }) {
  return (
    <>
      <MenuItem onClick={handleEdit}>
        <img src={editIcon} />
        Редакция
      </MenuItem>
      <MenuItem onClick={handleDelete}>
        <img src={deleteIcon} />
        Изтриване
      </MenuItem>
    </>
  )
}

export default CampaignOptions
