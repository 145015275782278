import { useState, useImperativeHandle, forwardRef, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import ClickAwayListener from 'react-click-away-listener'
import InfiniteScroll from 'react-infinite-scroller'
//plugins
import axios from 'axios'

//helpers
import Api from 'helpers/Api'

//misc
import RedditTextField from 'components/misc/RedditTextField'

//MUI components
import { CircularProgress } from '@material-ui/core'
import moment from 'moment'

function CustomerInput(props, ref) {
    const onChange = typeof props.onChange === 'function' ? props.onChange : null
    const onInputChange = typeof props.onInputChange === 'function' ? props.onInputChange : null
    const onFocus = typeof props.onFocus === 'function' ? props.onFocus : null
    const onBlur = typeof props.onBlur === 'function' ? props.onBlur : null
    const inputLabelProps = props.inputLabelProps
    const searchParamName = props.searchParamName || 'name'
    const autoFocus = props.autoFocus || false
    const noCompany = props.noCompany || false
    const tradeRegistryReqUrl = props.tradeRegistryReqUrl || ''
    const customersFindReqUrl = props.customersFindReqUrl || ''

    const defaultData = {}

    const [state, setState] = useState({
        showOptions: false,
        input: {
            value: '',
            timeout: null,
        },
        selected: null,
        customers: {
            data: [],
            total: 0,
            pages: 0,
            page: 0,
            hasMore: false,
            request: null,
            loading: false,
            dataLoaded: false,
        },
        tradeRegistry: {
            data: [],
            total: 0,
            pages: 0,
            page: 0,
            hasMore: false,
            request: null,
            loading: false,
            dataLoaded: false,
        },
        loading: false,
        filter: false,
        onError: null,
        onClose: null,
    })

    useImperativeHandle(ref, () => ({
        onChange: fn => {
            setState(prev => ({
                ...prev,
                onChange: fn
            }))
        },
        onInputChange: fn => {
            setState(prev => ({
                ...prev,
                onChange: fn
            }))
        },
        onError: fn => {
            setState(prev => ({
                ...prev,
                onError: fn
            }))
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }))
        }
    }))

    useEffect(() => {
        setState(prev => ({
            ...prev,
            input: {
                ...prev.input,
                value: props.value || ''
            },
        }))

        clearTimeout(state.input.timeout)

        const timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: moment().unix()
            }))
        }, 500)

        setState(prev => ({
            ...prev,
            input: {
                ...prev.input,
                timeout
            }
        }))
    }, [props.value])

    useEffect(() => {
        if (state.showOptions && !state.customers.dataLoaded) loadCustomers(state.input.value)
    }, [props.companyId, state.showOptions])

    useEffect(() => {
        if (!state.filter && !noCompany) return

        setState(prev => ({
            ...prev,
            loading: true,
        }))

        const promise = Promise.all([
            loadCustomers(state.input.value),
            loadCustomersFromTradeRegistry(state.input.value)
        ])

        promise.finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }))
        })
    }, [state.filter])

    const loadCustomers = async (name, page = 1, reset = true) => {
        if (!props.companyId && !noCompany) return

        if (state.customers.request) state.customers.request.cancel()

        const request = axios.CancelToken.source()

        setState(prev => ({
            ...prev,
            customers: {
                ...prev.customers,
                loading: true,
                request: request,
                hasMore: false,
            }
        }))

        return Api.get(customersFindReqUrl,
            {
                params: {
                    company_id: props.companyId,
                    [searchParamName]: name,
                    page
                },
                cancelToken: request.token
            }
        )
            .then(res => {
                setState(prev => ({
                    ...prev,
                    customers: {
                        ...prev.customers,
                        data: reset ? res.data.items : [...prev.customers.data, ...res.data.items],
                        total: res.data.total,
                        pages: res.data.pages,
                        hasMore: res.data.pages > page,
                        loading: false,
                        dataLoaded: true,
                    },
                }))
            })

    }

    const loadCustomersFromTradeRegistry = async (name, page = 1, reset = true) => {
        // if (!props.companyId) return

        if (state.tradeRegistry.request) state.tradeRegistry.request.cancel()

        const request = axios.CancelToken.source()

        setState(prev => ({
            ...prev,
            tradeRegistry: {
                ...prev.tradeRegistry,
                loading: true,
                request,
                hasMore: false,
            }
        }))

        return Api.get(tradeRegistryReqUrl,
            {
                params: {
                    company_id: props.companyId,
                    [searchParamName]: name,
                    page
                },
                cancelToken: request.token
            }
        )
            .then(res => {
                setState(prev => ({
                    ...prev,
                    tradeRegistry: {
                        ...prev.tradeRegistry,
                        // data: res.data.items,
                        data: reset ? res.data.items : [...prev.tradeRegistry.data, ...res.data.items],
                        total: res.data.total,
                        pages: res.data.pages,
                        hasMore: res.data.pages > page,
                        loading: false,
                        dataLoaded: true,
                    },
                }))
            })
            .catch(() => {
                setState(prev => ({
                    ...prev,
                    tradeRegistry: {
                        ...prev.tradeRegistry,
                        data: [],
                        total: 0,
                        pages: 0,
                        hasMore: false,
                        loading: false,
                        dataLoaded: true,
                    },
                }))
            })

    }

    const resetData = () => {
        setState(prev => ({
            ...prev,
            data: defaultData,
        }))
    }

    const handleInputChange = e => {
        const { name, value } = e.target

        setState(prev => ({
            ...prev,
            input: {
                ...prev.input,
                value
            },
        }))

        if (onInputChange) onInputChange(value)

        clearTimeout(state.input.timeout)

        const timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: moment().unix()
            }))
        }, 500)

        setState(prev => ({
            ...prev,
            input: {
                ...prev.input,
                timeout
            }
        }))
    }

    const handleToggleOptions = () => {
        setState(prev => ({
            ...prev,
            showOptions: !prev.showOptions
        }))
    }

    const showOptions = () => {
        if (!props.companyId) return

        if (state.showOptions) return

        setState(prev => ({
            ...prev,
            showOptions: true
        }))
    }

    const hideOptions = () => {
        setState(prev => ({
            ...prev,
            showOptions: false
        }))
    }

    const handleClickAway = () => {
        hideOptions()
    }

    const selectCustomer = customer => {
        setState(prev => ({
            ...prev,
            selected: customer
        }))

        if (onChange) onChange(customer)

        hideOptions()
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className="customer-input">
                <RedditTextField
                    margin="dense"
                    size="small"
                    fullWidth
                    label={props.label}
                    name={props.name}
                    value={state.input.value || ''}
                    onChange={handleInputChange}
                    onClick={showOptions}
                    error={props.error}
                    helperText={props.helperText}
                    onFocus={() => {
                        onFocus && onFocus()
                        showOptions()
                    }}
                    onBlur={() => {
                        onBlur && onBlur()
                        setTimeout(() => {
                            handleClickAway()
                        }, 500)
                    }}
                    autoFocus={autoFocus}
                    InputLabelProps={inputLabelProps}
                />
                {!(state.customers.data.length === 0 && state.tradeRegistry.data.length === 0) ?
                    <div className={`dropdown ${state.showOptions ? 'show' : ''} ${!state.customers.data.length || !state.tradeRegistry.data.length ? 'half' : ''}`}>

                        {state.loading
                            ?
                            <Skeleton count={5} height={30} />
                            :
                            state.customers.data.length === 0 && state.tradeRegistry.data.length === 0
                                ?
                                <p>Няма намерени резултати</p>
                                :

                                <div className="row">
                                    {state.customers.data.length > 0
                                        ?
                                        <div className={`companies-col col ${state.tradeRegistry.data.length > 0 ? 'col-2' : ''}`}>
                                            <div className="head">
                                                Моите клиенти
                                            </div>
                                            <div className="ul">
                                                <InfiniteScroll
                                                    key={0}
                                                    element="ul"
                                                    pageStart={1}
                                                    initialLoad={false}
                                                    loadMore={(page) => loadCustomers(state.input.value, page, false)}
                                                    hasMore={state.customers.hasMore}
                                                    loader={<li key={-1}><CircularProgress size={20} /></li>}
                                                    useWindow={false}
                                                >
                                                    {state.customers.data.map((customer, i) =>
                                                        <li
                                                            key={'c-' + i}
                                                            onClick={e => selectCustomer(customer)}
                                                            className={state.selected && state.selected.id === customer.id && state.selected.class === customer.class ? 'selected' : ''}
                                                        >
                                                            {customer?.info?.name}
                                                            <span style={{ display: 'block', fontSize: '10px' }}>
                                                                ЕИК: {customer?.info?.eikegn}, {customer.addresses[0]?.settlement?.name}
                                                            </span>
                                                        </li>
                                                    )}
                                                </InfiniteScroll>
                                            </div>
                                        </div>
                                        :
                                        ''
                                    }


                                    {state.tradeRegistry.data.length > 0
                                        ?
                                        <div className={`companies-col col ${state.customers.data.length > 0 ? 'col-2' : ''}`}>
                                            <div className="head">
                                                От търговския регистър
                                            </div>
                                            <div className="ul">
                                                <InfiniteScroll
                                                    key={1}
                                                    element="ul"
                                                    pageStart={1}
                                                    initialLoad={false}
                                                    loadMore={(page) => loadCustomersFromTradeRegistry(state.input.value, page, false)}
                                                    hasMore={state.tradeRegistry.hasMore}
                                                    loader={<li key={-1}><CircularProgress size={20} /></li>}
                                                    useWindow={false}
                                                >
                                                    {state.tradeRegistry.data.map((customer, i) =>
                                                        <li
                                                            key={'tr-' + i}
                                                            onClick={e => selectCustomer(customer)}
                                                            className={state.selected && state.selected.id === customer.id && state.selected.class === customer.class ? 'selected' : ''}
                                                        >
                                                            {customer?.info?.name}
                                                            <span style={{ display: 'block', fontSize: '10px' }}>
                                                                ЕИК: {customer?.info?.eikegn}, {customer.addresses[0]?.settlement?.name}
                                                            </span>
                                                        </li>
                                                    )}
                                                </InfiniteScroll>
                                            </div>
                                        </div>
                                        :
                                        ''
                                    }
                                </div>


                        }
                    </div>
                    :
                    <></>
                }
            </div>
        </ClickAwayListener>
    )
}

export default forwardRef(CustomerInput)