import { useIsMount } from "helpers/IsMount"
import { useNestedState } from "helpers/NestedState"
import { usePresenceSocketChannel } from "helpers/Socket"
import { useQuery } from "helpers/Url"
import moment from "moment"
import { useAppContext } from "providers/App"
import { useAuthDataContext } from "providers/Auth"
import { createContext, useState, useContext, useEffect } from "react"
// import User from "../models/User";

export const PayOrderContext = createContext({})

const PayOrderProvider = props => {

    const query = useQuery()
    const auth = useAuthDataContext()

    const isMount = useIsMount()

    const filter = {
        page: 1,
        start_date: '',
        end_date: '',
        search: ''
    }

    const [state, setState] = useState({
        loading: true,
        forceLoading: true,
        refresh: false,
        setFilter: false,
        data: [],
        pages: 0,
        total: 0,
        filter: {
            page: query.get('page') || 1,
            start_date: query.get('start_date') || '',
            end_date: query.get('end_date') || '',
            // company_id: auth?.getUser()?.getCompany()?.getId(),
            search: query.get('search') || ''
        },
    })

    const [documents, setDocuments] = useNestedState({})

    useEffect(() => {
        if (!isMount) {
            return
        }

        setState(prev => ({
            ...prev,
            filter: filter,
            refresh: moment().unix(),
            // setFilter: moment().unix(),
            forceLoading: true,
        }))
    }, [auth])

    usePresenceSocketChannel({
        channel: 'companies',
        channelId: auth?.getUser()?.getCompany()?.getId(),
        event: '.blackbox\\documents\\events\\PayOrderChanged',
        onEvent: (payload) => {
            setState(prev => ({
                ...prev,
                refresh: moment().unix(),
                setFilter: moment().unix(),
                forceLoading: false,
            }))

            // да се изчисти стейта на запазения документ,
            // за да може да се направи нова заявка за него, 
            // когато се отвори страницата за редакция
            if (documents[payload.id]) {
                setDocuments(prev => ({
                    ...prev,
                    [payload.id]: null
                }))
            }
        },
    }, [auth])

    const setDocumentData = (id, data) => {
        setDocuments(id, data, null)
    }

    const clearFilter = () => {
        setState(prev => ({
            ...prev,
            filter: filter,
            setFilter: moment().unix().getTime()
        }))
    }

    const cleanUp = () => {
        setState(prev => ({
            ...prev,
            refresh: false,
            setFilter: false,
        }))
    }

    const data = {
        state,
        setState,
        documents,
        setDocuments,
        setDocumentData,
        clearFilter,
        cleanUp
    }

    return <PayOrderContext.Provider value={data} {...props} />
}

export const usePayOrderContext = () => useContext(PayOrderContext)

export default PayOrderProvider