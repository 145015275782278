//components
import IncomesAndExpenses from "components/pages/reports/partials/IncomesAndExpenses"
import ActivitiesDashboard from "../partials/ActivitiesDashboard"

function Dashboard({ id, handleClose }) {
  return (
    <>
      <div className="row">
        <div className="col">
          <ActivitiesDashboard clientId={id} handleClose={handleClose} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="field">
            <div className="statistics-holder">
              <IncomesAndExpenses
                chartOnly={true}
                hasAnimation={false}
                endpoint={`store/clients/dashboard/incomes-expenses?client_id=${id}`}
                graphStyle={{
                  height: 170,
                  marginBottom: 0,
                }}
                chartStyle={{
                  height: 230,
                }}
                headStyle={{
                  paddingLeft: 0,
                  paddingTop: 0,
                }}
                key={id}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard
