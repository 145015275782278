import { useEffect, useRef, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { cloneDeep } from 'lodash'
import printJS from 'print-js'
import { useLocation } from 'react-router-dom'

//modals
import Question from 'components/modals/Question'

//helpers
import Api from 'helpers/Api'
import { splitLongNumbers } from 'helpers/numbers'
import { useQuery } from 'helpers/Url'

//providers
import { useAuthDataContext } from 'providers/Auth'
import { useDocumentContext } from './Provider'
import { useAppContext } from 'providers/App'
import { useDocumentsTabsContext } from 'providers/DocumentsTabs'

//MUI components
import { Checkbox, Tooltip, Button } from '@material-ui/core'

//images
import checkIcon from 'assets/img/app/icons/check.png'
import mailIcon from 'assets/img/app/icons/mail.png'
import downloadIcon from 'assets/img/app/icons/download.png'
import printIcon from 'assets/img/app/icons/print.png'
import doubleCheckIcon from 'assets/img/documents/icons/double-check.png'
import checkIconWhite from 'assets/img/documents/icons/check.png'

//partials
import MultipleActions from './partials/MultipleActions'
import RepeatOptions from './partials/RepeatOptions'
import MakePaid from './partials/payment/MakePaid'
import Options from './partials/Options'
import AddPayment from './partials/payment/AddPayment'
import Filter from './partials/filter/Index'
import Send from 'components/partials/documents/Send'
import Export from './partials/export/Export.jsx'
import Config from './partials/payment/Config'
import AdditionalOptions from './partials/additional_options/Index'

//miscs
import NoSelectedCompany from 'components/misc/NoSelectedCompany'
import EmptyList from 'components/misc/EmptyList'
import Pagination from 'components/misc/Pagination'
import MenuOptions from 'components/misc/MenuOptions'
import PrintButtonLayout from './partials/PrintButtonLayout'
import DownloadButtonLayout from './partials/DownloadButtonLayout'
import PaginationMini from 'components/misc/PaginationMini'

//hooks
import useConvertToDebitNote from './hooks/useConvertToDebitNote'
import useConvertToCreditNote from './hooks/useConvertToCreditNote'
import useConvertToProforma from './hooks/useConvertToProforma'
import useConvertToInvoice from './hooks/useConvertToInvoice'
import useRightClick from 'hooks/useRightClick'
import moment from 'moment'

function Index() {
    const { handleAddNewSubtab, getActiveTab, getActiveTabTypeId, getActiveSubtabId, appendNewSubtabNextNumber } = useDocumentsTabsContext()
    const auth = useAuthDataContext()
    const app = useAppContext()
    const location = useLocation()
    const query = useQuery()

    const { handleConvertToDebitNote } = useConvertToDebitNote()
    const { handleConvertToCreditNote } = useConvertToCreditNote()
    const { handleConvertToProforma } = useConvertToProforma()
    const { handleConvertToInvoice } = useConvertToInvoice()
    const { handlePositionMenuOptions } = useRightClick()

    const deleteModalRef = useRef(null)
    const makePaidModalRef = useRef(null)
    const makeUnpaidModalRef = useRef(null)
    const addPaymentModalRef = useRef(null)
    const sendModalRef = useRef(null)
    const repeatOptionsModalRef = useRef(null)
    const exportModalRef = useRef(null)
    const searchTimeoutRef = useRef(null)

    const context = useDocumentContext()
    const { state, setState, documentReqData, isLoading } = context
    const { items } = documentReqData || {}

    const [localState, setLocalState] = useState({
        selected: {},
        successTooltip: false,
        successMessage: '',
        configPaymentPopover: null
    })
    const [allDocumentsFromAllPagesSelected, setAllDocumentsFromAllPagesSelected] = useState(false)

    useEffect(() => {
        //всички методи на плащане
        app.getPaymentMethods()
        appendNewSubtabNextNumber(getActiveTabTypeId())
    }, [])

    // useEffect(() => {
    //     if (state.setFilter) {
    //         setState(prev => ({
    //             ...prev,
    //             filter: {
    //                 ...prev.filter,
    //                 page: query.get('page') || 1,
    //                 start_date: query.get('start_date') || '',
    //                 end_date: query.get('end_date') || '',
    //                 search: query.get('search') || '',
    //                 client_id: query.get('client_id') || '',
    //             },
    //             refresh: moment().unix(),
    //         }))
    //     }
    // }, [location.search])

    useEffect(() => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                client_id: location.state?.client_id || '',
            },
            refresh: moment().unix(),
        }))
    }, [location.state])

    useEffect(() => {
        if (documentReqData) {
            setLocalState(prev => ({
                ...prev,
                selected: {}
            }))
        }
    }, [documentReqData])

    function loading(loading) {
        setState(prev => ({
            ...prev,
            loading,
            forceLoading: true,
        }))
    }

    const refresh = (reset = true, forceLoading = true) => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: reset ? 1 : prev.filter.page
            },
            refresh: moment().unix(),
            forceLoading
        }))
    }

    const success = msg => {
        app.handleSuccess(msg)
    }

    const error = msg => {
        app.handleError(msg)
    }

    // delete
    const handleDelete = id => {
        const modal = deleteModalRef.current

        modal.open()
        modal.onSuccess(() => {
            handleDestroy(id)
        })
    }

    const handleDestroy = id => {
        Api.post('documents/delete', {
            id
        })
            .then(res => {
                success('Документа беше изтрит успешно!')
                document.querySelector(`.tabs .active li[data-id="${id}"] i.close`)?.click()
                refresh()
            })
            .catch(() => {
                error()
            })
    }

    const handleMakePaid = id => {
        const modal = makePaidModalRef.current
        modal.open(id)
        modal.onSuccess(() => {
            refresh()
            success()
        })
    }

    const handleMakeUnpaid = id => {
        const modal = makeUnpaidModalRef.current
        modal.open()
        modal.onSuccess(() => {
            makeUnpaid(id)
        })
    }

    const makeUnpaid = id => {
        Api.post('documents/make-unpaid', {
            id
        })
            .then(res => {
                // appendDataFromPaymentReq(res.data)
                refresh()
                success('Документа беше върнат за плащане успешно!')
            })
    }

    const handleAddPayment = id => {
        const modal = addPaymentModalRef.current
        modal.open(id)
        modal.onSuccess(() => {
            refresh()
            success()
        })
    }

    function handlePreview(data) {
        handleAddNewSubtab(data, 'preview')
    }

    function handleEdit(data) {
        handleAddNewSubtab(data, 'edit')
    }

    const handleSend = id => {
        let email = null

        if (!Array.isArray(id)) {
            const document = (items || []).find(d => Number(d.id) === Number(id))

            if (document) {
                email = document?.customerinfo?.email
            }
        }

        const modal = sendModalRef.current
        modal.open(id, email)
        modal.onSuccess(() => {
            success()
        })
        modal.onError(() => {
            error()
        })
    }

    const handleExport = id => {
        const modal = exportModalRef.current

        modal.open(id)
        modal.onSuccess(() => {
            success('Експортирането завърши успешно!')
        })
        modal.onError(() => {
            error()
        })
    }

    /**
     * @param {string} pdfUrl връзка към .pdf файла
     */
    const handlePrint = pdfUrl => {
        printJS({
            printable: `${pdfUrl}&token=${auth.getToken()}`,
            onError: error => {
                console.log(error)
            }
        })
    }

    const handleShowRepeatOptions = data => {
        const modal = repeatOptionsModalRef.current

        modal.open(data)
        modal.onSuccess(() => {
            refresh()
            success()
        })

        modal.onError(() => {
            error()
        })
    }

    // Search
    const handleSearch = (key, val, delay = 300) => {
        if (searchTimeoutRef.current) clearTimeout(searchTimeoutRef.current)

        if (typeof key === 'object') {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    ...key
                },
            }))
        } else {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    [key]: val,
                },
            }))
        }

        searchTimeoutRef.current = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                setFilter: moment().unix()
            }))
        }, delay)
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            setFilter: moment().unix()
        }))
    }

    const clearFilter = () => context.clearFilter(getActiveTabTypeId())

    /**
     * 
     * @param {object} document избрания документ 
     */
    const handleSelect = document => {
        if (allDocumentsFromAllPagesSelected) setAllDocumentsFromAllPagesSelected(false)

        const selected = cloneDeep(localState.selected)

        if (document) {
            if (selected[document.id]) {
                delete selected[document.id]
            } else {
                selected[document.id] = 1
            }
        }

        setLocalState(prev => ({
            ...prev,
            selected
        }))
    }

    const handleSelectAll = () => {
        // DS
        // if (areAllSelected() && !allDocumentsFromAllPagesSelected) {
        //     setAllDocumentsFromAllPagesSelected(true)

        //     return
        // }


        if (allDocumentsFromAllPagesSelected) setAllDocumentsFromAllPagesSelected(false)

        selectAllAtCurrentPage()
    }

    function selectAllAtCurrentPage() {
        const selected = {}

        if (!areAllSelected()) {
            (items || []).map(d => {
                selected[d.id] = 1
            })
        }

        setLocalState(prev => ({
            ...prev,
            selected
        }))
    }

    /**
     * 
     * @returns колко избрани елемента има
     */
    const getSelectedCount = () => allDocumentsFromAllPagesSelected ? documentReqData.total : Object.values(localState.selected).length

    /**
     * 
     * @returns дали всички елементи са избрани
     */
    const areAllSelected = () => allDocumentsFromAllPagesSelected || (getSelectedCount() === getDataCount())

    /**
     * 
     * @param {number} id id на документа 
     * @returns дали докуемнта се редактира
     */
    const isCurrentlyEdited = id => (context.documentsEditing[id] || false)

    /**
     * 
     * @param {number} id id на документа 
     * @returns масив с потребители, които редактират докуемнта
     */
    const getCurrentlyEditingUsers = id => {
        const users = context.documentsEditing[id]

        if (users) {
            return Object.values(users)
        }

        return []
    }

    /**
     * 
     * @param {number} id id на подраздела 
     * @returns елемента на подраздела в списъка с раздели
     */
    const getCurrentSubtab = id => {
        const subtab = document.querySelector(`.tabs .subtabs li[data-id="${id}"]`) || null

        return subtab
    }

    /**
     * 
     * @param {string} event събитието (leave, enter) 
     * @param {number} id id на подраздела 
     */
    const handleSubtabIndicator = (event, id) => {
        const subtab = getCurrentSubtab(id)

        if (subtab) {
            if (event === 'enter') subtab.classList.add('indicate')
            else if (event === 'leave') subtab.classList.remove('indicate')
        }
    }

    /**
     * 
     * @param {number} id id на документа 
     */
    const handleSuccessTooltip = (id, message) => {
        setLocalState(prev => ({
            ...prev,
            successTooltip: id,
            successMessage: message,
        }))
        setTimeout(() => {
            setLocalState(prev => ({
                ...prev,
                successTooltip: false,
                message: ''
            }))
        }, 2000)
    }

    /**
     * 
     * @param {number} langId id на езика 
     */
    const download = pdfUrl => {
        window.open(`${pdfUrl}&token=${auth.getToken()}`)
    }

    /**
     * 
     * @returns колко документи има в таблицата
     */
    const getDataCount = () => (items?.length || 0)

    const handleDublicate = data => {
        app.handleInfo('Издаване на дубликат...')
        Api.get(`documents/duplicate?id=${data.id}`)
            .then(res => {
                // handleSuccessTooltip(data.id, 'Документа беше дублиран.')
                success('Документа беше дублиран.')
                // console.log(res.data)
                handlePreview(res.data)
            })
            .catch(() => {
                app.handleError()
            })
            .finally(() => {
                app.handleHideInfo()
            })
    }

    /**
     * 
     * @param {object} data данните, който се заместват в реда на таблицата, когато някакво плащане бъде извършено по документ
     */
    const appendDataFromPaymentReq = data => {
        const clonedItems = [...items]

        const updatedData = clonedItems.map(d => {
            if (d.id === data.id) {
                d = data
            }
            return d
        })

        setState(prev => ({
            ...prev,
            [getActiveTabTypeId()]: {
                ...prev[getActiveTabTypeId()],
                data: updatedData,
            }
        }))
    }

    /**
 * дали има маркиран филтър, какъвто и да е той
 * @returns {boolean}
 */
    const areAnyFiltersApplied = () => {
        const { filter } = state

        const hasSearch = () => (filter?.search || '')?.length
        const hasSent = () => (filter?.sent || '')?.length
        const hasPeriod = () => (filter?.period || '')?.length
        const hasStatus = () => (filter?.status || '')?.length
        const hasPaymentMethod = () => typeof filter?.payment_method_id === 'number'
        const hasType = () => ((Number(filter?.type_id) !== Number(getActiveTabTypeId())) && filter?.type_id?.length)

        if (hasSearch()
            || hasSent()
            || hasPeriod()
            || hasStatus()
            || hasPaymentMethod()
            || hasType()
        ) {
            return true
        }

        return false
    }

    /**
     * връща описателния текст, когато няма намерен резултат
     * @returns {string}
     */
    const generateNoResultsInfoText = () => {
        const { filter } = state
        const hasSearch = () => (filter?.search || '')?.length
        const hasSent = () => (filter?.sent || '')?.length
        const hasPeriod = () => (filter?.period || '')?.length
        const hasPaymentMethod = () => typeof filter?.payment_method_id === 'number'
        const hasType = () => ((Number(filter?.type_id) !== Number(getActiveTabTypeId())) && filter?.type_id?.length)

        return `${areAnyFiltersApplied() ? `Не бяха открити документи ${hasSearch() ? `с тези ключови думи${hasPaymentMethod() || hasType() || hasPeriod() || hasSent() ? ',' : ''}` : ''}${hasSent() ? ` с този статус${hasPaymentMethod() || hasType() || hasPeriod() ? ',' : ''}` : ''}${hasPeriod() ? ` за този период${hasPaymentMethod() || hasType() ? ',' : ''}` : ''}${hasPaymentMethod() ? ` с такъв метод на плащане ${hasType() ? ',' : ''} ` : ''}${hasType() ? ` от този тип` : ''}.` : 'Все още няма запазени документи в този списък.'} ${getActiveTab().notFoundText}`.replace(/ +(?= )/g, '').replace(' .', '.')
    }

    function renderMultipleActions() {
        if (getSelectedCount()) {
            return (
                <MultipleActions
                    getSelectedCount={getSelectedCount}
                    getSelectedDocumentsIds={() => Object.keys(localState.selected)}
                    getDocuments={() => items}
                    typeId={getActiveTabTypeId()}
                    allDocumentsFromAllPagesSelected={allDocumentsFromAllPagesSelected}
                    totalCount={documentReqData?.total || 0}
                    setAllDocumentsFromAllPagesSelected={() => {
                        setAllDocumentsFromAllPagesSelected(true)
                        if (!areAllSelected()) selectAllAtCurrentPage()
                    }
                    }
                />
            )
        }

        return <></>
    }

    function hasRepeatDocuments() {
        const repeatDocument = items.find(d => d.is_repeat)

        return repeatDocument
    }

    function isCompanyVatRegistered() {
        return auth?.getUser()?.getCompany()?.vat_registered
    }

    return (
        <>
            {/* <PageLoader show={state.loading} /> */}
            {renderMultipleActions()}
            <Question
                ref={deleteModalRef}
                mainMessage="Потвърждавам изтриването"
                secondaryMessage="Изтритите от Вас данни не могат да бъдат възстановени."
                agreeBtnText="Изтриване"
                agreeBtnClass="remove"
            />

            <Question
                ref={makeUnpaidModalRef}
                mainMessage="Върни за плащане"
                secondaryMessage="След Вашето потвърждение, всички плащания към документа ще бъдат премахнати от системата."
                agreeBtnText="Продължете"
            />

            <MakePaid
                ref={makePaidModalRef}
            />

            <AddPayment
                ref={addPaymentModalRef}
            />

            <Send
                ref={sendModalRef}
                documentsType="documents"
            />

            <Export
                ref={exportModalRef}
                typeId={getActiveTabTypeId()}
                getSelectedCount={getSelectedCount}
            />

            <RepeatOptions
                ref={repeatOptionsModalRef}
            />

            <section id="documents">
                {!auth.getUser().getCompany()
                    ?
                    <NoSelectedCompany />
                    :
                    <div className="panel table-panel">
                        <div className="filter-holder">
                            <Filter
                                filter={state.filter}
                                handleSearch={handleSearch}
                                clearFilter={clearFilter}
                                typeId={getActiveTabTypeId()}
                            />
                            <PaginationMini
                                page={state.filter?.page}
                                pages={documentReqData?.pages}
                                handlePage={handlePage}
                                isLoading={isLoading}
                            />
                        </div>
                        {isLoading
                            ?
                            <Skeleton
                                count={5}
                                height={60}
                            />
                            :
                            getDataCount() === 0 ?
                                <EmptyList
                                    infoText={generateNoResultsInfoText()}
                                    btnText={getActiveTab().name}
                                    areAnyFiltersApplied={areAnyFiltersApplied()}
                                />
                                :
                                <>
                                    <table className="type-outer">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <Tooltip
                                                        title={areAllSelected() && !allDocumentsFromAllPagesSelected ? `${items?.length || 0} бр.` : allDocumentsFromAllPagesSelected ? `${documentReqData?.total} бр.` : 'Маркиране на всички документи от тази страница'}
                                                    >
                                                        <div
                                                            className="custom-checkbox"
                                                            onClick={handleSelectAll}
                                                        >
                                                            {areAllSelected() || allDocumentsFromAllPagesSelected ?
                                                                <div>
                                                                    <img
                                                                        src={allDocumentsFromAllPagesSelected ? doubleCheckIcon : checkIconWhite}
                                                                        alt=""

                                                                    />
                                                                </div>
                                                                : <div className="empty"></div>
                                                            }
                                                        </div>
                                                    </Tooltip>
                                                </th>
                                                <th>
                                                    Тип
                                                </th>
                                                <th>
                                                    Номер
                                                </th>
                                                <th>
                                                    Дата
                                                </th>
                                                <th>
                                                    Получател
                                                </th>
                                                {isCompanyVatRegistered() ?
                                                    <>
                                                        <th className="text-right">
                                                            Сума
                                                        </th>
                                                        <th className="text-right">
                                                            ДДС
                                                        </th>
                                                    </>
                                                    :
                                                    <>
                                                        <th></th>
                                                        <th></th>
                                                    </>
                                                }
                                                <th className="text-right">
                                                    {isCompanyVatRegistered() ? 'Общо' : 'Сума'}
                                                </th>
                                                <th className="text-right">
                                                    За плащане
                                                </th>
                                                <th className="text-center">
                                                    Платено
                                                </th>
                                                {hasRepeatDocuments() ?
                                                    <th className="text-center">
                                                        Периодична
                                                    </th>
                                                    :
                                                    <></>
                                                }
                                                <th className="text-center">
                                                    Опции
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {items.map(d =>
                                                <tr
                                                    key={'doc-' + d.id}
                                                    className={`${isCurrentlyEdited(d.id) ? 'editing' : ''} ${items.length > 7 ? 'small-padding' : ''}`}
                                                    // onClick={e => {
                                                    //     if (isRowNotClickable(e.target)) handleAddNewSubtab(d, "preview")
                                                    // }}
                                                    onMouseEnter={() => {
                                                        handleSubtabIndicator('enter', d.id)
                                                    }}
                                                    onMouseLeave={() => {
                                                        handleSubtabIndicator('leave', d.id)
                                                    }}
                                                    onContextMenu={e => {
                                                        e.preventDefault()
                                                    }}
                                                    data-id={d.id}
                                                    data-download-pdf-url={d?.download_pdf_url || ''}
                                                    onMouseDown={e => {
                                                        handlePositionMenuOptions(e, d)
                                                    }}
                                                >
                                                    <td>
                                                        <Tooltip
                                                            title={`Маркиране на ${d.type?.translation?.name.toLowerCase()}`}
                                                        >
                                                            <Checkbox
                                                                id={`document-cb-${d.id}`}
                                                                checked={localState.selected[d.id] ? true : false}
                                                                color="primary"
                                                                onClick={() => {
                                                                    handleSelect(d)
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    </td>
                                                    <td
                                                        onClick={() => {
                                                            handleAddNewSubtab(d, "preview")
                                                        }}
                                                    >
                                                        <div style={{ display: 'flex' }}>
                                                            <span>{d.type?.translation?.name}</span>
                                                            {getCurrentlyEditingUsers(d.id).map(user =>
                                                                <Tooltip
                                                                    key={'u-' + user.id}
                                                                    title={`Този документ в момента се редактира от ${user.name}`}
                                                                >
                                                                    <img src={user.avatar} alt="" width={20} style={{ marginLeft: '5px' }} />
                                                                </Tooltip>
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td onClick={e => handleAddNewSubtab(d, "preview")}>
                                                        {d.no}
                                                    </td>
                                                    <td onClick={e => handleAddNewSubtab(d, "preview")}>
                                                        {d.date_dmy}
                                                    </td>
                                                    <td onClick={e => handleAddNewSubtab(d, "preview")}>
                                                        {d?.customerinfo?.name || ''}
                                                    </td>
                                                    {isCompanyVatRegistered() ?
                                                        <>
                                                            <td onClick={e => handleAddNewSubtab(d, "preview")} className="text-right price">
                                                                {splitLongNumbers(d.net_price_formatted)} {d?.currency?.name}
                                                            </td>
                                                            <td onClick={e => handleAddNewSubtab(d, "preview")} className="text-right">
                                                                {splitLongNumbers(d.vat_price_formatted)} {d?.currency?.name}
                                                            </td>
                                                        </>
                                                        :
                                                        <>
                                                            <td></td>
                                                            <td></td>
                                                        </>
                                                    }
                                                    <td onClick={e => handleAddNewSubtab(d, "preview")} className="text-right price">
                                                        {splitLongNumbers(d.gross_price_formatted)} {d?.currency?.name}
                                                    </td>
                                                    <td onClick={e => handleAddNewSubtab(d, "preview")} className="text-right price">
                                                        {splitLongNumbers(d.unpaid_price_formatted)} {d?.currency?.name}
                                                    </td>
                                                    <td className="text-center">
                                                        <Config
                                                            doc={d}
                                                            handleMakeUnpaid={handleMakeUnpaid}
                                                            refresh={refresh}
                                                        // appendData={appendDataFromPaymentReq}
                                                        />
                                                    </td>
                                                    {hasRepeatDocuments() ?
                                                        <td onClick={e => handleAddNewSubtab(d, "preview")} className="text-center">
                                                            {d.is_repeat
                                                                ?
                                                                <img src={checkIcon} alt="" height={12} />
                                                                :
                                                                '-'
                                                            }
                                                        </td>
                                                        :
                                                        <></>
                                                    }
                                                    <Tooltip
                                                        title={localState.successMessage}
                                                        open={localState.successTooltip === d.id ? true : false}
                                                    >
                                                        <td className="text-center">
                                                            <div className="options-holder">
                                                                <Tooltip
                                                                    title="Изпращане на документа"
                                                                >
                                                                    <Button
                                                                        className="options-menu-button ex-option"
                                                                        onClick={() => {
                                                                            handleSend(d.id)
                                                                        }}
                                                                    >
                                                                        <img src={mailIcon} />
                                                                    </Button>
                                                                </Tooltip>
                                                                <Tooltip
                                                                    title="Сваляне на документа"
                                                                >
                                                                    <DownloadButtonLayout downloadUrl={`${d.download_pdf_url}&token=${auth.getToken()}`}>
                                                                        <Button className="options-menu-button ex-option">
                                                                            <img src={downloadIcon} />
                                                                        </Button>
                                                                    </DownloadButtonLayout>
                                                                </Tooltip>
                                                                <PrintButtonLayout printUrl={`${d.print_url}&token=${auth.getToken()}`}>
                                                                    <Tooltip
                                                                        title="Принтиране на документа"
                                                                    >
                                                                        <Button
                                                                            className="options-menu-button ex-option"
                                                                        >
                                                                            <img src={printIcon} />
                                                                        </Button>
                                                                    </Tooltip>
                                                                </PrintButtonLayout>
                                                                <MenuOptions
                                                                    id={d.id}
                                                                    enableRightClick={true}
                                                                >
                                                                    <Options
                                                                        data={d}
                                                                        handleEdit={() => {
                                                                            handleEdit(d)
                                                                        }}
                                                                        activeSubtabIndex={getActiveSubtabId()}
                                                                        handleDelete={handleDelete}
                                                                        handleMakePaid={handleMakePaid}
                                                                        handleMakeUnpaid={handleMakeUnpaid}
                                                                        handleAddPayment={handleAddPayment}
                                                                        handleSend={handleSend}
                                                                        handleExport={handleExport}
                                                                        handlePreview={() => {
                                                                            handlePreview(d)
                                                                        }}
                                                                        handleConvertToProforma={handleConvertToProforma}
                                                                        handleConvertToInvoice={() => {
                                                                            handleConvertToInvoice(d.id)
                                                                        }}
                                                                        handleConvertToCreditNote={handleConvertToCreditNote}
                                                                        handleConvertToDebitNote={handleConvertToDebitNote}
                                                                        handleShowRepeatOptions={handleShowRepeatOptions}
                                                                        handleSuccessCopied={handleSuccessTooltip}
                                                                        handlePrint={handlePrint}
                                                                        handleDublicate={() => {
                                                                            handleDublicate(d)
                                                                        }}
                                                                        handleDownload={pdfUrl => {
                                                                            download(pdfUrl)
                                                                        }}
                                                                    />
                                                                </MenuOptions>
                                                            </div>
                                                        </td>
                                                    </Tooltip>
                                                </tr>
                                            )}
                                            <AdditionalOptions
                                                typeId={getActiveTabTypeId()}
                                                handleExport={handleExport}
                                                handleSend={handleSend}
                                                hasRepeatDocuments={hasRepeatDocuments()}
                                            />
                                        </tbody>
                                    </table>
                                    <Pagination
                                        page={state.filter.page}
                                        pages={documentReqData?.pages}
                                        handlePage={handlePage}
                                    />
                                </>
                        }
                    </div>
                }
            </section>
        </>
    )
}

export default Index