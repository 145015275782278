import { forwardRef, useImperativeHandle } from "react";
import { Form } from "react-bootstrap";

function Slug(props, ref) {

    useImperativeHandle(ref, () => ({
        getName: getName,
        getType: getType,
        getTitle: getTitle,
        hasSettings: hasSettings,
        getSettings: getSettings,
        getDefaultOptions: () => ({ action: 'abort' })
    }));

    const getName = () => {
        return 'Публичен URL';
    }

    const getType = () => {
        return 'slug';
    }

    const getTitle = () => {
        return null;
    }

    const hasSettings = () => true;

    const getSettings = ({ state, setConfig, createOrUpdate }) => {

        return (
            <div>
                <label>
                    Действия при дублиране:
                </label>
                <div>
                    <Form.Check
                        id={'cb-skip'}
                        type="radio"
                        label="Не записвай"
                        checked={state.options?.action === 'skip'}
                        onChange={e => setConfig('action', 'skip')}
                    />
                    <Form.Check
                        id={'cb-rename_with_index'}
                        type="radio"
                        label="Прекръсти с добавен индекс"
                        checked={state.options?.action === 'rename_with_index'}
                        onChange={e => setConfig('action', 'rename_with_index')}
                    />
                    <Form.Check
                        id={'cb-abort'}
                        type="radio"
                        label="Прекрати импорта"
                        checked={state.options?.action === 'abort'}
                        onChange={e => setConfig('action', 'abort')}
                    />
                </div>
            </div>
        )
    }

    return getName();
}

export default forwardRef(Slug)