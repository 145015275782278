import { useState, useImperativeHandle, forwardRef, useEffect } from 'react'
import { Modal, Button, Row, Col, FloatingLabel, Form, Table } from 'react-bootstrap';
import { createPortal } from 'react-dom';

import SaveButton from 'components/misc/Button'
import { CaretDownFill, CaretRightFill, CaretUpFill } from 'react-bootstrap-icons';
import Api from 'helpers/Api';
import { useAppContext } from 'providers/App';

function Columns(props, ref) {

    const app = useAppContext();

    const defaultData = {
        sortKey: '',
        sortDir: '',
        limit: 25,
        selected: [],
        withDetails: false,
    }

    const [state, setState] = useState({
        show: false,
        params: null,
        data: defaultData,
        onSuccess: null,
        onClose: null,
        onEntering: null,
        onExiting: null,
    });

    useImperativeHandle(ref, () => ({
        open: () => {
            handleShow();
        },
        close: () => {
            handleClose();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        },
    }));

    useEffect(() => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                sortKey: props.sortKey,
                sortDir: props.sortDir,
                limit: props.limit
            }
        }));
    }, [props.sortKey, props.sortDir, props.limit]);

    useEffect(() => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                selected: props.selected.length ? props.selected : (Object.keys(props.columns) || [])
            }
        }));
    }, [props.selected]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = () => {
        show();
    }


    const handleClose = () => {
        hide();
    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        let url = 'set-table-options';

        let data = new FormData(e.target);

        data.append('key', props.tableKey);
        data.append('sort', state.data.sortKey);
        data.append('order', state.data.sortDir);

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess()
            }

            hide();
        }).catch(error => {
            const _err = error.response;

            if (_err && _err.status && _err.status === 422) {
                for (const [key, messages] of Object.entries(_err.data.errors)) {
                    app.showError(messages[0] || messages);
                }
            } else {
                app.showError();
            }
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: value
            }
        }))
    }

    const handleCheckboxChange = id => {
        if (isChecked(id)) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    selected: prev.data.selected.filter(s => s !== id)
                }
            }));
        } else {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    selected: prev.data.selected.concat(id)
                }
            }));
        }
    }

    const isChecked = (id) => {
        return state.data.selected.indexOf(id) > -1;
    }

    const toggleDetails = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                withDetails: !prev.data.withDetails
            }
        }));
    }

    const toggleSort = id => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                sortKey: id,
                sortDir: prev.data.sortKey === id ? (prev.data.sortDir === 'asc' ? 'desc' : 'asc') : 'asc'
            }
        }))
    }

    const isSortable = id => {
        if (typeof props.sortableColumns === 'undefined') {
            return true;
        }

        return props.sortableColumns.indexOf(id) > -1;
    }

    return createPortal(
        <Modal size={props.description ? 'lg' : 'md'} centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
            <form className="columns-options" onSubmit={handleSave}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Настройки на колони
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table className="no-shadow" borderless>
                        <tbody>
                            {props.columns && Object.entries(props.columns).map(column =>
                                <tr key={column[0]} >
                                    <td style={{ width: '30px' }}>
                                        {isChecked(column[0]) && isSortable(column[0]) &&
                                            <div className="sort" onClick={e => toggleSort(column[0])}>
                                                {state.data.sortKey === column[0]
                                                    ?
                                                    state.data.sortDir === 'asc'
                                                        ?
                                                        <CaretUpFill />
                                                        :
                                                        <CaretDownFill />
                                                    :
                                                    ''
                                                }
                                            </div>
                                        }
                                    </td>
                                    <td>
                                        <div style={{ display: 'flex' }}>
                                            <Form.Check
                                                type="switch"
                                                name="columns[]"
                                                // label={`${column[1]}`}
                                                value={column[0]}
                                                checked={isChecked(column[0])}
                                                onChange={e => handleCheckboxChange(column[0])}
                                            />
                                            <label>
                                                {column[1]}
                                                {props.description?.[column[0]]
                                                    ?
                                                    <i style={{ fontWeight: '100' }}> - {props.description?.[column[0]]}</i>
                                                    :
                                                    ''
                                                }
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                            )}

                            {props.details && Object.entries(props.details).length > 0 &&
                                <>
                                    <tr>
                                        <td colSpan={2}>
                                            <div style={{
                                                backgroundColor: '#eee',
                                                borderTop: '1px solid #999',
                                                borderBottom: '1px solid #999',
                                                padding: '3px',
                                                cursor: 'pointer',
                                            }}
                                                onClick={toggleDetails}
                                            >
                                                {state.data.withDetails
                                                    ?
                                                    <CaretDownFill />
                                                    :
                                                    <CaretRightFill />
                                                } Характеристики ({Object.entries(props.details).length} бр)
                                            </div>
                                        </td>
                                    </tr>

                                    {Object.entries(props.details).map(column =>
                                        <tr key={column[0]} style={{ display: state.data.withDetails ? 'table-row' : 'none' }}>
                                            <td style={{ width: '30px' }}>
                                                {isChecked(column[0]) && isSortable(column[0]) &&
                                                    <div className="sort" onClick={e => toggleSort(column[0])}>
                                                        {state.data.sortKey === column[0]
                                                            ?
                                                            state.data.sortDir === 'asc'
                                                                ?
                                                                <CaretUpFill />
                                                                :
                                                                <CaretDownFill />
                                                            :
                                                            ''
                                                        }
                                                    </div>
                                                }
                                            </td>
                                            <td>
                                                <Form.Check
                                                    type="switch"
                                                    name="columns[]"
                                                    label={column[1]}
                                                    value={column[0]}
                                                    checked={isChecked(column[0])}
                                                    onChange={e => handleCheckboxChange(column[0])}
                                                />
                                            </td>
                                        </tr>
                                    )}
                                </>
                            }
                        </tbody>
                    </Table>

                    <div style={{
                        backgroundColor: '#eee',
                        borderTop: '1px solid #999',
                        borderBottom: '1px solid #999',
                        padding: '3px',
                        // display: 'flex',
                        // justifyContent: 'center'
                        textAlign: 'center'
                    }}
                    >
                        Покажи <select name="limit" value={state.data.limit} onChange={handleInputChange}>
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                            <option value={200}>200</option>
                            <option value={500}>500</option>
                        </select> реда на страница
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Отказ
                    </Button>
                    <SaveButton
                        loading={state.loading}
                        className="save"
                    />
                </Modal.Footer>
            </form>
        </Modal>,
        document.body
    )

}

export default forwardRef(Columns);