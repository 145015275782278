//providers
import { useRegisterContext } from "./RegisterProvider"

const useCompanySettlementInputFieldHooks = () => {
    const register = useRegisterContext()
    const { setCompanyDataState } = register

    const handleCustomerSettlementChange = settlement => {
        if (!settlement) return

        const { name, municipality_id, id } = settlement
        let settlementData = {}

        if (id) {
            settlementData = {
                settlement: name,
                settlementId: id,
                municipalityId: municipality_id
            }
        } else {
            settlementData = { settlement }
        }

        setCompanyDataState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                ...settlementData,
            }
        }))
    }
    
    return {
        register,
        handleCustomerSettlementChange
    }
}

export default useCompanySettlementInputFieldHooks