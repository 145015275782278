import { forwardRef, useState } from 'react';
import { bg } from 'date-fns/locale'

import moment from 'moment';

import Info from 'components/misc/Info';
import RedditTextField from 'components/misc/RedditTextField';
// import LockedFilter from 'components/misc/LockedFIlter';


function DatePicker(props) {
    // const [startDate, setStartDate] = useState(new Date());

    const type = props.type || "date";
    const name = props.name;
    const placeholder = props.placeholder;
    const value = props.value || null;
    const onChange = props.onChange || (() => { });
    const disabled = Boolean(props.disabled);
    const isInvalid = Boolean(props.isInvalid);
    const error = props.error;
    const info = props.info;
    const size = props.size;
    const variant = props.variant || 'floatingLabel';
    const lock = props.lock;

    const inputDateFormat = type === 'datetime-local' ? 'YYYY-MM-DD HH:mm:ss' : type === 'date' ? 'YYYY-MM-DD' : type === 'time' ? 'HH:mm' : ''; // moment

    const getValue = () => {
        // console.log(value)

        let val = getValueObj();

        if (val) {
            return val.toDate();
        }

        return null;
    }

    const getValueObj = () => {
        // console.log(value)

        // return null;

        if (type === 'time') {
            if (value) {
                let date = moment();
                let time = moment(value, 'HH:mm');

                date.set({
                    hour: time.get('hour'),
                    minute: time.get('minute')
                });

                // console.log(date);

                return date;
            }
        }

        if (typeof value === String) {
            return moment(value);
        }

        if (typeof value === Date) {
            return value;
        }

        if (value) {
            return moment(value);
        }

        return null;
    }

    const handleChange = event => {
        onChange(event);
    }

    const getInput = () => {
        if (variant === 'basic') {
            return <input
                type={type}
                placeHolder={placeholder}
                name={name}
                value={getValueObj() ? getValueObj().format(inputDateFormat) : ''}
                onChange={handleChange}
                disabled={disabled}
                // error={error}
                size={size}
            />
        }

        return <RedditTextField
            size={size}
            variant="filled"
            margin="dense"
            fullWidth
            type={type}
            name={name}
            value={getValueObj() ? getValueObj().format(inputDateFormat) : ''}
            onChange={handleChange}
            label={placeholder}
            error={isInvalid}
            helperText={error}
            disabled={disabled}
        />

    }

    // console.log(getValue())

    return (
        <>
            {getInput()}
        </>
    )
}

export default DatePicker;