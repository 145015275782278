import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react'
import { createPortal } from 'react-dom';

import { useAuthDataContext } from 'providers/Auth';
import { useAppContext } from 'providers/App';
import Api from 'helpers/Api';
import Loader from 'components/misc/Loader';
import NoDataFound from 'components/misc/NoDataFound';
import { Button } from '@material-ui/core';
import moment from 'moment'

function Availability(props, ref) {

    const auth = useAuthDataContext();
    const app = useAppContext();

    const defaultData = [];

    const [state, setState] = useState({
        show: false,
        params: null,
        id: null,
        refresh: null,
        loading: false,
        data: defaultData,
        onSuccess: null,
        onClose: null,
        onEntering: null,
        onExiting: null,
    });

    useImperativeHandle(ref, () => ({
        open: (articleId) => {
            handleShow(articleId);
        },
        close: () => {
            handleClose();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        },
        onCancel: fn => {
            setState(prev => ({
                ...prev,
                onCancel: fn
            }));
        }
    }));

    useEffect(() => {
        if (!state.id) {
            return;
        }

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        Api.get('store/articles/full-availability', {
            params: {
                id: state.id,
            }
        }).then(res => {

            let rows = res.data;

            let data = {};

            rows.map(row => {
                if (!data[row.depot.store_id]) {
                    data[row.depot.store_id] = {
                        store: row.depot.store,
                        depots: [],
                        quantity: 0,
                    }
                }

                data[row.depot.store_id].depots.push({
                    depot: row.depot,
                    quantity: row.quantity
                });

                data[row.depot.store_id].quantity += row.quantity;
            });

            data = Object.values(data);

            // console.log(data);

            setState(prev => ({
                ...prev,
                data: data,
            }));
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }, [state.id, state.refresh]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = (articleId) => {
        setState(prev => ({
            ...prev,
            id: articleId,
            refresh: moment().unix(),
            data: defaultData
        }));

        show();
    }

    const handleClose = () => {
        hide(state.onClose);
    }

    const handleSave = e => {
        e.preventDefault();

        // setState(prev => ({
        //     ...prev,
        //     loading: true,
        // }));

        hide(state.onClose);
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: value
            }
        }))
    }

    return createPortal(
        <div className={`${state.show ? "visible" : ""} overlay`}>
            <div
                className={`popup-primary small ${state.show ? "show" : ""}`}
            >
                <div className="body">
                    <h3>
                        Наличност
                    </h3>
                    <br />
                    <div className="preview-article-home">
                        {state.loading
                            ?
                            <Loader />
                            :
                            state.data.length === 0
                                ?
                                <NoDataFound>
                                    Няма информация за наличността
                                </NoDataFound>
                                :
                                <table className="type-outer">
                                    <thead>
                                        <tr>
                                            <th>
                                                Склад
                                            </th>
                                            <th>
                                                Група
                                            </th>
                                            <th className="text-right">
                                                Наличност
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.data.map(row =>
                                            <React.Fragment key={row.store.id}>
                                                <tr style={{ background: 'cornsilk' }}>
                                                    <td colSpan={3}>
                                                        {row.store?.translation?.name || row.store.name} - <i>{row.depots.length} ред(а)</i>
                                                    </td>
                                                </tr>
                                                {row.depots.map(depot =>
                                                    <tr key={depot.depot.id}>
                                                        <td>
                                                            {depot.depot.name}
                                                        </td>
                                                        <td>
                                                            {depot.depot.group?.name}
                                                        </td>
                                                        <td className={`text-right ${depot.quantity < 0 && 'negative-value'}`}>
                                                            {depot.quantity}
                                                        </td>
                                                    </tr>
                                                )}
                                                <tr className="total-secondary">
                                                    <td colSpan={2}>
                                                        Общо за магазина
                                                    </td>
                                                    <td className={`text-right ${row.quantity < 0 && 'negative-value'}`}>
                                                        {row.quantity}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )}
                                    </tbody>
                                </table>
                        }
                    </div>
                </div>

                <div className="footer">
                    <Button className="cancel" onClick={handleClose}>
                        Затвори
                    </Button>
                </div>
            </div>
        </div>,
        document.body
    )

}

export default forwardRef(Availability);