import { useState, useEffect } from 'react'
import Api from 'helpers/Api';
import Loader from 'components/misc/Loader';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import Refs from 'Refs';
import { useAppContext } from 'providers/App';
import { useAuthDataContext } from 'providers/Auth';
import { Button } from '@material-ui/core';

import 'assets/scss/fairnet.scss'

function Index(props) {

    const { show, hide } = props;

    const app = useAppContext();
    const auth = useAuthDataContext();
    const navigate = useHistory();

    const [state, setState] = useState({
        loading: true,
        data: {

        },
    });

    useEffect(() => {
        if (!props.id) {
            return;
        }

        setState(prev => ({
            ...prev,
            loading: true
        }));

        Api.get('store/orders/fairnet/show', {
            params: {
                id: props.id
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data,
                loading: false,
            }));
        });
    }, [props.id]);

    const showClient = () => {
        props.showClient(state.data.client_id);
    }

    return (
        <>
            {state.loading
                ?
                <Loader />
                :
                <>
                    <div className="preview-fairnet-configurator">
                        <div className="heading">
                            <span>1</span>
                            <div className="right">
                                <b>
                                    Лична информация
                                </b>
                            </div>
                        </div>

                        <div className="preview-field">
                            <ul>
                                <li>
                                    {state.data.name}
                                </li>
                                <li>
                                    {state.data.company_name}
                                </li>
                                <li>
                                    {state.data.phone}
                                </li>
                                <li>
                                    {state.data.email}
                                </li>
                                <li>
                                    {state.data.website}
                                </li>
                            </ul>
                            <div className="message">
                                <b>
                                    Описание
                                </b>
                                <p>
                                    {state.data.description}
                                </p>
                            </div>
                        </div>

                        <div className="heading">
                            <span>2</span>
                            <div className="right">
                                <b>
                                    Вид на щанда
                                </b>
                            </div>
                        </div>

                        <div className="preview-field">
                            <div className="mid">
                                <h6>
                                    {state.data.stand}
                                </h6>
                                <b>
                                    Тип
                                </b>
                                <p>
                                    {state.data.stand_type}
                                </p>
                            </div>
                            <ul className="right">
                                <li>
                                    <b>
                                        Площ
                                    </b>
                                    <p>
                                        {state.data.area || '-'} кв.м.
                                    </p>
                                </li>
                                <li>
                                    <b>
                                        Лице
                                    </b>
                                    <p>
                                        {state.data.surface || '-'} м.
                                    </p>
                                </li>
                                <li>
                                    <b>
                                        Дълбочина
                                    </b>
                                    <p>
                                        {state.data.depth || '-'} м.
                                    </p>
                                </li>
                            </ul>
                        </div>

                        <div className="heading">
                            <span>3</span>
                            <div className="right">
                                <b>
                                    Обзавеждане на щанда
                                </b>
                            </div>
                        </div>

                        <div className="preview-field">
                            <div className="row">
                                {state.data.chairs?.length &&
                                    <div className="col">
                                        <h6>
                                            Столове
                                        </h6>
                                        <ul>
                                            {state.data.chairs.map((item, i) =>
                                                <li key={i}>
                                                    <img src={item.image} alt="" />
                                                    <div className="right">
                                                        <b>
                                                            {item.name}
                                                        </b>
                                                        <p>
                                                            {item.quantity} бр.
                                                        </p>
                                                    </div>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                }
                                {state.data.tables?.length &&
                                    <div className="col">
                                        <h6>
                                            Маси
                                        </h6>
                                        <ul>
                                            {state.data.tables.map((item, i) =>
                                                <li key={i}>
                                                    <img src={item.image} alt="" />
                                                    <div className="right">
                                                        <b>
                                                            {item.name}
                                                        </b>
                                                        <p>
                                                            {item.quantity} бр.
                                                        </p>
                                                    </div>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                }
                                {state.data.desks?.length &&
                                    <div className="col">
                                        <h6>
                                            Инфодеск
                                        </h6>
                                        <ul>
                                            {state.data.desks.map((item, i) =>
                                                <li key={i}>
                                                    <img src={item.image} alt="" />
                                                    <div className="right">
                                                        <b>
                                                            {item.name}
                                                        </b>
                                                        <p>
                                                            {item.quantity} бр.
                                                        </p>
                                                    </div>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                }
                                {state.data.storages?.length &&
                                    <div className="col">
                                        <h6>
                                            Склад
                                        </h6>
                                        <ul>
                                            {state.data.storages.map((item, i) =>
                                                <li key={i}>
                                                    <img src={item.image} alt="" />
                                                    <div className="right">
                                                        <b>
                                                            {item.name}
                                                        </b>
                                                        <p>
                                                            {item.quantity} кв.м.
                                                        </p>
                                                    </div>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                }
                                {state.data.carpets?.length &&
                                    <div className="col">
                                        <h6>
                                            Мокет
                                        </h6>
                                        <ul>
                                            {state.data.carpets.map((item, i) =>
                                                <li key={i}>
                                                    <img src={item.image} alt="" />
                                                    <div className="right">
                                                        <b>
                                                            {item.name}
                                                        </b>

                                                    </div>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="heading">
                            <span>4</span>
                            <div className="right">
                                <b>
                                    Брандиране на щанда
                                </b>
                            </div>
                        </div>

                        <div className="preview-field">
                            <div className="row">
                                <div className="col">
                                    <b>
                                        Изписване на името на фирмата
                                    </b>
                                    <p>
                                        {state.data.brand_name}
                                    </p>
                                </div>
                                <div className="col">
                                    <b>
                                        Прикачени файлове
                                    </b>
                                    <p>
                                        {state.data.files.map((file, i) =>
                                            <a key={i} href={file.url} target="_blank">{file.name}</a>
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default Index;