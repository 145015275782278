import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';

function Options(props) {
    return (
        <>
            <MenuItem onClick={e => props.handleEdit(props.data.id)}>
                Редакция
            </MenuItem>
            <MenuItem onClick={e => props.handleDelete(props.data.id)}>
                Изтриване
            </MenuItem>
        </>
    )
}

export default Options;