import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useLocation, useHistory, Link } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'

//components
import Layout from 'components/Layout'

//helpers
import Api from 'helpers/Api'
import { useQuery } from 'helpers/Url'

//MUI components
import Alert from '@material-ui/lab/Alert'
import { Button } from '@material-ui/core'

//misc
import Pagination from 'components/misc/Pagination'
import MenuOptions from 'components/misc/MenuOptions'
import NoSelectedCompany from 'components/misc/NoSelectedCompany'
import PaginationMini from 'components/misc/PaginationMini'

//partials
import Options from './partials/Options'
import Filter from './partials/Filter'
import AddOrEdit from './partials/AddOrEdit'

//modals
import Question from 'components/modals/Question'

//providers
import { useAuthDataContext } from 'providers/Auth'
import moment from 'moment'

let timeout

function Index(props, ref) {

    const location = useLocation()
    const history = useHistory()
    const query = useQuery()
    const auth = useAuthDataContext()

    const addOrEditModalRef = useRef(null)
    const deleteModalRef = useRef(null)

    const [state, setState] = useState({
        loading: true,
        refresh: false,
        setFilter: false,
        data: [],
        pages: 0,
        total: 0,
        filter: {
            page: 1,
        },
    })

    useImperativeHandle(ref, () => ({
        add: () => {
            handleAdd()
        },
        refresh: () => {
            refresh(true)
        }
    }))

    useEffect(() => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: query.get('page') || 1,
                start_date: query.get('start_date') || '',
                end_date: query.get('end_date') || '',
            },
            refresh: moment().unix()
        }))
    }, [location.search])

    useEffect(() => {
        if (state.setFilter) {
            Object.entries(state.filter).map(filter => {
                // if (filter[1]) {
                query.set(filter[0], filter[1])
                // }
            })

            history.push('?' + query.toString())
        }

    }, [state.setFilter])

    useEffect(() => {
        if (state.refresh) {
            loadData()
        }
    }, [state.refresh])

    const loadData = () => {

        loading(true)

        let url = 'expenses/all?resource=1'

        Object.entries(state.filter).map(filter => {
            url += (url.indexOf('?') > -1 ? '&' : '?') + filter[0] + '=' + filter[1]
        })

        Api.get(url)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data.items,
                    total: res.data.total,
                    pages: res.data.pages
                }))
            })
            .finally(() => {
                loading(false)
            })
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }))
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: reset ? 1 : prev.filter.page
            },
            refresh: moment().unix()
        }))
    }

    const handleAdd = () => {
        let modal = addOrEditModalRef.current

        modal.add()

        modal.onSuccess(() => {
            refresh(true)
        })
    }

    // update
    const handleEdit = id => {
        let modal = addOrEditModalRef.current

        modal.edit(id)

        modal.onSuccess(() => {
            refresh(false)
        })
    }

    // delete
    const handleDelete = id => {
        let modal = deleteModalRef.current

        modal.open()
        modal.onSuccess(() => {
            handleDestroy(id)
        })
    }

    const handleDestroy = id => {
        Api.post('expenses/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                refresh()
            }
        })
    }

    // Search
    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout)

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },
        }))

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                setFilter: moment().unix()
            }))
        }, delay)
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            setFilter: moment().unix()
        }))
    }

    return (
        <>
            <AddOrEdit
                ref={addOrEditModalRef}
                companyId={state.filter.company_id}
            />

            <Question
                ref={deleteModalRef}
                mainMessage="Потвърждавам изтриването"
                secondaryMessage="Изтритите от Вас данни не могат да бъдат възстановени."
                agreeBtnText="Изтриване"
                agreeBtnClass="remove"
            />


            {/* <div id="head" className="row space-between">
                <div className="title">
                    Разходи
                </div>
                <div
                    className="row right"
                    style={{
                        width: 'fit-content'
                    }}
                >
                    <Button
                        className="add"
                        onClick={handleAdd}
                    >
                        Добави
                    </Button>

                    <Button
                        className="normal"
                        onClick={() => {
                            history.push('/settings?menu=3&submenu=3_2')
                        }}
                        style={{ marginLeft: '10px' }}
                    >
                        Списък с категории
                    </Button>
                </div>
            </div> */}


            {/* // <div className="panel"> */}
            <>
                <div className="filter-holder">
                    <Filter
                        filter={state.filter}
                        handleSearch={handleSearch}
                    />
                    <PaginationMini
                        isLoading={state.loading}
                        page={state.filter.page}
                        pages={state.pages}
                        handlePage={handlePage}
                    />
                </div>
                {state.loading
                    ?
                    <Skeleton count={5} height={60} />
                    :
                    state.data.length === 0
                        ?
                        <Alert severity="error">
                            Няма намерена информация!
                        </Alert>
                        :
                        <>
                            <table className="type-outer">
                                <thead>
                                    <tr>
                                        <th>Дата</th>
                                        <th>Разход</th>
                                        <th>Контрагент</th>
                                        {/* <th>ДДС разход</th> */}
                                        {/* <th className="text-right">Сума нето</th> */}
                                        {/* <th className="text-right">ДДС</th> */}
                                        <th className="text-right">Сума бруто</th>
                                        <th className="text-center">Файлове</th>
                                        <th className="text-center">Опции</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.data.map(c =>
                                        <tr
                                            key={'c-' + c.id}
                                            onClick={e => {
                                                if (e.target) {
                                                    if (e.target.classList?.contains('MuiButtonBase-root') || e.target.getAttribute('aria-hidden') === 'true') return
                                                }

                                                handleEdit(c.id)
                                            }}
                                        >
                                            <td className='date'>
                                                {c.date_dmy}
                                            </td>
                                            <td className='product-with-cat'>
                                                {c.category?.name || ''} {c.category?.name && c.product?.name ? '→' : ''} {c.product?.name || '-'}
                                            </td>
                                            <td>
                                                {c?.contragent?.info?.name || '-'}
                                            </td>
                                            {/* <td>
                                                {c.vat_expense ? 'da' : ''}
                                            </td> */}
                                            {/* <td className="text-right price">
                                                {c.net_formatted} {c.currency?.name}
                                            </td> */}
                                            {/* <td className="text-right">
                                                {Number(c.vat_perc)}% ({c.vat_formatted} {c.currency?.name})
                                            </td> */}
                                            <td className="text-right price">
                                                {c.gross_formatted} {c.currency?.name}
                                            </td>
                                            <td className="text-center">
                                                {c.files_count}
                                            </td>
                                            <td className="text-center">
                                                <MenuOptions>
                                                    <Options
                                                        data={c}
                                                        handleEdit={handleEdit}
                                                        handleDelete={handleDelete}
                                                    />
                                                </MenuOptions>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                            <Pagination
                                page={state.filter.page}
                                pages={state.pages}
                                handlePage={handlePage}
                            />
                        </>
                }
            </>
            {/* </div> */}

        </>
    )
}

export default forwardRef(Index)