import { useState, useImperativeHandle, forwardRef, useEffect, useRef } from 'react'
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import Api from 'helpers/Api';
import { useValidation } from 'helpers/Validation';
import Error from 'components/modals/Error';
import { useHistory } from 'react-router-dom';

function NewStoreMove(props, ref) {

    const navigate = useHistory();

    const defaultData = {
        type: 'new'
    };

    const [state, setState] = useState({
        show: false,
        params: null,
        id: null,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onClose: null,
    });

    const [moves, setMoves] = useState([]);

    const [validations, setValidations] = useValidation();

    const errorModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        open: (id) => {
            handleShow(id);
        },
        close: () => {
            handleClose();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    useEffect(() => {
        if (!state.show) {
            return;
        }

        Api.get('storemoves/opened')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setMoves(res.data);

                    if (res.data[0]) {
                        setState(prev => ({
                            ...prev,
                            data: {
                                ...prev.data,
                                move_id: res.data[0].id
                            }
                        }));
                    }
                }
            });
    }, [state.show]);

    const handleShow = (id) => {
        setState(prev => ({
            ...prev,
            show: true,
            id: id,
        }));
    }

    const handleClose = () => {
        setState(prev => ({
            ...prev,
            show: false,
            data: defaultData
        }));

        setValidations(null);

        if (typeof state.onClose === 'function') {
            state.onClose();
        }
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: value
            }
        }));
    }

    const handleSave = (e) => {
        e.preventDefault();

        if (state.data.type === 'new') {
            navigate.push('/storage/moves/new?from_move_id=' + state.id);
        }

        if (state.data.type === 'current') {
            navigate.push('/storage/moves/' + state.data.move_id + '/edit?from_move_id=' + state.id);
        }
    }

    const div = {
        width: '100%',
        backgroundColor: '#dde7fb',
        padding: '5px',
        borderRadius: '4px'

    }

    const label = {
        cursor: 'pointer'
    }

    return (
        <>
            <Modal centered show={state.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Избор на операция
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col className="">
                            <div style={div}>
                                <Form.Check
                                    type="radio"
                                    name="type"
                                    value="current"
                                    checked={state.data.type === 'current'}
                                    onChange={handleInputChange}
                                    label="Добави към текуща операция"
                                    id="current_operation"
                                />
                            </div>

                            <div style={{
                                padding: '5px'
                            }}>
                                <Form.Select
                                    name="move_id"
                                    value={state.data?.move_id || ''}
                                    onChange={handleInputChange}
                                    // isInvalid={Boolean(validations?.cashdesk_id)}
                                    size="sm"
                                    disabled={state.data.type !== 'current'}
                                >
                                    {moves.map(o =>
                                        <option key={o.id} value={o.id}>
                                            #{o.id} {o.fromdepot.store?.name}/{o.fromdepot?.name} -&gt; {o.todepot.store?.name}/{o.todepot?.name} ({o.articles_count} арт.)
                                        </option>
                                    )}

                                </Form.Select>
                            </div>

                            <div style={div}>
                                <Form.Check
                                    type="radio"
                                    name="type"
                                    value="new"
                                    checked={state.data.type === 'new'}
                                    onChange={handleInputChange}
                                    label="Нова операция"
                                    id="new_operation"
                                />
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Отказ
                    </Button>
                    <Button variant="dark" onClick={handleSave}>
                        Напред
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default forwardRef(NewStoreMove);