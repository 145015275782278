import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

//helpers
import Api from 'helpers/Api'
import { useQuery } from 'helpers/Url'

//misc
import PageLoader from 'components/misc/PageLoader'

function Index() {
    const query = useQuery()
    const history = useHistory()

    useEffect(() => {
        const code = query.get('code')
        const state = query.get('state')

        Api.post('e-services/slack/connect', {
            code,
            state
        })
        .then(res => {
            if (res.data.success) history.replace('/integrations/success')
            else history.replace('/integrations/fail')
        })
        .catch(() => {
            history.replace('/integrations/fail')
        })
    }, [])

    return (
        <PageLoader show />
    )
}

export default Index