import { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react'
import Api from 'helpers/Api'
import { useQuery } from 'helpers/Url'
import { useLocation, useHistory, Link, useParams } from 'react-router-dom'
import { Table, FloatingLabel, Form, Row, Col, Container, InputGroup } from 'react-bootstrap'
import { ArrowLeftCircle, TrashFill, ArrowLeftRight, Upload, Check2Square } from 'react-bootstrap-icons'
import { useNestedState } from 'helpers/NestedState'
import { useCurrencyContext } from 'providers/Currency'
import { useValidation } from 'helpers/Validation'
import { useAuthDataContext } from 'providers/Auth'
import { cloneDeep } from 'lodash'

import Question from 'components/modals/Question'
import Layout from 'components/Layout'

//misc
import Loader from 'components/misc/Loader'
import moment from 'moment'
import Autocomplete from 'components/misc/Dropdown'
import SaveButton from 'components/misc/Button'
import { isNumeric } from 'helpers/Price'
import Info from 'components/misc/Info'
import Error from 'components/modals/Error'
import DatePicker from 'components/partials/filter/DatePicker'
import Import from 'components/modals/Import'
import { TYPES } from 'constants/imports'
import Refs from 'Refs'
import { Button, MenuItem, InputAdornment, Menu } from '@material-ui/core'
import RedditTextField from 'components/misc/RedditTextField'
import RedditSelect from 'components/misc/RedditSelect'

//images
import dropArrowIcon from 'assets/img/app/icons/drop-arrow-white.png'
import moveArrowIcon from 'assets/img/storage/icons/move-arrow.png'
import { showPdf } from '../helpers'

let timeout

function AddOrEdit({ }, ref) {

    const params = useParams()
    const location = useLocation()
    const navigate = useHistory()
    const query = useQuery()

    const auth = useAuthDataContext()
    const currencies = useCurrencyContext()

    const user = auth.getUser()

    const INITIAL_STATE = {
        loading: false,
        loadingBase: false,
        loadingClose: false,
        loadingSend: false,
        loadingCancel: false,
        loadingAccept: false,
        dataLoading: false,
        data: {
            rows: [{}],
            allow_modify: user.permission('storemoves_edit') ? true : false,
            allow_change_depots: user.permission('storemoves_edit') ? true : false,
            allow_close: user.permission('storemoves_edit') ? true : false,
            allow_send: user.permission('storemoves_between_barsys') ? true : false,
        },
        timeout: null,
        saveButtonMenu: null,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
        onClose: null,
        addNote: false,
        show: false
    }
    const [state, setState] = useNestedState(INITIAL_STATE)

    const [availability, setAvailability] = useState([])

    const [depots, setDepots] = useState([])

    const [validations, setValidations] = useValidation()

    const formRef = useRef(null)
    const saveAndCloseModalRef = useRef(null)
    const saveAndSendModalRef = useRef(null)
    const errorModalRef = useRef(null)
    const importModalRef = useRef(null)

    useImperativeHandle(ref, () => ({
        add: () => {
            add()
        },
        edit: (id) => {
            edit(id)
        },
        hide: () => {
            hide()
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }))
        }
    }))

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }))
    }

    const hide = () => {
        setState(() => ({ ...INITIAL_STATE }))
    }

    const add = () => {
        show()
    }

    const edit = (id) => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: moment().unix()
        }))

        show()
    }

    useEffect(() => {
        Api.get('store/storemoves/depots')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setDepots(res.data)
                }
            })
    }, [auth.getUser().getStoreId()])

    useEffect(() => {
        if (state.id) {
            loadData()
        }
    }, [state.id, auth.getUser().getStoreId()])

    // зареждане на данни от поръчка/сметка
    useEffect(() => {
        if (state.id) {
            return
        }

        // от зареждане
        if (query.get('from_load_id')) {
            loadDataFromStoreLoad()
        }

        // от прехвърляне
        if (query.get('from_move_id')) {
            loadDataFromStoreMove()
        }

    }, [location])

    useEffect(() => {
        if (state.data.rows.length === 0) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    rows: [{}]
                }
            }))
        }
    }, [state.data.rows])

    useEffect(() => {
        getArticleAvailability()
    }, [state.data.from_depot_id, state.data.to_depot_id])



    const loadData = () => {

        loading(true)

        let url = 'store/storemoves/show-edit-data'

        Api.get(url, {
            params: {
                id: state.id,
                move_id: query.get('from_move_id'),
            }
        }).then(res => {
            handleResponse(res)
        }).catch(err => {
            handleError(err)
        }).finally(() => {
            loading(false)
        })
    }

    const loadDataFromStoreLoad = () => {

        loading(true)

        let url = 'store/storemoves/from-storeload'

        Api.get(url, {
            params: {
                load_id: query.get('from_load_id')
            }
        }).then(res => {
            handleResponse(res)
        }).catch(err => {
            handleError(err)
        }).finally(() => {
            loading(false)
        })
    }

    const loadDataFromStoreMove = () => {

        loading(true)

        let url = 'store/storemoves/from-storemove'

        Api.get(url, {
            params: {
                move_id: query.get('from_move_id')
            }
        }).then(res => {
            handleResponse(res)
        }).catch(err => {
            handleError(err)
        }).finally(() => {
            loading(false)
        })
    }

    const handleResponse = res => {

        let data = res.data

        let rows = data.rows

        rows = rows.map(row => {
            //

            return row
        })

        data.rows = rows

        setState(prev => ({
            ...prev,
            data: data,
            addNote: !!data.description
        }))
    }

    const handleError = error => {
        console.log(error)

        const _err = error.response

        if (_err && _err.status) {
            if (_err.status === 422) {
                setValidations(_err.data.errors)
            } else {
                let modal = errorModalRef.current

                if (modal) {
                    modal.open(_err.data.error || _err.data.message)

                    modal.onClose(() => {
                        navigate.goBack()
                    })
                }
            }
        }
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            dataLoading: Boolean(loading)
        }))
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleCheckboxChange = e => {
        const name = e.target.name

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }))
    }

    const save = (close = false, send = false) => {
        if (state.loading) {
            return
        }

        setValidations(null)

        let url = state.id ? 'store/storemoves/edit' : 'store/storemoves/add'

        let form = formRef.current

        let data = new FormData(form)

        if (state.id) {
            data.append('id', state.id)
        }

        if (close) {
            data.append('close', 1)
        }

        if (send) {
            data.append('send', 1)
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            handleClose()
            state.onSuccess()

            showPdf(res.data.id, auth.getToken());
        }).catch(error => {
            handleSaveError(error)
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
                loadingBase: false,
                loadingClose: false,
                loadingSend: false,
            }))
        })
    }

    const handleSave = e => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            loading: true,
            loadingBase: true,
        }))

        save(false)
    }

    const handleSaveAndClose = e => {
        e.preventDefault()

        let modal = saveAndCloseModalRef.current

        if (modal) {
            modal.open()

            modal.onSuccess(() => {
                setState(prev => ({
                    ...prev,
                    loading: true,
                    loadingClose: true,
                }))

                save(true)
            })
        }

    }

    const handleSaveAndSend = e => {
        e.preventDefault()

        let modal = saveAndSendModalRef.current

        if (modal) {
            modal.open()

            modal.onSuccess(() => {
                setState(prev => ({
                    ...prev,
                    loading: true,
                    loadingSend: true,
                }))

                save(false, true)
            })
        }

    }

    const handleSaveReceived = e => {
        e.preventDefault()

        if (state.loading) {
            return
        }

        setState(prev => ({
            ...prev,
            loading: true,
            loadingBase: true,
        }))

        setValidations(null)

        let url = 'store/storemoves/edit-from-receiver'

        let form = formRef.current

        let data = new FormData(form)

        data.append('id', state.id)

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            handleResponse(res)
        }).catch(error => {
            handleSaveError(error)
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
                loadingBase: false,
                loadingClose: false,
                loadingSend: false,
            }))
        })
    }

    const handleCancel = e => {
        e.preventDefault()

        let modal = Refs.getInstance().getRef('question')

        modal.open('Сигурни ли сте?')
        modal.onSuccess(() => {
            cancel()
        })
    }

    const cancel = () => {
        setState(prev => ({
            ...prev,
            loading: true,
            loadingCancel: true,
        }))

        Api.post('store/storemoves/cancel', {
            id: state.data.id
        }).then(res => {
            if (res.data.success) {
                handleClose()
                state.onSuccess()
            }
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
                loadingCancel: false,
            }))
        })
    }

    const handleAccept = e => {
        e.preventDefault()

        let modal = Refs.getInstance().getRef('question')

        modal.open('Сигурни ли сте?')
        modal.onSuccess(() => {
            accept()
        })
    }

    const accept = () => {
        setState(prev => ({
            ...prev,
            loading: true,
            loadingAccept: true,
        }))

        let form = formRef.current

        let data = new FormData(form)

        data.append('id', state.id)

        Api.post('store/storemoves/accept', data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (res.data.success) {
                handleClose()
                state.onSuccess()
            }
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
                loadingAccept: false,
            }))
        })
    }

    const handleSaveError = error => {
        const _err = error.response

        if (_err && _err.status) {
            if (_err.status === 422) {
                setValidations(_err.data.errors)
            } else {
                let modal = errorModalRef.current

                if (modal) {
                    modal.open(_err.data.error || _err.data.message)
                }
            }
        }
    }

    const handleAddRow = e => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: prev.data.rows.concat({})
            }
        }))
    }

    const handleRemoveRow = (e, index) => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: prev.data.rows.filter((row, i) => i !== index)
            }
        }))
    }

    const handleAcceptRow = (e, index) => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: Object.values({
                    ...prev.data.rows,
                    [index]: {
                        ...prev.data.rows[index],
                        received_quantity: prev.data.rows[index].quantity
                    }
                })
            }
        }))
    }

    const handleChangeArticle = (data, index) => {

        let row = state.data.rows[index]

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: Object.values({
                    ...prev.data.rows,
                    [index]: {
                        ...prev.data.rows[index],
                        article: data,
                        article_id: data.id,
                        quantity: 1
                    }
                })
            }
        }))

        getArticleAvailability(data.id)

    }

    const getArticleAvailability = (id) => {

        let ids = []

        if (id) {
            ids = Array.isArray(id) ? id : [id]
        } else {
            ids = state.data.rows.map(r => r.article_id).filter(r => r)
        }

        let fromDepotId = state.data.from_depot_id
        let toDepotId = state.data.to_depot_id

        let depots = [fromDepotId, toDepotId]

        let availabilityData = {}

        Api.get('store/articles/availability', {
            params: {
                id: ids,
                depot_id: depots
            }
        }).then(res => {
            res.data.map(data => {
                if (!availabilityData[data.article_id]) {
                    availabilityData[data.article_id] = {
                        quantity_from_depot_before: null,
                        quantity_to_depot_before: null
                    }
                }

                if (Number(data.depot_id) === Number(fromDepotId)) {
                    availabilityData[data.article_id].quantity_from_depot_before = data.quantity
                }

                if (Number(data.depot_id) === Number(toDepotId)) {
                    availabilityData[data.article_id].quantity_to_depot_before = data.quantity
                }

            })

            setAvailability(prev => ({
                ...prev,
                ...availabilityData
            }))

        })

    }

    // при смяна на количеството 
    const handleQtyChange = (e, index) => {
        handleInputChange(e)

        let value = e.target.value

    }

    const calcQuantityFromDepotAfter = (quantityBefore, quantity) => {
        return (parseFloat(quantityBefore) || 0) - parseFloat(quantity)
    }

    const calcQuantityToDepotAfter = (quantityBefore, quantity) => {
        return (parseFloat(quantityBefore) || 0) + parseFloat(quantity)
    }

    const getTotalQty = () => {
        let total = 0

        state.data.rows.map(row => {
            total += parseFloat(row.quantity || 0)
        })

        return total
    }

    const handleFromDepotChange = e => {
        let id = e.target.value

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                from_depot_id: id,
            }
        }))
    }

    const handleToDepotChange = e => {
        let id = e.target.value

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                to_depot_id: id,
            }
        }))
    }

    const getFromDepots = () => {
        let data = cloneDeep(depots).filter(d => Number(d.store_id) === Number(auth.getUser().getStoreId()))

        // return depots.filter(d => Number(d.id) !== Number(state.data.to_depot_id));

        return data
    }

    const getToDepots = () => {
        let data = cloneDeep(depots).filter(d => Number(d.store_id) !== Number(auth.getUser().getStoreId()))

        // return depots.filter(d => Number(d.id) !== Number(state.data.from_depot_id));

        return data
    }

    const handleSwitchDepots = () => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                from_depot_id: prev.data.to_depot_id,
                to_depot_id: prev.data.from_depot_id,
            }
        }))
    }

    const getQuantityFromDepotBefore = articleId => {
        return availability[articleId] && isNumeric(availability[articleId].quantity_from_depot_before) ? availability[articleId].quantity_from_depot_before : 0
    }

    const getQuantityToDepotBefore = articleId => {
        return availability[articleId] && isNumeric(availability[articleId].quantity_to_depot_before) ? availability[articleId].quantity_to_depot_before : 0
    }

    const getQuantityFromDepotAfter = row => {
        if (state.data.from_depot_id === state.data.to_depot_id) {
            return getQuantityFromDepotBefore(row.article_id)
        }

        return calcQuantityFromDepotAfter(getQuantityFromDepotBefore(row.article_id), row.quantity)
    }

    const getQuantityToDepotAfter = row => {
        if (state.data.from_depot_id === state.data.to_depot_id) {
            return getQuantityToDepotBefore(row.article_id)
        }

        return calcQuantityToDepotAfter(getQuantityToDepotBefore(row.article_id), row.quantity)
    }

    const handleImport = () => {
        let modal = importModalRef.current

        if (!modal) {
            return
        }

        modal.open()
        // modal.setParams(state.data);

        let finished = false

        modal.onChunk(({ is_test, ready, type_id, results }) => {
            // console.log(data);

            // setState(prev => ({
            //     ...prev,
            //     data: {
            //         ...prev.data,
            //         rows: results
            //     }
            // }))

            // замяна или добавяне
            let erase = Number(type_id) === 1

            let rows

            if (erase) {
                rows = []
            } else {
                rows = cloneDeep(state.data.rows)
            }

            // console.log(erase)
            // console.log(rows)
            // console.log(results);

            results.map((row, index) => {
                // let data = row.article;

                rows.push(row)
            })

            // console.log(rows);

            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    rows: Object.values(rows)
                }
            }))

            // само, ако е завършил импорта
            // за да може да се изпълнят всички наведнъж
            if (ready) {
                // да се изпълни само веднъж
                if (finished === false) {
                    let ids = rows.map(r => r.article_id).filter(r => r)

                    getArticleAvailability(ids)
                }

                finished = true
            }
        })

        modal.onSuccess(() => {
            // alert(1);

        })
    }

    const showArticle = id => {
        let modal = Refs.getInstance().getRef('article')

        if (!modal) {
            return
        }

        modal.open(id)
    }

    const isAcceptButtonDisabled = () => {
        return state.data.rows.filter(row => Number(row.quantity) !== Number(row.received_quantity)).length > 0
    }

    const openMenu = e => {
        setState(prev => ({
            ...prev,
            saveButtonMenu: e.currentTarget
        }))
    }

    const closeMenu = () => {
        setState(prev => ({
            ...prev,
            saveButtonMenu: null
        }))
    }

    const handleAddNote = () => {
        setState(prev => ({
            ...prev,
            addNote: !prev.addNote
        }))
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault()
        }

        if (typeof state.onClose === 'function') {
            hide(state.onClose)
        } else {
            hide()
        }
    }

    return (
        <>
            <div className={`${state.show ? "visible" : ""} overlay`}>
                <div
                    className={`popup-primary extra-large complex-modal preview-storage-load-modal cargo-2-modal ${state.show ? "show" : ""}`}
                >
                    <div className="header light-brown">
                        <b>
                            {state.id ? 'Редактиране на прехвърляне' : 'Ново прехвърляне'}
                        </b>
                        <p>
                            Lorem Ipsum е елементарен примерен<br /> текст, използван в печатарската
                        </p>
                    </div>
                    <div className="body">
                        <div className="panel" style={{
                            marginTop: 0
                        }}>

                            {state.dataLoading
                                ?
                                <Loader />
                                :
                                <>
                                    <Form ref={formRef}>
                                        <div className="row">
                                            <div className="col">
                                                <h6>
                                                    Данни за прехвърляне
                                                </h6>
                                                <div className="field">
                                                    <div className="row">
                                                        <div className="col">
                                                            <RedditTextField
                                                                size="small"
                                                                variant="filled"
                                                                margin="dense"
                                                                fullWidth
                                                                label="Прехвърляне №"
                                                                value={state.data.id || ''}
                                                                readOnly
                                                                disabled
                                                            />
                                                        </div>
                                                        <div className="col">
                                                            <RedditTextField
                                                                size="small"
                                                                variant="filled"
                                                                margin="dense"
                                                                fullWidth
                                                                label="Документ дата"
                                                                type="datetime-local"
                                                                name="document_date"
                                                                value={state.data?.document_date ? moment(state.data.document_date).format('YYYY-MM-DDTHH:mm') : '-'}
                                                                onChange={handleInputChange}
                                                                error={Boolean(validations?.document_date && (validations.document_date[0] || validations.document_date))}
                                                                helperText={validations?.document_date && (validations.document_date[0] || validations.document_date)}
                                                                disabled={state.data.allow_modify === false}
                                                            />
                                                        </div>
                                                        <div className="col">
                                                            <RedditTextField
                                                                size="small"
                                                                variant="filled"
                                                                margin="dense"
                                                                fullWidth
                                                                label="Създадено на"
                                                                type="datetime-local"
                                                                value={state.data.created_at ? moment(state.data.created_at).format('YYYY-MM-DDTHH:mm') : '-'}
                                                                readOnly
                                                                disabled
                                                            />
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div
                                                    className="field"
                                                    style={{
                                                        maxWidth: '600px',
                                                        backgroundColor: '#ECF3F9',
                                                        margin: '0 auto'
                                                    }}
                                                >
                                                    <div className="row">
                                                        <div className="col">
                                                            <RedditSelect
                                                                name="from_depot_id"
                                                                value={state.data.from_depot_id || ''}
                                                                onChange={handleFromDepotChange}
                                                                label="От склад"
                                                                disabled={state.data.allow_change_depots === false}
                                                                error={Boolean(validations?.from_depot_id)}
                                                                helperText={validations?.from_depot_id && (validations.from_depot_id[0] || validations.from_depot_id)}
                                                            >
                                                                {state.data.fromdepot ?
                                                                    <MenuItem value={state.data.from_depot_id}>
                                                                        {state.data.fromdepot?.store?.name} - {state.data.fromdepot?.translation?.name || state.data.fromdepot.name}
                                                                    </MenuItem>
                                                                    :
                                                                    getFromDepots().map(o =>
                                                                        <MenuItem key={o.id} value={o.id}>
                                                                            {o?.store?.translation?.name || o?.store?.name} - {o?.translation?.name || o.name}
                                                                        </MenuItem>
                                                                    )}
                                                            </RedditSelect>
                                                        </div>
                                                        <div className="col" style={{
                                                            maxWidth: '50px',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                        }}>
                                                            <img src={moveArrowIcon} alt="" />
                                                        </div>
                                                        <div className="col">
                                                            <RedditSelect
                                                                name="to_depot_id"
                                                                value={state.data.to_depot_id || ''}
                                                                onChange={handleToDepotChange}
                                                                label="В склад"
                                                                disabled={state.data.allow_change_depots === false}
                                                                error={Boolean(validations?.to_depot_id)}
                                                                helperText={validations?.to_depot_id && (validations.to_depot_id[0] || validations.to_depot_id)}
                                                            >
                                                                {state.data.todepot ?
                                                                    <MenuItem value={state.data.to_depot_id}>
                                                                        {state.data.todepot?.store?.name} - {state.data.todepot?.translation?.name || state.data.todepot.name}
                                                                    </MenuItem>
                                                                    :
                                                                    [...getFromDepots(), ...getToDepots()].map(o =>
                                                                        <MenuItem key={o.id} value={o.id}>
                                                                            {o?.store?.translation?.name || o?.store?.name} - {o?.translation?.name || o.name}
                                                                        </MenuItem>
                                                                    )
                                                                }
                                                            </RedditSelect>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <h6>
                                                    Артикули
                                                </h6>
                                                <div className="field">
                                                    {state.data.allow_modify
                                                        ?
                                                        <table className="type-doc">
                                                            <thead>
                                                                <tr>
                                                                    <th
                                                                        style={{
                                                                            maxWidth: '40px'
                                                                        }}
                                                                    >

                                                                    </th>
                                                                    <th style={{ maxWidth: '40px' }}>
                                                                        №
                                                                    </th>
                                                                    <th style={{ width: '100%' }}>
                                                                        Артикул
                                                                    </th>
                                                                    <th style={{ width: '10%' }}>
                                                                        Текущо
                                                                    </th>
                                                                    <th style={{ width: '10%' }}>
                                                                        След
                                                                    </th>
                                                                    <th style={{ width: '10%' }}>
                                                                        Количество
                                                                    </th>
                                                                    <th style={{ width: '10%' }}>
                                                                        Текущо
                                                                    </th>
                                                                    <th style={{ width: '10%' }}>
                                                                        След
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {state.data.rows.map((row, index) =>
                                                                    <tr key={'r-' + index}>
                                                                        <td
                                                                            style={{
                                                                                paddingLeft: 0,
                                                                                maxWidth: '40px'
                                                                            }}
                                                                        >
                                                                            <Button className='small remove' onClick={e => handleRemoveRow(e, index)}>
                                                                            </Button>
                                                                        </td>
                                                                        <td style={{ width: '40px' }}>
                                                                            {index + 1}

                                                                            <input type="hidden" name={`rows[${index}][id]`} value={state.data.rows[index].id || ''} />
                                                                        </td>
                                                                        <td style={{ width: '20%' }}>
                                                                            <Autocomplete
                                                                                margin="none"
                                                                                size="small"
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                url="store/autocomplete/articles"
                                                                                inputName="article_name"
                                                                                inputIdName={`rows[${index}][article_id]`}
                                                                                inputValue={state.data?.rows[index] && state.data?.rows[index]?.article?.name ? state.data?.rows[index]?.article?.name : ''}
                                                                                inputIdValue={state.data?.rows[index] && state.data?.rows[index].article_id ? state.data?.rows[index].article_id : ''}
                                                                                onChange={data => handleChangeArticle(data, index)}
                                                                                error={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].article_id && (validations.rows[index].article_id[0] || validations.rows[index].article_id))}
                                                                                helperText={validations && validations.rows && validations.rows[index] && validations.rows[index].article_id && (validations.rows[index].article_id[0] || validations.rows[index].article_id)}
                                                                                renderText={data => {
                                                                                    return (
                                                                                        <div style={{ display: 'flex', width: '100%' }}>
                                                                                            <div style={{ width: 'max-content', marginRight: '10px' }}>
                                                                                                {data.id}
                                                                                            </div>
                                                                                            <div>
                                                                                                {data.name}
                                                                                                <br />
                                                                                                {data.article_name}
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }}
                                                                                renderInputText={data => data.article_name}
                                                                                listStyle={{
                                                                                    minWidth: 'max-content'
                                                                                }}
                                                                                className="quantity-input input-with-right-label"
                                                                                autoFocus
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <RedditTextField
                                                                                margin="none"
                                                                                size="small"
                                                                                variant="outlined"
                                                                                type="number"
                                                                                step="0.0001"
                                                                                fullWidth
                                                                                value={getQuantityFromDepotBefore(state.data?.rows[index].article_id)}
                                                                                disabled
                                                                                InputProps={state.data?.rows[index]?.article?.amount_type_short ? {
                                                                                    endAdornment: <InputAdornment position="end">{state.data?.rows[index]?.article?.amount_type_short || '-'}</InputAdornment>,
                                                                                } : {}}
                                                                                className="quantity-input input-with-right-label"
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <RedditTextField
                                                                                margin="none"
                                                                                size="small"
                                                                                variant="outlined"
                                                                                type="number"
                                                                                step="0.0001"
                                                                                fullWidth
                                                                                value={getQuantityFromDepotAfter(state.data?.rows[index])}
                                                                                disabled
                                                                                InputProps={state.data?.rows[index]?.article?.amount_type_short ? {
                                                                                    endAdornment: <InputAdornment position="end">{state.data?.rows[index]?.article?.amount_type_short || '-'}</InputAdornment>,
                                                                                } : {}}
                                                                                className="quantity-input input-with-right-label"
                                                                            />
                                                                        </td>
                                                                        <td style={{ width: '10%' }}>
                                                                            <RedditTextField
                                                                                margin="none"
                                                                                size="small"
                                                                                variant="outlined"
                                                                                type="number"
                                                                                step="0.0001"
                                                                                fullWidth
                                                                                name={`rows[${index}][quantity]`}
                                                                                value={state.data?.rows[index] && state.data?.rows[index].quantity ? state.data?.rows[index].quantity : ''}
                                                                                onChange={e => handleQtyChange(e, index)}
                                                                                error={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].quantity && (validations.rows[index].quantity[0] || validations.rows[index].quantity))}
                                                                                helperText={validations && validations.rows && validations.rows[index] && validations.rows[index].quantity && (validations.rows[index].quantity[0] || validations.rows[index].quantity)}
                                                                                InputProps={state.data?.rows[index]?.article?.amount_type_short ? {
                                                                                    endAdornment: <InputAdornment position="end">{state.data?.rows[index]?.article?.amount_type_short || '-'}</InputAdornment>,
                                                                                } : {}}
                                                                                className="quantity-input input-with-right-label"
                                                                            />

                                                                        </td>
                                                                        <td>
                                                                            <RedditTextField
                                                                                margin="none"
                                                                                size="small"
                                                                                variant="outlined"
                                                                                type="number"
                                                                                step="0.0001"
                                                                                fullWidth
                                                                                value={getQuantityToDepotBefore(state.data?.rows[index].article_id)}
                                                                                disabled
                                                                                InputProps={state.data?.rows[index]?.article?.amount_type_short ? {
                                                                                    endAdornment: <InputAdornment position="end"> {state.data?.rows[index]?.article?.amount_type_short || '-'}</InputAdornment>,
                                                                                } : {}}
                                                                                className="quantity-input input-with-right-label"
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <RedditTextField
                                                                                margin="none"
                                                                                size="small"
                                                                                variant="outlined"
                                                                                type="number"
                                                                                step="0.0001"
                                                                                fullWidth
                                                                                value={getQuantityToDepotAfter(state.data?.rows[index])}
                                                                                disabled
                                                                                InputProps={state.data?.rows[index]?.article?.amount_type_short ? {
                                                                                    endAdornment: <InputAdornment position="end"> {state.data?.rows[index]?.article?.amount_type_short || '-'}</InputAdornment>,
                                                                                } : {}}
                                                                                className="quantity-input input-with-right-label"
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                        :
                                                        <table className="type-doc">
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ maxWidth: '40px' }}>
                                                                        №
                                                                    </th>
                                                                    <th style={{ width: '100%' }}>
                                                                        Артикул
                                                                    </th>
                                                                    <th>
                                                                        Изпратено количество
                                                                    </th>
                                                                    <th>
                                                                        Получено количество
                                                                    </th>
                                                                    <th className="options">

                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {state.data.rows.map((row, index) =>
                                                                    <tr key={'r-' + index}>
                                                                        <td title={row.id || '-'} style={{ width: '5%' }}>
                                                                            {index + 1}

                                                                            <input type="hidden" name={`rows[${index}][id]`} value={state.data.rows[index].id || ''} />
                                                                        </td>
                                                                        <td style={{ width: '100%' }}>
                                                                            <span className="link" onClick={e => showArticle(row.article_id)}>
                                                                                {row.article?.article_name}
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            {row.quantity} {row.article?.amount_type_short || '-'}
                                                                        </td>
                                                                        <td>
                                                                            <RedditTextField
                                                                                margin="none"
                                                                                size="small"
                                                                                variant="outlined"
                                                                                type="number"
                                                                                step="0.0001"
                                                                                fullWidth
                                                                                name={`rows[${index}][received_quantity]`}
                                                                                value={row.received_quantity || ''}
                                                                                onChange={handleInputChange}
                                                                                error={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].received_quantity && (validations.rows[index].received_quantity[0] || validations.rows[index].received_quantity))}
                                                                                helperText={validations && validations.rows && validations.rows[index] && validations.rows[index].received_quantity && (validations.rows[index].received_quantity[0] || validations.rows[index].received_quantity)}
                                                                                InputProps={row.article?.amount_type_short ? {
                                                                                    endAdornment: <InputAdornment position="end"> {row.article?.amount_type_short || '-'}</InputAdornment>,
                                                                                } : {}}
                                                                                className="quantity-input input-with-right-label"
                                                                            />
                                                                        </td>
                                                                        <td className="options">
                                                                            <Button variant="primary" size="sm" onClick={e => handleAcceptRow(e, index)}>
                                                                                <Check2Square />
                                                                            </Button>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    }
                                                    <hr />
                                                    <div className="row">
                                                        <div className="col">
                                                            {state.data.allow_modify &&
                                                                <Button
                                                                    className="small add"
                                                                    onClick={handleAddRow}
                                                                    style={{
                                                                        marginBottom: '10px'
                                                                    }}
                                                                >
                                                                </Button>
                                                            }
                                                            {state.addNote ?
                                                                <RedditTextField
                                                                    size="small"
                                                                    variant="filled"
                                                                    margin="dense"
                                                                    type="text"
                                                                    fullWidth
                                                                    label="Забележка"
                                                                    name="description"
                                                                    value={state.data?.description || ''}
                                                                    onChange={handleInputChange}
                                                                    error={Boolean(validations?.description)}
                                                                    helperText={validations?.description && (validations.description[0] || validations.description)}
                                                                    multiline
                                                                    rows={3}
                                                                    autoFocus={true}
                                                                />
                                                                :
                                                                <input hidden value={null} name="description" />
                                                            }
                                                            <span
                                                                className="link"
                                                                style={{
                                                                    width: 'fit-content',
                                                                    marginTop: '10px',
                                                                }}
                                                                onClick={handleAddNote}
                                                            >
                                                                {state.addNote ? 'Премахване' : 'Добавяне'} на забележка
                                                            </span>
                                                        </div>
                                                        <div className="col">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </>
                            }

                        </div>
                    </div>
                    <div className="footer">
                        <Menu
                            anchorEl={state.saveButtonMenu}
                            elevation={0}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            open={Boolean(state.saveButtonMenu)}
                            onClose={closeMenu}
                            onClick={closeMenu}
                        >
                            {state.data.allow_modify &&
                                <MenuItem
                                    loading={state.loadingBase}
                                    disabled={state.loading}
                                    onClick={handleSave}
                                >
                                    Запази данните
                                </MenuItem>
                            }
                            {state.data.allow_modify && state.data.allow_close &&
                                <MenuItem
                                    loading={state.loadingClose}
                                    disabled={state.loading}
                                    onClick={handleSaveAndClose}
                                >
                                    Запази и премести артикулите
                                </MenuItem>
                            }
                        </Menu>

                        <Button
                            className="cancel"
                            onClick={handleClose}
                            style={{
                                marginLeft: 'auto',
                                minWidth: '100px'
                            }}
                        >
                            Назад
                        </Button>
                        {state.data.allow_cancel &&
                            <SaveButton
                                loading={state.loadingCancel}
                                disabled={state.loading}
                                onClick={handleCancel}
                                className="save"
                            >
                                Откажи прехвърлянето
                            </SaveButton>
                        }
                        {state.data.allow_accept &&
                            <SaveButton
                                loading={state.loadingBase}
                                disabled={state.loading}
                                onClick={handleSaveReceived}
                                className="save"
                            >
                                Запиши получените
                            </SaveButton>
                        }
                        {state.data.allow_accept &&
                            <SaveButton
                                loading={state.loadingAccept}
                                disabled={state.loading || isAcceptButtonDisabled()}
                                className="save"
                                onClick={handleAccept}
                            >
                                Приеми прехвърлянето
                            </SaveButton>
                        }
                        {state.data.allow_send &&
                            <SaveButton
                                loading={state.loadingSend}
                                disabled={state.loading}
                                className="save with-dropdown"
                                onClick={handleSaveAndSend}
                            >
                                {state.data.canceled
                                    ?
                                    'Запази и изпрати пак'
                                    :
                                    'Запази и изпрати'
                                }
                                <i
                                    className="menu"
                                    onClick={openMenu}
                                >
                                    <img src={dropArrowIcon} />
                                </i>
                            </SaveButton>
                        }
                    </div>
                </div>
            </div>
            <Question
                ref={saveAndCloseModalRef}
                mainMessage="Сигурни ли сте, че искате да извършите това прехвърляне веднага към другия склад?"
            />

            <Question
                ref={saveAndSendModalRef}
            >
                <p style={{ fontSize: '1.25rem' }}>
                    Сигурни ли сте, че искате да изпратите това прехвърляне към другия склад?
                </p>
                <br />
                Веднъж изпратено прехвърлянето ще може да бъде редактирано само от получателя.
                <br />
                Стоките ще се прехвърлят между складовете след като бъде потвърдено от другата страна.
            </Question>

            <Error
                ref={errorModalRef}
            />

            <Import
                ref={importModalRef}
                type={TYPES.STOREMOVE}
                createText="Замяна"
                updateText="Добавяне"
            />
        </>
    )
}

export default forwardRef(AddOrEdit)