import { useEffect, useState } from "react"

//helpers
import Api from "helpers/Api"

function useFetchStores(clientId = null) {
    const [loading, setLoading] = useState(true)
    const [stores, setStores] = useState({})
    const [currentPage, setCurrentPage] = useState(1)

    useEffect(() => {
        _fetchData()
    }, [clientId, currentPage])

    function _fetchData() {
        if (!clientId) {
            console.error('client id is required')

            return
        }

        setLoading(true)

        Api.get(`store/clients/objects/get?client_id=${clientId}&page=${currentPage}`)
            .then(res => {
                _setStores(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    function _setStores(data) {
        setStores(data)
    }

    function getStores() {
        return stores.items
    }

    function getCurrentPage() {
        return currentPage
    }

    function getTotalPages() {
        return stores.pages
    }

    function getTotalItems() {
        return stores.total
    }

    function handlePage(page) {
        setCurrentPage(page.selected + 1)
    }

    function refetchData() {
        if (getCurrentPage() === 1) {
            _fetchData()

            return
        }

        setCurrentPage(1)
    }

    return {
        getStores,
        loadingStores: loading,
        getCurrentPage,
        getTotalPages,
        getTotalItems,
        handlePage,
        refetchData
    }
}

export default useFetchStores