import { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react'
import { buildUrl, useQuery } from 'helpers/Url'
import { useLocation, useHistory, Link, useParams } from 'react-router-dom'
import { Table, Form, Row, Col, Container, InputGroup, Dropdown } from 'react-bootstrap'
import { ArrowLeftCircle, Upload, XCircle } from 'react-bootstrap-icons'
import { useNestedState } from 'helpers/NestedState'
import { useCurrencyContext } from 'providers/Currency'
import { useValidation } from 'helpers/Validation'
import { useAuthDataContext } from 'providers/Auth'
import { cloneDeep } from 'lodash'
import { MenuItem, InputAdornment, Menu, Button } from '@material-ui/core'
import Skeleton from "react-loading-skeleton"

import Question from 'components/modals/Question'
import Layout from 'components/Layout'
import Api from 'helpers/Api'

//misc
import Loader from 'components/misc/Loader'
import Alert from 'components/misc/Alert'
import moment from 'moment'
import SaveButton from 'components/misc/Button'
import Pagination from 'components/misc/Pagination'
import Info from 'components/misc/Info'
import Error from 'components/modals/Error'
import TableRow from './partials/TableRow'
import TableCategoryRow from './partials/TableCategoryRow'
import DatePicker from 'components/partials/filter/DatePicker'
import Import from 'components/modals/Import'
import { TYPES } from 'constants/imports'
import Refs from 'Refs'
import { useAppContext } from 'providers/App'
import SetEmpty from './partials/SetEmpty'
import { baseUrl } from 'helpers/mode'
import RedditTextField from 'components/misc/RedditTextField'
import RedditSelect from 'components/misc/RedditSelect'
import PaginationMini from 'components/misc/PaginationMini'

//images
import dropArrowIcon from 'assets/img/app/icons/drop-arrow-white.png'

//TO DO
let timeout

function Index({ }, ref) {

    const params = useParams()
    const location = useLocation()
    const navigate = useHistory()
    const query = useQuery()

    const app = useAppContext()
    const auth = useAuthDataContext()
    const currencies = useCurrencyContext()

    const user = auth.getUser()

    const [state, setState] = useNestedState({
        loading: false,
        loadingBase: false,
        loadingExit: false,
        loadingClose: false,
        loadingChanges: false,
        dataLoading: false,
        rowsLoading: false,
        data: {
            allow_close: user.permission('revisions_close') ? true : false,
        },
        rows: {
            rows: [{}],
            pages: 0,
            total: 0,
            filter: {
                page: 1,
            },
            refresh: false,
        },
        original: {},
        timeout: null,
        saveButtonMenu: null,
        show: false,
        addNote: false
    })

    useImperativeHandle(ref, () => ({
        open: (id) => {
            handleShow(id)
        },
        close: () => {
            handleClose()
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }))
        }
    }))

    const [hiddenCategories, setHiddenCategories] = useState([])

    const [validations, setValidations] = useValidation()

    const [reasons, setReasons] = useState([])

    const formRef = useRef(null)
    const saveAndCloseModalRef = useRef(null)
    const errorModalRef = useRef(null)
    const articleModalRef = useRef(null)
    const importModalRef = useRef(null)
    const setEmptyModalRef = useRef(null)

    useEffect(() => {
        if (state.id) {
            loadFullData()
        }
    }, [state.id])

    useEffect(() => {
        if (state.data.view_type === 'selected' && state.rows.rows.length === 0) {
            setState(prev => ({
                ...prev,
                rows: {
                    ...prev.rows,
                    rows: [{}]
                }
            }))
        }
    }, [state.rows.rows])

    useEffect(() => {
        setState(prev => ({
            ...prev,
            rows: {
                ...prev.rows,
                filter: {
                    ...prev.rows.filter,
                    page: query.get('page') || 1,
                    search: query.get('search') || '',
                },
                refresh: moment().unix()
            },
        }))
    }, [location.search])

    useEffect(() => {
        if (state.rows.refresh) {

            if (state.rowsLoading) {
                return
            }

            setState(prev => ({
                ...prev,
                rowsLoading: true
            }))

            loadRows().then(() => {
                setState(prev => ({
                    ...prev,
                    rowsLoading: false
                }))
            })
        }
    }, [state.rows.refresh])

    useEffect(() => {
        if (!state.data.id) {
            return
        }

        // при тест с laravel dev server
        // заявката блокира всички останали, тъй като
        // уеб сървъра може да обработва само 1 заявка
        let base = baseUrl
        // let base = 'http://localhost:8001';

        let url = buildUrl(base + 'store/revisions/calculation-progress', {
            id: state.data.id,
            token: auth.getToken()
        })

        const eventSource = new EventSource(url)

        eventSource.addEventListener('ping', event => {
            let data = JSON.parse(event.data)

            // console.log(data);

            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    calculating: data.calculating,
                    calculationPercent: data.percent || 0,
                },
            }))

            if (data.ready && data.percent === 100) {
                eventSource.close()

                loadData()
                loadRows()
            }

        })

        eventSource.onerror = err => {
            // app.showError('EventSource failed');
        }

        return () => {
            eventSource.close()
        }

    }, [state.data.id])

    useEffect(() => {
        Api.get('store/revisions/reasons')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setReasons(res.data)
                }
            })
    }, [])

    const handleShow = (id) => {
        setState(prev => ({
            ...prev,
            id,
            show: true,
        }))
    }

    const handleClose = () => {
        setState(prev => ({
            ...prev,
            id: null,
            show: false,
        }))
    }

    const loadFullData = () => {
        loading(true)

        let p1 = loadData()
        let p2 = loadRows()

        Promise.all([p1, p2]).then(() => {
            loading(false)
        })
    }

    const loadData = async () => {
        let url = 'store/revisions/show'

        return Api.get(url, {
            params: {
                id: state.id
            }
        }).then(res => {
            setDataResponse(res.data)
        }).catch(err => {
            console.log(err)
        })
    }

    const loadRows = async () => {
        let url = 'store/revisions/rows'

        return Api.get(url, {
            params: {
                ...state.rows.filter,
                id: state.id,
            }
        }).then(res => {
            setRowsResponse(res.data)
        }).catch(err => {
            console.log(err)
        })
    }

    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout)

        if (typeof key === 'object') {
            setState(prev => ({
                ...prev,
                rows: {
                    ...prev.rows,
                    filter: {
                        ...prev.rows.filter,
                        ...key
                    },
                }
            }))
        } else {
            setState(prev => ({
                ...prev,
                rows: {
                    ...prev.rows,
                    filter: {
                        ...prev.rows.filter,
                        [key]: val,
                    },
                }
            }))
        }

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                rows: {
                    ...prev.rows,
                    filter: {
                        ...prev.rows.filter,
                        page: 1
                    },
                    refresh: moment().unix()
                }
            }))
        }, delay)
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            rows: {
                ...prev.rows,
                filter: {
                    ...prev.rows.filter,
                    page: page.selected + 1,
                },
                refresh: moment().unix()
            }
        }))
    }

    const setDataResponse = data => {
        setState(prev => ({
            ...prev,
            data: data,
            original: cloneDeep(data),
            addNote: !!data.description
        }))
    }

    const setRowsResponse = data => {
        let rows = data.items

        rows = rows.map(row => {
            //

            return row
        })

        setState(prev => ({
            ...prev,
            rows: {
                ...prev.rows,
                rows: rows,
                pages: data.pages,
                page: data.page,
                total: data.total,
            }
        }))
    }

    const isDirty = (key, modificator = null) => {
        if (modificator) {
            return modificator(state.data[key]) !== modificator(state.original[key])
        }

        return String(state.data[key]) !== String(state.original[key])
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            dataLoading: Boolean(loading)
        }))
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleRowChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'rows')
    }

    const handleToDateChange = e => {
        const value = e.target.value

        setState('to_date_local', value, 'data')
    }

    const handleCheckboxChange = e => {
        const name = e.target.name

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }))
    }

    const save = (close = false, exit = false, onSuccess = null) => {
        if (state.loading) {
            return
        }

        setState(prev => ({
            ...prev,
            loading: true,
            loadingBase: close ? false : true,
            loadingClose: close ? true : false,
        }))

        setValidations(null)

        let url = state.id ? 'store/revisions/edit' : 'store/revisions/add'

        let form = formRef.current

        let data = new FormData(form)

        if (state.id) {
            data.append('id', state.id)
        }

        if (close) {
            data.append('close', 1)
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (exit) {
                navigate.push('/storage/revisions')
            } else {
                navigate.replace('/storage/revisions/' + res.data.id + '/edit')

            }

            if (typeof onSuccess === 'function') {
                onSuccess(res.data)
            }
        }).catch(error => {
            const _err = error.response

            if (_err && _err.status) {
                if (_err.status === 422) {
                    setValidations(_err.data.errors)
                } else {
                    let modal = errorModalRef.current

                    if (modal) {
                        modal.open(_err.data.error || _err.data.message)
                    }
                }
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
                loadingBase: false,
                loadingExit: false,
                loadingClose: false,
            }))
        })
    }

    const handleSave = e => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            loading: true,
            loadingBase: true,
            loadingExit: false,
            loadingClose: false,
        }))

        save(false, false, () => {
            app.showSuccess('Действието е успешно!')
        })
    }

    const handleSaveAndClose = e => {
        e.preventDefault()

        let modal = saveAndCloseModalRef.current

        if (modal) {
            modal.open()
            modal.onSuccess(() => {
                setState(prev => ({
                    ...prev,
                    loading: true,
                    loadingBase: false,
                    loadingExit: false,
                    loadingClose: true,
                }))

                save(true, true, () => {
                    app.showSuccess('Действието е успешно!')
                })
            })
        }

    }

    const handleSaveAndExit = e => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            loading: true,
            loadingBase: false,
            loadingExit: true,
            loadingClose: false,
        }))

        save(false, true, () => {
            app.showSuccess('Действието е успешно!')
        })
    }

    const handleSetAll = e => {
        e.preventDefault()

        let modal = setEmptyModalRef.current

        if (modal) {
            modal.open(state.data, state.rows.rows)
            modal.onSuccess(() => {
                loadRows()

                app.showSuccess('Действието е успешно!')
            })
        }
    }

    const handleSetReason = (reason) => {
        let modal = Refs.getInstance().getRef('question')

        if (!modal) {
            return
        }

        modal.open(
            <div>
                Сигурни ли сте, че искате да зададете причина "<b>{reason.name}</b>" за всички артикули без
                зададена причина и разлики в отчитането?
            </div>
        )

        modal.onSuccess(() => {
            Api.post('store/revisions/set-reason', {
                id: state.data.id,
                reason_id: reason.id
            }).then(res => {
                if (res.data.success) {
                    loadRows()

                    app.showSuccess()
                } else {
                    app.showError()
                }
            }).catch(err => {
                app.showError()
            })
        })
    }

    const applyChanges = () => {
        if (!state.id) {
            return
        }

        if (state.loading) {
            return
        }

        setState(prev => ({
            ...prev,
            loading: true,
            loadingChanges: true,
        }))

        setValidations(null)

        let url = 'store/revisions/change'

        let form = formRef.current

        let data = new FormData(form)

        data.append('id', state.id)

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            setDataResponse(res.data.data)
            setRowsResponse(res.data.rows)
        }).catch(error => {
            const _err = error.response

            if (_err && _err.status) {
                if (_err.status === 422) {
                    setValidations(_err.data.errors)
                } else {
                    let modal = errorModalRef.current

                    if (modal) {
                        modal.open(_err.data.error || _err.data.message)
                    }
                }
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
                loadingChanges: false,
            }))
        })
    }

    const handleApplyChanges = e => {
        e.preventDefault()

        applyChanges()
    }

    const handleAddRow = e => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            rows: {
                ...prev.rows,
                rows: prev.rows.rows.concat({})
            }
        }))
    }

    const handleRemoveRow = (e, index) => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            rows: {
                ...prev.rows,
                rows: prev.rows.rows.filter((row, i) => i !== index)
            }
        }))
    }

    const handleChangeArticle = (data, index) => {

        let row = state.rows.rows[index]

        if (data.id) {
            // взимаме данни за наличността...
            getRow(data.id, row => {
                setState(prev => ({
                    ...prev,
                    rows: {
                        ...prev.rows,
                        rows: Object.values({
                            ...prev.rows.rows,
                            [index]: {
                                ...prev.rows.rows[index],
                                ...row,
                                article: data,
                                article_id: data.id,
                            }
                        })
                    }
                }))
            })
        } else {
            setState(prev => ({
                ...prev,
                rows: {
                    ...prev.rows,
                    rows: Object.values({
                        ...prev.rows.rows,
                        [index]: {
                            ...prev.rows.rows[index],
                            amount_unit: null,
                            storage_quantity: null,
                            actual_quantity: null,
                            quantity_diff: null,
                            amount_unit_diff: null,
                            article_actual_price: null,
                            article_delivery_price: null,
                            article: data,
                            article_id: null,
                        }
                    })
                }
            }))
        }

    }

    const handleChangeReason = (data, index) => {

        let row = state.rows.rows[index]

        // да обновим за всички еднакви артикули причината
        setState(prev => ({
            ...prev,
            rows: {
                ...prev.rows,
                rows: prev.rows.rows.map(r => {
                    if (r.article_id === row.article_id) {
                        r.reason = data
                        r.reason_id = data?.id || null
                    }

                    return r
                })
            }
        }))
    }

    const getRow = (articleId, callback) => {
        Api.get('store/revisions/row', {
            params: {
                revision_id: state.id,
                article_id: articleId
            }
        }).then(res => {
            callback(res.data)
        })
    }

    // при смяна на количеството 
    const handleQtyChange = (e, index) => {
        let row = state.rows.rows[index]

        // handleRowChange(e);

        let value = e.target.value

        // да обновим за всички еднакви артикули количеството
        setState(prev => ({
            ...prev,
            rows: {
                ...prev.rows,
                rows: prev.rows.rows.map(r => {
                    if (r.article_id === row.article_id) {
                        r.actual_quantity = value
                    }

                    return r
                })
            }
        }))
    }

    const getTotalQty = () => {
        let total = 0

        state.rows.rows.map(row => {
            total += parseFloat(row.actual_quantity || 0)
        })

        return total
    }

    const getRowStorageQuantity = row => {
        if (typeof row.storage_quantity !== 'undefined') {
            return parseFloat(row.storage_quantity || 0)
        }

        return null
    }

    const getRowQuantityDiff = row => {
        if (row.actual_quantity !== null) {
            return parseFloat(row.actual_quantity || 0) - parseFloat(row.storage_quantity || 0)
        }

        return null
    }

    const getRowAmountUnitDiff = row => {
        if (row.amount_unit === null || row.actual_quantity === null) {
            return null
        }

        // let isAmountFloat = isFloat(row.amount_unit);
        // let isQuantityFloat = isFloat(row.actual_quantity);

        // if (isAmountFloat || isQuantityFloat) {
        return getRowQuantityDiff(row) / parseFloat(row.amount_unit || 0)
        // }

        // return getRowQuantityDiff(row) - parseFloat(row.storage_quantity || 0);
    }

    const getRowPriceDiff = row => {
        switch (Number(state.data.price_mode_id)) {
            case 1:
                return getRowAmountUnitDiff(row) * parseFloat(row.article_actual_price || 0)
            case 2:
                return getRowQuantityDiff(row) * parseFloat(row.article_delivery_price || 0)
        }
    }

    const expandCategory = id => {
        if (isCategoryHidden(id)) {
            setHiddenCategories(prev => prev.filter(c => c !== id))
        } else {
            setHiddenCategories(prev => prev.concat(id))
        }
    }

    const isCategoryHidden = id => {
        return hiddenCategories.findIndex(c => c === id) > -1
    }

    const renderRows = () => {
        let output = []

        let category = null
        let visible = true

        state.rows.rows.map((row, index) => {

            if (state.data.view_type === 'category') {
                if (row.category) {
                    if (row.category.id !== category) {

                        let total = state.rows.rows.filter(c => c?.category?.id === row.category.id).length
                        let expanded = !isCategoryHidden(row.category.id)

                        visible = expanded

                        output.push(
                            <TableCategoryRow
                                category={row.category}
                                total={total}
                                expanded={expanded}
                                expand={expandCategory}
                            />
                        )
                    }
                }
            }

            output.push(
                <TableRow
                    key={index}
                    state={state}
                    validations={validations}
                    row={row}
                    index={index}
                    handleChangeArticle={handleChangeArticle}
                    getRowStorageQuantity={getRowStorageQuantity}
                    handleQtyChange={handleQtyChange}
                    getRowQuantityDiff={getRowQuantityDiff}
                    getRowAmountUnitDiff={getRowAmountUnitDiff}
                    getRowPriceDiff={getRowPriceDiff}
                    handleChangeReason={handleChangeReason}
                    handleRemoveRow={handleRemoveRow}
                    handleShowArticle={handleShowArticle}
                    visible={visible}
                />
            )

            category = row.category?.id || null
        })

        return output
    }

    const handleShowArticle = id => {
        let modal = Refs.getInstance().getRef('article')

        if (modal) {
            modal.open(id)
        }
    }

    const handleImport = () => {
        let modal = importModalRef.current

        if (!modal) {
            return
        }

        modal.open({
            type: 'update'
        })

        modal.setParams({
            id: state.data.id
        })

        modal.onSuccess(() => {
            setState(prev => ({
                ...prev,
                rows: {
                    ...prev.rows,
                    filter: {
                        ...prev.rows.filter,
                        page: 1,
                        search: '',
                    },
                    refresh: moment().unix()
                },
            }))
        })
    }

    const handleDelete = data => {
        let modal = Refs.getInstance().getRef('delete')

        modal.open()
        modal.onSuccess(() => {
            handleDestroy(state.data.id)
        })
    }

    const handleDestroy = id => {
        Api.post('store/revisions/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                navigate.push('/storage/revisions')
            }
        })
    }

    const openMenu = e => {
        setState(prev => ({
            ...prev,
            saveButtonMenu: e.currentTarget
        }))
    }

    const closeMenu = () => {
        setState(prev => ({
            ...prev,
            saveButtonMenu: null
        }))
    }

    const handleAddNote = () => {
        setState(prev => ({
            ...prev,
            addNote: !prev.addNote
        }))
    }

    return (
        <>
            <div className={`${state.show ? "visible" : ""} overlay`}>
                <div
                    className={`popup-primary extra-large complex-modal preview-storage-load-modal revision-modal  ${state.show ? "show" : ""}`}
                >
                    <div className="header light-brown">
                        <b>
                            {state.id ? 'Редактиране на ревизия' : 'Нова ревизия'}
                        </b>
                        <p>
                            Lorem Ipsum е елементарен примерен<br /> текст, използван в печатарската
                        </p>
                    </div>
                    <div
                        className="body"
                        style={{
                            padding: '20px'
                        }}
                    >
                        {state.dataLoading
                            ?
                            <div style={{
                                width: '100%'
                            }}>
                                <Skeleton count={1} height={35} />
                                <Skeleton count={1} height={35} />
                                <Skeleton count={1} height={35} />
                                <Skeleton count={1} height={35} />
                                <Skeleton count={1} height={35} />
                            </div>
                            :
                            <>
                                {state.data.calculating === 1 &&
                                    <Alert variant="warning" style={{ position: 'fixed', bottom: '10px', zIndex: 1 }}>
                                        Внимание! Наличността на артикулите в тази ревизия все още не е напълно изчислена.
                                        <br />
                                        След като процеса приключи страницата ще се актуализира автоматично!
                                    </Alert>
                                }

                                <Form ref={formRef}>
                                    {/* <Container> */}
                                    <div className="row">
                                        <div className="col">
                                            <h6>
                                                Данни за ревизията
                                            </h6>
                                            <div className="field">
                                                <div className="row">
                                                    <div className="col">
                                                        <RedditTextField
                                                            size="small"
                                                            variant="filled"
                                                            margin="dense"
                                                            fullWidth
                                                            label="Ревизия №"
                                                            readOnly
                                                            value={state.data.id || ''}
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <RedditTextField
                                                            size="small"
                                                            variant="filled"
                                                            margin="dense"
                                                            fullWidth
                                                            label="Създал"
                                                            readOnly
                                                            value={state.data?.creator?.username || ''}
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <RedditTextField
                                                            size="small"
                                                            variant="filled"
                                                            margin="dense"
                                                            fullWidth
                                                            label="Създадено на"
                                                            readOnly
                                                            value={state.data.created_at ? moment(state.data.created_at).utc().format('DD.MM.YYYY HH:mm') : moment().utc().format('DD.MM.YYYY HH:mm')}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <RedditTextField
                                                            size="small"
                                                            variant="filled"
                                                            margin="dense"
                                                            fullWidth
                                                            label="Търговски обект"
                                                            readOnly
                                                            value={state.data?.depot?.store?.translation?.name || state.data?.depot?.store?.name || ''}
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <RedditTextField
                                                            size="small"
                                                            variant="filled"
                                                            margin="dense"
                                                            fullWidth
                                                            label="Склад"
                                                            readOnly
                                                            value={state.data?.depot?.translation?.name || state.data?.depot?.name || ''}
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <RedditTextField
                                                            size="small"
                                                            variant="filled"
                                                            margin="dense"
                                                            fullWidth
                                                            label="Статус"
                                                            readOnly
                                                            value={state.data?.status?.translation?.name || state.data?.status?.name || ''}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <RedditSelect
                                                            name="view_type_id"
                                                            value={state.data.view_type_id || ''}
                                                            onChange={handleInputChange}
                                                            label="Вид"
                                                            error={Boolean(validations && validations.view_type_id)}
                                                            helperText={validations && validations.view_type_id && (validations.view_type_id[0] || validations.view_type_id)}
                                                        >
                                                            <MenuItem value={1}>
                                                                Всички артикули по категории
                                                            </MenuItem>
                                                            <MenuItem value={2}>
                                                                Избрани артикули
                                                            </MenuItem>
                                                            <MenuItem value={3}>
                                                                Всички артикули с наличност
                                                            </MenuItem>
                                                            <MenuItem value={4}>
                                                                Всички артикули
                                                            </MenuItem>
                                                        </RedditSelect>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <RedditSelect
                                                            name="price_mode_id"
                                                            value={state.data.price_mode_id || ''}
                                                            onChange={handleInputChange}
                                                            label="Калкулация по"
                                                            error={Boolean(validations && validations.price_mode_id)}
                                                            helperText={validations && validations.price_mode_id && (validations.price_mode_id[0] || validations.price_mode_id)}
                                                        >
                                                            <MenuItem value={1}>
                                                                Продажни цени
                                                            </MenuItem>
                                                            <MenuItem value={2}>
                                                                Доставни цени
                                                            </MenuItem>
                                                        </RedditSelect>
                                                    </div>
                                                    <div className="col">
                                                        <RedditTextField
                                                            size="small"
                                                            variant="filled"
                                                            margin="dense"
                                                            fullWidth
                                                            label="Наличност към дата"
                                                            type="datetime-local"
                                                            name="to_date"
                                                            value={state.data?.to_date_local ? moment(state.data.to_date_local).format('YYYY-MM-DDTHH:mm') : ''}
                                                            onChange={handleToDateChange}
                                                            error={Boolean(validations?.to_date)}
                                                            helperText={validations?.to_date && (validations.to_date[0] || validations.to_date)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {(isDirty('view_type_id') || isDirty('price_mode_id') || isDirty('to_date', value => moment(value).toString()) || isDirty('to_date_local', value => moment(value).toString())) &&
                                        <div className="row" style={{
                                            justifyContent: 'center'
                                        }}>
                                            <SaveButton
                                                loading={state.loadingChanges}
                                                disabled={state.loading}
                                                className="save"
                                                onClick={handleApplyChanges}
                                            >
                                                Приложи промените
                                            </SaveButton>
                                        </div>
                                    }
                                    <div className="row">
                                        <div className="col">
                                            <h6>
                                                Артикули
                                            </h6>
                                            <div className="field">
                                                <div className="filter-holder">
                                                    <div className="row filter">
                                                        <RedditTextField
                                                            label="Търсене..."
                                                            name="search"
                                                            value={state.rows.filter.search || ''}
                                                            onChange={e => handleSearch(e.target.name, e.target.value)}
                                                        />
                                                    </div>
                                                    <PaginationMini
                                                        page={state.rows.filter.page}
                                                        pages={state.rows.pages}
                                                        total={state.rows.total}
                                                        handlePage={handlePage}
                                                        isLoading={state.rowsLoading}
                                                    />
                                                </div>
                                                <div className="row" style={{ position: 'relative' }}>
                                                    <div className="col">
                                                        <table className="type-outer">
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ width: '3%' }}>
                                                                        #
                                                                    </th>
                                                                    <th style={{ width: '20%' }}>
                                                                        Артикул
                                                                    </th>
                                                                    <th style={{ width: '10%' }}>
                                                                        Единица
                                                                    </th>
                                                                    <th style={{ width: '10%' }}>
                                                                        К-во в склада
                                                                    </th>
                                                                    <th style={{ width: '10%' }}>
                                                                        Отчетено к-во
                                                                    </th>
                                                                    <th style={{ width: '10%' }}>
                                                                        Разлика(к-во)
                                                                    </th>
                                                                    <th style={{ width: '10%' }}>
                                                                        Разлика(ед)
                                                                    </th>
                                                                    <th style={{ width: '10%' }}>
                                                                        Разлика(цена)
                                                                    </th>
                                                                    <th style={{ width: '10%' }}>
                                                                        Причина
                                                                    </th>
                                                                    <th className="text-center" style={{ width: '5%' }}>

                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {renderRows()}
                                                            </tbody>
                                                        </table>
                                                        <hr />
                                                        <div className="row" style={{ alignItems: 'flex-start', justifyContent: 'space-between' }}>
                                                            <div
                                                                className="col"
                                                                style={{
                                                                    minWidth: '400px'
                                                                }}
                                                            >
                                                                {state.data.view_type === 'selected' &&
                                                                    <Button
                                                                        className="add small"
                                                                        onClick={handleAddRow}
                                                                        style={{
                                                                            marginBottom: '10px'
                                                                        }}
                                                                    >
                                                                    </Button>
                                                                }

                                                                {state.addNote ?
                                                                    <RedditTextField
                                                                        size="small"
                                                                        variant="filled"
                                                                        margin="dense"
                                                                        type="text"
                                                                        fullWidth
                                                                        label="Бележки"
                                                                        name="description"
                                                                        value={state.data?.description || ''}
                                                                        onChange={handleInputChange}
                                                                        error={Boolean(validations?.description)}
                                                                        helperText={validations?.description && (validations.description[0] || validations.description)}
                                                                        multiline
                                                                        rows={3}
                                                                        autoFocus
                                                                    />
                                                                    :
                                                                    <input hidden value={null} name="description" />
                                                                }
                                                                <span
                                                                    className="link"
                                                                    style={{
                                                                        width: 'fit-content',
                                                                        marginTop: '10px',
                                                                    }}
                                                                    onClick={handleAddNote}
                                                                >
                                                                    {state.addNote ? 'Премахване' : 'Добавяне'} на забележка
                                                                </span>
                                                            </div>

                                                            <div className="row" style={{
                                                                justifyContent: 'flex-end',
                                                                alignItems: 'center',
                                                            }}>
                                                                <div className="col" style={{
                                                                    maxWidth: '260px'
                                                                }}>
                                                                    <SaveButton
                                                                        disabled={state.loading || state.data.calculating === 1}
                                                                        className="save"
                                                                        onClick={handleSetAll}
                                                                    >
                                                                        Занули пропуснатите
                                                                    </SaveButton>
                                                                </div>
                                                                <div className="col" style={{
                                                                    maxWidth: '260px'
                                                                }}>
                                                                    <RedditSelect
                                                                        name="reasonId"
                                                                        value={state.reasonId || ''}
                                                                        onChange={e => {
                                                                            const { value } = e.target

                                                                            handleSetReason(JSON.parse(value))
                                                                        }}
                                                                        label="Задай причина за всички"
                                                                    >
                                                                        {reasons.map(r =>
                                                                            <MenuItem key={r.id} value={JSON.stringify(r)} data-name={r.name}>
                                                                                {r.name}
                                                                            </MenuItem>
                                                                        )}
                                                                    </RedditSelect>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br />
                                                        <div style={{ width: '100%' }}>
                                                            <Pagination
                                                                className="mt-3"
                                                                page={state.rows.filter.page}
                                                                pages={state.rows.pages}
                                                                total={state.rows.total}
                                                                handlePage={handlePage}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </>
                        }
                    </div>

                    <div className="footer">
                        <Menu
                            anchorEl={state.saveButtonMenu}
                            elevation={0}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            open={Boolean(state.saveButtonMenu)}
                            onClose={closeMenu}
                            onClick={closeMenu}
                        >
                            <MenuItem
                                onClick={handleSave}
                            >
                                Запази и продължи
                            </MenuItem>
                            <MenuItem
                                onClick={handleSaveAndClose}
                            >
                                Запази и приключи
                            </MenuItem>

                        </Menu>
                        {state.data.allow_delete &&
                            <Button
                                className="remove"
                                onClick={handleDelete}
                                style={{ marginRight: 'auto' }}>
                                Изтрий
                            </Button>
                        }
                        <Button
                            className="cancel"
                            onClick={handleClose}
                        >
                            Отказ
                        </Button>
                        <SaveButton
                            className="save with-dropdown"
                            loading={state.loading || state.loadingBase || state.loadingExit || (state.data.calculating === 1)}
                            disabled={state.loadingExit}
                            onClick={handleSaveAndExit}
                        >
                            Запази
                            <i
                                className="menu"
                                onClick={openMenu}
                            >
                                <img src={dropArrowIcon} />
                            </i>
                        </SaveButton>
                    </div>
                </div>
            </div>
            <Question
                ref={saveAndCloseModalRef}
                mainMessage="Сигурни ли сте, че искате да затворите тази ревизия?"
            />

            <Error
                ref={errorModalRef}
            />

            <Import
                ref={importModalRef}
                type={TYPES.REVISION}
                createText="Замяна"
                updateText="Добавяне"
            />

            <SetEmpty
                ref={setEmptyModalRef}
            />
        </>
    )
}

export default forwardRef(Index)