import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

//constatns
import { PAYORDER_TYPE } from 'constants/Document'

//MUI components
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { Button } from '@material-ui/core'

function AddButton() {
    const [anchorEl, setAnchorEl] = useState(null)

    const history = useHistory()

    const handleClick = e => {
        setAnchorEl(e.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleAddBudgetPayment = () => {
        history.push('/payorders/add?type_id=' + PAYORDER_TYPE.BUDGET_PAYMENT)
    }

    const handleAddBudgetPaymentMulti = () => {
        history.push('/payorders/add?type_id=' + PAYORDER_TYPE.BUDGET_PAYMENT_MULTI)
    }

    const handleAddCreditOrder = () => {
        history.push('/payorders/add?type_id=' + PAYORDER_TYPE.CREDIT_ORDER)
    }

    const handleAddDebitOrder = () => {
        history.push('/payorders/add?type_id=' + PAYORDER_TYPE.DEBIT_ORDER)
    }

    const handleAddPaymentNote = () => {
        history.push('/payorders/add?type_id=' + PAYORDER_TYPE.PAYMENT_NOTE)
    }

    const handleAddCashOrderIncome = () => {
        history.push('/payorders/add?type_id=' + PAYORDER_TYPE.CASH_ORDER_INCOME)
    }

    const handleAddCashOrderDebit = () => {
        history.push('/payorders/add?type_id=' + PAYORDER_TYPE.CASH_ORDER_DEBIT)
    }

    return (
        <>
            <Button
                onClick={handleClick}
                className="add"
            >
                Нов документ
            </Button>
            <Menu
                anchorEl={anchorEl}
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                onClick={handleClose}
            >
                <MenuItem onClick={handleAddBudgetPayment}>
                    Плащане от/към бюджета
                </MenuItem>
                <MenuItem onClick={handleAddBudgetPaymentMulti}>
                    Плащане от/към бюджета (многоредово)
                </MenuItem>
                <MenuItem onClick={handleAddCreditOrder}>
                    Кредитен превод
                </MenuItem>
                <MenuItem onClick={handleAddDebitOrder}>
                    Директен дебит
                </MenuItem>
                <MenuItem onClick={handleAddPaymentNote}>
                    Вносна бележка
                </MenuItem>
            </Menu>
        </>
    )
}

export default AddButton