//miscs
import Autocomplete from "components/misc/Autocomplete"

//providers
import { usePublicPreviewContext } from './PublicPreviewProvider'

const SettlementInputField = () => {
    const publicPreview = usePublicPreviewContext()

    const handleCustomerSettlementChange = data => {
        if(data.id){
            publicPreview.setDocument(prev => ({
                ...prev,
                'settlement[name]'              : data.name,
                'settlement[id]'                : data.id,
                'settlement[municipality_id]'   : data.municipality_id
            }))
        }
    }

    return (
        <>
            <Autocomplete
                inputPlaceholder="Населено място"
                inputName={`customeraddress[settlement][name]`}
                inputIdName={`customeraddress[settlement][id]`}
                url="settlements/all"
                selectedId={publicPreview.getSettlementId() || null}
                selectedValue={publicPreview.getSettlement() || ''}
                getOptionLabel={data => data?.name || ''}
                renderOption={option => {
                    return [
                        option.name,
                        option.municipality ? 'общ. ' + option.municipality.name : null,
                        option.municipality && option.municipality.district ? 'обл. ' + option.municipality.district.name : null
                    ].filter(o => {
                        return o !== null
                    }).join(', ')
                }}
                onChange={handleCustomerSettlementChange}
                onInputChange={handleCustomerSettlementChange}
            />
        </>
    )
}

export default SettlementInputField