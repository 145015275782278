//components
import Layout from "components/Layout"
import ComplaintsList from "components/pages/customers/partials/ComplaintsList"

//libraries
import { Button } from "@material-ui/core"

//stores
import { useShowModal } from "stores/customer/useAddToComplaintsModal"

export default function Complaints() {
  const { showModal } = useShowModal()

  function openAddToCommunicationsModal() {
    showModal(true)
  }

  return (
    <Layout>
      <div className="container-fluid">
        <div className="head-with-heading">
          <h3>Рекламация</h3>
          <div className="buttons">
            <Button className="add" onClick={openAddToCommunicationsModal}>
              Нова рекламация
            </Button>
          </div>
        </div>
        <div
          className="panel"
          style={{
            marginTop: 0,
          }}
        >
          <div className="complaints">
            <ComplaintsList />
          </div>
        </div>
      </div>
    </Layout>
  )
}
