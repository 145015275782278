//images
import clockIcon from "assets/img/customers/icons/clock.png"

//components
import MenuOptions from "components/misc/MenuOptions"
import CampaignOptions from "./CampaignOptions"
import moment from "moment"
import CampaignClients from "./CampaignClients"

function CampaignsList({ campaigns }) {
  return (
    <ul className="campaigns-list">
      {campaigns.map(
        ({ id, title, status, type, clients, startDate, endDate }) => (
          <li key={id}>
            <MenuOptions>
              <CampaignOptions handleDelete={() => {}} handleEdit={() => {}} />
            </MenuOptions>
            <div className="row">
              <div className="col">
                <span className="label">Статус</span>
                <b>
                  <span
                    className="status"
                    style={{
                      backgroundColor: status.color,
                    }}
                  ></span>
                  {status.title}
                </b>
              </div>
              <div className="col">
                <span className="label">Тип</span>
                <b>{type.title}</b>
              </div>
            </div>
            <b>{title}</b>
            <CampaignClients clients={clients} />
            <hr />
            <div className="row">
              <div className="col">
                <span className="label">
                  <img src={clockIcon} alt="" />
                  Начало
                </span>
                <b>
                  {moment(startDate, "YYYY-MM-DD").format("ddd, DD MMM YYYY")}
                </b>
              </div>
              <div className="col">
                <span className="label">
                  <img src={clockIcon} alt="" />
                  Край
                </span>
                <b>
                  {moment(endDate, "YYYY-MM-DD").format("ddd, DD MMM YYYY")}
                </b>
              </div>
            </div>
          </li>
        )
      )}
    </ul>
  )
}

export default CampaignsList
