import { useState, useImperativeHandle, forwardRef } from "react"
import { useLocation } from "react-router-dom"
import Button from "@material-ui/core/Button"
import SaveButton from "../../../../misc/Button"

//images
import closeIcon from "assets/img/modals/icons/close.png"
import exportIcon from "assets/img/documents/icons/export-white.png"
import downloadIcon from "assets/img/documents/icons/download.png"
import sendIcon from "assets/img/documents/icons/send.png"
import leftArrowIcon from "assets/img/documents/icons/left-arrow.png"
import downloadZipIcon from "assets/img/app/icons/download-colored.png"
import downloadPdfIcon from "assets/img/app/icons/download-pdf.png"
import loader from "assets/img/loader.svg"

import { useValidation } from "helpers/Validation"

//helpers
import Api from "helpers/Api"

//libraries
import { MenuItem } from "@material-ui/core"
import { useAuthDataContext } from "providers/Auth"
import RedditSelect from "components/misc/RedditSelect"

//hooks
import useFindFilters from "../../hooks/useFindFilters"

//helpers
import { useDocumentContext } from "../../Provider"

function Export({ typeId, getSelectedCount }, ref) {
  const location = useLocation()

  const auth = useAuthDataContext()
  const context = useDocumentContext()
  const { getSearchParam, documentReqData } = context

  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState(false)

  const [state, setState] = useState({
    ids: [],
    data: null,
    overlay: false,
    modal: false,
    params: null,
    onSuccess: null,
    onError: null,
    onClose: null,
  })

  const {
    getSearch,
    getDocTypes,
    getPeriod,
    getSent,
    getPaymentStatus,
    getPaymentMethod,
  } = useFindFilters()

  const [validations, setValidations] = useValidation()

  useImperativeHandle(ref, () => ({
    open: (ids) => {
      handleOpen(ids)
    },
    close: () => {
      handleHide()
    },
    onSuccess: (fn) => {
      setState((prev) => ({
        ...prev,
        onSuccess: fn,
      }))
    },
    onError: (fn) => {
      setState((prev) => ({
        ...prev,
        onError: fn,
      }))
    },
    onClose: (fn) => {
      setState((prev) => ({
        ...prev,
        onClose: fn,
      }))
    },
  }))

  const handleOpen = (ids) => {
    if (!Array.isArray(ids)) {
      ids = [ids]
    }

    setState((prev) => ({
      ...prev,
      overlay: true,
      ids: ids,
    }))

    setTimeout(() => {
      setState((prev) => ({
        ...prev,
        modal: true,
      }))
    }, 50)
  }

  const handleHide = () => {
    setState((prev) => ({
      ...prev,
      modal: false,
    }))

    setTimeout(() => {
      setState((prev) => ({
        ...prev,
        overlay: false,
      }))
      setStep(1)
      setLoading(false)
    }, 100)

    resetData()
    setValidations(null)

    if (typeof state.onClose === "function") {
      state.onClose()
    }
  }

  const resetData = () => {
    setState((prev) => ({
      ...prev,
      ids: [],
      data: null,
    }))
  }

  const handleInputChange = (e) => {
    const { name } = e.target
    const { value } = e.target

    setState((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        [name]: value,
      },
    }))
  }

  const handleSave = (e) => {
    e.preventDefault()
    setStep(2)

    setLoading(true)

    setValidations(null)

    let url = "documents/export"

    let data = new FormData(e.target)
    if (state.ids[0]) {
      state.ids.map((id) => {
        data.append("id[]", id)
      })
    } else {
      url += location.search
    }

    Api.post(url, data)
      .then((res) => {
        if (res.data.url) {
          window.open(res.data.url + "&token=" + auth.getToken())
        }

        if (typeof state.onSuccess === "function") {
          state.onSuccess(res.data)
        }

        handleHide()
      })
      .catch((error) => {
        let _err = error.response

        if (_err && _err.status && _err.status === 422) {
          setValidations(_err.data.errors)
        } else {
          if (typeof state.onError === "function") {
            state.onError(error)
          }
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  //Определя дали е клинат бутона за експорт и файловете са започнали да се генерират на сървъра (сме преминали към 2ра стъпка)
  function isReady() {
    return step === 2
  }

  function getAllFilesCount() {
    if (!typeId) return 0
    return documentReqData?.total || 0
  }

  function handleBack() {
    setStep(1)
  }

  function getAffectedDocumentsCount() {
    if (state.ids[0]) {
      return getSelectedCount ? getSelectedCount() : 1
    }

    return getAllFilesCount()
  }

  return (
    <div className={`${state.overlay ? "visible" : ""} overlay`}>
      <form
        className={`${
          state.modal ? "show" : ""
        } export-modal popup-primary small`}
        noValidate
        onSubmit={handleSave}
      >
        <div className="body">
          <h3 className="head">
            Експортиране на данни
            <img
              className="close-icon"
              alt="close"
              src={closeIcon}
              onClick={handleHide}
            />
          </h3>
          <p>
            Изтеглете необходимите документи в избран формат или ги изпратете
            директно към Вашият счетоводител.
          </p>
          <div className="filters">
            <b>Приложени филтри:</b>
            <table>
              <tbody>
                {(getSearchParam("search") || "").length ? (
                  <tr>
                    <td>Търсене:</td>
                    <td>
                      <span>{getSearch()}</span>
                      <input
                        type="hidden"
                        name="search"
                        value={getSearchParam("search") || ""}
                      />
                    </td>
                  </tr>
                ) : (
                  <></>
                )}
                {(getSearchParam("type_id") || "").length || typeId ? (
                  <tr>
                    <td>Тип документи:</td>
                    <td>
                      <span>{getDocTypes(typeId)}</span>
                      <input
                        type="hidden"
                        name="type_id"
                        value={getSearchParam("type_id") || ""}
                      />
                    </td>
                  </tr>
                ) : (
                  <></>
                )}
                {(
                  getSearchParam("period") ||
                  getSearchParam("start_date") ||
                  getSearchParam("end_date") ||
                  ""
                ).length ? (
                  <tr>
                    <td>За период:</td>
                    <td>
                      <span>{getPeriod()}</span>
                      <input
                        type="hidden"
                        name="period"
                        value={getSearchParam("period") || ""}
                      />
                      <input
                        type="hidden"
                        name="start_date"
                        value={getSearchParam("start_date") || ""}
                      />
                      <input
                        type="hidden"
                        name="end_date"
                        value={getSearchParam("end_date") || ""}
                      />
                    </td>
                  </tr>
                ) : (
                  <></>
                )}
                {(getSearchParam("sent") || "").length ? (
                  <tr>
                    <td>Изпратени:</td>
                    <td>
                      <span>{getSent()}</span>
                      <input
                        type="hidden"
                        name="sent"
                        value={getSearchParam("sent") || ""}
                      />
                    </td>
                  </tr>
                ) : (
                  <></>
                )}
                {(getSearchParam("status") || "").length ? (
                  <tr>
                    <td>Статус:</td>
                    <td>
                      <span>{getPaymentStatus()}</span>
                      <input
                        type="hidden"
                        name="status"
                        value={getSearchParam("status") || ""}
                      />
                    </td>
                  </tr>
                ) : (
                  <></>
                )}
                {(getSearchParam("payment_method_id") || "").length ? (
                  <tr>
                    <td>Метод на плащане:</td>
                    <td>
                      <span>{getPaymentMethod()}</span>
                      <input
                        type="hidden"
                        name="payment_method_id"
                        value={getSearchParam("payment_method_id") || ""}
                      />
                    </td>
                  </tr>
                ) : (
                  <></>
                )}
              </tbody>
            </table>
          </div>
          <div className="row">
            <div className="col">
              <RedditSelect
                label="За програма"
                name="type"
                value={state.data?.type || ""}
                error={Boolean(validations && validations.type)}
                helperText={
                  validations &&
                  validations.type &&
                  (validations.type[0] || validations.type)
                }
                onChange={handleInputChange}
                disabled={isReady()}
                className={isReady() ? "disabled" : ""}
              >
                <MenuItem value="csv">CSV</MenuItem>
                <MenuItem value="excel">Excel</MenuItem>
                <MenuItem value="microinvest_delta_pro">
                  Microinvest Delta Pro
                </MenuItem>
                <MenuItem value="ajur_l">АжурL</MenuItem>
                <MenuItem value="plus_minus">ПЛЮС-МИНУС</MenuItem>
              </RedditSelect>
            </div>
          </div>
          {isReady() ? (
            loading ? (
              <img src={loader} alt="loading" />
            ) : (
              <ul className="documents-list">
                <li>
                  <img
                    src={
                      getAffectedDocumentsCount() === 1
                        ? downloadPdfIcon
                        : downloadZipIcon
                    }
                    alt=""
                  />
                  {getAffectedDocumentsCount() === 1
                    ? "Избран_документ.pdf"
                    : "Списък_с_документи.zip"}
                </li>
              </ul>
            )
          ) : (
            <div className="total-export">
              Избрани документи: <span>{getAffectedDocumentsCount()}</span>
            </div>
          )}
        </div>
        <div className="footer">
          {isReady() ? (
            <>
              <Button className="cancel back" onClick={handleBack}>
                <img src={leftArrowIcon} alt="" />
                Назад
              </Button>
              <SaveButton
                className="save d"
                text={
                  <>
                    <img src={downloadIcon} alt="" /> Изтегли
                  </>
                }
              />
              <SaveButton
                className="save s"
                text={
                  <>
                    <img src={sendIcon} alt="" /> Изпрати
                  </>
                }
              />
            </>
          ) : (
            <>
              <Button className="cancel" onClick={handleHide}>
                Отказ
              </Button>
              <SaveButton
                className="save"
                text={
                  <>
                    <img src={exportIcon} alt="" /> Експорт
                  </>
                }
                disabled={!state.data?.type}
              />
            </>
          )}
        </div>
      </form>
    </div>
  )
}

export default forwardRef(Export)
