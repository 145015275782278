//images
import brand from 'assets/img/app/brand-full.png'
import nextIcon from 'assets/img/documents/icons/forward.png'
import checkIcon from 'assets/img/verify/icons/check-with-decor.png'

//MUI components
import { Button } from '@material-ui/core'

//components
import Footer from './partials/Footer'

function Index() {

    const handleNavigateToHome = () => {
        window.location.href = '/'
    }

    return (
        <section id="company-delete">
            <div className="wrapper">
                <img src={brand} className="brand" />
                <img src={checkIcon} className="main-image" />
                <h1>
                    Фирмата е изтрита успешно!
                </h1>
                <p className="description">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </p>
                <Button
                    className="save"
                    onClick={handleNavigateToHome}
                >
                    Към начало
                    <img src={nextIcon} />
                </Button>
                <Footer />
            </div>
        </section>
    )
}

export default Index;