import { useState, useImperativeHandle, forwardRef } from 'react'
import ClickAwayListener from 'react-click-away-listener'

//MUI components
import { Checkbox, Button, Tooltip } from '@material-ui/core'

//misc
import SaveButton from 'components/misc/Button'
import RedditTextField from 'components/misc/RedditTextField'

//images
import closeIcon from 'assets/img/modals/icons/close.png'
import sentIcon from 'assets/img/modals/icons/sent.png'
import editIcon from 'assets/img/documents/icons/edit.png'
import accountantIcon from 'assets/img/modals/icons/accountant.png'
import accountantWhiteIcon from 'assets/img/modals/icons/accountant-white.png'

//heleprs
import { useValidation } from 'helpers/Validation'
import Api from 'helpers/Api'
import { useNestedState } from 'helpers/NestedState'
import { shortenToMaximumFirstAndLastName } from 'components/pages/documents/helpers/shorten'

//providers
import { useLanguageContext } from 'providers/Language'
import { useAppContext } from 'providers/App'

const initialState = {
    overlay: false,
    modal: false,
    params: null,
    onSuccess: null,
    onError: null,
    onClose: null,
    editName: false,
}
function Index(props, ref) {
    const { getSendCaseType, clearParentData } = props
    const app = useAppContext()

    const [state, setState] = useState({
        ...initialState,
        author: ''
    })

    const [contacts, setContacts] = useNestedState({
        data: []
    })

    const [validations, setValidations] = useValidation()

    useImperativeHandle(ref, () => ({
        open: (contacts) => {
            handleOpen(contacts)
        },
        close: () => {
            handleHide()
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        },
        onError: fn => {
            setState(prev => ({
                ...prev,
                onError: fn
            }))
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }))
        }
    }))

    const handleOpen = contacts => {
        setState(prev => ({
            ...prev,
            overlay: true,
            author: shortenToMaximumFirstAndLastName(getSendCaseType?.people?.author)
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                modal: true,
            }))
        }, 50)

        const contactsData = []

        contacts.map(email => {
            let data = {
                email: email,
                name: getSendCaseType.caseType === 3 ? !getSendCaseType?.people?.email?.length ? getSendCaseType?.people?.author : '' : '',
                company_name: getSendCaseType?.caseType === 3 ? getSendCaseType?.people?.company : '',
                checked: true
            }

            contactsData.push(data)
        })

        setContacts(prev => ({
            ...prev,
            data: contactsData
        }))
    }

    const handleHide = () => {
        setState(prev => ({
            ...prev,
            modal: false,
        }))

        setTimeout(() => {
            setState(initialState)
        }, 100)

        setValidations(null)

        if (typeof state.onClose === 'function') state.onClose()
    }

    const handleInputChange = e => {
        const { name, value } = e.target

        setContacts(name, value, '')
    }

    const handleCheckboxChange = index => {
        setContacts(prev => ({
            ...prev,
            data: Object.values({
                ...prev.data,
                [index]: {
                    ...prev.data[index],
                    checked: !prev.data[index].checked
                }
            })
        }))
    }

    const handleCheckAccountant = index => {
        setContacts(prev => ({
            ...prev,
            data: Object.values({
                ...prev.data,
                [index]: {
                    ...prev.data[index],
                    accountant: !prev.data[index].accountant
                }
            })
        }))
    }

    const handleSave = e => {
        e.preventDefault()

        if (!hasChecked()) {
            handleHide()
            clearParentData()

            return
        }

        setLoading(true)
        setValidations(null)

        const data = new FormData(e.target)

        Api.post('contacts/save', data)
            .then(() => {
                handleHide()
                clearParentData()
                app.handleSuccess('Успешно запазихте Вашите контакти.')
            })
            .catch(error => {
                const _err = error.response

                if (_err && _err.status && _err.status === 422) {
                    setValidations(_err.data.errors)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const setLoading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    const hasChecked = () => {
        let checked = false

        contacts.data.map(d => {
            if (d.checked) {
                checked = true
            }
        })

        return checked
    }

    /**
     * 
     * @param {string} name име на потребител/клиент/получател 
     * @returns първите букви от име и фамилия/бащино име
     */
    const getFirstLetters = name => {
        if (!name || !name.includes(' ')) return

        let firstLetters = name.split(' ')
        firstLetters = `${firstLetters[0]?.charAt(0) || ''} ${firstLetters[1]?.charAt(0) || ''}`

        return firstLetters
    }

    const handleEditName = (editName = false) => {
        setState(prev => ({
            ...prev,
            editName
        }))
    }

    const handleChangeName = (name, value) => {
        setState(prev => ({
            ...prev,
            [name]: value
        }))
    }

    return (
        <div className={`${state.overlay ? 'visible' : ''} overlay`}>
            <form className={`${state.modal ? 'show' : ''} send-success popup-primary medium`} noValidate onSubmit={handleSave}>
                <div className="body">
                    <img src={sentIcon} alt="sent" className="main-image" />
                    <h3>
                        Изпращането беше успешно!
                    </h3>
                    {contacts.data.length > 0
                        ?
                        <div className="save-holder">
                            <h4>
                                Запазете новите контакти
                            </h4>
                            <p>
                                Съхранете пощата на клиента, за да изпращате автоматично документи към него.
                            </p>
                            <hr
                                style={{
                                    margin: '20px auto',
                                    maxWidth: '300px'
                                }}
                            />
                            <ul className={getSendCaseType.caseType !== 1 ? 'custom-input' : ''}>
                                {contacts.data.map((contact, i) =>
                                    <li
                                        key={i}
                                        className={`row contact ${Boolean(validations && validations.data && validations.data[i]) ? 'validate' : ''}`}
                                    >
                                        {getSendCaseType.caseType === 1 ?
                                            <>
                                                {contact.checked ?
                                                    <>
                                                        <input type="hidden" name={`data[${i}][name]`} value={state.author} />
                                                        <input type="hidden" name={`data[${i}][company_name]`} value={getSendCaseType.people.company} />
                                                    </>
                                                    :
                                                    <>
                                                    </>
                                                }
                                                <div className="col">
                                                    <Checkbox
                                                        checked={contact.checked}
                                                        onChange={() => {
                                                            handleCheckboxChange(i)
                                                        }}
                                                        name={`data[${i}][email]`}
                                                        value={contact.email}
                                                        color="primary"
                                                    />
                                                </div>
                                                <div
                                                    className="col person"
                                                >
                                                    <div
                                                        className="heading"
                                                        onClick={() => {
                                                            handleEditName(true)
                                                        }}
                                                    >
                                                        <picture>
                                                            {getFirstLetters(state.author)}
                                                        </picture>
                                                        {state.editName ?
                                                            <ClickAwayListener
                                                                onClickAway={() => {
                                                                    handleEditName(false)
                                                                }}
                                                            >
                                                                <input
                                                                    type="text"
                                                                    value={state.author}
                                                                    autoFocus
                                                                    onChange={e => {
                                                                        handleChangeName('author', e.target.value)
                                                                    }}
                                                                />
                                                            </ClickAwayListener>
                                                            :
                                                            <>
                                                                {state.author}
                                                                <img src={editIcon} />
                                                            </>
                                                        }
                                                    </div>
                                                    <div className="text">
                                                        {getSendCaseType.people.company}
                                                    </div>
                                                    <a href={`mailto:${contact.email}`} className="email link">
                                                        {contact.email}
                                                    </a>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className="row top-row">
                                                    <div className="col">
                                                        <Checkbox
                                                            checked={contact.checked}
                                                            onChange={() => {
                                                                handleCheckboxChange(i)
                                                            }}
                                                            name={`data[${i}][email]`}
                                                            value={contact.email}
                                                            color="primary"
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <a href={`mailto:${contact.email}`} className="email link">
                                                            {contact.email}
                                                        </a>
                                                    </div>
                                                    {(getSendCaseType.people.guessType === 'accountant') && contact.checked ?
                                                        <Tooltip title={contact.accountant ? 'Тази е-поща не е на счетоводител' : 'Отбелязване като счетоводител'}>
                                                            <Button
                                                                className={`accountant ${contact.accountant ? 'active' : ''}`}
                                                                onClick={() => {
                                                                    handleCheckAccountant(i)
                                                                }}
                                                            >
                                                                <img src={contact.accountant ? accountantWhiteIcon : accountantIcon} />
                                                            </Button>
                                                        </Tooltip>
                                                        :
                                                        <>
                                                        </>
                                                    }
                                                </div>
                                                {contact.accountant ?
                                                    <input type="hidden" name={`data[${i}][accountant]`} value={1} />
                                                    :
                                                    <>
                                                    </>
                                                }
                                                {contact.checked ?
                                                    <div className="row">
                                                        <div className="col">
                                                            <RedditTextField
                                                                size="small"
                                                                margin="dense"
                                                                fullWidth
                                                                label="Име и Фамилия"
                                                                name={`data[${i}][name]`}
                                                                value={contact.name || ''}
                                                                onChange={handleInputChange}
                                                                error={Boolean(validations && validations.data && validations.data[i] && validations.data[i].name)}
                                                                helperText={validations && validations.data && validations.data[i] && validations.data[i].name && (validations.data[i].name[0] || validations.data[i].name)}
                                                                required
                                                            />
                                                        </div>
                                                        <div className="col">
                                                            <RedditTextField
                                                                size="small"
                                                                margin="dense"
                                                                fullWidth
                                                                label="Фирма"
                                                                name={`data[${i}][company_name]`}
                                                                value={contact.company_name || ''}
                                                                onChange={handleInputChange}
                                                                error={Boolean(validations && validations.data && validations.data[i] && validations.data[i].company_name)}
                                                                helperText={validations && validations.data && validations.data[i] && validations.data[i].company_name && (validations.data[i].company_name[0] || validations.data[i].company_name)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    :
                                                    <>
                                                    </>
                                                }
                                            </>
                                        }
                                    </li>
                                )}
                            </ul>
                        </div>
                        :
                        <p>
                            Съхраняването на текущата поща Ви помага при изпращането на съобщения в бъдеще към клиента, като системата разпознава автоматично файла и неговия получател.
                        </p>
                    }
                </div>
                <div className="footer">
                    <SaveButton
                        loading={state.loading}
                        text={hasChecked() ? 'Запазване' : 'Разбрах'}
                        className="save"
                    />
                </div>
            </form>
        </div>
    )
}

export default forwardRef(Index)