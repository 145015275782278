import { Card } from 'react-bootstrap';
import OrderHistory from './partials/OrderHistory';
import RowsHistory from './partials/RowsHistory';

function Index(props) {

    return (
        <>
            <Card className="mb-3">
                <Card.Header>
                    История на промените на поръчката
                </Card.Header>
                <Card.Body>
                    <OrderHistory
                        id={props.id}
                    />
                </Card.Body>
            </Card>

            <br />
            <Card className="">
                <Card.Header>
                    История на промените в артикулите
                </Card.Header>
                <Card.Body>
                    <RowsHistory
                        id={props.id}
                    />
                </Card.Body>
            </Card>
        </>
    )
}

export default Index;