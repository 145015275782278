//MUI components
import MenuItem from '@material-ui/core/MenuItem'

//images
import editIcon from 'assets/img/app/icons/edit.png'
import deleteIcon from 'assets/img/app/icons/delete.png'

function Options(props) {
    const deleteAccess = props.data.allow_delete
    const { id } = props.data


    return (
        <>
            <MenuItem
                onClick={() => {
                    props.handleEdit(id)
                }}
            >
                <img src={editIcon} />
                Редакция
            </MenuItem>
            {deleteAccess ?
                <MenuItem
                    onClick={() => {
                        props.handleDelete(id)
                    }}
                >
                    <img src={deleteIcon} />
                    Изтриване
                </MenuItem>
                :
                <>
                </>
            }
        </>
    )
}

export default Options