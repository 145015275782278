import { forwardRef, useImperativeHandle, useState } from "react"

//libraries
import { Menu, MenuItem } from "@material-ui/core"

function CreateSaleMenu({ createSaleButtonRef, externalAddSale }, ref) {
  const [open, setOpen] = useState(false)

  useImperativeHandle(ref, () => ({
    open: showMenu,
  }))

  function closeMenu() {
    setOpen(false)
  }

  function showMenu() {
    setOpen(true)
  }

  return (
    <Menu
      anchorEl={createSaleButtonRef.current}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      keepMounted
      open={open}
      onClose={closeMenu}
      onClick={closeMenu}
      className="create-documents-menu"
    >
      <MenuItem
        onClick={() => {
          externalAddSale(1)
        }}
      >
        <span className="image">O</span>
        Изписване
      </MenuItem>
    </Menu>
  )
}

export default forwardRef(CreateSaleMenu)
