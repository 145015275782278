//images
import brand from 'assets/img/app/brand-full.png'
import nextIcon from 'assets/img/documents/icons/forward.png'
import checkIcon from 'assets/img/verify/icons/check-with-decor.png'

//MUI components
import { Button } from '@material-ui/core'

//components
import Footer from '../partials/Footer'

function Index() {

    const handleNavigateToHome = () => {
        window.location.href = '/'
    }

    return (
        <section id="verify-success">
            <div className="wrapper">
                <img src={brand} className="brand" />
                <img src={checkIcon} className="main-image" />
                <h1>
                    Готово!
                </h1>
                <p className="description">
                    Вашият профил вече е потвърден!
                    <br />
                    Натиснете бутона "Продължете" и влезте в профилa си.
                </p>
                <Button
                    className="save"
                    onClick={handleNavigateToHome}
                >
                    Продължете
                    <img src={nextIcon} />
                </Button>
                <Footer />
            </div>
        </section>
    )
}

export default Index;