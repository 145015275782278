import { useRef, useState } from "react"

//librarires
import { Menu, MenuItem } from "@material-ui/core"
import printJS from "print-js"

//providers
import { useAuthDataContext } from "providers/Auth"

//helpers
import { isProd } from "helpers/mode"

function PrintButtonLayout({
  children = <></>,
  printUrl,
  multipleDocumentsIds,
  openPrintUrlInNewTab,
}) {
  const APP_URL = process.env[isProd ? "REACT_APP_URL" : "REACT_APP_URL_DEV"]

  const auth = useAuthDataContext()

  const childrenHolderRef = useRef()

  const [openMenu, setOpenMenu] = useState(false)
  const [printLoading, setPrintLoading] = useState(false)

  const handlePrintLoading = (printLoading) => {
    setPrintLoading(printLoading)
  }

  function handlePrint(pdfUrl, type) {
    const printUrl = `${pdfUrl}${getType(type)}`

    if (openPrintUrlInNewTab) {
      window.open(printUrl, "_blank")

      return
    }
    
    printJS({
      printable: printUrl,
      onLoadingStart: () => {
        handlePrintLoading(true)
      },
      onLoadingEnd: () => {
        handlePrintLoading(false)
      },
      onError: (error) => {
        console.log(error)
      },
    })
  }

  function handlePrintMultiple(type) {
    if (multipleDocumentsIds.length) {
      let url = `${APP_URL}documents/bulk-print?`
      multipleDocumentsIds.map((id, i) => {
        url += `${i === 0 ? "" : "&"}ids[]=${id}`
      })

      url += `${getType(type)}&token=${auth.getToken()}`

      handlePrint(url)
    }
  }

  function getType(type) {
    switch (type) {
      case "copy":
        return "&options[copy]=1"

      case "withcopy":
        return "&options[withcopy]=1"

      default:
        return ""
    }
  }

  function handleOpenMenu() {
    setOpenMenu(true)
  }

  function handleCloseMenu() {
    setOpenMenu(false)
  }

  return (
    <>
      <Menu
        anchorEl={childrenHolderRef.current}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        keepMounted
        open={openMenu}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
      >
        <MenuItem
          onClick={() => {
            const type = "original"
            if (multipleDocumentsIds) {
              handlePrintMultiple(type)
              return
            }
            handlePrint(printUrl, type)
          }}
        >
          Принтирай оригинал
        </MenuItem>
        <MenuItem
          onClick={() => {
            const type = "copy"
            if (multipleDocumentsIds) {
              handlePrintMultiple(type)
              return
            }
            handlePrint(printUrl, type)
          }}
        >
          Принтирай копие
        </MenuItem>
        <MenuItem
          onClick={() => {
            const type = "withcopy"

            if (multipleDocumentsIds) {
              handlePrintMultiple(type)
              return
            }
            handlePrint(printUrl, type)
          }}
        >
          Принтирай oригинал + копие
        </MenuItem>
      </Menu>
      <div
        ref={childrenHolderRef}
        onClick={handleOpenMenu}
        className={`print-button-layout ${printLoading ? "disabled" : ""}`}
      >
        {children}
      </div>
    </>
  )
}

export default PrintButtonLayout
