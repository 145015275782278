const Graphs = props => {
    const {
        currentPage,
        allPages,
        isCurrentPageDataVisible
    } = props
    const calcAllPagesPercentage = () => (allPages.paidPrice / allPages.grossPrice * 100)
    const calcCurrentPagePercentage = () => (currentPage.paidPrice / currentPage.grossPrice * 100)

    return (
        <ul className="graphs">
            {isCurrentPageDataVisible ?
                <li>
                    <div style={{ width: `${calcCurrentPagePercentage()}%` }}>

                    </div>
                </li>
                :
                <>
                </>
            }
            <li>
                <div style={{ width: `${calcAllPagesPercentage()}%` }}>

                </div>
            </li>
        </ul>
    )
}

export default Graphs