//MUI components
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

//providers
import { useAppContext } from 'providers/App'

//images
import loadingImage from 'assets/img/loader.svg'

function Alert(props) {
    return <MuiAlert
        elevation={6}
        variant="filled"
        {...props}
    />
}

function SnackBar(props, ref) {
    const app = useAppContext()
    const { severity, message, open } = props

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={open}
            autoHideDuration={6000}
        >
            <Alert
                onClose={() => {
                    app.handleHideSuccess()
                    app.handleHideError()
                    app.handleHideInfo()
                }}
                severity={severity}
                className={`${severity}-message`}
            >
                <div
                    style={{
                        display: 'block',
                        minWidth: '200px'
                    }}
                >
                    {message || ''}
                </div>
            </Alert>
        </Snackbar>
    )
}

export default SnackBar