import Company from "./Company"

export default class User {

    company = null;
    companies = [];

    constructor(data) {
        this.data = data
    }

    // isLogged() {
    //     return this.getId() ? true : false;
    // }

    getId() {
        if (this.data) {
            return this.data.id
        }
        return null
    }

    getData() {
        return this.data
    }

    getName() {
        return this.data?.name
    }

    getLastname() {
        return this.data?.lastname
    }

    getFullname() {
        return this.data?.fullname
    }

    getPhone() {
        return this.data?.phone
    }


    getEmail() {
        return this.data?.email
    }

    getAvatar() {
        return this.data?.avatar
    }

    getDeleteAccess() {
        return this.data?.allow_delete
    }

    hasStoreAccess() {
        return this.data?.has_store_access
    }

    hasOrdersAccess() {
        return this.data?.has_orders_access
    }

    hasCRMAccess() {
        return this.data?.has_crm_access
    }

    hasSubscriptionAccess() {
        return this.data?.has_subscriptions_access
    }

    getType() {
        if (this.data) {
            return this.data.type
        }
        return null
    }

    getRole() {
        if (this.data) {
            return this.data.role?.translation?.name
        }
        return null
    }

    // access(key) {

    //     if (this.isSuperAdmin()) {
    //         return true;
    //     }

    //     if (this.data && this.data.access && typeof this.data.access === 'object') {
    //         return this.data.access[key] || false;
    //     }

    //     return false;
    // }

    module(key) {
        if (this.isCompanyOwner()) {
            return true
        }

        if (this.data) {
            if (Array.isArray(this.data.modules)) {
                return this.data.modules.includes(key)
            }
        }

        return false
    }

    permission(key) {
        return true

        if (this.isCompanyOwner()) {
            return true
        }

        if (this.data) {
            if (Array.isArray(this.data.permissions)) {
                return this.data.permissions.includes(key)
            }
        }

        return false
    }

    hasAny(key) {
        let keys = key.split(',')

        for (let i = 0; i < keys.length; i++) {
            let key = keys[i].trim()

            if (this.permission(key)) {
                return true
            }
        }

        return false
    }

    hasAll(key) {
        let keys = key.split(',')

        for (let i = 0; i < keys.length; i++) {
            let key = keys[i].trim()

            if (this.permission(key) === false) {
                return false
            }
        }

        return true
    }

    isCompanyOwner() {
        if (this.data) {
            return this.data.is_company_owner
        }

        return false
    }

    isAdmin() {
        if (this.data) {
            return Boolean(this.data.is_admin)
        }
        return false
    }

    isSuperAdmin() {
        if (this.data) {
            return Boolean(this.data.is_admin && this.data.superadmin)
        }
        return false
    }


    isEmailVerified() {
        if (this.data) {
            return Boolean(this.data.is_email_verified)
        }
        return false
    }

    mustVerifyEmail() {
        if (this.data) {
            return Boolean(this.data.must_verify_email)
        }
        return false
    }

    getSetting(key) {
        if (Array.isArray(this.data.settings)) {
            let setting = this.data.settings.find(s => s.key === key)

            if (setting) {
                return setting.value
            }
        }

        return undefined
    }

    /** 
     * @returns bool
     */
    bankHelpInfoSeen() {
        if (Array.isArray(this.data.settings)) {
            let setting = this.data.settings.find(s => s.key === 'bank_help_info_seen')

            if (setting) {

                return setting.data
            }
        }

        return false
    }

    /**
     * @returns Company
     */
    getCompany() {
        if (this.company instanceof Company) {
            return this.company
        }

        if (this.data && this.data.company) {
            this.company = new Company(this.data.company)
        }

        if (!this.company) {
            window.location.reload()

            return {}
        }

        return this.company
    }

    getCompanyAddress() {
        return this.data.company_address
    }

    getStore() {
        return this.data.store
    }

    getStores() {
        return this.data.stores
    }

    getCompanies() {
        if (this.companies.length) {
            return this.companies
        }

        let data = []

        if (this.data && this.data.companies && Array.isArray(this.data.companies)) {

            this.data.companies.map(company => {
                data.push(
                    new Company(company)
                )
            })
        }

        this.companies = data

        return this.companies
    }

    addCompany(company) {
        this.companies.push(new Company(company))
    }

    //не се ползва
    removeCompany(companyId) {
        this.companies = this.companies.filter(c => c !== companyId)
    }

    getCompanyById(id) {
        let companies = this.getCompanies()

        let company = companies.find(c => Number(c.id) === Number(id))

        return company
    }

    getStoreId() {
        return this.data?.store_id
    }

    getStore() {
        return this.data?.store
    }

    getCashdesks() {
        return this.data?.store?.cashdesks || []
    }
}