import { useEffect, useState } from "react"
import { useHistory } from "react-router"
import moment from "moment"

//providers
import { useAuthDataContext } from "providers/Auth"

//misc
import DatePicker from 'components/misc/DatePicker'
import RedditTextField from 'components/misc/RedditTextField'
import Autocomplete from 'components/misc/Dropdown'

//helpers
import { useQuery } from 'helpers/Url'

//images
import clearFilterIcon from 'assets/img/documents/icons/clear-filter.png'
import Api from "helpers/Api"
import RedditSelect from "components/misc/RedditSelect"
import { MenuItem } from "@material-ui/core"

function Filter(props) {
    const history = useHistory()
    const query = useQuery()

    const auth = useAuthDataContext()

    const [types, setTypes] = useState([]);

    useEffect(() => {
        Api.get('store/subscriptions/types')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setTypes(res.data);
                }
            });
    }, [])

    const handleDateChange = (date, name) => {
        props.handleSearch(name, date)
    }

    const handleSearch = e => {
        const name = e.target.name
        const value = e.target.value

        props.handleSearch(name, value)
    }

    const handleChangeClient = data => {
        props.handleSearch({
            client_id: data?.id || '',
        })
    }

    const handleClearFilter = () => {
        history.push(history.location.pathname)
    }

    const areAppliedFilters = () => {
        if (props.filter.search || props.filter.client_id || props.filter.type_id) return true
        else return false
    }

    return (
        <div className="row filter">
            <div
                className="col"
                style={{
                    maxWidth: '300px'
                }}
            >
                <RedditTextField
                    size="small"
                    variant="filled"
                    margin="dense"
                    fullWidth
                    label="Търсене..."
                    name="search"
                    value={props.filter.search || ''}
                    onChange={handleSearch}
                />
            </div>

            <div
                className="col"
            >
                <Autocomplete
                    margin="none"
                    size="small"
                    variant="filled"
                    inputPlaceholder="Клиент"
                    url="store/autocomplete/clients"
                    inputIdName="client_id"
                    inputValue={''}
                    inputIdValue={props.filter.client_id || ''}
                    onChange={handleChangeClient}
                    onInputChange={handleChangeClient}
                    renderText={data => data.info?.name}
                />
            </div>

            <div className="col">
                <div style={{ width: '100px' }}>
                    <RedditSelect
                        size="small"
                        variant="filled"
                        margin="dense"
                        label="Тип"
                        name="type_id"
                        value={props.filter.type_id || ''}
                        onChange={handleSearch}

                    >
                        {types.map(type =>
                            <MenuItem value={type.id}>
                                {type.name} ({type.period_text})
                            </MenuItem>
                        )}
                    </RedditSelect>
                </div>
            </div>

            <div className="col">
                <div style={{ width: '100px' }}>
                    <RedditSelect
                        size="small"
                        variant="filled"
                        margin="dense"
                        label="Статус"
                        name="status"
                        value={props.filter.status || ''}
                        onChange={handleSearch}

                    >
                        <MenuItem value="">
                            Всички
                        </MenuItem>
                        <MenuItem value="active">
                            Активни
                        </MenuItem>
                        <MenuItem value="expired">
                            Изтекли
                        </MenuItem>
                    </RedditSelect>
                </div>
            </div>

            <div
                className={`col link ${areAppliedFilters() ? '' : 'disabled'}`}
                onClick={handleClearFilter}
                style={{
                    justifyContent: 'center',
                    width: 'fit-content',
                    flex: 'initial'
                }}
            >
                <img src={clearFilterIcon} />
            </div>
        </div>
    )
}

export default Filter