import { Card } from 'react-bootstrap';
import PaymentRequests from './partials/PaymentRequests';
import Payments from './partials/Payments';
import ClientPayments from './partials/ClientPayments';

function Index(props) {

    return (
        <>
            <Card className="mb-3">
                <Card.Header>
                    Плащания по клиентската заявка
                </Card.Header>
                <Card.Body>
                    <Payments
                        id={props.id}
                        showPayment={props.showPayment}
                    />
                </Card.Body>
            </Card>

            <Card className="mb-3">
                <Card.Header>
                    Заявки за плащане
                </Card.Header>
                <Card.Body>
                    <PaymentRequests
                        id={props.id}
                        showPaymentRequest={props.showPaymentRequestInfo}
                    />
                </Card.Body>
            </Card>

            <Card className="">
                <Card.Header>
                    Движения по клиентски портфейл
                </Card.Header>
                <Card.Body>
                    <ClientPayments
                        id={props.id}
                    />
                </Card.Body>
            </Card>
        </>
    )
}

export default Index;