//helpers
import Api from "helpers/Api"

//libraries
import { useQueryClient } from "react-query"

export default function useRemoveFromCalendar() {
    const queryClient = useQueryClient()

    function removeFromCalendar(id, successCb, errorCb) {
        Api.post(`fake-crm-calendar/delete-task`, {
            id
        })
            .then(() => {
                queryClient.invalidateQueries("fake-crm-calendar")
            })
    }

    return {
        removeFromCalendar
    }
}