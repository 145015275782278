import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'

//helpers
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'
import Api from 'helpers/Api'

//MUI components
import { Button } from '@material-ui/core'

//images
import closeIcon from 'assets/img/modals/icons/close.png'

//mics
import RedditTextField from 'components/misc/RedditTextField'

//misc
import SaveButton from 'components/misc/Button'

//constants
import { PAYMENT_EXTRA_DATA } from 'constants/PaymentMethod'

//providers
import { useAppContext } from 'providers/App'
import { useAuthDataContext } from 'providers/Auth'

//modals
import Question from 'components/modals/Question'
import Error from 'components/modals/Error'

const initialState = {
    overlay: false,
    modal: false,
    options: {},
    onSuccess: null,
    onError: null,
    editable: false,
    editMode: false,
    id: null
}

let timeout
function Epay(props, ref) {
    const {
        message,
        editBank,
        appendBank,
        unappendBank,
        defaultBankId,
        bankAccountsCount
    } = props
    const auth = useAuthDataContext()
    const app = useAppContext()

    const [state, setState] = useNestedState(initialState)

    const [validations, setValidations] = useValidation()

    const deleteModalRef = useRef(null)
    const errorModalRef = useRef(null)

    useImperativeHandle(ref, () => ({
        show: data => {
            show(data)
        },
        hide: () => {
            hide()
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        },
        onError: fn => {
            setState(prev => ({
                ...prev,
                onError: fn
            }))
        }
    }))

    useEffect(() => {
        setState(prev => ({
            ...prev,
            data: auth.getUser().getCompany().getSettings()
        }))
    }, [auth])

    const show = (data = {}) => {
        setState(prev => ({
            ...prev,
            overlay: true,
            ...data,
            editMode: data.id
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }))
        }, 50)
    }

    const hide = () => {
        setState(prev => ({
            ...prev,
            modal: false,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...initialState,
                overlay: false,
                modal: false,
                editable: false,
                data: null,
            }))
        }, 150)

        setValidations(null)
    }

    /**
     * 
     * @param {object} e референция към елемента 
     */
    const handleHide = e => {
        e.preventDefault()

        hide()
    }

    /**
     * 
     * @param {object} e референция към формата 
     */
    const handleSave = e => {
        e.preventDefault()

        setLoading(true)
        setValidations(null)

        const url = state.id ? 'banks/edit' : 'banks/add'
        const data = new FormData(e.target)
        if (state.id) data.append('id', state.id)

        Api.post(url, data)
            .then(res => {
                if (state?.id) editBank(res.data)
                else appendBank(res.data)

                hide()
                if (typeof state.onSuccess === 'function') state.onSuccess(res.data)
            })
            .catch(error => {
                const _err = error.response

                if (_err && _err.status && _err.status === 422) setValidations(_err.data.errors)
                else {
                    if (typeof state.onError === 'function') state.onError(error)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    /**
     * 
     * @param {boolean} loading дали да се визуализира зареждане 
     */
    const setLoading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    /**
     * 
     * @param {object} e референция към елемента 
     */
    const handleInputChange = e => {
        let { name, value } = e.target
        setEditable(true)

        if (name === 'iban') {
            value = value.replace(/\s/g, '').toUpperCase()
        }

        setState(name, value, '')

        clearTimeout(timeout)
        timeout = setTimeout(() => {
            if (name === 'iban') getBankByIban(value)
        }, 500)
    }

    const getBankByIban = iban => {
        if (iban.length >= 7) {
            Api.post('banks/guess', {
                iban
            })
                .then(res => {
                    const { name, bic } = res.data

                    if (name && bic) {
                        setState(prev => ({
                            ...prev,
                            name,
                            bic
                        }))
                    }
                })
        }
    }

    /**
     * 
     * @param {boolean} editable дали да се позволи запзаване 
     */
    const setEditable = editable => {
        setState(prev => ({
            ...prev,
            editable
        }))
    }

    const handleDelete = e => {
        e.preventDefault()

        if (bankAccountsCount > 1) {
            if (Number(state.editMode) === Number(defaultBankId)) {
                errorModalRef.current.open()

                return
            }
        }

        const modal = deleteModalRef.current
        modal.open()
        modal.onSuccess(() => {
            handleDestroy(state.editMode)
        })
    }

    const handleDestroy = id => {
        Api.post('banks/delete', {
            id
        })
        hide()
        unappendBank(id)
        app.handleSuccess('Банковата сметка беше изтрита успешно!')
    }

    return (<div key={state.id} className={`${state.overlay ? 'visible' : ''} overlay`}>
        <Question
            ref={deleteModalRef}
            agreeBtnClass="remove"
            mainMessage="Сигурни ли сте, че желаете да изтриете тази банкова сметка?"
        />
        <Error
            ref={errorModalRef}
            mainMessage="Банката по подразбиране не може да бъде изтрита!"
            secondaryMessage="За да изтриете тази банкова сметка, първо трябва да смените банката по подразбиране или тя да е единствената сметка в профила Ви."
        />
        <form className={`${state.modal ? 'show' : ''} popup-primary small`} noValidate onSubmit={handleSave} id="bankModal">
            <input hidden name="company_id" value={props.companyId} />
            <h2 className="head">
                <img src={PAYMENT_EXTRA_DATA['bank'].icon} className="logo" />
                Настройки за Банков превод
                <img className="close-icon" alt="close" src={closeIcon} onClick={handleHide} />
            </h2>

            <div className="body">
                <div className="row">
                    <div className="col">
                        <RedditTextField
                            size="small"
                            variant="filled"
                            margin="dense"
                            fullWidth
                            label="IBAN"
                            name="iban"
                            value={state?.iban || ''}
                            onChange={handleInputChange}
                            error={Boolean(validations && validations.iban)}
                            helperText={validations && validations.iban && (validations.iban[0] || validations.iban)}
                            required
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <RedditTextField
                            size="small"
                            variant="filled"
                            margin="dense"
                            fullWidth
                            label="Банка"
                            name="name"
                            value={state?.name || ''}
                            onChange={handleInputChange}
                            error={Boolean(validations && validations.name)}
                            helperText={validations && validations.name && (validations.name[0] || validations.name)}
                            required
                        />
                    </div>
                    <div className="col small">
                        <RedditTextField
                            size="small"
                            variant="filled"
                            margin="dense"
                            fullWidth
                            label="BIC"
                            name="bic"
                            value={state?.bic || ''}
                            onChange={handleInputChange}
                            error={Boolean(validations && validations.bic)}
                            helperText={validations && validations.bic && (validations.bic[0] || validations.bic)}
                            required
                        />
                    </div>
                </div>
                {state.editMode ?
                    <div
                        className="link"
                        style={{
                            color: '#ff426e',
                            width: 'fit-content'
                        }}
                        onClick={handleDelete}
                    >
                        Изтриване на сметката
                    </div>
                    :
                    <>
                    </>
                }
            </div>

            <div className="footer">
                <Button
                    className="cancel"
                    onClick={handleHide}

                >
                    Отказ
                </Button>
                <SaveButton
                    loading={state.loading}
                    className={`save ${!state.editable ? 'disabled' : ''}`}
                    form="bankModal"
                />
            </div>
        </form>
    </div>
    )
}

export default forwardRef(Epay)