import moment from "moment"
import { createContext, useContext, useState } from "react"

export const DashboardDataContext = createContext({})

const DashboardDataProvider = props => {
    const [refresh, setRefresh] = useState({})
    const [transactionsCount, setTransactionsCount] = useState(0)
    const [documents, setDocuments] = useState([])
    const [loadingDocuments, setLoadingDocuments] = useState(true)

    function refreshData(key) {
        setRefresh(prev => ({
            ...prev,
            [key]: moment().unix()
        }))
    }

    function getRefreshed(key) {
        return refresh[key]
    }

    function getTransactionsCount() {
        return transactionsCount
    }

    function getDocuments() {
        return documents
    }

    function getDocumentsCount() {
        return getDocuments().length
    }

    function getLoadingDocuments() {
        return loadingDocuments
    }

    const exportedData = {
        refreshData,
        getRefreshed,
        setTransactionsCount,
        getTransactionsCount,
        getDocumentsCount,
        getDocuments,
        setDocuments,
        setLoadingDocuments,
        getLoadingDocuments
    }

    return <DashboardDataContext.Provider value={exportedData} {...props} />
}

export const useDashboardDataContext = () => useContext(DashboardDataContext)

export default DashboardDataProvider