import axios from "axios";
import Api from "helpers/Api";
import { useAppContext } from "providers/App";
import { useRef, useState } from "react";

function useInvoiceFromAccount() {

    const app = useAppContext();

    const [isLoading, setIsLoading] = useState(false);

    const cancelToken = useRef();

    const createInvoiceFromAccount = async (accountId, typeId = null) => {
        if (cancelToken.current) {
            cancelToken.current.cancel();
        }

        cancelToken.current = axios.CancelToken.source();

        setIsLoading(true);

        return await Api.get('documents/from-account', {
            params: {
                account_id: accountId,
                type_id: typeId,
            },
            cancelToken: cancelToken.current.token,
        }).then(res => {

            return res.data;
        }).catch(err => {
            if (axios.isCancel(err)) {
                // 
            } else {
                app.showError();
            }
        }).finally(() => {
            setIsLoading(false);
        });
    }

    return {
        isLoading,
        createInvoiceFromAccount,
    };
}


export default useInvoiceFromAccount;