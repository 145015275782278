import { useState, useImperativeHandle, forwardRef, useEffect, useRef } from 'react'
import { Modal, Form, Row, Col } from 'react-bootstrap'
import SaveButton from 'components/misc/Button'
import Loader from 'components/misc/Loader'
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import Error from 'components/modals/Error'
import { useAuthDataContext } from 'providers/Auth'
import { Button, Checkbox, FormControlLabel, TextField, Radio } from '@material-ui/core'

function SetEmpty(props, ref) {

    const auth = useAuthDataContext()

    const defaultData = {
        revision: null,
        rows: [],
        quantity: 0,
        columns: ['plus'],
        type: 'all',
    }

    const [state, setState] = useState({
        show: false,
        params: null,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onClose: null,
    })

    const [validations, setValidations] = useValidation()

    const errorModalRef = useRef(null)
    const formRef = useRef(null)

    useImperativeHandle(ref, () => ({
        open: (revision, rows) => {
            handleShow(revision, rows)
        },
        close: () => {
            handleClose()
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }))
        }
    }))

    const handleShow = (revision, rows) => {
        const data = defaultData

        data.revision = revision
        data.rows = rows

        setState(prev => ({
            ...prev,
            show: true,
            data: data
        }))
    }

    const handleClose = () => {
        setState(prev => ({
            ...prev,
            show: false,
            data: defaultData
        }))

        setValidations(null)

        if (typeof state.onClose === 'function') {
            state.onClose()
        }
    }

    const hide = () => {
        setState(prev => ({
            ...prev,
            show: false,
        }))
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: value
            }
        }))
    }

    const handleCheckboxChange = name => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                columns: isChecked(name)
                    ? prev.data.columns.filter(c => c !== name)
                    : prev.data.columns.concat(name)
            }
        }))
    }

    const handleSave = e => {
        setValidations(null)

        setState(prev => ({
            ...prev,
            loading: true,
        }))

        let url = 'store/revisions/set-empty'

        let data = new FormData(formRef.current)

        data.append('id', state.data.revision?.id)

        if (state.data.type === 'page') {
            state.data.rows.map(row => {
                data.append('ids[]', row.id)
            })
        }

        Api.post(url, data)
            .then(res => {
                if (typeof state.onSuccess === 'function') {
                    state.onSuccess(res.data)
                }

                hide()
            })
            .catch(error => {
                const _err = error.response

                if (_err && _err.status) {
                    if (_err.status === 422) {
                        setValidations(_err.data.errors)
                    } else {
                        let modal = errorModalRef.current

                        if (modal) {
                            modal.open(_err.data.error || _err.data.message)
                        }
                    }
                }

            }).finally(() => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                }))
            })
    }

    const isChecked = column => {
        return state.data.columns.indexOf(column) > -1
    }

    const isRadioChecked = column => {
        return state.data.type === column
    }

    return (
        <>
            <div className={`${state.show ? "visible" : ""} overlay`}>
                <div
                    className={`popup-primary medium ${state.show ? "show" : ""}`}
                >
                    <form className="body" ref={formRef}>
                        <h3>
                            Зануляване на пропуснатите
                        </h3>

                        <p style={{
                        }}>
                            Системата автоматично ще зададе
                            {/* <Form.Control
                                size="sm"
                                className="price-input"
                                type="number"
                                name="quantity"
                                value={state.data.quantity || 0}
                                onChange={handleInputChange}
                                style={{
                                    display: 'inline-block',
                                    width: '40px'
                                }}
                            /> */}
                            <span
                                style={{
                                    padding: '0 5px 0 5px',
                                }}
                            >
                                <TextField
                                    // size="small"
                                    type="number"
                                    name="quantity"
                                    value={state.data.quantity || 0}
                                    onChange={handleInputChange}
                                    style={{
                                        width: '40px',
                                        height: '20px',
                                        position: 'relative',
                                        bottom: '-1px'
                                    }}
                                    variant="outlined"
                                    margin="dense"
                                    InputProps={{
                                        style: {
                                            padding: '0',
                                            fontSize: '12px',
                                            height: '20px',
                                        }
                                    }}
                                />
                            </span>
                            на всички артикули, които имат празна текуща стойност за отчетено количество
                            и наличност в системата:
                        </p>
                        <div
                            className="row"
                            style={{
                                marginLeft: '-9px'
                            }}
                        >

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        style={{
                                            marginRight: "-7px",
                                        }}
                                        color="primary"
                                        id="c-plus"
                                        name="columns[]"
                                        value="plus"
                                        checked={isChecked('plus')}
                                        onChange={e => handleCheckboxChange('plus')}
                                    />
                                }
                                style={{
                                    marginLeft: 0
                                }}
                                label="по-голяма от нула"
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        style={{
                                            marginRight: "-7px",
                                        }}
                                        color="primary"
                                        id="c-zero"
                                        name="columns[]"
                                        value="zero"
                                        checked={isChecked('zero')}
                                        onChange={e => handleCheckboxChange('zero')}
                                    />
                                }
                                style={{
                                    marginLeft: 0
                                }}
                                label="нула"
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        style={{
                                            marginRight: "-7px",
                                        }}
                                        color="primary"
                                        id="c-minus"
                                        name="columns[]"
                                        value="minus"
                                        checked={isChecked('minus')}
                                        onChange={e => handleCheckboxChange('minus')}
                                    />
                                }
                                style={{
                                    marginLeft: 0
                                }}
                                label="по-малка от нула"
                            />

                        </div>
                        <h3 style={{
                            display: 'block',
                            marginBottom: '10px',
                            fontSize: '15px'
                        }}>
                            Горепосочените условия ще бъдат приложени върху:
                        </h3>
                        <div
                            className="row"
                            style={{
                                marginBottom: '0',
                                marginLeft: '-9px'
                            }}
                        >
                            <div className="col">
                                <FormControlLabel
                                    control={
                                        <Radio
                                            style={{
                                                marginRight: "-7px",
                                            }}
                                            color="primary"
                                            id="r-all"
                                            name="type"
                                            value="all"
                                            checked={isRadioChecked('all')}
                                            onChange={handleInputChange}
                                        />
                                    }
                                    style={{
                                        marginLeft: 0
                                    }}
                                    label={`Всички артикули от каталога за ревизията (${state.data.revision?.articles_count} бр)`}
                                />
                            </div>
                        </div>
                        <div
                            className="row"
                            style={{
                                marginLeft: '-9px'
                            }}
                        >
                            <div className="col">
                                <FormControlLabel
                                    control={
                                        <Radio
                                            style={{
                                                marginRight: "-7px",
                                            }}
                                            color="primary"
                                            id="r-page"
                                            name="type"
                                            value="page"
                                            checked={isRadioChecked('page')}
                                            onChange={handleInputChange}
                                        />
                                    }
                                    style={{
                                        marginLeft: 0,
                                    }}
                                    label={`Артикулите, които се виждат на екрана (${state.data.rows.length} бр)`}
                                />
                            </div>
                        </div>
                    </form>
                    <div className="footer">
                        <Button
                            className="cancel"
                            onClick={handleClose}
                        >
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            onClick={handleSave}
                        />
                    </div>
                </div>
            </div>
            <Error
                ref={errorModalRef}
            />
        </>
    )
}

export default forwardRef(SetEmpty)