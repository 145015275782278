//libraries
import { Button, Tooltip } from "@material-ui/core"

export default function BillOperator() {
  return (
    <ul className="right">
      <li>
        <span>Обсулужва:</span>
        <Tooltip title="Промяна на служителя">
          <Button>Константин К.</Button>
        </Tooltip>
      </li>
      <li>
        <span>Каса:</span>
        <Tooltip title="Промяна на касата">
          <Button>Основна каса 1</Button>
        </Tooltip>
      </li>
    </ul>
  )
}
