import CommunicationList from "../partials/CommunicationList"

export default function Communication() {
  return (
    <div className="row">
      <div className="col">
        <h6>Комуникация с клиента</h6>
        <div
          className="field"
          style={{
            borderTopLeftRadius: 0,
          }}
        >
          <CommunicationList />
        </div>
      </div>
    </div>
  )
}
