import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import { Form, } from 'react-bootstrap'
import { Button, MenuItem } from '@material-ui/core'

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useLanguageContext } from 'providers/Language'

//misc
import SaveButton from 'components/misc/Button'
import RedditTextField from 'components/misc/RedditTextField'
import RedditSelect from 'components/misc/RedditSelect'
import moment from 'moment'
import Autocomplete from 'components/misc/Autocomplete'
import DatePicker from 'components/misc/DatePicker'

function AddOrEdit(props, ref) {

    const defaultData = {
        valid_to_type: 1,
        reusable: 0,
        groups: [],
        categories: []
    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        edit: false,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
    })

    const [cardsTypes, setCardsTypes] = useState([]);

    const [validations, setValidations] = useValidation()

    const formRef = useRef(null)

    useImperativeHandle(ref, () => ({
        add: () => {
            add();
        },
        edit: (id) => {
            edit(id)
        },
        hide: () => {
            hide()
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        }
    }))

    useEffect(() => {
        loadData()
    }, [state.edit])

    useEffect(() => {
        if (!state.show) {
            return;
        }

        Api.get('store/cards/cards-types')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setCardsTypes(res.data);
                }
            });
    }, [state.show])

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }))

        onEntering?.()
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }))

        onExiting?.()
    }

    const add = () => {
        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }));

        show();
    }

    const edit = id => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: moment().unix()
        }))

        show()
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault()
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }))

        setValidations(null)

        hide()
    }

    const loadData = () => {
        if (!state.id) {
            return
        }

        Api.get('store/cards/show?id=' + state.id)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data,
                }))
            });
    }


    const handleSave = () => {
        setState(prev => ({
            ...prev,
            loading: true,
        }))

        setValidations(null)

        let url = state.id ? 'store/cards/update' : 'store/cards/add'

        let data = new FormData(formRef.current)

        if (state.id) {
            data.append('id', state.id)
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
            }

            hide()
        }).catch(error => {
            const _err = error.response
            if (_err && _err.status && _err.status === 422) {
                setValidations(_err.data.errors)
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }))
        })
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleDateChange = e => {
        const name = e.target.name + '_ymd'
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleClientChange = (data) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                client: data,
                client_id: data.id,
            }
        }));
    }

    const handleCardTypeChange = e => {
        const name = e.target.name
        const value = e.target.value
        let cardType = cardsTypes.filter(t => Number(t.id) === Number(value));

        if (!cardType.length) {
            return;
        }

        cardType = cardType[0];

        let data = {}
        if (!state.edit) {
            let valid_from = getFromDate(cardType.valid_from_type);
            let valid_to = getToDate(valid_from, cardType.valid_to_type, cardType.valid_to_num);

            data = {
                credit_left: cardType.credit,
                discount: cardType.discount,
                valid_from,
                valid_to
            }
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: value,
                ...data
            }
        }))
    }

    const getFromDate = (type) => {
        switch (type) {
            case 3:
                return getStartOfWeek();
                break;
            case 4:
                return getStartOfMonth();
                break;

            case 5:
                return getStartOfYear();
                break;
            default:
                return getCurrentDay();
                break;
        }
    }

    const getToDate = (date, type, datesToAdd) => {
        switch (type) {
            case 2:
                // add days
                return moment(date, 'YYYY-MM-DD').add(datesToAdd, 'years');
                break;
            case 3:
                //add weeks
                return moment(date, 'YYYY-MM-DD').add(datesToAdd, 'weeks');
                break;
            case 4:
                //add months
                return moment(date, 'YYYY-MM-DD').add(datesToAdd, 'months');
                break;
            case 5:
                //add years
                return moment(date, 'YYYY-MM-DD').add(datesToAdd, 'years');
                break;

        }
    }

    const getStartOfWeek = () => {
        const date = moment().startOf('isoweek').format('YYYY-MM-DD');
        return date;
    }

    const getStartOfMonth = () => {
        const date = moment().startOf('month').format('YYYY-MM-DD');
        return date;
    }

    const getStartOfYear = () => {
        const date = moment().startOf('month').format('YYYY-MM-DD');
        return date;
    }

    const getCurrentDay = () => {
        const date = moment().format('YYYY-MM-DD');
        return date;
    }

    const handleCheckboxChange = e => {
        const name = e.target.name;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }));
    }

    const handleDiscountChange = e => {
        const name = e.target.name
        let value = e.target.value

        if (!value.includes('+') && !value.includes('-') && value.length > 0) {
            value = '-' + value;
        }

        if (value.includes('+')) {
            value = value.replace('-', '')
        }

        setState(name, value, 'data')
    }

    return (
        <>
            <div className={`${state.show ? "visible" : ""} overlay`}>
                <div
                    className={`popup-primary medium ${state.show ? "show" : ""}`}
                >
                    <div className="body">
                        <h3>
                            {`${state.id ? 'Редакция' : 'Добавяне'} на пакетна карта`}
                        </h3>
                        <br />
                        <form onSubmit={handleSave} ref={formRef}>
                            <div className="row">
                                <div className="col">
                                    <RedditSelect
                                        name="card_id"
                                        value={state.data.card_id || ''}
                                        onChange={handleCardTypeChange}
                                        label="Тип карта"
                                        error={Boolean(validations?.card_id)}
                                        helperText={validations?.card_id && (validations.card_id[0] || validations.card_id)}
                                    >

                                        {cardsTypes.map(c =>
                                            <MenuItem key={c.id} value={c.id}>
                                                {c.name}
                                            </MenuItem>
                                        )}
                                    </RedditSelect>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <RedditTextField
                                        size="small"
                                        variant="filled"
                                        margin="dense"
                                        label="Номер"
                                        name="num"
                                        value={state.data.num || ''}
                                        onChange={(e) => handleInputChange(e)}
                                        error={Boolean(validations?.num)}
                                        helperText={validations?.num && (validations.num[0] || validations.num)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Autocomplete
                                        size="small"
                                        variant="filled"
                                        margin="dense"
                                        url="store/autocomplete/clients"
                                        inputPlaceholder="Клиент"
                                        inputIdName={`client_id`}
                                        renderText={data => data?.info?.name}
                                        inputValue={state.data.client?.info?.name || ''}
                                        inputIdValue={state.data.client_id || ''}
                                        error={Boolean(validations?.client_id)}
                                        helperText={validations?.client_id && (validations?.client_id[0] || validations?.client_id)}
                                        onChange={data => handleClientChange(data)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <DatePicker
                                        size="small"
                                        variant="filled"
                                        margin="dense"
                                        type="datetime-local"
                                        placeholder="Важи от*"
                                        name="valid_from"
                                        value={state.data?.valid_from ? moment(state.data.valid_from).format('YYYY-MM-DD HH:mm:ss') : ''}
                                        onChange={handleInputChange}
                                        isInvalid={Boolean(validations?.valid_from)}
                                        error={validations?.valid_from && (validations.valid_from[0] || validations.valid_from)}
                                    />
                                </div>
                                <div className="col">
                                    <DatePicker
                                        size="small"
                                        variant="filled"
                                        margin="dense"
                                        type="datetime-local"
                                        placeholder="Важи до"
                                        name="valid_to"
                                        value={state.data?.valid_to ? moment(state.data.valid_to).format('YYYY-MM-DD HH:mm:ss') : ''}
                                        onChange={handleInputChange}
                                        isInvalid={Boolean(validations?.valid_to)}
                                        error={validations?.valid_to && (validations.valid_to[0] || validations.valid_to)}
                                        info="Празна дата означава без ограничение"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <RedditTextField
                                        size="small"
                                        variant="filled"
                                        margin="dense"
                                        label="Оставащи използвания"
                                        name="uses_left"
                                        value={state.data.uses_left || ''}
                                        onChange={(e) => handleInputChange(e)}
                                        error={Boolean(validations?.uses_left)}
                                        helperText={validations?.uses_left && (validations.uses_left[0] || validations.uses_left)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <RedditTextField
                                        size="small"
                                        variant="filled"
                                        margin="dense"
                                        label="Отстъпка %"
                                        name="discount"
                                        value={state.data.discount || ''}
                                        onChange={(e) => handleDiscountChange(e)}
                                        error={Boolean(validations?.discount)}
                                        helperText={validations?.discount && (validations.discount[0] || validations.discount)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <RedditTextField
                                        size="small"
                                        variant="filled"
                                        margin="dense"
                                        label="Оставащ кредит"
                                        name="credit_left"
                                        value={!isNaN(state.data.credit_left) ? state.data.credit_left : ''}
                                        onChange={(e) => handleInputChange(e)}
                                        error={Boolean(validations?.credit_left)}
                                        helperText={validations?.credit_left && (validations.credit_left[0] || validations.credit_left)}
                                    />
                                </div>

                            </div>
                            <div className="row">

                            </div>
                        </form>
                    </div>

                    <div className="footer">
                        <Button
                            variant="contained"
                            className="cancel"
                            onClick={handleClose}
                        >
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                            onClick={handleSave}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default forwardRef(AddOrEdit)
