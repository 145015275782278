import { useHistory } from "react-router"

//components
import { Button } from "@material-ui/core"
import Layout from "components/Layout"

//images
import ecoIcon from "assets/img/dashboard/icons/eco.png"

export default function AllSales() {
  const history = useHistory()

  function handleNavigateToHome() {
    history.push("/dashboard")
  }

  return (
    <Layout>
      <section id="no-documents">
        <div className="welcome">
          <h1>Страницата е достъпна за модул Склад!</h1>
          <p>
            Модул Склад е предназначен за всички, които искат лесно и бързо да
            администрират складови наличности, изписват материали и искат да
            имат точна отчетност на потреблението.
          </p>
          <Button
            className="create-invoice new-btn-primary"
            onClick={handleNavigateToHome}
          >
            Към начало
          </Button>
        </div>
        <div className="stamp">
          <img src={ecoIcon} alt="" />
          Опази природата.
          <br />
          Създай електронна фактура
        </div>
      </section>
    </Layout>
  )
}
