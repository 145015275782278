import { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react'
import Api from 'helpers/Api'
import Loader from 'components/misc/Loader'
import moment from 'moment'
import NoDataFound from 'components/misc/NoDataFound'
import Pagination from 'components/misc/Pagination'
import TableHeader from 'components/misc/TableHeader'
import TableCol from 'components/misc/TableCol'
import PriceValue from 'components/partials/PriceValue'
import MenuOptions from 'components/misc/MenuOptions'
import { MenuItem } from '@material-ui/core'
import editIcon from 'assets/img/app/icons/edit.png'
import { useAppContext } from 'providers/App'
import { useValidation } from 'helpers/Validation'

function Index(props, ref) {

    const app = useAppContext()

    const defaultData = []

    const tableRef = useRef(null)

    const [state, setState] = useState({
        data: defaultData,
        pages: 0,
        total: 0,
        loading: false,
        filter: {
            page: 1,
        },
        refresh: false,
    })

    const [attrs, setAttrs] = useState({});

    const [validations, setValidations] = useValidation()

    useImperativeHandle(ref, () => ({
        save: () => {
            handleSave();
        },
    }))

    useEffect(() => {

        loading(true)

        Api.get('store/subscriptions/payments', {
            params: {
                id: props.id,
                ...state.filter,
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data.items,
                currency: res.data.currency,
                total: res.data.total,
                pages: res.data.pages,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                },
            }))

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 })
            }

            setAttrs({});

            loading(false)
            sorting(false)
        })
    }, [props.id, state.refresh])

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }))
    }

    const sorting = (sorting) => {
        setState(prev => ({
            ...prev,
            sorting: Boolean(sorting)
        }))
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            refresh: moment().unix()
        }))
    }

    const handleSort = (sort, order) => {
        setState(prev => ({
            ...prev,
            sorting: true,
            filter: {
                ...prev.filter,
                sort: sort,
                order: order,
                page: 1,
            },
            refresh: moment().unix()
        }))
    }

    const handlePaymentPaidChange = (id, value) => {
        setAttrs(prev => ({
            ...prev,
            [id]: prev[id] ? {
                ...prev[id],
                paid: value
            } : {
                paid: value
            }
        }))
    }

    const handleSave = e => {
        if (e) e.preventDefault()

        setState(prev => ({
            ...prev,
            loading: true,
        }))

        setValidations(null)

        let url = 'store/subscriptions/payments'

        const data = {
            items: attrs
        };

        data.id = props.id;

        Api.post(url, data, {
            headers: {
                'content-type': 'application/json'
            }
        }).then(res => {
            if (typeof props.onSuccess === 'function') {
                props.onSuccess(res.data)
            }

            app.showSuccess()
        }).catch(error => {
            const _err = error.response

            if (_err && _err.status && _err.status === 422) {
                setValidations(_err.data.errors)
            } else {
                app.showError()
            }
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }))
        })
    }

    const getValue = (item, key) => {
        return attrs[item.id]?.[key] || item[key]
    }

    return (
        <div className="preview-bill-payments">
            {state.loading && !state.sorting
                ?
                <Loader />
                :
                <div className="row">
                    <div className="col">
                        <h6>
                            Списък с плащания
                        </h6>
                        <div className="field">
                            {state.data.length === 0 ?
                                <NoDataFound />
                                :
                                <>

                                    <table className="type-doc" ref={tableRef}>
                                        <TableHeader
                                            tableRef={tableRef}
                                            activeSortKey={state.filter.sort}
                                            activeSortDir={state.filter.order}
                                            onSort={(col, dir) => handleSort(col, dir)}
                                        >
                                            <TableCol sortable sortKey="id" style={{ width: '5%' }}>ID</TableCol>
                                            <TableCol sortable sortKey="date">Дата</TableCol>
                                            <TableCol className="text-right" sortable sortKey="amount">Сума</TableCol>
                                            <TableCol className="text-center">Платено</TableCol>
                                            {/* <TableCol className="options">Опции</TableCol> */}
                                        </TableHeader>
                                        <tbody>
                                            {state.data.map((r, i) =>
                                                <tr key={i}>
                                                    <td>
                                                        {r.id}
                                                    </td>
                                                    <td>
                                                        {r.date ? moment(r.date).format('DD.MM.YYYY') : ''}
                                                    </td>

                                                    <td className={`text-right ${r.amount < 0 && 'negative-value'}`}>
                                                        <PriceValue prefix={state.currency?.prefix} sufix={state.currency?.sufix}>{r.price}</PriceValue>

                                                    </td>
                                                    <td className="text-center">
                                                        <select
                                                            value={getValue(r, 'paid')}
                                                            onChange={e => handlePaymentPaidChange(r.id, e.target.value)}
                                                        >
                                                            <option value={0}>НЕ</option>
                                                            <option value={1}>ДА</option>
                                                        </select>
                                                    </td>
                                                    {/* <td className="options">
                                        <MenuOptions>
                                            <MenuItem onClick={e => handleShow(r.id)}>
                                                <img src={editIcon} />
                                                Преглед
                                            </MenuItem>
                                        </MenuOptions>
                                    </td> */}
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>

                                    <Pagination
                                        className="mt-3"
                                        page={state.filter.page}
                                        pages={state.pages}
                                        total={state.total}
                                        handlePage={handlePage}
                                    />
                                </>
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default forwardRef(Index)