import { useState, useImperativeHandle, forwardRef, useEffect } from 'react'
import { Modal, Button, Row, Col, FloatingLabel, Form } from 'react-bootstrap';
import { createPortal } from 'react-dom';

import SaveButton from 'components/misc/Button'
import { useAppContext } from 'providers/App';
import Api from 'helpers/Api';
import { useHistory } from 'react-router-dom';
import { useValidation } from 'helpers/Validation';

function PrintOptions(props, ref) {

    const app = useAppContext();
    const navigate = useHistory();

    const defaultData = {

    }

    const [state, setState] = useState({
        show: false,
        params: null,
        data: defaultData,
        onSuccess: null,
        onClose: null,
        onEntering: null,
        onExiting: null,
    });

    const [validations, setValidations] = useValidation();

    const [labels, setLabels] = useState([]);

    useImperativeHandle(ref, () => ({
        open: () => {
            handleShow();
        },
        close: () => {
            handleClose();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        },
        onCancel: fn => {
            setState(prev => ({
                ...prev,
                onCancel: fn
            }));
        }
    }));

    useEffect(() => {
        if (!state.show) {
            return;
        }

        if (labels.length) {
            return;
        }

        Api.get('store/articles/print-labels')
            .then(res => {
                setLabels(res.data.labels);
            }).catch(() => {
                // app.showError();
            });

    }, [state.show]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = () => {
        show();
    }


    const handleClose = () => {
        hide();
    }

    const handleSave = e => {
        e.preventDefault();

        // setState(prev => ({
        //     ...prev,
        //     loading: true,
        // }));

        hide();

        if (typeof state.onSuccess === 'function') {
            state.onSuccess({
                label_id: state.data.label_id || '',
                skip: state.data.skip || 0
            });
        }
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: value
            }
        }))
    }


    return createPortal(
        <Modal size="sm" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
            <form onSubmit={handleSave}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Настройки за печата
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col className="mb-3">
                            <FloatingLabel label="Етикет">
                                <Form.Select
                                    name="label_id"
                                    value={state.data.label_id || ''}
                                    onChange={handleInputChange}
                                    isInvalid={Boolean(validations && validations.label_id)}
                                >
                                    <option value=""></option>
                                    {labels.map(c =>
                                        <option key={c.id} value={c.id}>{c.name}</option>
                                    )}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {validations && validations.label_id && (validations.label_id[0] || validations.label_id)}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FloatingLabel label="Колко позиции да бъдат пропуснати?">
                                <Form.Control
                                    placeholder="Колко позиции да бъдат пропуснати?"
                                    name="skip"
                                    value={state.data.skip || 0}
                                    onChange={handleInputChange}
                                    isInvalid={Boolean(validations && validations.skip)}
                                />

                                <Form.Control.Feedback type="invalid">
                                    {validations && validations.skip && (validations.skip[0] || validations.skip)}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Отказ
                    </Button>
                    <SaveButton
                        loading={state.loading}
                        className="save"
                    >
                        Преглед преди печат
                    </SaveButton>
                </Modal.Footer>
            </form>
        </Modal>,
        document.body
    )

}

export default forwardRef(PrintOptions);