import { useEffect, useRef, useState } from 'react'
import Api from 'helpers/Api'
import { buildSearchParams, buildUrl, useQuery } from 'helpers/Url'
import { useLocation, useHistory, Link } from 'react-router-dom'

import Options from './partials/Options'

import Question from 'components/modals/Question'
import Filter from './partials/Filter'
import Layout from 'components/Layout'
import View from './view/View'
import Close from './partials/Close'
import NewStorno from './partials/NewStorno'
import NraExport from './partials/NraExport'
import Skeleton from 'react-loading-skeleton'

//misc
import MenuOptions from 'components/misc/MenuOptions'
import Pagination from 'components/misc/Pagination'
import Loader from 'components/misc/Loader'
import NoDataFound from 'components/misc/NoDataFound'
import TableHeader from 'components/misc/TableHeader'
import TableCol from 'components/misc/TableCol'
import PriceValue from 'components/partials/PriceValue'
import TableOptions from 'components/misc/TableOptions'
import DynamicTableCol from 'components/partials/table/DynamicTableCol'
import DynamicTableBodyCol from 'components/partials/table/DynamicTableBodyCol'

import Refs from 'Refs'

import { useAuthDataContext } from 'providers/Auth'
import { useAppContext } from 'providers/App'
import useInvoiceFromAccount from 'hooks/useInvoiceFromAccount'
import axios from 'axios'
import { INVOICE } from 'constants/invoices'
import PaginationMini from 'components/misc/PaginationMini'
import { Checkbox, Button } from "@material-ui/core"

import invoiceIcon from 'assets/img/accounts/icons/invoice.png'
import StoreOut from '../storeouts/StoreOut'
import moment from 'moment'

let timeout

function Index() {

    const location = useLocation()
    const navigate = useHistory()
    const query = useQuery()
    const auth = useAuthDataContext()
    const app = useAppContext()

    const { createInvoiceFromAccount } = useInvoiceFromAccount()

    const deleteModalRef = useRef(null)
    const closeModalRef = useRef(null)
    const viewModalRef = useRef(null)
    const tableRef = useRef(null)
    const newStornoModalRef = useRef(null)
    const nraExportModalRef = useRef(null)
    const storeOutModalRef = useRef(null)

    const [state, setState] = useState({
        loading: true,
        refresh: false,
        setFilter: false,
        data: {},
        totals: {},
        headings: {},
        types: {},
        pages: 0,
        total: 0,
        filter: {
            page: 1,
        },
        columns: {
            all: {},
            details: {},
            selected: [],
            sortable: [],
            sort: null,
            order: null,
        },
        tableKey: '',
        request: null,
        onDataLoaded: null
    })

    const [selected, setSelected] = useState([])

    useEffect(() => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: query.get('page') || 1,
                limit: query.get('limit') || '',
                search: query.get('search') || '',
                account_num: query.get('account_num') || '',
                status_id: query.get('status_id') || '',
                client_id: query.get('client_id') || '',
                article_id: query.get('article_id') || '',
                category_id: query.getAll('category_id[]') || [],
                created_from_date: query.get('created_from_date') || '',
                created_to_date: query.get('created_to_date') || '',
                creator_id: query.getAll('creator_id[]') || [],
                speditor_id: query.getAll('speditor_id[]') || [],
                tracking_number: query.get('tracking_number') || '',
                paymethod_id: query.getAll('paymethod_id[]') || [],
                cashdesk_id: query.has('cashdesk_id[]') ? query.getAll('cashdesk_id[]') : auth.getUser().getCashdesks().map(c => c.id),
                paid_status: query.get('paid_status') || '',
                deleted: query.get('deleted') || '',
                sort: query.get('sort') || '',
                order: query.get('order') || ''
            },
            refresh: moment().unix()
        }))
    }, [location.search, auth.getUser().getStoreId()])

    useEffect(() => {
        if (state.setFilter) {
            navigate.push('?' + buildSearchParams(query, state.filter))
        }

    }, [state.setFilter])

    useEffect(() => {
        if (state.filter.cashdesk_id?.length) {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: query.get('page') || 1,
                    cashdesk_id: auth.getUser().getCashdesks().map(c => c.id),
                },
                refresh: moment().unix()
            }))
        }
    }, [auth.getUser().getStoreId()])

    useEffect(() => {
        if (state.refresh) {
            loadData()
        }
    }, [state.refresh])

    useEffect(() => {
        refresh()
    }, [auth.getUser()?.getStoreId()])

    const loadData = () => {

        if (state.request) {
            state.request.cancel()
        }

        let request = axios.CancelToken.source()

        setState(prev => ({
            ...prev,
            request: request,
            loading: true,
        }))

        let url = 'store/accounts/all'

        Api.get(url, {
            params: state.filter,
            cancelToken: request.token
        }).then(res => {

            if (typeof state.onDataLoaded === 'function') {
                state.onDataLoaded(res.data)
            }

            setState(prev => ({
                ...prev,
                currency: res.data.currency,
                data: res.data.items,
                total: res.data.total,
                pages: res.data.pages,
                totals: res.data.totals,
                headings: res.data.headings,
                types: res.data.types,
                columns: res.data.columns,
                tableKey: res.data.tableKey,
                loading: false,
                sorting: false,
                onDataLoaded: null,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                }
            }))

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 })
            }
        })
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }))
    }

    const sorting = (sorting) => {
        setState(prev => ({
            ...prev,
            sorting: Boolean(sorting)
        }))
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: reset ? 1 : prev.filter.page
            },
            setFilter: moment().unix(),
            refresh: moment().unix(),
        }))
    }

    const refreshTable = () => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                sort: '',
                order: '',
                page: 1,
                limit: '',
            },
            setFilter: moment().unix(),
            refresh: moment().unix(),
        }))
    }

    const handleAdd = () => {
        navigate.push('/accounts/new')
    }

    const handleAddStorno = () => {
        let modal = newStornoModalRef.current

        if (!modal) {
            return
        }

        modal.open()
    }

    // update
    const handleEdit = data => {
        if (data.deleted) {
            return
        }

        navigate.push('/accounts/' + data.account_id + '/edit')
    }

    // delete
    const handleDelete = data => {
        let modal = deleteModalRef.current

        modal.open()
        modal.onSuccess(() => {
            handleDestroy(data.account_id)
        })
    }

    const handleDestroy = id => {
        Api.post('store/accounts/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                refresh(false)
            }
        })
    }

    const handleRestore = data => {
        Api.post('store/accounts/restore', {
            id: data.account_id
        }).then(res => {
            if (res.data.success) {
                refresh(false)
            }
        })
    }

    const handleShow = id => {
        let modal = viewModalRef.current

        modal.open(id, false)
    }

    const handleView = data => {
        let modal = viewModalRef.current

        modal.open(data.account_id)
    }

    // close
    const handleClose = data => {
        let modal = closeModalRef.current

        modal.open(data.account_id)
        modal.onSuccess(() => {
            refresh(false)
        })
    }

    // Search
    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout)

        if (typeof key === 'object') {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    ...key
                },
            }))
        } else {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    [key]: val,
                },
            }))
        }

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                setFilter: moment().unix()
            }))
        }, delay)
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            setFilter: moment().unix()
        }))
    }

    const handleSort = (sort, order) => {
        setState(prev => ({
            ...prev,
            sorting: true,
            filter: {
                ...prev.filter,
                sort: sort,
                order: order,
                page: 1,
            },
            setFilter: moment().unix()
        }))
    }

    const showClient = id => {
        if (!id) {
            return
        }

        let modal = Refs.getInstance().getRef('client')

        if (!modal) {
            return
        }

        modal.open(id)
    }

    const handleCreateInvoice = () => {
        if (selected.length === 0) {
            return app.showWarning('Изберете поне 1 сметка')
        }

        createInvoiceFromAccount(selected, INVOICE)
            .then(res => {
                if (res) {
                    navigate.push("/invoices", {
                        data: res,
                        mode: "new",
                    })
                }
            })
    }

    const handleExport = () => {
        let modal = nraExportModalRef.current

        if (!modal) {
            return
        }

        modal.open()
    }

    const toggleRow = (id) => {
        if (isSelected(id)) {
            setSelected(prev => prev.filter(i => Number(i) !== Number(id)))
        } else {
            setSelected(prev => prev.concat(Number(id)))
        }
    }

    const isSelected = id => {
        return selected.indexOf(Number(id)) > -1
    }

    const toggleAll = () => {
        if (isAllSelected()) {
            setSelected([])
        } else {
            setSelected(state.data.map(c => Number(c.account_id)))
        }
    }

    const selectAll = () => {
        setSelected(state.data.map(i => Number(i.id)))
    }

    const isAllSelected = () => {
        let selected = true

        state.data.map(c => {
            if (!isSelected(c.account_id)) {
                selected = false
            }
        })

        return selected
    }

    const showNextItem = (id) => {
        let table = tableRef.current

        if (!table) {
            return
        }

        // console.log(table);

        let thisRow = table.querySelector('tr[data-id="' + id + '"]')
        let nextRow = thisRow.nextSibling

        if (nextRow && nextRow.getAttribute('data-id')) {
            showItem(
                nextRow.getAttribute('data-id')
            )
        } else {
            showNextPage()
        }
    }

    const showPrevItem = (id) => {
        let table = tableRef.current

        if (!table) {
            return
        }

        // console.log(table);

        let thisRow = table.querySelector('tr[data-id="' + id + '"]')
        let prevRow = thisRow.previousSibling

        if (prevRow) {
            showItem(
                prevRow.getAttribute('data-id')
            )
        } else {
            showPrevPage()
        }
    }

    const showNextPage = () => {
        if (state.pages > state.filter.page) {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: Number(state.filter.page) + 1,
                },
                onDataLoaded: data => {
                    setTimeout(() => {
                        showItem(data.items[0].account_id)
                    }, 0)
                },
                setFilter: moment().unix(),
            }))
        }
    }

    const showPrevPage = () => {
        if (state.filter.page > 1) {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: Number(state.filter.page) - 1,
                },
                onDataLoaded: data => {
                    setTimeout(() => {
                        showItem(data.items[data.items.length - 1].account_id)
                    }, 0)
                },
                setFilter: moment().unix(),
            }))
        }
    }

    const showItem = id => {
        let table = tableRef.current

        if (!table) {
            return
        }

        let row = table.querySelector('tr[data-id="' + id + '"]')

        if (row) {
            row.click()

            handleShow(id)
        }
    }

    const handleLimitChange = limit => {
        handleSearch('limit', limit, 0)
    }

    const handleAddOut = () => {
        // navigate.push('/storage/outs/new')
        storeOutModalRef.current.add()
    }

    return (
        <>

            <Question
                ref={deleteModalRef}
                mainMessage="Сигурни ли сте?"
                agreeBtnText="Изтриване"
                agreeBtnClass="remove"
            />

            <Close
                ref={closeModalRef}
            />

            <View
                ref={viewModalRef}
                showNextItem={id => showNextItem(id)}
                showPrevItem={id => showPrevItem(id)}
            />

            <NewStorno
                ref={newStornoModalRef}
            />

            <NraExport
                ref={nraExportModalRef}
            />

            <StoreOut
                ref={storeOutModalRef}
            />


            <Layout>
                <div className="container-fluid">

                    <div className="head-with-heading">
                        <h3>Продажби по сметки</h3>

                        <div className="buttons">
                            <Button
                                className="add"
                                onClick={handleAdd} disabled={auth.getUser().permission('accounts_edit') === false}
                            >
                                Нова продажба
                            </Button>
                            <Button
                                className="add"
                                onClick={handleAddOut} disabled={auth.getUser().permission('storeouts_edit') === false}
                                style={{
                                    marginLeft: '10px'
                                }}
                            >
                                Ново изписване
                            </Button>
                        </div>
                    </div>

                    <div className="panel" style={{
                        marginTop: 0
                    }}>


                        <div className="filter-holder">
                            <Filter
                                filter={state.filter}
                                handleSearch={handleSearch}
                            />
                            <PaginationMini
                                page={state.filter.page}
                                pages={state.pages}
                                handlePage={handlePage}
                                isLoading={state.loading}
                            />
                        </div>

                        {state.loading && !state.sorting
                            ?
                            <Skeleton
                                count={5}
                                height={60}
                            />
                            :
                            state.data.length === 0 ?
                                <NoDataFound />
                                :
                                <>
                                    <table className="type-outer">
                                        <thead>
                                            <tr>
                                                <th className="text-center">
                                                    <Checkbox
                                                        checked={isAllSelected()}
                                                        color="secondary"
                                                        onClick={toggleAll}
                                                    />
                                                </th>
                                                {Object.entries(state.headings).map((heading, i) =>
                                                    <DynamicTableCol
                                                        key={i}
                                                        type={state.types[heading[0]]}
                                                        name={heading[1]}
                                                        title={state.columns.description[heading[0]]}
                                                        sortKey={heading[0]}
                                                        sortable={state.columns.sortable.indexOf(heading[0]) > -1}
                                                    />
                                                )}
                                                <th className="options">
                                                    Опции
                                                </th>
                                            </tr>

                                        </thead>
                                        <tbody>
                                            {state.data.map(c =>
                                                <tr
                                                    key={'c-' + c.id}
                                                    className={c.deleted ? 'deleted' : ''}
                                                    data-id={c.account_id}
                                                >
                                                    <td className="text-center">
                                                        <Checkbox
                                                            color="primary"
                                                            checked={isSelected(c.account_id)}
                                                            onClick={e => toggleRow(c.account_id)}
                                                            value={1}
                                                        />
                                                    </td>
                                                    {Object.entries(state.headings).map((heading, i) =>
                                                        heading[0] === 'id'
                                                            ?
                                                            <td key={heading[0]} onClick={e => handleView(c)}>
                                                                {c[heading[0]]}
                                                            </td>
                                                            :
                                                            heading[0] === 'status'
                                                                ?
                                                                <td key={heading[0]} className={`text-center ${c.status_data?.code === 'open' ? 'blue-value' : c.status_data?.code === 'close' ? 'brown-value' : ''}`} onClick={e => handleView(c)}>
                                                                    {c?.status}
                                                                </td>
                                                                :
                                                                <DynamicTableBodyCol
                                                                    key={heading[0]}
                                                                    type={state.types[heading[0]]}
                                                                    name={c[heading[0]]}
                                                                    data={c}
                                                                    currency={state.currency}
                                                                    onClick={() => handleView(c)}
                                                                />
                                                    )}
                                                    <td className="options">
                                                        <MenuOptions>
                                                            <Options
                                                                data={c}
                                                                handleEdit={handleEdit}
                                                                handleClose={handleClose}
                                                                handleDelete={handleDelete}
                                                                handleRestore={handleRestore}
                                                                handleView={handleView}
                                                            />
                                                        </MenuOptions>
                                                    </td>
                                                </tr>
                                            )}
                                            {state.totals.map((c, i) =>
                                                <tr key={i} className="total">
                                                    {Object.entries(c.items).map((heading, i) =>
                                                        <DynamicTableBodyCol
                                                            key={heading[0]}
                                                            type={state.types[heading[0]]}
                                                            name={heading[1]}
                                                            data={c.items}
                                                            currency={state.currency}
                                                            colspan={c.colspan[heading[0]] + 1}
                                                        />
                                                    )}
                                                    <td>

                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    <Pagination
                                        className="mt-3"
                                        page={state.filter.page}
                                        pages={state.pages}
                                        total={state.total}
                                        handlePage={handlePage}
                                        limit={state.filter.limit}
                                        onLimitChange={handleLimitChange}
                                    />
                                </>
                        }

                    </div>
                </div>
            </Layout >

            {selected.length > 0 &&
                <div className="multiple-actions">

                    <div
                        className="top"
                        style={{
                            marginBlock: 0,
                            justifyContent: "center",
                            minHeight: "35px",
                            gap: '10px'
                        }}
                    >
                        <div className="selected-count">Избрани: {selected.length}</div>
                        <Button onClick={selectAll}>
                            Избери всички <i>{state.data.length}</i>
                        </Button>
                    </div>

                    <ul>
                        <li>
                            <Button
                                onClick={() => {
                                    handleCreateInvoice()
                                }}
                            >
                                <img src={invoiceIcon} />
                                Фактура
                            </Button>
                        </li>
                    </ul>
                </div>
            }
        </>
    )
}

export default Index