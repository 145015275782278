//components
import CampaignFormTemplateAnswer from "./CampaignFormTemplateAnswer"
import MenuOptions from "components/misc/MenuOptions"
import RedditSelect from "components/misc/RedditSelect"

//libraries
import { Button, MenuItem, Tooltip } from "@material-ui/core"

//constnts
import { CAMPAIGN_FORM_TEMPLATE_FIELD_TYPES } from "constants/CampaignFormTemplate"

//stores
import { useCampaignFormTemplate } from "stores/customer/useCampaignFormTemplate"

//images
import removeIcon from "assets/img/app/icons/delete-white.png"

function CampaignFormTemplateQuestion({ question }) {
  const { id, value, answers, parentGroupId, type } = question

  const { handleChangeQuestionType, addAnswer, removeQuestion } =
    useCampaignFormTemplate()

  function getAnswerType() {
    let index = 0
    const currentType = type

    Object.values(CAMPAIGN_FORM_TEMPLATE_FIELD_TYPES).map(({ type }, i) => {
      if (currentType === type) {
        index = i
      }

      return type
    })

    return Object.keys(CAMPAIGN_FORM_TEMPLATE_FIELD_TYPES)[index]
  }

  return (
    <div className="campaign-form-template-question">
      <div className="row">
        <input
          value={value}
          className="heading heading-3"
          placeholder="Въведете заглавие..."
        />
        <Button className="simple-select">
          <img
            src={CAMPAIGN_FORM_TEMPLATE_FIELD_TYPES[getAnswerType()]?.icon}
            alt=""
            className="event-type-icon"
          />
          <RedditSelect
            onChange={(e) =>
              handleChangeQuestionType(parentGroupId, id, e.target.value)
            }
            value={type}
          >
            {Object.values(CAMPAIGN_FORM_TEMPLATE_FIELD_TYPES).map(
              ({ type, title }) => (
                <MenuItem key={type} value={type}>
                  {title}
                </MenuItem>
              )
            )}
            <MenuItem value="free">Микс</MenuItem>
          </RedditSelect>
        </Button>
      </div>
      <ul>
        {answers.map(({ id, type, placeholderValue }, i) => (
          <CampaignFormTemplateAnswer
            key={id}
            answer={{
              id,
              parentGroupId,
              parentQuestionId: question.id,
              type,
              parentType: question.type,
              placeholderValue,
              index: i,
            }}
          />
        ))}
      </ul>
      <hr />

      <Button
        className="add"
        onClick={() => type !== "free" && addAnswer(parentGroupId, id, type)}
      >
        {type === "free" ? (
          <MenuOptions hoverTooltipText="Избор на поле за добавяне">
            {Object.values(CAMPAIGN_FORM_TEMPLATE_FIELD_TYPES)
              .filter(({ type }) => type !== "radio")
              .map(({ type, title }) => (
                <MenuItem
                  key={type}
                  onClick={() => addAnswer(parentGroupId, id, type)}
                >
                  {title}
                </MenuItem>
              ))}
          </MenuOptions>
        ) : (
          <></>
        )}
        Нов отговор
      </Button>
      <Tooltip title="Премахване на въпрос">
        <Button
          onClick={() => removeQuestion(parentGroupId, id)}
          className="remove-question"
        >
          <img src={removeIcon} alt="" />
        </Button>
      </Tooltip>
    </div>
  )
}

export default CampaignFormTemplateQuestion
