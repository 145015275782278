import { useState, useImperativeHandle, forwardRef, useEffect, useRef } from 'react'
import Skeleton from 'react-loading-skeleton'
import ClickAwayListener from 'react-click-away-listener'
import InfiniteScroll from 'react-infinite-scroller'
//plugins
import axios from 'axios'

//helpers
import Api from 'helpers/Api'

//misc
import RedditTextField from 'components/misc/RedditTextField'

//MUI components
import { CircularProgress, Button } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip/Tooltip'
import moment from 'moment'

let fieldBlur
function CustomerInput(props, ref) {
    const onChange = typeof props.onChange === 'function' ? props.onChange : null
    const onInputChange = typeof props.onInputChange === 'function' ? props.onInputChange : null
    const onFocus = typeof props.onFocus === 'function' ? props.onFocus : null
    const inputLabelProps = props.inputLabelProps
    const searchParamName = props.searchParamName || 'name'
    const autoFocus = props.autoFocus || false
    const noCompany = props.noCompany || false
    const tradeRegistryReqUrl = props.tradeRegistryReqUrl || ''
    const customersFindReqUrl = props.customersFindReqUrl || ''

    const [activeIndexesInList, setActiveIndexesList] = useState({
        myClients: 0,
        fromRegister: null
    })

    const defaultData = {}

    const [state, setState] = useState({
        initialLoad: true,
        showOptions: false,
        input: {
            value: '',
            timeout: null,
        },
        selected: null,
        customers: {
            data: [],
            total: 0,
            pages: 0,
            page: 0,
            hasMore: false,
            request: null,
            loading: false,
            dataLoaded: false,
        },
        tradeRegistry: {
            data: [],
            total: 0,
            pages: 0,
            page: 0,
            hasMore: false,
            request: null,
            loading: false,
            dataLoaded: false,
        },
        loading: false,
        filter: false,
        onError: null,
        onClose: null,
        refreshOnRequest: moment().unix()
    })

    const inputRef = useRef()

    useImperativeHandle(ref, () => ({
        onChange: fn => {
            setState(prev => ({
                ...prev,
                onChange: fn
            }))
        },
        onInputChange: fn => {
            setState(prev => ({
                ...prev,
                onChange: fn
            }))
        },
        onError: fn => {
            setState(prev => ({
                ...prev,
                onError: fn
            }))
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }))
        }
    }))

    function controlFromKeyboardIniti(e) {
        switch (e.keyCode) {
            //down
            case 40:
                if (getMyClientsActiveIndex() === null && getFromRegisterActiveIndex() === null) {
                    if (state.customers.data[0]) {
                        setMyClientsActiveIndex(0)
                        return
                    }
                }

                if (getMyClientsActiveIndex() !== null) {
                    const nextIndex = getMyClientsActiveIndex() + 1
                    if (state.customers.data[getMyClientsActiveIndex() + 1]) setMyClientsActiveIndex(nextIndex)
                    else {
                        if (state.tradeRegistry.data.length) setFromRegisterActiveIndex(0)
                        else setMyClientsActiveIndex(0)
                    }
                }

                if (getFromRegisterActiveIndex() !== null) {
                    const nextIndex = getFromRegisterActiveIndex() + 1
                    if (state.tradeRegistry.data[getFromRegisterActiveIndex() + 1]) setFromRegisterActiveIndex(nextIndex)
                    else {
                        if (state.customers.data.length) setMyClientsActiveIndex(0)
                        else setFromRegisterActiveIndex(0)
                    }
                }

                break
            //left
            case 37:
                if (getFromRegisterActiveIndex() !== null) {
                    if (state.customers.data[0]) setMyClientsActiveIndex(0)
                }

                break
            //right
            case 39:
                if (getMyClientsActiveIndex() !== null) {
                    if (state.tradeRegistry.data[0]) setFromRegisterActiveIndex(0)
                }

                break
            //up
            case 38:
                if (getMyClientsActiveIndex() !== null) {
                    const nextIndex = getMyClientsActiveIndex() - 1
                    if (state.customers.data[getMyClientsActiveIndex() - 1]) setMyClientsActiveIndex(nextIndex)
                    else {
                        if (state.tradeRegistry.data.length) setFromRegisterActiveIndex(state.tradeRegistry.data.length - 1)
                        else setMyClientsActiveIndex(state.customers.data.length - 1)
                    }
                }

                if (getFromRegisterActiveIndex() !== null) {
                    const nextIndex = getFromRegisterActiveIndex() - 1
                    if (state.tradeRegistry.data[getFromRegisterActiveIndex() - 1]) setFromRegisterActiveIndex(nextIndex)
                    else {
                        if (state.customers.data.length) setMyClientsActiveIndex(state.customers.data.length - 1)
                        else setFromRegisterActiveIndex(state.tradeRegistry.data.length - 1)
                    }
                }

                break
            case 13:
                selectCustomer(getFromRegisterActiveIndex() ? state.tradeRegistry.data[getFromRegisterActiveIndex()] : state.customers.data[getMyClientsActiveIndex()])

                break
            default:
                break
        }
    }

    function setMyClientsActiveIndex(myClients) {
        if (myClients < 0) {
            myClients = 0
        }
        setActiveIndexesList(prev => ({
            ...prev,
            myClients,
            fromRegister: null
        }))
    }

    function setFromRegisterActiveIndex(fromRegister) {
        if (fromRegister < 0) {
            fromRegister = 0
        }
        setActiveIndexesList(prev => ({
            ...prev,
            fromRegister,
            myClients: null
        }))
    }

    function getMyClientsActiveIndex() {
        return activeIndexesInList.myClients
    }

    function getFromRegisterActiveIndex() {
        return activeIndexesInList.fromRegister
    }

    useEffect(() => {
        setTimeout(() => {
            inputRef.current?.focus()
        }, 500)
    }, [])

    useEffect(() => {
        setState(prev => ({
            ...prev,
            input: {
                ...prev.input,
                value: props.value || ''
            },
        }))

        clearTimeout(state.input.timeout)

        const timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: moment().unix()
            }))
        }, 300)

        setState(prev => ({
            ...prev,
            input: {
                ...prev.input,
                timeout
            }
        }))
    }, [props.value])

    useEffect(() => {
        if (state.showOptions && !state.customers.dataLoaded) loadCustomers(state.input.value)
    }, [props.companyId, state.showOptions])

    useEffect(() => {
        if (!state.filter && !noCompany) return

        setState(prev => ({
            ...prev,
            loading: true,
        }))

        const promise = Promise.all([
            loadCustomers(state.input.value),
            loadCustomersFromTradeRegistry(state.input.value)
        ])

        promise.finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }))
        })
    }, [state.filter])

    useEffect(() => {
        if (!props.handleShowFreeTextFields) return

        if (hasNoResults() && state.input.value.length >= 5) {
            props.handleShowFreeTextFields(true)
        } else {
            props.handleShowFreeTextFields(false)
        }
    }, [state.refreshOnRequest])

    const setRefreshOnRefres = refreshOnRequest => {
        setState(prev => ({
            ...prev,
            refreshOnRequest: moment().unix()
        }))
    }

    useEffect(() => {
        if (!state.customers.data.length && !state.tradeRegistry.data.length && state.initialLoad) {
            setState(prev => ({
                ...prev,
                showOptions: false
            }))
        } else {
            if (!noCompany) {
                setState(prev => ({
                    ...prev,
                    showOptions: true
                }))
            }
        }
        if (state.customers.page || state.tradeRegistry.page) {
            setState(prev => ({
                ...prev,
                initialLoad: false
            }))
        }
    }, [state.customers.data.length, state.tradeRegistry.data.length])

    const loadCustomers = async (name, page = 1, reset = true) => {
        if (!props.companyId && !noCompany) return

        if (state.customers.request) state.customers.request.cancel()

        const request = axios.CancelToken.source()

        setState(prev => ({
            ...prev,
            customers: {
                ...prev.customers,
                loading: true,
                request: request,
                hasMore: false,
            }
        }))

        return Api.get(customersFindReqUrl,
            {
                params: {
                    company_id: props.companyId,
                    [searchParamName]: name,
                    page
                },
                cancelToken: request.token
            }
        )
            .then(res => {
                setState(prev => ({
                    ...prev,
                    customers: {
                        ...prev.customers,
                        data: reset ? res.data.items : [...prev.customers.data, ...res.data.items],
                        total: res.data.total,
                        pages: res.data.pages,
                        hasMore: res.data.pages > page,
                        loading: false,
                        dataLoaded: true,
                    },
                }))
                setRefreshOnRefres()

                setActiveIndexesList({
                    myClients: 0,
                    fromRegister: null
                })
            })

    }

    const loadCustomersFromTradeRegistry = async (name, page = 1, reset = true) => {
        // if (!props.companyId) return

        if (state.tradeRegistry.request) state.tradeRegistry.request.cancel()

        const request = axios.CancelToken.source()

        setState(prev => ({
            ...prev,
            tradeRegistry: {
                ...prev.tradeRegistry,
                loading: true,
                request,
                hasMore: false,
            }
        }))

        return Api.get(tradeRegistryReqUrl,
            {
                params: {
                    company_id: props.companyId,
                    [searchParamName]: name,
                    page
                },
                cancelToken: request.token
            }
        )
            .then(res => {
                setState(prev => ({
                    ...prev,
                    tradeRegistry: {
                        ...prev.tradeRegistry,
                        // data: res.data.items,
                        data: reset ? res.data.items : [...prev.tradeRegistry.data, ...res.data.items],
                        total: res.data.total,
                        pages: res.data.pages,
                        hasMore: res.data.pages > page,
                        loading: false,
                        dataLoaded: true,
                    },
                }))
                setRefreshOnRefres()
                setActiveIndexesList({
                    myClients: 0,
                    fromRegister: null
                })
            })
            .catch(() => {
                setState(prev => ({
                    ...prev,
                    tradeRegistry: {
                        ...prev.tradeRegistry,
                        data: [],
                        total: 0,
                        pages: 0,
                        hasMore: false,
                        loading: false,
                        dataLoaded: true,
                    },
                }))
            })

    }

    const resetData = () => {
        setState(prev => ({
            ...prev,
            data: defaultData,
        }))
    }

    const handleInputChange = e => {
        const { name, value } = e.target

        if (!state.loading) setLoading(true)

        setState(prev => ({
            ...prev,
            input: {
                ...prev.input,
                value
            },
        }))

        if (onInputChange) onInputChange(value, true)

        clearTimeout(state.input.timeout)

        const timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: moment().unix()
            }))
        }, 300)

        setState(prev => ({
            ...prev,
            input: {
                ...prev.input,
                timeout
            }
        }))

        if (value.length >= 3) showOptions()
    }

    const setLoading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    const handleToggleOptions = () => {
        setState(prev => ({
            ...prev,
            showOptions: !prev.showOptions
        }))
    }

    const showOptions = () => {
        if (!props.companyId && !noCompany) return

        if (state.showOptions) return

        setState(prev => ({
            ...prev,
            showOptions: true
        }))
    }

    const hideOptions = () => {
        setState(prev => ({
            ...prev,
            showOptions: false
        }))
    }

    const handleClickAway = () => {
        hideOptions()
    }

    const selectCustomer = customer => {
        setState(prev => ({
            ...prev,
            selected: customer
        }))

        if (onChange) onChange(customer)

        hideOptions()
    }

    function renderAddButton() {
        if (state.input.value.length) {
            return <Button
                className={`add ${!state.input.value.length ? 'disabled' : ''}`}
                style={{
                    marginTop: '10px'
                }}
                onClick={() => {
                    props.handleShowClientHiddenFields(true, state.input.value)
                }}
            >
                Добавяне на данни
            </Button>
        }

        return <></>
    }

    function hasNoResults() {
        return state.customers.data.length === 0 && state.tradeRegistry.data.length === 0
    }

    function getInputType() {
        if (hasNoResults() && state.input.value.length) {
            if (!Number.isNaN(Number(state.input.value))) {
                if (state.input.value.length >= 4) {
                    return 'COMPANY_EIK'
                }
                return 'COMPANY_NAME'
            }
            return 'COMPANY_NAME'
        }

        return 'UNKNOWN'
    }

    function getlabel() {
        switch (getInputType()) {
            case 'COMPANY_EIK':
                return 'ЕИК'
            case 'COMPANY_NAME':
                return 'Фирма'
            case 'UNKNOWN':
                return 'Търсене на клиент'
            default:
                break
        }
    }

    function handleEnter(e) {
        if (e.keyCode === 13 || e.key === 'Enter') {
            e.preventDefault()
            props.handleShowClientHiddenFields(true, state.input.value)
        }
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={`customer-input ${hasNoResults() ? 'with-direct-add' : ''}`}>
                <RedditTextField
                    margin="dense"
                    size="small"
                    fullWidth
                    label={getlabel()}
                    name={props.name}
                    value={state.input.value || ''}
                    onChange={handleInputChange}
                    // onClick={showOptions}
                    onKeyPress={handleEnter}
                    onKeyDown={controlFromKeyboardIniti}
                    error={props.error}
                    helperText={props.helperText}
                    onFocus={() => {
                        clearTimeout(fieldBlur)
                        onFocus && onFocus()
                        showOptions()
                    }}
                    onBlur={() => {
                        clearTimeout(fieldBlur)
                        fieldBlur = setTimeout(() => {
                            handleClickAway()
                        }, 500)
                    }}
                    autoFocus={autoFocus}
                    InputLabelProps={inputLabelProps}
                />
                {hasNoResults() ?
                    <Tooltip title="Бързо добавяне на клиент">
                        <div className='direct-add'>
                            {renderAddButton()}
                        </div>
                    </Tooltip>
                    :
                    <>
                    </>
                }

                <div className={`dropdown ${state.showOptions && !hasNoResults() ? 'show' : ''} ${!state.customers.data.length || !state.tradeRegistry.data.length ? 'half' : ''}`}>
                    {state.loading
                        ?
                        <Skeleton count={5} height={30} />
                        :
                        hasNoResults()
                            ?
                            <>
                                <p>
                                    Няма намерени резултати
                                </p>
                                {renderAddButton()}
                            </>
                            :
                            <div className="row">
                                {state.customers.data.length > 0
                                    ?
                                    <div className={`col ${state.tradeRegistry.data.length > 0 ? 'col-2' : ''}`}>
                                        <div className="head">
                                            Моите клиенти
                                        </div>
                                        <div className="ul">
                                            <InfiniteScroll
                                                key={0}
                                                element="ul"
                                                pageStart={1}
                                                initialLoad={false}
                                                loadMore={(page) => loadCustomers(state.input.value, page, false)}
                                                hasMore={state.customers.hasMore}
                                                loader={<li key={-1}><CircularProgress size={20} /></li>}
                                                useWindow={false}
                                            >
                                                {state.customers.data.map((customer, i) =>
                                                    <li
                                                        key={'c-' + i}
                                                        onClick={e => selectCustomer(customer)}
                                                        className={state.selected && state.selected.id === customer.id && state.selected.class === customer.class || activeIndexesInList.myClients === i ? 'selected' : ''}
                                                    >
                                                        {customer?.info?.name}
                                                        <span style={{ display: 'block', fontSize: '10px' }}>
                                                            ЕИК: {customer?.info?.eikegn}, {customer.addresses[0]?.settlement?.name}
                                                        </span>
                                                    </li>
                                                )}
                                            </InfiniteScroll>
                                        </div>
                                    </div>
                                    :
                                    ''
                                }
                                {state.tradeRegistry.data.length > 0
                                    ?
                                    <div className={`col ${state.customers.data.length > 0 ? 'col-2' : ''}`}>
                                        <div className="head">
                                            От търговския регистър
                                        </div>
                                        <div className="ul">
                                            <InfiniteScroll
                                                key={1}
                                                element="ul"
                                                pageStart={1}
                                                initialLoad={false}
                                                loadMore={(page) => loadCustomersFromTradeRegistry(state.input.value, page, false)}
                                                hasMore={state.tradeRegistry.hasMore}
                                                loader={<li key={-1}><CircularProgress size={20} /></li>}
                                                useWindow={false}
                                            >
                                                {state.tradeRegistry.data.map((customer, i) =>
                                                    <li
                                                        key={'tr-' + i}
                                                        onClick={e => selectCustomer(customer)}
                                                        className={state.selected && state.selected.id === customer.id && state.selected.class === customer.class || activeIndexesInList.fromRegister === i ? 'selected' : ''}
                                                    >
                                                        {customer?.info?.name}
                                                        <span style={{ display: 'block', fontSize: '10px' }}>
                                                            ЕИК: {customer?.info?.eikegn}, {customer.addresses[0]?.settlement?.name}
                                                        </span>
                                                    </li>
                                                )}
                                            </InfiniteScroll>
                                        </div>
                                    </div>
                                    :
                                    ''
                                }
                            </div>
                    }
                </div>
            </div>
        </ClickAwayListener>
    )
}

export default forwardRef(CustomerInput)