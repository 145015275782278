import { useState, useImperativeHandle, forwardRef } from 'react'
import Button from '@material-ui/core/Button';

//images
import attentionIcon from 'assets/img/modals/icons/attention.png'
import loader from 'assets/img/loader.svg'

function Error(props, ref) {
    const [state, setState] = useState({
        overlay: false,
        modal: false,
        params: null,
        msg: null,
        onSuccess: null,
        onClose: null,
    });

    useImperativeHandle(ref, () => ({
        open: (msg = null) => {
            handleShow(msg);
        },
        close: () => {
            handleClose();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    const show = () => {
        setState(prev => ({
            ...prev,
            overlay: true,
        }));

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                modal: true,
            }))
        }, 50);
    }

    const hide = () => {
        setState(prev => ({
            ...prev,
            modal: false,
        }));

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
            }));
        }, 100);
    }

    const handleAccept = () => {
        handleClose();

        if (state.onSuccess && typeof state.onSuccess === 'function') {
            state.onSuccess();
        }
    }

    const handleShow = (msg) => {
        setState(prev => ({
            ...prev,
            msg: msg
        }));

        show();
    }

    const handleClose = () => {
        hide();

        if (typeof state.onClose === 'function') {
            state.onClose();
        }
    }

    return (
        <div className={`${state.overlay ? 'visible' : ''} overlay`}>
            <div className={`popup-primary small question ${state.modal ? 'show' : ''}`}>
                <div className="body">
                    <img src={attentionIcon} alt="question" className="main-image" />
                    {props.mainMessage &&
                        <h3>
                            {props.mainMessage}
                        </h3>
                    }
                    <p>
                        {state.msg || props.secondaryMessage}
                    </p>
                </div>
                <div className="footer">
                    {state.loading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            <Button
                                variant="contained"
                                color="primary"
                                className="save"
                                onClick={handleAccept}
                            >
                                Разбрах
                            </Button>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default forwardRef(Error);