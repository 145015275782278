import { useState, useImperativeHandle, forwardRef, useRef, useEffect } from 'react'
import { Modal, Button, Row, Col, FloatingLabel, Form, Table } from 'react-bootstrap';
import { createPortal } from 'react-dom';

import { useAuthDataContext } from 'providers/Auth';
import { useAppContext } from 'providers/App';
import Loader from 'components/misc/Loader';
import Pagination from 'components/misc/Pagination';
import DynamicTableCol from 'components/partials/table/DynamicTableCol';
import DynamicTableBodyCol from 'components/partials/table/DynamicTableBodyCol';
import axios from 'axios';
import TableOptions from 'components/misc/TableOptions';
import NoDataFound from 'components/misc/NoDataFound';
import TableHeader from 'components/misc/TableHeader';
import Api from 'helpers/Api';

import { OPERATION_TYPE_IDS } from 'constants/imports';
import moment from 'moment'

function Configs(props, ref) {

    const auth = useAuthDataContext();
    const app = useAppContext();

    const TYPE = props.type;
    const OPERATION_TYPE_ID = OPERATION_TYPE_IDS[TYPE];

    const defaultData = {
        sorting: false,
        loading: true,
        refresh: false,
        data: {},
        headings: {},
        types: {},
        pages: 0,
        total: 0,
        filter: {
            page: 1,
        },
        columns: {
            all: {},
            details: {},
            selected: [],
            sortable: [],
            sort: null,
            order: null,
        },
        tableKey: '',
        request: null,
    }

    const [state, setState] = useState({
        show: false,
        params: null,
        data: defaultData,
        onSuccess: null,
        onClose: null,
        onEntering: null,
        onExiting: null,
    });

    const tableRef = useRef();

    useImperativeHandle(ref, () => ({
        open: () => {
            handleShow();
        },
        close: () => {
            handleClose();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        },
        onCancel: fn => {
            setState(prev => ({
                ...prev,
                onCancel: fn
            }));
        }
    }));

    useEffect(() => {
        if (!state.show) {
            return;
        }

        loadData();
    }, [state.show, state.data.refresh]);

    const loadData = () => {

        if (state.data.request) {
            state.data.request.cancel();
        }

        let request = axios.CancelToken.source();

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                request: request,
                loading: true,
            }
        }));

        let url = 'imports/configs?operation_type_id=' + OPERATION_TYPE_ID;

        Api.get(url, {
            params: state.data.filter,
            cancelToken: request.token
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    data: res.data.items,
                    total: res.data.total,
                    pages: res.data.pages,
                    headings: res.data.headings,
                    types: res.data.types,
                    columns: res.data.columns,
                    tableKey: res.data.tableKey,
                    loading: false,
                    sorting: false,
                    filter: {
                        ...prev.data.filter,
                        ...res.data.filter,
                    }
                }
            }));

            if (res.data.pages > 0 && state.data.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 });
            }
        });
    }

    const refreshTable = () => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                filter: {
                    ...prev.data.filter,
                    sort: '',
                    order: '',
                    page: 1
                },
                refresh: moment().unix(),
            }
        }));
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                filter: {
                    ...prev.data.filter,
                    page: page.selected + 1,
                },
                refresh: moment().unix(),
            }
        }));
    }

    const handleSort = (sort, order) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                sorting: true,
                filter: {
                    ...prev.data.filter,
                    sort: sort,
                    order: order,
                    page: 1,
                },
                refresh: moment().unix()
            }
        }));
    }

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = () => {
        setState(prev => ({
            ...prev,
            data: defaultData
        }));

        show();
    }

    const handleClose = () => {
        hide(state.onClose);
    }

    const handleSave = e => {
        e.preventDefault();

        // setState(prev => ({
        //     ...prev,
        //     loading: true,
        // }));


    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: value
            }
        }))
    }

    const setConfig = config => {
        hide(state.onClose);

        state.onSuccess(config);
    }

    return createPortal(
        <Modal size="lg" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Шаблони с конфигурации
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {state.data.loading && !state.data.sorting
                    ?
                    <Loader />
                    :
                    state.data.data.length === 0 ?
                        <NoDataFound />
                        :
                        <>
                            <div className="pagination-with-options mb-3">
                                <Pagination
                                    page={state.data.filter.page}
                                    pages={state.data.pages}
                                    handlePage={handlePage}
                                />

                                <TableOptions
                                    url={`imports/configs?operation_type_id=${OPERATION_TYPE_ID}`}
                                    filter={state.data.filter}
                                    columns={state.data.columns}
                                    tableKey={state.data.tableKey}
                                    refresh={refreshTable}
                                />
                            </div>

                            <Table className="big" striped hover ref={tableRef}>
                                <TableHeader
                                    tableRef={tableRef}
                                    activeSortKey={state.data.filter.sort}
                                    activeSortDir={state.data.filter.order}
                                    onSort={(col, dir) => handleSort(col, dir)}
                                >
                                    {Object.entries(state.data.headings).map((heading, i) =>
                                        <DynamicTableCol
                                            key={i}
                                            type={state.data.types[heading[0]]}
                                            name={heading[1]}
                                            title={state.data.columns.description[heading[0]]}
                                            sortKey={heading[0]}
                                            sortable={state.data.columns.sortable.indexOf(heading[0]) > -1}
                                        />
                                    )}
                                    <th className="options">Опции</th>
                                </TableHeader>
                                <tbody>
                                    {state.data.data.map((c, index) =>
                                        <tr
                                            key={'c-' + index}
                                            className={c.deleted ? 'deleted' : ''}
                                        >
                                            {Object.entries(state.data.headings).map((heading, i) =>
                                                <DynamicTableBodyCol
                                                    key={heading[0]}
                                                    type={state.data.types[heading[0]]}
                                                    name={c[heading[0]]}
                                                    data={c}
                                                />
                                            )}
                                            <td className="options">
                                                <Button
                                                    variant="primary"
                                                    size="sm"
                                                    onClick={e => setConfig(c.config)}
                                                >
                                                    Избери
                                                </Button>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>

                            <Pagination
                                className="mt-3"
                                page={state.data.filter.page}
                                pages={state.data.pages}
                                total={state.data.total}
                                handlePage={handlePage}
                            />
                        </>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Затвори
                </Button>

            </Modal.Footer>
        </Modal>,
        document.body
    )

}

export default forwardRef(Configs);