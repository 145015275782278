
import { useState } from 'react'
import ClickAwayListener from 'react-click-away-listener'

import { Radio } from '@material-ui/core'

//constants
import { SENT_FILTER_TYPES } from 'constants/Document'

//helpers
import { useQuery } from 'helpers/Url'

const Sent = props => {
    const query = useQuery()
    const { sent, handleSearch } = props

    const [state, setState] = useState({
        visible: false
    })
    const handleVisibilty = () => {
        setState(prev => ({
            ...prev,
            visible: !prev.visible
        }))
    }

    /**
     * избор на статус
     * @param {number} value 
     */
    const handleSelect = value => {
        if (value === '0') value = ''

        handleSearch('sent', value)
    }

    return (
        <div className="col">
            <div
                className="custom-filter-select sent"
                onClick={handleVisibilty}
            >
                <div className="option">
                    {SENT_FILTER_TYPES[sent || query.get('sent') || 0]}
                    {sent !== '' ?
                        <span className="applied"></span>
                        :
                        <>
                        </>
                    }
                </div>
                {state.visible ?
                    <ClickAwayListener
                        onClickAway={handleVisibilty}
                    >
                        <div className="sent-options">
                            <ul>
                                {Object.values(SENT_FILTER_TYPES).map((t, i) =>
                                    <li
                                        key={`sent-${i}`}
                                        onClick={() => {
                                            handleSelect(Object.keys(SENT_FILTER_TYPES)[i])
                                        }}
                                    >
                                        <Radio
                                            color="primary"
                                            style={{
                                                padding: '0',
                                                marginRight: '5px',
                                            }}
                                            checked={Object.keys(SENT_FILTER_TYPES)[i] === (sent || query.get('sent') || '0')}
                                        />
                                        {t}
                                    </li>
                                )}
                            </ul>
                        </div>
                    </ClickAwayListener>
                    :
                    <>
                    </>
                }
            </div>
        </div>
    )
}

export default Sent