import { useRef, useState } from "react"
import { createPortal } from "react-dom"

//MUI components
import { Button, Popover } from "@material-ui/core"

//images
import editIcon from "assets/img/documents/icons/edit-lines.png"
import buildingIcon from "assets/img/documents/icons/building.png"
import userColoredIcon from "assets/img/documents/icons/user-colored.png"
import editColoredIcon from "assets/img/documents/icons/edit-colored.png"
import storeColoredIcon from "assets/img/documents/icons/store-colored.png"
import storeAddColoredIcon from "assets/img/documents/icons/store-add-colored.png"

//hooks
import useSetActiveTradeObject from "hooks/useSetActiveTradeObject"

//components
import AddOrEditStore from "components/pages/customers/partials/AddOrEditStore"
import View from "components/pages/customers/view/View"

//providers
import { useAppContext } from "providers/App"
import ClientProvider from 'components/pages/customers/Provider';

function ClientConfig({
  clientId = null,
  clientName = null,
  clientEik = null,
  clientAddresses = [],
  activeClientAddressId = null,
  handleChangeCustomerTradeObject = () => {},
}) {
  const { handleSuccess } = useAppContext()

  const { isLoading, setActiveTradeObject } = useSetActiveTradeObject()

  const addOrEidtStoreRef = useRef(null)
  const openConfigModalButtonRef = useRef(null)
  const viewClientRef = useRef(null)

  const [anchorEl, setAnchorEl] = useState(null)
  const [newlyAddedTradeObjects, setNewlyAddedTradeObjects] = useState([])

  function handleClose() {
    setAnchorEl(null)
  }

  function handleOpen(e) {
    setAnchorEl(e.target)
  }

  function handleChangeAddress(address) {
    handleChangeCustomerTradeObject(address)

    setActiveTradeObject(clientId, address.id)
  }

  function handleAddTradeObject() {
    handleClose()

    const modal = addOrEidtStoreRef.current

    setTimeout(() => {
      modal.add()

      modal.onSuccess((data) => {
        handleSuccess("Търговския обект беше добавен успешно!")
        setAnchorEl(openConfigModalButtonRef.current)
        setNewlyAddedTradeObjects([...newlyAddedTradeObjects, data])
      })
    }, 200)
  }

  function handleViewClient() {
    handleClose()

    const modal = viewClientRef.current

    modal.open(clientId, 'dashboard')
    modal.onClose(() => {
      setAnchorEl(openConfigModalButtonRef.current)
    })
  }

  function handleEditClient() {
    handleClose()

    const modal = viewClientRef.current

    modal.open(clientId, 'edit')
    modal.onClose(() => {
      setAnchorEl(openConfigModalButtonRef.current)
    })
  }

  return (
    <>
      <Button
        className="btn-primary open-client-config-modal-btn"
        onClick={handleOpen}
        ref={openConfigModalButtonRef}
      >
        <img src={editIcon} alt="" />
      </Button>
      {createPortal(
        <>
          <Popover
            className="client-config-modal"
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={handleClose}
            transitionDuration={200}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <div className="head">
              <img src={buildingIcon} alt="" />
              <div className="right">
                <h3>{clientName}</h3>
                <p>ЕИК: {clientEik}</p>
              </div>
            </div>
            <ul className="options-list">
              <li onClick={handleViewClient}>
                <img src={userColoredIcon} alt="" />
                Основни данин
              </li>
              <li onClick={handleEditClient}>
                <img src={editColoredIcon} alt="" />
                Редактиране
              </li>
            </ul>
            {clientAddresses.length > 1 ? (
              <div className="objects-container">
                <h3>Обекти</h3>
                <div className="holder">
                  <ul className={isLoading ? "disabled" : ""}>
                    {[...clientAddresses, ...newlyAddedTradeObjects].map(
                      (address) => (
                        <li
                          key={address.id}
                          className={
                            activeClientAddressId === address.id
                              ? "selected"
                              : ""
                          }
                          onClick={() => {
                            handleChangeAddress(address)
                          }}
                        >
                          <img src={storeColoredIcon} alt="" />
                          <div className="right">
                            <b>{address.name}</b>
                            <p>{address.full_address}</p>
                          </div>
                        </li>
                      )
                    )}
                  </ul>
                  <Button onClick={handleAddTradeObject}>
                    <img src={storeAddColoredIcon} alt="" />
                    Добавяне на нов обект
                  </Button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </Popover>
          <AddOrEditStore ref={addOrEidtStoreRef} clientId={clientId} />
          <ClientProvider>
            <View ref={viewClientRef} />
          </ClientProvider>
        </>,
        document.body
      )}
    </>
  )
}

export default ClientConfig
