//MUI components
import MenuItem from '@material-ui/core/MenuItem'

//images
import editIcon from 'assets/img/app/icons/edit.png'
import deleteIcon from 'assets/img/app/icons/delete.png'

function Options(props) {
    const { data, handleEdit, handleDelete } = props

    return (
        <>
            <MenuItem
                onClick={() => {
                    handleEdit(data)
                }}
            >
                <img src={editIcon} />
                Редакция
            </MenuItem>

            <MenuItem
                onClick={() => {
                    handleDelete(data.id)
                }}
            >
                <img src={deleteIcon} />
                Изтриване
            </MenuItem>
        </>
    )
}

export default Options