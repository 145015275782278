//libraries
import MenuItem from "@material-ui/core/MenuItem"

//images
import editIcon from "assets/img/app/icons/edit.png"
import deleteIcon from "assets/img/app/icons/delete.png"

function PipelinesStatusOptions({ handleEdit, handleRemove }) {
  const OPTIONS = [
    {
      icon: editIcon,
      action: handleEdit,
      title: "Редакция",
    },
    {
      icon: deleteIcon,
      action: handleRemove,
      title: "Изтриване",
    },
  ]

  return OPTIONS.map(({ action, title, icon }) => (
    <MenuItem onClick={action}>
      <img src={icon} alt={title} />
      {title}
    </MenuItem>
  ))
}

export default PipelinesStatusOptions
