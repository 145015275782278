//helpers
import { useQuery as uQ } from 'react-query'

//helpers
import Api from "helpers/Api"

//libraries
import moment from 'moment'

function useFetchCalendar(reqData) {
    const weekParam = `week?start=${reqData.startDate}&end=${reqData.endDate}`
    const monthPram = `month?year=${moment(reqData.startDate).format('YYYY')}&month=${moment(reqData.startDate).format('MM')}`

    function getParam() {
        return reqData.endDate ? weekParam : monthPram
    }

    const { data, isLoading, isFetching, refetch } = uQ(['fake-crm-calendar', getParam()], _fetchData, {
        staleTime: 100,
    })

    async function _fetchData() {
        const res = await Api.get(`fake-crm-calendar/${getParam()}`)

        return res.data
    }

    function getCalendar() {
        return data || null
    }

    return {
        getCalendar,
        loadingCalendar: isFetching,
    }
}

export default useFetchCalendar