import axios from "axios";
import Api from "helpers/Api";
import { useRef, useState } from "react";

function useFetchInvoice() {

    const [isLoading, setIsLoading] = useState(false);

    const cancelToken = useRef();

    const fetchData = async (id) => {
        if (cancelToken.current) {
            cancelToken.current.cancel();
        }

        cancelToken.current = axios.CancelToken.source();

        setIsLoading(true);

        return await Api.get('documents/show', {
            params: {
                id: id
            },
            cancelToken: cancelToken.current.token,
        }).then(res => {

            return res.data;
        }).catch(err => {
            if (axios.isCancel(err)) {
                // 
            } else {
                // 
            }
        }).finally(() => {
            setIsLoading(false);
        });
    }

    return {
        isLoading,
        fetchData,
    };
}


export default useFetchInvoice;