import { useRef } from "react"

//helpers
import { splitLongNumbers } from "helpers/numbers"

//libraries
import Skeleton from "react-loading-skeleton"
import moment from "moment"
import { Button } from "@material-ui/core"

//components
import NoDataFound from "components/misc/NoDataFound"
import Refs from "Refs"

function SubscriptionsActivitiesDashboardTable({
  loadingActivities,
  getActivities,
  refetchData,
  clientId,
}) {

  function handleClickAddSubscriptionButton() {
    const modal = Refs.getInstance().getRef('newSubscription');

    modal.add(clientId)
    modal.onSuccess(() => {
      modal.hide()
      refetchData()
    })
  }

  if (loadingActivities) {
    return (
      <div
        style={{
          width: "100%",
        }}
      >
        <Skeleton count={1} height={35} />
        <Skeleton count={1} height={35} />
        <Skeleton count={1} height={35} />
        <Skeleton count={1} height={35} />
        <Skeleton count={1} height={35} />
      </div>
    )
  }

  if (!loadingActivities && !getActivities().length) {
    return (
      <>
        <NoDataFound
          style={{
            marginBottom: "-10px",
          }}
        >
          <h2>Няма налични записи</h2>
          <p>Може да добавите нов абонамент от бутона по-долу.</p>
          <Button
            className="add new-btn-primary"
            onClick={handleClickAddSubscriptionButton}
          >
            Абонамент
          </Button>
        </NoDataFound>
      </>
    )
  }

  return (
    <>
      <table className="type-outer">
        <thead>
          <tr>
            <th>№</th>
            <th>Тип</th>
            <th>Активен</th>
            <th>Дата на активиране</th>
            <th>Дата на изтичане</th>
            <th className="text-right">Сума</th>
          </tr>
        </thead>
        <tbody>
          {getActivities().map((activity) => (
            <tr key={activity.id}>
              <td
                style={{
                  cursor: "default",
                }}
              >
                {activity.id}
              </td>
              <td
                style={{
                  cursor: "default",
                }}
              >
                {activity.type}
              </td>
              <td
                style={{
                  cursor: "default",
                }}
              >
                {activity.is_active ? "Да" : "Не"}
              </td>
              <td
                style={{
                  cursor: "default",
                }}
              >
                {activity.activated_at
                  ? `${moment(activity.activated_at, "YYYY-MM-DDTHH:mm").format(
                    "DD.MM.YYYY"
                  )} г.`
                  : "-"}
              </td>
              <td
                style={{
                  cursor: "default",
                }}
              >
                {activity.expires_at
                  ? `${moment(activity.expires_at, "YYYY-MM-DDTHH:mm").format(
                    "DD.MM.YYYY"
                  )} г.`
                  : "-"}
              </td>
              <td
                style={{
                  cursor: "default",
                }}
                className="text-right"
              >
                {splitLongNumbers((activity.price || 0).toFixed(2))}{" "}
                {activity.currency.name}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <hr />
      <Button
        className="add new-btn-primary"
        onClick={handleClickAddSubscriptionButton}
      >
        Абонамент
      </Button>
    </>
  )
}

export default SubscriptionsActivitiesDashboardTable
