//MUI components
import MenuItem from '@material-ui/core/MenuItem'

//images
import editIcon from 'assets/img/app/icons/edit.png'
import deleteIcon from 'assets/img/app/icons/delete.png'

function Options(props) {
    const { data, handleEdit, handleDelete, handleActivate, handleRenew, handleAddStoreOut } = props

    return (
        <>
            {!props.data.is_active
                ?
                <MenuItem
                    onClick={() => handleActivate(data.id)}>
                    <img src={editIcon} alt="edit" />
                    Активирай
                </MenuItem>
                :
                <MenuItem
                    onClick={() => handleRenew(data.id)}>
                    <img src={editIcon} alt="edit" />
                    Поднови
                </MenuItem>
            }

            <MenuItem
                onClick={() => handleAddStoreOut(data.id)}>
                <img src={editIcon} alt="edit" />
                Ново изписване
            </MenuItem>

            {data.allow_edit ?
                <MenuItem
                    onClick={() => {
                        handleEdit(data.id)
                    }}
                >
                    <img src={editIcon} />
                    Редакция
                </MenuItem>
                :
                <>
                </>
            }
            {data.allow_delete ?
                <MenuItem
                    onClick={() => {
                        handleDelete(data.id)
                    }}
                >
                    <img src={deleteIcon} />
                    Изтриване
                </MenuItem>
                :
                <>
                </>
            }
        </>
    )
}

export default Options