import { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'

//misc
import MenuOptions from 'components/misc/MenuOptions'
import SaveButton from 'components/misc/Button'
import NoSelectedCompany from 'components/misc/NoSelectedCompany'
import RedditSelect from 'components/misc/RedditSelect'

//helpers
import Api from 'helpers/Api'

//MUI components
import Alert from '@material-ui/lab/Alert'
import { Button, MenuItem, Tooltip } from '@material-ui/core'

//partials
import Options from './partials/Options'

//modals
import AddOrEdit from './partials/AddOrEdit'
import Question from 'components/modals/Question'

//providers
import { useAuthDataContext } from 'providers/Auth'
import { useAppContext } from 'providers/App'

//images
import checkIcon from 'assets/img/documents/icons/check.png'
import checkGrayIcon from 'assets/img/documents/icons/check-gray.png'
import moment from 'moment'

function Index() {
    const history = useHistory()
    const auth = useAuthDataContext()
    const app = useAppContext()

    const companyId = auth?.getUser()?.getCompany()?.getId()

    const addOrEditModalRef = useRef(null)
    const deleteModalRef = useRef(null)

    const [state, setState] = useState({
        loading: true,
        refresh: false,
        data: [],
        loadingSave: false,
        editable: false
    })

    useEffect(() => {
        getData()
    }, [state.refresh])

    const getData = () => {
        if (!companyId) return

        loading(true)

        const url = 'documents/mail-signatures/all'

        Api.get(url)
            .then(res => {
                const { items } = res.data

                setState(prev => ({
                    ...prev,
                    data: items
                }))
            })
            .finally(() => {
                loading(false)
            })
    }

    /**
     * 
     * @param {boolean} loading дали да се визуализира зареждане 
     */
    const loading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    const refresh = () => {
        setState(prev => ({
            ...prev,
            refresh: moment().unix()
        }))
    }

    /**
     * 
     * @param {string} type тип на подписа (фирмен - add или личен - add-private)
     */
    const handleAdd = type => {
        const modal = addOrEditModalRef.current

        modal.add(type)
        modal.onSuccess(() => {
            refresh(true)
        })
    }

    /**
     * 
     * @param {object} data данни на подписа
     */
    const handleEdit = data => {
        const modal = addOrEditModalRef.current

        modal.edit(data)
        modal.onSuccess(() => {
            refresh(false)
        })
    }

    /**
     * 
     * @param {number} id id на подписа 
     */
    const handleDelete = id => {
        const modal = deleteModalRef.current

        modal.open()
        modal.onSuccess(() => {
            handleDestroy(id)
        })
    }

    /**
     * 
     * @param {number} id id на подписа 
     */
    const handleDestroy = id => {
        Api.post('documents/mail-signatures/delete', {
            id
        })
            .then(res => {
                app.handleSuccess('Подписа беше изтрит успешно!')
                if (res.data.success) refresh()
            })
            .catch(() => {
                app.handleError('Нещо се обърка!')
            })
    }

    const handleChangeDefaultSign = id => {
        handleSelect(id)

        const url = 'documents/mail-signatures/set-default'
        const data = {
            id
        }

        Api.post(url, data)
            .then(() => {
                app.handleSuccess('Данните бяха запазени успешно!')
            })
            .catch(() => {
                app.handleError('Нещо се обърка!')
            })
    }

    /**
     * 
     * @param {boolean} loadingSave дали да се визуализира зареждане при запазване
     */
    const setLoadingSave = loadingSave => {
        setState(prev => ({
            ...prev,
            loadingSave
        }))
    }

    /**
     * 
     * @param {Number} id 
     */
    const handleSelect = id => {
        const data = state.data.map(s => {
            if (s.id === id) {
                s.default = 1
            } else {
                s.default = 0
            }

            return s
        })

        setState(prev => ({
            ...prev,
            data,
            editable: true
        }))
    }

    /**
     * 
     * @param {string} text неформатиран текст 
     * @returns форматиран текст
     */
    const createMarkup = text => ({ __html: text })

    /**
     * 
     * @param {array} sings всички подписи 
     * @returns подписите разделени на две групи (лични - private и фирмени - public)
     */
    const getSigns = sings => {
        let structuredSigns = {
            private: [],
            public: []
        }

        sings.map(s => {
            if (s.is_private) structuredSigns.private.push(s)
            else structuredSigns.public.push(s)
        })

        return structuredSigns
    }

    /**
     * 
     * @param {array} signs всички подписи 
     * @returns избран подпис
     */
    const getDefaultSign = signs => signs.find(s => s.selected)


    /**
     * 
     * @param {node} target елемента, върху който е кликнато 
     * @returns дали реда е кликаем
     */
    const isRowNotClickable = target => {
        let clickable = true

        if (target) {
            if (
                target.classList?.contains('MuiButtonBase-root') ||
                target.classList?.contains('default') ||
                target.classList?.contains('MuiButton-label') ||
                (target.getAttribute('aria-hidden') === 'true') ||
                (document.querySelector('body').getAttribute('style') === 'overflow: hidden;')
            ) {
                clickable = false
            }
        }

        return clickable
    }

    return (
        <>
            <AddOrEdit
                ref={addOrEditModalRef}
            />
            <Question
                ref={deleteModalRef}
                mainMessage="Потвърждавам изтриването"
                secondaryMessage="Изтритите от Вас данни не могат да бъдат възстановени."
                agreeBtnText="Изтриване"
                agreeBtnClass="remove"
            />
            <div id="signs">
                {/* <div id="head">
                    <div
                        className="title"
                        style={{
                            minWidth: '200px'
                        }}
                    >
                        {state.data.length ?
                            <RedditSelect
                                name="vat"
                                value={getDefaultSign(state.data)?.id}
                                onChange={handleSelect}
                                label="Подпис по подразбиране"
                                key={state.data}
                            >
                                {state.data.map(sign =>
                                    <MenuItem
                                        value={sign.id}
                                        key={sign.id}
                                    >
                                        {sign.text}
                                    </MenuItem>
                                )}
                            </RedditSelect>
                            :
                            <>
                                Вашите подписи
                            </>
                        }
                    </div>
                </div> */}
                {!companyId
                    ?
                    <NoSelectedCompany />
                    :
                    state.loading
                        ?
                        <Skeleton count={5} height={60} />
                        :
                        state.data.length === 0
                            ?
                            <Alert severity="error">
                                Няма намерена информация!
                            </Alert>
                            :
                            <>
                                <ul className="list">
                                    {getSigns(state.data).public.map(sign =>
                                        <li
                                            key={`c-${sign.id}`}
                                            onClick={e => {
                                                if (!sign.allow_edit) return
                                                if (isRowNotClickable(e.target)) handleEdit(sign)
                                            }}

                                        >
                                            <div className="options">
                                                <Tooltip title={sign.default !== 1 ? 'Задаване на основен подпис' : ''}>
                                                    <img
                                                        src={checkIcon}
                                                        className={`default ${sign.default === 1 ? 'current' : ''}`}
                                                        onClick={() => {
                                                            handleChangeDefaultSign(sign.id)
                                                        }}
                                                    />
                                                </Tooltip>
                                                Основен подпис
                                            </div>
                                            <div dangerouslySetInnerHTML={createMarkup(sign.text)} />
                                            {sign.image ?
                                                <img src="https://danielstandard.com/img/home/top-right-shape.png" className="main" />
                                                :
                                                <>
                                                </>
                                            }
                                            {(sign.allow_delete || sign.allow_edit) ?
                                                <MenuOptions>
                                                    <Options
                                                        data={sign}
                                                        handleEdit={handleEdit}
                                                        handleDelete={handleDelete}
                                                    />
                                                </MenuOptions>
                                                :
                                                <>
                                                </>
                                            }
                                        </li>
                                    )}
                                </ul>
                            </>
                }
                <hr />
                <Tooltip title="Създаване на фирмен подпис">
                    <Button
                        className="add"
                        onClick={() => {
                            handleAdd('add')
                        }}
                    >
                        Фирмен
                    </Button>
                </Tooltip>
            </div>
        </>
    )
}

export default Index