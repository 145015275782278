import { useLanguageContext } from "providers/Language";
import { forwardRef, useImperativeHandle } from "react";
import { Form } from "react-bootstrap";

function Description(props, ref) {

    const langs = useLanguageContext();

    useImperativeHandle(ref, () => ({
        getName: getName,
        getType: getType,
        getTitle: getTitle,
        hasSettings: hasSettings,
        getSettings: getSettings,
        getDefaultOptions: () => ({ lang_id: 1 })
    }));

    const getName = () => {
        return 'Описание';
    }

    const getType = () => {
        return 'description';
    }

    const getTitle = () => {
        return null;
    }

    const hasSettings = () => true;

    const getSettings = ({ state, setConfig }) => {
        return (
            <div>
                <label>
                    Език:
                </label>
                <div>
                    <Form.Select
                        value={state.options?.lang_id || ''}
                        onChange={e => setConfig('lang_id', e.target.value)}
                    >
                        <option value="" disabled></option>
                        {langs.data.map(lang =>
                            <option key={lang.id} value={lang.id}>{lang.name}</option>
                        )}
                    </Form.Select>
                </div>
            </div>
        )
    }

    return getName();
}

export default forwardRef(Description)