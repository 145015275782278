//misc
import RedditTextField from 'components/misc/RedditTextField'

//images
import loaderImage from 'assets/img/loader-2.svg'
import checkIcon from 'assets/img/login/icons/check.png'

//hooks
import useCustomInputFieldHook from '../useCompanyNumberInputFieldHook'
import useCompanyDataHook from '../useCompanyDataHook'

//providers
import { useRegisterContext } from '../RegisterProvider'

function CustomerInput() {
    const register = useRegisterContext()
    const {
        handleInputChange
    } = useCustomInputFieldHook()

    const {
        isSelectedCompany,
    } = useCompanyDataHook()

    return (
        <div className="eik-input">
            {register.companyData.loading && register.companyData?.eik?.length >= 5 ?
                <img src={loaderImage} className="loader" />
                :
                <>
                </>
            }
            <RedditTextField
                margin="dense"
                size="small"
                fullWidth
                label="ЕИК / Булстат"
                name="eik"
                value={register.companyData.eik || ''}
                onChange={handleInputChange}
                autoFocus={true}
                type="number"
            />
            
            {isSelectedCompany() ?
                <img src={checkIcon} className="check" />
                :
                <>
                </>
            }
        </div>
    )
}

export default CustomerInput