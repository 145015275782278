import { forwardRef, useImperativeHandle, useState } from 'react'

//MUI components
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import Tooltip from '@material-ui/core/Tooltip'

//images
import settingsIcon from 'assets/img/app/icons/settings.png'

function DateOptions(props, ref) {
    const [anchorEl, setAnchorEl] = useState(null)

    useImperativeHandle(ref, () => ({
        show: (e) => {
            handleShow(e)
        },
        close: () => {
            handleClose();
        },
    }));

    const handleShow = e => {
        setAnchorEl(e.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            elevation={0}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            {props.children}
        </Menu>
    )
}

export default forwardRef(DateOptions);