//librairies
import moment from 'moment'
import { create } from 'zustand'

const INITIAL_FORM = {
    id: null,
    date: moment().format("YYYY-MM-DD"),
    creator: {
        id: null,
        name: "",
    },
    fileName: "",
    note: "",
}
export const useAddToMarketingArchiveModal = create((set, get) => ({
    show: false,
    form: { ...INITIAL_FORM },
    showModal: (show = true, initialForm = null) => set(state => ({
        show,
        form: {
            ...INITIAL_FORM,
            ...(initialForm || state.form)
        }
    })),
    setForm: (key, value) => set((state) => ({
        ...state,
        form: {
            ...state.form,
            [key]: value,
        }
    })),
    hideModal: () => set({ show: false, form: INITIAL_FORM }),
    getForm: () => get().form,
    getShown: () => get().show
}))