//helpers
import Api from "helpers/Api"

//providers
import { useAppContext } from "providers/App"
import { useDashboardDataContext } from "../providers/dashboard"

function useDocumentPayments({ makeUnpaidModalRef }) {
    const app = useAppContext()
    const dashboardData = useDashboardDataContext()
    const { refreshData } = dashboardData
    function refreshTransactions() {
        refreshData("TRANSACTIONS")
    }

    function handleMakeUnpaid(id) {
        const modal = makeUnpaidModalRef.current
        modal.open()
        modal.onSuccess(() => {
            _makeUnpaid(id)
        })
    }

    function _makeUnpaid(id) {
        Api.post("documents/make-unpaid", {
            id,
        })
            .then((res) => {
                appendDataFromPaymentReq(res.data)
                app.handleSuccess("Документа беше върнат за плащане успешно!")
            })
    }

    function appendDataFromPaymentReq(data) {
        const updatedDocuments = [...dashboardData.getDocuments()]

        let indexToReplace = 0
        updatedDocuments.map((ud, i) => {
            if (ud.id === data.id) {
                indexToReplace = i
            }
        })

        updatedDocuments[indexToReplace] = data

        dashboardData.setDocuments(updatedDocuments)
        refreshTransactions()
    }

    return {
        appendDataFromPaymentReq,
        handleMakeUnpaid
    }
}

export default useDocumentPayments