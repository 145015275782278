import React, { useEffect, useRef, useState } from 'react';
import { Box, Link } from '@material-ui/core';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import SaveButton from '../../../misc/Button';
import Api from '../../../../helpers/Api';
import SnackBar from '../../../misc/SnackBar';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import { useQuery } from '../../../../helpers/Url';
import { useAuthDataContext } from '../../../../providers/Auth';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: '#042731',
    },
    alert: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: '#042731',
        '&:hover': {
            backgroundColor: '#042731',
        }
    },
}));

function Index() {

    const classes = useStyles();
    const query = useQuery();
    const auth = useAuthDataContext();
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [validation, setValidation] = useState(false);
    const [msg, setMsg] = useState(false);

    const snackBarSuccessRef = useRef(null);
    const snackBarFailRef = useRef(null);

    const errorMessages = {
        'passwords.token': 'Този линк не е активен вече. Моля, изпратете нова заявка за промяна на паролата!',
        'passwords.user': 'Не успяхме да открием този потребител!',
    };

    const email = query.get('email');
    const token = query.get('token');

    useEffect(() => {
        if (!email || !token) {
            console.warn('Missing email and/or token');
            // history.replace('/');
        }
    });

    const handleSend = e => {
        e.preventDefault();

        setLoading(true);
        setValidation(false);

        let data = new FormData(e.target);

        data.append('email', email);
        data.append('token', token);

        Api.post('auth/password/reset', data)
            .then(res => {
                if (res.data.success || res.data.user) {
                    snackBarSuccessRef.current.show();

                    if (res.data.user) {
                        setTimeout(() => {
                            auth.login(res.data);
                            history.push('/');
                        }, 2000);
                    }
                } else {
                    if (res.data.status) {
                        let msg = errorMessages[res.data.status] || 'Нещо се обърка!';

                        setMsg(msg);
                        setValidation(true);
                    }
                }
            })
            .catch(err => {
                let _err = err.response;

                if (_err && _err.status && _err.status === 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {
                        setValidation(prev => {
                            return { ...prev, [input]: err }
                        });
                    }
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <section>
            <SnackBar ref={snackBarSuccessRef} severity="success">
                Паролата е променена!
            </SnackBar>

            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Забравена парола
                    </Typography>

                    <Alert className={classes.alert} severity="info">
                        Въведете нова парола
                    </Alert>

                    {validation === true &&
                        <Alert className={classes.alert} severity="error">
                            {msg}
                        </Alert>
                    }

                    <form className={classes.form} noValidate onSubmit={handleSend}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            type="password"
                            id="password"
                            label="Нова парола"
                            name="password"
                            autoComplete="password"
                            autoFocus
                            error={validation && validation.password}
                            helperText={validation && validation.password}
                        />

                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            type="password"
                            id="password_confirmation"
                            label="Повторете паролата"
                            name="password_confirmation"
                            error={validation && validation.password_confirmation}
                            helperText={validation && validation.password_confirmation}
                        />

                        <SaveButton
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            loading={loading}
                        >
                            Смяна на паролата
                        </SaveButton>


                    </form>
                </div>

            </Container>
        </section>
    )
}

export default Index;