//MUI components
import { Tooltip, Button } from '@material-ui/core'

//images
import brand from 'assets/img/app/brand-full.png'
import backIcon from 'assets/img/app/icons/back-arrow.png'
import yourInvoicesIcon from 'assets/img/login/icons/your-invoices.png'
import yourMailsIcon from 'assets/img/login/icons/your-mails.png'
import yourIncomesIcon from 'assets/img/login/icons/your-incomes.png'

//hooks
import useScreensHook from './useScreensHook'
import useCompanyDataHook from './useCompanyDataHook'
import useAuthDataHook from './useAuthDataHook'
import useFinalScreenHook from './useFinalScreenHook'

//partials
import CompanyData from './partials/CompanyData'
import AuthData from './partials/AuthData'
import Footer from './partials/Footer'
import FinalScreen from './FinalScreen'

//providers
import { useRegisterContext } from './RegisterProvider'
import { useAuthDataContext } from 'providers/Auth'

const SignUp = () => {
    const auth = useAuthDataContext()
    const register = useRegisterContext()
    const { currentScreen } = register.screens
    const { companyData } = register

    const {
        isCompanyDataFilled
    } = useCompanyDataHook()

    const {
        setScreens
    } = useScreensHook()

    const {
        isAuthDataFilled
    } = useAuthDataHook()

    const {
        showFinalScreen
    } = useFinalScreenHook()

    return (
        <section id="register">
            <div className={`wrapper ${auth.isLogged() ? 'finished' : ''}`}>
                {showFinalScreen ?
                    <FinalScreen />
                    :
                    <>
                        <div className="info-part">
                            {currentScreen === 1 ?
                                <div className="first-step">
                                    <h1>
                                        Създавай лесно
                                    </h1>
                                    <h2>
                                        всички документи
                                    </h2>
                                    <h3>
                                        с Docuela
                                    </h3>
                                    {/* <p>
                                        Lorem Ipsum е елементарен примерен текст, използван в печатарската и типографската индустрия. Lorem Ipsum е индустриален стандарт от около 1500 година
                                    </p> */}
                                    <ul>
                                        <li>
                                            <img src={yourInvoicesIcon} />
                                            <div className="right-part">
                                                <h4>
                                                    Фактури по Ваш стил
                                                </h4>
                                                <p>
                                                    Поставете Вашето лого, изберете шаблон, език и валута на документите Ви
                                                </p>
                                            </div>
                                        </li>
                                        <li>
                                            <img src={yourMailsIcon} />
                                            <div className="right-part">
                                                <h4>
                                                    Изпращане и споделяне на документи
                                                </h4>
                                                <p>
                                                    Изпращате всички документи по имейл, споделяте линкове и сваляте документа си в подходящ формат
                                                </p>
                                            </div>
                                        </li>
                                        <li>
                                            <img src={yourIncomesIcon} />
                                            <div className="right-part">
                                                <h4>
                                                    Справки на приходи и разходи
                                                </h4>
                                                <p>
                                                    Обобщени справки за приходи и разходи, по категории или клиенти като използвате зададен от Вас период
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                :
                                <div className="second-step">
                                    <h2>
                                        {companyData.data.name}
                                    </h2>
                                    <ul className="data-list">
                                        <li>
                                            <span>ЕИК:</span> <i>{companyData.data?.eik || companyData?.eik || ''}</i>
                                        </li>
                                        <li>
                                            <span>МОЛ:</span> <i>{companyData.data?.mol || ''}</i>
                                        </li>
                                        {companyData.data?.vatNumber ?
                                            <li>
                                                <span>ИН по ЗДДС:</span> <i>{companyData.data?.vatNumber || ''}</i>
                                            </li>
                                            :
                                            <>
                                            </>
                                        }
                                        <li>
                                            <span>Нас. място:</span> <i>{companyData.data?.settlement || ''}</i>
                                        </li>
                                        <li>
                                            <span>Адрес:</span> <i>{companyData.data?.address || ''}</i>
                                        </li>
                                    </ul>
                                    <ul className="more">
                                        <li>
                                            Управление на бизнес документация
                                        </li>
                                        <li>
                                            Шаблони на документите по Ваш вкус
                                        </li>
                                        <li>
                                            Статистика за финансовите дейности
                                        </li>
                                        <li>
                                            Плащания по удобни начини
                                        </li>
                                        <li>
                                            Управление на продукти/услуги
                                        </li>
                                        <li>
                                            Интеграции, нужни за Вашата дейност
                                        </li>
                                    </ul>
                                </div>
                            }
                        </div>
                        <div className="steps-form">
                            {/* {currentScreen !== 1 ?
                                <Button
                                    className="back"
                                    onClick={() => {
                                        setScreens(1)
                                    }}
                                >
                                    <img src={backIcon} />
                                    Назад
                                </Button>
                                :
                                <>
                                </>
                            } */}
                            <img src={brand} className="brand" />
                            <h2>
                                Данни на Вашата фирма
                            </h2>
                            <ul className="steps">
                                <Tooltip title="Данни за Вашата фирма">
                                    <li
                                        className={`${isCompanyDataFilled() ? 'finished' : ''} ${currentScreen === 1 ? 'current' : ''}`}
                                        onClick={() => {
                                            setScreens(1)
                                        }}
                                    >
                                    </li>
                                </Tooltip>
                                <Tooltip title="Данни за вход в системата">
                                    <li
                                        className={`${isAuthDataFilled() ? 'finished' : ''} ${currentScreen === 2 ? 'current' : ''}`}
                                        onClick={() => {
                                            setScreens(2)
                                            if (!isCompanyDataFilled()) document.querySelector('input[name="customerinfo[name]"]').focus()
                                        }}
                                    >
                                    </li>
                                </Tooltip>
                            </ul>
                            <ul className="screens">
                                {currentScreen === 1 ?
                                    <CompanyData />
                                    :
                                    <AuthData />
                                }
                            </ul>
                            <Footer />
                        </div>
                    </>
                }
            </div>
            <p className="footer">
                © Copyright Docuela 2022. All Rights Reserved.
            </p>
        </section>
    )
}

export default SignUp