import { useHistory } from "react-router-dom"

//libraries
import { Button } from "@material-ui/core"

//styles
import "assets/scss/pages/dashboard.scss"

//images
import documentIcon from "assets/img/dashboard/icons/document.png"
import ecoIcon from "assets/img/dashboard/icons/eco.png"

function NoDocuments() {
  const history = useHistory()

  function addInvoice() {
    history.push("/invoices", {
      data: {
        typeId: 3,
      },
      mode: "new",
    })
  }

  return (
    <section id="no-documents">
      <div className="welcome">
        <h1>Добре дошли!</h1>
        <p>
          Добре дошли в света на Докуела - твоят надежден партньор в
          електронното фактуриране и управление на платежните документи.
          Автоматизираното управление на документите е първата стъпка, която ще
          направи твоя бизнес по-лек и успешен.
        </p>
        <Button className="create-invoice new-btn-primary" onClick={addInvoice}>
          <img src={documentIcon} alt="" />
          Създай фактура
        </Button>
      </div>
      <div className="stamp">
        <img src={ecoIcon} alt="" />
        Опази природата.
        <br />
        Създай електронна фактура
      </div>
    </section>
  )
}

export default NoDocuments
