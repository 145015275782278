import { useImperativeHandle, forwardRef, useState } from "react"

//libraries
import { MenuItem, Button } from "@material-ui/core"

//misc
import RedditTextField from "components/misc/RedditTextField"

//hooks
import useFetchSuggestedNumbers from "../hooks/useFetchSuggestedNumbers"

//images
import checkIcon from "assets/img/app/icons/check-gray.png"

//libraries
import ClickAwayListener from "react-click-away-listener"

function DocumentNumber(
  { number, handleInputChange, companyId, companyAddressId, typeId },
  ref
) {
  const [showMenu, setShowMenu] = useState(false)

  const { getSuggestedNumbers, loadingSuggestedNumbers } =
    useFetchSuggestedNumbers({
      companyId,
      companyAddressId,
      typeId,
    })

  useImperativeHandle(ref, () => ({
    openMenu: (e) => {
      openMenu(e)
    },
  }))

  const closeMenu = () => {
    setShowMenu(false)
  }

  const openMenu = (e) => {
    setShowMenu(Boolean(e.target))
  }

  function getValue() {
    return String(number) || "0000000001"
  }

  return (
    <ClickAwayListener onClickAway={closeMenu}>
      <div className="no-row">
        <span className="no-label">№</span>
        <RedditTextField
          variant="standard"
          name="no"
          value={getValue().replace(/ /g, "")}
          onChange={handleInputChange}
          onFocus={openMenu}
          onBlur={() => {
            if (String(getValue()).length && Number(getValue())) {
              handleInputChange({
                target: {
                  name: "no",
                  value: String(getValue()).padStart(10, 0),
                },
              })
            }
          }}
        />
        {showMenu ? (
          <div className="numbers-dropdown">
            <h3>Предложени номера:</h3>
            <ul>
              {getSuggestedNumbers().map((n) => (
                <MenuItem
                  key={n.id}
                  onClick={() => {
                    handleInputChange({
                      target: {
                        name: "no",
                        value: String(n.next_number).padStart(10, 0),
                      },
                    })
                    closeMenu()
                  }}
                >
                  {n.next_number}
                  {n.next_number === getValue() ? (
                    <img src={checkIcon} alt="" />
                  ) : (
                    <></>
                  )}
                </MenuItem>
              ))}
            </ul>
            <div className="bottom">
              <Button
                style={{
                  left: 0,
                  top: 0,
                  position: "relative",
                  paddingLeft: "38px",
                  paddingRight: "15px",
                  width: "fit-content",
                  margin: "0 auto",
                }}
                className="add"
                onClick={() => {
                  handleInputChange({
                    target: {
                      name: "no",
                      value: " ",
                    },
                  })
                  document.querySelector('input[name="no"]').focus()
                  closeMenu()
                }}
              >
                Нов
              </Button>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </ClickAwayListener>
  )
}

export default forwardRef(DocumentNumber)
