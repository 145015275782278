import { Grid, TextField } from "@material-ui/core";
import { useEffect } from "react";
import Autocomplete from "../../../../misc/Autocomplete";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import moment from "moment";
import { setCompany } from '../../../../../requests/User';
import { useAuthDataContext } from "../../../../../providers/Auth";

function Filter(props) {

    const auth = useAuthDataContext();

    const handleCompanyChange = data => {
        if (data && data.id) {
            props.handleSearch('company_id', data.id, 0);

            setCompany(data.id)
                .then(user => {
                    auth.setData(user);
                });

        } else {
            props.handleSearch('company_id', '', 0);
        }
    }

    const handleDateChange = (date, name) => {
        props.handleSearch(name, moment(date).format('YYYY-MM-DD'));
    }

    const handleSearch = e => {
        let name = e.target.name;
        let value = e.target.value;

        props.handleSearch(name, value);
    }

    return (
        <Grid container spacing={1}>

            <Grid item xs={2}>
                <TextField
                    variant="standard"
                    size="small"
                    margin="normal"
                    fullWidth
                    type="search"
                    label="Търсене"
                    name="search"
                    value={props.filter.search || ''}
                    onChange={handleSearch}
                />
            </Grid>


            {/* <Grid item xs={2} style={{ marginLeft: 'auto' }}>
                <Autocomplete
                    freeSolo={false}
                    disableClearable
                    size="small"
                    variant="standard"
                    inputPlaceholder="Фирма (трябва да бъде select)"
                    inputIdName="company_id"
                    url="companies/all"
                    selectedId={props.filter.company_id || null}
                    getOptionLabel={option => option?.info?.name || option?.name || ''}
                    renderOption={option => option?.info?.name || option?.name || ''}
                    onChange={handleCompanyChange}
                    onInputChange={handleCompanyChange}
                />
            </Grid> */}
        </Grid>
    )
}

export default Filter;