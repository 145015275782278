import { forwardRef, useImperativeHandle } from "react";
import { Form } from "react-bootstrap";

function Supplier(props, ref) {

    useImperativeHandle(ref, () => ({
        getName: getName,
        getType: getType,
        getTitle: getTitle,
        hasSettings: hasSettings,
        getSettings: getSettings,
        getDefaultOptions: ({ createOrUpdate }) => {
            if (createOrUpdate === 'create') {
                return { action: 'create' };
            } else if (createOrUpdate === 'update') {
                return { action: 'ignore' };
            }
        }
    }));

    const getName = () => {
        return 'Доставчик';
    }

    const getType = () => {
        return 'supplier';
    }

    const getTitle = () => {
        return null;
    }

    const hasSettings = () => true;

    const getSettings = ({ state, setConfig }) => {

        return (
            <div>
                <label>
                    При липсващ доставчик:
                </label>
                <div>
                    <Form.Check
                        id={'cb-create'}
                        type="radio"
                        label="Създай го"
                        checked={state.options?.action === 'create'}
                        onChange={e => setConfig('action', 'create')}
                    />
                    <Form.Check
                        id={'cb_ignore'}
                        type="radio"
                        label="Игнорирай го"
                        checked={state.options?.action === 'ignore'}
                        onChange={e => setConfig('action', 'ignore')}
                    />
                    <Form.Check
                        id={'cb-abort'}
                        type="radio"
                        label="Прекрати импорта"
                        checked={state.options?.action === 'abort'}
                        onChange={e => setConfig('action', 'abort')}
                    />
                </div>
            </div>
        )
    }

    return getName();
}

export default forwardRef(Supplier)