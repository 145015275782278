import { useState, useImperativeHandle, forwardRef, useEffect, useRef } from 'react'
import { Modal, Button, Row, Col, FloatingLabel, Form, Table, ProgressBar, Alert } from 'react-bootstrap';
import { createPortal } from 'react-dom';

import { buildUrl } from 'helpers/Url';
import { useAuthDataContext } from 'providers/Auth';
import { useAppContext } from 'providers/App';
import Details from './Details';
import { baseUrl } from 'helpers/mode';

function Result(props, ref) {

    const auth = useAuthDataContext();
    const app = useAppContext();

    const defaultData = {
        import_id: null,
        logs: [],
    }

    const [state, setState] = useState({
        show: false,
        params: null,
        data: defaultData,
        onSuccess: null,
        onClose: null,
        onEntering: null,
        onExiting: null,
    });

    const detailsModalRef = useRef();

    useImperativeHandle(ref, () => ({
        open: (importId) => {
            handleShow(importId);
        },
        close: (cb) => {
            hide(cb);
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        },
        onCancel: fn => {
            setState(prev => ({
                ...prev,
                onCancel: fn
            }));
        },
    }));

    useEffect(() => {
        if (!state.show) {
            return;
        }

        // при тест с laravel dev server
        // заявката блокира всички останали, тъй като
        // уеб сървъра може да обработва само 1 заявка
        let base = baseUrl;
        // let base = 'http://localhost:8001';

        let url = buildUrl(base + 'store/imports/progress', {
            id: state.data.import_id,
            token: auth.getToken()
        });

        const eventSource = new EventSource(url);

        eventSource.addEventListener('ping', event => {
            let data = JSON.parse(event.data);

            // console.log(data);

            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    is_test: data.is_test,
                    ready: data.ready,
                    success: data.success,
                    rows_count: data.rows_count,
                    ready_count: data.ready_count,
                    percent: data.percent || 0,
                    logs: data.logs || [],
                    time: data.time || 0,
                    remaining_time: data.remaining_time || 0,
                },
            }));

            if (typeof props.onChunk === 'function') {
                props.onChunk({
                    is_test: data.is_test,
                    ready: data.ready,
                    success: data.success,
                    results: data.results || [],
                    type_id: data.type_id,
                });
            }
        });

        eventSource.onerror = err => {
            app.showError('Връзката със сървъра беше прекъсната!');
        }

        return () => {
            eventSource.close();
        }

    }, [state.show, state.data.import_id]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = (importId) => {
        setState(prev => ({
            ...prev,
            data: {
                ...defaultData,
                import_id: importId,
            }
        }));

        show();
    }

    const handleClose = () => {
        if (state.data.success && !state.data.is_test) {
            props.onSuccess();
        }

        hide(state.onClose);
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: value
            }
        }))
    }

    const getTime = time => {
        let min = Math.floor(time / 60);
        let sec = Math.ceil(time % 60);

        return min.toString().padStart(2, 0) + ':' + sec.toString().padStart(2, 0);
    }

    const getMessages = () => {
        return state.data.logs.filter(c => c.type_id === 1);
    }

    const getErrors = () => {
        return state.data.logs.filter(c => c.type_id === 2);
    }

    const handleShowDetails = () => {
        let modal = detailsModalRef.current;

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open(state.data.import_id);
        });

        modal.onClose(() => {
            show();
        });
    }

    return (
        <>
            <Details
                ref={detailsModalRef}
            />

            {createPortal(
                <Modal size="md" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Резултат от импорта {state.data.is_test ? '(тест)' : ''}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <ProgressBar className="mb-3" now={state.data.percent} label={`${state.data.percent}%`} /> */}

                        <div className="progress mb-3" style={{ position: 'relative', height: '40px' }}>
                            <div className="progress-bar" style={{ width: state.data.percent + '%' }}>
                                {/* {state.data.percent}% */}
                            </div>
                            <label
                                style={{
                                    position: 'absolute',
                                    left: '50%',
                                    top: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    color: '#000'
                                }}
                            >
                                {state.data.percent}%
                            </label>
                        </div>

                        <Table bordered>
                            <tbody>
                                <tr>
                                    <td style={{ width: '50%' }}>
                                        Общ брой редове
                                    </td>
                                    <td>
                                        <b>{state.data.rows_count || 0} </b> бр.
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: '50%' }}>
                                        Обработени редове
                                    </td>
                                    <td>
                                        <b>{state.data.ready_count || 0} </b> бр.
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: '50%' }}>
                                        Общо време
                                    </td>
                                    <td>
                                        <b>{getTime(state.data.time)}</b> сек.
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: '50%' }}>
                                        Оставащо време
                                    </td>
                                    <td>
                                        <b>{getTime(state.data.remaining_time)}</b> сек.
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: '50%' }}>
                                        Статус
                                    </td>
                                    <td>
                                        <b>
                                            {state.data.ready
                                                ?
                                                state.data.success ?
                                                    'Успешен'
                                                    :
                                                    'Неуспешен'
                                                : 'Обработва се'
                                            }
                                        </b>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>

                        {getMessages().length > 0 &&
                            <div className="mt-3">
                                <p className="mb-1">
                                    Съобщения: <b>{getMessages().length}</b> бр.
                                </p>
                                <Alert variant="success" style={{ margin: 0 }}>
                                    {getMessages().map(c =>
                                        <p key={c.id} style={{ margin: 0 }}>
                                            {c.text}
                                        </p>
                                    )}
                                </Alert>
                            </div>
                        }

                        {getErrors().length > 0 &&
                            <div className="mt-3">
                                <p className="mb-1">
                                    Обработени грешки: <b>{getErrors().length}</b> бр.
                                </p>
                                <Alert variant="danger" style={{ margin: 0 }}>
                                    {getErrors().map(c =>
                                        <p key={c.id} style={{ margin: 0 }}>
                                            {c.text}
                                        </p>
                                    )}
                                </Alert>
                            </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleShowDetails}>
                            Детайли
                        </Button>
                        <Button variant="secondary" onClick={handleClose}>
                            Затвори
                        </Button>
                    </Modal.Footer>
                </Modal>,
                document.body
            )}

        </>
    )
}

export default forwardRef(Result);