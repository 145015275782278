import { useState, useEffect } from "react"

//components
import SaveButton from "components/misc/Button"
import RedditSelect from "components/misc/RedditSelect"
import RedditTextField from "components/misc/RedditTextField"
import DatePicker from "components/misc/DatePicker"
import TextField from "components/misc/TextField"
import PriceValue from "components/partials/PriceValue"
import Autocomplete from "components/misc/Autocomplete"
import FindCompany from "components/pages/sandbox/findcompany/components/FindCompany"

//libraries
import { Button, InputAdornment, MenuItem } from "@material-ui/core"
import { useQueryClient } from "react-query"

//constants
import { COMPLAINT_STATUSES } from "constants/Complaints"

//hooks
import useFetchClientStore from "../hooks/useFetchClientStore"
import useSaveOnServer from "hooks/useSaveOnServer"

//providers
import { useAuthDataContext } from "providers/Auth"

//helpers
import { useValidation } from "helpers/Validation"
import { useNestedState } from "helpers/NestedState"
import { calcDiscount, convertFromRateToRate } from "helpers/Price"

//refs
import Refs from "Refs"

//stores
import { useAddToComplaintsModal } from "stores/customer/useAddToComplaintsModal"
import moment from "moment"

function AddOrEditComplaint({ allComplaints, clientId }, ref) {
  const queryClient = useQueryClient()

  const { getUser } = useAuthDataContext()

  const [validations, setValidations] = useValidation()

  const { getClient, loadingClient } = useFetchClientStore(clientId)

  const { getShown, getForm, hideModal } = useAddToComplaintsModal()

  const [state, setState] = useNestedState({})

  const { saveOnServer, loadingSaveOnServer } = useSaveOnServer()

  useEffect(() => {
    setState(() => ({
      form: getForm().form,
      data: getForm().data,
    }))
  }, [getForm()])

  const handleClose = (e) => {
    if (e) {
      e.preventDefault()
    }

    setState(getForm())
    hideModal()
  }

  function handleChange(e) {
    const { name, value } = e.target

    setState((prev) => ({
      ...prev,
      form: {
        ...prev.form,
        [name]: value,
      },
    }))
  }

  const getRowTotalPrice = (row) => {
    return parseFloat(row.current_price || 0) * parseFloat(row.amount || 0)
  }

  const getRowTotalDiscount = (row) => {
    return calcDiscount(getRowTotalPrice(row), parseFloat(row.discount || 0))
  }

  const getRowTotalRealPrice = (row) => {
    return getRowTotalPrice(row) + getRowTotalDiscount(row)
  }

  const getRowPriceRatio = (row) => {
    return row.price_ratio || ""
  }

  const getRowsWithArticle = () => {
    return state?.data?.rows.filter((r) => r.article_id)
  }

  const getSelectedCount = () => {
    // само редовете с избран артикул да се четат
    let rows = getRowsWithArticle()

    return rows.length
  }

  const getTotalSum = () => {
    let total = 0

    state?.data?.rows.map((row) => {
      total += getRowTotalRealPrice(row)
    })

    return total
  }

  const getTotalDiscount = () => {
    return calcDiscount(getTotalSum(), state?.data?.discount)
  }

  const getTotalReal = () => {
    return getTotalSum() + getTotalDiscount()
  }

  const isDiscount = () => {
    return parseFloat(state?.data?.discount || 0) <= 0
  }

  const handleInputChange = (e) => {
    const name = e.target.name
    const value = e.target.value

    setState(name, value, "data")
  }

  const handleRemoveRow = (e, index) => {
    e.preventDefault()

    setState((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        rows: prev.data.rows.filter((row, i) => i !== index),
      },
    }))
  }

  const handleAddNote = () => {
    setState((prev) => ({
      ...prev,
      addNote: !prev.addNote,
    }))
  }

  const handleRowAmountChange = (e, index) => {
    let value = e.target.value

    setState((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        rows: Object.values({
          ...prev.data.rows,
          [index]: {
            ...prev.data.rows[index],
            amount: value,
          },
        }),
      },
    }))
  }

  const handleChangeArticleInput = (name, index) => {
    setState((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        rows: Object.values({
          ...prev.data.rows,
          [index]: {
            ...prev.data.rows[index],
            article: {
              article_name: name,
            },
            article_id: null,
            attrs: [], // изчистваме атрибутитие
          },
        }),
      },
    }))
  }

  const handleRowCurrentPriceChange = (e, index) => {
    let value = e.target.value

    setState((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        rows: Object.values({
          ...prev.data.rows,
          [index]: {
            ...prev.data.rows[index],
            current_price: value,
            price_ratio: calculatePriceRatio(
              value,
              prev.data.rows[index].actual_price
            ),
          },
        }),
      },
    }))
  }

  const calculatePriceRatio = (from, to) => {
    return (parseFloat(from || 0) / parseFloat(to || 0) - 1) * 100
  }

  const isCurrentPriceDisabled = (row) => {
    if (getUser().permission("accounts_edit_current_price") === false) {
      return true
    }

    if (
      row.hasPrice === false &&
      getUser().permission("set_empty_price_article_in_account") === false
    ) {
      return true
    }

    return false
  }

  const handleChangeArticle = (data, index) => {
    let row = state?.data?.rows[index]

    let currency = data?.currency

    data.currency = state?.data?.currency

    let currentPrice = convertFromRateToRate(
      data.current_price || data.actual_price,
      currency?.rate,
      state?.data?.currency_rate
    )
    let actualPrice = convertFromRateToRate(
      data.actual_price,
      currency?.rate,
      state?.data?.currency_rate
    )
    let avgDeliveryPrice = convertFromRateToRate(
      data.avg_delivery_price,
      currency?.rate,
      state?.data?.currency_rate
    )
    let avgDeliveryPriceWithVat = convertFromRateToRate(
      data.avg_delivery_price_with_vat,
      currency?.rate,
      state?.data?.currency_rate
    )
    let currentDeliveryPrice = convertFromRateToRate(
      data.current_delivery_price,
      currency?.rate,
      state?.data?.currency_rate
    )
    let currentDeliveryPriceWithVat = convertFromRateToRate(
      data.current_delivery_price_with_vat,
      currency?.rate,
      state?.data?.currency_rate
    )
    let priceRatio = calculatePriceRatio(currentPrice, actualPrice)

    let hasAttrs = data.attrs && data.attrs.length > 0

    setState((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        rows: Object.values({
          ...prev.data.rows,
          [index]: {
            ...prev.data.rows[index],
            article: data,
            article_id: data.id,
            amount: data.amount_unit || 1,
            current_price: currentPrice,
            actual_price: actualPrice,
            avg_delivery_price: avgDeliveryPrice,
            avg_delivery_price_with_vat: avgDeliveryPriceWithVat,
            current_delivery_price: currentDeliveryPrice,
            current_delivery_price_with_vat: currentDeliveryPriceWithVat,
            price_ratio: priceRatio,
            price_rules: data.price_rules || [],
            attrs: [], // изчистваме атрибутитие
            hasPrice: !(
              data.current_price === null && data.actual_price === null
            ),
          },
        }),
      },
    }))

    // ако има атрибути да покажем модала за редакцията им...
    // if (hasAttrs) {
    //   handleEditArticleAttributes(data, [], index)
    // }
  }

  const handleAddArticle = (index) => {
    let modal = Refs.getInstance().getRef("newArticle")

    if (modal) {
      modal.add()
      modal.onSuccess((article) => {
        if (article) {
          handleChangeArticle(article, index)
        }
      })
    }
  }

  const handleShowArticle = (id) => {
    let modal = Refs.getInstance().getRef("article")

    if (modal) {
      modal.open(id)
    }
  }

  const handleAddRow = (e) => {
    e.preventDefault()

    setState((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        rows: prev.data.rows.concat({
          isNew: true,
        }),
      },
    }))
  }

  function handleSaveToLocalStorage() {
    let updatedComplaints = [...allComplaints]

    if (state?.form?.id) {
      updatedComplaints = allComplaints.map((complaint) => {
        if (complaint.form.id === state?.form?.id) {
          complaint = {
            data: state?.data,
            form: state?.form,
          }
        }

        return complaint
      })
    } else {
      updatedComplaints.push({
        data: state?.data,
        form: {
          ...state?.form,
          id: moment().unix(),
        },
      })
    }

    saveOnServer("complaints", updatedComplaints, () => {
      handleClose()
      queryClient.invalidateQueries("complaints")
    })
  }

  function getSelectedStatusColor() {
    const selectedStatus = COMPLAINT_STATUSES.find(
      ({ value }) => value === state?.form?.statusId
    )

    return selectedStatus?.color || "#2c2c2c"
  }

  return (
    <div className={`${getShown() ? "visible" : ""} overlay`}>
      <div
        className={`popup-primary medium-large complex-modal storage-load-form-modal complaint-modal ${
          getShown() ? "show" : ""
        }`}
      >
        <div className="header sky-blue">
          <b> {state?.form?.id ? "Редакция" : "Добавяне"} на Рекламация</b>
          <p>Добавете вида рекламация и необходимите материали за ремонта</p>
        </div>
        <div className="body">
          <div className="row">
            <div className="col">
              <h6>Основни</h6>
              <div className="field">
                <div className="row">
                  <div className="col">
                    <div className="row">
                      <div className="col">
                        <span
                          style={{
                            backgroundColor: getSelectedStatusColor(),
                          }}
                          className="color-indicator"
                        ></span>
                        <RedditSelect
                          label="Статус"
                          value={state?.form?.statusId}
                          name="statusId"
                          onChange={handleChange}
                        >
                          {COMPLAINT_STATUSES.map(({ value, title }) => (
                            <MenuItem key={value} value={value}>
                              {title}
                            </MenuItem>
                          ))}
                        </RedditSelect>
                      </div>
                      <div className="col">
                        <DatePicker
                          reddit
                          fullWidth
                          label="Дата / Час"
                          disableToolbar
                          format="dd/MM/yyyy"
                          value={state?.data?.date_ymd}
                          name="date_ymd"
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col">
                        <RedditTextField
                          fullWidth
                          label="Поръчка №"
                          name="order_number"
                          value={state?.data?.order_number}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        {getClient() ? (
                          <RedditTextField
                            fullWidth
                            label="Клиент"
                            name="client"
                            readOnly={getClient()}
                            key={getClient()}
                            value={getClient()?.info?.name}
                            // onChange={handleChange}
                          />
                        ) : (
                          <FindCompany
                            // centeredLabel={true}
                            label="Клиент/Доставчик"
                            inputIdName="clientField"
                            setSelectedCompanyCB={(value) => {
                              setState("client", value, "form")
                            }}
                            initialValue={state?.form?.clientField}
                            setInputValueCB={(value) => {
                              setState("clientField", value, "form")
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <RedditTextField
                          fullWidth
                          label="Адрес"
                          name="address"
                          readOnly
                          value={
                            (state?.form?.client || getClient())?.address
                              ?.full_address || "-"
                          }
                          // onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <RedditTextField
                          fullWidth
                          label="Телефон"
                          name="phone"
                          value={state?.data?.phone}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col">
                        <RedditTextField
                          fullWidth
                          label="Е-поща"
                          name="email"
                          value={state?.data?.email}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="row multiline-holder">
                      <div className="col">
                        <RedditTextField
                          label="Описание"
                          multiline
                          rows={7}
                          name="description"
                          value={state?.data?.description}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <DatePicker
                          reddit
                          fullWidth
                          label="Дата / Час за поправка"
                          disableToolbar
                          format="dd/MM/yyyy"
                          value={state?.data?.term_date_ymd}
                          name="term_date_ymd"
                          // defaultValue={state?.data?.date_ymd || today}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col">
                        <RedditTextField
                          fullWidth
                          label="Техник за контакти"
                          name="contact_person"
                          value={state?.data?.contact_person}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h6>Изписване на материали за ремонта</h6>
              <div className="field">
                <table className="type-doc">
                  <thead>
                    <tr>
                      <th className="options"></th>
                      <th style={{ width: "40px" }}>№</th>
                      <th style={{ width: "15%" }}>Артикул</th>
                      <th>Количество</th>
                      <th>Единична цена</th>
                      <th>Тотал</th>
                      <th>Бележки</th>
                    </tr>
                  </thead>
                  <tbody>
                    {state?.data?.rows.map((row, index) => (
                      <tr
                        key={"r-" + index}
                        className={row.is_service ? "trqbva-da-e-disabled" : ""}
                      >
                        <td className="options">
                          <div style={{ display: "flex", gap: "5px" }}>
                            <Button
                              className="small remove"
                              onClick={(e) => handleRemoveRow(e, index)}
                            ></Button>
                            {/* {(row.price_rules || []).length > 0 &&
                                                                    <Button variant="primary" size="sm" onClick={e => handleShowPriceRules(row)}>
                                                                        <CurrencyDollar />
                                                                    </Button>
                                                                } */}
                          </div>
                        </td>
                        <td title={row.id || "-"} style={{ width: "40px" }}>
                          {index + 1}

                          <input
                            type="hidden"
                            name={`rows[${index}][id]`}
                            value={state?.data?.rows[index].id || ""}
                          />

                          {row.stornodata && (
                            <input
                              type="hidden"
                              name={`rows[${index}][stornodata][storno_of_account_row_id]`}
                              value={
                                row.stornodata.storno_of_account_row_id || ""
                              }
                            />
                          )}

                          <input
                            type="hidden"
                            name={`rows[${index}][article_id]`}
                            value={state?.data?.rows[index].article_id || ""}
                          />
                          <input
                            type="hidden"
                            name={`rows[${index}][depot_id]`}
                            value={state?.data?.rows[index].depot_id || ""}
                          />

                          {(row.price_rules || []).map((id) => (
                            <input
                              key={id}
                              type="hidden"
                              name={`rows[${index}][price_rules][]`}
                              value={id}
                            />
                          ))}
                        </td>
                        <td style={{ width: "15%" }}>
                          {/* {row.is_special ? ( */}
                          <>
                            <RedditTextField
                              margin="none"
                              size="small"
                              variant="outlined"
                              type="text"
                              fullWidth
                              value={
                                state?.data?.rows[index] &&
                                state?.data?.rows[index]?.article?.article_name
                                  ? state?.data?.rows[index]?.article
                                      ?.article_name
                                  : ""
                              }
                              inputName={`rows[${index}][article_name]`}
                              onChange={(e) => {
                                handleChangeArticleInput(e.target.value, index)
                              }}
                              error={Boolean(
                                validations &&
                                  validations.rows &&
                                  validations.rows[index] &&
                                  validations.rows[index].article_id &&
                                  (validations.rows[index].article_id[0] ||
                                    validations.rows[index].article_id)
                              )}
                              helperText={
                                validations &&
                                validations.rows &&
                                validations.rows[index] &&
                                validations.rows[index].article_id &&
                                (validations.rows[index].article_id[0] ||
                                  validations.rows[index].article_id)
                              }
                              className="quantity-input input-with-right-label"
                            />
                          </>
                          {/*  ) : (
                                <>
                                  <Autocomplete
                                    margin="none"
                                    size="small"
                                    variant="outlined"
                                    url="store/accounts/articles"
                                    params={{
                                      client_id: state?.data?.client_id,
                                    }}
                                    // inputPlaceholder="Име"
                                    inputName="article_name"
                                    inputIdName={`rows[${index}][article_id]`}
                                    inputValue={
                                      state?.data?.rows[index] &&
                                      state?.data?.rows[index].article_name
                                        ? state?.data?.rows[index].article_name
                                        : ""
                                    }
                                    inputIdValue={
                                      state?.data?.rows[index] &&
                                      state?.data?.rows[index].article_id
                                        ? state?.data?.rows[index].article_id
                                        : ""
                                    }
                                    onChange={(data) =>
                                      handleChangeArticle(data, index)
                                    }
                                    onInputChange={(data) =>
                                      handleChangeArticleInput(data, index)
                                    }
                                    onClickAddButton={(e) =>
                                      handleAddArticle(index)
                                    }
                                    onClickShowButton={handleShowArticle}
                                    error={Boolean(
                                      validations &&
                                        validations.rows &&
                                        validations.rows[index] &&
                                        validations.rows[index].article_id &&
                                        (validations.rows[index]
                                          .article_id[0] ||
                                          validations.rows[index].article_id)
                                    )}
                                    helperText={
                                      validations &&
                                      validations.rows &&
                                      validations.rows[index] &&
                                      validations.rows[index].article_id &&
                                      (validations.rows[index].article_id[0] ||
                                        validations.rows[index].article_id)
                                    }
                                    renderText={(data) => {
                                      return (
                                        <div style={{ display: "flex" }}>
                                          <div style={{ marginRight: "10px" }}>
                                            {data.id}
                                          </div>
                                          <div
                                            style={{
                                              width: "150px",
                                              marginRight: "10px",
                                            }}
                                          >
                                            {data.article_name}
                                          </div>
                                          <div
                                            style={{
                                              width: "150px",
                                              marginRight: "10px",
                                            }}
                                          >
                                            {data.ref_num}
                                          </div>
                                          <div
                                            style={{
                                              width: "100px",
                                              marginRight: "20px",
                                              textAlign: "right",
                                            }}
                                          >
                                            {data.store_quantity || 0}{" "}
                                            {data.amount_type_short}
                                          </div>
                                          <div>
                                            <PriceValue
                                              prefix={data.currency?.prefix}
                                              sufix={data.currency?.sufix}
                                            >
                                              {data.current_price}
                                            </PriceValue>
                                          </div>
                                        </div>
                                      )
                                    }}
                                    renderInputText={(data) =>
                                      data.article_name
                                    }
                                    preventInitialLoad={true}
                                    autoFocus={row.isNew ? true : false}
                                    addButtonDisabled={
                                      getUser().permission("articles_edit") ===
                                      false
                                    }
                                    listStyle={{
                                      minWidth: "max-content",
                                    }}
                                    className="quantity-input input-with-right-label"
                                  />
                                </>
                              )}
*/}
                          {row.attrs && row.attrs.length > 0 && (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                {row.attrs.map((attr) => (
                                  <div key={attr.id}>
                                    /{attr.name}: {attr.value}/
                                    <input
                                      type="hidden"
                                      name={`rows[${index}][attrs][${attr.id}]`}
                                      value={attr.value || ""}
                                    />
                                  </div>
                                ))}
                              </div>
                              {/* <PencilSquare
                                onClick={(e) =>
                                  handleEditArticleAttributes(
                                    row.article,
                                    row.attrs,
                                    index
                                  )
                                }
                                style={{
                                  fontSize: "14px",
                                  marginLeft: "10px",
                                  marginTop: "3px",
                                  cursor: "pointer",
                                }}
                              /> */}
                            </div>
                          )}

                          {/* {row.stornodata &&
                                                                <>
                                                                    <span style={{ fontSize: '12px' }}>
                                                                        Сторно на сметка №{row.stornodata.storno_of_account_row_id}
                                                                    </span>

                                                                    {!row.id &&
                                                                        <span
                                                                            onClick={e => showSelectStorno(row, index)}
                                                                        >
                                                                            промени
                                                                        </span>
                                                                    }
                                                                </>
                                                            } */}
                        </td>

                        <td>
                          <TextField
                            margin="none"
                            size="small"
                            variant="outlined"
                            fullWidth
                            type="number"
                            min="0"
                            step="0.001"
                            onChange={(e) => handleRowAmountChange(e, index)}
                            // onBlur={(e) => handleRowAmountBlur(row, index)}
                            value={
                              state?.data?.rows[index] &&
                              state?.data?.rows[index].amount
                                ? state?.data?.rows[index].amount
                                : ""
                            }
                            name={`rows[${index}][amount]`}
                            readOnly={
                              getUser().permission(
                                "accounts_allow_quantity_change"
                              ) === false
                            }
                            error={Boolean(
                              validations &&
                                validations.rows &&
                                validations.rows[index] &&
                                validations.rows[index].amount &&
                                (validations.rows[index].amount[0] ||
                                  validations.rows[index].amount)
                            )}
                            helperText={
                              validations &&
                              validations.rows &&
                              validations.rows[index] &&
                              validations.rows[index].amount &&
                              (validations.rows[index].amount[0] ||
                                validations.rows[index].amount)
                            }
                            InputProps={
                              state?.data?.rows[index]?.article
                                ?.amount_type_short
                                ? {
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {state?.data?.rows[index]?.article
                                          ?.amount_type_short || "-"}
                                      </InputAdornment>
                                    ),
                                  }
                                : {}
                            }
                            className="quantity-input input-with-right-label"
                          />
                        </td>
                        <td>
                          <TextField
                            margin="none"
                            size="small"
                            variant="outlined"
                            fullWidth
                            type="number"
                            min="0"
                            step="0.001"
                            onChange={(e) =>
                              handleRowCurrentPriceChange(e, index)
                            }
                            value={state?.data?.rows[index].current_price || ""}
                            name={`rows[${index}][current_price]`}
                            readOnly={row.is_special ? true : false}
                            disabled={isCurrentPriceDisabled(row)}
                            error={Boolean(
                              validations &&
                                validations.rows &&
                                validations.rows[index] &&
                                validations.rows[index].current_price &&
                                (validations.rows[index].current_price[0] ||
                                  validations.rows[index].current_price)
                            )}
                            helperText={
                              validations &&
                              validations.rows &&
                              validations.rows[index] &&
                              validations.rows[index].current_price &&
                              (validations.rows[index].current_price[0] ||
                                validations.rows[index].current_price)
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {state?.data?.currency.prefix ||
                                    state?.data?.currency.sufix ||
                                    "-"}
                                  /
                                  {state?.data?.rows[index]?.article
                                    ?.amount_type_short || "-"}
                                </InputAdornment>
                              ),
                            }}
                            className="quantity-input input-with-right-label"
                          />
                        </td>
                        <td>
                          <TextField
                            margin="none"
                            size="small"
                            variant="outlined"
                            fullWidth
                            type="number"
                            min="0"
                            step="0.001"
                            value={getRowTotalRealPrice(row)}
                            disabled
                            InputProps={
                              state?.data?.currency
                                ? {
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {state?.data?.currency.prefix ||
                                          state?.data?.currency.sufix ||
                                          "-"}
                                      </InputAdornment>
                                    ),
                                  }
                                : {}
                            }
                            className="quantity-input input-with-right-label"
                          />
                        </td>
                        <td>
                          <TextField
                            margin="none"
                            size="small"
                            variant="outlined"
                            fullWidth
                            type="text"
                            value={row.description || ""}
                            name={`rows[${index}][description]`}
                            onChange={handleInputChange}
                            className="quantity-input input-with-right-label"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <hr />

                <div className="row">
                  <div
                    className="col"
                    style={{
                      paddingLeft: "10px",
                    }}
                  >
                    <Button
                      className="small add"
                      onClick={handleAddRow}
                      style={{
                        marginBottom: "10px",
                      }}
                    ></Button>

                    {state?.addNote ? (
                      <RedditTextField
                        size="small"
                        variant="filled"
                        margin="dense"
                        type="text"
                        fullWidth
                        label="Забележка"
                        name="description"
                        value={state?.data?.description || ""}
                        onChange={handleInputChange}
                        error={Boolean(validations?.description)}
                        helperText={
                          validations?.description &&
                          (validations.description[0] ||
                            validations.description)
                        }
                        multiline
                        rows={3}
                        autoFocus={true}
                      />
                    ) : (
                      <input hidden value={null} name="description" />
                    )}
                    <span
                      className="link"
                      style={{
                        width: "fit-content",
                        marginTop: "10px",
                      }}
                      onClick={handleAddNote}
                    >
                      {state?.addNote ? "Премахване" : "Добавяне"} на забележка
                    </span>
                  </div>
                  <div className="col">
                    <table
                      style={{
                        width: "300px",
                        marginLeft: "auto",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td className="text-right">Стойност</td>
                          <td
                            className="bold text-right"
                            style={{
                              maxWidth: "150px",
                            }}
                          >
                            {`${getTotalSum().toFixed(2)} ${
                              state?.data?.currency.prefix ||
                              state?.data?.currency.sufix ||
                              "-"
                            }`}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-right">
                            {isDiscount() ? "Отстъпка" : "Надценка"} (
                            {state?.data?.discount}%)
                          </td>
                          <td
                            className="bold text-right"
                            style={{
                              maxWidth: "150px",
                            }}
                          >
                            {`${getTotalDiscount().toFixed(2)} ${
                              state?.data?.currency.prefix ||
                              state?.data?.currency.sufix ||
                              "-"
                            }`}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-right">Сума за плащане</td>
                          <td
                            className="bold text-right"
                            style={{
                              maxWidth: "150px",
                            }}
                          >
                            {`${getTotalReal().toFixed(2)} ${
                              state?.data?.currency.prefix ||
                              state?.data?.currency.sufix ||
                              "-"
                            }`}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer">
          <Button className="cancel" onClick={handleClose}>
            Отказ
          </Button>
          <SaveButton
            loading={loadingSaveOnServer}
            className="save"
            // onClick={(e) => formRef.current?.save(e)}
            onClick={handleSaveToLocalStorage}
          />
        </div>
      </div>
    </div>
  )
}

export default AddOrEditComplaint
