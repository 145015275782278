import { Fragment, useEffect, useRef, useState } from 'react'
import { useLocation, useHistory, Link, Redirect } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'

//MUI components
import Alert from '@material-ui/lab/Alert'
import { Button, Tooltip } from '@material-ui/core'

//helpers
import Api from 'helpers/Api'
import { useQuery } from 'helpers/Url'

//misc
import MenuOptions from 'components/misc/MenuOptions'
import Pagination from 'components/misc/Pagination'
import NoSelectedCompany from 'components/misc/NoSelectedCompany'

//modals
import Question from 'components/modals/Question'

//providers
import { useAuthDataContext } from 'providers/Auth'

//partials
import Filter from './partials/Filter'
import AddOrEdit from './partials/AddOrEdit'
import Options from './partials/Options'
import moment from 'moment'

let timeout

function Index() {
    const location = useLocation()
    const history = useHistory()
    const query = useQuery()
    const auth = useAuthDataContext()

    const [state, setState] = useState({
        loading: true,
        refresh: false,
        setFilter: false,
        data: [],
        pages: 0,
        total: 0,
        filter: {
            page: 1,
            company_id: query.get('company_id') || auth?.getUser()?.getCompany()?.id,
        },
    })

    const addOrEditModalRef = useRef(null)
    const deleteModalRef = useRef(null)

    useEffect(() => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: query.get('page') || 1,
                start: query.get('start') || '',
                end: query.get('end') || '',
                company_id: query.get('company_id') || prev.filter.company_id || '',
            },
            refresh: moment().unix()
        }))
    }, [location.search])

    useEffect(() => {
        if (state.setFilter) {
            Object.entries(state.filter).map(filter => {
                // if (filter[1]) {
                query.set(filter[0], filter[1])
                // }
            })

            history.push('?' + query.toString())
        }

    }, [state.setFilter])

    useEffect(() => {
        if (state.refresh) loadData()
    }, [state.refresh])

    const loadData = () => {
        if (!state.filter.company_id) return

        setLoading(true)

        let url = 'expenses/categories/all?resource=1'

        Object.entries(state.filter).map(filter => {
            url += (url.indexOf('?') > -1 ? '&' : '?') + filter[0] + '=' + filter[1]
        })

        Api.get(url)
            .then(res => {
                const { items, total, pages } = res.data

                setState(prev => ({
                    ...prev,
                    data: items,
                    total,
                    pages
                }))
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const setLoading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: reset ? 1 : prev.filter.page
            },
            refresh: moment().unix()
        }))
    }

    const handleAdd = (parent = null) => {
        const modal = addOrEditModalRef.current

        modal.open(parent)

        modal.onSuccess(() => {
            refresh(true)
        })
    }

    // update
    const handleEdit = data => {
        const modal = addOrEditModalRef.current

        modal.edit(data)
        modal.onSuccess(() => {
            refresh(false)
        })
    }

    // delete
    const handleDelete = id => {
        const modal = deleteModalRef.current

        modal.open()
        modal.onSuccess(() => {
            handleDestroy(id)
        })
    }

    const handleDestroy = id => {
        setLoading(true)
        Api.post('expenses/categories/delete', {
            id
        })
            .then(res => {
                if (res.data.success) refresh()
            })
    }

    // Search
    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout)

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },
        }))

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                setFilter: moment().unix()
            }))
        }, delay)
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            setFilter: moment().unix()
        }))
    }

    if (!auth.getUser().permission("expenses:read")) {
        return <Redirect to="/settings/numbers" />
    }

    return (
        <>
            <AddOrEdit
                ref={addOrEditModalRef}
                companyId={state.filter.company_id}
            />

            <Question
                ref={deleteModalRef}
                mainMessage="Потвърждавам изтриването"
                secondaryMessage="Изтритите от Вас данни не могат да бъдат възстановени."
                agreeBtnText="Изтриване"
                agreeBtnClass="remove"
            />

            <section id="expenses">
                <div id="head">
                    <div className="title">
                        Категории разходи
                    </div>
                    <Button
                        className="add"
                        onClick={() => {
                            handleAdd()
                        }}
                    >
                        Добави
                    </Button>
                </div>
                <Filter
                    filter={state.filter}
                    handleSearch={handleSearch}
                />
                <hr />

                {!state.filter.company_id ?
                    <NoSelectedCompany />
                    :
                    state.loading ?
                        <Skeleton count={5} height={60} />
                        :
                        state.data.length === 0 ?
                            <Alert severity="error">
                                Няма намерена информация!
                            </Alert>
                            :
                            <>
                                <ul className="categories">
                                    {state.data.map(c =>
                                        <li
                                            key={c.id}
                                        >
                                            <div
                                                className="row align-center"
                                                onClick={e => {
                                                    const { classList } = e.target
                                                    if (classList.contains('MuiButton-label') || classList.contains('custom-menu') || classList.contains('add') || classList.contains('MuiButtonBase-root') || e.target.hasAttribute('aria-hidden')) return
                                                    handleEdit(c)
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        backgroundColor: c.color
                                                    }}
                                                    className="color"
                                                >
                                                </div>
                                                <b>
                                                    {c.name} <span>({c.children.length || '0'} подкатегор{c.children.length === 1 ? 'я' : 'ии'})</span>
                                                </b>

                                                <div className="options">
                                                    <Tooltip title="Добавяне на подкатегория">
                                                        <Button
                                                            className="add small"
                                                            onClick={() => {
                                                                handleAdd(c.id)
                                                            }}
                                                            style={{ marginLeft: '10px' }}
                                                        >
                                                        </Button>
                                                    </Tooltip>
                                                    <MenuOptions>
                                                        <Options
                                                            data={c}
                                                            handleEdit={handleEdit}
                                                            handleDelete={handleDelete}
                                                        />
                                                    </MenuOptions>
                                                </div>
                                            </div>
                                            <ul>
                                                {c.children.map(sc =>
                                                    <li
                                                        key={`${c.id}-${sc.id}`}
                                                        onClick={e => {
                                                            const { classList } = e.target
                                                            if (classList.contains('MuiButton-label') || classList.contains('custom-menu') || classList.contains('add') || classList.contains('MuiButtonBase-root') || e.target.hasAttribute('aria-hidden')) return
                                                            handleEdit(sc)
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                backgroundColor: sc.color
                                                            }}
                                                            className="color"
                                                        >
                                                        </div>
                                                        <b>
                                                            {sc.name}
                                                        </b>
                                                        <div className="options">
                                                            <MenuOptions>
                                                                <Options
                                                                    data={sc}
                                                                    handleEdit={handleEdit}
                                                                    handleDelete={handleDelete}
                                                                />
                                                            </MenuOptions>
                                                        </div>
                                                    </li>
                                                )}
                                            </ul>
                                        </li>
                                    )}
                                </ul>

                                <Pagination
                                    page={state.filter.page}
                                    pages={state.pages}
                                    handlePage={handlePage}
                                />
                            </>
                }
            </section>
        </>
    )
}

export default Index