import { forwardRef, useImperativeHandle } from "react";
import { Form } from "react-bootstrap";

function MinQuantity(props, ref) {

    useImperativeHandle(ref, () => ({
        getName: getName,
        getType: getType,
        getTitle: getTitle,
        hasSettings: hasSettings,
        getSettings: getSettings,
        getDefaultOptions: ({ createOrUpdate }) => ({})
    }));

    const getName = () => {
        return 'Мин. складово кол.';
    }

    const getType = () => {
        return 'min_quantity';
    }

    const getTitle = () => {
        return null;
    }

    const hasSettings = () => false;

    const getSettings = ({ state, setConfig, createOrUpdate }) => {
        return null;
    }

    return getName();
}

export default forwardRef(MinQuantity)