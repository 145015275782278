import { forwardRef, useEffect, useImperativeHandle, useRef, useState, Fragment } from 'react'

//plugins
import { Button, Checkbox, FormControlLabel } from '@material-ui/core'

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useLanguageContext } from 'providers/Language'

//misc
import SaveButton from 'components/misc/Button'
import RedditTextField from 'components/misc/RedditTextField'
import moment from 'moment'


function AddOrEdit(props, ref) {

    const langs = useLanguageContext()

    const defaultData = {

    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        edit: false,
        data: defaultData,
        tabId: langs.data[0]?.id,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null
    })

    const [validations, setValidations] = useValidation()

    const validationModalRef = useRef(null)
    const formRef = useRef(null)

    useImperativeHandle(ref, () => ({
        add: () => {
            handleShow()
        },
        edit: (id) => {
            edit(id)
        },
        hide: () => {
            hide()
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        }
    }))

    useEffect(() => {
        loadData()
    }, [state.edit])

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }))

        onEntering?.()
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }))

        onExiting?.()
    }

    const handleShow = () => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
            },
            id: null,
        }))

        show()
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault()
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }))

        setValidations(null)

        hide()
    }

    const edit = id => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: moment().unix()
        }))

        show()
    }

    const loadData = () => {
        if (!state.id) {
            return
        }

        Api.get('store/storeouts/deals/show?id=' + state.id)
            .then(res => {

                let data = res.data

                setState(prev => ({
                    ...prev,
                    data: data
                }))
            })
    }


    const handleSave = () => {
        if (state.loading) {
            return
        }

        setState(prev => ({
            ...prev,
            loading: true,
        }))

        setValidations(null)

        let url = state.id ? 'store/storeouts/deals/edit' : 'store/storeouts/deals/add'

        let data = new FormData(formRef.current)

        if (state.id) {
            data.append('id', state.id)
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
            }

            handleClose()
        }).catch(error => {
            const _err = error.response

            if (_err && _err.status && _err.status === 422) {
                setValidations(_err.data.errors)
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }))
        })
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleCheckboxChange = e => {
        const name = e.target.name

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }))
    }

    const handleDateChange = e => {
        const name = e.target.name + '_ymd'
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleTabChange = id => {
        setState(prev => ({
            ...prev,
            tabId: Number(id)
        }))
    }


    return (
        <>

            <div className={`${state.show ? "visible" : ""} overlay`}>
                <div
                    className={`popup-primary medium ${state.show ? "show" : ""}`}
                >
                    <div className="body">
                        <h3>
                            Причина изписване {state.data?.id && '(ID: ' + state.data.id + ')'}
                        </h3>
                        <br />
                        <form onSubmit={handleSave} ref={formRef}>
                            <div className="row">
                                <div className="col">
                                    <RedditTextField
                                        size="small"
                                        variant="filled"
                                        margin="dense"
                                        label="Име"
                                        name="name"
                                        value={state.data.name || ''}
                                        onChange={handleInputChange}
                                        error={Boolean(validations && validations.name)}
                                        helperText={validations && validations.name && (validations.name[0] || validations.name)}
                                    />
                                </div>

                            </div>

                        </form>
                    </div>
                    <div className="footer">
                        <Button className="cancel" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            onClick={handleSave}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default forwardRef(AddOrEdit)
