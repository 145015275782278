import { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react'
import Api from 'helpers/Api'
import { buildUrl, useQuery } from 'helpers/Url'
import { useLocation, useHistory, Link, useParams } from 'react-router-dom'
import { Table, Form, Row, Col, Container, InputGroup } from 'react-bootstrap'
import { ArrowLeftCircle, FileText, Plus, PlusCircle, TrashFill, Upload, XCircle } from 'react-bootstrap-icons'
import { useNestedState } from 'helpers/NestedState'
import { useCurrencyContext } from 'providers/Currency'
import { useValidation } from 'helpers/Validation'
import { useAuthDataContext } from 'providers/Auth'
import { useAppContext } from 'providers/App'
import { cloneDeep } from 'lodash'
import { Button, MenuItem, InputAdornment, Menu } from '@material-ui/core'

import Question from 'components/modals/Question'
import Layout from 'components/Layout'
import Supplier from 'components/pages/suppliers/partials/AddOrEdit'
//images
import dropArrowIcon from 'assets/img/app/icons/drop-arrow-white.png'

//misc
import Loader from 'components/misc/Loader'
import Alert from 'components/misc/Alert'
import moment from 'moment'
import Autocomplete from 'components/misc/Dropdown'
import SaveButton from 'components/misc/Button'
import { addExtraPrice, calcExtraPrice, isNumeric, negativeValue, positiveValue, removeExtraPrice, vatPrice } from 'helpers/Price'
import Info from 'components/misc/Info'
import Error from 'components/modals/Error'
import DatePicker from 'components/partials/filter/DatePicker'
import Import from 'components/modals/Import'
import { TYPES } from 'constants/imports'
import Intrastat from './Intrastat'
import Expenses from './Expenses'
import PriceValue from 'components/partials/PriceValue'
import Refs from 'Refs'
import NoDataFound from 'components/misc/NoDataFound'
import Pagination from 'components/misc/Pagination'
import RedditTextField from 'components/misc/RedditTextField'
import RedditSelect from 'components/misc/RedditSelect'

let timeout

function AddOrEdit({ }, ref) {
    const location = useLocation()
    const query = useQuery()

    const app = useAppContext()
    const auth = useAuthDataContext()
    const currencies = useCurrencyContext()

    const INITIAL_STATE = {
        loading: false,
        loadingBase: false,
        loadingExit: false,
        loadingClose: false,
        dataLoading: false,
        rowsLoading: false,
        data: {
            has_tax: 1,
            with_tax: 0,
            currency_id: currencies.getDefault()?.id || '',
            currency_rate: currencies.getDefault()?.rate || '',
            currency: currencies.getDefault(),
            allow_close: true,
        },
        rows: {
            rows: [{}],
            original: [{}],
            pages: 0,
            total: 0,
            filter: {
                page: 1,
            },
            onDataLoaded: null,
            refresh: false,
            setFilter: false,
        },
        saveButtonMenu: null,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
        onClose: null,
        addNote: false,
        show: false
    }

    const [state, setState] = useNestedState(INITIAL_STATE)

    const [operations, setOperations] = useState([])
    const [depots, setDepots] = useState([])
    const [documentTypes, setDocumentTypes] = useState([])
    const [paymentMethods, setPaymentMethods] = useState([])
    const [taxGroups, setTaxGroups] = useState([])

    const [validations, setValidations] = useValidation()

    const formRef = useRef(null)
    const supplierModalRef = useRef(null)
    const saveAndCloseModalRef = useRef(null)
    const errorModalRef = useRef(null)
    const importModalRef = useRef(null)
    const intrastatModalRef = useRef(null)
    const expensesModalRef = useRef(null)

    useImperativeHandle(ref, () => ({
        add: () => {
            add()
        },
        edit: (id) => {
            edit(id)
        },
        hide: () => {
            hide()
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }))
        }
    }))

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }))
    }

    const hide = () => {
        setState(() => ({ ...INITIAL_STATE }))
    }

    const add = () => {
        show()
    }

    const edit = (id) => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: moment().unix()
        }))

        show()
    }

    useEffect(() => {
        Api.get('store/storeloads/operations')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setOperations(res.data)
                }
            })

        Api.get('store/storeloads/documents-types')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setDocumentTypes(res.data)
                }
            })

        Api.get('store/storeloads/payment-methods')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setPaymentMethods(res.data)
                }
            })

        Api.get('store/storeloads/tax-groups')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setTaxGroups(res.data)
                }
            })
    }, [])

    useEffect(() => {
        Api.get('store/storeloads/depots')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setDepots(res.data)
                }
            })
    }, [auth.getUser().getStoreId()])

    useEffect(() => {
        if (state.id) {
            loadFullData()
        }
    }, [state.id, query.get('_')])

    // зареждане на данни от поръчка/сметка
    useEffect(() => {
        if (state.id) {
            return
        }

        // от зареждане
        if (query.get('from_load_id') && query.get('return')) {
            loadDataFromStoreLoad()
        }

        // от документ
        if (query.get('from_document_id')) {
            loadDataFromDocument()
        }
    }, [location])

    useEffect(() => {
        if (!state.data.id && state.rows.rows.length === 0) {
            setState(prev => ({
                ...prev,
                rows: {
                    ...prev.rows,
                    rows: [{}]
                }
            }))
        }
    }, [state.rows.rows])

    // useEffect(() => {
    //     setState(prev => ({
    //         ...prev,
    //         rows: {
    //             ...prev.rows,
    //             filter: {
    //                 ...prev.rows.filter,
    //                 page: query.get('page') || 1,
    //                 search: query.get('search') || '',
    //             },
    //             refresh: moment().unix()
    //         },
    //     }))
    // }, [location.search]);

    useEffect(() => {
        if (state.rows.refresh) {

            if (state.rowsLoading) {
                return
            }

            setState(prev => ({
                ...prev,
                rowsLoading: true
            }))

            loadRows().then(() => {
                setState(prev => ({
                    ...prev,
                    rowsLoading: false
                }))
            })
        }
    }, [state.rows.refresh])

    const loadFullData = () => {
        loading(true)

        let p1 = loadData()
        let p2 = loadRows()

        Promise.all([p1, p2]).then(() => {
            loading(false)
        })
    }

    const loadData = async () => {
        let url = 'store/storeloads/show'

        return Api.get(url, {
            params: {
                id: state.id
            }
        }).then(res => {
            setDataResponse(res.data)
        }).catch(err => {
            handleError(err)
        })
    }

    const loadRows = async () => {
        let url = 'store/storeloads/rows'

        return Api.get(url, {
            params: {
                ...state.rows.filter,
                id: state.id,
            }
        }).then(res => {
            setRowsResponse(res.data)
        }).catch(err => {
            handleError(err)
        })
    }

    const loadDataFromStoreLoad = () => {

        loading(true)

        let url = 'store/storeloads/from-storeload'

        Api.get(url, {
            params: {
                load_id: query.get('from_load_id'),
                return: query.get('return'),
            }
        }).then(res => {
            handleResponse(res)
        }).catch(err => {
            handleError(err)
        }).finally(() => {
            loading(false)
        })
    }

    const loadDataFromDocument = () => {

        loading(true)

        let url = 'store/storeloads/from-document'

        Api.get(url, {
            params: {
                document_id: query.get('from_document_id')
            }
        }).then(res => {
            handleResponse(res)
        }).catch(err => {
            handleError(err)
        }).finally(() => {
            loading(false)
        })
    }

    const handleResponse = res => {
        let data = res.data

        // понякога има редове, а понякога няма...
        let rows = null

        if (data.rows) {
            rows = data.rows.map(row => {
                row.current_price_gross = row.current_price_with_vat
                row.latest_current_price = row?.article?.current_delivery_price
                row.latest_current_price_gross = calcGrossPrice(row?.article?.current_delivery_price, row.delivery_tax_id)

                let currentPrice = parseFloat(row.current_price_gross || 0)

                if (Number(data.with_tax) === 1 || Number(data.has_tax) === 0) {
                    currentPrice = parseFloat(row.current_price || 0)
                }

                row.profit_perc = calcExtraPrice(currentPrice, row.actual_price)

                return row
            })
        }

        setState(prev => ({
            ...prev,
            data: data,
            rows: {
                ...prev.rows,
                rows: rows === null ? prev.rows.rows : rows,
            },
            addNote: !!data.description
        }))
    }

    const handleError = error => {
        console.log(error)

        const _err = error.response

        if (_err && _err.status) {
            if (_err.status === 422) {
                setValidations(_err.data.errors)
            } else {
                let modal = errorModalRef.current

                if (modal) {
                    modal.open(_err.data.error || _err.data.message)

                    modal.onClose(() => {
                        handleClose()
                    })
                }
            }
        }
    }

    const setDataResponse = data => {
        setState(prev => ({
            ...prev,
            data: data,
        }))
    }

    const setRowsResponse = data => {
        let rows = data.items

        rows = rows.map(row => {
            //

            return row
        })

        if (typeof state.rows.onDataLoaded === 'function') {
            state.rows.onDataLoaded(data)
        }

        setState(prev => ({
            ...prev,
            rows: {
                ...prev.rows,
                rows: rows,
                original: cloneDeep(rows),
                pages: data.pages,
                page: data.page,
                total: data.total,
                onDataLoaded: null,
            }
        }))
    }

    const isDirty = () => {
        return JSON.stringify(state.rows.rows) !== JSON.stringify(state.rows.original)
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            dataLoading: Boolean(loading)
        }))
    }

    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout)

        if (typeof key === 'object') {
            setState(prev => ({
                ...prev,
                rows: {
                    ...prev.rows,
                    filter: {
                        ...prev.rows.filter,
                        ...key
                    },
                }
            }))
        } else {
            setState(prev => ({
                ...prev,
                rows: {
                    ...prev.rows,
                    filter: {
                        ...prev.rows.filter,
                        [key]: val,
                    },
                }
            }))
        }

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                rows: {
                    ...prev.rows,
                    filter: {
                        ...prev.rows.filter,
                        page: 1
                    },
                    refresh: moment().unix(),
                    setFilter: true,
                }
            }))
        }, delay)
    }

    const handlePage = (page, onDataLoaded = null) => {
        let modal = Refs.getInstance().getRef('question')

        if (isDirty()) {
            modal.open('Имате незапазени промени. При смяна на страницата ще бъдат загубени. Желаете ли да продължите?')

            modal.onSuccess(() => {
                changePage(page, onDataLoaded)
            })
        } else {
            changePage(page, onDataLoaded)
        }
    }

    const changePage = (page, onDataLoaded = null) => {
        setState(prev => ({
            ...prev,
            rows: {
                ...prev.rows,
                filter: {
                    ...prev.rows.filter,
                    page: page.selected + 1,
                },
                onDataLoaded: onDataLoaded,
                refresh: moment().unix(),
                setFilter: true,
            }
        }))
    }

    const isLoad = () => {
        return Number(state.data.operation_id) === 1
    }

    const isReturn = () => {
        return Number(state.data.operation_id) === 2
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleRowsInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'rows')
    }


    const handleCheckboxChange = e => {
        const name = e.target.name

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }))
    }

    const handleCurrencyChange = e => {
        const name = e.target.name
        const value = e.target.value

        setCurrency(value)
    }

    const setCurrency = id => {
        let currency = currencies.data.find(c => Number(c.id) === Number(id))

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                currency_id: id,
                currency: currency,
                currency_rate: currency ? currency.rate : prev.data.currency_rate,
            }
        }))
    }

    const save = (close = false, exit = false, onSuccess = null) => {
        if (state.loading) {
            return
        }

        setValidations(null)

        let url = state.id ? 'store/storeloads/edit' : 'store/storeloads/add'

        let form = formRef.current

        let data = new FormData(form)

        if (state.id) {
            data.append('id', state.id)
        }

        if (close) {
            data.append('close', 1)
        }

        if (query.get('from_load_id')) {
            data.append('from_load_id', query.get('from_load_id'))
            data.append('return', query.get('return'))
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (!exit) {
                handleResponse(res)
            }

            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
            }

            handleClose()
        }).catch(error => {
            const _err = error.response

            if (_err && _err.status) {
                if (_err.status === 422) {
                    setValidations(_err.data.errors)
                } else {
                    let modal = errorModalRef.current

                    if (modal) {
                        modal.open(_err.data.error || _err.data.message)
                    }
                }
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
                loadingBase: false,
                loadingExit: false,
                loadingClose: false,
            }))
        })
    }

    const handleSave = e => {
        setState(prev => ({
            ...prev,
            loading: true,
            loadingBase: true,
            loadingExit: false,
            loadingClose: false,
        }))

        save(false, false, () => {
            app.showSuccess('Действието е успешно!')
        })
    }

    const handleSaveAndExit = e => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            loading: true,
            loadingBase: false,
            loadingExit: true,
            loadingClose: false,
        }))

        save(false, true, () => {
            app.showSuccess('Действието е успешно!')
        })
    }

    const handleSaveAndClose = e => {
        e.preventDefault()

        let modal = saveAndCloseModalRef.current

        if (modal) {
            modal.open()

            modal.onSuccess(() => {
                setState(prev => ({
                    ...prev,
                    loading: true,
                    loadingBase: false,
                    loadingExit: false,
                    loadingClose: true,
                }))

                save(true, false, () => {
                    app.showSuccess('Действието е успешно!')
                })
            })
        }
    }

    const handleChangeSupplier = data => {

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                supplier: data,
                supplier_id: data.id,
                with_tax: Number(data.default_price_mode) === 1 ? 1 : 0
            }
        }))

        if (data.default_currency_id) {
            setCurrency(data.default_currency_id)
        }
    }

    const handleAddSupplier = () => {
        let modal = supplierModalRef.current

        if (modal) {
            modal.add()
            modal.onSuccess(supplier => {
                if (supplier) {
                    handleChangeSupplier(supplier)
                }
            })
        }
    }

    const handleAddRow = e => {
        e.preventDefault()

        if (state.rows.page && state.rows.page !== state.rows.pages) {
            handlePage({ selected: state.rows.pages - 1 }, data => {
                setTimeout(() => {
                    addRow()
                }, 0)
            })
        } else {
            addRow()
        }
    }

    const addRow = () => {
        setState(prev => ({
            ...prev,
            rows: {
                ...prev.rows,
                rows: prev.rows.rows.concat({
                    isNew: true,
                }),
                // total: prev.rows.total + 1,
            }
        }))
    }

    const handleRemoveRow = (e, index, row) => {
        e.preventDefault()

        let modal = Refs.getInstance().getRef('question')

        if (!modal) {
            return
        }

        modal.open('Сигурни ли сте, че искате да премахнете този ред?')

        modal.onSuccess(() => {
            if (row.id) {
                removeRowFromDb(row, index)
            } else {
                removeRow(row, index)
            }

        })
    }

    const removeRowFromDb = (row, index) => {
        Api.post('store/storeloads/delete-row', {
            id: state.data.id,
            row_id: row.id,
        }).then(res => {
            if (res.data.success) {
                loadRows()
            }
        }).catch(err => {
            //
        })
    }

    const removeRow = (row, index) => {
        setState(prev => ({
            ...prev,
            rows: {
                ...prev.rows,
                rows: prev.rows.rows.filter((row, i) => i !== index),
                // total: prev.rows.total - 1,
            }
        }))
    }

    const handleRemoveRows = e => {
        e.preventDefault()

        let modal = Refs.getInstance().getRef('question')

        modal.open('Да се премахнат ли всички редове?')

        modal.onSuccess(() => {
            if (state.data.id) {
                removeRowsFromDb()
            } else {
                removeRows()
            }
        })
    }

    const removeRowsFromDb = () => {
        Api.post('store/storeloads/delete-rows', {
            id: state.data.id,
        }).then(res => {
            if (res.data.success) {
                loadRows()
            }
        }).catch(err => {
            //
        })
    }

    const removeRows = () => {
        setState(prev => ({
            ...prev,
            rows: {
                ...prev.rows,
                rows: []
            }
        }))
    }

    const handleChangeArticle = (data, index) => {

        let row = state.rows.rows[index]

        let actualPrice = data.actual_price || row.actual_price || 0
        let currentPrice = row.current_price || data.current_price || 0

        // let currentGrossPrice = calcGrossPrice(currentPrice, data.tax_id);

        // if (Number(state.data.with_tax) === 1 || Number(state.data.has_tax) === 0) {
        //     return parseFloat(row.current_price_gross || 0);
        // }

        // let profitPerc = calcExtraPrice(currentGrossPrice, actualPrice);

        let latestCurrentPrice = data.current_delivery_price
        let latestCurrentGrossPrice = data.current_delivery_price_with_vat

        setState(prev => ({
            ...prev,
            rows: {
                ...prev.rows,
                rows: Object.values({
                    ...prev.rows.rows,
                    [index]: {
                        ...prev.rows.rows[index],
                        article: data,
                        article_id: data.id,
                        delivery_tax_id: data.tax_id || prev.rows.rows[index].delivery_tax_id,
                        latest_current_price: latestCurrentPrice,
                        latest_current_price_gross: latestCurrentGrossPrice,
                        // current_price: currentPrice,
                        // current_price_gross: currentGrossPrice,
                        actual_price: actualPrice,
                        // profit_perc: profitPerc,
                        // quantity: 1
                    }
                })
            }
        }))

    }

    const handleAddArticle = (index) => {
        let modal = Refs.getInstance().getRef('newArticle')

        if (modal) {
            modal.add()
            modal.onSuccess(article => {

                // console.log(article);

                if (article) {
                    handleChangeArticle(article, index)
                }
            })
        }
    }

    const handleShowArticle = id => {
        let modal = Refs.getInstance().getRef('article')

        if (modal) {
            modal.open(id)
        }
    }

    // при смяна на количеството 
    const handleQtyChange = (e, index) => {
        let name = e.target.name
        let value = e.target.value

        if (isReturn()) {
            if (value) {
                value = negativeValue(value)
            }
        } else {
            if (value) {
                value = positiveValue(value)
            }
        }

        setState(name, value, 'rows')

        setCurrentPrice(index, value, state.rows.rows[index].delivery_total)
    }

    // при смяна на доставната цена
    const handleDeliveryTotalChange = (e, index) => {
        handleRowsInputChange(e)

        let value = e.target.value

        setCurrentPrice(index, state.rows.rows[index].quantity, value)
    }

    // при смяна на ддс
    const handleDeliveryTaxChange = (e, index) => {
        handleRowsInputChange(e)

        setCurrentPrice(index, state.rows.rows[index].quantity, state.rows.rows[index].delivery_total)
    }

    // при смяна на единичната цена
    const handleCurrentPriceChange = (e, index) => {
        handleRowsInputChange(e)

        let value = e.target.value

        setDeliveryTotal(index, value, state.rows.rows[index].quantity)
        setCurrentGrossPrice(index, value)
    }

    const setDeliveryTotal = (index, currentPrice, qty) => {
        if (!isNumeric(currentPrice)) {
            currentPrice = 0
        }

        if (!isNumeric(qty)) {
            qty = 0
        }

        let deliveryTotal = currentPrice * qty

        setState(prev => ({
            ...prev,
            rows: {
                ...prev.rows,
                rows: Object.values({
                    ...prev.rows.rows,
                    [index]: {
                        ...prev.rows.rows[index],
                        delivery_total: deliveryTotal.toFixed(4),
                    }
                })
            }
        }))
    }

    const setCurrentPrice = (index, qty, deliveryTotal) => {

        if (!isNumeric(qty)) {
            qty = 0
        }

        if (!isNumeric(deliveryTotal)) {
            deliveryTotal = 0
        }

        let current = Number(deliveryTotal) / Number(qty)

        setState(prev => ({
            ...prev,
            rows: {
                ...prev.rows,
                rows: Object.values({
                    ...prev.rows.rows,
                    [index]: {
                        ...prev.rows.rows[index],
                        current_price: current.toFixed(4),
                    }
                })
            }
        }))

        setCurrentGrossPrice(index, current)

        return current
    }

    const setCurrentGrossPrice = (index, currentPrice, withTax = false) => {

        let row = state.rows.rows[index]

        let gross = currentPrice

        if (withTax === false) {
            gross = calcGrossPrice(currentPrice, row.delivery_tax_id)
        }

        setState(prev => ({
            ...prev,
            rows: {
                ...prev.rows,
                rows: Object.values({
                    ...prev.rows.rows,
                    [index]: {
                        ...prev.rows.rows[index],
                        current_price_gross: gross,
                    }
                })
            }
        }))

        let current = gross

        if (Number(state.data.with_tax) === 1 || Number(state.data.has_tax) === 0) {
            current = currentPrice
        }

        setProfitPerc(index, current, state.rows.rows[index].actual_price || gross)
    }

    const calcGrossPrice = (price, taxId) => {

        if (!isNumeric(price)) {
            price = 0
        }

        let tax = getTaxById(taxId)

        let gross = addExtraPrice(price, tax?.value)

        return gross.toFixed(4)
    }

    const getTaxById = id => {
        return taxGroups.find(g => Number(g.id) === Number(id))
    }

    // при смяна на надценката
    const handleProfitPercChange = (e, index) => {
        handleRowsInputChange(e)

        let value = e.target.value

        setActualPrice(index, getCurrentPrice(index), value)
    }

    const setActualPrice = (index, currentPrice, profit) => {

        let actual = addExtraPrice(currentPrice, profit)

        setState(prev => ({
            ...prev,
            rows: {
                ...prev.rows,
                rows: Object.values({
                    ...prev.rows.rows,
                    [index]: {
                        ...prev.rows.rows[index],
                        actual_price: actual.toFixed(4),
                    }
                })
            }
        }))
    }

    // при смяна на продажната цена
    const handleActualPriceChange = (e, index) => {
        handleRowsInputChange(e)

        let value = e.target.value

        setProfitPerc(index, getCurrentPrice(index), value)
    }

    const setProfitPerc = (index, current, actual) => {

        let percent = calcExtraPrice(current, actual)

        setState(prev => ({
            ...prev,
            rows: {
                ...prev.rows,
                rows: Object.values({
                    ...prev.rows.rows,
                    [index]: {
                        ...prev.rows.rows[index],
                        profit_perc: percent,
                    }
                })
            }
        }))
    }

    // при смяна на Без ДДС / С ДДС
    useEffect(() => {
        let rows = cloneDeep(state.rows.rows)

        rows = rows.map((row, index) => {

            let currentPrice = getCurrentPrice(index)
            let profitPerc = calcExtraPrice(currentPrice, row.actual_price)

            row.profit_perc = profitPerc

            return row
        })

        setState(prev => ({
            ...prev,
            rows: {
                ...prev.rows,
                rows: rows
            }
        }))
    }, [state.data.with_tax, state.data.has_tax])

    // при сямана на Регистрация по ДДС
    // useEffect(() => {
    //     let hasTax = Number(state.data.has_tax);

    //     console.log(hasTax);

    //     if (hasTax === 0) {
    //         setState(prev => ({
    //             ...prev,
    //             data: {
    //                 ...prev.data,
    //                 with_tax: 1
    //             }
    //         }));
    //     }
    // }, [state.data.has_tax]);

    const getTotalQty = () => {
        let total = 0

        state.rows.rows.map(row => {
            total += parseFloat(row.quantity || 0)
        })

        return total || 0
    }

    const getTotalDelivery = () => {
        let total = 0

        state.rows.rows.map(row => {
            total += parseFloat(row.current_price || 0) * parseFloat(positiveValue(row.quantity) || 0)
        })

        return total || 0
    }

    const getTotalNet = () => {
        let total = 0

        let hasTax = Number(state.data.has_tax) === 1
        let withTax = Number(state.data.with_tax) === 1

        state.rows.rows.map(row => {
            let delivery = parseFloat(row.current_price || 0) * parseFloat(positiveValue(row.quantity) || 0)
            let tax = getTaxById(row.delivery_tax_id)

            if (!hasTax) {
                total += delivery
            } else {
                if (withTax) {
                    total += removeExtraPrice(delivery, tax?.value)
                } else {
                    total += delivery
                }
            }
        })

        return total || 0
    }

    const getTotalVat = () => {
        let total = 0

        let hasTax = Number(state.data.has_tax) === 1
        let withTax = Number(state.data.with_tax) === 1

        if (!hasTax) {
            return 0
        }

        state.rows.rows.map(row => {
            let delivery = parseFloat(row.current_price || 0) * parseFloat(positiveValue(row.quantity) || 0)
            let tax = getTaxById(row.delivery_tax_id)

            if (withTax) {
                total += delivery - removeExtraPrice(delivery, tax?.value)
            } else {
                total += vatPrice(delivery, tax?.value)
            }
        })

        return total || 0
    }

    const getTotalGross = () => {
        return getTotalNet() + getTotalVat()
    }

    const getLatestCurrentPrice = index => {
        let row = state.rows?.rows[index]

        if (!row) {
            return 0
        }

        if (Number(state.data.with_tax) === 1 || Number(state.data.has_tax) === 0) {
            return parseFloat(row.latest_current_price_gross)
        }

        return parseFloat(row.latest_current_price || 0)
    }

    const getCurrentPrice = index => {

        let row = state.rows?.rows[index]

        if (!row) {
            return 0
        }

        if (Number(state.data.with_tax) === 1 || Number(state.data.has_tax) === 0) {
            return parseFloat(row.current_price || 0)
        }

        return parseFloat(row.current_price_gross || 0)
    }

    const getCurrentPriceVal = index => {
        let row = state.rows?.rows[index]

        if (!row) {
            return 0
        }

        if (Number(state.data.with_tax) === 1 || Number(state.data.has_tax) === 0) {
            return parseFloat(row.current_price_gross || 0)
        }

        return parseFloat(row.current_price || 0)
    }

    const handleImport = () => {
        let modal = importModalRef.current

        if (!modal) {
            return
        }

        modal.open()
        // modal.setParams(state.data);

        modal.onChunk(({ is_test, type_id, results }) => {
            // console.log(data);

            // setState(prev => ({
            //     ...prev,
            //     data: {
            //         ...prev.data,
            //         rows: results
            //     }
            // }))

            // замяна или добавяне
            let erase = Number(type_id) === 1

            let rows

            if (erase) {
                rows = []
            } else {
                rows = cloneDeep(state.rows.rows)
            }

            // console.log(erase)
            // console.log(rows)
            // console.log(results);

            results.map((row, index) => {
                let data = row.article

                let quantity = isReturn() ? negativeValue(row.quantity) : positiveValue(row.quantity)
                let actualPrice = data.actual_price || row.actual_price || 0

                let currentPrice = row.current_price

                // ако няма зададена ед. цена при импорта
                // да се вземе последната доставна цена от артикула
                if (currentPrice === null) {
                    if (Number(state.data.with_tax) === 1 || Number(state.data.has_tax) === 0) {
                        currentPrice = data.current_delivery_price_with_vat
                    } else {
                        currentPrice = data.current_delivery_price
                    }
                }

                let deliveryTotal = currentPrice * (quantity || 1)

                let taxId = row.delivery_tax_id || data.tax_id

                let currentGrossPrice = calcGrossPrice(currentPrice, taxId)

                // if (Number(state.data.with_tax) === 1 || Number(state.data.has_tax) === 0) {
                //     return parseFloat(row.current_price_gross || 0);
                // }

                let profitPerc = calcExtraPrice(currentGrossPrice, actualPrice)

                let latestCurrentPrice = data.current_delivery_price
                let latestCurrentGrossPrice = data.current_delivery_price_with_vat

                rows.push({
                    ...row,
                    quantity: quantity,
                    delivery_total: deliveryTotal,
                    delivery_tax_id: taxId,
                    latest_current_price: latestCurrentPrice,
                    latest_current_price_gross: latestCurrentGrossPrice,
                    current_price: currentPrice,
                    current_price_gross: currentGrossPrice,
                    actual_price: actualPrice,
                    profit_perc: profitPerc,
                })

            })

            // console.log(rows);

            setState(prev => ({
                ...prev,
                rows: {
                    ...prev.rows,
                    rows: Object.values(rows)
                }
            }))
        })

        modal.onSuccess(() => {
            // alert(1);
        })
    }

    const handleEditIntrastat = e => {
        e.preventDefault()

        let modal = intrastatModalRef.current

        if (!modal) {
            return
        }

        modal.edit(state.data.id)
    }

    const handleEditExpenses = e => {
        e.preventDefault()

        let modal = expensesModalRef.current

        if (!modal) {
            return
        }

        modal.edit(state.data.id)

        modal.onSuccess(({ expenses_sum }) => {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    expenses_sum: expenses_sum
                }
            }))
        })
    }

    const handleDelete = data => {
        let modal = Refs.getInstance().getRef('delete')

        modal.open()
        modal.onSuccess(() => {
            handleDestroy(state.data.id)
        })
    }

    const handleDestroy = id => {
        Api.post('store/storeloads/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                handleClose()
                state.onSuccess()
            }
        })
    }

    // console.log(state.rows.rows);

    const openMenu = e => {
        setState(prev => ({
            ...prev,
            saveButtonMenu: e.currentTarget
        }))
    }

    const closeMenu = () => {
        setState(prev => ({
            ...prev,
            saveButtonMenu: null
        }))
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault()
        }

        if (typeof state.onClose === 'function') {
            hide(state.onClose)
        } else {
            hide()
        }
    }

    const handleAddNote = () => {
        setState(prev => ({
            ...prev,
            addNote: !prev.addNote
        }))
    }

    return (
        <>
            <div className={`${state.show ? "visible" : ""} overlay`}>
                <div
                    className={`popup-primary extra-large complex-modal storage-load-form-modal cargo-modal ${state.show ? "show" : ""}`}
                >
                    <div className="header light-brown">
                        <b>
                            {state.id ? 'Редактиране на зареждане' : 'Ново зареждане'}
                        </b>
                        <p>
                            Lorem Ipsum е елементарен примерен<br /> текст, използван в печатарската
                        </p>
                    </div>
                    <div className="body">
                        {state.dataLoading
                            ?
                            <Loader />
                            :
                            <>
                                <Form ref={formRef}>
                                    {/* <Container> */}
                                    <div className="row">
                                        <div className="col">
                                            <h6>
                                                Данни за зареждането
                                            </h6>
                                            <div className="field">
                                                <div className="row">
                                                    <div className="col">
                                                        <RedditTextField
                                                            size="small"
                                                            variant="filled"
                                                            margin="dense"
                                                            fullWidth
                                                            type="datetime-local"
                                                            label="Създадено на"
                                                            value={state.data.created_at ? moment(state.data.created_at).format('YYYY-MM-DDTHH:mm') : moment().format('YYYY-MM-DDTHH:mm')}
                                                            readOnly
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <RedditSelect
                                                            name="operation_id"
                                                            value={state.data?.operation_id || operations[0]?.id || ''}
                                                            onChange={handleInputChange}
                                                            label="Операция"
                                                            error={Boolean(validations?.operation_id)}
                                                            helperText={validations?.operation_id && (validations.operation_id[0] || validations.operation_id)}
                                                        >
                                                            {operations.map(o =>
                                                                <MenuItem key={o.id} value={o.id}>
                                                                    {o?.translation?.name || o.name}
                                                                </MenuItem>
                                                            )}
                                                        </RedditSelect>
                                                    </div>
                                                    <div className="col">
                                                        <RedditSelect
                                                            name="depot_id"
                                                            value={state.data?.depot_id || depots[0]?.id || ''}
                                                            onChange={handleInputChange}
                                                            label="Склад"
                                                            error={Boolean(validations?.depot_id)}
                                                            helperText={validations?.depot_id && (validations.depot_id[0] || validations.depot_id)}
                                                        >
                                                            {depots.map(o =>
                                                                <MenuItem key={o.id} value={o.id}>
                                                                    {o?.translation?.name || o.name}
                                                                </MenuItem>
                                                            )}
                                                        </RedditSelect>
                                                    </div>
                                                    <div className="col">
                                                        <RedditTextField
                                                            size="small"
                                                            variant="filled"
                                                            margin="dense"
                                                            fullWidth
                                                            type="datetime-local"
                                                            label="Затворено на"
                                                            value={state.data.closed_at ? moment(state.data.closed_at).format('YYYY-MM-DDTHH:mm') : '-'}
                                                            readOnly
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <Autocomplete
                                                            variant="filled"
                                                            url="store/autocomplete/suppliers"
                                                            inputName="supplier_name"
                                                            inputIdName="supplier_id"
                                                            inputValue={state.data?.supplier?.name || ''}
                                                            inputIdValue={state.data?.supplier_id || ''}
                                                            onChange={handleChangeSupplier}
                                                            onClickAddButton={handleAddSupplier}
                                                            error={Boolean(validations?.supplier_id)}
                                                            helperText={validations?.supplier_id && (validations.supplier_id[0] || validations.supplier_id)}
                                                            inputPlaceholder="Доставчик"
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <RedditSelect
                                                            value={Number(state.data.has_tax) || 0}
                                                            onChange={handleInputChange}
                                                            label="Регистрация по ДДС"
                                                            name="has_tax"
                                                        >
                                                            <MenuItem value={0}>
                                                                Не
                                                            </MenuItem>
                                                            <MenuItem value={1}>
                                                                Да
                                                            </MenuItem>
                                                        </RedditSelect>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <h6>
                                                Документ за зареждането
                                            </h6>
                                            <div className="field">
                                                <div className="row">
                                                    <div className="col">
                                                        <RedditSelect
                                                            name="document_type_id"
                                                            value={state.data?.document_type_id || ''}
                                                            onChange={handleInputChange}
                                                            label="Тип документ"
                                                            error={Boolean(validations?.document_type_id)}
                                                            helperText={validations?.document_type_id && (validations.document_type_id[0] || validations.document_type_id)}
                                                        >
                                                            {documentTypes.map(o =>
                                                                <MenuItem key={o.id} value={o.id}>
                                                                    {o?.translation?.name || o.name}
                                                                </MenuItem>
                                                            )}
                                                        </RedditSelect>
                                                    </div>
                                                    <div
                                                        className="col"
                                                        style={{
                                                            minWidth: '120px'
                                                        }}
                                                    >
                                                        <RedditTextField
                                                            size="small"
                                                            variant="filled"
                                                            margin="dense"
                                                            fullWidth
                                                            name="document_no"
                                                            value={state.data?.document_no || ''}
                                                            onChange={handleInputChange}
                                                            label="Документ №"
                                                            error={Boolean(validations?.document_no)}
                                                            helperText={validations?.document_no && (validations.document_no[0] || validations.document_no)}
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <RedditTextField
                                                            size="small"
                                                            variant="filled"
                                                            margin="dense"
                                                            fullWidth
                                                            type="date"
                                                            name="document_date"
                                                            value={state.data?.document_date ? moment(state.data.document_date || '').format('YYYY-MM-DD') : ''}
                                                            onChange={handleInputChange}
                                                            label="Документ дата"
                                                            error={Boolean(validations?.document_date)}
                                                            helperText={validations?.document_date && (validations.document_date[0] || validations.document_date)}
                                                        />
                                                    </div>
                                                    <div
                                                        className="col"
                                                        style={{
                                                            minWidth: '140px'
                                                        }}
                                                    >
                                                        <RedditSelect
                                                            value={Number(state.data.with_tax) || 0}
                                                            onChange={handleInputChange}
                                                            label="Единичните цени"
                                                            name="with_tax"
                                                        >
                                                            <MenuItem value={0}>
                                                                Без ДДС
                                                            </MenuItem>
                                                            <MenuItem value={1}>
                                                                С ДДС
                                                            </MenuItem>
                                                        </RedditSelect>
                                                    </div>
                                                    {auth.getUser().permission('storeloads_edit_total_paid') ?
                                                        <>
                                                            <div
                                                                className="col"
                                                                style={{
                                                                    minWidth: '140px'
                                                                }}
                                                            >
                                                                <RedditTextField
                                                                    size="small"
                                                                    variant="filled"
                                                                    margin="dense"
                                                                    fullWidth
                                                                    name="total_paid"
                                                                    value={state.data?.total_paid || ''}
                                                                    onChange={handleInputChange}
                                                                    label="Платена сума"
                                                                    error={Boolean(validations?.total_paid)}
                                                                    helperText={validations?.total_paid && (validations.total_paid[0] || validations.total_paid)}
                                                                />
                                                            </div>
                                                            <div
                                                                className="col"
                                                                style={{
                                                                    minWidth: '180px'
                                                                }}
                                                            >
                                                                <RedditSelect
                                                                    name="payment_method_id"
                                                                    value={state.data?.payment_method_id || ''}
                                                                    onChange={handleInputChange}
                                                                    label="Начин на плащане"
                                                                >
                                                                    {paymentMethods.map(o =>
                                                                        <MenuItem key={o.id} value={o.id}>
                                                                            {o?.translation?.name || o.name}
                                                                        </MenuItem>
                                                                    )}
                                                                </RedditSelect>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                        </>
                                                    }
                                                    <div className="col">
                                                        <RedditTextField
                                                            size="small"
                                                            variant="filled"
                                                            margin="dense"
                                                            fullWidth
                                                            type="date"
                                                            name="paid_due_date"
                                                            value={state.data?.paid_due_date ? moment(state.data.paid_due_date).format('YYYY-MM-DD') : '-'}
                                                            onChange={handleInputChange}
                                                            label="Срок за плащане"
                                                            error={Boolean(validations?.paid_due_date)}
                                                            helperText={validations?.paid_due_date && (validations.paid_due_date[0] || validations.paid_due_date)}
                                                        />
                                                    </div>
                                                    {/* <div className="col">
                                                        <RedditSelect
                                                            name="currency_id"
                                                            value={state.data?.currency_id || ''}
                                                            onChange={handleCurrencyChange}
                                                            label="Валута"
                                                        >
                                                            {currencies.data.map(o =>
                                                                <MenuItem key={o.id} value={o.id}>
                                                                    {o?.translation?.name || o.name}
                                                                </MenuItem>
                                                            )}
                                                        </RedditSelect>
                                                    </div>
                                                    <div className="col">
                                                        <RedditTextField
                                                            fullWidth
                                                            name="currency_rate"
                                                            value={state.data?.currency_rate || ''}
                                                            onChange={handleInputChange}
                                                            label="Валутен курс"
                                                            error={Boolean(validations?.currency_rate)}
                                                            helperText={validations?.currency_rate && (validations.currency_rate[0] || validations.currency_rate)}
                                                        />
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* {state.data.id ?
                                        <div className="row filter">
                                            <RedditTextField
                                                fullWidth
                                                name="search"
                                                value={state.rows.filter.search || ''}
                                                onChange={e => handleSearch(e.target.name, e.target.value)}
                                                label="Търсене..."
                                            />
                                        </div>
                                        :
                                        <>
                                        </>
                                    }
                                    <br /> */}

                                    <div className="row">
                                        <div className="col">
                                            <h6>
                                                Артикули
                                            </h6>
                                            <div className="field">
                                                <table className="type-doc">
                                                    <thead>
                                                        <tr>
                                                            <th
                                                                style={{
                                                                    maxWidth: '40px'
                                                                }}
                                                            >

                                                            </th>
                                                            <th style={{ maxWidth: '40px' }}>
                                                                №
                                                            </th>
                                                            <th style={{ width: '100%' }}>
                                                                Артикул
                                                            </th>
                                                            <th>
                                                                К-во
                                                            </th>
                                                            {auth.getUser().permission('storeloads_edit_delivery_price') &&
                                                                <>
                                                                    <th>
                                                                        Сума
                                                                    </th>
                                                                    <th>
                                                                        ДДС Гр.
                                                                    </th>
                                                                    <th>
                                                                        Ед. цена
                                                                    </th>
                                                                    <th>
                                                                        Последно
                                                                    </th>
                                                                </>
                                                            }
                                                            {auth.getUser().permission('storeloads_edit_actual_price') &&
                                                                <th className="text-center">
                                                                    Прод. цена
                                                                </th>
                                                            }
                                                        </tr>

                                                    </thead>
                                                    <tbody>
                                                        {state.rows.rows.map((row, index) =>
                                                            <tr key={'r-' + index}>
                                                                <td
                                                                    style={{
                                                                        paddingLeft: 0,
                                                                        maxWidth: '40px'
                                                                    }}
                                                                >
                                                                    <Button className='small remove' onClick={e => handleRemoveRow(e, index, row)}>
                                                                    </Button>
                                                                </td>
                                                                <td style={{ maxWidth: '40px' }}>
                                                                    {index + 1}

                                                                    <input type="hidden" name={`rows[${index}][id]`} value={row.id || ''} />
                                                                </td>
                                                                <td>
                                                                    <Autocomplete
                                                                        fullWidth
                                                                        margin="none"
                                                                        size="small"
                                                                        variant="outlined"
                                                                        url="store/autocomplete/articles"
                                                                        inputName="article_name"
                                                                        inputIdName={`rows[${index}][article_id]`}
                                                                        inputValue={row?.article?.article_name || ''}
                                                                        inputIdValue={row.article_id || ''}
                                                                        onChange={data => handleChangeArticle(data, index)}
                                                                        onClickAddButton={e => handleAddArticle(index)}
                                                                        onClickShowButton={handleShowArticle}
                                                                        error={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].article_id && (validations.rows[index].article_id[0] || validations.rows[index].article_id))}
                                                                        helperText={validations && validations.rows && validations.rows[index] && validations.rows[index].article_id && (validations.rows[index].article_id[0] || validations.rows[index].article_id)}
                                                                        renderText={data => {
                                                                            return (
                                                                                <div style={{ display: 'flex', width: '100%' }}>
                                                                                    <div style={{ width: 'max-content', marginRight: '10px' }}>
                                                                                        {data.id}
                                                                                    </div>
                                                                                    <div>
                                                                                        {data.name}
                                                                                        <br />
                                                                                        {data.article_name}
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }}
                                                                        renderInputText={data => data.article_name}
                                                                        listStyle={{
                                                                            minWidth: 'max-content'
                                                                        }}
                                                                        className="quantity-input input-with-right-label"
                                                                        preventInitialLoad={true}
                                                                        autoFocus={row.isNew ? true : false}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <RedditTextField
                                                                        margin="none"
                                                                        size="small"
                                                                        variant="outlined"
                                                                        type="number"
                                                                        step="0.0001"
                                                                        fullWidth
                                                                        onChange={e => handleQtyChange(e, index)}
                                                                        value={row.quantity || ''}
                                                                        name={`rows[${index}][quantity]`}
                                                                        error={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].quantity && (validations.rows[index].quantity[0] || validations.rows[index].quantity))}
                                                                        helperText={validations && validations.rows && validations.rows[index] && validations.rows[index].quantity && (validations.rows[index].quantity[0] || validations.rows[index].quantity)}
                                                                        InputProps={row.article?.amount_type_short ? {
                                                                            endAdornment: <InputAdornment position="end">{row.article?.amount_type_short || '-'}</InputAdornment>,
                                                                        } : {}}
                                                                        className="quantity-input input-with-right-label"
                                                                    />

                                                                </td>
                                                                {auth.getUser().permission('storeloads_edit_delivery_price') &&
                                                                    <>
                                                                        <td>
                                                                            <RedditTextField
                                                                                margin="none"
                                                                                size="small"
                                                                                variant="outlined"
                                                                                type="number"
                                                                                step="0.0001"
                                                                                fullWidth
                                                                                name={`rows[${index}][delivery_total]`}
                                                                                value={row.delivery_total || ''}
                                                                                onChange={e => handleDeliveryTotalChange(e, index)}
                                                                                error={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].delivery_total && (validations.rows[index].delivery_total[0] || validations.rows[index].delivery_total))}
                                                                                helperText={validations && validations.rows && validations.rows[index] && validations.rows[index].delivery_total && (validations.rows[index].delivery_total[0] || validations.rows[index].delivery_total)}
                                                                                InputProps={state.data?.currency ? {
                                                                                    endAdornment: <InputAdornment position="end">{state.data.currency.prefix || state.data.currency.sufix || '-'}</InputAdornment>,
                                                                                } : {}}
                                                                                className="quantity-input input-with-right-label"
                                                                            />
                                                                        </td>
                                                                        <td style={{ maxWidth: '135px', minWidth: '125px' }}>

                                                                            <RedditSelect
                                                                                // margin="none"
                                                                                // size="small"
                                                                                // variant="outlined"
                                                                                name={`rows[${index}][delivery_tax_id]`}
                                                                                value={row.delivery_tax_id || '0'}
                                                                                onChange={e => handleDeliveryTaxChange(e, index)}
                                                                                label="ДДС група"
                                                                                error={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].delivery_tax_id && (validations.rows[index].delivery_tax_id[0] || validations.rows[index].delivery_total))}
                                                                                helperText={validations && validations.rows && validations.rows[index] && validations.rows[index].delivery_tax_id && (validations.rows[index].delivery_tax_id[0] || validations.rows[index].delivery_tax_id)}
                                                                                className="quantity-input input-with-right-label"
                                                                            >
                                                                                <MenuItem value="0" disabled>
                                                                                    Избор
                                                                                </MenuItem>
                                                                                {taxGroups.map(o =>
                                                                                    <MenuItem key={o.id} value={o.id}>
                                                                                        {o?.translation?.name || o.name}
                                                                                    </MenuItem>
                                                                                )}
                                                                            </RedditSelect>
                                                                        </td>
                                                                        <td>
                                                                            <RedditTextField
                                                                                margin="none"
                                                                                size="small"
                                                                                variant="outlined"
                                                                                type="number"
                                                                                step="0.0001"
                                                                                fullWidth
                                                                                name={`rows[${index}][current_price]`}
                                                                                value={row.current_price || ''}
                                                                                onChange={e => handleCurrentPriceChange(e, index)}
                                                                                error={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].current_price && (validations.rows[index].current_price[0] || validations.rows[index].current_price))}
                                                                                helperText={validations && validations.rows && validations.rows[index] && validations.rows[index].current_price && (validations.rows[index].current_price[0] || validations.rows[index].current_price)}
                                                                                InputProps={state.data?.currency ? {
                                                                                    endAdornment: <InputAdornment position="end">{state.data.currency.prefix || state.data.currency.sufix || '-'}/{row.article?.amount_type_short || '-'}</InputAdornment>,
                                                                                } : {}}
                                                                                className="quantity-input input-with-right-label"
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            {(getLatestCurrentPrice(index) || 0).toFixed(2)} {row.article?.currency ? `${row.article.currency.prefix || row.article.currency.sufix || '-'}/${row.article?.amount_type_short || '-'}` : ''}
                                                                        </td>
                                                                    </>
                                                                }
                                                                {auth.getUser().permission('storeloads_edit_actual_price') &&
                                                                    <td>
                                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                                            <span style={{ width: '20%', textAlign: 'right' }}>
                                                                                <span>
                                                                                    {getCurrentPrice(index).toFixed(2)}
                                                                                </span>
                                                                                <span style={{ margin: '0 3px' }}>
                                                                                    x
                                                                                </span>
                                                                            </span>
                                                                            <RedditTextField
                                                                                margin="none"
                                                                                size="small"
                                                                                variant="outlined"
                                                                                type="number"
                                                                                step="0.0001"
                                                                                fullWidth
                                                                                name={`rows[${index}][profit_perc]`}
                                                                                value={row.profit_perc || ''}
                                                                                onChange={e => handleProfitPercChange(e, index)}
                                                                                error={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].profit_perc && (validations.rows[index].profit_perc[0] || validations.rows[index].profit_perc))}
                                                                                helperText={validations && validations.rows && validations.rows[index] && validations.rows[index].profit_perc && (validations.rows[index].profit_perc[0] || validations.rows[index].profit_perc)}
                                                                                InputProps={{
                                                                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                                                }}
                                                                                className="quantity-input input-with-right-label"
                                                                            />
                                                                            <span style={{ margin: '0 3px' }}>
                                                                                =
                                                                            </span>
                                                                            <RedditTextField
                                                                                margin="none"
                                                                                size="small"
                                                                                variant="outlined"
                                                                                type="number"
                                                                                step="0.0001"
                                                                                fullWidth
                                                                                name={`rows[${index}][actual_price]`}
                                                                                value={row.actual_price || ''}
                                                                                onChange={e => handleActualPriceChange(e, index)}
                                                                                error={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].actual_price && (validations.rows[index].actual_price[0] || validations.rows[index].actual_price))}
                                                                                helperText={validations && validations.rows && validations.rows[index] && validations.rows[index].actual_price && (validations.rows[index].actual_price[0] || validations.rows[index].actual_price)}
                                                                                InputProps={state.data?.currency ? {
                                                                                    endAdornment: <InputAdornment position="end">{state.data.currency.prefix || state.data.currency.sufix || '-'}/{row.article?.amount_type_short || '-'}</InputAdornment>,
                                                                                } : {}}
                                                                                className="quantity-input input-with-right-label"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                }
                                                            </tr>
                                                        )}

                                                    </tbody>
                                                </table>
                                                <hr />
                                                <div className="row">
                                                    <div className="col">
                                                        <Button
                                                            className="small add"
                                                            onClick={handleAddRow}
                                                            style={{
                                                                marginBottom: '10px'
                                                            }}
                                                        >
                                                        </Button>
                                                        {state.addNote ?
                                                            <RedditTextField
                                                                size="small"
                                                                variant="filled"
                                                                margin="dense"
                                                                type="text"
                                                                fullWidth
                                                                label="Забележка"
                                                                name="description"
                                                                value={state.data?.description || ''}
                                                                onChange={handleInputChange}
                                                                error={Boolean(validations?.description)}
                                                                helperText={validations?.description && (validations.description[0] || validations.description)}
                                                                multiline
                                                                rows={3}
                                                                autoFocus={true}
                                                            />
                                                            :
                                                            <input hidden value={null} name="description" />
                                                        }
                                                        <span
                                                            className="link"
                                                            style={{
                                                                width: 'fit-content',
                                                                marginTop: '10px',
                                                            }}
                                                            onClick={handleAddNote}
                                                        >
                                                            {state.addNote ? 'Премахване' : 'Добавяне'} на забележка
                                                        </span>
                                                    </div>
                                                    <div className="col">
                                                        <table style={{
                                                            width: '300px',
                                                            marginLeft: 'auto'
                                                        }}>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="text-right">
                                                                        Сума
                                                                    </td>
                                                                    <td
                                                                        className="bold text-right"
                                                                        style={{
                                                                            maxWidth: '150px'
                                                                        }}
                                                                    >
                                                                        {state.data.currency.prefix} {getTotalDelivery().toFixed(2)} {state.data.currency.sufix}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-right">
                                                                        Данъчна основа
                                                                    </td>
                                                                    <td
                                                                        className="bold text-right"
                                                                        style={{
                                                                            maxWidth: '150px'
                                                                        }}
                                                                    >
                                                                        {state.data.currency.prefix} {getTotalNet().toFixed(2)} {state.data.currency.sufix}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-right">
                                                                        ДДС
                                                                    </td>
                                                                    <td
                                                                        className="bold text-right"
                                                                        style={{
                                                                            maxWidth: '150px'
                                                                        }}
                                                                    >
                                                                        {state.data.currency.prefix} {getTotalVat().toFixed(2)} {state.data.currency.sufix}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-right" >
                                                                        Общо
                                                                    </td>
                                                                    <td
                                                                        className="bold text-right"
                                                                        style={{
                                                                            maxWidth: '150px'
                                                                        }}
                                                                    >
                                                                        {state.data.currency.prefix} {getTotalGross().toFixed(2)} {state.data.currency.sufix}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </>
                        }
                    </div>

                    <div className="footer">
                        <Menu
                            anchorEl={state.saveButtonMenu}
                            elevation={0}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            open={Boolean(state.saveButtonMenu)}
                            onClose={closeMenu}
                            onClick={closeMenu}
                        >
                            <MenuItem
                                onClick={handleSave}
                            >
                                Запази и продължи
                            </MenuItem>

                            {state.data.allow_close ?
                                <MenuItem
                                    onClick={handleSaveAndClose}
                                >
                                    Запази и вкарай в склада
                                </MenuItem>
                                :
                                <>
                                </>
                            }
                        </Menu>

                        {state.data.allow_delete ?
                            <Button
                                className="remove"
                                onClick={handleDelete}
                                style={{ marginRight: 'auto' }}>
                                Изтрий
                            </Button>
                            :
                            <>
                            </>
                        }
                        <Button
                            className="cancel"
                            onClick={handleClose}
                        >
                            Отказ
                        </Button>
                        <SaveButton
                            className="save with-dropdown"
                            loading={state.loadingExit || state.loadingBase || state.loadingClose}
                            disabled={state.loading}
                            onClick={handleSave}
                        >
                            Запази
                            <i
                                className="menu"
                                onClick={openMenu}
                            >
                                <img src={dropArrowIcon} />
                            </i>
                        </SaveButton>
                    </div>
                </div>
            </div>
            <Supplier
                ref={supplierModalRef}
            />

            <Question
                ref={saveAndCloseModalRef}
                mainMessage="Сигурни ли сте, че искате да затворите това зареждане?"
            />

            <Error
                ref={errorModalRef}
            />

            <Import
                ref={importModalRef}
                type={TYPES.STORELOAD}
                createText="Замяна"
                updateText="Добавяне"
            />

            <Intrastat
                ref={intrastatModalRef}
            />

            <Expenses
                ref={expensesModalRef}
            />
        </>
    )
}

export default forwardRef(AddOrEdit)