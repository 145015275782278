import { useState } from "react"
import { createPortal } from "react-dom"

//components
import PaginationMini from "components/misc/PaginationMini"
import RedditTextField from "components/misc/RedditTextField"
import MenuOptions from "components/misc/MenuOptions"
import CommunicationOptions from "./CommunicationOptions"
import AddOrEditMarketingArchive from "./AddOrEditMarketingArchive"

//librarires
import { Pagination } from "react-bootstrap"
import moment from "moment"
import { useQueryClient } from "react-query"
import { Skeleton } from "@mui/material"

//stores
import { useAddToMarketingArchiveModal } from "stores/customer/useAddToMarketingArchiveModal"

//hooks
import useFetchFromServer from "hooks/useFetchFromServer"
import useSaveOnServer from "hooks/useSaveOnServer"

function MarketingArchiveList() {
  const queryClient = useQueryClient()

  const { getData, loadingFromServer } = useFetchFromServer(
    "marketingArchive",
    []
  )
  const { saveOnServer } = useSaveOnServer()

  const [filters, setFilters] = useState({
    search: "",
    statusId: "all",
  })

  const { showModal } = useAddToMarketingArchiveModal()

  function getFilteredMarketingArchiveList() {
    return getData().filter((archive) => {
      return archive?.note.toLowerCase().includes(filters.search.toLowerCase())
    })
  }

  function handleSearch(e) {
    const { name, value } = e.target

    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  function handleEdit(archive) {
    showModal(true, archive)
  }

  function handleRevemo(i) {
    const newMarketingArchiveList = [...getData()]
    newMarketingArchiveList.splice(i, 1)

    saveOnServer("marketingArchive", newMarketingArchiveList, () => {
      queryClient.invalidateQueries("marketingArchive")
    })
  }

  return (
    <>
      <div className="filter-holder">
        <div className="row filter">
          <div
            className="col"
            style={{
              maxWidth: "300px",
            }}
          >
            <RedditTextField
              size="small"
              margin="dense"
              fullWidth
              label="Търсене..."
              name="search"
              value={filters.search}
              onChange={handleSearch}
            />
          </div>
        </div>
        <PaginationMini
          isLoading={false}
          page={1}
          pages={5}
          handlePage={() => {}}
        />
      </div>
      {loadingFromServer ? (
        <>
          <Skeleton variant="rectangular" width="100%" height={50} />
          <br />
          <Skeleton variant="rectangular" width="100%" height={50} />
          <br />
          <Skeleton variant="rectangular" width="100%" height={50} />
          <br />
          <Skeleton variant="rectangular" width="100%" height={50} />
          <br />
          <Skeleton variant="rectangular" width="100%" height={50} />
        </>
      ) : (
        <table className="type-outer">
          <thead>
            <tr>
              <th>Дата</th>
              <th>Име на файла</th>
              <th>Тип</th>
              <th>Размер</th>
              <th>Бележки</th>
              <th className="text-center">Опции</th>
            </tr>
          </thead>
          <tbody>
            {getFilteredMarketingArchiveList().map((archive, i) => (
              <tr key={archive.id}>
                <td
                  onClick={() => {
                    handleEdit(archive)
                  }}
                >
                  {`${moment(archive?.date).format("DD.MM.YYYY")} г.`}
                </td>
                <td
                  onClick={() => {
                    handleEdit(archive)
                  }}
                >
                  {archive?.fileName || "-"}
                </td>
                <td
                  onClick={() => {
                    handleEdit(archive)
                  }}
                >
                  {archive?.fileType || "-"}
                </td>
                <td
                  onClick={() => {
                    handleEdit(archive)
                  }}
                >
                  {archive?.fileSize || "0 KB"}
                </td>
                <td
                  onClick={() => {
                    handleEdit(archive)
                  }}
                >
                  {archive?.note && archive?.note?.length >= 30
                    ? `${archive?.note?.substring(30, 0)}...`
                    : archive?.note || "-"}
                </td>
                <td
                  className="text-center"
                  style={{
                    width: "64px",
                  }}
                >
                  <MenuOptions>
                    <CommunicationOptions
                      handleEdit={() => handleEdit(archive)}
                      handleRemove={() => handleRevemo(i)}
                    />
                  </MenuOptions>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <Pagination page={1} pages={5} handlePage={() => {}} />
      {createPortal(
        <AddOrEditMarketingArchive allMarketingArchive={getData()} />,
        document.body
      )}
    </>
  )
}

export default MarketingArchiveList
