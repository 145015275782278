import { useState } from "react"

//libraries
import { Button, Popover, Tooltip } from "@material-ui/core"

//components
import RedditTextField from "components/misc/RedditTextField"

//images
import addClientIcon from "assets/img/customers/icons/add-client.png"
import removeIcon from "assets/img/customers/icons/remove-filled.png"
import closeIcon from "assets/img/app/icons/close-small.png"

const CLIENT_COLORS = ["#3BC870", "#C471F5", "#0E61DD"]
const CLIENTS_FROM_SERVER = [
  {
    id: 1,
    name: "Client 1",
  },
  {
    id: 2,
    name: "Client 2",
  },
  {
    id: 3,
    name: "Client 3",
  },
  {
    id: 4,
    name: "Client 4",
  },
  {
    id: 5,
    name: "Client 5",
  },
  {
    id: 6,
    name: "Client 6",
  },
  {
    id: 7,
    name: "Client 7",
  },
]

function CampaignClients({ clients = [], editable = false }) {
  const [addClientEl, setAddClientEl] = useState(null)
  const [selectedClients, setSelectedClients] = useState(clients)

  function getFirstTwoLettersOfName(name) {
    return (
      name
        ?.split(" ")
        ?.map((n) => n[0])
        ?.join("") || ""
    )
  }

  function getLimitedClients() {
    return selectedClients.length > 3
      ? [
          ...selectedClients.slice(0, 3),
          {
            id: "last",
            name: `+${selectedClients.length - 3}`,
          },
        ]
      : selectedClients
  }

  return (
    <>
      <ul className="campaingn-clients">
        {getLimitedClients().map(({ id, name }, i) => (
          <li
            key={id}
            style={{
              backgroundColor: CLIENT_COLORS[i] || "#AEB7C9",
            }}
          >
            {id === "last" ? name : getFirstTwoLettersOfName(name)}
            {editable && i <= 2 ? (
              <Tooltip title={`Премахване на ${name}`}>
                <Button
                  className="remove-client"
                  onClick={() =>
                    setSelectedClients((prev) =>
                      prev.filter((client) => client.id !== id)
                    )
                  }
                >
                  <img src={closeIcon} alt="" />
                </Button>
              </Tooltip>
            ) : (
              <></>
            )}
          </li>
        ))}
      </ul>
      {editable ? (
        <>
          <Button
            className={`add-client ${
              !selectedClients.length ? "no-margin" : ""
            }`}
            onClick={({ target }) => setAddClientEl(target)}
          >
            <img src={addClientIcon} alt="" />
          </Button>
          <Popover
            open={!!addClientEl}
            anchorEl={addClientEl}
            onClose={() => setAddClientEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            className="add-client-popover"
          >
            <RedditTextField label="Търсене..." autoFocus />
            <ul className="clients-list">
              {CLIENTS_FROM_SERVER.map(({ id, name }) => (
                <li
                  key={id}
                  onClick={() =>
                    setSelectedClients((prev) => [...prev, { id, name }])
                  }
                  className={
                    selectedClients.find((c) => c.id === id)
                      ? "selected disabled"
                      : ""
                  }
                >
                  {name}
                </li>
              ))}
            </ul>
          </Popover>
          <Button
            className="remove-all-clients"
            onClick={() => setSelectedClients([])}
          >
            <img src={removeIcon} alt="" />
            Изтрий всички
          </Button>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default CampaignClients
