import { useRef } from "react"

//hooks
import useFetchTransactions from "../hooks/useFetchTransactions"

//images
import cashIcon from "assets/img/dashboard/icons/cash.png"
import loader from "assets/img/loader.svg"

//libraries
import { Button } from "@material-ui/core"

//components
import AddExpense from "components/pages/finances/expenses/partials/AddOrEdit"
import AddIncome from "components/pages/finances/incomes/partials/AddOrEdit"

//providers
import { useAuthDataContext } from "providers/Auth"
import { useDashboardDataContext } from "../providers/dashboard"

//helpers
import { splitLongNumbers } from "helpers/numbers"

function TransactionsTable() {
  const auth = useAuthDataContext()
  const dashboardData = useDashboardDataContext()

  const addExpenseModalRef = useRef()
  const addIncomeModalRef = useRef()

  const { getTransactions, isLoading } = useFetchTransactions()

  if (isLoading) {
    return <img src={loader} alt="loading" />
  }

  function renderAddButtons() {
    function _handleAddIncome() {
      const modal = addIncomeModalRef.current
      modal.add()

      modal.onSuccess(() => {
        dashboardData.refreshData("TRANSACTIONS")
      })
    }

    function _handleAddExpense() {
      const modal = addExpenseModalRef.current
      modal.add()

      modal.onSuccess(() => {
        dashboardData.refreshData("TRANSACTIONS")
      })
    }

    return (
      <>
        <AddExpense
          ref={addExpenseModalRef}
          companyId={auth?.getUser()?.getCompany()?.id}
        />
        <AddIncome
          ref={addIncomeModalRef}
          companyId={auth?.getUser()?.getCompany()?.id}
        />
        <div className="buttons">
          <Button className="add income" onClick={_handleAddIncome}>
            Приход
          </Button>
          <Button className="add expense" onClick={_handleAddExpense}>
            Разход
          </Button>
        </div>
      </>
    )
  }

  if (!isLoading && !getTransactions().length) {
    return (
      <div className="no-data">
        <img src={cashIcon} alt="" />
        <b>
          Все още нямате добавени
          <br />
          приходи и разходи
        </b>
        <p>
          Lorem Ipsum е елементарен примерен текст,
          <br />
          използван в печатарската и типографската индустрия
        </p>
        {renderAddButtons()}
      </div>
    )
  }

  return (
    <>
      <div className="head">
        <div className="left">
          <img src={cashIcon} alt="" />
          <div className="r">
            <b>Приходи и разходи</b>
            <p>Вижте последните приходи и разходи,<br /> които сте направили.</p>
          </div>
        </div>
        {renderAddButtons()}
      </div>
      <table className="type-outer">
        <thead>
          <tr>
            <th>Дата</th>
            <th className="text-right">Сума</th>
            <th>Описание</th>
          </tr>
        </thead>
        <tbody>
          {getTransactions().map((d) => (
            <tr key={d.id} className={d.type}>
              <td>{d.date}</td>
              <td className="text-right" style={{ whiteSpace: 'nowrap' }}>
                {splitLongNumbers(d.price)} {d.currency.name}
              </td>
              <td>{d.description || "-"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

export default TransactionsTable
