//hooks
import useCompanyDataHook from "../useCompanyDataHook"

//partials
import CompanyNumberInputField from './CompanyNumberInputField'
import CompanyVatInputField from "./CompanyVatInputField"
import CompanySettlementInputField from "./CompanySettlementInputField"

//providers
import { useRegisterContext } from "../RegisterProvider"

//miscs
import RedditTextField from "components/misc/RedditTextField"

//MUI components
import { Button } from '@material-ui/core'

//images
import loadingImage from 'assets/img/loader-2.svg'

const CompanyData = () => {
    const register = useRegisterContext()
    const { companyData } = register

    const {
        handleCompanyDataChange,
        setCompanyDataState,
        handleSearchChange,
        isEditMode,
        areFieldsShown,
        setEditMode,
    } = useCompanyDataHook()

    return (
        <li className={`company-data ${areFieldsShown() ? 'filled' : ''}`}>
            <p>
                След като въведете ЕИК/ Булстат, ще попълним всички данни на фирмата автоматично за Ваше улеснение, ако тя съществува в търговсия регистър
            </p>
            <CompanyNumberInputField
                companyId={1}
                label="ЕИК / Булстат"
                name="customerinfo[name]"
                value={register.search || null}
                type="number"
                selectCustomer={setCompanyDataState}
                onInputChange={handleSearchChange}
                autoFocus
                className="main-input"
                noCompany={true}
            />
            {areFieldsShown() ?
                <>
                    {!isEditMode() ?
                        <div className={`holder ${register.companyData.loading ? 'disabled' : ''}`}>
                            <ul className="data-list">
                                <li>
                                    <span>Име на фирма:</span> <i>{companyData.data?.name || ''}</i>
                                </li>
                                <li>
                                    <span>МОЛ:</span> <i>{companyData.data?.mol || ''}</i>
                                </li>
                                <li>
                                    <span>ИН по ЗДДС:</span> <i>{register.getVatNumberLoading() ? <img src={loadingImage} style={{ width: '15px', height: '15px' }} /> : companyData.data?.vatNumber || ''}</i>
                                </li>
                                <li>
                                    <span>Населено място:</span> <i>{companyData.data?.settlement || ''}</i>
                                </li>
                                <li>
                                    <span>Адрес:</span> <i>{companyData.data?.address || ''}</i>
                                </li>
                            </ul>
                            <Button
                                onClick={() => {
                                    setEditMode(true)
                                }}
                                className="edit-mode"
                            >
                                Редактиране на данните
                            </Button>
                        </div>
                        :
                        <div className={`holder ${register.companyData.loading ? 'disabled' : ''}`}>
                            <div className="row">
                                <div className="col">
                                    <RedditTextField
                                        size="small"
                                        margin="dense"
                                        fullWidth
                                        name="name"
                                        value={companyData.data?.name || ''}
                                        label="Име на фирма"
                                        required
                                        onChange={handleCompanyDataChange}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <RedditTextField
                                        size="small"
                                        margin="dense"
                                        fullWidth
                                        name="mol"
                                        value={companyData.data?.mol || ''}
                                        label="МОЛ"
                                        required
                                        onChange={handleCompanyDataChange}
                                    />
                                </div>
                                <div className="col">
                                    <CompanyVatInputField />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <CompanySettlementInputField />
                                </div>
                                <div className="col">
                                    <RedditTextField
                                        size="small"
                                        margin="dense"
                                        fullWidth
                                        name="address"
                                        value={companyData.data?.address || ''}
                                        label="Адрес"
                                        required
                                        onChange={handleCompanyDataChange}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                </>
                :
                <>
                </>
            }
        </li>
    )
}

export default CompanyData