import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <div className="footer">
        {/* <ul className="links">
                <li>
                    <Link to="/" className="link">
                        Условия за ползване
                    </Link>
                </li>
                <li>
                    <Link to="/" className="link">
                        Лични данни
                    </Link>
                </li>
                <li>
                    <Link to="/" className="link">
                        Бисквитки
                    </Link>
                </li>
            </ul> */}
            <p>
                © Copyright Docuela 2022. All Rights Reserved.
            </p>
        </div>
    )
}

export default Footer