import { forwardRef, useImperativeHandle, useRef } from 'react'

//plugins
import { Button } from '@material-ui/core'

//helpers
import { useNestedState } from 'helpers/NestedState'

//providers
import { useSubscriptionContext } from '../Provider'

//misc
import SaveButton from 'components/misc/Button'
import Form from './Form'
import moment from 'moment'

function AddOrEdit(props, ref) {

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        edit: false,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
    })

    const context = useSubscriptionContext()

    useImperativeHandle(ref, () => ({
        add: (clientId) => {
            handleShow(clientId)
        },
        edit: (id) => {
            edit(id)
        },
        hide: () => {
            hide()
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        }
    }))

    const formRef = useRef(null)

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }))

        onEntering?.()
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }))

        onExiting?.()
    }

    const handleShow = (clientId = null) => {
        setState(prev => ({
            ...prev,
            id: null,
        }))

        context.setState(prev => ({
            ...prev,
            clientId: clientId
        }))

        show()
    }

    const handleClose = e => {
        e.preventDefault()

        close()
    }

    const close = () => {

        setState(prev => ({
            ...prev,
            id: null,
        }))

        context.resetData()

        if (typeof state.onClose === 'function') {
            hide(state.onClose)
        } else {
            hide()
        }
    }


    const edit = id => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: moment().unix()
        }))

        show()
    }

    const onSuccess = (data) => {
        if (typeof state.onSuccess === 'function') {
            state.onSuccess(data)
        }

        context.resetData()

        hide()
    }

    return (
        <>

            <div className={`${state.show ? "visible" : ""} overlay`}>
                <div
                    className={`popup-primary medium complex-modal storage-load-form-modal subscription-modal ${state.show ? "show" : ""}`}
                >
                    <div className="header light-brown">
                        <b>
                            Абонамент {state.data?.id && '(ID: ' + state.data.id + ')'}
                        </b>
                        <p>
                            Lorem Ipsum е елементарен примерен<br /> текст, използван в печатарската
                        </p>
                    </div>
                    <div className="body">
                        <Form
                            ref={formRef}
                            id={state.id}
                            show={show}
                            hide={hide}
                            onSuccess={onSuccess}
                        />
                    </div>
                    <div className="footer">
                        <Button className="cancel" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            onClick={e => formRef.current?.save(e)}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default forwardRef(AddOrEdit)
