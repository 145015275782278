import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import Skeleton from 'react-loading-skeleton'

//constants
import { settings } from 'constants/CompanySettings'

//miscs
import RedditSelect from 'components/misc/RedditSelect'
import RedditTextField from 'components/misc/RedditTextField'

//images
import checkIcon from 'assets/img/documents/icons/check.png'
import checkGrayIcon from 'assets/img/documents/icons/check-gray.png'

//providers
import { useLanguageContext } from 'providers/Language'
import { useCurrencyContext } from 'providers/Currency'
import { useAppContext } from 'providers/App'

//MUI components
import { Button, MenuItem, Tooltip, Switch, FormControlLabel } from '@material-ui/core'

//helpers
import Api from 'helpers/Api'

//modals
import Bank from 'components/pages/settings/partials/payments/partials/Bank'

function Index({ handleDisableSave, companyId, banks }) {
    const app = useAppContext()
    const langs = useLanguageContext()
    const currencies = useCurrencyContext()

    const getBanks = () => banks || []
    const getDefaultBankId = () => getBanks().find(b => b.default)?.id || null

    const [state, setState] = useState({
        loading: true,
        loadingSave: false,
        refresh: false,
        data: [],
        banks: getBanks(),
        defaultBankId: getDefaultBankId(),
        tempSrc: undefined
    })

    const snackBarFailRef = useRef(null)
    const bankConfigModalRef = useRef(null)

    useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
        setLoading(true)

        const url = `settings/get?company_id=${companyId}`

        Api.get(url)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data,
                }))
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const saveData = e => {
        e.preventDefault()

        setLoadingSave(true)

        const data = new FormData(e.target)

        data.append('company_id', companyId)

        Api.post('/settings/set', data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
            .then(res => {
                success('Вашите данни бяха запазени успешно!')

                setState(prev => ({
                    ...prev,
                    data: res.data,
                }))
            })
            .catch(() => {
                error('Възникна грешка.')
            })
            .finally(() => {
                setLoadingSave(false)
            })
    }

    const setLoading = loading => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }))
    }

    const setLoadingSave = loadingSave => {
        setState(prev => ({
            ...prev,
            loadingSave
        }))
    }

    // от базата
    const getSavedSetting = key => {
        const setting = state.data.find(setting => setting.key === key)

        if (setting) {
            return setting
        }

        return null
    }

    // предварително дефинирани настройки
    const getSetting = key => settings.find(setting => setting.key === key)

    // стойност на настройката в базата
    const getSettingValue = key => getSavedSetting(key)?.value

    // стойност на настройката в базата
    const getSettingData = key => getSavedSetting(key)?.data

    // списък с опции
    const getSettingOptions = key => {
        const setting = getSetting(key)

        let options = setting.options || []

        if (key === 'lang_id') {
            options = langs.data
        }

        if (key === 'currency_id') {
            options = currencies.data
        }

        return options
    }

    const handleInputChange = e => {
        handleDisableSave(false)

        const { name, value, checked, type } = e.target
        if (type === 'file') {
            const src = URL.createObjectURL(e.target.files[0])
            setTempSrc(src)
        }

        setState(prev => ({
            ...prev,
            [name]: checked || value
        }))

    }

    const setTempSrc = tempSrc => {
        setState(prev => ({
            ...prev,
            tempSrc
        }))
    }

    const getSettingInput = key => {
        const setting = getSetting(key)
        let input

        if (setting) {
            switch (setting.type) {
                case 'input-text':
                    input = <RedditTextField
                        size="small"
                        variant="filled"
                        margin="dense"
                        label={setting.name}
                        name={`settings[${key}]`}
                        onChange={handleInputChange}
                        value={getSettingValue(key)}
                    />
                    break
                case 'input-file':
                    input = <input
                        type="file"
                        onChange={handleInputChange}
                        name={`settings[${key}]`}
                        multiple={setting.multiple || false}
                    />
                    break
                case 'select':
                    input = key === 'set_expenses_with_vat' || key === 'set_incomes_with_vat' ?
                        <>
                            <FormControlLabel control={
                                <Switch
                                    name={key}
                                    defaultChecked={getSettingValue(key) === '1'}
                                    onChange={handleInputChange}
                                    color="primary"
                                />
                            }
                                label={setting.name}
                            />
                            {state[key] !== undefined ?
                                <input hidden name={`settings[${key}]`} value={state[key] ? 1 : 0} />
                                :
                                <>
                                </>
                            }
                        </>
                        : <RedditSelect
                            name={`settings[${key}]`}
                            defaultValue={getSettingValue(key)}
                            onChange={handleInputChange}
                            label={setting.name}
                        >
                            {getSettingOptions(key).map(o =>
                                <MenuItem
                                    key={`o_${o.id}`}
                                    value={o.id}
                                // disabled={setting.disableClearable || false}
                                >
                                    {o.name} {state[key] == o.id ? 'ok' : ''}
                                </MenuItem>
                            )}
                        </RedditSelect>
                    break
            }
        }

        return input
    }

    const success = (msg = null) => {
        app.handleSuccess(msg)
    }

    const error = () => {
        snackBarFailRef.current.show()
    }

    /**
     * 
     * @param {number} id ид на банка 
     * @returns дали банката е основна
     */
    const isCurrentBank = id => {
        if (id === state.defaultBankId) {
            return true
        }

        return false
    }

    const editBank = (bank = {}) => {
        const banks = state.banks.map(b => {
            if (b.id === bank.id) {
                b = bank
            }

            return b
        })

        setState(prev => ({
            ...prev,
            banks
        }))
    }

    const appendBank = (bank = {}) => {
        setState(prev => ({
            ...prev,
            banks: [
                bank,
                ...prev.banks
            ],
            defaultBankId: bank?.id || null
        }))
    }

    const unappendBank = id => {
        const banks = state.banks.filter(b => Number(b.id) !== Number(id))

        setState(prev => ({
            ...prev,
            banks
        }))
    }

    const handleChangeDefaultBank = id => {
        if (id === state.defaultBankId) return

        setState(prev => ({
            ...prev,
            defaultBankId: id
        }))

        Api.post('banks/set-as-default', {
            id,
            company_id: companyId
        })

        app.handleSuccess('Банката по подразбиране беше сменена успешно!')
    }

    return (
        <>
            <b>
                Допълнителни настройки
            </b>
            {createPortal(
                <Bank
                    ref={bankConfigModalRef}
                    message="Нямате зададени настройки за приемане на банков превод. Моля, посочете ги тук."
                    appendBank={appendBank}
                    editBank={editBank}
                    unappendBank={unappendBank}
                    defaultBankId={state.defaultBankId}
                    companyId={companyId}
                    bankAccountsCount={state.banks.length}
                />,
                document.body
            )}
            {state.loading
                ?
                <Skeleton count={5} height={60} />
                :
                <>
                    <div className="col bank-data">
                        <h3 className="heading">
                            Банкови сметки
                            <Button
                                className="add simple-add"
                                onClick={() => {
                                    bankConfigModalRef.current.show()
                                }}
                                style={{
                                    width: 'fit-content'
                                }}
                            >
                                Нова сметка
                            </Button>
                        </h3>
                        {(!state.banks.length || 0) ?
                            <p className="no-data">
                                Нямате добавени банкови сметки.
                            </p>
                            :
                            <ul>
                                {state.banks.map(b =>
                                    <li
                                        key={`bank-${b.id}`}
                                        className={isCurrentBank(b.id) ? 'current' : ''}
                                        onClick={e => {
                                            if (e.target.classList.contains('not-default')) return
                                            
                                            bankConfigModalRef.current.show(b)
                                        }}
                                    >
                                        <div className="options">
                                            <Tooltip title={!isCurrentBank(b.id) ? 'Задаване като банка по подразбиране' : ''}>
                                                <img
                                                    src={isCurrentBank(b.id) ? checkIcon : checkGrayIcon}
                                                    className={isCurrentBank(b.id) ? 'default' : 'not-default'}
                                                    onClick={() => {
                                                        handleChangeDefaultBank(b.id)
                                                    }}
                                                />
                                            </Tooltip>
                                            Основна сметка
                                        </div>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        IBAN:
                                                    </td>
                                                    <td>
                                                        {b.iban}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        BIC:
                                                    </td>
                                                    <td>
                                                        {b.bic}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Банка:
                                                    </td>
                                                    <td>
                                                        {b.name}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </li>
                                )}
                            </ul>
                        }
                    </div>
                    {/* При неправилна подреба на JSX - визуализацията се губи! */}
                    <div className="col">
                        <h3 className="heading">
                            Други настройки
                        </h3>
                        <div className="row">
                            {settings.map((setting, i) =>
                                i <= 1 ?
                                    setting.type !== 'input-file' ?
                                        <div className="col">
                                            {getSettingInput(setting.key)}
                                        </div>
                                        :
                                        <>
                                        </>
                                    :
                                    <>
                                    </>

                            )}
                        </div>
                        <div className="row">
                            <div className="col">
                                {settings.map((setting, i) =>
                                    i > 1 ?
                                        setting.type !== 'input-file' ?
                                            <div className="col">
                                                {getSettingInput(setting.key)}
                                            </div>
                                            :
                                            <>
                                            </>
                                        :
                                        <>
                                        </>

                                )}
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default Index