import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import { TrashFill } from 'react-bootstrap-icons'
import { sortable } from 'react-sortable'
import { cloneDeep } from 'lodash'

//helpers
import Api from 'helpers/Api'
import { addExtraPrice, calcExtraPrice, removeExtraPrice } from 'helpers/Price'


//providers
import { useLanguageContext } from 'providers/Language'
import { useArticleContext } from '../Provider'
import { useAppContext } from 'providers/App'
import { useCurrencyContext } from 'providers/Currency'
import { useAuthDataContext } from 'providers/Auth'

import Info from 'components/modals/Info'

//misc
import HelpInfo from 'components/misc/Info'
import Loader from 'components/misc/Loader'
import Alert from 'components/misc/Alert'

import './style.scss'

import { CKEditor } from '@ckeditor/ckeditor5-react'
import RedditTextField from 'components/misc/RedditTextField'
import RedditSelect from 'components/misc/RedditSelect'
import { MenuItem, Checkbox, Switch, FormControlLabel, Button } from '@material-ui/core'
import uploadIcon from 'assets/img/app/icons/upload.png'
import LeftSideMenu from 'components/misc/LeftSideMenu'

//images
import viewIcon from 'assets/img/accounts/icons/view.png'
import imagesIcon from 'assets/img/articles/icons/image.png'
import moreIcon from 'assets/img/articles/icons/more.png'

const ClassicEditor = window.ClassicEditor

function SortableItem(props) {
    return (
        <div {...props}>
            {props.children}
        </div>
    )
}

const SortItem = sortable(SortableItem)

function Index(props, ref) {

    const app = useAppContext()
    const auth = useAuthDataContext()
    const langs = useLanguageContext()
    const currencies = useCurrencyContext()
    const context = useArticleContext()

    const { state, setState } = context
    const { validations, setValidations } = context
    const { defaultData } = context

    const [stores, setStores] = useState([])
    const [depots, setDepots] = useState([])

    const [amountTypes, setAmountTypes] = useState([])
    const [taxGroups, setTaxGroups] = useState([])

    const { show, hide, edit, refresh } = props

    const category = props.category || null

    useImperativeHandle(ref, () => ({
        save: (e) => {
            handleSave(e)
        },
    }))

    const formRef = useRef(null)

    const inputFileRef = useRef(null)
    const validationModalRef = useRef(null)

    useEffect(() => {
        loadData()
    }, [props.id])

    // useEffect(() => {
    //     if (state.data.details.length === 0) {
    //         setState(prev => ({
    //             ...prev,
    //             data: {
    //                 ...prev.data,
    //                 details: [{}]
    //             }
    //         }))
    //     }
    // }, [state.data.details]);

    useEffect(() => {

        if (amountTypes.length === 0) {
            Api.get('store/articles/amount-types')
                .then(res => {
                    if (Array.isArray(res.data)) {
                        setAmountTypes(res.data)
                    }
                })
        }

        if (stores.length === 0) {
            Api.get('store/articles/stores')
                .then(res => {
                    if (Array.isArray(res.data)) {
                        setStores(res.data)
                    }
                })
        }

        if (depots.length === 0) {
            Api.get('store/articles/depots')
                .then(res => {
                    if (Array.isArray(res.data)) {
                        setDepots(res.data)
                    }
                })
        }

        if (taxGroups.length === 0) {
            Api.get('store/articles/tax-groups')
                .then(res => {
                    if (Array.isArray(res.data)) {
                        setTaxGroups(res.data)
                    }
                })
        }
    }, [])

    useEffect(() => {
        if (state.refresh) {
            loadData()
        }
    }, [state.refresh])

    useEffect(() => {
        let tax = getTaxGroupById(state.data.tax_id)

        let taxval = parseFloat(tax?.value || 0)

        let avgDeliveryPriceWithVat = addExtraPrice(state.data.avg_delivery_price, taxval)

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                avg_delivery_price_with_vat: avgDeliveryPriceWithVat,
            }
        }))

    }, [state.data.tax_id, state.data.avg_delivery_price])

    useEffect(() => {
        setProfit()
        setMargin()
    }, [state.data.avg_delivery_price, state.data.amount_unit])


    const loadData = () => {
        if (!props.id) {
            return
        }

        if (state.data.id === props.id) {
            return
        }

        setState(prev => ({
            ...prev,
            dataLoading: state.data.id ? false : true,
        }))

        Api.get('store/articles/show?id=' + props.id)
            .then(res => {

                let data = res.data

                if (data.storesdetails) {
                    let storesdetails = {}

                    res.data.storesdetails.map(detail => {
                        storesdetails[detail.store_id] = detail
                    })

                    data.storesdetails = storesdetails
                } else {
                    data.storesdetails = defaultData.storesdetails
                }

                if (data.depotsdetails) {
                    let depotsdetails = {}

                    res.data.depotsdetails.map(detail => {
                        depotsdetails[detail.depot_id] = detail
                    })

                    data.depotsdetails = depotsdetails
                } else {
                    data.depotsdetails = defaultData.depotsdetails
                }


                setState(prev => ({
                    ...prev,
                    data: data,
                    initialData: data,
                    dataLoading: false,
                }))
            })
    }

    const handleSave = e => {
        e.preventDefault()

        if (state.loading) {
            return
        }

        setState(prev => ({
            ...prev,
            loading: true,
        }))

        setValidations(null)

        let url = state.data.id ? 'store/articles/edit' : 'store/articles/add'

        let data = new FormData(formRef.current)

        if (state.data.id) {
            data.append('id', state.data.id)
        }

        if (category) {
            data.append('category_id', category.id)
        }

        if (!state.data.is_for_sale) {
            data.append('is_for_sale', 0)
        }

        state.data.files.map((file, i) => {
            if (file.was_recently_attached) {
                data.append('files[' + i + ']', file)
            }
        })

        if (typeof props.params === 'object') {
            Object.entries(props.params).map(e => {
                data.append(e[0], e[1])
            })
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {

            if (typeof props.onSuccess === 'function') {
                props.onSuccess(res.data)
            }

            app.showSuccess()

            // refresh(res.data.id);
        }).catch(error => {
            const _err = error.response

            if (_err && _err.status && _err.status === 422) {
                setValidations(_err.data.errors)

                // скриването unmount-ва модала и създава проблеми...

                // hide(() => {
                //     let modal = validationModalRef.current;

                //     if (modal) {
                //         modal.open();

                //         modal.onClose(() => {

                //             show();
                //         });
                //     }
                // });
            } else {
                app.showError()
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }))
        })
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleCheckboxChange = e => {
        const name = e.target.name

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }))
    }

    const handleDateChange = e => {
        const name = e.target.name + '_ymd'
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleTaxChange = e => {
        let id = e.target.value

        let tax = getTaxGroupById(id)

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                tax_id: id,
            }
        }))
    }

    const getTaxGroupById = id => {
        return taxGroups.find(c => Number(c.id) === Number(id))
    }

    const setProfit = (avgDeliveryPrice = null, amountUnit = null, price = null) => {

        let tax = getTaxGroupById(state.data.tax_id)

        let taxval = parseFloat(tax?.value || 0)

        if (avgDeliveryPrice === null) {
            avgDeliveryPrice = parseFloat(state.data.avg_delivery_price || 0)
        }

        if (amountUnit === null) {
            amountUnit = parseFloat(state.data.amount_unit || 1)
        }

        if (price === null) {
            price = parseFloat(state.data.actual_price || 0)
        }

        let pricePerUnit = removeExtraPrice(price, taxval) / amountUnit

        let profit = 0

        if (avgDeliveryPrice > 0) {
            profit = (pricePerUnit / avgDeliveryPrice - 1) * 100
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                profit_perc: profit
            }
        }))
    }

    const setMargin = (avgDeliveryPrice = null, amountUnit = null, price = null) => {

        let tax = getTaxGroupById(state.data.tax_id)

        let taxval = parseFloat(tax?.value || 0)

        if (avgDeliveryPrice === null) {
            avgDeliveryPrice = parseFloat(state.data.avg_delivery_price || 0)
        }

        if (amountUnit === null) {
            amountUnit = parseFloat(state.data.amount_unit || 1)
        }

        if (price === null) {
            price = parseFloat(state.data.actual_price || 0)
        }

        let pricePerUnit = removeExtraPrice(price, taxval) / amountUnit

        let perc = 0

        if (pricePerUnit > 0) {
            perc = ((pricePerUnit - avgDeliveryPrice) / pricePerUnit) * 100
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                margin_perc: perc
            }
        }))
    }

    const setActualPrice = (avgDeliveryPriceWithVat = null, profit = null) => {
        if (avgDeliveryPriceWithVat === null) {
            avgDeliveryPriceWithVat = parseFloat(state.data.avg_delivery_price_with_vat || 0)
        }

        if (profit === null) {
            profit = parseFloat(state.data.profit_perc || 0)
        }

        let price = addExtraPrice(avgDeliveryPriceWithVat, profit)

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                actual_price: price
            }
        }))
    }

    const handleCurrencyChange = e => {
        let id = e.target.value

        let currency = currencies.data.find(c => Number(c.id) === Number(id))

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                currency_id: id,
                currency: currency
            }
        }))
    }

    const handleActualPriceChange = e => {
        let value = e.target.value

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                actual_price: value,
            }
        }))

        setProfit(null, null, value)
        setMargin(null, null, value)
    }

    const handleProfitChange = e => {
        let value = e.target.value

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                profit_perc: value,
            }
        }))

        setActualPrice(null, value)
    }

    const handleTabChange = tabIndex => {
        setState(prev => ({
            ...prev,
            activeTabIndex: Number(tabIndex)
        }))
    }

    const handleShowAttach = e => {
        e.preventDefault()

        inputFileRef.current.click()
    }

    const handleAttach = e => {
        let files = [...e.target.files]

        files.forEach((f, i) => {
            let url = URL.createObjectURL(f)

            files[i].id = Math.random().toString(7).substring(2)
            files[i].was_recently_attached = true
            files[i].url = url
        })

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                files: prev.data.files.concat(files)
            }
        }))
    }

    const handleDetach = (id, index) => {

        let file = state.data.files.filter((f, i) => {
            return Number(f.id) === Number(id)
        })[0]

        if (!file.was_recently_attached) {
            Api.post('store/articles/delete-file', {
                id: id
            }).then(res => {
                if (res.data.success) {
                    removeFile(id)
                }
            })
        } else {
            removeFile(id)
        }

        if (validations?.files && validations.files[index]) {
            delete validations.files[index]
        }

        setValidations(validations)
    }

    const removeFile = id => {
        let files = state.data.files.filter((f, i) => {
            return Number(f.id) !== Number(id)
        })

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                files: files
            }
        }))
    }

    const handleSortFiles = items => {
        console.log(items)
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                files: items
            }
        }))
    }

    const handleIsForSaleChange = e => {
        const name = e.target.name

        // handleCheckboxChange(e);

        const checkForSale = (check) => {
            let storesdetails = cloneDeep(state.data.storesdetails)

            stores.map(store => {
                if (!storesdetails[store.id]) {
                    storesdetails[store.id] = {}
                };

                storesdetails[store.id].is_for_sale = check
            })

            return storesdetails
        }

        // ако е разерешена продажба да се маркира разрешена продажба и по магазини
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true,
                storesdetails: prev.data[name] ? checkForSale(false) : checkForSale(true),
            }
        }))
    }

    const handleStoreDetailChange = (storeId, name, value) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                storesdetails: {
                    ...prev.data.storesdetails,
                    [storeId]: {
                        ...prev.data.storesdetails[storeId],
                        [name]: prev.data.storesdetails[storeId] ? !prev.data.storesdetails[storeId][name] : true,
                    }
                }
            }
        }))
    }

    const setMainTab = key => {
        setState(prev => ({
            ...prev,
            mainTabId: key
        }))
    }

    const hasMainTabValidate = () => {
        let valid = false

        let main = [
            'article_name',
            'langs',
            'amount_type_id',
            'amount_unit',
            'tax_id',
            'currency_id',
            'avg_delivery_price',
            'avg_delivery_price_with_vat',
            'actual_price',
            'profit_perc',
            'ref_num',
            'barcode'
        ]

        if (validations) {
            main.map(t => {
                if (validations[t] && state.activeTabIndex !== 0) {
                    valid = true
                }
            })
        }

        return valid
    }

    const hasFilesTabValidate = () => {
        let valid = false

        let main = ['files']

        if (validations) {
            main.map(t => {
                if (validations[t] && state.activeTabIndex !== 2) {
                    valid = true
                }
            })
        }

        return valid
    }

    return (
        <>
            <div className="header purple">
                <b>
                    {state.data?.id ? 'Редакция' : 'Добавяне'} на артикул
                </b>
                <p>
                    Lorem Ipsum е елементарен примерен<br /> текст, използван в печатарската
                </p>
            </div>
            <div className="body">
                <div className="row">
                    <LeftSideMenu
                        tabsArr={[
                            {
                                name: 'Основни',
                                validate: hasMainTabValidate(),
                                icon: viewIcon
                            },
                            {
                                name: 'Допълнителни',
                                validate: false,
                                icon: moreIcon
                            },
                            {
                                name: 'Изображения',
                                validate: hasFilesTabValidate(),
                                icon: imagesIcon
                            }
                        ]}
                        activeTabIndex={state.activeTabIndex}
                        setActiveTabIndex={handleTabChange}
                    />
                    <div className="right-form-holder">
                        <Info
                            ref={validationModalRef}
                            mainMessage="Моля, попълнете всички задължителни полета, означени с червен цвят!"
                        />

                        <form ref={formRef} onSubmit={handleSave}>
                            {state.dataLoading
                                ?
                                <div style={{ padding: '150px 0' }}>
                                    <Loader />
                                </div>
                                :
                                <div className="row">
                                    <div className="col">
                                        <div style={state.activeTabIndex !== 0 ? { display: 'none' } : {}}>
                                            {state.data.master_id &&
                                                <Alert>
                                                    Този артикул е разновидност на артикул с ID {state.data.master_id} и въвеждането на някои от основните данни като име и описание не е задължително.
                                                    Ако някои от данните не бъдат въведени, ще се визаулизират данните на главния артикул.
                                                </Alert>
                                            }
                                            <div className="row">
                                                <div className="col">
                                                    <h6>
                                                        Основни
                                                    </h6>
                                                    <div className="field">
                                                        <div className="row">
                                                            <div className="col">
                                                                <RedditTextField
                                                                    size="small"
                                                                    variant="filled"
                                                                    margin="dense"
                                                                    fullWidth
                                                                    label="Име на артикул"
                                                                    name="article_name"
                                                                    value={state.data.article_name || ''}
                                                                    onChange={handleInputChange}
                                                                    error={Boolean(validations && validations.article_name)}
                                                                    helperText={validations && validations.article_name && (validations.article_name[0] || validations.article_name)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            {langs.data.map(lang =>
                                                                <Fragment key={lang.id}>
                                                                    <div className="col" >
                                                                        <RedditTextField
                                                                            size="small"
                                                                            variant="filled"
                                                                            margin="dense"
                                                                            fullWidth
                                                                            label={`Публично име ${lang.name}`}
                                                                            name={`langs[${lang.id}][name]`}
                                                                            value={state.data.langs[lang.id]?.name || ''}
                                                                            onChange={handleInputChange}
                                                                            error={Boolean(validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].name)}
                                                                            helperText={validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].name && (validations.langs[lang.id].name[0] || validations.langs[lang.id].name)}
                                                                        />
                                                                    </div>
                                                                    <div className="col" >
                                                                        <RedditTextField
                                                                            size="small"
                                                                            variant="filled"
                                                                            margin="dense"
                                                                            fullWidth
                                                                            label={`Подзаглавие ${lang.name}`}
                                                                            name={`langs[${lang.id}][subtitle]`}
                                                                            value={state.data.langs[lang.id]?.subtitle || ''}
                                                                            onChange={handleInputChange}
                                                                            error={Boolean(validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].subtitle)}
                                                                            helperText={validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].subtitle && (validations.langs[lang.id].subtitle[0] || validations.langs[lang.id].subtitle)}
                                                                        />
                                                                    </div>
                                                                </Fragment>
                                                            )}
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <RedditSelect
                                                                    name="amount_type_id"
                                                                    value={state.data.amount_type_id || ''}
                                                                    onChange={handleInputChange}
                                                                    label="Мерна единица"
                                                                    error={Boolean(validations && validations.amount_type_id)}
                                                                    helperText={validations && validations.amount_type_id && (validations.amount_type_id[0] || validations.amount_type_id)}
                                                                >
                                                                    {amountTypes.map(c =>
                                                                        <MenuItem key={c.id} value={c.id}>
                                                                            {c?.translation?.name || c?.translation?.short_name || c.name}
                                                                        </MenuItem>
                                                                    )}
                                                                </RedditSelect>
                                                            </div>
                                                            <div className="col">
                                                                <RedditTextField
                                                                    type="number"
                                                                    size="small"
                                                                    min="0"
                                                                    step="0.01"
                                                                    variant="filled"
                                                                    margin="dense"
                                                                    fullWidth
                                                                    label="Единица продажба"
                                                                    name="amount_unit"
                                                                    value={state.data.amount_unit || ''}
                                                                    onChange={handleInputChange}
                                                                    error={Boolean(validations && validations.amount_unit)}
                                                                    helperText={validations && validations.amount_unit && (validations.amount_unit[0] || validations.amount_unit)}
                                                                />
                                                            </div>
                                                            <div className="col">
                                                                <RedditSelect
                                                                    name="tax_id"
                                                                    value={state.data.tax_id || taxGroups[1]?.id || ''}
                                                                    onChange={handleTaxChange}
                                                                    label="ДДС група"
                                                                    error={Boolean(validations && validations.tax_id)}
                                                                    helperText={validations && validations.tax_id && (validations.tax_id[0] || validations.tax_id)}
                                                                >
                                                                    {taxGroups.map(c =>
                                                                        <MenuItem key={c.id} value={c.id}>
                                                                            {c.name}
                                                                        </MenuItem>
                                                                    )}
                                                                </RedditSelect>
                                                            </div>
                                                            <div className="col">
                                                                <RedditSelect
                                                                    name="currency_id"
                                                                    value={state.data.currency_id || ''}
                                                                    onChange={handleCurrencyChange}
                                                                    label="Валута"
                                                                    error={Boolean(validations && validations.currency_id)}
                                                                    helperText={validations && validations.currency_id && (validations.currency_id[0] || validations.currency_id)}
                                                                >
                                                                    {currencies.data.map(c =>
                                                                        <MenuItem key={c.id} value={c.id}>
                                                                            {c.name}
                                                                        </MenuItem>
                                                                    )}
                                                                </RedditSelect>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <RedditTextField
                                                                    type="number"
                                                                    size="small"
                                                                    min="0"
                                                                    step="0.01"
                                                                    variant="filled"
                                                                    margin="dense"
                                                                    fullWidth
                                                                    name="avg_delivery_price"
                                                                    value={state.data.avg_delivery_price || ''}
                                                                    onChange={handleInputChange}
                                                                    label="Себестойност без ДДС"
                                                                    error={Boolean(validations && validations.avg_delivery_price)}
                                                                    helperText={validations && validations.avg_delivery_price && (validations.avg_delivery_price[0] || validations.avg_delivery_price)}
                                                                />
                                                            </div>
                                                            <div className="col">
                                                                <RedditTextField
                                                                    type="number"
                                                                    size="small"
                                                                    min="0"
                                                                    step="0.01"
                                                                    variant="filled"
                                                                    margin="dense"
                                                                    fullWidth
                                                                    name="avg_delivery_price_with_vat"
                                                                    value={state.data.avg_delivery_price_with_vat || ''}
                                                                    label="Себестойност с ДДС"
                                                                    readOnly
                                                                    error={Boolean(validations && validations.avg_delivery_price)}
                                                                    helperText={validations && validations.avg_delivery_price && (validations.avg_delivery_price[0] || validations.avg_delivery_price)}
                                                                />
                                                            </div>
                                                            <div className="col">
                                                                <RedditTextField
                                                                    type="number"
                                                                    size="small"
                                                                    min="0"
                                                                    step="0.01"
                                                                    variant="filled"
                                                                    margin="dense"
                                                                    fullWidth
                                                                    name="actual_price"
                                                                    value={state.data.actual_price || ''}
                                                                    label="Продажна цена"
                                                                    onChange={handleActualPriceChange}
                                                                    error={Boolean(validations && validations.actual_price)}
                                                                    helperText={validations && validations.actual_price && (validations.actual_price[0] || validations.actual_price)}
                                                                />
                                                            </div>
                                                            <div className="col">
                                                                <RedditTextField
                                                                    type="number"
                                                                    size="small"
                                                                    min="0"
                                                                    step="0.01"
                                                                    variant="filled"
                                                                    margin="dense"
                                                                    fullWidth
                                                                    name="profit_perc"
                                                                    value={state.data.profit_perc || ''}
                                                                    label="Надценка (%)"
                                                                    onChange={handleProfitChange}
                                                                    error={Boolean(validations && validations.profit_perc)}
                                                                    helperText={validations && validations.profit_perc && (validations.profit_perc[0] || validations.profit_perc)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <RedditTextField
                                                                    size="small"
                                                                    variant="filled"
                                                                    margin="dense"
                                                                    fullWidth
                                                                    label="Референтен номер"
                                                                    name="ref_num"
                                                                    value={state.data.ref_num || ''}
                                                                    onChange={handleInputChange}
                                                                    error={Boolean(validations && validations.ref_num)}
                                                                    helperText={validations && validations.ref_num && (validations.ref_num[0] || validations.ref_num)}
                                                                />
                                                            </div>
                                                            <div className="col">
                                                                <RedditTextField
                                                                    size="small"
                                                                    variant="filled"
                                                                    margin="dense"
                                                                    fullWidth
                                                                    label="Общ номер"
                                                                    name="art_num"
                                                                    value={state.data.art_num || ''}
                                                                    onChange={handleInputChange}
                                                                    error={Boolean(validations && validations.art_num)}
                                                                    helperText={validations && validations.art_num && (validations.art_num[0] || validations.art_num)}
                                                                />
                                                            </div>
                                                            <div className="col">
                                                                <RedditTextField
                                                                    size="small"
                                                                    variant="filled"
                                                                    margin="dense"
                                                                    fullWidth
                                                                    label="Баркод"
                                                                    name="barcode"
                                                                    value={state.data.barcode || ''}
                                                                    onChange={handleInputChange}
                                                                    error={Boolean(validations && validations.barcode)}
                                                                    helperText={validations && validations.barcode && (validations.barcode[0] || validations.barcode)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <h6>
                                                        Описание
                                                    </h6>
                                                    <div className="field">
                                                        {langs.data.map(lang =>
                                                            <div key={lang.id} className="row">
                                                                <div className="col">
                                                                    <div
                                                                        style={{
                                                                            display: 'none'
                                                                        }}
                                                                    >
                                                                        <RedditTextField
                                                                            size="small"
                                                                            variant="filled"
                                                                            margin="dense"
                                                                            fullWidth
                                                                            label="Описание"
                                                                            name={`langs[${lang.id}][description]`}
                                                                            value={state.data.langs[lang.id]?.description || ''}
                                                                            onChange={handleInputChange}
                                                                            error={Boolean(validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].description)}
                                                                            helperText={validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].description && (validations.langs[lang.id].description[0] || validations.langs[lang.id].description)}
                                                                            multiline
                                                                            rows="3"
                                                                        />
                                                                    </div>
                                                                    <CKEditor
                                                                        editor={ClassicEditor}
                                                                        data={state.data.langs[lang.id]?.description || ''}
                                                                        onChange={(event, editor) => {
                                                                            const name = `langs[${lang.id}][description]`
                                                                            const value = editor.getData()
                                                                            setState(name, value, 'data')
                                                                        }}
                                                                    />
                                                                    {validations?.langs?.[lang?.id]?.description ?
                                                                        <div className='validate'>
                                                                            {validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].description && (validations.langs[lang.id].description[0] || validations.langs[lang.id].description)}
                                                                        </div>
                                                                        :
                                                                        <>
                                                                        </>
                                                                    }
                                                                </div>

                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={state.activeTabIndex !== 1 ? { display: 'none' } : {}}>
                                            <div className="row">
                                                <div className="col">
                                                    <h6>
                                                        Разрешения
                                                    </h6>
                                                    <div className="field">
                                                        <div className="row">
                                                            <div className="col">
                                                                <FormControlLabel
                                                                    control={
                                                                        <Switch
                                                                            style={{
                                                                                marginRight: "5px",
                                                                            }}
                                                                            checked={Number(state.data?.is_for_sale) === 1}
                                                                            color="primary"
                                                                            value={1}
                                                                            name="is_for_sale"
                                                                            onChange={handleIsForSaleChange}
                                                                        />
                                                                    }
                                                                    style={{
                                                                        marginLeft: 0
                                                                    }}
                                                                    label="Разреши продажба"
                                                                />
                                                            </div>
                                                        </div>
                                                        {stores.map(store =>
                                                            <div className="row" key={store.id} style={{ marginLeft: '25px' }}>
                                                                <div className="col" >
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Switch
                                                                                style={{
                                                                                    marginRight: "5px",
                                                                                }}
                                                                                checked={state.data.storesdetails && state.data.storesdetails[store.id] && Number(state.data.storesdetails[store.id].is_for_sale) === 1}
                                                                                color="primary"
                                                                                value={1}
                                                                                name={`storesdetails[${store.id}][is_for_sale]`}
                                                                                disabled={Number(state.data.is_for_sale) !== 1}
                                                                                onChange={() => {
                                                                                    handleStoreDetailChange(store.id, 'is_for_sale', 1)
                                                                                }}
                                                                            />
                                                                        }
                                                                        label={`Разреши продажба в ${store?.translation?.name || store.name}`}
                                                                        style={{
                                                                            marginLeft: 0
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className="row">
                                                            <div className="col">
                                                                <FormControlLabel
                                                                    control={
                                                                        <Switch
                                                                            style={{
                                                                                marginRight: "5px",
                                                                            }}
                                                                            checked={Number(state.data?.is_public) === 1}
                                                                            color="primary"
                                                                            value={1}
                                                                            name="is_public"
                                                                            onChange={handleCheckboxChange}
                                                                        />
                                                                    }
                                                                    label="Публичен артикул"
                                                                    style={{
                                                                        marginLeft: 0
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <FormControlLabel
                                                                    control={
                                                                        <Switch
                                                                            style={{
                                                                                marginRight: "5px",
                                                                            }}
                                                                            checked={Number(state.data?.is_service) === 1}
                                                                            color="primary"
                                                                            value={1}
                                                                            name="is_service"
                                                                            onChange={handleCheckboxChange}
                                                                        />
                                                                    }
                                                                    label="Услуга"
                                                                    style={{
                                                                        marginLeft: 0
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <h6>
                                                        Допълнителни
                                                    </h6>
                                                    <div className="field">
                                                        <div className="row">
                                                            <div className="col">
                                                                <RedditTextField
                                                                    type="number"
                                                                    size="small"
                                                                    min="0"
                                                                    step="0.01"
                                                                    variant="filled"
                                                                    margin="dense"
                                                                    fullWidth
                                                                    name="min_quantity"
                                                                    value={state.data.min_quantity || ''}
                                                                    label="Мин. наличност"
                                                                    onChange={handleInputChange}
                                                                    error={Boolean(validations && validations.min_quantity)}
                                                                    helperText={validations && validations.min_quantity && (validations.min_quantity[0] || validations.min_quantity)}
                                                                />
                                                            </div>
                                                            <div className="col">
                                                                <RedditTextField
                                                                    type="number"
                                                                    size="small"
                                                                    min="0"
                                                                    step="0.01"
                                                                    variant="filled"
                                                                    margin="dense"
                                                                    fullWidth
                                                                    name="max_quantity"
                                                                    value={state.data.max_quantity || ''}
                                                                    label="Макс. наличност"
                                                                    onChange={handleInputChange}
                                                                    error={Boolean(validations && validations.max_quantity)}
                                                                    helperText={validations && validations.max_quantity && (validations.max_quantity[0] || validations.max_quantity)}
                                                                />
                                                            </div>
                                                            <div className="col">
                                                                <RedditTextField
                                                                    size="small"
                                                                    variant="filled"
                                                                    margin="dense"
                                                                    fullWidth
                                                                    label="Код"
                                                                    name="code"
                                                                    value={state.data.code || ''}
                                                                    onChange={handleInputChange}
                                                                    error={Boolean(validations && validations.code)}
                                                                    helperText={validations && validations.code && (validations.code[0] || validations.code)}
                                                                />
                                                            </div>
                                                            <div className="col">
                                                                <RedditTextField
                                                                    size="small"
                                                                    variant="filled"
                                                                    margin="dense"
                                                                    fullWidth
                                                                    label="Интрастат"
                                                                    name="intrastat"
                                                                    value={state.data.intrastat || ''}
                                                                    onChange={handleInputChange}
                                                                    error={Boolean(validations && validations.intrastat)}
                                                                    helperText={validations && validations.intrastat && (validations.intrastat[0] || validations.intrastat)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={state.activeTabIndex !== 2 ? { display: 'none' } : {}}>
                                            <h6>
                                                Списък с изображения
                                            </h6>
                                            <div
                                                className="field"
                                                style={{
                                                    paddingBottom: '0'
                                                }}
                                            >
                                                <div className="row files sortable-list">
                                                    {state.data.files.map((file, index) =>
                                                        <SortItem
                                                            key={index}
                                                            items={state.data.files}
                                                            onSortItems={handleSortFiles}
                                                            sortId={index}
                                                            className="file-container"
                                                        >
                                                            <div className="file" draggable={false}>

                                                                {file.type.includes('video')
                                                                    ?
                                                                    <video src={file.url} onError={e => e.target.classList.add('hidden')} />
                                                                    :
                                                                    <img src={file.url} onError={e => e.target.classList.add('hidden')} />
                                                                }

                                                                <div className="buttons">
                                                                    <Button
                                                                        className="remove small"
                                                                        style={{
                                                                            width: '40px',
                                                                            border: '1px solid #fff',
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center'
                                                                        }}
                                                                        size="sm"
                                                                        onClick={e => handleDetach(file.id, index)}
                                                                    >
                                                                    </Button>
                                                                </div>
                                                            </div>

                                                            {validations?.files && validations.files[index] ?
                                                                <div className="validate">
                                                                    {validations.files[index][0] || validations.files[index]}
                                                                </div>
                                                                :
                                                                <>
                                                                </>
                                                            }

                                                            <input name={`files_position[${index}]`} value={file.was_recently_attached ? 0 : file.id} hidden readOnly />
                                                        </SortItem>
                                                    )}
                                                    <div
                                                        className="upload"
                                                        style={{
                                                            justifyContent: 'center',
                                                            width: 'calc(100% / 6 - 15px)'
                                                        }}
                                                    >
                                                        <img src={uploadIcon} />
                                                        <h5>
                                                            Изберете файл
                                                        </h5>
                                                        <input type="file" multiple name="file" ref={inputFileRef} onChange={handleAttach} accept="png,.jpeg,.jpg,.gif,.webp,.webm,.mp4" title="" />
                                                        <p>
                                                            Допустими формати: png, .jpeg, .jpg, .gif, .webp, .webm, .mp4
                                                        </p>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>


                                </div>
                            }
                        </form >
                    </div>
                </div >
            </div >
        </>
    )
}

export default forwardRef(Index)