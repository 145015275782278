import { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react'
import Api from 'helpers/Api'
import { useQuery } from 'helpers/Url'
import { useLocation, useHistory, Link, useParams } from 'react-router-dom'
import { Table, FloatingLabel, Form, Row, Col, Container, InputGroup } from 'react-bootstrap'
import { ArrowLeftCircle, TrashFill, ArrowLeftRight, Upload, Check2Square } from 'react-bootstrap-icons'
import { useNestedState } from 'helpers/NestedState'
import { useCurrencyContext } from 'providers/Currency'
import { useValidation } from 'helpers/Validation'
import { useAuthDataContext } from 'providers/Auth'
import { cloneDeep } from 'lodash'

import Question from 'components/modals/Question'
import Layout from 'components/Layout'
import CustomerDeepData from '../documents/partials/CustomerDeepData'
import axios from 'axios'

//misc
import Loader from 'components/misc/Loader'
import moment from 'moment'
import Autocomplete from 'components/misc/Dropdown'
import SaveButton from 'components/misc/Button'
import { isNumeric } from 'helpers/Price'
import Info from 'components/misc/Info'
import Error from 'components/modals/Error'
import DatePicker from 'components/partials/filter/DatePicker'
import Import from 'components/modals/Import'
import { TYPES } from 'constants/imports'
import Refs from 'Refs'
import { Button, MenuItem, InputAdornment, Menu } from '@material-ui/core'
import RedditTextField from 'components/misc/RedditTextField'
import RedditSelect from 'components/misc/RedditSelect'
import { useAppContext } from 'providers/App'
import PriceValue from 'components/partials/PriceValue'
import dropArrowIcon from 'assets/img/app/icons/drop-arrow-white.png'
import Skeleton from "react-loading-skeleton"
import ClientConfig from 'components/misc/ClientConfig'
import { showPdf } from './helpers'

let timeout

const DEALS = {
    SUBSCRIPTION: 1,
    CONTRACT: 2,
}

function Index({ noRedirect = false }, ref) {

    const params = useParams()
    const location = useLocation()
    const navigate = useHistory()
    const query = useQuery()

    const app = useAppContext()
    const auth = useAuthDataContext()
    const currencies = useCurrencyContext()

    const user = auth.getUser()

    const INITIAL_STATE = {
        loading: false,
        loadingBase: false,
        loadingClose: false,
        dataLoading: false,
        data: {
            rows: [{}],
            allow_modify: user.permission('storeouts_edit') ? true : false,
            allow_change_depots: user.permission('storeouts_edit') ? true : false,
            allow_close: user.permission('storeouts_edit') ? true : false,
            allow_send: user.permission('storeouts_between_barsys') ? true : false,
        },
        timeout: null,
        saveButtonMenu: null,
        showClientHiddenFields: false,
        addNote: false,
        onSuccess: null
    }

    const [state, setState] = useNestedState(INITIAL_STATE)

    const [availability, setAvailability] = useState([])
    const [depots, setDepots] = useState([])
    const [focusedInputName, setFocusedInputName] = useState(null)

    const [validations, setValidations] = useValidation()

    const formRef = useRef(null)
    const saveAndCloseModalRef = useRef(null)
    const saveAndSendModalRef = useRef(null)
    const errorModalRef = useRef(null)
    const importModalRef = useRef(null)
    const colNameRef = useRef(null)

    useImperativeHandle(ref, () => ({
        add: (subscriptionId, clientId) => {
            add(subscriptionId, clientId)
        },
        edit: (id) => {
            edit(id)
        },
        hide: () => {
            hide()
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        }
    }))

    const show = () => {
        setState(prev => ({
            ...prev,
            show: true,
        }))
    }

    const hide = () => {
        setState(() => ({ ...INITIAL_STATE }))
    }

    const add = (subscriptionId, clientId) => {
        show()

        setState(prev => ({
            ...prev,
            subscriptionId,
            clientId
        }))
    }

    const edit = (id) => {
        setState(prev => ({
            ...prev,
            id,
        }))

        show()
    }

    useEffect(() => {
        Api.get('store/storeouts/depots')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setDepots(res.data)

                    if (!state.id) {
                        if (res.data.length === 1) {
                            setState(prev => ({
                                ...prev,
                                data: {
                                    ...prev.data,
                                    depot_id: res.data[0].id
                                }
                            }))
                        }
                    }
                }
            })
    }, [auth.getUser().getStoreId()])

    useEffect(() => {
        if (state.id) {
            loadData()
        }
    }, [state.id, auth.getUser().getStoreId()])

    // зареждане на данни от поръчка/сметка
    useEffect(() => {
        if (state.id) {
            return
        }

        // // от зареждане
        // if (query.get('from_load_id')) {
        //     loadDataFromStoreLoad()
        // }

        // // от прехвърляне
        // if (query.get('from_move_id')) {
        //     loadDataFromStoreMove()
        // }

        // от абонамент
        if (state.subscriptionId) {
            loadDataFromSubscription()
        }

        if (state.clientId) {
            loadDataFromClient()
        }

    }, [state.id, state.subscriptionId, state.clientId])
    console.log(state.clientId)
    useEffect(() => {
        if (state.data.rows.length === 0) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    rows: [{}]
                }
            }))
        }
    }, [state.data.rows])

    useEffect(() => {
        getArticleAvailability()
    }, [state.data.depot_id])

    const loadData = () => {

        loading(true)

        let url = 'store/storeouts/show-edit-data'

        Api.get(url, {
            params: {
                id: state.id,
            }
        }).then(res => {
            handleResponse(res)
        }).catch(err => {
            handleError(err)
        }).finally(() => {
            loading(false)
        })
    }

    const loadDataFromStoreLoad = () => {

        loading(true)

        let url = 'store/storeouts/from-storeload'

        Api.get(url, {
            params: {
                load_id: query.get('from_load_id')
            }
        }).then(res => {
            handleResponse(res)
        }).catch(err => {
            handleError(err)
        }).finally(() => {
            loading(false)
        })
    }

    const loadDataFromStoreMove = () => {

        loading(true)

        let url = 'store/storeouts/from-storemove'

        Api.get(url, {
            params: {
                move_id: query.get('from_move_id')
            }
        }).then(res => {
            handleResponse(res)
        }).catch(err => {
            handleError(err)
        }).finally(() => {
            loading(false)
        })
    }

    const loadDataFromSubscription = () => {

        loading(true)

        let url = 'store/storeouts/from-subscription'

        Api.get(url, {
            params: {
                subscription_id: state.subscriptionId
            }
        }).then(res => {
            handleResponse(res)
        }).catch(err => {
            handleError(err)
        }).finally(() => {
            loading(false)
        })
    }

    const loadDataFromClient = () => {

        loading(true)

        let url = 'store/storeouts/from-client'

        Api.get(url, {
            params: {
                client_id: state.clientId
            }
        }).then(res => {
            handleResponse(res)
        }).catch(err => {
            handleError(err)
        }).finally(() => {
            loading(false)
        })
    }

    const handleResponse = res => {

        let data = res.data

        let rows = data.rows || []

        rows = rows.map(row => {
            //

            return row
        })

        data.rows = rows

        setState(prev => ({
            ...prev,
            data: data,
            addNote: !!data.description
        }))
    }

    const handleError = error => {
        console.log(error)

        const _err = error.response

        if (_err && _err.status) {
            if (_err.status === 422) {
                setValidations(_err.data.errors)
            } else {
                let modal = errorModalRef.current

                if (modal) {
                    modal.open(_err.data.error || _err.data.message)

                    modal.onClose(() => {
                        navigate.goBack()
                    })
                }
            }
        }
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            dataLoading: Boolean(loading)
        }))
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleCheckboxChange = e => {
        const name = e.target.name

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }))
    }

    const save = (close = false) => {
        if (state.loading) {
            return
        }

        setValidations(null)

        let url = state.id ? 'store/storeouts/edit' : 'store/storeouts/add'

        let form = formRef.current

        let data = new FormData(form)

        if (state.id) {
            data.append('id', state.id)
        }

        if (close) {
            data.append('close', 1)
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (!noRedirect) navigate.push('/storage/outs')

            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
            }

            hide()

            showPdf(res.data.id, auth.getToken())
        }).catch(error => {
            handleSaveError(error)
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
                loadingBase: false,
                loadingClose: false,
                loadingSend: false,
            }))
        })
    }

    const handleSave = e => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            loading: true,
            loadingBase: true,
        }))

        save(false)
    }

    const handleSaveAndClose = e => {
        e.preventDefault()

        let modal = saveAndCloseModalRef.current

        if (modal) {
            modal.open()

            modal.onSuccess(() => {
                setState(prev => ({
                    ...prev,
                    loading: true,
                    loadingClose: true,
                }))

                save(true)
            })
        }

    }

    const handleSaveError = error => {
        const _err = error.response

        if (_err && _err.status) {
            if (_err.status === 422) {
                setValidations(_err.data.errors)
            } else {
                let modal = errorModalRef.current

                if (modal) {
                    modal.open(_err.data.error || _err.data.message)
                }
            }
        }
    }

    const handleAddRow = e => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: prev.data.rows.concat({})
            }
        }))
    }

    const handleRemoveRow = (e, index) => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: prev.data.rows.filter((row, i) => i !== index)
            }
        }))
    }

    const handleChangeArticle = (data, index) => {

        let row = state.data.rows[index]

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: Object.values({
                    ...prev.data.rows,
                    [index]: {
                        ...prev.data.rows[index],
                        article: data,
                        article_id: data.id,
                        quantity: 1
                    }
                })
            }
        }))

        getArticleAvailability(data.id)

    }

    const getArticleAvailability = (id) => {

        let ids = []

        if (id) {
            ids = Array.isArray(id) ? id : [id]
        } else {
            ids = state.data.rows.map(r => r.article_id).filter(r => r)
        }

        let depotId = state.data.depot_id

        let depots = [depotId]

        let availabilityData = {}

        Api.get('store/articles/availability', {
            params: {
                id: ids,
                depot_id: depots
            }
        }).then(res => {
            res.data.map(data => {
                if (!availabilityData[data.article_id]) {
                    availabilityData[data.article_id] = {
                        quantity_from_depot_before: null,
                    }
                }

                if (Number(data.depot_id) === Number(depotId)) {
                    availabilityData[data.article_id].quantity_from_depot_before = data.quantity
                }

            })

            setAvailability(prev => ({
                ...prev,
                ...availabilityData
            }))

        })

    }

    // при смяна на количеството 
    const handleQtyChange = (e, index) => {
        handleInputChange(e)

        let value = e.target.value

    }

    const calcQuantityFromDepotAfter = (quantityBefore, quantity) => {
        return (parseFloat(quantityBefore) || 0) - parseFloat(quantity)
    }

    const getTotalQty = () => {
        let total = 0

        state.data.rows.map(row => {
            total += parseFloat(row.quantity || 0)
        })

        return total
    }

    const handleDepotChange = e => {
        let id = e.target.value

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                depot_id: id,
            }
        }))
    }

    const handleDealChange = data => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                deal_id: data?.id || null,
            }
        }))
    }

    const getQuantityFromDepotBefore = articleId => {
        return availability[articleId] && isNumeric(availability[articleId].quantity_from_depot_before) ? availability[articleId].quantity_from_depot_before : 0
    }

    const getQuantityFromDepotAfter = row => {
        return calcQuantityFromDepotAfter(getQuantityFromDepotBefore(row.article_id), row.quantity)
    }

    const handleChangeSubscription = data => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                subscription_id: data?.id || null,
                subscription: data
            }
        }))
    }

    const renderSubscriptionInputName = data => {
        if (!data) {
            return null
        }

        return `Абонамент ${data.id} - ${data.type?.name}`
    }

    const handleImport = () => {
        let modal = importModalRef.current

        if (!modal) {
            return
        }

        modal.open()
        // modal.setParams(state.data);

        let finished = false

        modal.onChunk(({ is_test, ready, type_id, results }) => {
            // console.log(data);

            // setState(prev => ({
            //     ...prev,
            //     data: {
            //         ...prev.data,
            //         rows: results
            //     }
            // }))

            // замяна или добавяне
            let erase = Number(type_id) === 1

            let rows

            if (erase) {
                rows = []
            } else {
                rows = cloneDeep(state.data.rows)
            }

            // console.log(erase)
            // console.log(rows)
            // console.log(results);

            results.map((row, index) => {
                // let data = row.article;

                rows.push(row)
            })

            // console.log(rows);

            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    rows: Object.values(rows)
                }
            }))

            // само, ако е завършил импорта
            // за да може да се изпълнят всички наведнъж
            if (ready) {
                // да се изпълни само веднъж
                if (finished === false) {
                    let ids = rows.map(r => r.article_id).filter(r => r)

                    getArticleAvailability(ids)
                }

                finished = true
            }
        })

        modal.onSuccess(() => {
            // alert(1);

        })
    }

    const showArticle = id => {
        let modal = Refs.getInstance().getRef('article')

        if (!modal) {
            return
        }

        modal.open(id)
    }

    const closeMenu = () => {
        setState(prev => ({
            ...prev,
            saveButtonMenu: null
        }))
    }

    const openMenu = e => {
        setState(prev => ({
            ...prev,
            saveButtonMenu: e.currentTarget
        }))
    }

    /**
    * 
    * @returns дали да се се покажат всички полета на клиента, след като се избере фирма
    */
    function showAllClientFields() {
        if (state.data?.customerinfo?.id) {
            if (!(state.data?.customer?.name || '').length && !(state.data?.customerinfo?.eikegn || '').length) {
                setState(prev => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        customerinfo: null,
                        customer: null,
                        customeraddress: null
                    }
                }))
            }
        }
        if (((state.data?.customer?.name || '').length || (state.data?.customerinfo?.eikegn || '').length) && state.showClientHiddenFields || state.data?.customerinfo?.id) {
            return true
        }

        return false
    }

    const clearCustomerData = () => {
        if (!state.data.customer) return
        setState(prev => ({
            ...prev,
            customer: null,
            customeraddress: null,
            costomerinfo: null
        }))
    }

    // customer
    const handleCustomerChange = (data, fromMain = false) => {
        // if (!data) return

        if (!showAllClientFields() && state.data.customer) clearCustomerData()

        if (fromMain && !Number.isNaN(Number(data))) return

        if (data && typeof data === 'object') {

            // let customer = data;
            let customer = cloneDeep(data)

            // не трябва да се подава id от търговския регистър
            if (customer.class === 'App\\Models\\TradeRegistry\\Company') {
                customer.id = null
            }

            let addresses = data.addresses.map((address, index) => {

                if (address.name === null || address.name.length === 0) {
                    address.name = `Офис ${index + 1}`
                }

                return address
            })

            customer.addresses = addresses
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    customer: customer,
                    customerinfo: customer.info,
                    customeraddress: null,
                }
            }))

            handleCustomerAddressChange(customer.addresses[0])
        } else {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    customer: {
                        id: prev?.data?.customer?.id,
                        name: data
                    },
                    // customerinfo: {
                    //     id: prev?.data?.customerinfo?.id,
                    //     eikegn: data
                    // }
                }
            }))
        }
    }

    const handleCustomerAddressChange = data => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                customeraddress: typeof data === 'object' && data !== null ? data : {
                    id: prev.data.customeraddress?.id,
                    name: data
                },
            }
        }))
    }

    function handleFocusToChangeBg(inputName) {
        setFocusedInputName(inputName)
    }

    const handleCustomerEikChange = data => {
        if (data && typeof data === 'object') handleCustomerChange(data)
    }

    /**
     * 
     * @returns дали ИН ПО ЗДДС е валиден
     */
    const isVatNumberValid = () => {
        let valid = {
            isValid: false,
            hasVat: false
        }
        if (String(state.data?.customerinfo?.vat_is_valid).length > 0) {
            valid = {
                ...valid,
                hasVat: true
            }
            if (state.data?.customerinfo?.vat_is_valid) {
                valid = {
                    ...valid,
                    isValid: true
                }
            }
        }

        return valid
    }

    const handleCustomerSettlementChange = data => {
        if (typeof data !== 'object' || Number(data?.id) !== Number(state.data?.customeraddress?.settlement?.id)) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    customeraddress: {
                        ...prev.data?.customeraddress,
                        area: null,
                        street: null,
                        street_no: null,
                        postcode: null
                    }
                }
            }))
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                customeraddress: {
                    ...prev.data?.customeraddress,
                    settlement: typeof data === 'object' ? data : {
                        id: null,
                        municipality_id: null,
                        name: data
                    },
                }
            }
        }))
    }

    const handleCheckCustomerVatNumber = () => {
        const vatNumber = state?.data?.customerinfo?.vat_number

        if (!vatNumber) return

        checkVies(vatNumber)
            .then(res => {
                if (res.valid) success('Фирмата е регистрирана по ЗДДС')
                else error('Фирмата не е регистрирана по ЗДДС')

                setState('customerinfo.vat_is_valid', res.valid, 'data')
            })
    }

    const checkVies = async number => {
        if (state.viesRequest) state.viesRequest.cancel()

        const viesRequest = axios.CancelToken.source()

        setState(prev => ({
            ...prev,
            viesRequest
        }))
        setVatLoading(true)

        return await Api.get('documents/check-vies', {
            params: {
                vat_number: number,
            },
            cancelToken: viesRequest.token
        })
            .then(res => res.data)
            .finally(() => {
                setVatLoading(false)
                renewCheckVat(false)
                setTimeout(() => {
                    renewCheckVat(true)
                }, 3000)
            })
    }

    const setVatLoading = vatLoading => {
        setState(prev => ({
            ...prev,
            vatLoading
        }))
    }

    const renewCheckVat = renewCheckVat => {
        setState(prev => ({
            ...prev,
            renewCheckVat
        }))
    }

    const handleShowClientHiddenFields = (showClientHiddenFields, data) => {
        setState(prev => ({
            ...prev,
            showClientHiddenFields
        }))

        let isEik = false
        if (data) {
            if (!Number.isNaN(Number(data))) {
                isEik = true
            }

            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    customerinfo: {
                        id: prev?.data?.customerinfo?.id,
                        eikegn: isEik ? data : '',
                        name: !isEik ? data : ''
                    }
                }
            }))
        }
    }

    const success = msg => {
        app.handleSuccess(msg)
    }

    const error = msg => {
        app.handleError(msg)
    }

    const handleAddNote = () => {
        setState(prev => ({
            ...prev,
            addNote: !prev.addNote
        }))
    }

    const handleChangeCustomerTradeObject = customeraddress => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                customeraddress
            }
        }))
    }


    return (
        <>
            <div className={`${state.show ? "visible" : ""} overlay`}>
                <div
                    className={`popup-primary extra-large complex-modal storage-load-form-modal cargo-modal store-out-modal ${state.show ? "show" : ""}`}
                >
                    <div className="header light-brown">
                        <b>
                            {state.id ? 'Редактиране на изписване' : 'Ново изписване'}
                        </b>
                        <p>
                            Lorem Ipsum е елементарен примерен<br /> текст, използван в печатарската
                        </p>
                    </div>
                    <div className="body">
                        {state.dataLoading
                            ?
                            <div style={{
                                width: '100%'
                            }}>
                                <Skeleton count={1} height={35} />
                                <Skeleton count={1} height={35} />
                                <Skeleton count={1} height={35} />
                                <Skeleton count={1} height={35} />
                                <Skeleton count={1} height={35} />
                            </div>
                            :
                            <>
                                <Form ref={formRef}>
                                    <div className="row">
                                        <div
                                            className="col client-holder"
                                            style={{
                                                maxWidth: '420px'
                                            }}
                                        >
                                            <h6
                                                style={{
                                                    display: 'flex'
                                                }}
                                            >
                                                Клиент
                                                {state.data?.customerinfo?.id ?
                                                    <ClientConfig
                                                        clientId={state.data?.customer?.id}
                                                        clientName={state.data?.customer?.info?.name}
                                                        clientEik={state.data?.customer?.info?.eikegn}
                                                        clientAddresses={state.data?.customer?.addresses}
                                                        activeClientAddressId={state.data?.customeraddress?.id}
                                                        handleChangeCustomerTradeObject={handleChangeCustomerTradeObject}
                                                    />
                                                    :
                                                    <></>
                                                }
                                            </h6>
                                            <div
                                                className="field"
                                                style={{
                                                    flex: 1,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center'
                                                }}
                                            >

                                                <div className="row">
                                                    {console.log(state.data)}
                                                    <div className={`col client ${showAllClientFields() ? 'fields-shown' : ''}`}>
                                                        <div className="panel">
                                                            <CustomerDeepData
                                                                showAllClientFields={showAllClientFields}
                                                                data={state.data}
                                                                validations={validations}
                                                                handleCustomerChange={handleCustomerChange}
                                                                handleFocusToChangeBg={handleFocusToChangeBg}
                                                                focusedInputName={focusedInputName}
                                                                handleCustomerEikChange={handleCustomerEikChange}
                                                                setState={setState}
                                                                state={state}
                                                                isVatNumberValid={isVatNumberValid}
                                                                handleInputChange={handleInputChange}
                                                                handleCustomerSettlementChange={handleCustomerSettlementChange}
                                                                handleCheckCustomerVatNumber={handleCheckCustomerVatNumber}
                                                                colNameRef={colNameRef}
                                                                handleShowClientHiddenFields={handleShowClientHiddenFields}
                                                                tradeRegistryReqUrl="store/customers/trade-registry"
                                                                customersFindReqUrl="store/customers/find"
                                                                noCompany={true}
                                                                autoFocus={true}
                                                                noTradeObject={true}
                                                                handleChangeCustomerTradeObject={handleChangeCustomerTradeObject}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <h6>
                                                Данни за изписването
                                            </h6>
                                            <div
                                                className="field"
                                                style={{
                                                    flex: 1,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    // justifyContent: 'center'
                                                }}
                                            >
                                                <div className="row" style={{
                                                }}>
                                                    <div className="col">
                                                        <RedditSelect
                                                            name="depot_id"
                                                            value={state.data.depot_id || ''}
                                                            onChange={handleDepotChange}
                                                            label="От склад"
                                                            disabled={state.data.allow_change_depots === false}
                                                            error={Boolean(validations?.depot_id)}
                                                            helperText={validations?.depot_id && (validations.depot_id[0] || validations.depot_id)}
                                                        >
                                                            {state.data.depot ?
                                                                <MenuItem value={state.data.depot_id}>
                                                                    {state.data.depot?.store?.translation?.name} - {state.data.depot?.translation?.name || state.data.depot.name}
                                                                </MenuItem>
                                                                :
                                                                depots.map(o =>
                                                                    <MenuItem key={o.id} value={o.id}>
                                                                        {o?.store?.translation?.name || o?.store?.name} - {o?.translation?.name || o.name}
                                                                    </MenuItem>
                                                                )}
                                                        </RedditSelect>
                                                    </div>
                                                    <div className="col">
                                                        <Autocomplete
                                                            inputPlaceholder="Причина"
                                                            variant="filled"
                                                            fullWidth
                                                            url="store/storeouts/deals"
                                                            inputName="deal"
                                                            inputIdName="deal_id"
                                                            inputValue={state.data.deal?.name || ''}
                                                            inputIdValue={state.data.deal_id || ''}
                                                            onChange={handleDealChange}
                                                            error={Boolean(validations && validations.deal)}
                                                            helperText={validations && validations.deal && (validations.deal[0] || validations.deal)}
                                                            size="sm"
                                                            listStyle={{
                                                                minWidth: 'max-content'
                                                            }}
                                                        />
                                                    </div>
                                                    {Number(state.data.deal_id) === DEALS.SUBSCRIPTION &&
                                                        <div className="col">
                                                            <Autocomplete
                                                                inputPlaceholder="Абонамент"
                                                                variant="filled"
                                                                fullWidth
                                                                url="store/storeouts/subscriptions"
                                                                params={{
                                                                    customer_id: state.data.customer?.id,
                                                                }}
                                                                requiredParams={['customer_id']}
                                                                inputIdName="subscription_id"
                                                                inputValue={renderSubscriptionInputName(state.data.subscription) || ''}
                                                                inputIdValue={state.data.subscription_id || ''}
                                                                onChange={handleChangeSubscription}
                                                                error={Boolean(validations && validations.subscription_id)}
                                                                helperText={validations && validations.subscription_id && (validations.subscription_id[0] || validations.subscription_id)}
                                                                size="sm"
                                                                renderText={data => (
                                                                    <div style={{ display: 'flex', gap: '10px' }}>
                                                                        <div style={{ width: '40px' }}>
                                                                            {data.id}
                                                                        </div>
                                                                        <div style={{ width: '100px' }}>
                                                                            {data.type?.name}
                                                                        </div>
                                                                        <div style={{ width: '100px', textAlign: 'right' }}>
                                                                            <PriceValue sufix="лв">{data.price}</PriceValue>
                                                                        </div>
                                                                        <div style={{ width: '100px', textAlign: 'right' }}>
                                                                            до {data.expires_at_dmy || '-'}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                renderInputText={renderSubscriptionInputName}
                                                                listStyle={{
                                                                    minWidth: 'max-content'
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                    {Number(state.data.deal_id) === DEALS.CONTRACT &&
                                                        <div className="col">
                                                            <RedditTextField
                                                                size="small"
                                                                variant="filled"
                                                                margin="dense"
                                                                fullWidth
                                                                label="Референция"
                                                                type="text"
                                                                name="ref"
                                                                value={state.data.ref || ''}
                                                                onChange={handleInputChange}
                                                                error={Boolean(validations && validations.ref)}
                                                                helperText={validations && validations.ref && (validations.ref[0] || validations.ref)}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <h6>
                                                Артикули
                                            </h6>
                                            <div className="field">
                                                <table className="type-doc">
                                                    <thead>
                                                        <tr>
                                                            <th
                                                                style={{
                                                                    maxWidth: '40px',
                                                                    width: '40px',
                                                                    minWidth: 'initial'
                                                                }}
                                                            >
                                                            </th>
                                                            <th style={{ maxWidth: '40px', width: '40px' }}>
                                                                №
                                                            </th>
                                                            <th style={{ width: '100%' }}>
                                                                Артикул
                                                            </th>
                                                            <th>
                                                                Текущо
                                                            </th>
                                                            <th>
                                                                Количество
                                                            </th>
                                                            <th>
                                                                След
                                                            </th>
                                                            <th >
                                                                Бележки
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {state.data.rows.map((row, index) =>
                                                            <tr key={'r-' + index}>
                                                                <td
                                                                    style={{
                                                                        paddingLeft: 0,
                                                                        maxWidth: '40px',
                                                                        width: '40px',
                                                                        minWidth: 'initial'
                                                                    }}
                                                                >
                                                                    <Button className='small remove' onClick={e => handleRemoveRow(e, index)}>
                                                                    </Button>

                                                                </td>
                                                                <td style={{ maxWidth: '40px', width: '40px' }}>
                                                                    {index + 1}

                                                                    <input type="hidden" name={`rows[${index}][id]`} value={state.data.rows[index].id || ''} />
                                                                </td>
                                                                <td>
                                                                    <Autocomplete
                                                                        fullWidth
                                                                        margin="none"
                                                                        size="small"
                                                                        variant="outlined"
                                                                        url="store/autocomplete/articles"
                                                                        inputName="article_name"
                                                                        inputIdName={`rows[${index}][article_id]`}
                                                                        inputValue={state.data?.rows[index] && state.data?.rows[index]?.article?.name ? state.data?.rows[index]?.article?.name : ''}
                                                                        inputIdValue={state.data?.rows[index] && state.data?.rows[index].article_id ? state.data?.rows[index].article_id : ''}
                                                                        onChange={data => handleChangeArticle(data, index)}
                                                                        error={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].article_id && (validations.rows[index].article_id[0] || validations.rows[index].article_id))}
                                                                        helperText={validations && validations.rows && validations.rows[index] && validations.rows[index].article_id && (validations.rows[index].article_id[0] || validations.rows[index].article_id)}
                                                                        renderText={data => {
                                                                            return (
                                                                                <div style={{ display: 'flex', width: '100%' }}>
                                                                                    <div style={{ width: 'max-content', marginRight: '10px' }}>
                                                                                        {data.id}
                                                                                    </div>
                                                                                    <div>
                                                                                        {data.name}
                                                                                        <br />
                                                                                        {data.article_name}
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }}
                                                                        renderInputText={data => data.article_name}
                                                                        listStyle={{
                                                                            minWidth: 'max-content'
                                                                        }}
                                                                        autoFocus
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <RedditTextField
                                                                        margin="none"
                                                                        size="small"
                                                                        variant="outlined"
                                                                        type="number"
                                                                        step="0.0001"
                                                                        fullWidth
                                                                        value={getQuantityFromDepotBefore(state.data?.rows[index].article_id)}
                                                                        disabled
                                                                        InputProps={state.data?.rows[index]?.article?.amount_type_short ? {
                                                                            endAdornment: <InputAdornment position="end">{state.data?.rows[index]?.article?.amount_type_short || '-'}</InputAdornment>,
                                                                        } : {}}
                                                                        className="quantity-input input-with-right-label"
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <RedditTextField
                                                                        margin="none"
                                                                        size="small"
                                                                        variant="outlined"
                                                                        type="number"
                                                                        step="0.0001"
                                                                        fullWidth
                                                                        name={`rows[${index}][quantity]`}
                                                                        value={state.data?.rows[index] && state.data?.rows[index].quantity ? state.data?.rows[index].quantity : ''}
                                                                        onChange={e => handleQtyChange(e, index)}
                                                                        error={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].quantity && (validations.rows[index].quantity[0] || validations.rows[index].quantity))}
                                                                        helperText={validations && validations.rows && validations.rows[index] && validations.rows[index].quantity && (validations.rows[index].quantity[0] || validations.rows[index].quantity)}
                                                                        InputProps={state.data?.rows[index]?.article?.amount_type_short ? {
                                                                            endAdornment: <InputAdornment position="end">{state.data?.rows[index]?.article?.amount_type_short || '-'}</InputAdornment>,
                                                                        } : {}}
                                                                        className="quantity-input input-with-right-label"
                                                                    />

                                                                </td>
                                                                <td>
                                                                    <RedditTextField
                                                                        margin="none"
                                                                        size="small"
                                                                        variant="outlined"
                                                                        type="number"
                                                                        step="0.0001"
                                                                        fullWidth
                                                                        value={getQuantityFromDepotAfter(state.data?.rows[index])}
                                                                        disabled
                                                                        InputProps={state.data?.rows[index]?.article?.amount_type_short ? {
                                                                            endAdornment: <InputAdornment position="end">{state.data?.rows[index]?.article?.amount_type_short || '-'}</InputAdornment>,
                                                                        } : {}}
                                                                        className="quantity-input input-with-right-label"
                                                                    />
                                                                </td>

                                                                <td style={{ width: '20%' }}>
                                                                    <RedditTextField
                                                                        margin="none"
                                                                        size="small"
                                                                        variant="outlined"
                                                                        step="0.0001"
                                                                        fullWidth
                                                                        type="text"
                                                                        name={`rows[${index}][notes]`}
                                                                        value={state.data?.rows[index] && state.data?.rows[index].notes ? state.data?.rows[index].notes : ''}
                                                                        onChange={handleInputChange}
                                                                        error={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].notes && (validations.rows[index].notes[0] || validations.rows[index].notes))}
                                                                        helperText={validations && validations.rows && validations.rows[index] && validations.rows[index].notes && (validations.rows[index].notes[0] || validations.rows[index].notes)}
                                                                        className="quantity-input input-with-right-label"
                                                                    />
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                <hr />
                                                <div className="row">
                                                    <div className="col">
                                                        <Button
                                                            className="small add"
                                                            onClick={handleAddRow}
                                                            style={{
                                                                marginBottom: '10px'
                                                            }}
                                                        >
                                                        </Button>
                                                        {state.addNote ?
                                                            <RedditTextField
                                                                size="small"
                                                                variant="filled"
                                                                margin="dense"
                                                                type="text"
                                                                fullWidth
                                                                label="Бележки"
                                                                name="description"
                                                                value={state.data?.description || ''}
                                                                onChange={handleInputChange}
                                                                error={Boolean(validations?.description)}
                                                                helperText={validations?.description && (validations.description[0] || validations.description)}
                                                                multiline
                                                                rows={3}
                                                                autoFocus={true}
                                                            />
                                                            :
                                                            <input hidden value={null} name="description" />
                                                        }
                                                        <span
                                                            className="link"
                                                            style={{
                                                                width: 'fit-content',
                                                                marginTop: '10px',
                                                            }}
                                                            onClick={handleAddNote}
                                                        >
                                                            {state.addNote ? 'Премахване' : 'Добавяне'} на забележка
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </>
                        }
                    </div>
                    <div className="footer">
                        <Menu
                            anchorEl={state.saveButtonMenu}
                            elevation={0}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            open={Boolean(state.saveButtonMenu)}
                            onClose={closeMenu}
                            onClick={closeMenu}
                        >
                            {state.data.allow_close ?
                                <MenuItem
                                    onClick={handleSaveAndClose}
                                >
                                    Запази и изпиши артикулите
                                </MenuItem>
                                :
                                <>
                                </>
                            }
                        </Menu>

                        <Button
                            className="cancel"
                            onClick={hide}
                        >
                            Отказ
                        </Button>

                        <SaveButton
                            className={`save ${state.data.allow_close ? 'with-dropdown' : ''}`}
                            loading={state.loadingBase}
                            disabled={state.loading}
                            onClick={handleSave}
                        >
                            Запази данните
                            {state.data.allow_close ?
                                <i
                                    className="menu"
                                    onClick={openMenu}
                                >
                                    <img src={dropArrowIcon} />
                                </i>
                                :
                                <>
                                </>
                            }
                        </SaveButton>
                    </div>
                </div>
            </div>
            <Question
                ref={saveAndCloseModalRef}
                mainMessage="Сигурни ли сте, че искате да затворите това изписване веднага?"
            />

            <Error
                ref={errorModalRef}
            />

            <Import
                ref={importModalRef}
                type={TYPES.STOREOUT}
                createText="Замяна"
                updateText="Добавяне"
            />
        </>
    )
}

export default forwardRef(Index)