const MenuIndicator = props => {
    const { active, style, iconColor } = props

    return (
        <div
            className={`custom-menu ${active ? 'active' : ''}`}
            style={style}
        >
            <span
                style={{
                    backgroundColor: iconColor
                }}
            >

            </span>
            <span
                style={{
                    backgroundColor: iconColor
                }}
            >

            </span>
            <span
                style={{
                    backgroundColor: iconColor
                }}
            >

            </span>
        </div>
    )
}

export default MenuIndicator