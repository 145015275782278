import { Link } from 'react-router-dom'

//MUI components
import Alert from '@material-ui/lab/Alert'

const NoSelectedCompany = props => {
    const { message } = props

    /**
     * 
     * @returns съобщение
     */
    const getMessage = () => {
        let msg   = <>Няма избрана фирма! Добавете фирма от <Link to="/companies" className="link">тук</Link> </>

        if (message) {
            msg   = message
        }

        return msg
    }

    return (
        <Alert severity="error">
            {getMessage()}
        </Alert>
    )
}

export default NoSelectedCompany