import { Fragment, useEffect, useRef, useState } from 'react';
import Api from 'helpers/Api';
import { buildSearchParams, useQuery } from 'helpers/Url';
import { useLocation, useHistory } from 'react-router-dom';
import { Table } from 'react-bootstrap';

import Options from './partials/Options';

import Question from 'components/modals/Question';
import Filter from './partials/Filter';
import Layout from 'components/Layout';
import View from './view/View';

//misc
import MenuOptions from 'components/misc/MenuOptions'
import Pagination from 'components/misc/Pagination'
import Loader from 'components/misc/Loader';
import NoDataFound from 'components/misc/NoDataFound';
import { useAuthDataContext } from 'providers/Auth';
import TableHeader from 'components/misc/TableHeader';
import Refs from 'Refs';
import TableOptions from 'components/misc/TableOptions';
import DynamicTableCol from 'components/partials/table/DynamicTableCol';
import DynamicTableBodyCol from 'components/partials/table/DynamicTableBodyCol';
import axios from 'axios';

import { usePresenceSocketChannel } from 'helpers/Socket';
import { useAppContext } from 'providers/App';
import moment from 'moment'

let timeout;

function Index() {

    const location = useLocation();
    const navigate = useHistory();
    const query = useQuery();
    const auth = useAuthDataContext();
    const app = useAppContext();

    const deleteModalRef = useRef(null);
    const closeModalRef = useRef(null);
    const viewModalRef = useRef(null);
    const tableRef = useRef(null);

    const [state, setState] = useState({
        loading: true,
        refresh: false,
        setFilter: false,
        data: {},
        totals: {},
        headings: {},
        types: {},
        pages: 0,
        total: 0,
        filter: {
            page: 1,
        },
        columns: {
            all: {},
            details: {},
            selected: [],
            sortable: [],
            sort: null,
            order: null,
        },
        tableKey: '',
        request: null,
        onDataLoaded: null
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: query.get('page') || 1,
                limit: query.get('limit') || '',
                search: query.get('search') || '',
                order_num: query.get('order_num') || '',
                status_id: query.has('status_id[]') ? query.getAll('status_id[]') : [],
                store_id: query.has('store_id[]') ? query.getAll('store_id[]') : [],
                from_store_id: query.has('from_store_id[]') ? query.getAll('from_store_id[]') : [],
                client_id: query.get('client_id') || '',
                article_id: query.get('article_id') || '',
                category_id: query.getAll('category_id[]') || [],
                created_from_date: query.get('created_from_date') || '',
                created_to_date: query.get('created_to_date') || '',
                delivery_from_date: query.get('delivery_from_date') || '',
                delivery_to_date: query.get('delivery_to_date') || '',
                creator_id: query.get('creator_id') || '',
                tel: query.get('tel') || '',
                speditor_id: query.getAll('speditor_id[]') || [],
                paymethod_id: query.getAll('paymethod_id[]') || [],
                notes: query.get('notes') || '',
                deleted: query.get('deleted') || '',
                sort: query.get('sort') || '',
                order: query.get('order') || ''
            },
            refresh: moment().unix()
        }))
    }, [location.search]);

    useEffect(() => {
        if (state.setFilter) {
            navigate.replace('?' + buildSearchParams(new URLSearchParams(), state.filter));
        }

    }, [state.setFilter]);

    useEffect(() => {
        if (state.refresh) {
            loadData();
        }
    }, [state.refresh]);

    useEffect(() => {
        refresh();
    }, [auth.getUser()?.getStoreId()]);

    usePresenceSocketChannel({
        channel: 'companies',
        channelId: auth?.getUser()?.getCompany()?.getId(),
        event: '.blackbox\\store\\events\\Order\\FullyCreated',
        onEvent: (payload) => {
            refresh(false);
        }
    }, [auth]);

    usePresenceSocketChannel({
        channel: 'companies',
        channelId: auth?.getUser()?.getCompany()?.getId(),
        event: '.blackbox\\store\\events\\Order\\Updated',
        onEvent: (payload) => {
            refresh(false);
        }
    }, [auth]);

    const loadData = () => {

        if (state.request) {
            state.request.cancel();
        }

        let request = axios.CancelToken.source();

        setState(prev => ({
            ...prev,
            request: request,
            loading: true,
        }));

        let url = 'store/orders/all';

        Api.get(url, {
            params: state.filter,
            cancelToken: request.token
        }).then(res => {

            if (typeof state.onDataLoaded === 'function') {
                state.onDataLoaded(res.data);
            }

            setState(prev => ({
                ...prev,
                currency: res.data.currency,
                data: res.data.items,
                total: res.data.total,
                pages: res.data.pages,
                totals: res.data.totals,
                headings: res.data.headings,
                types: res.data.types,
                columns: res.data.columns,
                tableKey: res.data.tableKey,
                loading: false,
                sorting: false,
                onDataLoaded: null,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                }
            }));

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 });
            }
        });
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }));
    }

    const sorting = (sorting) => {
        setState(prev => ({
            ...prev,
            sorting: Boolean(sorting)
        }));
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: reset ? 1 : prev.filter.page
            },
            setFilter: moment().unix(),
            refresh: moment().unix(),
        }));
    }

    const refreshTable = () => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                sort: '',
                order: '',
                page: 1,
                limit: '',
            },
            setFilter: moment().unix(),
            refresh: moment().unix(),
        }));
    }

    const handleAdd = () => {
        navigate.push('/orders/new');
    }

    // update
    const handleEdit = data => {
        if (data.deleted) {
            return;
        }

        navigate.push('/orders/' + data.order_id + '/edit');
    }

    // delete
    const handleDelete = data => {
        let modal = deleteModalRef.current;

        modal.open();
        modal.onSuccess(() => {
            handleDestroy(data.order_id);
        })
    }

    const handleDestroy = id => {
        Api.post('store/orders/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                refresh(false);
            }
        });
    }

    const handleRestore = data => {
        Api.post('store/orders/restore', {
            id: data.order_id
        }).then(res => {
            if (res.data.success) {
                refresh(false);
            }
        });
    }

    const handleShow = id => {
        let modal = viewModalRef.current;

        modal.open(id, false);
    }

    const handleView = data => {
        let modal = viewModalRef.current;

        modal.open(data.order_id);
    }

    // close
    const handleClose = data => {
        let modal = closeModalRef.current;

        modal.open();
        modal.onSuccess(() => {
            close(data.order_id);
        })
    }

    const close = id => {
        Api.post('store/orders/close', {
            id: id
        }).then(res => {
            if (res.data.success) {
                refresh(false);
            }
        });
    }

    // Search
    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout);

        if (typeof key === 'object') {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    ...key
                },
            }));
        } else {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    [key]: val,
                },
            }));
        }

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                setFilter: moment().unix()
            }));
        }, delay);
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            setFilter: moment().unix()
        }));
    }

    const handleSort = (sort, order) => {
        setState(prev => ({
            ...prev,
            sorting: true,
            filter: {
                ...prev.filter,
                sort: sort,
                order: order,
                page: 1,
            },
            setFilter: moment().unix()
        }));
    }

    const showAccount = id => {
        if (auth.getUser().permission('accounts_account_orders') === false) {
            return app.showError('Нямате право да преглеждате сметка!');
        }

        let modal = Refs.getInstance().getRef('account');

        if (!modal) {
            return;
        }

        modal.open(id);
    }

    const showNextItem = (id) => {
        let table = tableRef.current;

        if (!table) {
            return;
        }

        // console.log(table);

        let thisRow = table.querySelector('tr[data-id="' + id + '"]');
        let nextRow = thisRow.nextSibling;

        if (nextRow && nextRow.getAttribute('data-id')) {
            showItem(
                nextRow.getAttribute('data-id')
            );
        } else {
            showNextPage();
        }
    }

    const showPrevItem = (id) => {
        let table = tableRef.current;

        if (!table) {
            return;
        }

        // console.log(table);

        let thisRow = table.querySelector('tr[data-id="' + id + '"]');
        let prevRow = thisRow.previousSibling;

        if (prevRow) {
            showItem(
                prevRow.getAttribute('data-id')
            );
        } else {
            showPrevPage();
        }
    }

    const showNextPage = () => {
        if (state.pages > state.filter.page) {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: Number(state.filter.page) + 1,
                },
                onDataLoaded: data => {
                    setTimeout(() => {
                        showItem(data.items[0].order_id);
                    }, 0)
                },
                setFilter: moment().unix(),
            }));
        }
    }

    const showPrevPage = () => {
        if (state.filter.page > 1) {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: Number(state.filter.page) - 1,
                },
                onDataLoaded: data => {
                    setTimeout(() => {
                        showItem(data.items[data.items.length - 1].order_id);
                    }, 0)
                },
                setFilter: moment().unix(),
            }));
        }
    }

    const showItem = id => {
        let table = tableRef.current;

        if (!table) {
            return;
        }

        let row = table.querySelector('tr[data-id="' + id + '"]');

        if (row) {
            row.click();

            handleShow(id);
        }
    }

    const handleLimitChange = limit => {
        handleSearch('limit', limit, 0);
    }

    return (
        <>

            <Question
                ref={deleteModalRef}
                mainMessage="Сигурни ли сте?"
                agreeBtnText="Изтриване"
                agreeBtnClass="remove"
            />

            <Question
                ref={closeModalRef}
                mainMessage="Сигурни ли сте, че искате да затворите това зареждане?"
                agreeBtnText="Да"
            />

            <View
                ref={viewModalRef}
                showNextItem={id => showNextItem(id)}
                showPrevItem={id => showPrevItem(id)}
            />

            <Layout>
                <div className="container-fluid">

                    <div className="head-with-heading">
                        <h3>Клиентски заявки</h3>

                        <div className="buttons">
                            {/* <Button className="add" onClick={handleAdd} disabled={auth.getUser().permission('clientorders_edit') === false}>
                                Нова заявка
                            </Button> */}
                        </div>
                    </div>

                    <div className="panel" style={{
                        marginTop: 0
                    }}>

                        <div className="filter-holder">
                            <Filter
                                filter={state.filter}
                                handleSearch={handleSearch}
                            />
                        </div>

                        {state.loading && !state.sorting
                            ?
                            <Loader />
                            :
                            state.data.length === 0 ?
                                <NoDataFound />
                                :
                                <>
                                    <Table className="type-outer" responsive striped hover ref={tableRef}>
                                        <TableHeader
                                            tableRef={tableRef}
                                            activeSortKey={state.filter.sort}
                                            activeSortDir={state.filter.order}
                                            onSort={(col, dir) => handleSort(col, dir)}
                                        >
                                            {Object.entries(state.headings).map((heading, i) =>
                                                <DynamicTableCol
                                                    key={i}
                                                    type={state.types[heading[0]]}
                                                    name={heading[1]}
                                                    title={state.columns.description[heading[0]]}
                                                    sortKey={heading[0]}
                                                    sortable={state.columns.sortable.indexOf(heading[0]) > -1}
                                                />
                                            )}
                                            <th className="options">
                                                Опции
                                            </th>
                                        </TableHeader>
                                        <tbody>
                                            {state.data.map(c =>
                                                <tr
                                                    key={'c-' + c.id}
                                                    className={c.deleted ? 'deleted' : ''}
                                                    data-id={c.order_id}
                                                >
                                                    {Object.entries(state.headings).map((heading, i) =>
                                                        heading[0] === 'id'
                                                            ?
                                                            <td key={heading[0]}>
                                                                <span className="link" onClick={e => handleView(c)}>
                                                                    {c[heading[0]]}
                                                                </span>
                                                            </td>
                                                            :
                                                            heading[0] === 'status'
                                                                ?
                                                                <td key={heading[0]} className={`text-center`} style={{ backgroundColor: c.status_data?.color || '' }}>
                                                                    <span style={{ color: c.status_data?.color || '#000', mixBlendMode: 'color-dodge' }}>
                                                                        {c?.status_data?.translation?.name || c?.status_data?.name}
                                                                    </span>
                                                                </td>
                                                                :
                                                                heading[0] === 'accounts'
                                                                    ?
                                                                    <td key={heading[0]}>
                                                                        {c.accounts.map((account, i) =>
                                                                            <Fragment key={account.id} >
                                                                                <span className="link" onClick={e => showAccount(account.id)}>{account.id}</span>{c.accounts.length - 1 > i && ', '}
                                                                            </Fragment>
                                                                        )}
                                                                    </td>
                                                                    :
                                                                    <DynamicTableBodyCol
                                                                        key={heading[0]}
                                                                        type={state.types[heading[0]]}
                                                                        name={c[heading[0]]}
                                                                        data={c}
                                                                        currency={state.currency}
                                                                        onClick={e => handleView(c)}
                                                                    />
                                                    )}
                                                    <td className="options">
                                                        <MenuOptions>
                                                            <Options
                                                                data={c}
                                                                handleEdit={handleEdit}
                                                                handleDelete={handleDelete}
                                                                handleRestore={handleRestore}
                                                                handleView={handleView}
                                                            />
                                                        </MenuOptions>
                                                    </td>
                                                </tr>
                                            )}
                                            {state.totals.map((c, i) =>
                                                <tr key={i} className="total">
                                                    {Object.entries(c.items).map((heading, i) =>
                                                        <DynamicTableBodyCol
                                                            key={heading[0]}
                                                            type={state.types[heading[0]]}
                                                            name={heading[1]}
                                                            data={c.items}
                                                            currency={state.currency}
                                                            colspan={c.colspan[heading[0]]}
                                                        />
                                                    )}
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>

                                    <Pagination
                                        className="mt-3"
                                        page={state.filter.page}
                                        pages={state.pages}
                                        total={state.total}
                                        handlePage={handlePage}
                                        limit={state.filter.limit}
                                        onLimitChange={handleLimitChange}
                                    />
                                </>
                        }

                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Index;