import { forwardRef, useImperativeHandle } from "react";
import { Form } from "react-bootstrap";

function File(props, ref) {

    useImperativeHandle(ref, () => ({
        getName: getName,
        getType: getType,
        getTitle: getTitle,
        hasSettings: hasSettings,
        getSettings: getSettings,
        getDefaultOptions: ({ createOrUpdate }) => ({
            delimiter: '@',
            erase: 0,
        })
    }));

    const getName = () => {
        return 'Снимки';
    }

    const getType = () => {
        return 'file';
    }

    const getTitle = () => {
        return 'URL път(ища) на снимка(и) за артикула';
    }

    const hasSettings = () => true;

    const getSettings = ({ state, setConfig }) => {
        return (
            <div>
                <label>
                    Разделител:
                </label>
                <Form.Control
                    type="text"
                    size="sm"
                    value={state.options?.delimiter || ''}
                    onChange={e => setConfig('delimiter', e.target.value)}
                />

                <br />

                <Form.Check
                    id={'cb-erase'}
                    label="Премахване на текущите"
                    checked={Number(state.options?.erase) === 1}
                    onChange={e => setConfig('erase', e.target.checked ? 1 : 0)}
                />
            </div>
        );
    }

    return getName();
}

export default forwardRef(File)