import { useState, useEffect, useRef } from 'react'
import { Card, Col, Row, Table } from 'react-bootstrap'
import Api from 'helpers/Api'
import Loader from 'components/misc/Loader'
import moment from 'moment'
import { Link, useHistory } from 'react-router-dom'
import FilePreview from './FilePreview'
import PriceValue from 'components/partials/PriceValue'
import SaveButton from 'components/misc/Button'
import Refs from 'Refs'

import { Button } from '@material-ui/core'

//hooks
import useInvoiceFromClient from 'hooks/useInvoiceFromClient'
import useFetchClientStore from '../hooks/useFetchClientStore'

//images
import requestIcon from 'assets/img/customers/icons/request.png'
import cardIcon from 'assets/img/customers/icons/card.png'
import invoiceIcon from 'assets/img/accounts/icons/invoice.png'
import billIcon from 'assets/img/accounts/icons/bill.png'
import paymentIcon from 'assets/img/customers/icons/payment.png'

//providers
import { useAuthDataContext } from 'providers/Auth'

function Index(props) {
    const { getUser } = useAuthDataContext()

    const navigate = useHistory()

    const { createInvoiceFromClient, isLoading } = useInvoiceFromClient()
    const { getClient, getClientAcountsTotal, getClientCurrency, loadingClient } = useFetchClientStore(props.id)

    const handleCreateAccount = () => {
        props.hide()

        navigate.push('/accounts/new?client_id=' + getClient()?.id)
    }

    const handleCreateInvoice = () => {
        createInvoiceFromClient(getClient()?.id)
            .then(res => {
                if (res) {
                    props.hide()

                    navigate.push("/invoices", {
                        data: res,
                        mode: "new",
                    })
                }
            })
    }

    const handleCreatePayment = () => {
        let modal = Refs.getInstance().getRef('newPayment')

        if (!modal) {
            return
        }

        props.hide(() => {
            modal.forClient(getClient())
        })

        modal.onClose(() => {
            props.show()
        })
    }

    const handleCreatePackageCard = () => {
        let modal = Refs.getInstance().getRef('newPackageCard')

        if (!modal) {
            return
        }

        props.hide(() => {
            modal.forClient(getClient())
        })

        modal.onClose(() => {
            props.show()
        })
    }

    const handleCreateOrder = () => {
        props.hide()

        navigate.push('/orders/new?client_id=' + getClient()?.id)
    }

    const handleShowAccounts = () => {
        props.hide()

        // за всички каси...
        navigate.push('/accounts?client_id=' + getClient()?.id + '&cashdesk_id[]=')
    }

    const handleShowOrders = () => {
        props.hide()

        navigate.push('/orders?client_id=' + getClient()?.id)
    }

    const handleShowInvoices = () => {
        props.hide()

        navigate.push('/invoices', {
            client_id: getClient()?.id
        })

        // navigate.push('/invoices?client_id=' + getClient()?.id)
    }

    const handleShowContracts = () => {
        props.hide()

        navigate.push('/contracts?client_id=' + getClient()?.id)
    }

    const handleShowWarranties = () => {
        props.hide()

        navigate.push('/warranty?client_id=' + getClient()?.id)
    }

    const handleShowProtocols = () => {
        props.hide()

        navigate.push('/protocols?client_id=' + getClient()?.id)
    }

    return (

        loadingClient
            ?
            <Loader />
            :
            <>
                <div className="preview-article-home">
                    <div className="options-bar">
                        <Button className="cancel" onClick={handleCreateAccount}>
                            <img src={billIcon} />
                            Нова сметка
                        </Button>
                        {/* <Button className="cancel" onClick={handleCreatePayment}>
                            <img src={paymentIcon} />
                            Ново плащане
                        </Button> */}
                        <Button disabled={isLoading} className="cancel" onClick={handleCreateInvoice}>
                            <img src={invoiceIcon} />
                            Нова фактура
                        </Button>
                        {/* <Button className="cancel" onClick={handleCreatePackageCard}>
                            <img src={cardIcon} />
                            Нова пакетна карта
                        </Button> */}
                        {/* <Button className="cancel" onClick={handleCreateOrder}>
                            <img src={requestIcon} />
                            Нова клиентска заявка
                        </Button> */}
                    </div>
                    <div className="row">
                        <div className="col">
                            <h6>
                                Основни данни
                            </h6>
                            <div className="field">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="th">
                                                Клиент
                                            </td>
                                            <td>
                                                {getClient()?.info?.name || '-'}
                                            </td>
                                            <td className="th">
                                                ДДС номер
                                            </td>
                                            <td>
                                                {getClient()?.info?.vat_number || '-'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="th">
                                                Телефон
                                            </td>
                                            <td>
                                                {getClient()?.info?.phone || '-'}
                                            </td>
                                            <td className="th">
                                                Е-поща
                                            </td>
                                            <td>
                                                {getClient()?.info?.email || '-'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="th">
                                                Дата на създаване
                                            </td>
                                            <td>
                                                {getClient()?.info?.created_at ? moment(getClient()?.info?.created_at).format('DD.MM.YYYY HH:mm') : '-'}
                                            </td>
                                            <td className="th">
                                                Последна актуализация
                                            </td>
                                            <td>
                                                {getClient()?.info?.updated_at ? moment(getClient()?.info?.updated_at).format('DD.MM.YYYY HH:mm') : '-'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="th">
                                                Шифър в Ажур
                                            </td>
                                            <td colSpan={3}>
                                                {getClient()?.ajur_id || '-'}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {getUser().hasStoreAccess() ?
                            <div className="col">
                                <h6>
                                    Оборот за разрешените обекти
                                </h6>
                                <div
                                    className="field"
                                    style={{
                                        flex: 1,
                                    }}
                                >
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="th">
                                                    Тотал
                                                </td>
                                                <td colSpan={3}>
                                                    <PriceValue prefix={getClientCurrency()?.prefix} sufix={getClientCurrency()?.sufix}>{getClientAcountsTotal()?.sum}</PriceValue>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="th">
                                                    Общо отстъпки
                                                </td>
                                                <td colSpan={3}>
                                                    <PriceValue prefix={getClientCurrency()?.prefix} sufix={getClientCurrency()?.sufix}>{getClientAcountsTotal()?.discount}</PriceValue>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="th">
                                                    Общо оборот
                                                </td>
                                                <td colSpan={3}>
                                                    <PriceValue prefix={getClientCurrency()?.prefix} sufix={getClientCurrency()?.sufix}>{getClientAcountsTotal()?.real}</PriceValue>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="th">
                                                    Брой продажби
                                                </td>
                                                <td colSpan={3}>
                                                    {getClientAcountsTotal()?.count}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="th">
                                                    Първа продажба
                                                </td>
                                                <td colSpan={3}>
                                                    {getClientAcountsTotal()?.first_account_date ? moment(getClientAcountsTotal()?.first_account_date).format('DD.MM.YYYY HH:mm') : '-'}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="th">
                                                    Последна продажба
                                                </td>
                                                <td colSpan={3}>
                                                    {getClientAcountsTotal()?.latest_account_date ? moment(getClientAcountsTotal()?.latest_account_date).format('DD.MM.YYYY HH:mm') : '-'}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            :
                            <>
                            </>
                        }
                        <div className="col">
                            <h6>
                                Справки
                            </h6>
                            <div
                                className="field"
                                style={{
                                    flex: 1,
                                }}
                            >
                                <ul
                                    className="list-of-links"
                                    style={{
                                        margin: '-10px'
                                    }}
                                >
                                    {getUser().hasStoreAccess() ?
                                        <li onClick={handleShowAccounts}>
                                            Продажби
                                        </li>
                                        :
                                        <>
                                        </>
                                    }
                                    {/* <li onClick={handleShowOrders}>
                                        Клиентски поръчки
                                    </li> */}
                                    <li onClick={handleShowInvoices}>
                                        Фактури
                                    </li>
                                    {/* <li onClick={handleShowContracts}>
                                        Договори
                                    </li>
                                    <li onClick={handleShowWarranties}>
                                        Гаранции
                                    </li>
                                    <li onClick={handleShowProtocols}>
                                        Приемо-пред. протоколи
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    )
}

export default Index