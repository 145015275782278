import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import { Modal, Button, Tabs, Tab, FloatingLabel, Form, Row, Col } from 'react-bootstrap';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useLanguageContext } from 'providers/Language'

//misc
import SaveButton from 'components/misc/Button'
import Info from 'components/modals/Info';
import moment from 'moment'

function AddOrEdit(props, ref) {

    const langs = useLanguageContext();

    const defaultData = {
        langs: {},
        file: null,
    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        edit: false,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
        parent_id: null
    });

    const [validations, setValidations] = useValidation();

    const validationModalRef = useRef(null);
    const inputFileRef = useRef(null);

    useImperativeHandle(ref, () => ({
        add: (id) => {
            handleShow(id);
        },
        edit: (id) => {
            edit(id);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        }
    }));

    useEffect(() => {
        loadData();
    }, [state.edit]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = (id) => {
        setState(prev => ({
            ...prev,
            data: defaultData,
            parent_id: id,
            id: null,
        }));

        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }));

        setValidations(null);

        hide();
    }

    const edit = id => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: moment().unix()
        }));

        show();
    }

    const loadData = () => {
        if (!state.id) {
            return;
        }

        Api.get('store/categories/edit?id=' + state.id)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data
                }))
            });
    }


    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = state.id ? 'store/categories/update' : 'store/categories/add';

        let data = new FormData(e.target);

        if (state.id) {
            data.append('id', state.id)
        }

        if (state.parent_id && !state.id) {
            data.append('parent_id', state.parent_id)
        }

        if (state.data.file?.was_recently_attached) {
            data.append('image', state.data.file);
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
            }

            hide()
        }).catch(error => {
            const _err = error.response;
            if (_err && _err.status && _err.status === 422) {
                hide(() => {
                    let modal = validationModalRef.current;

                    if (modal) {
                        modal.open();

                        modal.onClose(() => {
                            setValidations(_err.data.errors)
                            show();
                        });
                    }
                });
            }
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleDateChange = e => {
        const name = e.target.name + '_ymd'
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleShowAttach = e => {
        e.preventDefault();

        inputFileRef.current.click();
    }

    const handleAttach = e => {
        let file = e.target.files[0];
        let url = URL.createObjectURL(file);

        file.id = Math.random().toString(7).substring(2);
        file.was_recently_attached = true;
        file.url = url;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                file: file
            }
        }));
    }

    const handleDetach = e => {
        let file = state.data.file;

        if (!file.was_recently_attached) {
            Api.post('store/categories/remove-file', {
                id: state.data.id
            }).then(res => {
                if (res.data.success) {
                    removeFile();
                }
            });
        } else {
            removeFile();
        }

        if (validations?.image) {
            delete validations.image;
        }

        setValidations(validations);
    }

    const removeFile = id => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                file: null
            }
        }));
    }


    return (
        <>
            <Info
                ref={validationModalRef}
                mainMessage="Моля, попълнете всички задължителни полета, означени с червен цвят!"
            />
            <Modal centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Категория
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Tabs
                            defaultActiveKey={langs.data[0].id}
                            className="mb-3"
                            transition={false}
                        >
                            {langs.data.map(lang =>
                                <Tab key={lang.id} eventKey={lang.id} title={lang.name} tabClassName={validations && validations.langs && validations.langs[lang.id] ? 'validate' : ''}>
                                    <FloatingLabel label="Заглавие" className="mb-3">
                                        <Form.Control
                                            type="text"
                                            name={`langs[${lang.id}][name]`}
                                            placeholder="Заглавие"
                                            value={state.data.langs[lang.id]?.name || ''}
                                            onChange={(e) => handleInputChange(e)}
                                            isInvalid={Boolean(validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].name)}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].name && (validations.langs[lang.id].name[0] || validations.langs[lang.id].name)}
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                    <FloatingLabel label="Описание">
                                        <Form.Control
                                            as="textarea"
                                            name={`langs[${lang.id}][description]`}
                                            placeholder="Описание"
                                            onChange={(e) => handleInputChange(e)}
                                            value={state.data.langs[lang.id]?.description || ''}
                                            style={{ height: '100px' }}
                                            isInvalid={Boolean(validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].description)}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].description && (validations.langs[lang.id].description[0] || validations.langs[lang.id].description)}
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                    <Row className="mt-3">
                                        <Col>
                                            <FloatingLabel label="Публичен URL" className="mb-3">
                                                <Form.Control
                                                    type="text"
                                                    name={`langs[${lang.id}][slug]`}
                                                    placeholder="Публичен URL"
                                                    value={state.data.langs[lang.id]?.slug || ''}
                                                    onChange={(e) => handleInputChange(e)}
                                                    isInvalid={Boolean(validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].slug)}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].slug && (validations.langs[lang.id].slug[0] || validations.langs[lang.id].slug)}
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                </Tab>
                            )}
                        </Tabs>

                        <Row>
                            <Col>
                                {state.data.file &&
                                    <div className="mb-3">
                                        <>
                                            <div className="file">
                                                <img style={{ maxHeight: '100px', objectFit: 'contain' }} src={state.data.file?.url} onError={e => e.target.classList.add('hidden')} />
                                                <br />
                                                <span>
                                                    {state.data.file?.name}
                                                </span>
                                            </div>

                                            <div className="validate">
                                                {validations?.image}
                                            </div>
                                        </>
                                    </div>
                                }

                                <Button variant="outline-dark" size="sm" onClick={handleShowAttach}>
                                    Прикачете изображение
                                </Button>

                                {state.data.file &&
                                    <Button variant="outline-danger" size="sm" onClick={handleDetach} style={{ marginLeft: '5px' }}>
                                        Премахнете изображението
                                    </Button>
                                }

                                <input ref={inputFileRef} type="file" accept="image/*" onChange={handleAttach} hidden />
                            </Col>
                        </Row>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>

    )
}

export default forwardRef(AddOrEdit);
