import { Dropdown } from 'react-bootstrap';
import { Boxes, InfoCircle } from 'react-bootstrap-icons';

function Options(props) {
    return (
        <>
            <Dropdown.Item onClick={e => props.handleShowArticle(props.data.id)}>
                <InfoCircle /> Преглед на артикула
            </Dropdown.Item>
        </>
    )
}

export default Options;