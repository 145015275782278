import Api from "helpers/Api";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";

function AmountType(props, ref) {

    const [types, setTypes] = useState([]);

    useEffect(() => {
        Api.get('store/autocomplete/amount-types', {
            params: {
                limit: 9999
            }
        }).then(res => {
            if (Array.isArray(res.data.items)) {
                setTypes(res.data.items);
            }
        });
    }, [])

    useImperativeHandle(ref, () => ({
        getName: getName,
        getType: getType,
        getTitle: getTitle,
        hasSettings: hasSettings,
        getSettings: getSettings,
        getDefaultOptions: ({ createOrUpdate }) => ({})
    }));

    const getName = () => {
        return 'Тип количество';
    }

    const getType = () => {
        return 'amount_type_id';
    }

    const getTitle = () => {
        return null;
    }

    const hasSettings = () => true;

    const getSettings = ({ colIndex, state, setConfig, createOrUpdate, getValues }) => {


        const getMapping = () => {
            getValues(colIndex)
                .then(res => {
                    let options = {};

                    res.data.values.map(r => {
                        options[r] = null;
                    });

                    setConfig(options);

                })
        }

        // console.log(state.options)

        return (
            <div>
                <label>
                    "Mapping" на стойности:
                </label>
                <div>
                    <Button
                        size="sm"
                        onClick={e => getMapping()}
                    >
                        Изтегли текущите стойности
                    </Button>

                    <Table className="mt-3">
                        <tbody>
                            {Object.entries(state.options).map(c =>
                                <tr key={c[0]}>
                                    <td>{c[0]}</td>
                                    <td>=</td>
                                    <td>
                                        <Form.Select
                                            value={c[1] || ''}
                                            onChange={e => setConfig(c[0], e.target.value)}
                                            size="sm"
                                        >
                                            <option value="" disabled></option>
                                            {types.map(type =>
                                                <option key={type.id} value={type.id}>{type?.translation?.name || type?.name}</option>
                                            )}
                                        </Form.Select>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
            </div>
        )
    }

    return getName();
}

export default forwardRef(AmountType)