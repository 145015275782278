import useWindowSize from 'react-use/lib/useWindowSize'

//plugins
// import Confetti from 'react-confetti'

//images
import brand from 'assets/img/app/brand-full.png'
import nextIcon from 'assets/img/documents/icons/forward.png'
import finalScreenImage from 'assets/img/login/final-screen-shape.png'
import clientsIcon from 'assets/img/login/icons/clients.png'
import documentsIcon from 'assets/img/login/icons/documents.png'
import employeesIcon from 'assets/img/login/icons/employees.png'
import finacesIcon from 'assets/img/login/icons/finances.png'
import graphIcon from 'assets/img/login/icons/graph.png'
import integrationsIcon from 'assets/img/login/icons/integrations.png'
import notificationsIcon from 'assets/img/login/icons/notifications.png'
import paymentsIcon from 'assets/img/login/icons/payments.png'
import registerIcon from 'assets/img/login/icons/registers.png'
import searchIcon from 'assets/img/login/icons/search.png'
import securityIcon from 'assets/img/login/icons/security.png'
import signsIcon from 'assets/img/login/icons/signs.png'
import statisticsIcon from 'assets/img/login/icons/statistics.png'
import warehouseIcon from 'assets/img/login/icons/warehouse.png'

//MUI components
import { Button } from '@material-ui/core'

//hooks
import useFinalScreenHook from './useFinalScreenHook'

const FinalScreen = () => {
    const {
        handleNavigateToHome
    } = useFinalScreenHook()
    const { width, height } = useWindowSize()

    return (
        <>
            <ul className="final-list">
                <li>
                    <img src={integrationsIcon} />
                    Интеграции
                </li>
                <li>
                    <img src={paymentsIcon} />
                    Разплащане
                </li>
                <li>
                    <img src={clientsIcon} />
                    Клиенти
                </li>
                <li>
                    <img src={registerIcon} />
                    Регистри
                </li>
                <li>
                    <img src={documentsIcon} />
                    Документи
                </li>
                <li>
                    <img src={signsIcon} />
                    Ел. подпис
                </li>
                <li>
                    <img src={warehouseIcon} />
                    Склад
                </li>
                <li>
                    <img src={notificationsIcon} />
                    Известия
                </li>
                <li>
                    <img src={employeesIcon} />
                    Служители
                </li>
                <li>
                    <img src={graphIcon} />
                    Отчет
                </li>
                <li>
                    <img src={securityIcon} />
                    Сигурност
                </li>
                <li>
                    <img src={finacesIcon} />
                    Финанси
                </li>
                <li>
                    <img src={searchIcon} />
                    Справки
                </li>
                <li>
                    <img src={statisticsIcon} />
                    Статистика
                </li>
            </ul>
            <div className="final-screen">
                {/* <Confetti
                    width={1200}
                    height={height}
                    numberOfPieces={1000}
                    recycle={false}
                /> */}
                <img src={brand} className="brand" />
                <h1>
                    Готови сте да започнете
                </h1>
                <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text
                </p>
                <Button
                    className="save"
                    onClick={handleNavigateToHome}
                >
                    Към Вашият профил
                    <img src={nextIcon} />
                </Button>
                <img src={finalScreenImage} className="main-image" />
            </div>
        </>
    )
}

export default FinalScreen