import { TextField as MuiTextField } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import { forwardRef, useImperativeHandle, useRef } from 'react'

function TextField(props, ref) {
    const inputRef = useRef()

    useImperativeHandle(ref, () => ({
        getRef: () => inputRef
    }))

    return <MuiTextField
        inputRef={inputRef}
        InputProps={{
            endAdornment: (
                props.helperText &&
                <Tooltip title={props.helperText} placement="top" arrow>
                    <span className="error-info">

                    </span>
                </Tooltip>
            )
        }}
        {...props}
        helperText={null}
        onWheel={e => {
            if (props.type === 'number') e.target.blur()
        }}
    />
}

export default forwardRef(TextField)