import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import { Modal, Button, Col, Row, FloatingLabel, Form, } from 'react-bootstrap';

//helpers
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers

//misc
import SaveButton from 'components/misc/Button'
import moment from 'moment'

function EditAttributes(props, ref) {

    const defaultData = {
        article: null,
        attrs: []
    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        refresh: false,
        data: defaultData,
        loading: false,
        loadingBase: false,
        loadingApply: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
        onClose: null,
    });

    const [validations, setValidations] = useValidation();

    const formRef = useRef(null);

    useImperativeHandle(ref, () => ({
        open: (article, attrs) => {
            open(article, attrs);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));


    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const open = (article, attrs) => {

        let data = attrs.length ? attrs : article.attrs;

        setState(prev => ({
            ...prev,
            data: {
                article: article,
                attrs: data
            },
        }));

        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
        }));

        setValidations(null);

        if (typeof state.onClose === 'function') {
            hide(state.onClose);
        } else {
            hide();
        }
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            refresh: moment().unix(),
        }));
    }

    const handleSave = e => {
        e.preventDefault();

        // if (state.loading) {
        //     return;
        // }

        // setState(prev => ({
        //     ...prev,
        //     loading: true,
        //     loadingBase: true,
        // }));

        // setValidations(null);

        state.onSuccess(state.data.attrs);

        hide();
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data.attrs')
    }

    const handleDateChange = e => {
        const name = e.target.name + '_ymd'
        const value = e.target.value

        setState(name, value, 'data')
    }



    return (
        <>

            <Modal size="md" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form ref={formRef} onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Допълнителни атрибути към артикула
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        {(state.data.attrs || []).map((attr, index) =>
                            <Row key={attr.id} className="mb-3">
                                <Col>
                                    <FloatingLabel label={attr.name}>
                                        <Form.Control
                                            type="text"
                                            name={`[${index}][value]`}
                                            autoComplete="new-password"
                                            value={attr.value || ''}
                                            placeholder={attr.name}
                                            onChange={(e) => handleInputChange(e)}
                                            isInvalid={Boolean(validations?.num)}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {/*  */}
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                        )}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loadingBase}
                            disabled={state.loading}
                            className="save"
                            text="Потвърди"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(EditAttributes);
