//hooks
import useFetchOutsActivitiesDashboard from "../hooks/useFetchOutsActivitiesDashboard"

//components
import RedditTextField from "components/misc/RedditTextField"
import OutsActivitiesDashboardTable from "./OutsActivitiesDashboardTable"
import Pagination from "components/misc/Pagination"
import PaginationMini from "components/misc/PaginationMini"

function OutsActivitiesDashboard({ clientId }) {
  const {
    loadingActivities,
    getActivities,
    handleSearch,
    getSearchValue,
    getCurrentPage,
    getTotalPages,
    getTotalItems,
    handlePage,
    refetchData,
  } = useFetchOutsActivitiesDashboard(clientId)

  return (
    <>
      <div className="filter-holder">
        <div className="row filter">
          <div
            className="col"
            style={{
              maxWidth: "300px",
            }}
          >
            <RedditTextField
              size="small"
              margin="dense"
              fullWidth
              label="Търсене..."
              name="search"
              value={getSearchValue()}
              onChange={handleSearch}
            />
          </div>
        </div>
        <PaginationMini
          page={getCurrentPage()}
          pages={getTotalPages()}
          handlePage={handlePage}
          isLoading={loadingActivities}
        />
      </div>
      <OutsActivitiesDashboardTable
        loadingActivities={loadingActivities}
        getActivities={getActivities}
        clientId={clientId}
        refetchData={refetchData}
      />
      <Pagination
        page={getCurrentPage()}
        pages={getTotalPages()}
        total={getTotalItems()}
        handlePage={handlePage}
      />
    </>
  )
}

export default OutsActivitiesDashboard
