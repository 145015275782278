const MALE_SUFIX = [
    'ев',
    'ов',
    'ич',
];

const FEMALE_SUFIX = [
    'ева',
    'ова',
];

const DEFAULT_ADDRESSING = 'Уважаеми г-н/уважаема г-жо';

/**
 * @param {String} name 
 */
export const guessFromName = name => {
    if (!name) {
        return DEFAULT_ADDRESSING;
    }

    let parts = name.split(' ');
    let lastName = parts[parts.length - 1];

    if (!lastName) {
        return DEFAULT_ADDRESSING;
    }

    for (let i = 0; i < MALE_SUFIX.length; i++) {
        if (lastName.endsWith(MALE_SUFIX[i])) {
            return `Уважаеми г-н ${lastName},`;
        }
    }

    for (let i = 0; i < FEMALE_SUFIX.length; i++) {
        if (lastName.endsWith(FEMALE_SUFIX[i])) {
            return `Уважаема г-жо ${lastName},`;
        }
    }

    return `${DEFAULT_ADDRESSING} ${lastName}`;
}