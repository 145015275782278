import { useState } from "react"

//components
import Layout from "components/Layout"
import RedditSelect from "components/misc/RedditSelect"
import Calendar from "components/pages/customers/view/Calendar"

//libraries
import { Button, MenuItem } from "@material-ui/core"
import moment from "moment"

//constants
import { ADD_OPTIONS_TYPES } from "constants/calendar"

//stores
import { useAddToCalendarModal } from "stores/customer/useAddToCalendarModal"

//helpers
import { roundCurrentHourToNearestHalfHour } from "helpers/Calendar"

export default function MarketingCalendar() {
  const { showModal } = useAddToCalendarModal()

  const [filters, setFilters] = useState({
    type: "696969",
  })

  function getAddOptionsTypes() {
    const addOptionsTypes = []

    Object.values(ADD_OPTIONS_TYPES).map((option) => {
      if (option.sublist) addOptionsTypes.push(...option.sublist)

      addOptionsTypes.push(option)

      return option
    })

    return addOptionsTypes
  }

  function openAddEventModal() {
    showModal(true, {
      eventType: "meeting",
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      startHour: roundCurrentHourToNearestHalfHour(moment().format("HH:mm")),
      endHour: roundCurrentHourToNearestHalfHour(
        moment().add(30, "minutes").format("HH:mm")
      ),
    })
  }

  function handleChangeFilter(e) {
    const { name, value } = e.target

    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  return (
    <Layout>
      <div className="container-fluid">
        <div className="head-with-heading">
          <h3>Календар на дейността</h3>
          <div className="buttons">
            <Button className="add" onClick={openAddEventModal}>
              Нова бележка
            </Button>
          </div>
        </div>
        <div
          className="panel"
          style={{
            marginTop: 0,
          }}
        >
          <div className="filter-holder">
            <div className="row filter">
              <div className="col">
                <RedditSelect
                  label="Типове"
                  value={filters.type}
                  name="type"
                  onChange={handleChangeFilter}
                >
                  <MenuItem value="696969">Всички</MenuItem>
                  {getAddOptionsTypes().map(({ title, type, sublist }) => (
                    <MenuItem value={type} disabled={sublist}>
                      {title}
                    </MenuItem>
                  ))}
                </RedditSelect>
              </div>
            </div>
          </div>
          <div
            className="calendar-holder"
            style={{
              margin: "-20px",
              marginTop: 0,
            }}
          >
            <Calendar filters={filters} />
          </div>
        </div>
      </div>
    </Layout>
  )
}
