import { Column } from '@ant-design/plots'
import { Button, Checkbox, FormControl, Input, InputLabel, ListItemText, MenuItem, Select } from '@material-ui/core'
import RedditSelect from 'components/misc/RedditSelect'
import Api from 'helpers/Api'
import { useCallback, useEffect, useRef, useState } from 'react'
import calendarIcon from 'assets/img/app/icons/calendar.png'
import DateOptions from './DateOptions'
import DatePicker from 'components/misc/DatePicker'
import axios from 'axios'
import { cloneDeep } from 'lodash'
import Skeleton from 'react-loading-skeleton'
import ExpensesFilter from './ExpensesFilter'
import NoExpenseData from './NoExpenseData'
import PeriodNew from 'components/pages/documents/partials/filter/PeriodNew'
import moment from 'moment'

let timeout

function Index() {
    const [state, setState] = useState({
        data: {
            categories: [],
        },
        originalCategories: [],
        filteredCategories: [],
        comparedCategories: [],
        path: [],
        fullPath: [],
        filter: {
            group: 'month',
            start: null,
            end: null,
            force_start: null,
            force_end: null,
            category_id: [],
            contragent_id: [],
        },
        chart: null,
        loading: true,
        refresh: false,
        request: null,
    })

    const [data, setData] = useState([])

    const dateOptionsRef = useRef(null)

    useEffect(() => {
        let chart = state.chart

        if (!chart) {
            return
        }

        const onClick = (e) => {
            let name = e.gEvent.target.cfg.delegateObject.item.value
            let category = findCategoryByName(name)

            showSubCategories(category)
        }

        chart.on('legend-item-name:click', onClick)

        return () => {
            chart.off('legend-item-name:click', onClick)
        }
    }, [state.chart, state.data.categories])

    useEffect(() => {
        loadData()
    }, [state.refresh])

    useEffect(() => {
        let fullPath = []
        let data = cloneDeep(state.originalCategories)

        state.path.map(id => {
            let category = data.find(c => Number(c.data.id) === Number(id))

            if (category) {
                data = Object.values(category.children)

                fullPath.push(category)
            }
        })

        let filtered = data

        if (state.comparedCategories.length) {
            filtered = filtered.filter(category => {
                return state.comparedCategories.indexOf(category.data.id) > -1
            })
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                categories: data,
            },
            filteredCategories: filtered,
            fullPath: fullPath,
        }))
    }, [state.path, state.comparedCategories, state.originalCategories])

    useEffect(() => {
        if (state.comparedCategories.length === 0 && state.data.categories.length > 0) {
            setState(prev => ({
                ...prev,
                comparedCategories: prev.data.categories.map(c => c.data.id),
            }))
        }
    }, [state.data.categories, state.comparedCategories])

    useEffect(() => {
        if (state.loading) {
            return
        }

        let data = []

        let categories = state.filteredCategories

        state.data.dates.map(date => {
            categories.map(category => {
                let group = {
                    xField: date.date,
                    yField: category.stats.dates[date.date]?.gross || 0,
                    seriesField: category.data.name
                }

                data.push(group)
            })
        })

        // console.log(data)

        setData(data)
    }, [state.data])

    const loadData = () => {
        if (state.request) {
            state.request.cancel()
        }

        let request = axios.CancelToken.source()

        setState(prev => ({
            ...prev,
            request: request,
            loading: true,
        }))

        Api.post('accounts/expenses-by-category', state.filter, {
            cancelToken: request.token
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data,
                originalCategories: res.data.categories,
                filteredCategories: res.data.categories,
                // comparedCategories: res.data.categories.map(c => c.data.id),
                filter: {
                    ...prev.filter,
                    group: res.data.group,
                    force_start: res.data.start,
                    end: res.data.end
                },
                loading: false,
            }))

        })
    }

    const handleChangeCategory = e => {
        let values = e.target.value

        changeSelectedCategory(values)
    }

    const changeSelectedCategory = data => {
        if (data.length === 0) {
            return
        }

        setState(prev => ({
            ...prev,
            comparedCategories: data
        }))
    }

    const changeSelectedCustomer = data => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                contragent_id: data
            },
            refresh: moment().unix()
        }))
    }

    const findCategory = id => {
        return state.data.categories.find(c => Number(c.data.id) === Number(id))
    }

    const findCategoryByName = name => {
        return state.data.categories.find(c => String(c.data.name) === String(name))
    }

    const isSelectedCategory = id => {
        return state.comparedCategories.indexOf(id) > -1
    }

    const showSubCategories = category => {
        if (category.children.length === 0) {
            return
        }

        setState(prev => ({
            ...prev,
            path: [...prev.path, category.data.id],
            comparedCategories: [],
        }))
    }

    const handleBack = () => {
        let path = cloneDeep(state.path)

        path.pop()

        setState(prev => ({
            ...prev,
            path: path,
            comparedCategories: [],
        }))
    }

    const setChart = chart => {
        setState(prev => ({
            ...prev,
            chart: chart
        }))
    }

    const handleFilterChange = (name, value, delay = 0) => {
        clearTimeout(timeout)

        if (name === 'start_date') {
            name = "start"
        }

        if (name === 'end_date') {
            name = "end"
        }

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [name]: value
            },
            comparedCategories: [],
            path: []
        }))

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                refresh: moment().unix()
            }))
        }, delay)
    }

    const handleShowCalendar = e => {
        dateOptionsRef.current.show(e)
    }

    const getDateTotal = (date) => {
        let total = 0

        state.filteredCategories.map(category => {
            total += category.stats.dates[date].gross
        })

        total = total.toFixed(2)

        return total
    }

    const getTotal = () => {
        let total = 0

        state.filteredCategories.map(category => {
            total += category.stats.gross
        })

        total = total.toFixed(2)

        return total
    }

    const config = {
        data,
        xField: 'xField',
        yField: 'yField',
        seriesField: 'seriesField',
        // color: ['l(270) 0:#C871F3 0.5:#C871F3 1:#F871CF', 'l(270) 0:#0DCFF5 0.5:#40E0D4 1:#83F8A9'],
        isGroup: true,
        columnStyle: {
            radius: [20, 20, 0, 0],
        },
        // xAxis: {
        //     type: 'time',
        //     mask: state.filter.group === 'day' ? 'DD-MM-YYYY' : 'MM-YYYY',
        // },
        yAxis: {
            label: {
                formatter: (v) => Number(v).toFixed(2),
            },
        },
        tooltip: {
            formatter: (datum) => {
                return {
                    name: datum.seriesField,
                    value: datum.yField.toFixed(2),
                }
            },
        },
        legend: {
            marker: {
                symbol: 'circle'
            },
            position: 'top-right',
            itemSpacing: 10,
        },
    }

    function hasData() {
        if (data.length) {
            return true
        }

        return false
    }

    if (!hasData() && !state.loading) {
        return <NoExpenseData loadData={loadData} />
    }

    return (
        <>
            <div className="row">
                <div className="col chart">
                    <div className="head">
                        <div>
                            <div className="title">
                                Сравняване на разходите по категории
                            </div>
                            <div className="subtitle">
                                Сравнете разходите по категории, като използвате зададен от Вас период.
                            </div>
                        </div>
                        <div className="dates">
                            <div className={`btn ${state.filter.group === 'day' ? 'active' : ''}`} onClick={e => handleFilterChange('group', 'day')}>
                                Д
                            </div>
                            <div className={`btn ${state.filter.group === 'week' ? 'active' : ''}`} onClick={e => handleFilterChange('group', 'week')}>
                                С
                            </div>
                            <div className={`btn ${state.filter.group === 'month' ? 'active' : ''}`} onClick={e => handleFilterChange('group', 'month')}>
                                М
                            </div>
                            <div className={`btn`}>
                                <img src={calendarIcon} alt="" />
                                <PeriodNew
                                    period={state.filter.period}
                                    dates={{
                                        start_date: state.filter.start || null,
                                        end_date: state.filter.end || null,
                                    }}
                                    handleSearch={handleFilterChange}
                                />
                            </div>
                            {/* <DateOptions
                                ref={dateOptionsRef}
                            >
                                <MenuItem>
                                    <DatePicker
                                        reddit
                                        label="От дата"
                                        value={state.filter.force_start || state.filter.start || ''}
                                        onChange={e => handleFilterChange('start', e.target.value, 300)}
                                    />
                                </MenuItem>
                                <MenuItem>
                                    <DatePicker
                                        reddit
                                        label="До дата"
                                        value={state.filter.end || ''}
                                        onChange={e => handleFilterChange('end', e.target.value, 300)}
                                    />
                                </MenuItem>
                            </DateOptions> */}
                        </div>
                    </div >
                    {/* <div className="filter">
                        <div className="item category">
                            <RedditSelect
                                label="Категории"
                                value={state.comparedCategories}
                                renderValue={(selected) => selected.map(id => findCategory(id)).filter(c => c).map(c => c?.data?.name).join(', ')}
                                onChange={handleChangeCategory}
                                multiple
                            >
                                {(state.data?.categories || []).map(category =>
                                    <MenuItem key={'c' + category.data.id} value={category.data.id}>
                                        <Checkbox checked={isSelectedCategory(category.data.id)} />
                                        <ListItemText primary={category.data.name} />
                                    </MenuItem>
                                )}
                            </RedditSelect>
                        </div>
                    </div> */}

                    <ExpensesFilter
                        primaryFilter="category"
                        path={state.path}
                        filter={state.filter}
                        selectedCategories={state.comparedCategories}
                        onCustomerChange={data => changeSelectedCustomer(data)}
                        onCategoryChange={data => changeSelectedCategory(data)}
                    />

                    <div className="graph">
                        {state.path.length ?
                            <Button
                                className="cancel"
                                onClick={() => {
                                    handleBack()
                                }}
                            >
                                <span className="arrow">&#8592; Назад ({state.fullPath.map(category => category.data.name).join(' » ')})</span>
                            </Button>
                            : ''
                        }

                        {state.loading
                            ?
                            <Skeleton style={{ height: '100%' }} />
                            :
                            <Column {...config} onReady={e => setChart(e)} />
                        }
                    </div>

                </div>
            </div>
            <div className="row">
                <div className="col">
                    {state.loading
                        ?
                        <Skeleton count={4} height={30} />
                        :
                        <div className="scrollable">
                            <table className="type-outer">
                                <thead>
                                    <tr>
                                        <th>
                                            Категория
                                        </th>
                                        {state.data.dates.map(date =>
                                            <th key={'th-' + date.date} style={{ textAlign: 'right' }}>
                                                {date.label}
                                            </th>
                                        )}
                                        <th style={{ textAlign: 'right' }}>
                                            Общо
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.filteredCategories.map(category =>
                                        <tr key={'tr-' + category.data.id} onClick={e => showSubCategories(category)}>
                                            <td>
                                                {category.data.name}
                                            </td>
                                            {state.data.dates.map(date =>
                                                <td key={'td-' + date.date} style={{ textAlign: 'right' }}>
                                                    {category.stats.dates[date.date].gross.toFixed(2)}
                                                </td>
                                            )}
                                            <td style={{ textAlign: 'right' }}>
                                                {category.stats.gross.toFixed(2)}
                                            </td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td>
                                            Общо
                                        </td>
                                        {state.data.dates.map(date =>
                                            <td key={'tdd-' + date.date} style={{ textAlign: 'right' }}>
                                                {getDateTotal(date.date)}
                                            </td>
                                        )}
                                        <td style={{ textAlign: 'right' }}>
                                            {getTotal()}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default Index
