export const renderArticleDetailValueText = data => {
    return data.color
        ?
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: 20, height: 20, marginRight: 20, backgroundColor: data.color, backgroundImage: 'url(' + data.image_url + ')' }}>

            </div>
            <div>
                {data?.translation?.name || data.name}
            </div>
        </div>
        :
        data?.translation?.name || data.name
}