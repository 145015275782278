
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

//components
import List from './List'
import Settings from './Settings'

//helpers
import { useQuery } from 'helpers/Url'

function Index() {
    const history = useHistory()
    const query = useQuery()
    const [state, setState] = useState({
        innerPage: null,
    })

    useEffect(() => {
        setState(prev => ({
            ...prev,
            innerPage: Number(query.get('inner_page')) || null
        }))
    }, [history.location])

    return (
        !state.innerPage ?
            <List />
            :
            state.innerPage === 1 ?
                <Settings />
                :
                <>
                </>
    )
}

export default Index