//MUI components
import { MenuItem, Tooltip } from '@material-ui/core'

//images
import mailIcon from 'assets/img/app/icons/mail.png'
import linkIcon from 'assets/img/app/icons/link.png'
import printIcon from 'assets/img/app/icons/print.png'
import downloadIcon from 'assets/img/app/icons/download.png'
import fileIcon from 'assets/img/app/icons/file.png'
import previewIcon from 'assets/img/app/icons/preview.png'
import editIcon from 'assets/img/app/icons/edit.png'
import autoCreateIcon from 'assets/img/app/icons/auto-create.png'
import deleteIcon from 'assets/img/app/icons/delete.png'
import checkedIcon from 'assets/img/app/icons/check-bordered.png'
import partlyPaymentIcon from 'assets/img/app/icons/partly-payment.png'
import creditNoteIcon from 'assets/img/app/icons/credit-note.png'
import debitNoteIcon from 'assets/img/app/icons/debit-note.png'
import exportIcon from 'assets/img/app/icons/export.png'
import createProformaIcon from 'assets/img/app/icons/create-proforma.png'
import createInvoiceIcon from 'assets/img/app/icons/create-invoice.png'
import markAsUnpaidIcon from 'assets/img/app/icons/mark-as-unpaid.png'
import PrintButtonLayout from './PrintButtonLayout'
import DownloadButtonLayout from './DownloadButtonLayout'

function Options(props) {
    const {
        data,
        handleDownload,
        handleCopyLink,
        handlePrint,
        handleLogin
    } = props

    return (
        <>
            <MenuItem className='print'>
                <DownloadButtonLayout downloadUrl={data.download_pdf_public_url}>
                    <img src={downloadIcon} />
                    Изтегляне
                </DownloadButtonLayout>
            </MenuItem>

            <MenuItem className='print'>
                <PrintButtonLayout printUrl={data.print_public_url} openPrintUrlInNewTab={true}>
                    <img src={printIcon} />
                    Принтиране
                </PrintButtonLayout>
            </MenuItem>

            <MenuItem onClick={() => {
                handleCopyLink()
            }}>
                <img src={linkIcon} />
                Копиране на линк
            </MenuItem>

            <hr />

            <MenuItem onClick={() => {
                handleLogin()
            }}>
                <img src={fileIcon} />
                Моите документи
            </MenuItem>
        </>
    )
}

export default Options