import React, { useState, useImperativeHandle, forwardRef, useRef, useEffect } from 'react'
import Home from './Home'
import Edit from './Edit'
import Notes from './Notes'
import Payments from './Payments'
import PriceRules from './PriceRules'
import Note from 'components/partials/notes/AddOrEdit'

import closeIcon from '../../../../assets/img/modals/icons/close.png'

import { useClientContext } from '../Provider'
import { useAppContext } from 'providers/App'
import { useAuthDataContext } from 'providers/Auth'

import Api from 'helpers/Api'

import { MODELS } from 'constants/models'
import Refs from 'Refs'
import Vies from '../partials/Vies'
import { Button } from '@material-ui/core'

//images
import viewIcon from 'assets/img/accounts/icons/view.png'
import editIcon from 'assets/img/customers/icons/edit.png'
import dashboardIcon from 'assets/img/customers/icons/dashboard.png'
import storesIcon from 'assets/img/customers/icons/stores.png'
import referenceIcon from 'assets/img/customers/icons/reference.png'
import recommendationsIcon from 'assets/img/customers/icons/recommendations.png'
import complaintIcon from 'assets/img/customers/icons/complaint.png'
import ordersIcon from 'assets/img/customers/icons/orders.png'
import campaignsIcon from 'assets/img/customers/icons/campaigns.png'
import notesIcon from 'assets/img/customers/icons/notes.png'
import calendarIcon from 'assets/img/customers/icons/reference.png'
import documentIcon from 'assets/img/customers/icons/document.png'
import communicationIcon from 'assets/img/customers/icons/communication.png'

//components
import Stores from './Stores'
import Dashboard from './Dashboard'
import LeftSideMenu from 'components/pages/customers/partials/LeftSideMenu'
import SaveButton from 'components/misc/Button'
import Communication from './Communication'
import DocumentsActivitiesDashboard from '../partials/DocumentsActivitiesDashboard'
import Calendar from './Calendar'
import Pipelines from './Pipelines'
import Campaigns from './Campaigns'
import Complaints from './Complaints'

function View(props, ref) {
    const app = useAppContext()
    const auth = useAuthDataContext()

    const tabs = [
        {
            id: "main",
            name: "Основни данни",
            innerTabs: [{
                id: 'dashboard',
                name: 'Табло',
                component: Dashboard,
                icon: dashboardIcon
            },
            {
                id: 'home',
                name: 'Основни данни',
                component: Home,
                icon: viewIcon
            },
            {
                id: 'edit',
                name: 'Редактиране',
                component: Edit,
                icon: editIcon
            },
            {
                id: 'stores',
                name: 'Oбекти',
                component: Stores,
                icon: storesIcon
            },]
        },
        auth.getUser().hasCRMAccess() ?
            {
                id: "communication",
                name: "Комуникация",
                innerTabs: [{
                    id: 'communication',
                    name: 'Комуникация',
                    component: Communication,
                    icon: communicationIcon
                },
                {
                    id: 'documents',
                    name: 'Документи',
                    component: DocumentsActivitiesDashboard,
                    icon: documentIcon
                },
                {
                    id: 'calendar',
                    name: 'Календар',
                    component: Calendar,
                    icon: calendarIcon
                },
                {
                    id: 'pipelines',
                    name: 'Pipelines',
                    component: Pipelines,
                    icon: calendarIcon
                },
                // {
                //     id: 'notes',
                //     name: 'Бележки',
                //     component: () => <></>,
                //     icon: notesIcon
                // },
                {
                    id: 'campaigns',
                    name: 'Кампании',
                    component: Campaigns,
                    icon: campaignsIcon
                }]
            }
            : null,
        auth.getUser().hasCRMAccess() ?
            {
                id: "orders",
                name: "Поръчки",
                innerTabs: [
                    //     {
                    //     id: 'orders',
                    //     name: 'Поръчки',
                    //     component: () => <></>,
                    //     icon: ordersIcon
                    // },
                    {
                        id: 'complaint',
                        name: 'Рекламации',
                        component: Complaints,
                        icon: complaintIcon
                    },
                    // {
                    //     id: 'recommendations',
                    //     name: 'Препоръки',
                    //     component: () => <></>,
                    //     icon: recommendationsIcon
                    // },
                    // {
                    //     id: 'reference',
                    //     name: 'Справки',
                    //     component: () => <></>,
                    //     icon: referenceIcon
                    // }
                ]
            }
            : null

    ]

    function getAvailableTabs() {
        return tabs.filter(tab => tab)
    }

    function getInnerTabs() {
        return getAvailableTabs().map(tab => tab.innerTabs).flat()
    }



    const [state, setState] = useState({
        show: false,
        params: null,
        id: null,
        tabId: null,
        onClose: null,
        activeTabId: getInnerTabs()[0].id,
        loadingSave: false,
    })
    console.log(`getInnerTabs()[0].id: ${getInnerTabs()[0].id}`)
    const context = useClientContext()

    const noteModalRef = useRef(null)
    const viesModalRef = useRef(null)
    const screenRef = useRef(null)

    useImperativeHandle(ref, () => ({
        open: (id, activeTabId) => {
            handleShow(id, activeTabId)
        },
        close: () => {
            hide()
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }))
        }
    }))

    useEffect(() => {
        if (!state.show) {
            return
        }

        if (auth.getUser().permission('clients_view') === false) {
            app.showError('Нямате право да преглеждате клиент!')

            hide(state.onClose)
        }

    }, [state.show])

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }))
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }))
    }

    const handleShow = (id, activeTabId = getInnerTabs()[0].id) => {
        setState(prev => ({
            ...prev,
            id: id,
            activeTabId,
            tabId: getDefaultActiveTab()?.id,
        }))

        show()
    }

    const handleClose = () => {
        setState(prev => ({
            ...prev,
            show: false,
            tabId: getInnerTabs()[0].id,
            activeTabId: getInnerTabs()[0].id,
        }))

        context?.resetData?.()

        if (typeof state.onClose === 'function') {
            hide(state.onClose)
        } else {
            hide()
        }

        state.onClose?.()

        setState(prev => ({
            ...prev,
            onClose: null,
        }))
    }

    const setKey = id => {
        setState(prev => ({
            ...prev,
            tabId: id,
        }))
    }

    const hasTabPermission = key => {
        let map = {
            home: 'clients_view',
            edit: 'clients_edit',
            payments: 'clients_clientpayments',
            price_rules: 'clients_edit',
            notes: 'clients_notes',
        }

        let permission = map[key]

        if (permission && auth.getUser().permission(permission) === false) {
            return false
        }

        return true
    }

    const getDefaultActiveTab = () => {
        return getInnerTabs().find(tab => {
            return hasTabPermission(tab.id)
        })
    }

    const addNote = () => {

        let modal = noteModalRef.current

        if (!modal) {
            return
        }

        hide(() => {
            modal.add(state.id, MODELS.CLIENT)
        })

        modal.onClose(() => {
            show()
        })
    }

    const editNote = (id) => {

        let modal = noteModalRef.current

        if (!modal) {
            return
        }

        hide(() => {
            modal.edit(id)
        })

        modal.onClose(() => {
            show()
        })
    }

    const deleteNote = (id) => {

        let modal = Refs.getInstance().getRef('delete')

        if (!modal) {
            return
        }

        hide(() => {
            modal.open('Сигурни ли сте, че искате да изтриете тази бележка?')
        })

        modal.onCancel(() => {
            show()
        })

        modal.onSuccess(() => {
            destroyNote(id, () => {
                show()
            })
        })
    }

    const destroyNote = (id, cb) => {
        Api.post('notes/delete', {
            id: id
        }).then(res => {
            cb()
        })
    }

    const showAccount = (id) => {
        let modal = Refs.getInstance().getRef('account')

        if (!modal) {
            return
        }

        hide(() => {
            modal.open(id)
        })

        modal.onClose(() => {
            show()
        })
    }

    const showPriceRule = (id) => {
        let modal = Refs.getInstance().getRef('priceRule')

        if (!modal) {
            return
        }

        hide(() => {
            modal.edit(id)
        })

        modal.onClose(() => {
            show()
        })
    }

    const handleTabChange = activeTabId => {
        setState(prev => ({
            ...prev,
            activeTabId
        }))
    }

    function loadingSave(loadingSave) {
        setState(prev => ({
            ...prev,
            loadingSave
        }))
    }

    function getActiveTab() {
        return getInnerTabs().find(t => t.id === state.activeTabId)
    }

    return (
        <>
            <Note
                ref={noteModalRef}
            />

            <Vies
                ref={viesModalRef}
            />

            <div className={`${state.show ? "visible" : ""} overlay`}>
                <div
                    className={`popup-primary extra-large complex-modal customer-modal ${state.show ? "show" : ""}`}
                >
                    <div className="header sky-blue">
                        <b>
                            Преглед на клиент
                        </b>
                        <p>
                            Lorem Ipsum е елементарен примерен<br /> текст, използван в печатарската
                        </p>
                    </div>
                    {console.log('here', getActiveTab())}
                    <div className="body">
                        <div className="row">
                            <LeftSideMenu
                                tabsArr={getAvailableTabs()}
                                activeTabId={state.activeTabId}
                                setActiveTabId={handleTabChange}
                            />
                            <div className="right-form-holder">
                                {React.createElement(getActiveTab().component, {
                                    id: state.id,
                                    clientId: state.id,
                                    client: context?.data,
                                    show: show,
                                    hide: hide,
                                    handleClose: handleClose,
                                    addNote: addNote,
                                    editNote: editNote,
                                    deleteNote: deleteNote,
                                    showAccount: showAccount,
                                    showPriceRule: showPriceRule,
                                    viesModalRef: viesModalRef,
                                    ref: screenRef,
                                    loadingSave: state.loadingSave,
                                    handleStopLoadingSave: () => {
                                        loadingSave(false)
                                    },
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="footer">
                        <div
                            className="row"
                            style={{
                                marginLeft: 'auto',
                                width: 'fit-content'
                            }}
                        >
                            <Button className="cancel" onClick={handleClose}>
                                Затвори
                            </Button>
                            {state.activeTabId === 2 ?
                                <SaveButton
                                    loading={state.loadingSave}
                                    className="save"
                                    onClick={() => {
                                        screenRef.current?.save()
                                        loadingSave(true)
                                    }}
                                />
                                :
                                <>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default forwardRef(View)