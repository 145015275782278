import { useState, useImperativeHandle, forwardRef, useEffect, useRef } from 'react'
import { Modal, Button, Row, Col } from 'react-bootstrap';
import Api from 'helpers/Api';
import { useValidation } from 'helpers/Validation';
import { TYPES as DOCUMENTS_TYPES } from 'constants/documents';
import { useHistory } from 'react-router-dom';

function NewDocument(props, ref) {

    const navigate = useHistory();

    const defaultData = {};

    const [state, setState] = useState({
        show: false,
        params: null,
        id: null,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onClose: null,
    });

    const [validations, setValidations] = useValidation();

    const errorModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        open: (id) => {
            handleShow(id);
        },
        close: () => {
            handleClose();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    useEffect(() => {

    }, []);

    const handleShow = (id) => {
        setState(prev => ({
            ...prev,
            show: true,
            id: id,
        }));
    }

    const handleClose = () => {
        setState(prev => ({
            ...prev,
            show: false,
            data: defaultData
        }));

        setValidations(null);

        if (typeof state.onClose === 'function') {
            state.onClose();
        }
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: value
            }
        }));
    }

    const handleCreateDocument = (type) => {
        navigate(type.new + '?from_move_id=' + state.id);
    }

    const style = {
        width: '100%',
        margin: '2.5px 0',
        textAlign: 'left',
    }

    const label = {

    }

    return (
        <>
            <Modal centered show={state.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Изберете документ
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {DOCUMENTS_TYPES.map(type =>
                        <Button key={type.id} variant="secondary" style={style} onClick={e => handleCreateDocument(type)}>
                            {type.name}
                        </Button>
                    )}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Отказ
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default forwardRef(NewDocument);