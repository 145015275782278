import { useEffect, useRef, useState } from 'react'
import Api from 'helpers/Api'
import { buildUrl, useQuery } from 'helpers/Url'
import { useLocation, useHistory, Link, useParams } from 'react-router-dom'
import { Table, Form, Row, Col, Container, InputGroup } from 'react-bootstrap'
import { ArrowLeftCircle, Cash, Coin, CurrencyDollar, Envelope, Files, InfoCircle, PencilSquare, Percent, Rainbow, Receipt, TrashFill, Upload, XCircle } from 'react-bootstrap-icons'
import { useNestedState } from 'helpers/NestedState'
import { useCurrencyContext } from 'providers/Currency'
import { useValidation } from 'helpers/Validation'
import { useAuthDataContext } from 'providers/Auth'
import { cloneDeep } from 'lodash'
import { useAppContext } from 'providers/App'

import Question from 'components/modals/Question'
import Layout from 'components/Layout'
import Article from 'components/pages/articles/partials/AddOrEdit'
// import Address from 'components/pages/clients/partials/Addresses';
import Close from './partials/Close'
import PackageCard from './partials/PackageCard'
import SelectAccountRowStorno from './partials/SelectAccountRowStorno'
// import SendMail from './partials/SendMail';
import EditAttributes from './partials/EditAttributes'

//misc
import Loader from 'components/misc/Loader'
import Autocomplete from 'components/misc/Dropdown'
import SaveButton from 'components/misc/Button'
import HelpInfo from 'components/misc/Info'
import Error from 'components/modals/Error'
import Info from 'components/modals/Error'

import moment from 'moment'

import { addExtraPrice, calcDiscount, calcExtraPrice, convertFromRateToRate, isNumeric, removeExtraPrice, vatPrice } from 'helpers/Price'
import Refs from 'Refs'
import DatePicker from 'components/partials/filter/DatePicker'
import Import from 'components/modals/Import'
import { TYPES } from 'constants/imports'
import PriceValue from 'components/partials/PriceValue'
import useInvoiceFromAccount from 'hooks/useInvoiceFromAccount'
import { CREDIT_NOTE, INVOICE } from 'constants/invoices'
import RedditTextField from 'components/misc/RedditTextField'
import RedditSelect from 'components/misc/RedditSelect'
import { MenuItem, InputAdornment, Menu, Button } from '@material-ui/core'

//images
import dropArrowIcon from 'assets/img/app/icons/drop-arrow-white.png'

//components
import CustomerDeepData from '../documents/partials/CustomerDeepData'
import axios from 'axios'
import TextField from 'components/misc/TextField'

import CloseBillForm from './partials/CloseBillForm'
import ClientConfig from 'components/misc/ClientConfig'

let timeout

function Index() {

    const params = useParams()
    const location = useLocation()
    const navigate = useHistory()
    const query = useQuery()

    const app = useAppContext()
    const auth = useAuthDataContext()
    const currencies = useCurrencyContext()

    const { createInvoiceFromAccount } = useInvoiceFromAccount()

    const store = auth.getUser().getStore()

    const defaultData = {
        store: store,
        user_id: auth.getUser().getId(),
        with_invoice: 0,
        currency_id: currencies.getDefault()?.id || '',
        currency_rate: currencies.getDefault()?.rate || '',
        currency: currencies.getDefault(),
        rows: [{
            isNew: true,
        }],
        allow_close: true,
    }

    const [state, setState] = useNestedState({
        loading: false,
        loadingBase: false,
        loadingExit: false,
        loadingClose: false,
        dataLoading: false,
        data: defaultData,
        timeout: null,
        saveButtonMenu: null,
        addNote: false,
        showClientHiddenFields: false,
    })

    const [cashdesks, setCashdesks] = useState([])
    const [paymentMethods, setPaymentMethods] = useState([])
    const [speditors, setSpeditors] = useState([])
    const [addresses, setAddresses] = useState([])
    const [focusedInputName, setFocusedInputName] = useState(null)

    const [validations, setValidations] = useValidation()

    const formRef = useRef(null)
    const clientModalRef = useRef(null)
    const articleModalRef = useRef(null)
    const addressModalRef = useRef(null)
    const questionModalRef = useRef(null)
    const errorModalRef = useRef(null)
    const infoModalRef = useRef(null)
    const closeModalRef = useRef(null)
    const packageCardModalRef = useRef(null)
    const selectStornoModalRef = useRef(null)
    const importModalRef = useRef(null)
    const sendMailModalRef = useRef(null)
    const attributesModalRef = useRef(null)
    const colNameRef = useRef(null)

    useEffect(() => {
        if (!params.id) {
            setState(prev => ({
                ...prev,
                data: defaultData
            }))
        }
    }, [params.id, auth.getUser()?.getStore()])

    useEffect(() => {
        Api.get('store/accounts/payment-methods')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setPaymentMethods(res.data)
                }
            })

        Api.get('store/accounts/speditors')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setSpeditors(res.data)
                }
            })
    }, [])

    useEffect(() => {
        Api.get('store/accounts/cashdesks', {
            params: {
                store_id: state.data.store?.id,
            }
        }).then(res => {
            if (Array.isArray(res.data)) {
                setCashdesks(res.data)
            }
        })
    }, [state.data.store?.id])

    useEffect(() => {
        if (params.id) {
            loadData()
        }
    }, [params.id])

    // зареждане на данни от поръчка/сметка
    useEffect(() => {
        if (params.id) {
            return
        }

        // от клиентска заявка
        if (query.get('from_order_id')) {
            loadDataFromOrder()
        }

        // от зареждане
        if (query.get('from_load_id')) {
            loadDataFromStoreLoad()
        }

        // от прехвърляне
        if (query.get('from_move_id')) {
            loadDataFromStoreMove()
        }

        // от сметка
        if (query.get('from_account_id')) {
            // сторно от сметка
            if (query.get('storno')) {
                loadDataFromAccountStorno()
            }
            // копие от сметка
            else {
                loadDataFromAccount()
            }
        }

        // за клиент
        if (query.get('client_id')) {
            loadDataFromClient()
        }

        // сторно от връщане
        if (query.get('from_return_id')) {
            loadDataFromReturn()
        }

        // от документ
        if (query.get('from_document_id')) {
            loadDataFromDocument()
        }
    }, [location])

    useEffect(() => {
        if (state.data.rows.length === 0) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    rows: [{
                        isNew: true,
                    }]
                }
            }))
        }
    }, [state.data.rows])

    const resetData = () => {
        setState(prev => ({
            ...prev,
            data: defaultData,
        }))

        setAddresses([])
    }

    const loadData = () => {

        loading(true)

        let url = 'store/accounts/edit-data'

        Api.get(url, {
            params: {
                id: params.id
            }
        }).then(res => {
            handleResponse(res)
        }).catch(err => {
            handleError(err)
        }).finally(() => {
            loading(false)
        })
    }

    const loadDataFromOrder = () => {

        loading(true)

        let url = 'store/accounts/from-order'

        Api.get(url, {
            params: {
                order_id: query.get('from_order_id'),
                store_id: query.get('store_id')
            }
        }).then(res => {
            handleResponse(res)
        }).catch(err => {
            handleError(err)
        }).finally(() => {
            loading(false)
        })
    }

    const loadDataFromStoreLoad = () => {

        loading(true)

        let url = 'store/accounts/from-storeload'

        Api.get(url, {
            params: {
                load_id: query.get('from_load_id')
            }
        }).then(res => {
            handleResponse(res)
        }).catch(err => {
            handleError(err)
        }).finally(() => {
            loading(false)
        })
    }

    const loadDataFromStoreMove = () => {

        loading(true)

        let url = 'store/accounts/from-storemove'

        Api.get(url, {
            params: {
                move_id: query.get('from_move_id')
            }
        }).then(res => {
            handleResponse(res)
        }).catch(err => {
            handleError(err)
        }).finally(() => {
            loading(false)
        })
    }

    const loadDataFromAccountStorno = () => {
        loading(true)

        let url = 'store/accounts/storno-from-account'

        Api.get(url, {
            params: {
                account_id: query.get('from_account_id'),
                rows: query.getAll('rows[]')
            }
        }).then(res => {
            handleResponse(res)
        }).catch(err => {
            handleError(err)
        }).finally(() => {
            loading(false)
        })
    }

    const loadDataFromAccount = () => {
        loading(true)

        let url = 'store/accounts/account-from-account'

        Api.get(url, {
            params: {
                account_id: query.get('from_account_id'),
                rows: query.getAll('rows[]')
            }
        }).then(res => {
            handleResponse(res)
        }).catch(err => {
            handleError(err)
        }).finally(() => {
            loading(false)
        })
    }

    const loadDataFromClient = () => {
        loading(true)

        let url = 'store/accounts/from-client'

        Api.get(url, {
            params: {
                client_id: query.get('client_id'),
            }
        }).then(res => {
            handleResponse(res)
        }).catch(err => {
            handleError(err)
        }).finally(() => {
            loading(false)
        })
    }

    const loadDataFromReturn = () => {

        loading(true)

        let url = 'store/accounts/storno-from-return'

        Api.get(url, {
            params: {
                return_id: query.get('from_return_id'),
                store_id: query.get('store_id')
            }
        }).then(res => {
            handleResponse(res)
        }).catch(err => {
            handleError(err)
        }).finally(() => {
            loading(false)
        })
    }
    console.log(state.data)
    const loadDataFromDocument = () => {

        loading(true)

        let url = 'store/accounts/from-document'

        Api.get(url, {
            params: {
                document_id: query.get('from_document_id')
            }
        }).then(res => {
            handleResponse(res)
        }).catch(err => {
            handleError(err)
        }).finally(() => {
            loading(false)
        })
    }

    const handleResponse = res => {
        let data = res.data

        let rows = data.rows || []

        // data.currency_rate = data.currency?.rate;

        rows = rows.map(row => {
            row.article.currency = data.currency

            return row
        })

        data.rows = rows

        setState(prev => ({
            ...prev,
            data: {
                ...data,
                customer: {
                    ...data.customer,
                    name: data.customerinfo?.name || ''
                }
            },
            addNote: !!data.description
        }))

        // възможните адреси на клиента за доставка
        setAddresses(data?.client?.addresses || [])
    }

    const handleError = error => {
        console.log(error)

        const _err = error.response

        if (_err && _err.status) {
            if (_err.status === 422) {
                setValidations(_err.data.errors)
            } else {
                let modal = errorModalRef.current

                if (modal) {
                    modal.open(_err.data.error || _err.data.message)

                    modal.onClose(() => {
                        navigate.goBack()
                    })
                }
            }
        }
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            dataLoading: Boolean(loading)
        }))
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleCheckboxChange = e => {
        const name = e.target.name

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }))
    }

    const handleCashdeskChange = e => {
        handleInputChange(e)
    }

    const handleCurrencyChange = e => {
        const name = e.target.name
        const value = e.target.value

        setCurrency(value)

    }

    const setCurrency = id => {
        let currency = currencies.data.find(c => Number(c.id) === Number(id))

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                currency_id: id,
                currency: currency,
                currency_rate: currency ? currency.rate : prev.data.currency_rate,
            }
        }))

        if (state.data.rows.filter(r => r.article).length > 0) {
            let modal = questionModalRef.current

            if (!modal) {
                return
            }

            modal.open('Искате ли да се изчислят единичните цени на артикулите спрямо избраната валута?')

            modal.onSuccess(() => {
                setState(prev => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        rows: prev.data.rows.map(r => {
                            let article = r.article

                            if (article && article.currency) {
                                r.current_price = convertFromRateToRate(r.current_price, article.currency.rate, currency.rate)
                                r.actual_price = convertFromRateToRate(r.actual_price, article.currency.rate, currency.rate)
                                r.avg_delivery_price = convertFromRateToRate(r.avg_delivery_price, article.currency.rate, currency.rate)
                                r.avg_delivery_price_with_vat = convertFromRateToRate(r.avg_delivery_price_with_vat, article.currency.rate, currency.rate)
                                r.current_delivery_price = convertFromRateToRate(r.current_delivery_price, article.currency.rate, currency.rate)
                                r.current_delivery_price_with_vat = convertFromRateToRate(r.current_delivery_price_with_vat, article.currency.rate, currency.rate)
                                r.price_ratio = calculatePriceRatio(r.current_price, r.actual_price)
                                r.article.currency = currency
                            }

                            return r
                        })
                    }
                }))
            })
        }
    }

    const handleDiscountChange = e => {
        let value = e.target.value

        if (!value.includes('+') && !value.includes('-') && value.length > 0) {
            value = '-' + value
        }

        if (value.includes('+')) {
            value = value.replace('-', '')
        }

        if (value < -100) {
            value = -100
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                discount: value,
            }
        }))
    }

    const getFormData = () => {
        let form = formRef.current

        let data = new FormData(form)

        if (params.id) {
            data.append('id', params.id)
        }

        if (query.get('from_order_id')) {
            data.append('from_order_id', query.get('from_order_id'))
        }

        if (query.get('from_account_id')) {
            data.append('from_account_id', query.get('from_account_id'))
        }

        if (query.get('from_return_id')) {
            data.append('from_return_id', query.get('from_return_id'))
        }

        if (query.get('storno')) {
            data.append('storno', query.get('storno'))
        }

        if (query.get('from_document_id')) {
            data.append('from_document_id', query.get('from_document_id'))
        }

        return data
    }

    const save = (close = false, exit = false, onSuccess = null, onError = null) => {
        if (state.loading) {
            return
        }

        setState(prev => ({
            ...prev,
            loading: true
        }))

        setValidations(null)

        let url = params.id ? 'store/accounts/edit' : 'store/accounts/add'

        if (close) {
            url = params.id ? 'store/accounts/edit-and-close' : 'store/accounts/add-and-close'
        }

        let data = getFormData()

        let body = data

        if (close) {
            body = {}

            body.id = data.get('id')
            body.from_order_id = data.get('from_order_id')
            body.from_account_id = data.get('from_account_id')
            body.from_return_id = data.get('from_return_id')
            body.storno = data.get('storno')
            body.from_document_id = data.get('from_document_id')

            body.account = Object.fromEntries(data.entries())
            body.close = Object.fromEntries(close.entries())

            body = JSON.stringify(body)
        }

        Api.post(url, body, {
            headers: {
                'content-type': close ? 'application/json' : 'multipart/form-data'
                // 'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (exit) {
                navigate.push('/accounts')
            } else {
                // navigate(buildUrl('/accounts/' + res.data.id + '/edit', {
                //     closed: close ? 1 : 0,
                // }), {
                //     replace: true
                // });

                if (close) {
                    navigate.replace(buildUrl('/accounts/new', {
                        _: moment().unix(),
                    }))

                    resetData()

                    let modal = Refs.getInstance().getRef('account')

                    modal.open(res.data.id)
                } else {

                    navigate.replace('/accounts/' + res.data.id + '/edit')

                    handleResponse(res)
                }
            }

            if (typeof onSuccess === 'function') {
                onSuccess(res.data)
            }
        }).catch(error => {
            const _err = error.response

            if (_err && _err.status) {
                if (_err.status === 422) {
                    setValidations(_err.data.errors)
                } else {
                    let modal = errorModalRef.current

                    if (modal) {
                        modal.open(_err.data.error || _err.data.message)
                    }

                    if (typeof onError === 'function') {
                        onError(error)
                    }
                }
            }
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
                loadingBase: false,
                loadingExit: false,
                loadingClose: false,
            }))
        })
    }

    const handleSave = e => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            loading: true,
            loadingBase: true,
            loadingExit: false,
            loadingClose: false,
        }))

        save(false, false, () => {
            app.showSuccess('Действието е успешно!')
        })
    }

    const handleSaveAndExit = e => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            loading: true,
            loadingBase: false,
            loadingExit: true,
            loadingClose: false,
        }))

        save(false, true, () => {
            app.showSuccess('Действието е успешно!')
        })
    }

    const handleSaveAndClose = e => {
        e.preventDefault()

        // setState(prev => ({
        //     ...prev,
        //     loading: true,
        //     loadingBase: false,
        //     loadingExit: false,
        //     loadingClose: true,
        // }));

        let modal = closeModalRef.current

        modal.open(getFormData())

        modal.onSave(data => {
            save(data, false, (res) => {
                app.showSuccess(`Сметка ${res.id} е приключена успешно!`)

                modal.hide()
            }, (err) => {
                modal.hide()
            })
        })
    }

    const handleChangeClient = data => {

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                client: data,
                client_id: data?.id || '',
                discount: Number(data?.discount) || 0,
                paymethod_id: data?.method_id,
            }
        }))

        setAddresses(data?.addresses || [])

        let address = null

        if (data?.addresses) {
            address = data.addresses[0]
        }

        handleChangeAddress(address)

    }

    const handleChangeClientName = data => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                client: null,
                client_id: '',
                paymethod_id: data?.method_id,
            }
        }))

        setAddresses([])

        handleChangeAddress(null)
    }

    const handleAddClient = () => {
        let modal = Refs.getInstance().getRef('newClient')

        if (modal) {
            modal.add()
            modal.onSuccess(client => {
                if (client) {
                    handleChangeClient(client)
                }
            })
        }
    }

    const handleShowClient = id => {
        let modal = Refs.getInstance().getRef('client')

        if (modal) {
            modal.open(id)
        }
    }

    const handleChangeUser = data => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                creator: data,
                user_id: data?.id || '',
            }
        }))
    }

    const handleChangeAddress = address => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                deliveryaddress: address,
                delivery_address_id: address?.id,
                speditor_id: address?.speditor_id,
            }
        }))
    }

    const handleEditAddress = () => {
        if (!state.data.delivery_address_id) {
            return
        }

        let modal = addressModalRef.current

        if (modal) {
            modal.edit(state.data.delivery_address_id)

            modal.onSuccess(address => {
                if (address) {
                    handleChangeAddress(address)

                    // ако го има в списъка да се обнови...
                    setAddresses(prev => prev.map(a => {
                        if (Number(a.id) === Number(address.id)) {
                            a = address
                        }

                        return a
                    }))
                }
            })
        }
    }

    const handleAddAddress = (checkClient = true) => {
        if (!state.data.client_id) {
            if (checkClient) {
                let modal = infoModalRef.current

                if (modal) {
                    modal.open('Изберете клиент')
                }
            }

            return
        }

        let modal = addressModalRef.current

        if (modal) {

            modal.add(state.data.client_id)

            modal.onSuccess(address => {
                if (address) {
                    handleChangeAddress(address)

                    // да го добавим в списъка
                    setAddresses(prev => prev.concat(address))
                }
            })
        }
    }

    const handleClickAddress = () => {
        if (addresses.length === 0) {
            handleAddAddress(false)
        }
    }

    const handleAddRow = e => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: prev.data.rows.concat({
                    isNew: true,
                })
            }
        }))
    }

    const handleRemoveRow = (e, index) => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: prev.data.rows.filter((row, i) => i !== index)
            }
        }))
    }

    const handleChangeArticleInput = (name, index) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: Object.values({
                    ...prev.data.rows,
                    [index]: {
                        ...prev.data.rows[index],
                        article: null,
                        article_id: null,
                        attrs: [], // изчистваме атрибутитие
                    }
                })
            }
        }))
    }

    const handleChangeArticle = (data, index) => {

        let row = state.data.rows[index]

        let currency = data?.currency

        data.currency = state.data.currency

        let currentPrice = convertFromRateToRate(data.current_price || data.actual_price, currency?.rate, state.data.currency_rate)
        let actualPrice = convertFromRateToRate(data.actual_price, currency?.rate, state.data.currency_rate)
        let avgDeliveryPrice = convertFromRateToRate(data.avg_delivery_price, currency?.rate, state.data.currency_rate)
        let avgDeliveryPriceWithVat = convertFromRateToRate(data.avg_delivery_price_with_vat, currency?.rate, state.data.currency_rate)
        let currentDeliveryPrice = convertFromRateToRate(data.current_delivery_price, currency?.rate, state.data.currency_rate)
        let currentDeliveryPriceWithVat = convertFromRateToRate(data.current_delivery_price_with_vat, currency?.rate, state.data.currency_rate)
        let priceRatio = calculatePriceRatio(currentPrice, actualPrice)

        let hasAttrs = data.attrs && data.attrs.length > 0

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: Object.values({
                    ...prev.data.rows,
                    [index]: {
                        ...prev.data.rows[index],
                        article: data,
                        article_id: data.id,
                        amount: data.amount_unit || 1,
                        current_price: currentPrice,
                        actual_price: actualPrice,
                        avg_delivery_price: avgDeliveryPrice,
                        avg_delivery_price_with_vat: avgDeliveryPriceWithVat,
                        current_delivery_price: currentDeliveryPrice,
                        current_delivery_price_with_vat: currentDeliveryPriceWithVat,
                        price_ratio: priceRatio,
                        price_rules: data.price_rules || [],
                        attrs: [], // изчистваме атрибутитие
                        hasPrice: !(data.current_price === null && data.actual_price === null)
                    }
                })
            }
        }))

        // ако има атрибути да покажем модала за редакцията им...
        if (hasAttrs) {
            handleEditArticleAttributes(data, [], index)
        }
    }

    const handleAddArticle = (index) => {
        let modal = Refs.getInstance().getRef('newArticle')

        if (modal) {
            modal.add()
            modal.onSuccess(article => {
                if (article) {
                    handleChangeArticle(article, index)
                }
            })
        }
    }

    const handleShowArticle = id => {
        let modal = Refs.getInstance().getRef('article')

        if (modal) {
            modal.open(id)
        }
    }

    const handleEditArticleAttributes = (article, attrs = [], index) => {
        let modal = attributesModalRef.current

        if (!modal) {
            return
        }

        modal.open(article, attrs)

        modal.onSuccess(attrs => {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    rows: Object.values({
                        ...prev.data.rows,
                        [index]: {
                            ...prev.data.rows[index],
                            attrs: attrs
                        }
                    })
                }
            }))
        })
    }

    const handleShowPriceRules = row => {
        let rules = row.price_rules || []

        if (rules.length > 0) {
            if (rules.length > 1) {
                showChoosePriceRule(rules)
            } else {
                showPriceRule(rules[0])
            }
        }
    }

    const showPriceRule = id => {
        let modal = Refs.getInstance().getRef('priceRule')

        if (!modal) {
            return
        }

        modal.edit(id)
    }

    const showChoosePriceRule = (ids) => {
        let modal = errorModalRef.current

        if (!modal) {
            return
        }

        modal.open(
            <div style={{ textAlign: 'center' }}>
                Изберете номер на ценово правило, което искате да видите:
                <br />
                <br />
                <div style={{ display: 'flex', justifyContent: 'center', }}>
                    {ids.map(id =>
                        <Button key={id} onClick={e => showPriceRule(id)} style={{ marginRight: '5px' }}>
                            {id}
                        </Button>
                    )}
                </div>
            </div>
        )
    }

    const handleRowAmountChange = (e, index) => {
        let value = e.target.value

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: Object.values({
                    ...prev.data.rows,
                    [index]: {
                        ...prev.data.rows[index],
                        amount: value,
                    }
                })
            }
        }))
    }

    const handleRowAmountBlur = (row, index) => {
        if (row.amount < 0 && !row.id) {
            showSelectStorno(row, index)
        }
    }

    const showSelectStorno = (row, index) => {
        let modal = selectStornoModalRef.current

        if (!modal) {
            return
        }

        if (!row.article_id) {
            return
        }

        modal.open(row.article_id)

        modal.onSave(selected => {
            let stornodata = {
                storno_of_account_row_id: selected.storno_of_account_row_id
            }

            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    rows: Object.values({
                        ...prev.data.rows,
                        [index]: {
                            ...prev.data.rows[index],
                            current_price: selected.current_price,
                            discount: selected.discount,
                            stornodata: stornodata,
                        }
                    })
                }
            }))
        })
    }

    const handleRowCurrentPriceChange = (e, index) => {
        let value = e.target.value

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: Object.values({
                    ...prev.data.rows,
                    [index]: {
                        ...prev.data.rows[index],
                        current_price: value,
                        price_ratio: calculatePriceRatio(value, prev.data.rows[index].actual_price)
                    }
                })
            }
        }))
    }

    const calculatePriceRatio = (from, to) => {
        return ((parseFloat(from || 0) / parseFloat(to || 0)) - 1) * 100
    }

    // при смяна на количеството 
    const handleQtyChange = (e, index) => {
        handleInputChange(e)

        let value = e.target.value

    }

    const handleRowDiscountChange = (e, index) => {
        let value = e.target.value

        if (!value.includes('+') && !value.includes('-') && value.length > 0) {
            value = '-' + value
        }

        if (value.includes('+')) {
            value = value.replace('-', '')
        }

        if (value < -100) {
            value = -100
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: Object.values({
                    ...prev.data.rows,
                    [index]: {
                        ...prev.data.rows[index],
                        discount: value
                    }
                })
            }
        }))
    }

    const handleRowPriceRatioChange = (e, index) => {
        let value = e.target.value

        if (!value.includes('+') && !value.includes('-') && value.length > 0) {
            value = '-' + value
        }

        if (value.includes('+')) {
            value = value.replace('-', '')
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: Object.values({
                    ...prev.data.rows,
                    [index]: {
                        ...prev.data.rows[index],
                        price_ratio: value,
                        current_price: prev.data.rows[index].actual_price + ((prev.data.rows[index].actual_price * value) / 100),
                    }
                })
            }
        }))
    }

    const getRowTotalPrice = row => {
        return (parseFloat(row.current_price || 0) * parseFloat(row.amount || 0))
    }

    const getRowTotalDiscount = row => {
        return calcDiscount(getRowTotalPrice(row), parseFloat(row.discount || 0))
    }

    const getRowTotalRealPrice = row => {
        return getRowTotalPrice(row) + getRowTotalDiscount(row)
    }

    const getRowPriceRatio = row => {
        return row.price_ratio || ''
    }

    const getRowsWithArticle = () => {
        return state.data.rows.filter(r => r.article_id)
    }

    const getSelectedCount = () => {
        // само редовете с избран артикул да се четат
        let rows = getRowsWithArticle()

        return rows.length
    }

    const getTotalQty = () => {
        let total = 0

        state.data.rows.map(row => {
            total += parseFloat(row.quantity || 0)
        })

        return total
    }

    const getTotalSum = () => {
        let total = 0

        state.data.rows.map(row => {
            total += getRowTotalRealPrice(row)
        })

        return total
    }

    const getTotalDiscount = () => {
        return calcDiscount(getTotalSum(), state.data.discount)
    }

    const getTotalReal = () => {
        return getTotalSum() + getTotalDiscount()
    }

    const isDiscount = () => {
        return parseFloat(state.data.discount || 0) <= 0
    }

    const isDeliveryToAddress = () => {
        return true
    }

    const getAddressName = address => {
        if (!address) {
            return ''
        }

        let name = []

        if (address.delivery_type === 'address') {
            name = [
                address.dest_name_full,
                address.address,
                address.description,
                address.country_name,
            ]
        }

        if (address.delivery_type === 'office') {
            name = [
                address.dest_name_full,
                address.office_name,
            ]
        }

        if (address.delivery_type === 'store') {
            name = [
                address?.store?.translation?.name || address?.store?.name,
            ]
        }

        return name.filter(p => p).join(', ')
    }

    const getExtendedAddressName = address => {
        if (!address) {
            return ''
        }

        let map = {
            address: 'Адрес на клиент',
            office: 'Офис на куриер',
            store: 'Търговски обект'
        }

        let type = map[address.delivery_type]

        return type + ': ' + getAddressName(address)
    }

    const handleAddCard = e => {
        e.preventDefault()

        save(false, false, (res) => {
            addCard(res)
        })

    }

    const addCard = (account) => {
        let modal = packageCardModalRef.current

        if (!modal) {
            return
        }

        modal.open(account.id, account.client_id)

        // modal.onSave(data => {
        //     // save(false, false, false, () => {
        //     //     app.showSuccess('Действието е успешно!');
        //     // });

        //     console.log(data);
        // })

        modal.onSuccess(res => {
            handleResponse(res)
        })
    }

    const handleImport = () => {
        let modal = importModalRef.current

        if (!modal) {
            return
        }

        modal.open()
        // modal.setParams(state.data);

        modal.onChunk(({ is_test, type_id, results }) => {
            // console.log(data);

            // setState(prev => ({
            //     ...prev,
            //     data: {
            //         ...prev.data,
            //         rows: results
            //     }
            // }))

            // замяна или добавяне
            let erase = Number(type_id) === 1

            let rows

            if (erase) {
                rows = []
            } else {
                rows = cloneDeep(state.data.rows)
            }

            // console.log(erase)
            // console.log(rows)
            // console.log(results);

            results.map((row, index) => {
                let data = row.article

                let currency = data?.currency

                data.currency = state.data.currency

                let currentPrice = convertFromRateToRate(row.current_price || data.current_price || data.actual_price, currency?.rate, state.data.currency_rate)
                let actualPrice = convertFromRateToRate(data.actual_price, currency?.rate, state.data.currency_rate)
                let avgDeliveryPrice = convertFromRateToRate(data.avg_delivery_price, currency?.rate, state.data.currency_rate)
                let avgDeliveryPriceWithVat = convertFromRateToRate(data.avg_delivery_price_with_vat, currency?.rate, state.data.currency_rate)
                let currentDeliveryPrice = convertFromRateToRate(data.current_delivery_price, currency?.rate, state.data.currency_rate)
                let currentDeliveryPriceWithVat = convertFromRateToRate(data.current_delivery_price_with_vat, currency?.rate, state.data.currency_rate)
                let priceRatio = calculatePriceRatio(currentPrice, actualPrice)

                rows.push({
                    ...row,
                    article: data,
                    article_id: data.id,
                    amount: data.amount_unit,
                    current_price: currentPrice,
                    actual_price: actualPrice,
                    avg_delivery_price: avgDeliveryPrice,
                    avg_delivery_price_with_vat: avgDeliveryPriceWithVat,
                    current_delivery_price: currentDeliveryPrice,
                    current_delivery_price_with_vat: currentDeliveryPriceWithVat,
                    price_ratio: priceRatio
                })

            })

            // console.log(rows);

            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    rows: Object.values(rows)
                }
            }))
        })

        modal.onSuccess(() => {
            // alert(1);
        })
    }

    const isCurrentPriceDisabled = (row) => {
        if (auth.getUser().permission('accounts_edit_current_price') === false) {
            return true
        }

        if (row.hasPrice === false && auth.getUser().permission('set_empty_price_article_in_account') === false) {
            return true
        }

        return false
    }

    const handleShow = () => {
        let modal = Refs.getInstance().getRef('account')

        modal.open(state.data.id)
    }

    const handleSend = () => {
        let modal = sendMailModalRef.current

        if (!modal) {
            return
        }

        modal.show(state.data.id, state.data.client?.email)
    }

    const handleCreateInvoice = () => {
        createInvoiceFromAccount(state.data.id, INVOICE)
            .then(res => {
                if (res) {
                    navigate.push("/invoices", {
                        data: res,
                        mode: "new",
                    })
                }
            })
    }

    const handleCreateCreditNote = () => {
        createInvoiceFromAccount(state.data.id, CREDIT_NOTE)
            .then(res => {
                if (res) {
                    navigate.push("/invoices", {
                        data: res,
                        mode: "new",
                    })
                }
            })
    }

    const handleCreateStorno = () => {
        navigate.push('/accounts/new?from_account_id=' + state.data.id + '&storno=1')
    }

    const handleCreateClone = () => {
        navigate.push('/accounts/new?from_account_id=' + state.data.id)
    }

    const handleDelete = () => {
        let modal = questionModalRef.current

        modal.open('Сигурни ли сте?')
        modal.onSuccess(() => {
            handleDestroy(state.data.id)
        })
    }

    const handleDestroy = id => {
        Api.post('store/accounts/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                navigate.push('/accounts')
            }
        })
    }

    const openMenu = e => {
        setState(prev => ({
            ...prev,
            saveButtonMenu: e.currentTarget
        }))
    }

    const closeMenu = () => {
        setState(prev => ({
            ...prev,
            saveButtonMenu: null
        }))
    }

    /**
     * 
     * @returns дали да се се покажат всички полета на клиента, след като се избере фирма
     */
    function showAllClientFields() {
        if (state.data?.customerinfo?.id) {
            if (!(state.data?.customer?.name || '').length && !(state.data?.customerinfo?.eikegn || '').length) {
                setState(prev => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        customerinfo: null,
                        customer: null,
                        customeraddress: null
                    }
                }))
            }
        }
        if (((state.data?.customer?.name || '').length || (state.data?.customerinfo?.eikegn || '').length) && state.showClientHiddenFields || state.data?.customerinfo?.id) {
            return true
        }

        return false
    }

    const clearCustomerData = () => {
        if (!state.data.customer) return
        setState(prev => ({
            ...prev,
            customer: null,
            customeraddress: null,
            costomerinfo: null
        }))
    }

    // customer
    const handleCustomerChange = (data, fromMain = false) => {
        // if (!data) return

        if (!showAllClientFields() && state.data.customer) clearCustomerData()

        if (fromMain && !Number.isNaN(Number(data))) return

        if (data && typeof data === 'object') {

            // let customer = data;
            let customer = cloneDeep(data)

            // не трябва да се подава id от търговския регистър
            if (customer.class === 'App\\Models\\TradeRegistry\\Company') {
                customer.id = null
            }

            let addresses = data.addresses.map((address, index) => {

                if (address.name === null || address.name.length === 0) {
                    address.name = `Офис ${index + 1}`
                }

                return address
            })

            customer.addresses = addresses
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    customer: customer,
                    customerinfo: customer.info,
                    customeraddress: null,
                }
            }))

            handleCustomerAddressChange(customer.addresses[0])
        } else {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    customer: {
                        id: prev?.data?.customer?.id,
                        name: data
                    },
                    // customerinfo: {
                    //     id: prev?.data?.customerinfo?.id,
                    //     eikegn: data
                    // }
                }
            }))
        }
    }

    const handleCustomerAddressChange = data => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                customeraddress: typeof data === 'object' && data !== null ? data : {
                    id: prev.data.customeraddress?.id,
                    name: data
                },
            }
        }))
    }

    function handleFocusToChangeBg(inputName) {
        setFocusedInputName(inputName)
    }

    const handleCustomerEikChange = data => {
        if (data && typeof data === 'object') handleCustomerChange(data)
    }

    /**
     * 
     * @returns дали ИН ПО ЗДДС е валиден
     */
    const isVatNumberValid = () => {
        let valid = {
            isValid: false,
            hasVat: false
        }
        if (String(state.data?.customerinfo?.vat_is_valid).length > 0) {
            valid = {
                ...valid,
                hasVat: true
            }
            if (state.data?.customerinfo?.vat_is_valid) {
                valid = {
                    ...valid,
                    isValid: true
                }
            }
        }

        return valid
    }

    const handleCustomerSettlementChange = data => {
        if (typeof data !== 'object' || Number(data?.id) !== Number(state.data?.customeraddress?.settlement?.id)) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    customeraddress: {
                        ...prev.data?.customeraddress,
                        area: null,
                        street: null,
                        street_no: null,
                        postcode: null
                    }
                }
            }))
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                customeraddress: {
                    ...prev.data?.customeraddress,
                    settlement: typeof data === 'object' ? data : {
                        id: null,
                        municipality_id: null,
                        name: data
                    },
                }
            }
        }))
    }

    const handleCheckCustomerVatNumber = () => {
        const vatNumber = state?.data?.customerinfo?.vat_number

        if (!vatNumber) return

        checkVies(vatNumber)
            .then(res => {
                if (res.valid) success('Фирмата е регистрирана по ЗДДС')
                else error('Фирмата не е регистрирана по ЗДДС')

                setState('customerinfo.vat_is_valid', res.valid, 'data')
            })
    }

    const checkVies = async number => {
        if (state.viesRequest) state.viesRequest.cancel()

        const viesRequest = axios.CancelToken.source()

        setState(prev => ({
            ...prev,
            viesRequest
        }))
        setVatLoading(true)

        return await Api.get('documents/check-vies', {
            params: {
                vat_number: number,
            },
            cancelToken: viesRequest.token
        })
            .then(res => res.data)
            .finally(() => {
                setVatLoading(false)
                renewCheckVat(false)
                setTimeout(() => {
                    renewCheckVat(true)
                }, 3000)
            })
    }

    const setVatLoading = vatLoading => {
        setState(prev => ({
            ...prev,
            vatLoading
        }))
    }

    const renewCheckVat = renewCheckVat => {
        setState(prev => ({
            ...prev,
            renewCheckVat
        }))
    }

    const success = msg => {
        app.handleSuccess(msg)
    }

    const error = msg => {
        app.handleError(msg)
    }

    const handleShowClientHiddenFields = (showClientHiddenFields, data) => {
        setState(prev => ({
            ...prev,
            showClientHiddenFields
        }))

        let isEik = false
        if (data) {
            if (!Number.isNaN(Number(data))) {
                isEik = true
            }

            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    customerinfo: {
                        id: prev?.data?.customerinfo?.id,
                        eikegn: isEik ? data : '',
                        name: !isEik ? data : ''
                    }
                }
            }))
        }
    }

    const handleAddNote = () => {
        setState(prev => ({
            ...prev,
            addNote: !prev.addNote
        }))
    }

    const handleChangeCustomerTradeObject = customeraddress => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                customeraddress
            }
        }))
    }

    return (
        <>
            <Article
                ref={articleModalRef}
            />

            {/* <Address
                ref={addressModalRef}
            /> */}

            <Question
                ref={questionModalRef}
            />

            <Error
                ref={errorModalRef}
            />

            <Info
                ref={infoModalRef}
            />

            <Close
                ref={closeModalRef}
                loading={state.loading}
                validations={validations}
            />

            {/* <SendMail
                ref={sendMailModalRef}
            /> */}

            <EditAttributes
                ref={attributesModalRef}
            />

            <PackageCard
                ref={packageCardModalRef}
                loading={state.loading}
                validations={validations}
            />

            <SelectAccountRowStorno
                ref={selectStornoModalRef}
            />

            <Import
                ref={importModalRef}
                type={TYPES.ACCOUNT}
                createText="Замяна"
                updateText="Добавяне"
            />

            <Layout>
                <div className="new-bill">
                    <div className="head-with-heading">
                        <h3
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            {params.id ? 'Редактиране на продажба' : 'Нова продажба'}
                        </h3>
                        {state.data.id &&
                            <div className="mb-3">
                                <Button className='cancel' onClick={handleShow} disabled={state.data.allow_view === false}>
                                    Преглед
                                </Button>
                                {state.data.closed && state.data.allow_invoice &&
                                    <Button className='cancel' onClick={handleCreateInvoice} style={{ marginLeft: '10px' }}>
                                        Издай фактура
                                    </Button>
                                }
                                {state.data.allow_credit_note &&
                                    <Button className='cancel' onClick={handleCreateCreditNote} style={{ marginLeft: '10px' }}>
                                        Издай кредитно известие
                                    </Button>
                                }
                                {state.data.allow_storno &&
                                    <Button className='cancel' onClick={handleCreateStorno} style={{ marginLeft: '10px' }}>
                                        Сторно сметка
                                    </Button>
                                }
                                {state.data.allow_clone &&
                                    <Button className='cancel' onClick={handleCreateClone} style={{ marginLeft: '10px' }}>
                                        Клонирай
                                    </Button>
                                }
                                {state.data.allow_delete &&
                                    <Button className='cancel' onClick={handleDelete} style={{ marginLeft: '10px' }}>
                                        Анулирай
                                    </Button>
                                }
                            </div>
                        }
                    </div>

                    <div className="panel" style={{
                        marginTop: 0
                    }}>

                        {state.dataLoading
                            ?
                            <Loader />
                            :
                            <>


                                <Form ref={formRef}>
                                    {/* <Container> */}
                                    <input type="hidden" name="store_id" value={state.data.store?.id || ''} />
                                    <div className="row">
                                        <div
                                            className="col client-holder"
                                            style={{
                                                maxWidth: '420px'
                                            }}
                                        >
                                            <h6
                                                style={{
                                                    display: 'flex'
                                                }}
                                            >
                                                Клиент
                                                {state.data?.customerinfo?.id ?
                                                    <ClientConfig
                                                        clientId={state.data?.customer?.id}
                                                        clientName={state.data?.customer?.info?.name}
                                                        clientEik={state.data?.customer?.info?.eikegn}
                                                        clientAddresses={state.data?.customer?.addresses}
                                                        activeClientAddressId={state.data?.customeraddress?.id}
                                                        handleChangeCustomerTradeObject={handleChangeCustomerTradeObject}
                                                    />
                                                    :
                                                    <></>
                                                }
                                            </h6>
                                            <div
                                                className="field"
                                                style={{
                                                    flex: 1,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                <div className="row">
                                                    <div className={`col client ${showAllClientFields() ? 'fields-shown' : ''}`}>
                                                        <div className="panel">
                                                            <CustomerDeepData
                                                                showAllClientFields={showAllClientFields}
                                                                data={state.data}
                                                                validations={validations}
                                                                handleCustomerChange={handleCustomerChange}
                                                                handleFocusToChangeBg={handleFocusToChangeBg}
                                                                focusedInputName={focusedInputName}
                                                                handleCustomerEikChange={handleCustomerEikChange}
                                                                setState={setState}
                                                                state={state}
                                                                isVatNumberValid={isVatNumberValid}
                                                                handleInputChange={handleInputChange}
                                                                handleCustomerSettlementChange={handleCustomerSettlementChange}
                                                                handleCheckCustomerVatNumber={handleCheckCustomerVatNumber}
                                                                colNameRef={colNameRef}
                                                                handleShowClientHiddenFields={handleShowClientHiddenFields}
                                                                tradeRegistryReqUrl="store/customers/trade-registry"
                                                                customersFindReqUrl="store/customers/find"
                                                                noCompany={true}
                                                                autoFocus={true}
                                                                handleChangeCustomerTradeObject={handleChangeCustomerTradeObject}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <h6>
                                                Данни за продажба
                                            </h6>
                                            <div
                                                className="field"
                                                style={{
                                                    flex: 1,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                <div className="row">
                                                    <div className="col">
                                                        <RedditTextField
                                                            size="small"
                                                            variant="filled"
                                                            margin="dense"
                                                            fullWidth
                                                            label="Сметка №"
                                                            readOnly
                                                            value={state.data.id || ''}
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <RedditTextField
                                                            size="small"
                                                            variant="filled"
                                                            margin="dense"
                                                            type="text"
                                                            fullWidth
                                                            label="Заглавие"
                                                            name="alias"
                                                            value={state.data?.alias || ''}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <RedditTextField
                                                            size="small"
                                                            variant="filled"
                                                            margin="dense"
                                                            type="datetime-local"
                                                            fullWidth
                                                            label="Дата на продажба"
                                                            name="date"
                                                            value={state.data?.date ? moment(state.data.date || '').format('YYYY-MM-DDTHH:mm') : moment().format('YYYY-MM-DDTHH:mm')}
                                                            onChange={handleInputChange}
                                                            error={Boolean(validations?.date)}
                                                            helperText={validations?.date && (validations.date[0] || validations.date)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <RedditSelect
                                                            name="cashdesk_id"
                                                            value={state.data?.cashdesk_id || cashdesks[0]?.id || ''}
                                                            onChange={handleCashdeskChange}
                                                            label="Каса"
                                                            error={Boolean(validations?.cashdesk_id)}
                                                            helperText={validations?.cashdesk_id && (validations.cashdesk_id[0] || validations.cashdesk_id)}
                                                        >
                                                            {cashdesks.map(o =>
                                                                <MenuItem key={o.id} value={o.id}>
                                                                    {o?.translation?.name || o.name}
                                                                </MenuItem>
                                                            )}
                                                        </RedditSelect>
                                                    </div>
                                                    <div className="col">
                                                        <Autocomplete
                                                            size="small"
                                                            variant="filled"
                                                            margin="dense"
                                                            url="store/accounts/admins"
                                                            inputValue={``}
                                                            inputIdName={`user_id`}
                                                            inputIdValue={state.data?.user_id || ''}
                                                            onChange={data => handleChangeUser(data)}
                                                            error={Boolean(validations && validations.user_id && (validations.user_id[0] || validations.user_id))}
                                                            helperText={validations && validations.user_id && (validations.user_id[0] || validations.user_id)}
                                                            inputPlaceholder="Обслужва"
                                                            fullWidth
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <RedditTextField
                                                            size="small"
                                                            variant="filled"
                                                            margin="dense"
                                                            type="datetime-local"
                                                            fullWidth
                                                            label="Създадена на"
                                                            name="created_at"
                                                            readOnly
                                                            value={state.data?.created_at ? moment(state.data.created_at).format('YYYY-MM-DDTHH:mm') : moment().format('YYYY-MM-DDTHH:mm')}
                                                            onChange={handleInputChange}
                                                            error={Boolean(validations?.created_at)}
                                                            helperText={validations?.created_at && (validations.created_at[0] || validations.created_at)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <RedditSelect
                                                            name="currency_id"
                                                            value={state.data?.currency_id || ''}
                                                            onChange={handleCurrencyChange}
                                                            label="Валута"
                                                            error={Boolean(validations?.currency_id)}
                                                            helperText={validations?.currency_id && (validations.currency_id[0] || validations.currency_id)}
                                                        >
                                                            {currencies.data.map(o =>
                                                                <MenuItem key={o.id} value={o.id}>
                                                                    {o?.translation?.name || o.name}
                                                                </MenuItem>
                                                            )}
                                                        </RedditSelect>
                                                    </div>
                                                    <div className="col">
                                                        <RedditTextField
                                                            size="small"
                                                            variant="filled"
                                                            margin="dense"
                                                            type="text"
                                                            fullWidth
                                                            label="Отстъпка/Надценка"
                                                            name="discount"
                                                            value={state.data?.discount || ''}
                                                            onChange={handleDiscountChange}
                                                        />
                                                    </div>
                                                    <div className="col">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div
                                            class="col"
                                            style={{
                                                maxWidth: '470px'
                                            }}
                                        >
                                            <h6>
                                                Плащане
                                            </h6>
                                            <div className="field">
                                                <CloseBillForm
                                                    id={state.data.id}
                                                />
                                            </div>
                                        </div> */}
                                    </div>

                                    <div className="row">
                                        <div className="col">
                                            <h6>
                                                Артикули
                                            </h6>
                                            <div className="field">
                                                <table className="type-doc">
                                                    <thead>
                                                        <tr>
                                                            <th className="options">

                                                            </th>
                                                            <th style={{ width: '40px' }}>
                                                                №
                                                            </th>
                                                            <th style={{ width: '15%' }}>
                                                                Артикул
                                                            </th>
                                                            <th>
                                                                Количество
                                                            </th>
                                                            <th>
                                                                Единична цена
                                                            </th>
                                                            <th>
                                                                Отстъпка
                                                            </th>
                                                            <th>
                                                                Тотал
                                                            </th>
                                                            {auth.getUser().permission('show_delivery_price') &&
                                                                <>
                                                                    <th>
                                                                        Себестойност
                                                                    </th>
                                                                    <th>
                                                                        Последно зареждане
                                                                    </th>
                                                                </>
                                                            }
                                                            <th>
                                                                Базова цена
                                                            </th>
                                                            <th>
                                                                Баз$ / Тек$
                                                            </th>
                                                        </tr>

                                                    </thead>
                                                    <tbody>
                                                        {state.data.rows.map((row, index) =>
                                                            <tr key={'r-' + index} className={row.is_service ? 'trqbva-da-e-disabled' : ''}>
                                                                <td className="options">
                                                                    <div style={{ display: 'flex', gap: '5px' }}>
                                                                        <Button className='small remove' onClick={e => handleRemoveRow(e, index)}>
                                                                        </Button>
                                                                        {/* {(row.price_rules || []).length > 0 &&
                                                                    <Button variant="primary" size="sm" onClick={e => handleShowPriceRules(row)}>
                                                                        <CurrencyDollar />
                                                                    </Button>
                                                                } */}
                                                                    </div>
                                                                </td>
                                                                <td title={row.id || '-'} style={{ width: '40px' }}>
                                                                    {index + 1}

                                                                    <input type="hidden" name={`rows[${index}][id]`} value={state.data.rows[index].id || ''} />

                                                                    {row.stornodata &&
                                                                        <input type="hidden" name={`rows[${index}][stornodata][storno_of_account_row_id]`} value={row.stornodata.storno_of_account_row_id || ''} />
                                                                    }

                                                                    <input type="hidden" name={`rows[${index}][article_id]`} value={state.data.rows[index].article_id || ''} />
                                                                    <input type="hidden" name={`rows[${index}][depot_id]`} value={state.data.rows[index].depot_id || ''} />

                                                                    {(row.price_rules || []).map(id =>
                                                                        <input key={id} type="hidden" name={`rows[${index}][price_rules][]`} value={id} />
                                                                    )}
                                                                </td>
                                                                <td style={{ width: '15%' }}>
                                                                    {row.is_special
                                                                        ?
                                                                        <>
                                                                            <RedditTextField
                                                                                margin="none"
                                                                                size="small"
                                                                                variant="outlined"
                                                                                type="text"
                                                                                fullWidth
                                                                                value={state.data?.rows[index] && state.data?.rows[index]?.article?.article_name ? state.data?.rows[index]?.article?.article_name : ''}
                                                                                onChange={handleInputChange}
                                                                                error={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].article_id && (validations.rows[index].article_id[0] || validations.rows[index].article_id))}
                                                                                helperText={validations && validations.rows && validations.rows[index] && validations.rows[index].article_id && (validations.rows[index].article_id[0] || validations.rows[index].article_id)}
                                                                                className="quantity-input input-with-right-label"
                                                                            />
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <Autocomplete
                                                                                margin="none"
                                                                                size="small"
                                                                                variant="outlined"
                                                                                url="store/accounts/articles"
                                                                                params={{
                                                                                    client_id: state.data.client_id,
                                                                                }}
                                                                                // inputPlaceholder="Име"
                                                                                inputName="article_name"
                                                                                inputIdName={`rows[${index}][article_id]`}
                                                                                inputValue={state.data?.rows[index] && state.data?.rows[index]?.article?.article_name ? state.data?.rows[index]?.article?.article_name : ''}
                                                                                inputIdValue={state.data?.rows[index] && state.data?.rows[index].article_id ? state.data?.rows[index].article_id : ''}
                                                                                onChange={data => handleChangeArticle(data, index)}
                                                                                onInputChange={data => handleChangeArticleInput(data, index)}
                                                                                onClickAddButton={e => handleAddArticle(index)}
                                                                                onClickShowButton={handleShowArticle}
                                                                                error={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].article_id && (validations.rows[index].article_id[0] || validations.rows[index].article_id))}
                                                                                helperText={validations && validations.rows && validations.rows[index] && validations.rows[index].article_id && (validations.rows[index].article_id[0] || validations.rows[index].article_id)}
                                                                                renderText={data => {
                                                                                    return (
                                                                                        <div style={{ display: 'flex', }}>
                                                                                            <div style={{ marginRight: '10px' }}>
                                                                                                {data.id}
                                                                                            </div>
                                                                                            <div style={{ width: '150px', marginRight: '10px' }}>
                                                                                                {data.article_name}
                                                                                            </div>
                                                                                            <div style={{ width: '150px', marginRight: '10px' }}>
                                                                                                {data.ref_num}
                                                                                            </div>
                                                                                            <div style={{ width: '100px', marginRight: '20px', textAlign: 'right' }}>
                                                                                                {data.store_quantity || 0} {data.amount_type_short}
                                                                                            </div>
                                                                                            <div>
                                                                                                <PriceValue prefix={data.currency?.prefix} sufix={data.currency?.sufix}>{data.current_price}</PriceValue>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }}
                                                                                renderInputText={data => data.article_name}
                                                                                preventInitialLoad={true}
                                                                                autoFocus={row.isNew ? true : false}
                                                                                addButtonDisabled={auth.getUser().permission('articles_edit') === false}
                                                                                listStyle={{
                                                                                    minWidth: 'max-content'
                                                                                }}
                                                                                className="quantity-input input-with-right-label"
                                                                            />

                                                                        </>
                                                                    }

                                                                    {row.attrs && row.attrs.length > 0 &&
                                                                        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                                                                            <div>
                                                                                {row.attrs.map(attr =>
                                                                                    <div key={attr.id}>
                                                                                        /{attr.name}: {attr.value}/

                                                                                        <input type="hidden" name={`rows[${index}][attrs][${attr.id}]`} value={attr.value || ''} />
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                            <PencilSquare
                                                                                onClick={e => handleEditArticleAttributes(row.article, row.attrs, index)}
                                                                                style={{
                                                                                    fontSize: '14px',
                                                                                    marginLeft: '10px',
                                                                                    marginTop: '3px',
                                                                                    cursor: 'pointer'
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    }

                                                                    {/* {row.stornodata &&
                                                                <>
                                                                    <span style={{ fontSize: '12px' }}>
                                                                        Сторно на сметка №{row.stornodata.storno_of_account_row_id}
                                                                    </span>

                                                                    {!row.id &&
                                                                        <span
                                                                            onClick={e => showSelectStorno(row, index)}
                                                                        >
                                                                            промени
                                                                        </span>
                                                                    }
                                                                </>
                                                            } */}
                                                                </td>

                                                                <td>
                                                                    <TextField
                                                                        margin="none"
                                                                        size="small"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        type="number"
                                                                        min="0"
                                                                        step="0.001"
                                                                        onChange={e => handleRowAmountChange(e, index)}
                                                                        onBlur={e => handleRowAmountBlur(row, index)}
                                                                        value={state.data?.rows[index] && state.data?.rows[index].amount ? state.data?.rows[index].amount : ''}
                                                                        name={`rows[${index}][amount]`}
                                                                        readOnly={auth.getUser().permission('accounts_allow_quantity_change') === false}
                                                                        error={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].amount && (validations.rows[index].amount[0] || validations.rows[index].amount))}
                                                                        helperText={validations && validations.rows && validations.rows[index] && validations.rows[index].amount && (validations.rows[index].amount[0] || validations.rows[index].amount)}
                                                                        InputProps={state.data?.rows[index]?.article?.amount_type_short ? {
                                                                            endAdornment: <InputAdornment position="end">{state.data?.rows[index]?.article?.amount_type_short || '-'}</InputAdornment>,
                                                                        } : {}}
                                                                        className="quantity-input input-with-right-label"
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <TextField
                                                                        margin="none"
                                                                        size="small"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        type="number"
                                                                        min="0"
                                                                        step="0.001"
                                                                        onChange={e => handleRowCurrentPriceChange(e, index)}
                                                                        value={state.data?.rows[index].current_price || ''}
                                                                        name={`rows[${index}][current_price]`}
                                                                        readOnly={row.is_special ? true : false}
                                                                        disabled={isCurrentPriceDisabled(row)}
                                                                        error={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].current_price && (validations.rows[index].current_price[0] || validations.rows[index].current_price))}
                                                                        helperText={validations && validations.rows && validations.rows[index] && validations.rows[index].current_price && (validations.rows[index].current_price[0] || validations.rows[index].current_price)}
                                                                        InputProps={{
                                                                            endAdornment: <InputAdornment position="end">{state.data.currency.prefix || state.data.currency.sufix || '-'}/{state.data?.rows[index]?.article?.amount_type_short || '-'}</InputAdornment>,
                                                                        }}
                                                                        className="quantity-input input-with-right-label"
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <TextField
                                                                        margin="none"
                                                                        size="small"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        type="number"
                                                                        min="0"
                                                                        step="0.001"
                                                                        onChange={e => handleRowDiscountChange(e, index)}
                                                                        value={state.data?.rows[index].discount || ''}
                                                                        name={`rows[${index}][discount]`}
                                                                        readOnly={row.is_special ? true : false}
                                                                        disabled={isCurrentPriceDisabled(row)}
                                                                        error={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].discount && (validations.rows[index].discount[0] || validations.rows[index].discount))}
                                                                        helperText={validations && validations.rows && validations.rows[index] && validations.rows[index].discount && (validations.rows[index].discount[0] || validations.rows[index].discount)}
                                                                        InputProps={{
                                                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                                        }}
                                                                        className="quantity-input input-with-right-label"
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <TextField
                                                                        margin="none"
                                                                        size="small"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        type="number"
                                                                        min="0"
                                                                        step="0.001"
                                                                        value={getRowTotalRealPrice(row)}
                                                                        disabled
                                                                        InputProps={state.data?.currency ? {
                                                                            endAdornment: <InputAdornment position="end">{state.data.currency.prefix || state.data.currency.sufix || '-'}</InputAdornment>,
                                                                        } : {}}
                                                                        className="quantity-input input-with-right-label"
                                                                    />
                                                                </td>
                                                                {auth.getUser().permission('show_delivery_price') &&
                                                                    <>
                                                                        <td>
                                                                            <TextField
                                                                                margin="none"
                                                                                size="small"
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                type="number"
                                                                                min="0"
                                                                                step="0.001"
                                                                                value={row.avg_delivery_price_with_vat || ''}
                                                                                disabled
                                                                                InputProps={state.data?.currency ? {
                                                                                    endAdornment: <InputAdornment position="end">{state.data.currency.prefix || state.data.currency.sufix || '-'}/{state.data?.rows[index]?.article?.amount_type_short || '-'}</InputAdornment>,
                                                                                } : {}}
                                                                                className="quantity-input input-with-right-label"
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <TextField
                                                                                margin="none"
                                                                                size="small"
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                type="number"
                                                                                min="0"
                                                                                step="0.001"
                                                                                value={row.current_delivery_price_with_vat || ''}
                                                                                disabled
                                                                                InputProps={state.data?.currency ? {
                                                                                    endAdornment: <InputAdornment position="end">{state.data.currency.prefix || state.data.currency.sufix || '-'}/{state.data?.rows[index]?.article?.amount_type_short || '-'}</InputAdornment>,
                                                                                } : {}}
                                                                                className="quantity-input input-with-right-label"
                                                                            />
                                                                        </td>
                                                                    </>
                                                                }
                                                                <td>
                                                                    <TextField
                                                                        margin="none"
                                                                        size="small"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        type="number"
                                                                        min="0"
                                                                        step="0.001"
                                                                        value={row.actual_price || ''}
                                                                        disabled
                                                                        InputProps={state.data?.currency ? {
                                                                            endAdornment: <InputAdornment position="end">{state.data.currency.prefix || state.data.currency.sufix || '-'}/{state.data?.rows[index]?.article?.amount_type_short || '-'}</InputAdornment>,
                                                                        } : {}}
                                                                        className="quantity-input input-with-right-label"
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <TextField
                                                                        margin="none"
                                                                        size="small"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        type="number"
                                                                        min="0"
                                                                        step="0.001"
                                                                        name={`rows[${index}][price_ratio]`}
                                                                        value={getRowPriceRatio(row)}
                                                                        readOnly={row.is_special ? true : false}
                                                                        error={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].price_ratio && (validations.rows[index].price_ratio[0] || validations.rows[index].price_ratio))}
                                                                        helperText={validations && validations.rows && validations.rows[index] && validations.rows[index].price_ratio && (validations.rows[index].price_ratio[0] || validations.rows[index].price_ratio)}
                                                                        InputProps={{
                                                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                                        }}
                                                                        className="quantity-input input-with-right-label"
                                                                    />
                                                                </td>
                                                            </tr>
                                                        )}
                                                        {/* <tr>
                                                    <td colSpan={4}>
                                                        Общо
                                                    </td>
                                                    <td className="text-right">
                                                        {getTotalQty().toFixed(4)}
                                                    </td>
                                                </tr> */}

                                                    </tbody>
                                                </table>
                                                <hr />

                                                <div className="row">
                                                    <div
                                                        className="col"
                                                        style={{
                                                            paddingLeft: '10px'
                                                        }}
                                                    >
                                                        <Button
                                                            className="small add"
                                                            onClick={handleAddRow}
                                                            style={{
                                                                marginBottom: '10px'
                                                            }}
                                                        >
                                                        </Button>

                                                        {state.addNote ?
                                                            <RedditTextField
                                                                size="small"
                                                                variant="filled"
                                                                margin="dense"
                                                                type="text"
                                                                fullWidth
                                                                label="Забележка"
                                                                name="description"
                                                                value={state.data?.description || ''}
                                                                onChange={handleInputChange}
                                                                error={Boolean(validations?.description)}
                                                                helperText={validations?.description && (validations.description[0] || validations.description)}
                                                                multiline
                                                                rows={3}
                                                                autoFocus={true}
                                                            />
                                                            :
                                                            <input hidden value={null} name="description" />
                                                        }
                                                        <span
                                                            className="link"
                                                            style={{
                                                                width: 'fit-content',
                                                                marginTop: '10px',
                                                            }}
                                                            onClick={handleAddNote}
                                                        >
                                                            {state.addNote ? 'Премахване' : 'Добавяне'} на забележка
                                                        </span>
                                                    </div>
                                                    <div className="col">
                                                        <table style={{
                                                            width: '300px',
                                                            marginLeft: 'auto'
                                                        }}>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="text-right">
                                                                        Стойност
                                                                    </td>
                                                                    <td
                                                                        className="bold text-right"
                                                                        style={{
                                                                            maxWidth: '150px'
                                                                        }}
                                                                    >
                                                                        {`${getTotalSum().toFixed(2)} ${state.data.currency.prefix || state.data.currency.sufix || '-'}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-right">
                                                                        {isDiscount() ? 'Отстъпка' : 'Надценка'} ({state.data.discount}%)
                                                                    </td>
                                                                    <td
                                                                        className="bold text-right"
                                                                        style={{
                                                                            maxWidth: '150px'
                                                                        }}
                                                                    >
                                                                        {`${getTotalDiscount().toFixed(2)} ${state.data.currency.prefix || state.data.currency.sufix || '-'}`}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-right">
                                                                        Сума за плащане
                                                                    </td>
                                                                    <td
                                                                        className="bold text-right"
                                                                        style={{
                                                                            maxWidth: '150px'
                                                                        }}
                                                                    >
                                                                        {`${getTotalReal().toFixed(2)} ${state.data.currency.prefix || state.data.currency.sufix || '-'}`}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="footer">
                                        <Menu
                                            anchorEl={state.saveButtonMenu}
                                            elevation={0}
                                            getContentAnchorEl={null}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                            transformOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                            keepMounted
                                            open={Boolean(state.saveButtonMenu)}
                                            onClose={closeMenu}
                                            onClick={closeMenu}
                                        >
                                            <MenuItem
                                                onClick={handleSave}
                                            >
                                                Запази и продължи
                                            </MenuItem>
                                            <MenuItem
                                                onClick={handleSaveAndClose}
                                            >
                                                Запази и приключи сметката
                                            </MenuItem>

                                        </Menu>
                                        <Button
                                            className="cancel"
                                            onClick={navigate.goBack}
                                        >
                                            Отказ
                                        </Button>
                                        <SaveButton
                                            className="save with-dropdown"
                                            loading={state.loading || state.loadingBase || state.loadingExit}
                                            disabled={getSelectedCount() === 0}
                                            onClick={handleSaveAndExit}
                                        >
                                            Запази
                                            <i
                                                className="menu"
                                                onClick={openMenu}
                                            >
                                                <img src={dropArrowIcon} />
                                            </i>
                                        </SaveButton>
                                    </div>

                                </Form>
                            </>
                        }

                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Index