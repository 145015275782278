//partials
import IncomeCategories from "./incomes/Index"
import ExpenseCategories from "./expenses/Index"

function Categories({ screen }) {

    return (
        screen === 'categories/incomes' ?
            <IncomeCategories />
            :
            screen === 'categories/expenses' ?
                <ExpenseCategories />
                :
                <>
                </>
    )
}

export default Categories