//hooks
import useFetchLastDocuments from "../hooks/useFetchLastDocuments"

//images
import loader from "assets/img/loader.svg"

function LastDocuments() {
  const { getLastDocuments, isLoading } = useFetchLastDocuments()

  if (isLoading) {
    return <img src={loader} alt="loading" />
  }

  if (!isLoading && !getLastDocuments().length) {
    return (
      <p className="no-data">Няма добавени документи през Вашият профил.</p>
    )
  }

  return (
    <div className="main-info-holder">
      <table className="type-outer">
        <thead>
          <tr>
            <th>№</th>
            <th>Дата</th>
            <th>Получател</th>
            <th className="text-right">Общо</th>
          </tr>
        </thead>
        <tbody>
          {getLastDocuments().map((d) => (
            <tr key={d.id}>
              <td>{d.no}</td>
              <td>{d.date_dmy}</td>
              <td>{d.customer.info.name}</td>
              <td className="text-right">
                {d.gross_price_formatted} {d.currency.name}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default LastDocuments
