import { useRef, useState } from "react"
import { createPortal } from "react-dom"
import { useHistory } from "react-router-dom"

//libraries
import { Menu, MenuItem, Tooltip } from "@material-ui/core"

//components
import MenuIndicator from "components/misc/MenuIndicator"
import Question from "components/modals/Question"

//images
import deleteIcon from "assets/img/app/icons/delete.png"
import dublicateIcon from "assets/img/app/icons/dublicate.png"
import finishIcon from "assets/img/articles/icons/finish.png"

//helpers
import Api from "helpers/Api"

export default function PreviewBillOptions({ isBillClosed, billId }) {
  const [showMenu, setShowMenu] = useState(null)

  const navigate = useHistory()

  const questionModalRef = useRef(null)

  function handleDelete() {
    const modal = questionModalRef.current

    modal.open("Сигурни ли сте, че искате да анулирате продажбата?")
    modal.onSuccess(handleDestroy)
  }

  function handleDestroy() {
    Api.post("store/accounts/delete", {
      id: billId,
    }).then(() => {
      navigate.push("/accounts")
    })
  }

  function handleCreateClone() {
    navigate.push(`/accounts/new?from_account_id=${billId}`)
  }

  function handleClose() {
    alert("Липсва функционалност...")
  }

  return (
    <>
      <div className="options-holder">
        <div className="buttons">
          {isBillClosed ? (
            <button className="normal dismiss-bill" onClick={(e) => {}}>
              Сторно
            </button>
          ) : (
            <button className="normal edit" onClick={(e) => {}}>
              Редакция
            </button>
          )}
          <Tooltip title="Още опции">
            <button
              className="small"
              onClick={(e) => {
                setShowMenu(e.target)
              }}
            >
              <MenuIndicator
                active={true}
                style={{
                  margin: "0 auto",
                }}
              />
            </button>
          </Tooltip>
          <Menu
            anchorEl={showMenu}
            keepMounted
            open={Boolean(showMenu)}
            onClose={() => {
              setShowMenu(null)
            }}
            onClick={(e) => {
              setShowMenu(null)
            }}
            anchorOrigin={{ vertical: "bottom" }}
            className="custom-menu-options"
          >
            <MenuItem onClick={handleClose}>
              <img src={finishIcon} />
              Приключи
            </MenuItem>
            <MenuItem onClick={handleCreateClone}>
              <img src={dublicateIcon} />
              Клонирай
            </MenuItem>
            <MenuItem onClick={handleDelete}>
              <img src={deleteIcon} />
              Анулирай
            </MenuItem>
          </Menu>
        </div>
      </div>
      {createPortal(<Question ref={questionModalRef} agreeBtnText="Анулирай" />, document.body)}
    </>
  )
}
