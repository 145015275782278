import { useRef } from 'react'
import { Button } from '@material-ui/core'
import Api from 'helpers/Api'
import Loader from 'components/misc/Loader'
import moment from 'moment'
import PriceValue from 'components/partials/PriceValue'
import { useHistory } from 'react-router-dom'
import { isNumeric } from 'helpers/Price'
import { useAppContext } from 'providers/App'
import SaveButton from 'components/misc/Button'
import PrintButton from 'components/misc/PrintButton'
import Refs from 'Refs'
import { buildUrl } from 'helpers/Url'
import { useAuthDataContext } from 'providers/Auth'
import Rows from './Rows'
import { baseUrl } from 'helpers/mode'
import useInvoiceFromAccount from 'hooks/useInvoiceFromAccount'
import { CREDIT_NOTE, INVOICE } from 'constants/invoices'

//images
import editIcon from 'assets/img/accounts/icons/edit.png'
import invoiceIcon from 'assets/img/accounts/icons/invoice.png'
import billIcon from 'assets/img/accounts/icons/bill.png'

function Index(props) {

    const app = useAppContext()
    const auth = useAuthDataContext()
    const navigate = useHistory()

    const { show, hide } = props
    const { state, setState } = props

    const printButtonRef = useRef()
    const printVoucherButtonRef = useRef()

    const { createInvoiceFromAccount, isLoading } = useInvoiceFromAccount()

    const getAddressName = address => {
        if (!address) {
            return ''
        }

        let name = []

        if (address.delivery_type === 'address') {
            name = [
                address.dest_name_full,
                address.address,
                address.description,
                address.country_name,
            ]
        }

        if (address.delivery_type === 'office') {
            name = [
                address.dest_name_full,
                address.office_name,
            ]
        }

        if (address.delivery_type === 'store') {
            name = [
                address?.store?.translation?.name || address?.store?.name,
            ]
        }

        return name.filter(p => p).join(', ')
    }

    const getExtendedAddressName = address => {
        if (!address) {
            return ''
        }

        let map = {
            address: 'Адрес на клиент',
            office: 'Офис на куриер',
            store: 'Търговски обект'
        }

        let type = map[address.delivery_type]

        return type + ': ' + getAddressName(address)
    }

    const showClient = () => {
        props.showClient(state.data.client_id || state.data.client.id)
    }

    const showArticle = id => {
        props.showArticle(id)
    }

    const handleEdit = () => {
        navigate.push('/accounts/' + state.data.id + '/edit')
    }

    const sendMail = () => {
        props.sendMail(state.data)
    }

    const sendReceipt = () => {
        props.sendReceipt(state.data)
    }

    const editDeliveryData = () => {
        props.editDeliveryData()
    }

    const handleCreateInvoice = () => {
        createInvoiceFromAccount(state.data.id, INVOICE)
            .then(res => {
                if (res) {
                    props.hide()

                    navigate.push("/invoices", {
                        data: res,
                        mode: "new",
                    })
                }
            })
    }

    const handleCreateCreditNote = () => {
        createInvoiceFromAccount(state.data.id, CREDIT_NOTE)
            .then(res => {
                if (res) {
                    props.hide()

                    navigate.push("/invoices", {
                        data: res,
                        mode: "new",
                    })
                }
            })
    }

    const handleCreateStorno = () => {
        props.hide()

        navigate.push('/accounts/new?from_account_id=' + state.data.id + '&storno=1')
    }

    const handleCreateClone = () => {
        props.hide()

        navigate.push('/accounts/new?from_account_id=' + state.data.id)
    }

    const handleCreateShipmentRequests = (payer) => {
        createShipmentRequests(payer)
    }

    const createShipmentRequests = (payer) => {
        setState(prev => ({
            ...prev,
            loadingShipmentRequests: true,
        }))

        Api.post('store/accounts/create-shipment-requests', {
            id: state.data.id,
            payer: payer,
        }).then(res => {
            if (res.data.error) {
                app.showError(res.data.error)
            } else {
                setState(prev => ({
                    ...prev,
                    data: res.data
                }))
            }
        }).catch(err => {
            app.showError()
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loadingShipmentRequests: false,
            }))
        })
    }

    const handlePrintShipmentRequest = number => {
        if (number) {
            printShipmentRequest(number)
        } else {
            printShipmentRequest(state.data.tracking_numbers[0])
        }
    }

    const handlePrintReturnVoucher = number => {
        if (number) {
            printReturnVoucher(number)
        } else {
            printReturnVoucher(state.data.tracking_numbers[0])
        }
    }

    const trackOrder = number => {
        window.open(
            process.env.REACT_APP_URL + '/check-order?id=' + number,
            '_blank',
            'resizable=1,width=500,height=700'
        )
    }

    const printShipmentRequest = number => {
        printButtonRef.current.print(
            getPrintShipmentRequestUrl(number)
        )
    }

    const printReturnVoucher = number => {
        printVoucherButtonRef.current.print(
            getPrintReturnVoucherUrl(number)
        )
    }

    const getPrintShipmentRequestUrl = number => {
        let url = buildUrl(baseUrl + 'store/accounts/print-shipment-request', {
            id: state.data.id,
            tracking_number: number,
            token: auth.getToken()
        })

        return url
    }

    const getPrintReturnVoucherUrl = number => {
        let url = buildUrl(baseUrl + 'store/accounts/print-return-voucher', {
            id: state.data.id,
            tracking_number: number,
            token: auth.getToken()
        })

        return url
    }

    return (
        <>
            {state.loading
                ?
                <Loader />
                :
                <>
                    <div className="preview-article-home">
                        <div className="options-bar">
                            {state.data.allow_edit ?
                                <Button className="cancel" onClick={handleEdit}>
                                    <img src={editIcon} />
                                    Редактиране
                                </Button>
                                :
                                <>
                                </>
                            }
                            <Button disabled={isLoading} className="cancel" onClick={handleCreateInvoice} >
                                <img src={invoiceIcon} />
                                Фактура
                            </Button>
                            {state.data.allow_credit_note &&
                                <Button disabled={isLoading} className="cancel" onClick={handleCreateCreditNote} >
                                    <img src={invoiceIcon} />
                                    Кредитно известие
                                </Button>
                            }
                            {state.data.allow_storno &&
                                <Button className="cancel" onClick={handleCreateStorno} >
                                    <img src={billIcon} />
                                    Сторно сметка
                                </Button>
                            }
                            {state.data.allow_clone &&
                                <Button className="cancel" onClick={handleCreateClone} >
                                    Клонирай
                                </Button>
                            }
                        </div>
                        <div className="row">
                            <div className="col">
                                <h6>
                                    Описание на поръчката
                                </h6>
                                <div className="field">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="th" style={{ width: '13.66666%' }}>
                                                    ID
                                                </td>
                                                <td style={{ width: '19.66666%' }}>
                                                    {state.data.id || '-'}
                                                </td>
                                                <td className="th" style={{ width: '13.66666%' }}>
                                                    Обслужва
                                                </td>
                                                <td style={{ width: '19.66666%' }}>
                                                    {state.data?.creator?.username || '-'}
                                                </td>
                                                <td className="th" style={{ width: '13.66666%' }}>
                                                    Отворена на
                                                </td>
                                                <td style={{ width: '19.66666%' }}>
                                                    {state.data.created_at ? moment(state.data.created_at).format('DD.MM.YYYY HH:mm') : '-'}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="th">
                                                    Търговски обект
                                                </td>
                                                <td>
                                                    {state.data.store?.translation?.name || state.data.store?.name || '-'}
                                                </td>
                                                <td className="th">
                                                    Каса
                                                </td>
                                                <td>
                                                    {state.data.cashdesk?.translation?.name || state.data.cashdesk?.name || '-'}
                                                </td>
                                                <td className="th">
                                                    Актуализирано на
                                                </td>
                                                <td>
                                                    {state.data.updated_at ? moment(state.data.updated_at).format('DD.MM.YYYY HH:mm') : '-'}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="th">
                                                    Документ дата
                                                </td>
                                                <td>
                                                    {state.data.date ? moment(state.data.date).format('DD.MM.YYYY') : '-'}
                                                </td>
                                                <td className="th">
                                                    Плащане
                                                </td>
                                                <td>
                                                    {state.data?.paymentmethod?.translation?.name || '-'}
                                                </td>
                                                <td className="th">
                                                    Закрита на
                                                </td>
                                                <td>
                                                    {state.data.closed_at ? moment(state.data.closed_at).format('DD.MM.YYYY HH:mm') : '-'}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="th">
                                                    Клиент
                                                </td>
                                                <td colSpan={3} style={{ cursor: 'pointer' }} onClick={showClient}>
                                                    {state.data.client_name || state.data?.client?.name || '-'}
                                                    {isNumeric(state.data?.client?.discount) ?
                                                        <span className="discount">
                                                            {state.data?.client?.discount}%
                                                        </span>
                                                        :
                                                        <>
                                                        </>
                                                    }
                                                </td>
                                                <td className="th">
                                                    Телефон
                                                </td>
                                                <td>
                                                    {state.data.tel || '-'}
                                                </td>
                                            </tr>

                                            <tr>
                                                <td className="th">
                                                    Доставка до
                                                </td>
                                                <td>
                                                    Адрес на клиента
                                                </td>
                                                <td className="th">
                                                    Товарителница №
                                                </td>
                                                <td>
                                                    {(state.data.tracking_numbers || []).length ? state.data.tracking_numbers.map(number =>
                                                        <>
                                                            {/* <span key={number} className="link" onClick={e => trackOrder(number)}> */}
                                                            {number}
                                                            {/* </span> */}
                                                        </>
                                                    ) : '-'}
                                                </td>
                                                <td className="th">
                                                    Спедитор
                                                </td>
                                                <td>
                                                    {state.data.speditor?.name || '-'}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="th">
                                                    Данни за доставка
                                                </td>
                                                <td colSpan={5}>
                                                    {state.data.deliveryaddres ? getExtendedAddressName(state.data.deliveryaddress) : '-'}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="th">
                                                    Заглавие
                                                </td>
                                                <td colSpan={5}>
                                                    {state.data.alias || '-'}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="th">
                                                    Описание
                                                </td>
                                                <td colSpan={5}>
                                                    {state.data.description || '-'}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="th">
                                                    Допълнителни
                                                </td>
                                                <td colSpan={5}>
                                                    {state.data.extra_details || '-'}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <h6>
                                    Артикули
                                </h6>
                                <div className="field">
                                    <Rows
                                        id={state.data.id}
                                        show={show}
                                        hide={hide}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </>
            }
        </>
    )
}

export default Index