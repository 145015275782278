export const TYPES = {
    ARTICLE: 'article',
    STORELOAD: 'storeload',
    STORELOADCHECKUP: 'storeloadcheckup',
    STOREMOVE: 'storemove',
    STOREMOVECHECKUP: 'storemovecheckup',
    STOREOUT: 'storeout',
    REVISION: 'revision',
    ACCOUNT: 'account',
    PRICERULE: 'pricerule',
    ARTICLEGROUP: 'articlegroup',
};

export const OPERATION_TYPE_IDS = {
    article: 1,
    storeload: 101,
    storeloadcheckup: 111,
    storemove: 102,
    storemovecheckup: 112,
    revision: 103,
    account: 1001,
    pricerule: 2001,
    articlegroup: 3001,
};