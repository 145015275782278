import React, { useRef, useState } from 'react';
import { Box, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import SaveButton from '../../../misc/Button';
import Api from '../../../../helpers/Api';
import SnackBar from '../../../misc/SnackBar';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: '#042731',
    },
    alert: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: '#042731',
        '&:hover': {
            backgroundColor: '#042731',
        }
    },
}));

function Index() {

    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [validation, setValidation] = useState(false);
    const [msg, setMsg] = useState(false);

    const snackBarSuccessRef = useRef(null);
    const snackBarFailRef = useRef(null);

    const errorMessages = {
        'passwords.throttled': 'Вече има изпратено писмо с инструкции за смяна на паролата. За да изпратите ново трябва да изчакате няколко минути.'
    };

    const handleSend = e => {
        e.preventDefault();

        setLoading(true);
        setValidation(false);

        let data = new FormData(e.target);

        Api.post('auth/password/reset/send', data)
            .then(res => {
                if (res.data.success) {
                    snackBarSuccessRef.current.show();
                } else {
                    if (res.data.status) {
                        let msg = errorMessages[res.data.status] || 'Нещо се обърка!';

                        setMsg(msg);
                        setValidation(true);
                    }
                }
            })
            .catch(err => {
                let _err = err.response;

                if (_err && _err.status && _err.status === 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {
                        setValidation(prev => {
                            return { ...prev, [input]: err }
                        });
                    }
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <section>
            <SnackBar ref={snackBarSuccessRef} severity="success">
                Изпратихме писмо с инструкции за смяна на паролата!
            </SnackBar>



            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Забравена парола
                    </Typography>

                    {validation === true &&
                        <Alert className={classes.alert} severity="error">
                            {msg}
                        </Alert>
                    }

                    <form className={classes.form} noValidate onSubmit={handleSend}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Е-поща"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            error={validation && validation.email}
                            helperText={validation && validation.email}
                        />

                        <SaveButton
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            loading={loading}
                        >
                            Изпрати
                        </SaveButton>

                        <Grid container>
                            <Grid item xs>
                                <Link component={RouterLink} to="/login" variant="body2">
                                    Вход
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link component={RouterLink} to="/register" variant="body2">
                                    Регистрация
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                </div>

            </Container>
        </section>
    )
}

export default Index;