//libraries
import MenuItem from "@material-ui/core/MenuItem"

//constants
import { ADD_OPTIONS_TYPES } from "constants/calendar"

function CalendarAddOptions({ handleAdd }) {
  const ADD_OPTIONS = [
    {
      action: handleAdd,
      ...ADD_OPTIONS_TYPES["MEETING"],
    },
    {
      action: handleAdd,
      ...ADD_OPTIONS_TYPES["NETWORK"],
    },
    {
      action: handleAdd,
      ...ADD_OPTIONS_TYPES["MAIL"],
    },
    {
      action: handleAdd,
      ...ADD_OPTIONS_TYPES["CALL"],
    },
    {
      action: handleAdd,
      ...ADD_OPTIONS_TYPES["TASK"],
    },
    {
      action: handleAdd,
      ...ADD_OPTIONS_TYPES["OTHER"],
    },
  ]

  return ADD_OPTIONS.map(({ action, title, icon, type, sublist }) => (
    <MenuItem
      onClick={() => !sublist && action(type)}
      disabled={sublist}
      disableRipple={sublist}
      className={sublist ? "parent" : ""}
    >
      <img src={icon} alt={title} />
      {title}
      {sublist ? (
        <ul>
          {sublist.map(({ type, title, icon }) => (
            <MenuItem key={type} onClick={() => action(type)}>
              <img src={icon} alt={title} />
              {title}
            </MenuItem>
          ))}
        </ul>
      ) : (
        <></>
      )}
    </MenuItem>
  ))
}

export default CalendarAddOptions
