import axios from 'axios'

//helpers
import { isProd } from './mode'

const Api = axios.create({
    baseURL: process.env[isProd ? 'REACT_APP_URL' : 'REACT_APP_URL_DEV']
});

Api.interceptors.request.use(config => {

    let token;

    try {
        token = localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')).data?.access_token : '';
    } catch (e) {

    }

    config.params = config.params || {};

    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
});

// Api.interceptors.response.use(response => response, (error) => {
// console.log(error.response);

// if (error.response && error.response.status) {
//     if (error.response.status === 401) {
//         window.location = '/';
//     }
// }

// return error;
// });

export default Api;