import React, { useEffect, useRef, useState } from 'react';
import Api from 'helpers/Api';
import { useQuery } from 'helpers/Url';
import Layout from 'components/Layout';
import { useLocation, useHistory, Link } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/lab/Alert';
import { Button } from '@material-ui/core';
import Options from './partials/Options';
import loader from 'assets/img/loader.svg';
import Skeleton from 'react-loading-skeleton';
import Question from 'components/modals/Question';
import { useAuthDataContext } from 'providers/Auth';
import Filter from './partials/Filter';
import AddOrEdit from './partials/AddOrEdit';

//misc
import NoSelectedCompany from 'components/misc/NoSelectedCompany'
import MenuOptions from 'components/misc/MenuOptions'
import Pagination from 'components/misc/Pagination'
import PageLoader from 'components/misc/PageLoader'
import moment from 'moment'

let timeout;

function Index() {

    const location = useLocation();
    const history = useHistory();
    const query = useQuery();
    const auth = useAuthDataContext();

    const addOrEditModalRef = useRef(null);
    const deleteModalRef = useRef(null);

    const [state, setState] = useState({
        loading: true,
        refresh: false,
        setFilter: false,
        data: [],
        pages: 0,
        total: 0,
        filter: {
            page: 1,
            company_id: query.get('company_id') || auth?.getUser()?.getCompany()?.id,
        },
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: query.get('page') || 1,
                search: query.get('search') || '',
                company_id: query.get('company_id') || prev.filter.company_id || '',
            },
            refresh: moment().unix()
        }))
    }, [location.search]);

    useEffect(() => {
        if (state.setFilter) {
            Object.entries(state.filter).map(filter => {
                // if (filter[1]) {
                query.set(filter[0], filter[1]);
                // }
            });

            history.push('?' + query.toString());
        }

    }, [state.setFilter]);

    useEffect(() => {
        if (state.refresh) {
            loadData();
        }
    }, [state.refresh]);

    const loadData = () => {

        if (!state.filter.company_id) {
            return;
        }

        loading(true);

        let url = 'projects/types/all?resource=1';

        Object.entries(state.filter).map(filter => {
            url += (url.indexOf('?') > -1 ? '&' : '?') + filter[0] + '=' + filter[1];
        });

        Api.get(url)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data.items,
                    total: res.data.total,
                    pages: res.data.pages
                }));
            })
            .finally(() => {
                loading(false);
            })
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }));
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: reset ? 1 : prev.filter.page
            },
            refresh: moment().unix()
        }));
    }

    const handleAdd = () => {
        let modal = addOrEditModalRef.current;

        modal.add();

        modal.onSuccess(() => {
            refresh(true);
        });
    }

    // update
    const handleEdit = id => {
        let modal = addOrEditModalRef.current;

        modal.edit(id);

        modal.onSuccess(() => {
            refresh(false);
        });
    }

    // delete
    const handleDelete = id => {
        let modal = deleteModalRef.current;

        modal.open();
        modal.onSuccess(() => {
            handleDestroy(id);
        })
    }

    const handleDestroy = id => {
        Api.post('projects/types/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                refresh();
            }
        });
    }

    // Search
    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout);

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },
        }));

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                setFilter: moment().unix()
            }));
        }, delay);
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            setFilter: moment().unix()
        }));
    }

    return (
        <>

            <AddOrEdit
                ref={addOrEditModalRef}
                companyId={state.filter.company_id}
            />

            <Question
                ref={deleteModalRef}
                mainMessage="Сигурни ли сте?"
            />

            <Layout>
                <section id="projects">


                    <Filter
                        filter={state.filter}
                        handleSearch={handleSearch}
                    />

                    <br />

                    <Button
                        variant="contained"
                        onClick={handleAdd}
                    >
                        Добави
                    </Button>

                    <Button
                        variant="contained"
                        onClick={() => history.push('/projects?company_id=' + state.filter.company_id)}
                        style={{ marginLeft: '10px' }}
                    >
                        Списък с проекти
                    </Button>

                    <br />
                    <br />

                    {!state.filter.company_id
                        ?
                        <NoSelectedCompany />
                        :
                        state.loading
                            ?
                            <Skeleton count={5} height={60} />
                            :
                            state.data.length === 0
                                ?
                                <Alert severity="error">
                                    Няма намерена информация!
                                </Alert>
                                :
                                <>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Име</TableCell>
                                                    <TableCell align="center">Опции</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {state.data.map(c =>
                                                    <React.Fragment key={'c-' + c.id}>
                                                        <TableRow>
                                                            <TableCell>
                                                                {c.translation?.name}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <MenuOptions>
                                                                    <Options
                                                                        data={c}
                                                                        handleEdit={handleEdit}
                                                                        handleDelete={handleDelete}
                                                                    />
                                                                </MenuOptions>
                                                            </TableCell>
                                                        </TableRow>
                                                    </React.Fragment>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                    <Pagination
                                        page={state.filter.page}
                                        pages={state.pages}
                                        handlePage={handlePage}
                                    />
                                </>
                    }
                </section>
            </Layout>
        </>
    )
}

export default Index;